import translations from './common.loc.json';
import { getDefinitions, interpolateVariable, Locale } from './index';

/**
 *
 */
export class CommonLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  // -- #words
  // -----------------------------------------------

  public get About(): string {
    return this.definitions.About;
  }

  public get Active(): string {
    return this.definitions.Active;
  }

  public get Back(): string {
    return this.definitions.Back;
  }

  public get Blog(): string {
    return this.definitions.Blog;
  }

  public get Browse(): string {
    return this.definitions.Browse;
  }

  public get Collect(): string {
    return this.definitions.Collect;
  }

  public get ContactSales(): string {
    return this.definitions.ContactSales;
  }

  public get ContactSalesDescription(): string {
    return this.definitions.ContactSalesDescription;
  }

  public get Disabled(): string {
    return this.definitions.Disabled;
  }

  public get Documents(): string {
    return this.definitions.Documents;
  }

  public get Error(): string {
    return this.definitions.Error;
  }

  public get Guarantee(): string {
    return this.definitions.Guarantee;
  }

  public get Image(): string {
    return this.definitions.Image;
  }

  public get Insurance(): string {
    return this.definitions.Insurance;
  }

  public get Inventory(): string {
    return this.definitions.Inventory;
  }

  public get List(): string {
    return this.definitions.List;
  }

  public get Logistics(): string {
    return this.definitions.Logistics;
  }

  public get Manage(): string {
    return this.definitions.Manage;
  }

  public get Manufacturer(): string {
    return this.definitions.Manufacturer;
  }

  public get Next(): string {
    return this.definitions.Next;
  }

  public get NextStep(): string {
    return this.definitions.NextStep;
  }

  public get Overview(): string {
    return this.definitions.Overview;
  }

  public get Previous(): string {
    return this.definitions.Previous;
  }

  public get Private(): string {
    return this.definitions.Private;
  }

  public get Refurbishment(): string {
    return this.definitions.Refurbishment;
  }

  public get Remarketing(): string {
    return this.definitions.Remarketing;
  }

  public get Search(): string {
    return this.definitions.Search;
  }

  public get Secure(): string {
    return this.definitions.Secure;
  }

  public get Testimonials(): string {
    return this.definitions.Testimonials;
  }

  public get Today(): string {
    return this.definitions.Today;
  }

  public get Transact(): string {
    return this.definitions.Transact;
  }

  public get Unknown(): string {
    return this.definitions.Unknown;
  }

  public get Warranty(): string {
    return this.definitions.Warranty;
  }

  public get Yesterday(): string {
    return this.definitions.Yesterday;
  }

  // -- #phrases
  // -----------------------------------------------

  public get AddOnServices(): string {
    return this.definitions.AddOnServices;
  }

  public AllMakeCategory(make: string, category: string): string {
    const text = interpolateVariable(this.definitions.AllMakeCategory, 'make', make);
    return interpolateVariable(text, 'category', category);
  }

  public get AllVintages(): string {
    return this.definitions.AllVintages;
  }

  public get BrowseEquipment(): string {
    return this.definitions.BrowseEquipment;
  }

  public get BrowseListings(): string {
    return this.definitions.BrowseListings;
  }

  public get BuyEquipment(): string {
    return this.definitions.BuyEquipment;
  }

  public get BuyUsedSemiconductor(): string {
    return this.definitions.BuyUsedSemiconductor;
  }

  public get CollectDescription(): string {
    return this.definitions.CollectDescription;
  }

  public get CompanyProfile(): string {
    return this.definitions.CompanyProfile;
  }

  public get CreateListing(): string {
    return this.definitions.CreateListing;
  }

  public get DaysAgo(): string {
    return this.definitions.DaysAgo;
  }

  public get ExploreAllServices(): string {
    return this.definitions.ExploreAllServices;
  }

  public get ErrorProcessingRequest(): string {
    return this.definitions.ErrorProcessingRequest;
  }

  public get FileIcon(): string {
    return this.definitions.FileIcon;
  }

  public get FindIt(): string {
    return this.definitions.FindIt;
  }

  public get JustBrowsing(): string {
    return this.definitions.JustBrowsing;
  }
  public get GetStarted(): string {
    return this.definitions.GetStarted;
  }

  public get LatestPosts(): string {
    return this.definitions.LatestPosts;
  }

  public get ListDescription(): string {
    return this.definitions.ListDescription;
  }

  public get LookingToSell(): string {
    return this.definitions.LookingToSell;
  }

  public get ManageBuyDescription(): string {
    return this.definitions.ManageBuyDescription;
  }

  public get ManageSellDescription(): string {
    return this.definitions.ManageSellDescription;
  }

  public get ModernTechnology(): string {
    return this.definitions.ModernTechnology;
  }

  public get MyOrders(): string {
    return this.definitions.MyOrders;
  }

  public get OffloadYourSurplusEquipment(): string {
    return this.definitions.OffloadYourSurplusEquipment;
  }

  public PageNumber(pageNumber: number | string): string {
    return interpolateVariable(this.definitions.PageNumber, 'page_number', pageNumber);
  }

  public get PhoneNumber(): string {
    return this.definitions.PhoneNumber;
  }

  public get PrivacyPolicy(): string {
    return this.definitions.PrivacyPolicy;
  }

  public get SearchByKeywordMakeOrModel(): string {
    return this.definitions.SearchByKeywordMakeOrModel;
  }

  public get SearchDescription(): string {
    return this.definitions.SearchDescription;
  }

  public get SecureDescription(): string {
    return this.definitions.SecureDescription;
  }

  public get SeeAllListings(): string {
    return this.definitions.SeeAllListings;
  }

  public get SeeWhyManufacturersTrust(): string {
    return this.definitions.SeeWhyManufacturersTrust;
  }

  public get SellEquipment(): string {
    return this.definitions.SellEquipment;
  }

  public get SignIn(): string {
    return this.definitions.SignIn;
  }

  public get SignUp(): string {
    return this.definitions.SignUp;
  }

  public get SignUpDescription(): string {
    return this.definitions.SignUpDescription;
  }

  public get SixtyDaysAgo(): string {
    return this.definitions.SixtyDaysAgo;
  }

  public get StartHere(): string {
    return this.definitions.StartHere;
  }

  public get TermsAndConditions(): string {
    return this.definitions.TermsAndConditions;
  }

  public get CookiePreferences(): string {
    return this.definitions.CookiePreferences;
  }

  public get ThirtyDaysAgo(): string {
    return this.definitions.ThirtyDaysAgo;
  }

  public get TransactDescription(): string {
    return this.definitions.TransactDescription;
  }

  public get TrustedByLeaders(): string {
    return this.definitions.TrustedByLeaders;
  }

  public get WhatEquipmentAreYouLookingFor(): string {
    return this.definitions.WhatEquipmentAreYouLookingFor;
  }

  public get WhatEquipmentAreYouLookingToBuy(): string {
    return this.definitions.WhatEquipmentAreYouLookingToBuy;
  }

  public TopMakeCategory(make: string, category: string): string {
    const text = interpolateVariable(this.definitions.TopMakeCategory, 'make', make);
    return interpolateVariable(text, 'category', category);
  }

  public get TopManufacturers(): string {
    return this.definitions.TopManufacturers;
  }

  public get FilterManufacturers(): string {
    return this.definitions.FilterManufacturers;
  }

  public get NoExactMatches(): string {
    return this.definitions.NoExactMatches;
  }

  public get NoModelsFound(): string {
    return this.definitions.NoModelsFound;
  }

  public UsedMakeEquipmentForSale(makeName: string): string {
    return interpolateVariable(this.definitions.UsedMakeEquipmentForSale, 'make_name', makeName);
  }

  public BuyOrSellUsedMakeEquipment(makeName: string): string {
    return interpolateVariable(this.definitions.BuyOrSellUsedMakeEquipment, 'make_name', makeName);
  }

  public get NoDescriptionAvailable(): string {
    return this.definitions.NoDescriptionAvailable;
  }

  public get ActiveListings(): string {
    return this.definitions.ActiveListings;
  }

  public get TotalProducts(): string {
    return this.definitions.TotalProducts;
  }

  public get ServicesList(): string {
    return this.definitions.ServicesList;
  }

  public get NoProductsFound(): string {
    return this.definitions.NoProductsFound;
  }

  public get FilterProducts(): string {
    return this.definitions.FilterProducts;
  }

  public get ViewArticle(): string {
    return this.definitions.ViewArticle;
  }

  public get PurchaseTheEquipmentYouNeed(): string {
    return this.definitions.PurchaseTheEquipmentYouNeed;
  }

  public get CreatedDateColon(): string {
    return this.definitions.CreatedDateColon;
  }

  public get ThisListingIsPrivate(): string {
    return this.definitions.ThisListingIsPrivate;
  }

  public get HaveOneLikeThis(): string {
    return this.definitions.HaveOneLikeThis;
  }

  public get FindOneForMe(): string {
    return this.definitions.FindOneForMe;
  }

  public get VerifyEmail(): string {
    return this.definitions.VerifyEmail;
  }

  public get WelcomeBack(): string {
    return this.definitions.WelcomeBack;
  }

  public get WorldsFirstInteractivePlatform(): string {
    return this.definitions.WorldsFirstInteractivePlatform;
  }

  public get SearchEquipment(): string {
    return this.definitions.SearchEquipment;
  }

  public get FindBuyersForYourEquipment(): string {
    return this.definitions.FindBuyersForYourEquipment;
  }

  public get MyListings(): string {
    return this.definitions.MyListings;
  }

  public get FreeUpSpace(): string {
    return this.definitions.FreeUpSpace;
  }

  public get UhOhLoggedOut(): string {
    return this.definitions.UhOhLoggedOut;
  }

  public get WhatWouldYouLikeToDo(): string {
    return this.definitions.WhatWouldYouLikeToDo;
  }

  public get GoToHome(): string {
    return this.definitions.UhOhLoggedOut;
  }

  public get WhiteGloveExperience(): string {
    return this.definitions.WhiteGloveExperience;
  }

  public get MoneyBackGuarantee(): string {
    return this.definitions.MoneyBackGuarantee;
  }

  public get Submit(): string {
    return this.definitions.Submit;
  }

  public get SuccessYourTRHasBeenCreated(): string {
    return this.definitions.SuccessYourTRHasBeenCreated;
  }

  public get ListingCreated(): string {
    return this.definitions.ListingCreated;
  }

  public get Colon(): string {
    return this.definitions.Colon;
  }
}
