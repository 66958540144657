import { useField } from 'formik';
import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { ToggleSwitchCheckboxUI } from '../fields/toggleSwitchField';
import { CheckBoxFieldCheckedValue, CheckboxValue, getNewCheckboxValue } from './formikCheckBoxField';
import { FormikToggleSwitchWrapper } from './formikWrappers';

export interface FormikToggleSwitchFieldProps {
  readonly value: CheckboxValue;
  readonly checkedValue: CheckBoxFieldCheckedValue;
  readonly disabled?: boolean;
  readonly label?: string;
  readonly name: string;
  readonly required?: boolean;
  readonly ariaLabel?: string;
}

/**
 *
 */
export const FormikToggleSwitchField: FC<FormikToggleSwitchFieldProps> = (props) => {
  const { label, name, required, value, checkedValue, disabled, ariaLabel } = props;
  const [checked, setChecked] = useState(value === checkedValue);
  const [field, _, helpers] = useField({ type: 'checkbox', name });
  const { onBlur } = field;
  const { setValue } = helpers;

  useEffect(() => {
    setChecked(value === checkedValue);
  }, [value, checkedValue]);

  const handleChange: ChangeEventHandler = () => {
    setValue(getNewCheckboxValue(checkedValue, checked));
  };

  return (
    <FormikToggleSwitchWrapper label={label} required={required} disabled={disabled}>
      {/* ACTUAL CHECKBOX ELEMENT */}
      <input
        type="checkbox"
        className="peer absolute h-0 w-0 opacity-0"
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        checked={checked}
        aria-label={ariaLabel}
      />

      {/* CHECKBOX UI */}
      <ToggleSwitchCheckboxUI checked={checked} />
    </FormikToggleSwitchWrapper>
  );
};

export default FormikToggleSwitchField;
