import { MOOV_API_URL } from '@/src/constants';
import axios from 'axios';
import { openSuccess } from '../banner/slice';
import { AppDispatch, AppState } from '@/src/store';
import {
  ContactSalesFormValues,
  ContactSalesWithUserInfoFormValues
} from '@/src/globalComponents/globalModal/forms/contactSalesForm/types';
import { Localizer } from '@/src/localization';
import { stringifyArrayOfNums } from '@/src/providers/utils';
import { DTOLead } from '@/src/providers/leads/types';

export enum ContactSalesCTAName {
  NAME = 'Contact Sales'
}

export interface ContactSalesPayload extends ContactSalesFormValues {
  from_url: string;
  form_name: ContactSalesCTAName;
}
export interface ContactSalesPayloadWithUserInfo extends ContactSalesWithUserInfoFormValues {
  from_url: string;
  form_name: ContactSalesCTAName;
}

export const createLeadFromContactSales = (payload: ContactSalesPayload | ContactSalesPayloadWithUserInfo, loc: Localizer) => {
  const finalPayload: Record<string, unknown> = { ...payload };

  if ('inquiry_reason_values' in payload) {
    finalPayload.inquiry_reason = stringifyArrayOfNums(payload.inquiry_reason_values);
    delete finalPayload.inquiry_reason_values;
  }

  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<DTOLead> =>
    axios
      .post<{ data: DTOLead }>(`${MOOV_API_URL}/leads`, finalPayload, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((res) => {
        dispatch(openSuccess({ message: loc.Modals.InquirySent }));
        return res.data.data;
      });
};
