import { createContext } from 'react';

export interface SidebarState {
  open: boolean;
  visible: boolean;
  transitioning: boolean;
}

export const initialState: SidebarState = {
  open: false,
  visible: false, // whether a page should even have the sidebar
  transitioning: false
};

export const sidebarReducer = (state: SidebarState, action: any) => {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        open: true,
        transitioning: true
      };

    case 'close':
      return {
        ...state,
        open: false
      };
    case 'hide':
      return {
        ...state,
        visible: false
      };
    case 'unhide':
      return {
        ...state,
        visible: true
      };
    case 'transitionEnd': {
      return {
        ...state,
        transitioning: false
      };
    }
    default:
      return state;
  }
};

export type OpenSidebarAction = () => void;
export type CloseSidebarAction = () => void;
export type UnhideSidebarAction = () => void;
export type HideSidebarAction = () => void;
export type EndTransitionAction = () => void;
export type SidebarActions = {
  openSidebar: OpenSidebarAction;
  closeSidebar: CloseSidebarAction;
  unhideSidebar: UnhideSidebarAction;
  hideSidebar: HideSidebarAction;
  endTransition: EndTransitionAction;
};

export const SidebarStore = createContext(initialState);
export const SidebarDispatch = createContext<SidebarActions>({
  openSidebar: () => {},
  closeSidebar: () => {},
  unhideSidebar: () => {},
  hideSidebar: () => {},
  endTransition: () => {}
});
