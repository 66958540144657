import { GenerateCategorySummaryProps, ListingCategorySummary } from '../../entities/category';
import { stringFromKey, numberFromKey } from '@/src/common/lib';

/** @deprecated use common Attribute class constructor i.e. new Attribute({...}) */
export const generateCategorySummary = (props: GenerateCategorySummaryProps = {}): ListingCategorySummary => ({
  id: props.id || 0,
  slug: props.slug || 'categorySlug',
  name: props.name || 'categoryName',
  description: props.description || 'categoryDescription'
});

export const generateCategory = (props: Partial<Category> = {}) => ({
  id: props.id || 0,
  slug: props.slug || 'categorySlug',
  name: props.name || 'categoryName',
  description: props.description || 'categoryDescription',
  listings_count: props.listings_count || 0,
  parent_id: props.parent_id || 0
});

export class Category {
  id = 0;
  slug = '';
  name = '';
  description = '';
  listings_count = 0;
  parent_id = 0;

  constructor(args: Partial<Category> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): CategorySerializable {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      description: this.description,
      listings_count: this.listings_count,
      parent_id: this.parent_id
    };
  }

  //region static members
  static anyToDto(a: any): Category {
    const s = Category.anyToSerializable(a);
    return new Category(s);
  }

  static anyToSerializable(a: any): CategorySerializable {
    return {
      description: stringFromKey('description', a),
      id: numberFromKey('id', a),
      name: stringFromKey('name', a),
      slug: stringFromKey('slug', a),
      listings_count: numberFromKey('listings_count', a),
      parent_id: numberFromKey('parent_id', a)
    };
  }
  //endregion
}

const asEmpty = { ...new Category() };
export type CategorySerializable = typeof asEmpty;
