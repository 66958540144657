import { MoovButton } from '../../moovButton';
import { FC, ReactNode, useState } from 'react';
import { useGlobalAlertBanner } from '../../../hooks/useGlobalAlertBanner';
import { ERROR_MESSAGES } from '../../../constants/errorMessages';
import { Divider } from '../../dividers';

export interface WorkflowButtonProps {
  dismissButtonSubmit?: () => Promise<void>;
  actionButtonSubmit: () => Promise<void>;
  dismissButtonContent?: ReactNode;
  actionButtonContent: ReactNode;
  actionButtonId?: string;
  dismissButtonId?: string;
  actionButtonDisabled?: boolean;
}

export const WorkflowButtons: FC<WorkflowButtonProps> = ({
  dismissButtonSubmit,
  actionButtonSubmit,
  dismissButtonContent,
  actionButtonContent,
  actionButtonDisabled,
  actionButtonId,
  dismissButtonId
}) => {
  const [dismissLoading, setDismissLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const { openErrorAlert } = useGlobalAlertBanner();

  return (
    // DO NOT REMOVE pb-[var(--safari-bottom-drawer-spacing)], see customProperties.css for explanation.
    <div className="pb-[var(--safari-bottom-drawer-spacing)]">
      <Divider colorVariant="smokey-gray" className="hidden lg:block" />

      <div className="flex justify-between gap-x-2 p-4 lg:p-6">
        {dismissButtonSubmit && dismissButtonContent && (
          <MoovButton
            id={dismissButtonId}
            variant="tertiary"
            className="min-h-14 flex-1"
            onClick={() => {
              setDismissLoading(true);
              dismissButtonSubmit()
                .then(() => {
                  setDismissLoading(false);
                })
                .catch(() => {
                  setDismissLoading(false);
                  openErrorAlert(ERROR_MESSAGES.NO_REFRESH);
                });
            }}
            disabled={actionLoading}
            isLoading={dismissLoading}
          >
            {dismissButtonContent}
          </MoovButton>
        )}
        <MoovButton
          id={actionButtonId}
          variant="primary"
          className="min-h-14 flex-1"
          onClick={() => {
            setActionLoading(true);
            actionButtonSubmit()
              .then(() => {
                setActionLoading(false);
              })
              .catch(() => {
                setActionLoading(false);
                openErrorAlert(ERROR_MESSAGES.NO_REFRESH);
              });
          }}
          isLoading={actionLoading}
          disabled={dismissLoading || actionButtonDisabled}
        >
          {actionButtonContent}
        </MoovButton>
      </div>
    </div>
  );
};
