import { Typography, Link } from '@mui/material';
import { colors } from '../design/designConstants';
import { useAppSelector } from '../store';
import { getCMSLinksForLocation } from '../state/meta/selectors';
import { CMSLocations } from '../state/meta/cmsLinkTypes';

export const MessagePolicyTypography = () => {
  const cmsLinks = useAppSelector((state) => getCMSLinksForLocation(state, CMSLocations.MESSAGES));
  const link = cmsLinks.filter((link) => link.label === 'Privacy Policy')[0] || {};

  return (
    <>
      {/* DESKTOP */}
      <Typography sx={{ display: { d: 'block', xs: 'none' } }} variant="p12" lineHeight={'19px'}>
        {/* TODO: translations */}
        Messages including contact info may not be shared. We scan, review, and reserve the right to remove sensitive information from all
        messages to prevent fraud and{' '}
        <Link underline="none" component="a" href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}${link.path}`}>
          <Typography variant="p12" color={colors.skyBlue['600']} fontWeight="600">
            policy violations
          </Typography>
        </Link>
        .
      </Typography>

      {/* MOBILE */}
      <Typography sx={{ display: { d: 'none', xs: 'xs' } }} variant="p12" lineHeight={'19px'}>
        {/* TODO: translations */}
        All messages subject to review, review our{' '}
        <Link underline="none" component="a" href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}${link.path}`}>
          <Typography variant="p12" color={colors.skyBlue['600']} fontWeight="600">
            policy
          </Typography>
        </Link>{' '}
        for more info.
      </Typography>
    </>
  );
};
