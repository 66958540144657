import { FC } from 'react';
import { useLocalizer } from './localization';
import { Divider } from './bits/dividers';

interface Props {}

const AcknowledgeMessage: FC<Props> = ({ children }) => {
  const loc = useLocalizer();

  return (
    <div className="rounded-lg bg-magenta-50 p-5 lg:p-6">
      <div className="text-center font-lato text-20spx font-bold leading-tight lg:text-24spx">{loc.Listing.WellGetMorePhotos}</div>
      <Divider colorVariant="magenta-light" className="my-4" />
      <p className="mb-2 font-lato text-16spx font-bold lg:mb-4 lg:text-20spx">{loc.Listing.WhatElseWouldYouLikeToKnow}</p>
      {children}
    </div>
  );
};

export default AcknowledgeMessage;
