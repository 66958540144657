import { FC } from 'react';
import { useUser } from '@/src/hooks/useUser';
import { FindOneAnon } from './findOneAnon';
import { FindOneAuth } from './findOneAuth';

export const FindOneModal: FC = () => {
  const { user, userIsLoggedIn } = useUser();

  return userIsLoggedIn(user) ? <FindOneAuth /> : <FindOneAnon />;
};
