import { IMAGE_URLS } from '../constants/imageUrls';
import { generateMoovImageURL } from '../utils';
import { listingWaferSizeData } from '../providers/listings/constants';
import { Listing, ListingSummary } from '../entities/listing';
import { ListingPhoto } from '../entities/listingPhoto';
import { MakeSummary } from '../entities/make';
import { Listing2, Photo } from '../listings/types';
import { isListing2 } from '../listings/types';

export const generateDefaultListingPhoto = (): ListingPhoto => ({
  id: 1,
  name: 'file_0',
  content_type: 'image/png',
  order: 0,
  small_url: IMAGE_URLS.LISTING_PLACEHOLDER_IMAGE_SM,
  medium_url: IMAGE_URLS.LISTING_PLACEHOLDER_IMAGE_SM,
  large_url: IMAGE_URLS.LISTING_PLACEHOLDER_IMAGE_LG
});

export const generateMoovLogoListingPhoto = (listing: { id: number }): ListingPhoto => {
  const smallUrl = generateMoovImageURL(listing, 's');
  const mediumUrl = generateMoovImageURL(listing, 'm');
  const largeUrl = generateMoovImageURL(listing, 'l');

  return {
    id: 1,
    name: 'file_0',
    content_type: 'image/png',
    order: 0,
    small_url: smallUrl,
    medium_url: mediumUrl,
    large_url: largeUrl
  };
};

const isListing = (value: Listing | ListingSummary | Listing2): value is Listing => {
  if ((value as Listing)?.photos) {
    return true;
  }
  return false;
};

export const getListingDisplayPhoto = (listing: ListingSummary | Listing | Listing2 | null | undefined): ListingPhoto | Photo => {
  let photo = generateDefaultListingPhoto();

  if (isListing2(listing)) {
    return listing.displayPhoto ? listing.displayPhoto : photo;
  }

  if (listing?.photo) {
    photo = listing.photo;
  } else if (listing && isListing(listing) && listing.photos[0]) {
    photo = listing.photos[0];
  }

  return photo;
};

/**
 *
 */
export const groupMakesByName = (makes: MakeSummary[]): [string, MakeSummary[]][] => {
  const sortedMakes = [...makes].sort((a, b) => {
    const [A, B] = [a.name.toUpperCase(), b.name.toUpperCase()];
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  });

  const groups: Map<string, MakeSummary[]> = new Map();

  const defaultGroup: MakeSummary[] = [];
  groups.set('#', defaultGroup);

  for (const make of sortedMakes) {
    const value = make.name.slice(0, 1).toUpperCase();
    if (/[A-Z]/.test(value)) {
      let group = groups.get(value);
      if (typeof group === 'undefined') {
        group = [];
        groups.set(value, group);
      }
      group.push(make);
    } else {
      defaultGroup.push(make);
    }
  }

  return [...groups.entries()];
};

/**
 *
 */
export const waferSizeValuesToDisplayStringArray = (waferSizeValues: number[]): string[] => {
  // -- populate display values
  const formatted: string[] = [];
  for (const waferSize of waferSizeValues) {
    // -- if not found in constants, push the raw value instead
    const displayStr = listingWaferSizeData.get(waferSize) ?? waferSize.toString();
    formatted.push(displayStr);
  }

  return formatted;
};

/**
 *
 */
export const waferSizeValuesToDisplayString = (waferSizeValues: number[]): string => {
  return waferSizeValuesToDisplayStringArray(waferSizeValues).join(', ');
};
