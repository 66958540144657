import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useMobileSuggestedItemsModal } from '../../globalComponents/mobileSuggestedItemsModal/modals/mobileSuggestedItems/hooks/useMobileSuggestedItemsModal';
import { BionicOpportunityDispatch } from '../../state/opportunities/bionic/context';
import { SuggestedOpportunityDispatch } from '../../state/opportunities/suggested/context';
import { isWindowWidthMobile } from '../screen/useResolution';

export const useOpenCloseMobileSuggestedItemsToolTinder = () => {
  const router = useRouter();
  const { openMobileSuggestedItemsModal, closeMobileSuggestedItemsModal } = useMobileSuggestedItemsModal();

  const isBionic = !!router.query.bionicId;

  const bionicActions = useContext(BionicOpportunityDispatch);
  const suggestedActions = useContext(SuggestedOpportunityDispatch);

  const actions = isBionic ? bionicActions : suggestedActions;

  return {
    closeModal: () => {
      if (router.query.bionicId) {
        //Deleting this bionicId is so we don't keep re-fetching
        //opportunities on subsequent closings of this modal
        delete router.query.bionicId;
        router.replace({ pathname: router.pathname, query: router.query }, undefined, { shallow: true });
      }
      if (isWindowWidthMobile()) {
        closeMobileSuggestedItemsModal();
      } else {
        actions.closeModal();
      }
    },
    openModal: () => {
      if (isWindowWidthMobile()) {
        openMobileSuggestedItemsModal();
      } else {
        actions.openModal();
      }
    }
  };
};
