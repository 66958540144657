import { ChangeEventHandler, FC } from 'react';

import { SubProps } from '..';
import { LabeledNumberInput } from '@/src/design/bytes/labeledNumberInput';

export const Vintage: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setVintage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const rawValue = e.target.value.trim();
    const outValue = rawValue.length ? parseInt(rawValue) : undefined;

    dispatch({
      type: 'setVintage',
      value: outValue || ''
    });
  };

  return (
    <LabeledNumberInput
      label={loc.Listing.Vintage}
      placeholder="2000"
      value={state.vintage ? state.vintage.toString() : ''}
      onChange={setVintage}
    />
  );
};
