import {
  getDefinitions,
  interpolateVariable,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class ModelMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get FindSemiconductorEquipment(): string {
    return this.definitions.FindSemiconductorEquipment;
  }

  public get FindUsedSemiconductorEquipmentFast(): string {
    return this.definitions.FindUsedSemiconductorEquipmentFast;
  }

  public get IdleSurplusEquipment(): string {
    return this.definitions.IdleSurplusEquipment;
  }

  public get ListingsSimilarTo(): string {
    return this.definitions.ListingsSimilarTo;
  }

  public get ListYourEquipmentWithMoov(): string {
    return this.definitions.ListYourEquipmentWithMoov;
  }

  public get NoListingsFound(): string {
    return this.definitions.NoListingsFound;
  }

  public SeeAllMakeModelListings(makeName: string, modelName: string): string {
    const makeInterpolated = interpolateVariable(this.definitions.SeeAllMakeModelListings, 'make_name', makeName);
    return interpolateVariable(makeInterpolated, 'model_name', modelName);
  }

  public get TopListings(): string {
    return this.definitions.TopListings;
  }

  public UsedMakeModel(makeName: string, modelName: string): string {
    const makeInterpolated = interpolateVariable(this.definitions.UsedMakeModel, 'make_name', makeName);
    return interpolateVariable(makeInterpolated, 'model_name', modelName);
  }
}
