// The custom properties used in these classes are defined in src/styles/baseStyles/customProperties.css

export const NAVBAR_HEIGHT_CLASS = `h-[var(--navbar-height)]`;
export const NAVBAR_HEIGHT_VALUE = '6rem';
export const NAVBAR_WITH_BANNER_HEIGHT_CLASS = `h-[var(--navbar-with-banner-height)]`;
export const NAVBAR_WITH_BANNER_HEIGHT_VALUE = '9rem';

export const MIN_SIDEBAR_HEIGHT_CLASS = `min-h-[calc(100vh_-_var(--navbar-height))]`;
export const MIN_SIDEBAR_HEIGHT_WITH_BANNER_CLASS = `min-h-[calc(100vh_-_var(--navbar-with-banner-height))]`;
export const MAX_SIDEBAR_HEIGHT_CLASS = `max-h-[calc(100vh_-_var(--navbar-height))]`;
export const MAX_SIDEBAR_HEIGHT_WITH_BANNER_CLASS = `max-h-[calc(100vh_-_var(--navbar-with-banner-height))]`;

export const SIDEBAR_POSITION_TOP_CLASS = `top-25`;
export const SIDEBAR_POSITION_TOP_WITH_BANNER_CLASS = `top-36`;
