import { conditionToText, waferSizeToText } from '@/src/common/lib';
import { FS__FilterValues } from '@/src/hooks/useFullStory/types';
import { FilterValues, SearchFlowType } from '@/src/marketplace/search/types';
import { AppDispatch, AppState } from '@/src/store';
import { getOrFetchMakes } from '../makes/thunks';
import { getOrFetchModels } from '../models/thunks';
import { getOrFetchCategory } from '../category/thunks';

export const getFullStoryFilterValues = (payload: Partial<FilterValues> & Required<{ search_flow_type: SearchFlowType }>) => {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    const fullStoryFilterValues: FS__FilterValues = {
      search_flow_type: payload.search_flow_type,
      q: payload.q,
      sort_by: payload.order,
      has_photos: payload.has_photos,
      is_exclusive: payload.is_exclusive
    };

    if (payload.condition && payload.condition.length > 0) {
      fullStoryFilterValues.condition = payload.condition.map((condition) => ({ id: condition, name: conditionToText(condition) }));
    }
    if (payload.wafer_size && payload.wafer_size.length > 0) {
      fullStoryFilterValues.wafer_size = payload.wafer_size.map((waferSize) => ({ id: waferSize, name: waferSizeToText(waferSize) }));
    }
    if (payload.min_vintage) {
      fullStoryFilterValues.min_vintage = payload.min_vintage;
    }
    if (payload.max_vintage) {
      fullStoryFilterValues.max_vintage = payload.max_vintage;
    }

    const promises = [];
    if (payload.category_id && payload.category_id > 0) {
      promises.push(
        dispatch(getOrFetchCategory(payload.category_id)).then((category) => {
          if (!category) return;
          fullStoryFilterValues.category = { id: category.id, name: category.name };
        })
      );
    }
    if (payload.make_id && payload.make_id.length > 0) {
      promises.push(
        dispatch(getOrFetchMakes({ id: payload.make_id })).then((makes) => {
          if (!makes) return;
          fullStoryFilterValues.makes = makes.map((make) => ({ id: make.id, name: make.name }));
        })
      );
    }
    if (payload.model_id && payload.model_id.length > 0) {
      promises.push(
        dispatch(getOrFetchModels({ id: payload.model_id })).then((models) => {
          if (!models) return;
          fullStoryFilterValues.models = models.map((model) => ({ id: model.id, name: model.name }));
        })
      );
    }

    return Promise.allSettled(promises).then(() => {
      return fullStoryFilterValues;
    });
  };
};
