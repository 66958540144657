import { MOOV_API_URL } from '@/src/constants';
import { loadCMSLinks } from './slice';
import { CMSLinks } from './cmsLinkTypes';
import { AppDispatch, AppState } from '@/src/store';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';

export const fetchCMSLinks = () => {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .get<{ data: CMSLinks }>(`${MOOV_API_URL}/moov-co-meta/cms-links`, {
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
      .then((resp) => {
        dispatch(loadCMSLinks(resp.data.data));
      })
      .catch((e) => {
        captureException(e);
      });
  };
};
