import { RequiredLabel } from '../../bits/requiredLabel';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { CheckboxByte } from '../checkbox';
import { FocusEventHandler } from 'react';
import { CheckboxGroupOption, CheckboxGroupValue } from './types';
import { CheckboxByteOnChangeHandler } from '../checkbox/types';

export interface LabeledCheckboxGroupProps {
  label?: string;
  value: CheckboxGroupValue;
  options: CheckboxGroupOption[];
  onChange: CheckboxByteOnChangeHandler;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  helperText?: string;
  required?: boolean;
  error?: boolean;
}

export const LabeledCheckboxGroup = (props: LabeledCheckboxGroupProps) => {
  const { label, required, error, value, options, onChange, onBlur, helperText } = props;

  return (
    <FormControl error={error}>
      <FormLabel>
        {label}
        {required && <RequiredLabel error={error} />}
      </FormLabel>
      {options.map((option) => (
        <CheckboxByte
          checked={value.includes(option.id)}
          onChange={onChange}
          onBlur={onBlur}
          label={option.label}
          value={option.id}
          key={option.id}
        />
      ))}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
