import { FC, useEffect } from 'react';
import { GlobalThis } from 'type-fest';

declare const globalThis: GlobalThis & { turnstile: any; handleTurnstileWidgetSubmit: any };

/**
 * CAPTCHA widget from Cloudflare Turnstile
 *
 * Here's the tutorial I followed to set this up:
 * https://www.youtube.com/watch?v=OwW0znboh60
 *
 * Here are the official docs for the frontend of this:
 * https://developers.cloudflare.com/turnstile/get-started/server-side-validation/
 */
export const TurnstileDiv: FC<{
  setFieldError: (_field: string, _message: string | undefined) => void;
  setFieldValue: (_field: string, _value: any, _shouldValidate?: boolean | undefined) => void;
}> = ({ setFieldError, setFieldValue }) => {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY) {
      setFieldValue('turnstile_data', 'FAKE_TESTING_VALUE');
      return;
    }

    //#region Weird Stuff

    // lo siento

    /**
     * So, CloudFlare wants to scan the DOM for a dib with a certain class name
     * and then inspect its data-callback attribute for the name of a function
     * it expects to be defined in the global scope. So that 's why we're
     * doing this:
     */

    globalThis.handleTurnstileWidgetSubmit = function handleTurnstileWidgetSubmit(turnstileData: string) {
      if (!turnstileData) {
        setFieldError('turnstile_data', 'Whoops! Something went wrong.');
      }
      setFieldValue('turnstile_data', turnstileData);
    };

    /**
     * Following the tutorial in the link above exactly, you end up with a bug
     * where if the user navigates away from the page and back, you end up
     * a bug caused by Turnstile fighting with Next:
     *
     * Next wants all scripts to load once and only once. Turnstile wants to
     * execute their script every time the magic div is mounted. Turnstile
     * only executes their script on-load. Therefore, we won't give the
     * user a captcha if they navigate away from the register page and
     * back again.
     *
     * To fix this, we manually manage the script element. On mount, we append
     * the script to the DOM. On unmount, we remove it from the DOM. This makes
     * sure the script executs every time this div is mounted.
     *
     * Locally, hot-reloading will cause multiple instances of the widget to
     * load inside the magic div, because we call useEffect on hot reload,
     * but we never call teh unmount function... which is a weird bug,
     * but fortunately we don't need to worry about hot reloading in
     * prod.
     */

    const script = document.createElement('script');
    script.id = 'turnstileScript';
    script.src = `https://challenges.cloudflare.com/turnstile/v0/api.js`;
    script.async = true;
    script.defer = true;

    document.body.append(script);

    return () => {
      delete globalThis.turnstile;
      delete globalThis.handleTurnstileWidgetSubmit;
      document.getElementById(script.id)?.remove();
    };
    //#endregion Weird stuff
  }, []);

  if (!process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY) {
    return null;
  }

  return (
    <>
      <div
        className="cf-turnstile"
        data-sitekey={process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
        data-callback="handleTurnstileWidgetSubmit"
      />
    </>
  );
};
