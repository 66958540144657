import { createContext } from 'react';
import { FetchUserSuggestedListingsParams } from '../../../providers/listings';
import { SuggestedListing } from '../../../entities/listing';
import { getFreshInitialState } from '../helpers';
import { OpportunityActions } from '../types';
import { ViewMode } from '../../../userBuy/suggestedItems/suggestedItemsContent/types';

export const SuggestedOpportunityStore = createContext(getFreshInitialState());
export const SuggestedOpportunityDispatch = createContext<OpportunityActions>({
  rejectOpportunity: (_: string) => {},
  approveOpportunity: (_: string) => {},
  loadOpportunities: (_: SuggestedListing[]) => {},
  selectCurrentOpportunity: (_: string) => {},
  selectNextOpportunity: () => {},
  fetchOpportunities: () => {},
  completeInitialFetchOpportunities: () => {},
  updateQueryParams: (_: FetchUserSuggestedListingsParams) => {},
  openModal: () => {},
  closeModal: () => {},
  resetTransientState: () => {},
  reset: () => {},
  selectSuggestedItemView: (_: ViewMode) => {},
  clearSelectionMade: () => {}
});
