import { ViewMode } from '../../userBuy/suggestedItems/suggestedItemsContent/types';
import { isSuggestedListing, ListingActionStatus, SuggestedListing } from '../../entities/listing';
import { OpportunityModalState } from './types';

export const getFreshInitialState = (): OpportunityModalState => ({
  suggestedListingsRecord: {},
  suggestedListingKeys: [],
  currentListing: {} as SuggestedListing,
  loading: false,
  loaded: false,
  initialFetchComplete: false,
  selectionMade: false,
  feedbackGiven: false,
  modalOpen: false,
  viewMode: ViewMode.CARD,
  queryParams: {}
});

export const _array2Record = (sugggestedArray: SuggestedListing[]): Record<string, SuggestedListing> => {
  const verifyMeBeforeRemoving = sugggestedArray.reduce((acc: Record<string, SuggestedListing>, current: SuggestedListing) => {
    acc[current.opportunity_key] = current;

    return acc;
  }, {});

  return verifyMeBeforeRemoving;
};

export const _isLastOpportunity = (state: OpportunityModalState) => {
  const found = state.suggestedListingKeys.findIndex(
    (opportunityKey) => opportunityKey === (state.currentListing as SuggestedListing)?.opportunity_key
  );

  return found >= state.suggestedListingKeys.length - 1;
};

export const _findNextListing = (state: OpportunityModalState) => {
  if (!isSuggestedListing(state.currentListing)) return state.currentListing;
  else {
    let found = state.suggestedListingKeys.findIndex(
      (opportunityKey) => opportunityKey === (state.currentListing as SuggestedListing)?.opportunity_key
    );
    if (!_isLastOpportunity(state)) {
      found += 1;
    }

    return state.suggestedListingsRecord[state.suggestedListingKeys[found]];
  }
};

export const OpportunitiesReducer = (state: OpportunityModalState, action: any): OpportunityModalState => {
  switch (action.type) {
    case 'reject':
      return {
        ...state,
        suggestedListingsRecord: {
          ...state.suggestedListingsRecord,
          [action.payload.opportunity_key]: {
            ...state.suggestedListingsRecord[action.payload.opportunity_key],
            opportunity_status: ListingActionStatus.rejected
          }
        },
        currentListing: {
          ...state.currentListing,
          opportunity_status: ListingActionStatus.rejected
        },
        selectionMade: isSuggestedListing(state.currentListing) && state.currentListing.opportunity_status !== ListingActionStatus.rejected
      };
    case 'approve':
      return {
        ...state,
        suggestedListingsRecord: {
          ...state.suggestedListingsRecord,
          [action.payload.opportunity_key]: {
            ...state.suggestedListingsRecord[action.payload.opportunity_key],
            opportunity_status: ListingActionStatus.approved
          }
        },
        currentListing: {
          ...state.currentListing,
          opportunity_status: ListingActionStatus.approved
        },
        selectionMade: isSuggestedListing(state.currentListing) && state.currentListing.opportunity_status !== ListingActionStatus.approved
      };
    case 'fetch':
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case 'updateQueryParams':
      return {
        ...state,
        loading: false,
        loaded: false,
        queryParams: action.payload.queryParams
      };
    case 'completeInitialFetch':
      return {
        ...state,
        initialFetchComplete: true,
        loading: false,
        loaded: true
      };
    case 'load':
      return {
        ...state,
        suggestedListingsRecord: _array2Record(action.payload.listings),
        suggestedListingKeys: action.payload.listings.map((listing: SuggestedListing) => listing.opportunity_key),
        loading: false,
        loaded: true
      };
    case 'selectCurrentListing':
      return {
        ...state,
        currentListing: state.suggestedListingsRecord[action.payload.opportunity_key],
        selectionMade: false
      };
    case 'selectNextOpportunity':
      return {
        ...state,
        currentListing: _findNextListing(state),
        feedbackGiven: _isLastOpportunity(state),
        selectionMade: false
      };

    case 'clearSelectionMade':
      return {
        ...state,
        selectionMade: false
      };
    case 'openModal':
      return {
        ...state,
        modalOpen: true
      };
    case 'closeModal':
      return {
        ...state,
        modalOpen: false
      };
    case 'resetTransientState':
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case 'reset':
      return getFreshInitialState();
    case 'selectSuggestedItemView':
      return {
        ...state,
        viewMode: action.payload.viewMode
      };
    default:
      return state;
  }
};
