import translations from './account.loc.json';
import { Locale, getDefinitions, interpolateVariable } from './localization';

interface CompanyOptions {
  readonly value: number;
  readonly label: string;
}

/**
 *
 */
export class AccountLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public CompanyIndustryTypeOptions(): CompanyOptions[] {
    return [
      { value: 1, label: this.definitions.Semi },
      { value: 2, label: this.definitions.PCBSMT },
      { value: 3, label: this.definitions.LEDOptic },
      { value: 4, label: this.definitions.SolarPhotovoltaic },
      { value: 5, label: this.definitions.Logistics }
    ];
  }

  public CompanyTypeOptions(): CompanyOptions[] {
    return [
      { value: 1, label: this.definitions.EndUser },
      { value: 2, label: this.definitions.DealerCarrying },
      { value: 3, label: this.definitions.DealerNotCarrying },
      { value: 4, label: this.definitions.Refurbisher },
      { value: 5, label: this.definitions.ServiceProvider },
      { value: 6, label: this.definitions.OEM }
    ];
  }

  public EquipmentOrderDelivered(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.EquipmentOrderDelivered, 'pipeline_id', pipelineId);
  }

  public EquipmentOrderReceived(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.EquipmentOrderReceived, 'pipeline_id', pipelineId);
  }

  public EquipmentOrderTransit(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.EquipmentOrderTransit, 'pipeline_id', pipelineId);
  }

  public ListingCreatedFor(manufacturerName: string, modelName: string): string {
    const message = interpolateVariable(this.definitions.ListingCreatedFor, 'manufacturer_name', manufacturerName);
    return interpolateVariable(message, 'model_name', modelName);
  }

  public ListingSubmittedReview(listingManufacturer: string, listingModel: string): string {
    const message = interpolateVariable(this.definitions.ListingSubmittedReview, 'listing_manufacturer', listingManufacturer);
    return interpolateVariable(message, 'listing_model', listingModel);
  }

  public OrederUnderContract(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.OrederUnderContract, 'pipeline_id', pipelineId);
  }

  public PaymentReceived(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.PaymentReceived, 'pipeline_id', pipelineId);
  }

  public SentMessageOrder(pipelineId: string | number): string {
    return interpolateVariable(this.definitions.SentMessageOrder, 'pipeline_id', pipelineId);
  }

  public get ToolsInReview(): string {
    return this.definitions.ToolsInReview;
  }

  public get InReview(): string {
    return this.definitions.InReview;
  }

  public get SuggestedTools(): string {
    return this.definitions.SuggestedTools;
  }

  public get Suggested(): string {
    return this.definitions.Suggested;
  }

  public get NewListing(): string {
    return this.definitions.NewListing;
  }

  public get AllItems(): string {
    return this.definitions.AllItems;
  }

  public get Sold(): string {
    return this.definitions.Sold;
  }

  public get NothingInReviewYet(): string {
    return this.definitions.NothingInReviewYet;
  }

  public get FindYourNextTool(): string {
    return this.definitions.FindYourNextTool;
  }

  public get NoSuggestedTools(): string {
    return this.definitions.NoSuggestedTools;
  }

  public get NoActiveOrders(): string {
    return this.definitions.NoActiveOrders;
  }

  public get LookingToSell(): string {
    return this.definitions.LookingToSell;
  }

  public get NothingSoldYet(): string {
    return this.definitions.NothingSoldYet;
  }

  public get OnceYourListingHasSold(): string {
    return this.definitions.OnceYourListingHasSold;
  }
}
