import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerOfferSerializable, ReadableOfferStatus, SellerOfferGroupSerializable } from './types';

export enum OfferSortBys {
  HIGHEST_OFFER = 'Highest Offer',
  EXPIRE_TIME_DESC = 'Expiry Time DESC',
  EXPIRE_TIME_ASC = 'Expiry Time ASC'
}
export interface OffersState {
  byId: Record<string, BuyerOfferSerializable | SellerOfferGroupSerializable>;
  buyerOfferIds: string[];
  sellerOfferIds: string[];
  expandedOfferId: string;
  loading: boolean;
  error: boolean;
  initialized: boolean;
  filters: {
    status: ReadableOfferStatus | 'all';
    modelId: string;
    sortBy: OfferSortBys;
  };
}

export const initialState: OffersState = {
  byId: {},
  buyerOfferIds: [],
  sellerOfferIds: [],
  expandedOfferId: '',
  loading: false,
  error: false,
  initialized: false,
  filters: {
    status: ReadableOfferStatus.PENDING,
    modelId: '0',
    sortBy: OfferSortBys.HIGHEST_OFFER
  }
};

export const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
      state.initialized = false;
    },
    setError: (state) => {
      state.loading = false;
      state.error = true;
      state.initialized = false;
    },
    loadBuyerOffers: (state, action: PayloadAction<BuyerOfferSerializable[]>) => {
      state.byId = action.payload.reduce((acc: Record<string, BuyerOfferSerializable>, offer) => {
        acc[offer.key] = offer;
        return acc;
      }, {});
      state.buyerOfferIds = action.payload.map((offer) => offer.key);
      state.loading = false;
      state.initialized = true;
    },
    loadSingleBuyerOffer: (state, action: PayloadAction<BuyerOfferSerializable>) => {
      state.byId[action.payload.key] = action.payload;
      state.buyerOfferIds = [action.payload.key, ...state.buyerOfferIds];
    },
    replaceBuyerOffer: (state, action: PayloadAction<{ offer: BuyerOfferSerializable; oldOfferKey: string }>) => {
      // Put new offer in by id and remove old offer
      const newById = {
        ...state.byId,
        [action.payload.offer.key]: action.payload.offer
      };
      delete newById[action.payload.oldOfferKey];
      state.byId = newById;

      //Replace old offer key with new offer key in buyerOfferIds
      const newBuyerOfferIds = [...state.buyerOfferIds];
      const index = newBuyerOfferIds.findIndex((key) => key === action.payload.oldOfferKey);
      newBuyerOfferIds.splice(index, 1, action.payload.offer.key);
      state.buyerOfferIds = newBuyerOfferIds;
    },
    updateBuyerOffer: (state, action: PayloadAction<BuyerOfferSerializable>) => {
      const newById = {
        ...state.byId,
        [action.payload.offer.key]: action.payload
      };
      state.byId = newById;
    },
    setStatusFilter: (state, action: PayloadAction<ReadableOfferStatus | 'all'>) => {
      state.filters.status = action.payload;
    },
    setModelFilter: (state, action: PayloadAction<string>) => {
      state.filters.modelId = action.payload;
    },
    setSortBy: (state, action: PayloadAction<OfferSortBys>) => {
      state.filters.sortBy = action.payload;
    },
    loadSellerOffers: (state, action: PayloadAction<SellerOfferGroupSerializable[]>) => {
      state.byId = action.payload.reduce((acc: Record<string, SellerOfferGroupSerializable>, offer) => {
        // Key by listing key, thats the only unique identifier for seller offers since there is multiple offers in a group
        acc[offer.listing.key] = offer;
        return acc;
      }, {});

      state.sellerOfferIds = action.payload.map((offer) => offer.listing.key);
      state.loading = false;
      state.initialized = true;
    },
    updateSellerOfferGroup: (state, action: PayloadAction<SellerOfferGroupSerializable>) => {
      state.byId[action.payload.listing.key] = action.payload;
    },
    setExpandedOfferId: (state, action: PayloadAction<string>) => {
      state.expandedOfferId = action.payload;
    },
    clearFilters: (state) => {
      state.filters = { ...initialState.filters, status: 'all' };
    },
    reset: () => initialState
  }
});

export const {
  setLoading,
  setError,
  loadBuyerOffers,
  loadSingleBuyerOffer,
  replaceBuyerOffer,
  updateBuyerOffer,
  setStatusFilter,
  setModelFilter,
  loadSellerOffers,
  setExpandedOfferId,
  setSortBy,
  updateSellerOfferGroup,
  clearFilters,
  reset
} = slice.actions;

export default slice.reducer;
