import React, { ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import { useField } from 'formik';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { getFieldDescribedById, isEmpty } from '../../../utils';

export type CheckboxValue = string | number | boolean | undefined;
export type CheckBoxFieldCheckedValue = string | number | true;

/**
 *
 */
export const getNewCheckboxValue = (checkedValue: CheckBoxFieldCheckedValue, checked: boolean) => {
  switch (typeof checkedValue) {
    case 'string':
      return checked ? '' : checkedValue;
    case 'number':
      return checked ? 0 : checkedValue;
    case 'boolean':
      return checked ? false : true;
  }
};

type FormikCheckBoxFieldProps = {
  readonly label?: string;
  readonly name: string;
  readonly children?: ReactNode;
  readonly value: CheckboxValue;
  readonly checkedValue: CheckBoxFieldCheckedValue;
  readonly disabled?: boolean;
};

/**
 *
 */
const FormikCheckBoxField = (props: FormikCheckBoxFieldProps) => {
  const { label, name, value, checkedValue, disabled, children } = props;
  const [checked, setChecked] = useState(value === checkedValue);
  const [field, meta, helpers] = useField({ type: 'checkbox', name });
  const { onBlur } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    setChecked(value === checkedValue);
  }, [value, checkedValue]);

  const handleChange: ChangeEventHandler = () => {
    setValue(getNewCheckboxValue(checkedValue, checked));
  };

  return (
    <label className={`group relative block pl-8 tracking-wide ${disabled ? 'form-group-disabled' : 'cursor-pointer'}`}>
      {/* ACTUAL CHECKBOX ELEMENT */}
      <input
        type="checkbox"
        className="peer absolute h-0 w-0 opacity-0"
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        checked={checked}
        aria-describedby={getFieldDescribedById(name)}
        aria-invalid={touched && !isEmpty(error)}
      />

      {/* CHECKBOX UI */}
      <div
        className={`peer-focus-visible:default-focus-outline absolute left-0 top-1/2 flex -translate-y-1/2 items-center justify-center rounded text-24spx ${
          disabled ? '' : 'group-hover:opacity-70 peer-focus-visible:opacity-70'
        }`}
      >
        {checked ? <CheckBoxIcon fontSize="inherit" /> : <CheckBoxOutlineBlankIcon fontSize="inherit" />}
      </div>
      {isEmpty(children) ? label : children}
    </label>
  );
};

export default FormikCheckBoxField;
