export enum TouchPointType {
  TOUCH_POINT_TYPE_CALL = 8,
  TOUCH_POINT_TYPE_LVM = 9,
  TOUCH_POINT_TYPE_TEXT_SEND = 10,
  TOUCH_POINT_TYPE_TEXT_RECEIVED = 11,
  TOUCH_POINT_TYPE_EMAIL_SEND = 12,
  TOUCH_POINT_TYPE_EMAIL_RECEIVED = 13,
  TOUCH_POINT_TYPE_LI_MESSAGE_RECEIVED = 14,
  TOUCH_POINT_TYPE_LI_INMAIL_RECEIEVED = 15,
  TOUCH_POINT_TYPE_LI_MESSAGE_SENT = 16,
  TOUCH_POINT_TYPE_MISC = 17,
  TOUCH_POINT_TYPE_CAMPAIGN_SENT = 18,
  TOUCH_POINT_TYPE_LI_INMAIL_SENT = 24,
  TOUCH_POINT_TYPE_BIONIC_FOR_SALE = 27,
  TOUCH_POINT_TYPE_BIONIC_LISTING_CHECKIN = 28,
  TOUCH_POINT_TYPE_BIONIC_SUPPLY_GENERIC_CHECKIN = 29,
  TOUCH_POINT_TYPE_BIONIC_LISTING_VERIFICATION = 30,
  TOUCH_POINT_TYPE_BIONIC_BUYER_TECH_MATCH = 31,
  TOUCH_POINT_TYPE_BIONIC_BUYER_THANK_YOU = 32,
  TOUCH_POINT_TYPE_BIONIC_LISTING_HUNTER = 33,
  TOUCH_POINT_TYPE_BIONIC_WANTED_HUNTER = 34,
  TOUCH_POINT_TYPE_BIONIC_LISTING_CHECKIN_AE = 35,
  TOUCH_POINT_TYPE_HOLD_FOLLOW_UP = 36,
  TOUCH_POINT_TYPE_WECHAT_SENT = 37,
  TOUCH_POINT_TYPE_WECHAT_RECEIVED = 38,
  TOUCH_POINT_TYPE_NO_ANSWER = 39,
  TOUCH_POINT_TYPE_VISIT_LISTING = 40,
  TOUCH_POINT_TYPE_VISIT_SETTLEMENT = 41,
  TOUCH_POINT_TYPE_VISIT_BIONIC = 42,
  TOUCH_POINT_TYPE_SUBMITTED_INTERESTED = 43,
  TOUCH_POINT_TYPE_SUBMITTED_NOT_INTERESTED = 44,
  TOUCH_POINT_TYPE_SUBMITTED_QUESTIONS = 45,
  TOUCH_POINT_TYPE_LISTING_QUESTION_ANSWERED = 50
}
