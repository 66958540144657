import { getModalState } from '@/src/state/globalModal/selectors';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { resetUserPassword } from '@/src/providers/users';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { ERROR_MESSAGES } from '@/src/constants';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { ExistingUserRegisterAccount } from '@/src/state/tracking/fullstory/customEvents/register';

export const ExistingAccountResetPassword = () => {
  const dispatch = useAppDispatch();
  const {
    modalProps: { existingAccountResetPassword }
  } = useAppSelector(getModalState);
  const [loading, setLoading] = useState(false);
  const { openSuccessMessage, openErrorAlert } = useGlobalAlertBanner();
  const { fullStoryEvent } = useFullStory();
  if (!existingAccountResetPassword) return null;

  const sendEmail = () => {
    fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_SUBMITTED, { email: existingAccountResetPassword.email });
    setLoading(true);
    resetUserPassword({ email: existingAccountResetPassword.email })
      .then(() => {
        dispatch(closeModal());
        openSuccessMessage('Email sent successfully');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        captureException(e);
        openErrorAlert(ERROR_MESSAGES.REFRESH);
      });
  };

  const onClose = () => {
    fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_DISMISSED, { email: existingAccountResetPassword.email });
    dispatch(closeModal());
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle variant="h6">Claim your account.</DialogTitle>
      <DialogContent sx={{ maxWidth: '600px' }}>
        <DialogContentText variant="body2">
          {/* TODO: translations */}
          It appears an account with this email already exists. Click the Send Email button below to receive a link that will allow you to
          set a password and access your account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="contained" color="primary" onClick={sendEmail}>
          {/* TODO: translations */}
          Send Email
        </Button>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {/* TODO: translations */}
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
