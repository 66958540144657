import { DarkBlueButton, MagentaButton, WhiteButton } from '../../../bits/buttons';
import { MobileSuggestedItemsModalState, ModalStep, WorkflowModalButton } from '../../../state/mobileSuggestedItemsModal/types';
import { ModalButtonColors } from './types';

/**
 * TODO: #184791304 -  Once all the new buttons are in, replace the return value of this function
 * with MoovButtonVariant values and rename the function to findButtonVariant
 */
export const findButton = ({ buttonColor }: { buttonColor?: ModalButtonColors } = {}) => {
  switch (buttonColor) {
    case ModalButtonColors.DarkBlue:
      return DarkBlueButton;
    case ModalButtonColors.Magenta:
      return MagentaButton;
    case ModalButtonColors.White:
      return WhiteButton;
    default:
      return MagentaButton;
  }
};

export const getCurrentForm = (state: MobileSuggestedItemsModalState) => {
  if (state.props.steps) {
    const form = state.props.steps[state.currentStep].formValues;
    if (form) {
      return form;
    }
  }
  return {};
};

export const isEitherButtonSubmitting = (buttonOne: WorkflowModalButton, buttonTwo?: WorkflowModalButton) =>
  buttonOne.submitting || buttonTwo?.submitting;

export const isButtonDisabled = (buttonOne: WorkflowModalButton, buttonTwo: WorkflowModalButton | undefined, step: ModalStep) => {
  const eitherIsSubmitting = isEitherButtonSubmitting(buttonOne, buttonTwo);
  const formValid = buttonOne.isFormValid ? buttonOne.isFormValid(step) : true;

  return !formValid || eitherIsSubmitting;
};
