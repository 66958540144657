export interface MagiqLinkData {
  type: MagiqPageType;
  payload: any;
  true_user?: boolean;
}

export enum MagiqPageType {
  DASHBOARD = 'dashboard',
  OPPORTUNITY = 'suggested',
  LISTING = 'listing'
}
