import * as yup from 'yup';
import { Localizer } from '../localization';
import { isMaxTwoDecimalPlaces } from '../utils';

export const getVintageSchema = (loc: Localizer) =>
  yup
    .number()
    .test(
      'should be 4 digits',
      loc.CreateListingMain.MustBeFourDigits,
      (value: number | undefined) => value === undefined || String(value).length === 4
    )
    .min(1940, loc.CreateListingMain.MustBeGreaterThan1939);

export const getPriceSchema = (loc: Localizer) =>
  yup
    .number()
    .min(0, loc.Forms.MustBeAPositiveNumber)
    .test('should not exceed 2 decimal places', loc.Forms.CannotBeMoreThanTwoDecimalPlaces, isMaxTwoDecimalPlaces);

export const getMakeModelSchema = (loc: Localizer) =>
  yup.number().when('model_not_listed', {
    is: true,
    then: (schema) => schema,
    otherwise: (schema) => schema.moreThan(0, 'Select a model').required(loc.Forms.ManufacturerModelIsRequired)
  });

export const getRawMakeSchema = () =>
  yup.string().when('model_not_listed', {
    is: true,
    then: (schema) => schema.required('Manufacturer is required'),
    otherwise: (schema) => schema
  });

export const getRawModelSchema = () =>
  yup.string().when('model_not_listed', {
    is: true,
    then: (schema) => schema.required('Model is required'),
    otherwise: (schema) => schema
  });

export const getQuantitySchema = (loc: Localizer) => yup.number().moreThan(0, loc.Forms.MustBeOneOrGreater);