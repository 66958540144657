import { SearchFlowType } from '@/src/marketplace/search/types';
import { ListingSearchSortValue } from '@/src/providers/listings/constants';

export enum FS__SearchResultClickType {
  Category = 'Category',
  Model = 'Model'
}

export enum FS__FilterClickType {
  SortBy = 'Sort By',
  HasPhotos = 'Has Photos',
  IsExclusive = 'Is Exclusive',
  Category = 'Category',
  Make = 'Make',
  Model = 'Model',
  Condition = 'Condition',
  WaferSize = 'Wafer Size',
  Vintage = 'Vintage'
}

export enum FS__CategoryType {
  Parent = 'Parent',
  Child = 'Child'
}

export interface FS__FilterValues {
  search_flow_type?: SearchFlowType;
  q?: string;
  category?: { id: number; name: string };
  makes?: { id: number; name: string }[];
  models?: { id: number; name: string }[];
  sort_by?: ListingSearchSortValue;
  has_photos?: boolean;
  is_exclusive?: boolean;
  condition?: { id: number; name: string }[];
  wafer_size?: { id: number; name: string }[];
  min_vintage?: number;
  max_vintage?: number;
}
