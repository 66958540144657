import type { FC } from 'react';

import { EmptyFileIcon, PhotosIcon } from '../../../bits/svgs/svgs';

interface MobileDropzoneUIProps {
  readonly fileType: 'image' | 'file';
}

export const MobileDropzoneUI: FC<MobileDropzoneUIProps> = (props) => {
  const { fileType } = props;

  const isImages = fileType === 'image';
  const isFiles = fileType === 'file';

  return (
    <div className="base-button gap-x-3 rounded border-2 border-gray-200 p-3.5 text-14spx lg:hidden">
      <div>
        {isImages && <PhotosIcon className="h-4.5 w-5" fill="#234454" />}
        {isFiles && <EmptyFileIcon className="h-5 w-4" fill="#234454" />}
      </div>
      <p className="font-lato">
        {isImages && 'ADD PHOTOS'}
        {isFiles && 'ADD DOCUMENTS'}
      </p>
    </div>
  );
};
