import { FormValidationHook } from '@/src/hooks/useFormValidation';
import { ContactFormValues } from '.';
import { useLocalizer } from '@/src/localization';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { LabeledPhoneField } from '@/src/design/bytes/labeledPhoneField';
import { ContactPreference } from '@/src/providers/leads/types';

interface ContactHandleFieldProps<T> {
  formValidationHook: FormValidationHook<T>;
}

export const ContactHandleField = function <T extends ContactFormValues>(props: ContactHandleFieldProps<T>) {
  const { formValidationHook } = props;
  const { formValues, setFieldValue, onFieldBlur, isFieldErrored, getFieldError } = formValidationHook;
  const loc = useLocalizer();

  switch (formValues.contact_preference) {
    case ContactPreference.KakaoTalk:
    case ContactPreference.LINE:
      // TODO: Translations #185801497 - Phone number/LINE account
      let label = formValues.contact_preference === ContactPreference.KakaoTalk ? loc.Forms.PhoneKakao : 'Phone number/LINE account';
      return (
        <LabeledTextField
          type="text"
          label={label}
          placeholder={label}
          value={formValues.contact_handle || ''}
          onChange={(e) => setFieldValue('contact_handle', e.target.value)}
          onBlur={() => onFieldBlur('contact_handle')}
          error={isFieldErrored('contact_handle')}
          helperText={getFieldError('contact_handle')}
          required
          size="medium"
        />
      );
    case ContactPreference.WhatsApp:
      // TODO: Translations #185801497 - Whatsapp Phone Number
      label = 'Whatsapp Phone Number';
      return (
        <LabeledPhoneField
          label={label}
          placeholder={label}
          value={formValues.contact_handle || ''}
          onChange={(value) => setFieldValue('contact_handle', value)}
          onBlur={() => onFieldBlur('contact_handle')}
          error={isFieldErrored('contact_handle')}
          helperText={getFieldError('contact_handle')}
          required
        />
      );
    default:
      return null;
  }
};
