import { StrengthGaugeStage } from '../bits/svgs/strengthGauge';
import { Listing2, isEmptyToolStatus } from './types';
import { colors } from '../design/designConstants';
import { Status } from './types';
import { ListingStrengthParams } from './hooks';

const _isExistingListing = (params: ListingStrengthParams) => {
  return Number(params.tool_status) >= 0 && !!params.photos;
};

export const calcListingStrength = (params: ListingStrengthParams) => {
  let percentage = 0;

  if (_isExistingListing(params)) {
    if (params.photos && params.photos.length >= 3) {
      percentage += 40;
    }

    if (!isEmptyToolStatus(Number(params.tool_status))) {
      percentage += 15;
    }
  }

  if (params.configuration || params.description) {
    percentage += 20;
  }

  if (params.wafer_size_values?.length) {
    percentage += 10;
  }

  if (params.vintage) {
    percentage += 5;
  }

  if (params.address?.country) {
    percentage += 5;
  }

  if (params.condition) {
    percentage += 5;
  }

  return percentage;
};

export const getListingStrengthColorsAndStage = (percentage: number): { hexColorCode: string; stage: StrengthGaugeStage } => {
  if (percentage <= 28)
    return {
      hexColorCode: colors.notification.hotRed[500],
      stage: 1
    };

  if (percentage <= 42) {
    return {
      hexColorCode: colors.logo.orange,
      stage: 2
    };
  }

  if (percentage <= 56) {
    return {
      hexColorCode: colors.logo.gold,
      stage: 4
    };
  }

  if (percentage <= 70) {
    return {
      hexColorCode: colors.logo.lime,
      stage: 4
    };
  }

  if (percentage <= 84) {
    return {
      hexColorCode: colors.logo.emerald,
      stage: 4
    };
  }

  return {
    stage: 5,
    hexColorCode: colors.crayolaGreen['500']
  };
};

type RowState = 'editable' | 'static' | 'null';

export const getInputState = (listing: Listing2, listingAttribute: keyof Listing2, isEditable: boolean): RowState => {
  if (listing.status === Status.Sold) {
    return 'static';
  } else if (listing[listingAttribute] || isEditable) {
    return 'editable';
  } else {
    return 'null';
  }
};
