import { AppState } from '@/src/store';
import { createSelector } from '@reduxjs/toolkit';
import { MessageThread } from './types';

export function getByIdAll(store: AppState) {
  return store.messages.byThreadKey;
}

export function getThreadKeyOrder(store: AppState) {
  return store.messages.byThreadKeyOrder;
}

export const getAllMessageThreads = createSelector([getByIdAll, getThreadKeyOrder], (byId, threadKeyOrder): MessageThread[] => {
  return threadKeyOrder.map((key) => MessageThread.anyToDto(byId[key])).filter((thread) => Boolean(thread.key));
});

export const getCurrentThreadKey = createSelector([(state: AppState) => state.messages.currentThreadKey], (currentThreadKey): string => {
  return currentThreadKey;
});

export const getCurrentThread = createSelector([getByIdAll, getCurrentThreadKey], (byId, currentThreadKey): MessageThread => {
  const thread = byId[currentThreadKey];
  return thread ? MessageThread.anyToDto(thread) : new MessageThread();
});

export const getMessagesLoading = createSelector(
  [(state: AppState) => state.messages.loading, (state: AppState) => state.messages.initialized, (state: AppState) => state.messages.error],
  (loading, initialized, error): { loading: boolean; initialized: boolean; error: boolean } => ({ loading, initialized, error })
);

export const getUnreadMessageCount = createSelector(
  [(state: AppState) => state.messages.unreadCount],
  (unreadCount): number => unreadCount
);
