import {
  listingConditionNameData,
  listingDefaultConditionName,
  ListingSearchSort,
  ListingSearchSortValue,
  UserListingsSortValue
} from './constants';
import type { DTOListing, DTOListingSummary, DTOSuggestedListing } from './dtos';
import { generateMoovLogoListingPhoto } from '../../marketplace/utils';
import { FetchUserSuggestedListingsParams, SearchListingsParams } from '.';
import { FindManyListingBySearchParams, FindManySuggestedListingParams } from './repo';
import { isEmpty, isPopulated, parseIntI } from '../../utils';
import {
  CategoryMetadata,
  Listing,
  ListingMetadata,
  ListingMetadataRow,
  ListingActionStatus,
  ListingSummary,
  LocationMetaData,
  MakeMetadata,
  ModelMetadata,
  PrivateMetaData,
  StatusMetaData,
  SuggestedListing
} from '../../entities/listing';
import { DTOUnconfirmedListing } from '../unconfirmedListings/dtos';
import { ToolStatus } from '@/src/equipment/types';

export const getListingConditionName = (dto: DTOListingSummary | DTOListing | DTOUnconfirmedListing): string => {
  return listingConditionNameData.get(dto.condition) ?? listingDefaultConditionName;
};

export const getFormattedListingProps = (dto: DTOListingSummary | DTOListing | DTOSuggestedListing) => {
  return {
    condition_name: getListingConditionName(dto),
    verified_at_ts: dto.verified_at_ts !== 0 ? dto.verified_at_ts : null,
    vintage: dto.vintage !== 0 ? dto.vintage : null,
    price: Number.parseFloat(dto.price),
    wafer_size_values: parseWaferSizeValues(dto.wafer_size)
  };
};

export const buildListingSummary = (dto: DTOListingSummary): ListingSummary => {
  const result = { ...dto, ...getFormattedListingProps(dto) };
  delete (result as any).wafer_size;

  return result;
};

export const buildListing = (dto: DTOListing): Listing => {
  const result = { ...dto, ...getFormattedListingProps(dto) };
  delete (result as any).wafer_size;
  result.tool_status = result.tool_status ?? result.operational_status;

  const hasLegacyStatus = result.tool_status < ToolStatus.INSTALLED_RUNNING;
  if (hasLegacyStatus) {
    result.tool_status = ToolStatus.NO_STATUS;
  }

  return result;
};

export const string2ListingActionStatus = (DTOStatus: string): ListingActionStatus => {
  switch (DTOStatus) {
    case 'rejected':
      return ListingActionStatus.rejected;
    case 'approved':
      return ListingActionStatus.approved;
    default:
      return ListingActionStatus.none;
  }
};

export const buildSuggestedListing = (dto: DTOSuggestedListing): SuggestedListing => {
  const listing = {
    ...dto,
    ...getFormattedListingProps(dto)
  };

  if (listing.photos.length === 0) {
    listing.photos = [generateMoovLogoListingPhoto(listing)];
  }

  return listing;
};

export const parseWaferSizeValues = (waferSize: string): number[] => {
  if (waferSize === '') {
    return [];
  }

  // -- parse numbers from wafer_sizes string
  const waferSizes = new Set<number>();
  for (const sizeStr of waferSize.split(',')) {
    const size = Number.parseInt(sizeStr);
    // -- todo #182884832 log on parse error?
    // -- skip invalid wafer sizes
    if (!Number.isNaN(size)) {
      waferSizes.add(size);
    }
  }

  // -- sort
  return [...waferSizes.values()].sort((a, b) => a - b);
};

/**
 *
 */
export const stringifyWaferSizeValue = (values: number[] | undefined): string => {
  if (typeof values === 'undefined' || values.length === 0) {
    return '';
  }

  return [...new Set(values)].sort((a, b) => a - b).join(',');
};

/**
 *
 */
export const isListingSearchSortValue = (value: string | undefined): value is ListingSearchSortValue => {
  return (
    typeof value !== 'undefined' &&
    [ListingSearchSort.VERIFIED, ListingSearchSort.VINTAGE, ListingSearchSort.PHOTOS].includes(value as ListingSearchSortValue)
  );
};

/**
 *
 */
export const isMyListingsSortValue = (value: string | undefined): value is UserListingsSortValue => {
  return typeof value !== 'undefined' && ['newest', 'oldest', 'price_hl', 'price_lh', 'location_az', 'location_za'].includes(value);
};

export const removeZeroValue = (
  value: string | number | (string | number)[] | undefined
): string | number | (string | number)[] | undefined => {
  const zeroValues = [0, '0'];
  if (typeof value === 'undefined') {
    return value;
  } else if (Array.isArray(value)) {
    value = value.filter((item) => !zeroValues.includes(item));
    return value;
  } else if (zeroValues.includes(value)) {
    return undefined;
  }
  return value;
};

// TODO: #5129965 Tech Debt Refactor
export const convertNextParamsToApiParams = (params: SearchListingsParams): FindManyListingBySearchParams => {
  const convertedParams: FindManyListingBySearchParams = {};

  // q
  if (isPopulated(params.q)) {
    convertedParams.q = params.q;
  }
  if (params.sortByColumn ?? false) {
    if (params.sort) {
      convertedParams['order'] = [params.sort];
    }
  } else {
    // order
    switch (params.order) {
      case ListingSearchSort.VERIFIED:
        convertedParams['order'] = ['verified_at_ts desc'];
        break;
      case ListingSearchSort.VINTAGE:
        convertedParams['order'] = ['vintage desc'];
        !isPopulated(params.q) && convertedParams['order'].push('photo_count gte 1 desc', 'verified_at_ts desc');
        break;
      case 'newest':
        convertedParams['order'] = ['created_at desc'];
        break;
      case 'oldest':
        convertedParams['order'] = ['created_at asc'];
        break;
      case 'price_hl':
        // id is used to keep the ordering consistent when applying OFFSET in Postgres in case we have multiple rows with the same price
        isPopulated(params.q)
          ? (convertedParams['order'] = ['price.keyword desc'])
          : (convertedParams['order'] = ['price desc', 'id desc']);
        break;
      case 'price_lh':
        // id is used to keep the ordering consistent when applying OFFSET in Postgres in case we have multiple rows with the same price
        isPopulated(params.q) ? (convertedParams['order'] = ['price.keyword asc']) : (convertedParams['order'] = ['price asc', 'id desc']);
        break;
      case 'location_az':
        // id is used to keep the ordering consistent when applying OFFSET in Postgres in case we have multiple rows with the same location
        isPopulated(params.q)
          ? (convertedParams['order'] = ['location.keyword asc'])
          : (convertedParams['order'] = [`location asc`, 'id desc']);
        break;
      case 'location_za':
        // id is used to keep the ordering consistent when applying OFFSET in Postgres in case we have multiple rows with the same location
        isPopulated(params.q)
          ? (convertedParams['order'] = ['location.keyword desc'])
          : (convertedParams['order'] = ['location desc', 'id desc']);
        break;
      case ListingSearchSort.PHOTOS:
      default:
        convertedParams['order'] = ['photo_count desc'];
        !isPopulated(params.q) && convertedParams['order'].push('verified_at_ts desc');
        break;
    }
  }
  if (convertedParams['order'] && !convertedParams['order'].includes('id desc')) {
    convertedParams['order'] = convertedParams['order'].concat('id desc');
  } else {
    convertedParams['order'] = ['id desc'];
  }

  // has_photos
  if (params.has_photos === true) {
    convertedParams['gte:photo_count'] = 1;
  }

  if (params.is_exclusive) {
    convertedParams.is_exclusive = 1;
  }

  // vintage
  if (isPopulated(params.min_vintage) && isPopulated(params.max_vintage)) {
    convertedParams['between:vintage'] = `${params.min_vintage}|${params.max_vintage}`;
  } else if (isPopulated(params.min_vintage) && !isPopulated(params.max_vintage)) {
    convertedParams['gte:vintage'] = params.min_vintage;
  } else if (!isPopulated(params.min_vintage) && isPopulated(params.max_vintage)) {
    convertedParams['lte:vintage'] = params.max_vintage;
  }

  // category_id
  params.category_id = removeZeroValue(params.category_id);
  if (isPopulated(params.category_id)) {
    convertedParams.category_id = params.category_id;
  }
  // make_id
  params.make_id = removeZeroValue(params.make_id);
  if (isPopulated(params.make_id)) {
    convertedParams.make_id = params.make_id;
  }
  // model_id
  params.model_id = removeZeroValue(params.model_id);
  if (isPopulated(params.model_id)) {
    convertedParams.model_id = params.model_id;
  }
  // limit
  if (isPopulated(params.limit)) {
    convertedParams.limit = params.limit;
  }
  // offset
  if (isPopulated(params.offset)) {
    convertedParams.offset = params.offset;
  }
  // autocomplete
  if (typeof params.autocomplete !== 'undefined') {
    convertedParams.autocomplete = params.autocomplete;
  }

  // condition
  if (Array.isArray(params.condition)) {
    convertedParams.condition = params.condition;
  } else {
    if (isPopulated(params.condition)) {
      const parsedCondition = parseIntI(params.condition);
      convertedParams.condition = parsedCondition == 1 ? [0, 1] : [parsedCondition];
    }
  }

  // wafer_size
  if (Array.isArray(params.wafer_size)) {
    isPopulated(params.q) ? (convertedParams.wafer_size_arr = params.wafer_size) : (convertedParams['set:wafer_size'] = params.wafer_size);
  }

  // category
  if (!isEmpty(params.category_id)) {
    convertedParams.category_id = params.category_id;
  }

  // make
  if (!isEmpty(params.make_id)) {
    convertedParams.make_id = params.make_id;
  }

  // model
  if (!isEmpty(params.model_id)) {
    convertedParams.model_id = params.model_id;
  }

  // private
  if (params.private) {
    if (params.private === 'private') {
      convertedParams.private = [1];
    } else if (params.private === 'public') {
      convertedParams.private = [0];
    }
  }

  // location
  if (params.location && params.location.length > 0) {
    convertedParams['location.keyword'] = params.location;
  }

  // status - active/disabled
  if (!isEmpty(params.status)) {
    convertedParams.status = [];
    if (params.status?.includes('active')) {
      convertedParams.status?.push(100, 101, 102, 103, 104, 111, 141, 142, 143);
    }
    if (params.status?.includes('disabled')) {
      convertedParams.status?.push(200);
    }
    if (params.status?.includes('sold')) {
      convertedParams.status?.push(201);
    }
  }

  return convertedParams;
};

/**
 * @param metadata
 */
export const cleanMetadata = (metadata: ListingMetadataRow[]): ListingMetadata => {
  if (!metadata || metadata.length === 0) {
    return {
      location: [],
      category: [],
      make: [],
      model: [],
      private: [],
      status: []
    };
  }
  const locationMetadata: LocationMetaData[] = [];
  const uniqueLocations = new Set<string>();
  const categoryMetadata: CategoryMetadata[] = [];
  const uniqueCategories = new Set<string>();
  const makeMetadata: MakeMetadata[] = [];
  const uniqueMakes = new Set<string>();
  const modelMetadata: ModelMetadata[] = [];
  const uniqueModels = new Set<string>();
  const privateMetadata: PrivateMetaData[] = [];
  const uniquePrivate = new Set<string | number>();
  const statusMetadata: StatusMetaData[] = [];
  const uniqueStatus = new Set<string | number>();
  metadata.forEach((row: ListingMetadataRow) => {
    if (!uniqueLocations.has(row.location)) {
      locationMetadata.push({ location: row.location, count: row.location_count });
      uniqueLocations.add(row.location);
    }
    if (!uniqueCategories.has(row.category_name)) {
      categoryMetadata.push({ name: row.category_name, count: row.category_count, id: row.category_id });
      uniqueCategories.add(row.category_name);
    }
    if (!uniqueMakes.has(row.make_name)) {
      makeMetadata.push({ name: row.make_name, count: row.make_count, id: row.make_id });
      uniqueMakes.add(row.make_name);
    }
    if (!uniqueModels.has(row.model_name)) {
      modelMetadata.push({ name: row.model_name, count: row.model_count, id: row.model_id });
      uniqueModels.add(row.model_name);
    }
    if (!uniquePrivate.has(row.private)) {
      privateMetadata.push({ private: row.private, count: row.private_count });
      uniquePrivate.add(row.private);
    }
    if (!uniqueStatus.has(row.status)) {
      statusMetadata.push({ status: row.status, count: row.status_count });
      uniqueStatus.add(row.status);
    }
  });

  locationMetadata.sort((a, b) => b.count - a.count);
  categoryMetadata.sort((a, b) => b.count - a.count);
  makeMetadata.sort((a, b) => b.count - a.count);
  modelMetadata.sort((a, b) => b.count - a.count);

  const listingMetaData = {
    location: locationMetadata,
    category: categoryMetadata,
    make: makeMetadata,
    model: modelMetadata,
    private: privateMetadata,
    status: statusMetadata
  };

  return listingMetaData;
};

export const convertSuggestedNextParamsToApiParams = (params: FetchUserSuggestedListingsParams): FindManySuggestedListingParams => {
  const convertedParams: FindManySuggestedListingParams = {};

  // has_photos
  if (params.has_photos === true) {
    convertedParams['gte:photo_count'] = 1;
  }
  // is_seen
  if (params.is_seen === false) {
    convertedParams['_c:seen'] = 0;
  }
  // bionic_message_id
  if (isPopulated(params.bionic_message_id)) {
    convertedParams['_c:bionic_message_id'] = params.bionic_message_id;
  }
  // vintage
  if (isPopulated(params.min_vintage)) {
    convertedParams['gte:vintage'] = params.min_vintage;
  }
  // model_id
  params.model_id = removeZeroValue(params.model_id);
  if (isPopulated(params.model_id)) {
    convertedParams.model_id = params.model_id;
  }
  // limit
  if (isPopulated(params.limit)) {
    convertedParams.limit = params.limit;
  }
  // offset
  if (isPopulated(params.offset)) {
    convertedParams.offset = params.offset;
  }
  // // condition
  // if (isPopulated(params.condition)) {
  //   const parsedCondition = parseIntI(params.condition);
  //   convertedParams.condition = parsedCondition == 1 ? [0, 1] : [parsedCondition];
  // }
  // wafer_size
  if (Array.isArray(params.wafer_size)) {
    convertedParams['set:wafer_size'] = params.wafer_size;
  }
  // condition
  if (Array.isArray(params.condition)) {
    convertedParams.condition = params.condition;
  }
  // opportunity_status
  if (Array.isArray(params.opportunity_status)) {
    convertedParams['opportunities.status'] = params.opportunity_status;
  }
  // model
  if (!isEmpty(params.model_id)) {
    convertedParams.model_id = params.model_id;
  }

  return convertedParams;
};
