import { FC, useState } from 'react';

import { ContentWrapper } from '../contentWrappers';
import { NavigationSection } from './navigationSection';
import { InfoSection } from './infoSection';
import { useSidebar } from '../../hooks/useSidebar';
import { SocialAndLanguageSection } from './socialAndLanguageSection';
import { useUser } from '../../hooks/useUser';

/**
 *
 */
const Footer: FC = () => {
  const { visible } = useSidebar();
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const { user, userIsLoggedIn } = useUser();
  const loggedIn = userIsLoggedIn(user);

  return (
    <>
      {modal}

      <footer className={`relative bg-moov-blue-500 pt-6 text-white lg:py-10 lg:pt-page-t-desktop ${visible ? 'pb-22' : 'pb-6'}`}>
        <ContentWrapper
          className={`flex flex-col-reverse font-light tracking-wide lg:grid lg:gap-6 ${
            loggedIn ? 'lg:grid-cols-[3fr_4fr]' : 'lg:grid-cols-[3fr_5fr_1.5fr]'
          }`}
        >
          <InfoSection setModal={setModal} />
          <NavigationSection />
          {!loggedIn && <SocialAndLanguageSection />}
        </ContentWrapper>
      </footer>
    </>
  );
};

export default Footer;
