import { colors } from '@/src/design/designConstants';
import { BuyerOffer, ReadableOfferStatus } from '../types';

export const getOfferAmountColor = (buyerOffer: BuyerOffer) =>
  buyerOffer.offer.readable_status === ReadableOfferStatus.ACCEPTED
    ? colors.crayolaGreen['500']
    : buyerOffer.offer.readable_status === ReadableOfferStatus.DECLINED
    ? colors.notification.hotRed['500']
    : ReadableOfferStatus.EXPIRED === buyerOffer.offer.readable_status
    ? colors.orange['500']
    : colors.crayolaGreen['500'];

export const getExpireDaysColor = (buyerOffer: BuyerOffer) =>
  buyerOffer.offer.readable_status === ReadableOfferStatus.ACCEPTED
    ? colors.crayolaGreen['500']
    : buyerOffer.offer.readable_status === ReadableOfferStatus.DECLINED
    ? colors.notification.hotRed['500']
    : ReadableOfferStatus.EXPIRED === buyerOffer.offer.readable_status
    ? colors.orange['500']
    : colors.magenta['500'];
