import { useAppSelector } from '@/src/store';
import { GTMEvent } from '../../integrations/googleTagManager/types';
import { getCurrentUser } from '@/src/state/user/selectors';

export const useGTM = () => {
  const user = useAppSelector(getCurrentUser);

  const trackGTMEvent = (event: GTMEvent) => {
    if (typeof window !== 'undefined') {
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
      }
      if (user?.id && event.event !== 'updateUserId') {
        event.user_id = user.id;
        event.moov_custom_id = user.id;
        event.moov_user_url = user.id;
      } else {
        user?.id && (event.user_id = user.id);
      }
      window.dataLayer.push(event);
    }
  };

  return {
    trackGTMEvent
  };
};
