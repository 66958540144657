import { FC } from 'react';

import { CheckboxByte } from '@/src/design/bytes/checkbox';

import { SubProps } from '..';
import { useLocalizer } from '@/src/localization';

export const ModelNotFound: FC<SubProps> = ({ state, dispatch }) => {
  const loc = useLocalizer();
  const setModelNotListed = (checked: boolean) => {
    dispatch({
      type: 'setModelNotListed',
      value: checked
    });
  };

  return <CheckboxByte checked={state.modelNotListed} onChange={setModelNotListed} label={loc.Forms.MyModelIsNotListed} />;
};
