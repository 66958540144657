import translations from './howToSell.loc.json';
import { getDefinitions, Locale } from '../../localization';

/**
 *
 */
export class HowToSelLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get Title(): string {
    return this.definitions.Title;
  }

  public get SubTitle(): string {
    return this.definitions.SubTitle;
  }
}
