import { ChangeEventHandler, FC } from 'react';

import { SubProps } from '..';
import { useLocalizer } from '@/src/localization';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';

export const MinSellPrice: FC<SubProps> = ({ state, dispatch }) => {
  const loc = useLocalizer();
  const setMinSellPrice: ChangeEventHandler<HTMLInputElement> = (e) => {
    const rawValue = e.target.value.trim();
    let outValue;
    if (!rawValue.length) {
      outValue = undefined;
    } else {
      outValue = parseInt(rawValue, 10);
    }
    dispatch({
      type: 'setMinSellPrice',
      value: outValue || ''
    });
  };

  return (
    <LabeledCurrencyInput
      label={loc.EquipmentManage.MinSellPriceDot}
      placeholder="0"
      value={state.minSellPrice ? state.minSellPrice.toString() : ''}
      onChange={setMinSellPrice}
    />
  );
};
