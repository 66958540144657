import * as ModelsRepo from './repo';
import type { FindManyParams } from '../types';
import { Model } from '../../entities/model';

export interface FetchModelsParams extends FindManyParams {
  readonly category_id?: number | string;
  readonly make_id?: number | string;
}

/**
 *
 */
export const fetchModels = async (params: FetchModelsParams): Promise<Model[]> => {
  return ModelsRepo.findManyModel(params);
};

interface SearchModelsParams extends FindManyParams {
  readonly q?: string;
}

/**
 * TODO: see if we can delete
 * @deprecated use new searchmodels thunk instead
 */
export const searchModels = async (params: SearchModelsParams): Promise<Model[]> => {
  return ModelsRepo.findManyModel(params);
};

/**
 *
 */
export const fetchModel = async (modelSlugOrId: string | number): Promise<Model> => {
  return ModelsRepo.findOneModel(modelSlugOrId);
};

export const fetchUserSuggestedModels = async (): Promise<Model[]> => {
  return ModelsRepo.findManyUserSuggestedModels();
};
