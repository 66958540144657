export enum GlobalAlertBannerType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

//Global banner durations will be set in milliseconds in the Banner component
export enum GlobalBannerDurations {
  DEFAULT = 30,
  SHORT = 5
}
