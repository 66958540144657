import { FC } from 'react';

import { Condition } from './inputs/ConditionInput';
import { Location } from './inputs/LocationInput';
import { MinSellPrice } from './inputs/MinSellPriceInput';
import { ModelNotFound } from './inputs/ModelNotFound';
import { ModelSearch } from './inputs/ModelSearchInput';
import { Quantity } from './inputs/QuantityInput';
import { RawManufacturer } from './inputs/RawManufacturerInput';
import { RawModel } from './inputs/RawModelInput';
import { SerialNumber } from './inputs/SerialNumberInput';
import { Vintage } from './inputs/VintageInput';
import { WaferSizes } from './inputs/WaferSizesInput';
import { ToolStatus } from './inputs/ToolStatusInput';
import { hasModelSet } from './state';

import { SubProps } from '.';
import { CustomFields } from './inputs/CustomFields/CustomFields';
import { Box, Divider, Stack } from '@mui/material';
import { spacing } from '@/src/design/designConstants';

export const DetailsTab: FC<SubProps> = (props) => {
  return (
    // TODO: ENG-93 - Replace hard-coded height and width with reuseable
    // spacing constants for modal sizes
    <Box height={{ d: '640px' }} width={{ d: '600px' }} p={spacing['3xl']}>
      <Stack rowGap={spacing.xl}>
        <ModelSearch {...props} />
        <RawManufacturer {...props} />
        <RawModel {...props} />
        <ModelNotFound {...props} />
      </Stack>

      <Divider variant="form" sx={{ my: spacing['3xl'] }} />

      {hasModelSet(props.state) && (
        <Stack rowGap={spacing.xl}>
          <Location {...props} />
          <ToolStatus {...props} />
          <MinSellPrice {...props} />
          <Condition {...props} />
          <WaferSizes {...props} />
          <Vintage {...props} />
          <SerialNumber {...props} />
          <Quantity {...props} />
          <CustomFields {...props} />
        </Stack>
      )}
    </Box>
  );
};
