import { AppState } from '@/src/store';
import { createSelector } from '@reduxjs/toolkit';

import { Wanted2 } from './types';
import { toRecord } from '@/src/state/services';
import { WaferSize } from '../common/lib';

export function getByIdAll(store: AppState) {
  return store.requests2.byId;
}

export function getCurrentId(store: AppState) {
  return store.requests2.currentId;
}

// use createSelector to automatically memoize the result of this selector and prevent unnecessary re-renders
export const getAll = createSelector([getByIdAll], (byId) => {
  return Object.values(byId).map((e) => Wanted2.anyToDto(e));
});

export const getById = createSelector([getByIdAll, (_, id) => id], (byIdAll, id) => {
  return byIdAll[id];
});

export const getCurrent = createSelector([getCurrentId, getByIdAll], (currentId, byId) => {
  let r: Wanted2 = new Wanted2();
  if (currentId) {
    const s = byId[currentId];
    if (s) {
      r = Wanted2.anyToDto(s);
    }
  }
  return r;
});

export const isWantedSelected = createSelector([getCurrentId], (currentId) => {
  return !!currentId;
});

export const getByIdCount = createSelector([getByIdAll], (byId) => {
  return Object.keys(byId).length;
});

export const getUniqueMakes = createSelector(getAll, (wanted) => {
  return Object.values(toRecord(wanted.map((e) => e.make).filter((e) => e.name)));
});

export const getUniqueModels = createSelector(getAll, (wanted) => {
  return Object.values(toRecord(wanted.map((e) => e.model).filter((e) => e.name)));
});

export const getUniqueConditions = createSelector(getAll, (wanted) => {
  return [...new Set(wanted.map((e) => e.condition).filter((e) => e))];
});

export const getUniqueLocations = createSelector(getAll, (wanted) => {
  return [...new Set(wanted.map((e) => e.location).filter((e) => e))];
});

export const getUniqueCategories = createSelector(getAll, (wanted) => {
  return Object.values(toRecord(wanted.map((e) => e.category).filter((e) => e.name)));
});

export const getUniqueWaferSizes = createSelector(getAll, (wanted) => {
  return [...new Set(wanted.map((e) => e.wafer_size_values).flat())] as WaferSize[];
});

// use createSelector to automatically memoize the result of this selector and prevent unnecessary re-renders
export const getLoadingState = createSelector(
  [(state: AppState) => state.requests2.initialized, (state: AppState) => state.requests2.loading],
  (initialized, loading) => {
    return { initialized, loading };
  }
);
