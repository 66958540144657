import { useState } from 'react';
import { EquipmentDropzoneFile, EquipmentFileDropzoneFileType, isEquipmentDropzoneFile } from '../index';
import { CreateEquipmentPhotoUpload } from './photoTypes';
import { EquipmentSerializable } from '@/src/equipment/types';
import { disableEquipmentPhotos, saveEquipmentPhotos, uploadEquipmentPhotos } from './photoUtils';
import { useAppDispatch } from '@/src/store';
import { fetchById } from '@/src/equipment/thunks';

export const useUploadEquipmentPhotos = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onPhotosUpdateSubmit = async (
    files: EquipmentFileDropzoneFileType[],
    filesToDelete: EquipmentFileDropzoneFileType[],
    equipment: EquipmentSerializable
  ) => {
    setLoading(true);
    const newPhotos: EquipmentDropzoneFile[] = files.filter(isEquipmentDropzoneFile);

    const newPhotosData: CreateEquipmentPhotoUpload[] = newPhotos.map((photo) => {
      return {
        name: photo.name,
        type: photo.type
      };
    });

    try {
      // DELETE PHOTOS
      if (filesToDelete.length > 0) {
        await disableEquipmentPhotos(equipment.id, { Photos: filesToDelete });
      }

      // UPLOAD PHOTOS
      const uploadedPhotos = await uploadEquipmentPhotos(equipment.id, newPhotosData, newPhotos);

      // TODO #182881550 this is disgusting. make it less disgusting.
      const photosAndUploadUrls = files.map((photo) => {
        if (isEquipmentDropzoneFile(photo)) {
          return { ...photo, url: uploadedPhotos.shift() || '' };
        }
        return photo;
      });

      if (photosAndUploadUrls.length > 0) {
        await saveEquipmentPhotos(equipment.id, { Photos: photosAndUploadUrls });
      }

      dispatch(fetchById(equipment.id.toString()));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  return {
    onPhotosUpdateSubmit,
    loading
  };
};
