/* eslint-disable @next/next/no-img-element */
import { FC, useState } from 'react';
import { EquipmentFileDropzoneFileType } from './index';
import { getImageSrc, hasDroppedPhotos } from './utils';
import { useLocalizer } from '@/src/localization';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Delete, ZoomIn } from '@mui/icons-material';
import { ImageList, ImageListItem, Stack } from '@mui/material';
import ImageGalleryModal from '@/src/bits/modals/imageGalleryModal';
import { changeFilesToPhotos, isPhoto } from '@/src/entities/common';
import { Button } from '@mui/material';

interface DroppedFilesProps {
  fileType: 'image' | 'file';
  files: EquipmentFileDropzoneFileType[];
  disabled?: boolean;
  disableControls?: boolean;
  removeFile: (i: number) => void;
  moveFileToFirst: (i: number) => void;
  photoGallery?: boolean;
}

export const DroppedPhotos: FC<DroppedFilesProps> = ({
  fileType,
  files,
  disabled,
  disableControls,
  moveFileToFirst,
  removeFile,
  photoGallery
}) => {
  const loc = useLocalizer();
  const [modal, setModal] = useState<JSX.Element | null>(null);

  //TODO: Story to rewrite the modal #185987195. Don't repeat this pattern.
  function openModal(i: number) {
    setModal(<ImageGalleryModal photos={changeFilesToPhotos(files)} alt={'altText'} initialIndex={i} setModal={setModal} />);
  }

  return (
    <>
      {/* DROPPED PHOTOS */}
      {modal}
      {hasDroppedPhotos(fileType, files) && (
        <ImageList cols={4} rowHeight={95} gap={12}>
          {files.map((file, i) => (
            <ImageListItem
              sx={{
                borderRadius: '5px',
                pointerEvents: disabled ? 'none' : 'visible',
                '&:hover .equipment-file-dropzone-photo-buttons': { visibility: 'visible' },
                '&:hover .equipment-file-dropzone-photo': { opacity: 0.5 }
              }}
              key={getImageSrc(file)}
            >
              <Stack direction="row" height="100%" width="100%">
                <img
                  className="equipment-file-dropzone-photo"
                  style={{ borderRadius: '5px', height: '100%', width: '100%', objectFit: 'cover' }}
                  src={getImageSrc(file)}
                  alt={file.name}
                />
                {!disableControls && (
                  <Stack
                    className="equipment-file-dropzone-photo-buttons"
                    direction="column"
                    visibility="hidden"
                    spacing={0.5}
                    height="100%"
                    width="100%"
                    position="absolute"
                    justifyContent="center"
                    alignItems="center"
                    padding="0px 15px 0px 15px"
                  >
                    <Stack direction="row" spacing={0.5} width="100%" justifyContent="center">
                      {i > 0 && (
                        <Button
                          sx={{ width: '75%' }}
                          color="secondary"
                          aria-label={loc.Forms.MoveFileToFirst}
                          onClick={() => moveFileToFirst(i)}
                        >
                          <KeyboardDoubleArrowLeftIcon />
                        </Button>
                      )}
                      <Button sx={{ width: '25%' }} color="error" aria-label={loc.Forms.RemoveFile} onClick={() => removeFile(i)}>
                        <Delete />
                      </Button>
                    </Stack>
                    {photoGallery && !!isPhoto(files[i]) && (
                      <Button color="info" variant="outlined" sx={{ width: !i ? '75%' : '100%' }} onClick={() => openModal(i)}>
                        <ZoomIn />
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  );
};
