import { DropDownOption } from '../../bits/forms/fields/dropdowns/types';
import { ListingStatus } from '../../entities/listing';
import { ListingOrderByField } from '../../providers/listings/repo';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { Status } from '../types';

export enum FILTER_KEYS {
  CATEGORIES = 'categories',
  CONDITIONS = 'conditions',
  // ENG-1878 put this filter back filtering on address country
  // LOCATIONS = 'locations',
  MAKES = 'makes',
  MODELS = 'models',
  WAFER_SIZES = 'waferSizes',
  STATUS = 'status'
}

export const STATUSES: DropDownOption[] = [
  { id: ListingStatus.ACTIVE.toString(), label: 'Listed' },
  { id: ListingStatus.DISABLED.toString(), label: 'Unlisted' }
];

export enum SORTABLE_LISTING_FIELDS {
  make = 'make',
  toolStatus = 'tool_status',
  model = 'model',
  condition = 'condition',
  category = 'category',
  waferSize = 'wafer_size_values',
  price = 'price',
  quantity = 'quantity',
  vintage = 'vintage',
  serialNumber = 'serial_number',
  createdAt = 'created_at',
  verifiedAt = 'verified_at_ts',
  location = 'location'
}
// ***** functions *****
/**
 * creates the value passed to the order query string param for Moov search apis
 * Single column sort only
 * return null to exclude order from the param list i.e. 'photos' sorting
 * @param {SortingState} arg - Support for single column sort only
 * @returns {string} - value for order query string param
 */

export function sortingStateToOrderParam(arg: GridSortModel): ListingOrderByField | null {
  let result = null;
  /* E.G. sort by category asc listings?order=categories.name asc
          sort by model desc listings?order=models.name desc */
  if (arg.length > 0) {
    const { field, sort } = arg[0];
    const direction: 'asc' | 'desc' = sort ? sort : 'asc';
    const supportedFields: { id: string; field: ListingOrderByField }[] = [
      { id: SORTABLE_LISTING_FIELDS.category, field: `categories.name ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.condition, field: `condition ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.createdAt, field: `created_at ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.location, field: `location ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.make, field: `makes.name ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.toolStatus, field: `tool_status ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.model, field: `models.name ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.price, field: `price ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.quantity, field: `quantity ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.serialNumber, field: `serial_number ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.verifiedAt, field: `verified_at_ts ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.vintage, field: `vintage ${direction}` },
      { id: SORTABLE_LISTING_FIELDS.waferSize, field: `wafer_size ${direction}` }
    ];
    const sf = supportedFields.find((e) => e.id.toLowerCase() === field.toLowerCase());
    if (sf) result = sf.field;
  }
  return result;
}

export interface StatusToParamResult {
  status: string[];
}

/**
 * description: creates the correct api params based on selected status filter
 * @param arg {DropDownOption[]}
 * @return {StatusToParamResult}
 *  */
export const statusToParam = (arg: string[]): StatusToParamResult => {
  let status: string[] = [];

  if (arg.some((statusFilter) => statusFilter === 'ALL_STATUSES')) return { status: ['active', 'disabled', 'sold'] };
  if (arg.some((statusFilter) => statusFilter === Status.Active.toString())) status.push('active');
  if (arg.some((statusFilter) => statusFilter === Status.Sold.toString())) status.push('sold');
  if (arg.some((statusFilter) => statusFilter === Status.Disabled.toString())) status.push('disabled');

  //No status filter set, return all statuses
  if (status.length === 0) status = ['active', 'disabled', 'sold'];

  return {
    status
  };
};
