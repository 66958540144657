import { DropDownOption } from '../bits/forms/fields/dropdowns/types';
import { AnsweredConfigQuestion } from '../createListing/useCreateListingForm';
import { ToolStatus } from '../equipment/types';
import { ListingCategorySummary } from './category';
import type { BooleanValue, SimpleDateFormat, Slug, StatusValue, UnixTimestamp, Url } from './common';
import type { ListingFile } from './listingFile';
import { ListingPhoto } from './listingPhoto';
import { MakeSummary } from './make';
import { ModelSummary } from './model';

// -- #results
// -----------------------------------------------

export interface ListingAttribute {
  readonly description: string;
  readonly id: number;
  readonly name: string;
  readonly slug: Slug;
}

export interface ListingSummary {
  readonly id: number;
  readonly key: string;
  readonly status: StatusValue;
  readonly private: BooleanValue;
  readonly location: string;
  readonly verified_at_ts: UnixTimestamp | null;
  readonly condition: StatusValue;
  readonly condition_name: string;
  readonly vintage: number | null;
  // -- `price` set to 0 if listing is not owned
  // WARNING: price is actually coming back as a string from the API
  readonly price: number | 0;
  readonly quantity: number;
  readonly created_at: SimpleDateFormat;
  readonly category: ListingAttribute;
  readonly make: ListingAttribute;
  readonly model: ListingAttribute;
  readonly photo?: ListingPhoto;
  readonly wafer_size_values: number[];
  readonly disabled: BooleanValue;
  remarketing_slug?: string;
  marketing_price: string | '0';
}

export interface Listing extends ListingSummary {
  // -- `serial_number` set to "" if listing is not owned
  readonly serial_number: string | '';
  readonly tool_status: ToolStatus;
  readonly description: string;
  readonly configuration: string;
  readonly updated_at: SimpleDateFormat;
  readonly files: ListingFile[];
  readonly photos: ListingPhoto[];
  readonly question_count?: number;
}

export enum ListingActionStatus {
  rejected = 'rejected',
  approved = 'approved',
  none = 'none'
}

export interface SuggestedListing extends Listing {
  readonly opportunity_key: string;
  readonly buyer_seen_at_ts: number;
  readonly opportunity_status: string;
  readonly is_actionable: boolean;
  readonly marketing_price: string;
}

export const isSuggestedListing = (tryMe: unknown | Listing | SuggestedListing): tryMe is SuggestedListing => {
  return !!(tryMe as SuggestedListing)?.opportunity_key;
};

export const isListing = (arg: unknown): arg is Listing => {
  return (
    !!arg &&
    typeof arg === 'object' &&
    typeof (arg as Listing).key === 'string' &&
    typeof (arg as Listing).category === 'object' &&
    typeof (arg as Listing).make === 'object' &&
    typeof (arg as Listing).model === 'object'
  );
};

export interface CreateUnconfirmedListing {
  model_id?: number;
  raw_make?: string;
  raw_model?: string;
  readonly model_not_listed?: boolean;
  readonly serial_number?: string;
  readonly status?: StatusValue;
  readonly private?: BooleanValue;
  readonly condition?: StatusValue;
  readonly description?: string;
  readonly configuration?: string;
  readonly vintage?: number;
  readonly price?: number;
  readonly quantity?: number;
  readonly tool_status?: number;
  readonly wafer_size_values?: number[];
  readonly address: { country: string };
  readonly configQuestionAnswers?: AnsweredConfigQuestion[];
}

export interface CreateManyListingPhoto {
  readonly photos: (ListingPhoto | { url: Url })[];
}

export interface GenerateListingSummaryProps {
  id?: number;
  key?: string;
  status?: StatusValue;
  private?: BooleanValue;
  verified_at_ts?: UnixTimestamp | null;
  condition?: StatusValue;
  condition_name?: string;
  vintage?: number | null;
  price?: number;
  created_at?: SimpleDateFormat;
  category?: ListingCategorySummary;
  make?: MakeSummary;
  model?: ModelSummary;
  photo?: ListingPhoto;
  location?: string;
  quantity?: number;
}

export interface ListingMetadataRow {
  category_count: number;
  category_id: number;
  category_name: string;
  location: string;
  location_count: number;
  make_count: number;
  make_id: number;
  make_name: string;
  model_count: number;
  model_id: number;
  model_name: string;
  private: string | number;
  private_count: number;
  status: string | number;
  status_count: number;
}

/** @deprecated Use DropDownOption */
export interface LocationMetaData {
  location: string;
  count: number;
}

export interface PrivateMetaData {
  private: string | number;
  count: number;
}

export interface StatusMetaData {
  status: string | number;
  count: number;
}

/** @deprecated Use DropDownOption */
export interface CategoryMetadata {
  id: number;
  name: string;
  count: number;
}

/** @deprecated Use DropDownOption */
export interface MakeMetadata extends CategoryMetadata {}

/** @deprecated Use DropDownOption */
export interface ModelMetadata extends CategoryMetadata {}

export interface ListingMetadata {
  location: LocationMetaData[];
  category: CategoryMetadata[];
  make: MakeMetadata[];
  model: ModelMetadata[];
  private: PrivateMetaData[];
  status: StatusMetaData[];
}

export enum ListingStatus {
  ACTIVE = 100,
  DISABLED = 200,
  SOLD = 201,
  DISABLED_EXPIRED = 203,
  DELETED = 300
}

// TODO #185315532: replace legacy Listings feature and rename interface accordingly
export interface ListingMetadataV2 {
  categories: DropDownOption[];
  conditions: DropDownOption[];
  locations: DropDownOption[];
  makes: DropDownOption[];
  models: DropDownOption[];
  statuses: DropDownOption[];
  waferSizes: DropDownOption[];
}
