import type { FC } from 'react';
import { Required } from '../required';
import { calcFieldTextColor } from '../utils';

interface FieldLabelProps {
  readonly label?: string;
  readonly required?: boolean;
  readonly hasErrored?: boolean;
  readonly disabled?: boolean;
  readonly submitted?: boolean;
  readonly touched?: boolean;
  readonly validateOnTouchOrSubmit?: boolean;
}

export const FieldLabel: FC<FieldLabelProps> = (props) => {
  const { children, label, required, hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit } = props;
  const textColor = calcFieldTextColor('text-moov-blue-500', hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit);

  return (
    <label className="w-full">
      {label && (
        <p className={`mb-1.5 font-lato text-14spx font-bold tracking-wide ${textColor}`}>
          {label}
          <Required
            required={required}
            hasErrored={hasErrored}
            disabled={disabled}
            touched={touched}
            submitted={submitted}
            validateOnTouchOrSubmit={validateOnTouchOrSubmit}
          />
        </p>
      )}
      {children}
    </label>
  );
};
