import { FC } from 'react';
import { hasDroppedFiles } from './utils';
import { FileIconLarge } from '@/src/bits/fileLink/fileIcon';
import CloseIcon from '@mui/icons-material/Close';
import { EquipmentFileDropzoneFileType } from './index';
import { useLocalizer } from '@/src/localization';

interface DroppedFilesProps {
  fileType: 'image' | 'file';
  files: EquipmentFileDropzoneFileType[];
  disabled?: boolean;
  disableControls?: boolean;
  removeFile: (i: number) => void;
}

export const DroppedFiles: FC<DroppedFilesProps> = ({ fileType, files, disabled, disableControls, removeFile }) => {
  const loc = useLocalizer();
  return (
    <>
      {hasDroppedFiles(fileType, files) && (
        <div className={disabled ? 'form-group-disabled' : ''}>
          <div className={`flex flex-col gap-1 ${disabled ? 'pointer-events-none' : ''}`}>
            {files.map((file, i) => (
              <div key={'file_location' in file ? file.file_location : file.name}>
                <a href={'file_location' in file ? file.file_location : ''} target="_blank" rel="noreferrer">
                  <div className="group">
                    <div className="grid grid-cols-[min-content] overflow-hidden rounded bg-corp-gray-50 p-1 hover:bg-moov-blue-100">
                      <div className="col-start-1 col-end-1 pl-2">
                        <FileIconLarge file={file} />
                      </div>
                      <div className="col-start-2 col-end-2 flex items-center overflow-hidden">
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap pl-2">{file.name}</p>
                      </div>
                      {!disableControls && (
                        <div className="col-start-3 col-end-3 flex items-center justify-end pl-2 pr-2 text-24spx">
                          <div className="h-10 w-10">
                            <button
                              data-testid="EquipmentFileDropzone-RemoveFile"
                              type="button"
                              onClick={(e) => {
                                removeFile(i);
                                e.preventDefault();
                              }}
                              className="flex h-10 w-10 items-center justify-center rounded bg-danger-red text-white hover:brightness-95 group-focus-within:flex group-hover:flex lg:hidden"
                              aria-label={loc.Forms.RemoveFile}
                            >
                              <CloseIcon fontSize="inherit" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
