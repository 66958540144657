import { CreateListingMainLocalizer } from '../createListing/index.loc';
import { DashboardMainLocalizer } from '../dashboardMain/index.loc';
import { ErrorLocalizer } from './error.loc';
import { EditListingMainLocalizer } from '../listings/edit/index.loc';
import { EquipmentManageLocalizer } from '@/src/equipment/ui/index.loc';
import { ProfileMainLocalizer } from '../profile/index.loc';
import { RegisterCompanyLocalizer } from '../registerCompany/index.loc';
import { RegisterUserLocalizer } from '../registerUser/index.loc';
import { SettlementLocalizer } from '../settlement/index.loc';
import { UnsubscribeLocalizer } from '../unsubscribe/index.loc';
import { UserBuyLocalizer } from '../userBuy/index.loc';
import { VerificationLocalizer } from '../verification/index.loc';
import { FormsLocalizer } from '../bits/forms/index.loc';
import { ModalsLocalizer } from '../bits/modals/index.loc';
import { BlogLocalizer } from '../corporate/blog/index.loc';
import { BuyMainLocalizer } from '../corporate/buyMain/index.loc';
import { CareersLocalizer } from '../corporate/careers/index.loc';
import { CompanyProfileLocalizer } from '../corporate/companyProfile/index.loc';
import { CorpLocalizer } from '../corporate/corp.loc';
import { HomeMainLocalizer } from '../corporate/homeMain/index.loc';
import { NewsLocalizer } from '../corporate/news/index.loc';
import { HowToSelLocalizer } from '../corporate/sell/howToSell.loc';
import { SellMainLocalizer } from '../corporate/sell/index.loc';
import { ServicesLocalizer } from '../corporate/services/index.loc';
import { FooterLocalizer } from '../layout/footer/index.loc';
import { AllCategoryMainLocalizer } from '@/src/marketplace/allMakesMain/index.loc';
import { AllMakeMainLocalizer } from '../marketplace/allMakeMain/index.loc';
import { CategoryMainLocalizer } from '../marketplace/categoryMain/index.loc';
import { ListingLocalizer } from '../marketplace/listingV2/index.loc';
import { MarketplaceMainLocalizer } from '../marketplacev2/index.loc';
import { ModelMainLocalizer } from '../marketplace/modelMain/index.loc';
import { SearchLocalizer } from '../marketplace/search/index.loc';
import { CommonLocalizer } from './common.loc';
import { MagicLocalizer } from '../marketplace/serviceTiles/magic.loc';
import { defaultLocale, locales } from './constants';
import { LanguageLocalizer } from './language.loc';
import { NavLocalizer } from './nav.loc';
import type { Locale, LocaleLanguage } from './types';
import { BuySellWidgetsLocalizer } from '../corporate/components/buySellWidgets/index.loc';
import { TestimonyLocalizer } from '../corporate/components/testimonialsWidget/index.loc';
import { AccountLocalizer } from '../account.loc';
import { RemarketingLocalizer } from '../remarketing/index.loc';
import { ToolRequestLocalizer } from '../requests2/index.loc';

/**
 *
 */
export class Localizer {
  private locale: Locale;
  private localeLanguage: LocaleLanguage;

  public Account;
  public AllCategoryMain;
  public AllMakeMain;
  public Blog;
  public BuyMain;
  public BuySellWidgets;
  public Careers;
  public CategoryMain;
  public Common;
  public CompanyProfile;
  public Corp;
  public CreateListingMain;
  public DashboardMain;
  public EditListingMain;
  public EquipmentManage;
  public ErrorMessage;
  public Footer;
  public Forms;
  public HowToSell;
  public HomeMain;
  public Language;
  public Listing;
  public Magic;
  public Modals;
  public ModelMain;
  public MarketplaceMain;
  public Nav;
  public News;
  public ProfileMain;
  public RegisterCompany;
  public RegisterUser;
  public Remarketing;
  public Search;
  public SellMain;
  public Services;
  public Settlement;
  public Testimony;
  public Unsubscribe;
  public UserBuy;
  public Verification;
  public ToolRequest;

  /**
   * Sets to default locale if invalid.
   */
  constructor(nextLocale?: string) {
    // -- set locale
    this.locale = locales.find((item) => item === nextLocale) ?? defaultLocale;

    // -- set locale language
    switch (this.locale) {
      case 'en':
        this.localeLanguage = 'en';
        break;
      case 'jp':
        this.localeLanguage = 'jp';
        break;
      case 'ko':
        this.localeLanguage = 'ko';
        break;
      case 'zh-CN':
        this.localeLanguage = 'cn';
        break;
      case 'zh-TW':
        this.localeLanguage = 'tw';
        break;
    }

    // -- set localizers
    // ----------------------------

    this.Account = new AccountLocalizer(this.locale);
    this.AllCategoryMain = new AllCategoryMainLocalizer(this.locale);
    this.AllMakeMain = new AllMakeMainLocalizer(this.locale);
    this.Blog = new BlogLocalizer(this.locale);
    this.BuyMain = new BuyMainLocalizer(this.locale);
    this.BuySellWidgets = new BuySellWidgetsLocalizer(this.locale);
    this.Careers = new CareersLocalizer(this.locale);
    this.CategoryMain = new CategoryMainLocalizer(this.locale);
    this.Common = new CommonLocalizer(this.locale);
    this.CompanyProfile = new CompanyProfileLocalizer(this.locale);
    this.Corp = new CorpLocalizer(this.locale);
    this.CreateListingMain = new CreateListingMainLocalizer(this.locale);
    this.DashboardMain = new DashboardMainLocalizer(this.locale);
    this.EditListingMain = new EditListingMainLocalizer(this.locale);
    this.EquipmentManage = new EquipmentManageLocalizer(this.locale);
    this.ErrorMessage = new ErrorLocalizer(this.locale);
    this.Footer = new FooterLocalizer(this.locale);
    this.Forms = new FormsLocalizer(this.locale);
    this.HomeMain = new HomeMainLocalizer(this.locale);
    this.HowToSell = new HowToSelLocalizer(this.locale);
    this.Language = new LanguageLocalizer(this.locale);
    this.Listing = new ListingLocalizer(this.locale);
    this.Magic = new MagicLocalizer(this.locale);
    this.MarketplaceMain = new MarketplaceMainLocalizer(this.locale);
    this.Modals = new ModalsLocalizer(this.locale);
    this.ModelMain = new ModelMainLocalizer(this.locale);
    this.Nav = new NavLocalizer(this.locale);
    this.News = new NewsLocalizer(this.locale);
    this.ProfileMain = new ProfileMainLocalizer(this.locale);
    this.RegisterCompany = new RegisterCompanyLocalizer(this.locale);
    this.RegisterUser = new RegisterUserLocalizer(this.locale);
    this.Remarketing = new RemarketingLocalizer(this.locale);
    this.Search = new SearchLocalizer(this.locale);
    this.SellMain = new SellMainLocalizer(this.locale);
    this.Services = new ServicesLocalizer(this.locale);
    this.Settlement = new SettlementLocalizer(this.locale);
    this.Testimony = new TestimonyLocalizer(this.locale);
    this.Unsubscribe = new UnsubscribeLocalizer(this.locale);
    this.UserBuy = new UserBuyLocalizer(this.locale);
    this.Verification = new VerificationLocalizer(this.locale);
    this.ToolRequest = new ToolRequestLocalizer(this.locale);
  }

  /**
   *
   */
  public getLocale(): Locale {
    return this.locale;
  }

  /**
   *
   */
  public getLocaleLanguage(): LocaleLanguage {
    return this.localeLanguage;
  }
}
