import { ChangeEvent, FC } from 'react';

import { useAppSelector } from '@/src/store';
import { getCustomFields } from '@/src/equipment/selectors';
import { SubProps } from '../..';
import { Stack } from '@mui/material';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';

export const CustomFields: FC<SubProps> = ({ state, dispatch }) => {
  const customFields = useAppSelector(getCustomFields);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    dispatch({
      type: 'setCustomField',
      field,
      value: event.target.value
    });
  };

  return (
    <Stack spacing={2} paddingTop={2}>
      {customFields.map((customField) => {
        return (
          <LabeledTextField
            key={customField.field}
            label={customField.label}
            value={state.customFields[customField.field]}
            onChange={(e) => {
              onChange(e, customField.field);
            }}
          />
        );
      })}
    </Stack>
  );
};
