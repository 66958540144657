import { spacing } from '@/src/design/designConstants';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { loginMaskClickEnum } from '@/src/state/tracking/fullstory/customEvents/register';
import { CustomEvents } from '@/src/state/tracking/fullstory/customEvents';

import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { Button } from '@mui/material';
import { FC } from 'react';

export interface LoginMaskProps {
  maskText: string | JSX.Element;
  fullstoryEventType: loginMaskClickEnum;
}
export const LoginMask: FC<LoginMaskProps> = ({ children, maskText, fullstoryEventType }) => {
  const loggedIn = useAppSelector(userIsLoggedIn);
  const dispatch = useAppDispatch();
  const { fullStoryEvent } = useFullStory();

  const openLoginModal = () => {
    fullStoryEvent(CustomEvents.register.loginMaskClick, { type: fullstoryEventType });
    dispatch(openModal({ modalType: GlobalModalTypes.LOGIN, modalProps: {} }));
  };

  return loggedIn ? (
    <>{children}</>
  ) : (
    <Button variant="outlined" color="info" sx={{ py: spacing.lg }} onClick={openLoginModal} id="register-login-mask-button">
      {maskText}
    </Button>
  );
};
