import { Attribute, AttributeSerializable, Condition, conditionToText, numberFromKey, stringFromKey } from '@/src/common/lib';
import { SelectWithOptionsDropdownOption } from '@/src/design/bytes/selectWithOptions';

//#region Value Types i.e. alternative to enums to provide stronger type safety and unreachable support

export const Priority = {
  Automated: -1,
  Low: 1,
  Medium: 2,
  High: 3
} as const;
export type Priority = (typeof Priority)[keyof typeof Priority];

export const priorities: { id: Priority; label: string }[] = [
  { id: Priority.Automated, label: 'Automated' },
  { id: Priority.Low, label: 'Low' },
  { id: Priority.Medium, label: 'Medium' },
  { id: Priority.High, label: 'High' }
];

export function priorityToString(a: Priority): string {
  return priorities.find((v) => v.id === a)?.label || '';
}

// Necessary for id value that comes back as string from the Select control
export function idToPriority(a: string): Priority {
  return priorities.find((v) => v.id === parseInt(a))?.id || Priority.Automated;
}

export const priorityOptions: SelectWithOptionsDropdownOption[] = priorities
  .map((e) => ({ id: `${e.id}`, label: e.label }))
  .filter((e) => e.id !== '-1');

export class Wanted2 {
  //region Properties
  category = new Attribute();
  condition: Condition = Condition.Used;
  created_at = '';
  id = 0;
  location = '';
  make = new Attribute();
  model = new Attribute();
  opportunity_count = 0;
  price = '';
  priority: Priority = Priority.Automated;
  wafer_size = '';
  wafer_size_values: number[] = [];
  insurance = 0;
  logistics = 0;
  appraisal = 0;
  installation = 0;
  configuration = '';
  wanted_at = '';
  //endregion

  constructor(args: Partial<Wanted2> = {}) {
    Object.assign(this, args);
  }

  //region Getters
  get conditionText(): string {
    return conditionToText(this.condition);
  }

  get priorityText(): string {
    return Wanted2.priorityToText(this.priority.toString());
  }

  //endregion

  toSerializable(): WantedSerializable {
    return {
      category: this.category.toSerializable(),
      condition: this.condition,
      created_at: this.created_at,
      id: this.id,
      location: this.location,
      make: this.make.toSerializable(),
      price: this.price,
      model: this.model.toSerializable(),
      opportunity_count: this.opportunity_count,
      priority: this.priority,
      wafer_size: this.wafer_size,
      wafer_size_values: this.wafer_size_values,
      insurance: this.insurance,
      logistics: this.logistics,
      appraisal: this.appraisal,
      installation: this.installation,
      configuration: this.configuration,
      wanted_at: this.wanted_at
    };
  }

  //region Static Members
  static anyToDto(a: any): Wanted2 {
    const category = Attribute.anyToDto(a.category ?? {});
    const make = Attribute.anyToDto(a.make ?? {});
    const model = Attribute.anyToDto(a.model ?? {});
    const s = Wanted2.anyToSerializable(a);
    return new Wanted2({ ...s, category, make, model });
  }

  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): WantedSerializable {
    // transform wafer_size into wafer_size_values
    const ws = stringFromKey('wafer_size', a);
    let wsv: number[] = [];
    if (ws !== '') wsv = ws.split(',').map((e) => Number.parseInt(e));

    return {
      category: Attribute.anyToSerializable(a.category ?? {}),
      condition: numberFromKey('condition', a) as Condition,
      created_at: stringFromKey('created_at', a),
      id: numberFromKey('id', a),
      location: stringFromKey('location', a),
      make: Attribute.anyToSerializable(a.make ?? {}),
      model: Attribute.anyToSerializable(a.model ?? {}),
      opportunity_count: numberFromKey('opportunity_count', a),
      price: stringFromKey('price', a),
      priority: numberFromKey('priority', a) as Priority,
      wafer_size: ws,
      wafer_size_values: wsv.sort((a, b) => a - b),
      insurance: numberFromKey('insurance', a),
      logistics: numberFromKey('logistics', a),
      appraisal: numberFromKey('appraisal', a),
      installation: numberFromKey('installation', a),
      configuration: stringFromKey('configuration', a),
      wanted_at: stringFromKey('wanted_at', a)
    };
  }

  static priorityToText(priority: string): string {
    switch (priority) {
      case Priority.Automated.toString():
        return 'Automated';
      case Priority.Low.toString():
        return 'Low';
      case Priority.Medium.toString():
        return 'Medium';
      case Priority.High.toString():
        return 'High';
      default:
        return '';
    }
  }

  //endregion
}
// create an object with only Dto properties / no functions or getters

export type WantedSerializable = {
  category: AttributeSerializable;
  condition: Condition;
  created_at: string;
  id: number;
  location: string;
  make: AttributeSerializable;
  model: AttributeSerializable;
  opportunity_count: number;
  price: string;
  priority: Priority;
  wafer_size: string;
  wafer_size_values: number[];
  insurance: number;
  logistics: number;
  appraisal: number;
  installation: number;
  configuration: string;
  wanted_at: string;
};

export const isWantedSerializable = (arg: unknown): arg is WantedSerializable => {
  return (
    typeof arg === 'object' &&
    (arg as WantedSerializable).condition !== undefined &&
    (arg as WantedSerializable).price !== undefined &&
    (arg as WantedSerializable).priority !== undefined &&
    (arg as WantedSerializable).opportunity_count !== undefined
  );
};
