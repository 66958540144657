import { useUser } from '../useUser';
import { captureException } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

export const useCaptureExceptionForExternalUsers = () => {
  const { user, userIsInternal } = useUser();
  return {
    captureExceptionForExternalUsers: (error: any, captureContext?: CaptureContext) => {
      !userIsInternal(user) && captureException(error, captureContext);
    }
  };
};
