import type { FC } from 'react';

import { BlockIcon, CirclePlusIcon, EmptyFileIcon, PhotosIcon } from '../../../../bits/svgs/svgs';

interface DropzoneIconProps {
  readonly fileType: 'file' | 'image';
  readonly isDragActive: boolean;
  readonly isDragReject: boolean;
}

export const DropzoneIcon: FC<DropzoneIconProps> = (props) => {
  const { fileType, isDragActive, isDragReject } = props;

  if (isDragActive && !isDragReject) {
    return null;
  }

  return (
    <div className="h-16.5 w-16.5">
      {isDragReject ? (
        <div className="h-full w-full">
          <BlockIcon className="h-full w-full" fill="#FD385D" />
        </div>
      ) : (
        <>
          <div className="h-full w-full group-hover:hidden">
            {fileType === 'image' && <PhotosIcon className="h-full w-full" fill="#8FA5B0" />}
            {fileType === 'file' && <EmptyFileIcon className="h-full w-full" fill="#8FA5B0" />}
          </div>
          <div className="hidden h-full w-full group-hover:block" data-testid="DropzoneIcon-CirclePlus">
            <CirclePlusIcon className="h-full w-full" fill="#234454" />
          </div>
        </>
      )}
    </div>
  );
};
