import { useAppDispatch, useAppSelector } from '@/src/store';
import { useEffect } from 'react';
import { reset } from '../slice';
import { fetchSellerOffers } from '../thunks';
import { getFilteredSellerOffers, getOffersLoading, getSellerOffers } from '../selectors';
import { Box, Stack, Typography } from '@mui/material';
import { MoovLoader } from '@/src/bits/forms/loaders';
import { colors, spacing } from '@/src/design/designConstants';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { NullFilterState } from '../nullFilterState';
import { OffersSellersTabDesktop } from './desktop';
import { OffersSellerTabMobile } from './mobile';

export const OffersSellersTab = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSellerOffers());

    return () => {
      //Reset on unmount so state doesnt affect buyer tab
      dispatch(reset());
    };
  }, []);

  const { initialized, loading } = useAppSelector(getOffersLoading);
  const allSelleroffers = useAppSelector(getSellerOffers);
  const filteredSellerOffers = useAppSelector(getFilteredSellerOffers);

  if (!initialized && loading) {
    return (
      <Stack variant="center" width="100%" height="100%">
        <MoovLoader isLoading />
      </Stack>
    );
  }

  if (initialized && !allSelleroffers.length) {
    return (
      <Stack variant="center" alignItems={'center'} width="100%" height="100%">
        <Stack width="100%" p={spacing['2xl']} spacing={spacing.xl}>
          <Stack variant="center" spacing={spacing.md} textAlign={'center'}>
            <Typography color={colors.corpGray['500']} variant="subtitle3">
              No Current Offers
            </Typography>
            <Typography color={colors.corpGray['500']} variant="p14">
              No offers yet on your listings, be on the look out for notifications when your next offer comes in!
            </Typography>
          </Stack>
          <Stack width="100%" variant="center" py={spacing.md} px={spacing.sm}>
            <img src={IMAGE_URLS.OFFER_NULL_STATE} alt="order card illustration" />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (initialized && !filteredSellerOffers.length) {
    return <NullFilterState />;
  }

  return (
    <>
      <Box display={{ d: 'block', xs: 'none' }}>
        <OffersSellersTabDesktop />
      </Box>
      <Box display={{ d: 'none', xs: 'block' }}>
        <OffersSellerTabMobile />
      </Box>
    </>
  );
};
