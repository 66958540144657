import { useContext, useEffect } from 'react';
import { isSuggestedListing, ListingActionStatus } from '../../../../../entities/listing';
import { useAnyOpportunities } from '../../../../../hooks/opportunities/useAnyOpportunities';
import { MobileSuggestedItemsModalDispatch } from '../../../../../state/mobileSuggestedItemsModal/context';
import { SuggestedItemsSteps } from '../types';

export const useRedirectIfOpportunityIsNotActionable = () => {
  const actions = useContext(MobileSuggestedItemsModalDispatch);
  const { currentListing, loading, loaded } = useAnyOpportunities();
  useEffect(() => {
    if (!loading && loaded && isSuggestedListing(currentListing)) {
      if (!currentListing.is_actionable) {
        let previousStep = SuggestedItemsSteps.SUGGESTED_ITEMS;
        switch (currentListing.opportunity_status) {
          case ListingActionStatus.rejected:
            previousStep = SuggestedItemsSteps.NOT_INTERESTED;
            break;
          case ListingActionStatus.approved:
            previousStep = SuggestedItemsSteps.QUESTIONS;
            break;
        }
        actions.goToStep(SuggestedItemsSteps.SHOW_NEXT, { previousStep });
      }
    }
  }, [loading, loaded, currentListing, actions]);
};
