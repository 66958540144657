import { useContext } from 'react';
import { MobileSuggestedItemsModalStore } from '../../../../state/mobileSuggestedItemsModal/context';
import { SuggestedItemsSteps } from './types';

const ShowNext: React.FC = () => {
  const state = useContext(MobileSuggestedItemsModalStore);

  let bgColorClass = '';
  switch (state.props.previousStep) {
    case SuggestedItemsSteps.QUESTIONS:
      bgColorClass = 'bg-magenta-50';
      break;
    case SuggestedItemsSteps.NOT_INTERESTED:
      bgColorClass = 'bg-moov-blue-100';
      break;
  }

  return (
    <div className={`h-full px-4 py-6 text-moov-blue-500 ${bgColorClass}`}>
      <div className="mb-2.5 text-center font-lato text-20spx font-bold">Thank you for your submission.</div>
      <div className="text-center font-lato text-16spx">Your Account Executive will be in touch with you shortly.</div>
    </div>
  );
};

export default ShowNext;
