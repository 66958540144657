import { Listing } from '../../entities/listing';
import { useCaptureExceptionForExternalUsers } from '../useCaptureExceptionForExternalUsers';
import { UpdateRejectReason } from '../../providers/opportunities/types';
import { rejectOpportunity, rejectOpportunityByListingAndUserId } from '../../providers/opportunities';
import { captureException } from '@sentry/nextjs';
import { ERROR_MESSAGES } from '../../constants';

export const useSubmitRejectReasonForm = () => {
  const { captureExceptionForExternalUsers } = useCaptureExceptionForExternalUsers();
  return async (
    formValues: UpdateRejectReason,
    opportunityKey: string | undefined,
    userId: number | undefined,
    listing: Listing,
    setErrorMessage?: (arg: ERROR_MESSAGES) => void,
    onSuccess?: () => void
  ) => {
    try {
      if (opportunityKey) {
        await rejectOpportunity(opportunityKey, listing.id, formValues.rejectReason, formValues.rejectComments);
      } else if (listing && userId) {
        await rejectOpportunityByListingAndUserId(listing, userId, formValues.rejectReason, formValues.rejectComments);
      } else {
        captureException('Error: Cannot submit reason for rejection', {
          contexts: { submitRejectReasons: { message: 'Cannot submit reject reasons without opportunity key or user id' } }
        });
        setErrorMessage && setErrorMessage(ERROR_MESSAGES.REFRESH);
      }
      onSuccess && onSuccess();
    } catch (err) {
      captureExceptionForExternalUsers(err, { contexts: { rejectReasonFormSubmit: { opportunityKey, listingId: listing.id } } });
      setErrorMessage && setErrorMessage(ERROR_MESSAGES.REFRESH);
    }
  };
};
