import { colors, spacing, borderRadius } from '@/src/design/designConstants';
import { fetchCategoriesForStore } from '@/src/state/category/thunks';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { ListItem, ListItemButton, Popper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import routes from '@/src/routes';
import { SearchFlowType } from '@/src/marketplace/search/types';
import { getCategoriesLoaded, getParentCategoryRelationships } from '@/src/state/category/selectors';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';

export const CategorySubHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const parentCategoryDetails = useAppSelector(getParentCategoryRelationships);
  const categoriesLoaded = useAppSelector(getCategoriesLoaded);
  const [activeTab, setActiveTab] = useState<null | HTMLAnchorElement>(null);
  const { fullStoryEvent } = useFullStory();
  useEffect(() => {
    dispatch(fetchCategoriesForStore());
  }, []);

  if (!categoriesLoaded) {
    return null;
  }

  return (
    <Stack sx={{ backgroundColor: colors.surface.white }} onMouseLeave={() => setActiveTab(null)}>
      <Stack direction="row">
        <ListItemButton
          sx={{ padding: spacing.xl }}
          href={routes.search()}
          onClick={() => {
            fullStoryEvent(CustomEvents.navigation.categories.seeAllCategoryClick);
          }}
        >
          <Typography color={colors.moovBlue['500']} variant="p12" whiteSpace={'nowrap'}>
            Shop All Categories
          </Typography>
        </ListItemButton>
        <TabContext value={activeTab?.id || ''}>
          <Tabs variant="scrollable" scrollButtons value={activeTab?.id || false} sx={{ width: '100%', color: colors.moovBlue['500'] }}>
            {parentCategoryDetails.map((parentCategory) => {
              return (
                <Tab
                  id={`parent-category-nav-${parentCategory.id.toString()}`}
                  onMouseEnter={(e) => {
                    setActiveTab(e.currentTarget);
                  }}
                  href={routes.search({ search_flow_type: SearchFlowType.Category, category_id: parentCategory.id })}
                  onClick={() => {
                    fullStoryEvent(CustomEvents.navigation.categories.categoryClick, {
                      category: parentCategory.name,
                      category_id: parentCategory.id
                    });
                  }}
                  sx={{ maxWidth: 'fit-content', minWidth: 'fit-content' }}
                  key={`categories-sub-header-parent-${parentCategory.id}`}
                  label={
                    <Typography variant="p12" noWrap color={colors.moovBlue['500']} sx={{ textTransform: 'none' }}>
                      {parentCategory.name}
                    </Typography>
                  }
                  value={`parent-category-nav-${parentCategory.id.toString()}`}
                />
              );
            })}
          </Tabs>
        </TabContext>
      </Stack>

      <Popper
        sx={{
          backgroundColor: colors.surface.white,
          borderRadius: borderRadius.rounded,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          maxHeight: '400px',
          zIndex: 1201,
          overflowY: 'auto'
        }}
        open={Boolean(activeTab)}
        anchorEl={activeTab}
      >
        {parentCategoryDetails
          .find((category) => {
            return `parent-category-nav-${category.id}` === activeTab?.id;
          })
          ?.categories.map((childCategory) => {
            return (
              <ListItem key={`child-category-desktop-popper-$${childCategory.name}`}>
                <ListItemButton
                  href={routes.search({ search_flow_type: SearchFlowType.Category, category_id: childCategory.id })}
                  onClick={() => {
                    fullStoryEvent(CustomEvents.navigation.categories.categoryClick, {
                      category: childCategory.name,
                      category_id: childCategory.id
                    });
                  }}
                >
                  <Typography variant="p12" noWrap color={colors.moovBlue['500']} sx={{ textTransform: 'none' }}>
                    {childCategory.name}
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
      </Popper>
    </Stack>
  );
};
