import * as yup from 'yup';
import { Localizer } from '../localization';
import { contactPreferencesForPhone, contactPreferencesWithHandles } from '../providers/leads/types';

export const getEmailSchema = (loc: Localizer) =>
  yup.string().email(loc.RegisterUser.PleaseValidEmail).required(loc.RegisterUser.EmailRequired);

export const getPhoneSchema = () =>
  yup.string().when('contact_preference', {
    is: (value: number | undefined) => typeof value !== 'undefined' && contactPreferencesForPhone.includes(value),
    then: yup.string().required('Phone Number is required'),
    otherwise: yup.string()
  });

export const getContactHandleSchema = (loc: Localizer) =>
  yup.string().when('contact_preference', {
    is: (value: number | undefined) => typeof value !== 'undefined' && contactPreferencesWithHandles.includes(value),
    then: yup.string().required(loc.Forms.ContactInfoRequired),
    otherwise: yup.string()
  });

// TODO: Translations #185801497 - Request for Inquiry is required
export const getInquiryReasonValuesSchema = () => yup.array().of(yup.number()).min(1, 'Request for Inquiry is required');

export const getContactFormSchema = (loc: Localizer) =>
  yup.object({
    email: getEmailSchema(loc),
    first_name: yup.string(),
    last_name: yup.string(),
    company: yup.string(),
    phone: getPhoneSchema(),
    contact_preference: yup.number(),
    contact_handle: getContactHandleSchema(loc)
  });
