import { getDefinitions, interpolateVariable, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class FormsLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ANewPasswordIsRequired(): string {
    return this.definitions.ANewPasswordIsRequired;
  }

  public get Budget(): string {
    return this.definitions.Budget;
  }

  // TODO Translations
  public get TargetPrice(): string {
    return this.definitions.TargetPrice;
  }

  public get BudgetIsRequired(): string {
    return this.definitions.BudgetIsRequired;
  }

  // TODO Translations
  public get TargetPriceIsRequired(): string {
    return this.definitions.TargetPriceIsRequired;
  }

  public get Cancel(): string {
    return this.definitions.Cancel;
  }

  public get CannotBeChangedOnceListingIsCreated(): string {
    return this.definitions.CannotBeChangedOnceListingIsCreated;
  }

  public get CannotBeMoreThanTwoDecimalPlaces(): string {
    return this.definitions.CannotBeMoreThanTwoDecimalPlaces;
  }

  public get Clear(): string {
    return this.definitions.Clear;
  }

  public get ClickToRestPassword(): string {
    return this.definitions.ClickToRestPassword;
  }

  public get CompanyName(): string {
    return this.definitions.CompanyName;
  }

  public get CompanyType(): string {
    return this.definitions.CompanyType;
  }

  public get ConfirmNewPassword(): string {
    return this.definitions.ConfirmNewPassword;
  }

  public get ContactInfoRequired(): string {
    return this.definitions.ContactInfoRequired;
  }

  public get ContainsOneCapital(): string {
    return this.definitions.ContainsOneCapital;
  }

  public get ContainsOneNumberOrSpecial(): string {
    return this.definitions.ContainsOneNumberOrSpecial;
  }

  public get Country(): string {
    return this.definitions.Country;
  }

  public get Create(): string {
    return this.definitions.Create;
  }

  public get CurrentPassword(): string {
    return this.definitions.CurrentPassword;
  }

  public get CurrentPasswordRequiredToChangePassword(): string {
    return this.definitions.CurrentPasswordRequiredToChangePassword;
  }

  public get DescribeYourItem(): string {
    return this.definitions.DescribeYourItem;
  }

  public get Document(): string {
    return this.definitions.Document;
  }

  public get DragAndDropFilesPre(): string {
    return this.definitions.DragAndDropFilesPre;
  }

  public get DragAndDropFilesPost(): string {
    return this.definitions.DragAndDropFilesPost;
  }

  public get DropHereUpload(): string {
    return this.definitions.DropHereUpload;
  }

  public get Email(): string {
    return this.definitions.Email;
  }

  public get Ext(): string {
    return this.definitions.Ext;
  }

  public get FilesNotAccepted(): string {
    return this.definitions.FilesNotAccepted;
  }

  public get FilesTooLarge(): string {
    return this.definitions.FilesTooLarge;
  }

  public get FileTypeNot(): string {
    return this.definitions.FileTypeNot;
  }

  public get FirstName(): string {
    return this.definitions.FirstName;
  }

  public get HasEightOrMore(): string {
    return this.definitions.HasEightOrMore;
  }

  public get HidePassword(): string {
    return this.definitions.HidePassword;
  }

  public get HowTeamContact(): string {
    return this.definitions.HowTeamContact;
  }

  public get ImagesUploadedInNextForm(): string {
    return this.definitions.ImagesUploadedInNextForm;
  }

  public get Industry(): string {
    return this.definitions.Industry;
  }

  public get KakaoTalk(): string {
    return this.definitions.KakaoTalk;
  }

  public get LastName(): string {
    return this.definitions.LastName;
  }

  public get ListingsWithImagesSell(): string {
    return this.definitions.ListingsWithImagesSell;
  }

  public get LoadingMoovLogo(): string {
    return this.definitions.LoadingMoovLogo;
  }

  public get ManufacturerModelIsRequired(): string {
    return this.definitions.ManufacturerModelIsRequired;
  }

  public MaxSize(maxSize: number): string {
    return interpolateVariable(this.definitions.MaxSize, 'maxSize', maxSize);
  }

  public get MoveFileToFirst(): string {
    return this.definitions.MoveFileToFirst;
  }

  public get MustBeAPositiveNumber(): string {
    return this.definitions.MustBeAPositiveNumber;
  }

  public get MustBeOneOrGreater(): string {
    return this.definitions.MustBeOneOrGreater;
  }

  public get MyModelIsNotListed(): string {
    return this.definitions.MyModelIsNotListed;
  }

  public get NewPassword(): string {
    return this.definitions.NewPassword;
  }

  public get Password(): string {
    return this.definitions.Password;
  }

  public get PasswordsMustMatch(): string {
    return this.definitions.PasswordsMustMatch;
  }

  public get PasswordRequired(): string {
    return this.definitions.PasswordRequired;
  }

  public get PasswordConfirmationRequired(): string {
    return this.definitions.PasswordConfirmationRequired;
  }

  public get PasswordReset(): string {
    return this.definitions.PasswordReset;
  }

  public get PhoneKakao(): string {
    return this.definitions.PhoneKakao;
  }

  public get PleaseEnterAValidEmail(): string {
    return this.definitions.PleaseEnterAValidEmail;
  }

  public get PreferredLanguage(): string {
    return this.definitions.PreferredLanguage;
  }

  public get RatesMayApply(): string {
    return this.definitions.RatesMayApply;
  }

  public get RemoveFile(): string {
    return this.definitions.RemoveFile;
  }

  public get Required(): string {
    return this.definitions.Required;
  }

  public get ResetPassword(): string {
    return this.definitions.ResetPassword;
  }

  public get ShowPassword(): string {
    return this.definitions.ShowPassword;
  }

  public get SMS(): string {
    return this.definitions.SMS;
  }

  public get Submit(): string {
    return this.definitions.Submit;
  }

  public get SuccessYourChangesHaveBeenSaved(): string {
    return this.definitions.SuccessYourChangesHaveBeenSaved;
  }

  public get SelectAModel(): string {
    return this.definitions.SelectAModel;
  }

  public get SearchModelsByKeyword(): string {
    return this.definitions.SearchModelsByKeyword;
  }

  public get SpecifyTheConfiguration(): string {
    return this.definitions.SpecifyTheConfiguration;
  }

  public get SuccessListingUpdated(): string {
    return this.definitions.SuccessListingUpdated;
  }

  public get ThisIsAPrivateListing(): string {
    return this.definitions.ThisIsAPrivateListing;
  }

  public get TryResetPasswordAgain(): string {
    return this.definitions.TryResetPasswordAgain;
  }

  public get TypeSearchModels(): string {
    return this.definitions.TypeSearchModels;
  }

  public get Update(): string {
    return this.definitions.Update;
  }

  public get USDollar(): string {
    return this.definitions.USDollar;
  }

  public get WhatsApp(): string {
    return this.definitions.WhatsApp;
  }

  public get YourTitle(): string {
    return this.definitions.YourTitle;
  }

  public get Skip(): string {
    return this.definitions.Skip;
  }
}
