import { AppEnv } from '../entities/common';

export const getMoovCoWebEnv = (): AppEnv => {
  let appEnv: AppEnv;
  switch (process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV) {
    case 'production':
    case 'stage':
    case 'test':
    case 'beta':
    case 'local':
      appEnv = process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV;
      break;
    default:
      throw new TypeError(`invalid App env: "${process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV}"`);
  }
  return appEnv;
};

/**
 *
 */
const appVersion = process.env.NEXT_PUBLIC_MOOV_CO_WEB_VERSION;
if (typeof appVersion !== 'string' || appVersion === '') {
  throw new TypeError(`invalid App version: "${appVersion}"`);
}
export const MOOV_CO_WEB_VERSION: string = appVersion;
