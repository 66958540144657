import { ListingSummary } from '@/src/entities/listing';
import { getRemarketingInfoBySlug } from '@/src/exclusives/selectors';
import { AppState, useAppSelector } from '@/src/store';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import Image from 'next/image';
import { FC } from 'react';
import { Listing2 } from '@/src/listings/types';

interface ExclusiveBadgingProps {
  listing: ListingSummary | Listing2;
  simple?: boolean;
}

export const ExclusiveBadging: FC<ExclusiveBadgingProps> = ({ listing, simple = false }) => {
  const remarketingInfo = useAppSelector((state: AppState) => getRemarketingInfoBySlug(state, listing.remarketing_slug));
  const hasLogoOrName = remarketingInfo && (remarketingInfo.remarketing_logo || remarketingInfo.remarketing_name);

  return (
    <Stack direction="row" position="relative" maxWidth="100%">
      <Chip
        variant="filled"
        color="secondary"
        sx={{ position: 'relative', zIndex: 1, maxHeight: '24px' }}
        label={
          <Stack direction={'row'} alignItems={'center'}>
            <Stack fontSize="14px" mr={spacing.xs}>
              ✦
            </Stack>
            {/* TODO: #186086204 translations for EXCLUSIVE */}
            <Box sx={{ mt: '0.2em' }}>EXCLUSIVE</Box>
          </Stack>
        }
      />
      {hasLogoOrName && !simple && (
        <Chip
          variant="filled"
          sx={{
            paddingLeft: '90px',
            background: colors.surface.white,
            position: 'absolute',
            maxHeight: '24px',
            maxWidth: 'fit-content'
          }}
          label={
            <Stack direction={'row'} spacing={1} alignItems="center">
              {!!remarketingInfo.remarketing_logo && (
                <Box sx={{ position: 'relative', minWidth: '20px', minHeight: '20px', maxHeight: '20px', maxWidth: '20px' }}>
                  <Image
                    quality={100}
                    layout="fill"
                    src={remarketingInfo.remarketing_logo}
                    alt={`remarketing-logo-${remarketingInfo.remarketing_slug}`}
                    objectFit="contain"
                  />
                </Box>
              )}
              {!!remarketingInfo.remarketing_name && (
                <Typography variant="button12AllCaps" noWrap color={colors.moovBlue['500']}>
                  {remarketingInfo.remarketing_name}
                </Typography>
              )}
            </Stack>
          }
        />
      )}
    </Stack>
  );
};
