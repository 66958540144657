import type { ListingSearchSortValue, UserListingsSortValue } from './constants';
import type { DTOListing } from './dtos';
import * as ListingRepo from './repo';
import { isEmpty } from '../../utils';
import {
  buildListing,
  buildListingSummary,
  buildSuggestedListing,
  cleanMetadata,
  convertNextParamsToApiParams,
  convertSuggestedNextParamsToApiParams
} from './utils';
import { DropzoneFile } from '../../bits/forms/formikFields/formikDropzone';
import type { FindManyParams } from '../types';
import { Url } from '../../entities/common';
import { ListingSummary, ListingMetadata, Listing, SuggestedListing, CreateManyListingPhoto } from '../../entities/listing';
import { UnconfirmedListing, dtoToUnconfirmedListing } from '../../entities/unconfirmedListing';
import { CreateListingFileUpload, ListingFile } from '../../entities/listingFile';
import { CreateListingPhotoUpload, ListingPhoto } from '../../entities/listingPhoto';
import { MoovMetrics } from '../../entities/metrics';
import { Wanted } from '../../entities/wanted';
import { ListingOrderByField } from './repo';
import { Listing2, ListingSerializable } from '@/src/listings/types';

export interface FetchListingsMetadataParams {
  user_id: number | string;
}

export interface FetchUserSuggestedListingsParams extends FindManyParams {
  has_photos?: boolean;
  condition?: number[];
  min_vintage?: number;
  wafer_size?: number[];
  model_id?: number | string | (number | string)[];
  is_seen?: boolean;
  bionic_message_id?: string;
  opportunity_status?: number[];
}

export interface SearchListingsParams {
  q?: string;
  order?: ListingSearchSortValue | UserListingsSortValue;
  condition?: number | string | (number | string)[];
  has_photos?: boolean;
  is_exclusive?: boolean;
  min_vintage?: number;
  max_vintage?: number;
  category_id?: number | string | (number | string)[];
  make_id?: number | string | (number | string)[];
  model_id?: number | string | (number | string)[];
  wafer_size?: number[] | string[];
  sortByColumn?: boolean;
  sort?: ListingOrderByField;
  limit?: number | string;
  offset?: number | string;
  /**
   * For public searches only, will be ignored by authenticated searches.
   */
  autocomplete?: boolean;
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  location?: string[];
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  private?: string;
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  status?: string[];
}

// -- #methods
// ----------------------------

/**
 * Requires browser context with a valid session id set.
 */
export const fetchUserPicksListings = async (params: FindManyParams): Promise<ListingSummary[]> => {
  const dtos = await ListingRepo.findManyUserPicksListing(params);

  return dtos.map(buildListingSummary);
};

export type SearchListingsParamsPublic = Omit<SearchListingsParams, 'location' | 'private' | 'status'>;

/**
 * Only search term queries with `autocomplete=false` will trigger a new search Slack notification.
 * `autocomplete` defaults to `true`.
 * `order` defaults to `smart_search`.
 */
export const searchListings = async (params: SearchListingsParamsPublic, useExperimental = false): Promise<ListingSummary[]> => {
  const apiParams = convertNextParamsToApiParams(params);
  const dtos = await ListingRepo.findManyListingBySearch(apiParams, useExperimental);

  return dtos.map(buildListingSummary);
};

export const searchListingsTemp = async (params: SearchListingsParamsPublic, useExperimental = false): Promise<ListingSerializable[]> => {
  const apiParams = convertNextParamsToApiParams(params);
  const dtos = await ListingRepo.findManyListingBySearch(apiParams, useExperimental);
  const transform = dtos.map((dto) => {
    return Listing2.anyToSerializable(dto);
  });

  return transform;
};

export type SearchListingsParamsAuth = Omit<SearchListingsParams, 'autocomplete'>;

/**
 * Searches Listings owned by a User.
 */
export const searchUserListings = async (params: SearchListingsParamsAuth): Promise<Listing[]> => {
  const dtos = await ListingRepo.findManyUserListingBySearch(convertNextParamsToApiParams(params));

  return dtos.map(buildListing);
};

export const searchUserListingsForListing2 = async (params: SearchListingsParamsAuth): Promise<DTOListing[]> => {
  const dtos = await ListingRepo.findManyUserListingBySearch(convertNextParamsToApiParams(params));

  return dtos;
};

export const fetchListingsMetadata = async (params: FetchListingsMetadataParams): Promise<ListingMetadata> => {
  const metadata = await ListingRepo.getListingsMetadata(params);

  if (isEmpty(metadata)) {
    return {
      location: [],
      category: [],
      make: [],
      model: [],
      private: [],
      status: []
    };
  }

  return cleanMetadata(metadata);
};

/**
 *
 */
export const fetchWantedListings = async (wantedId: number, params: FindManyParams): Promise<ListingSummary[]> => {
  const dtos = await ListingRepo.findManyListingByWanted(wantedId, params);

  return dtos.map(buildListingSummary);
};

/**
 *
 */
export const fetchListingDTO = async (listingKey: string): Promise<DTOListing> => {
  const dto = await ListingRepo.findOneListing(listingKey, {});

  return dto;
};

export const fetchListingByOpportunityKey = async (opportunityKey: string): Promise<Listing> => {
  const dto = await ListingRepo.findOneListingByOpportunityKey(opportunityKey);

  return buildListing(dto);
};

// -- #UserListing

/**
 * Requires browser context with a valid session id set.
 */
export const fetchUserListings = async (userId: number, params: FindManyParams): Promise<ListingSummary[]> => {
  const dtos = await ListingRepo.findManyListing({ user_id: userId, ...params });

  return dtos.map(buildListingSummary);
};

/**
 *
 */
export const fetchUserListing = async (listingKey: string, userId: number): Promise<Listing> => {
  const dto = await ListingRepo.findOneListing(listingKey, { user_id: userId });

  return buildListing(dto);
};

/**
 * Requires browser context with a valid session id set.
 */
export const fetchUserSuggestedListings = async (params: FetchUserSuggestedListingsParams): Promise<SuggestedListing[]> => {
  const apiParams = convertSuggestedNextParamsToApiParams(params);
  const dtos = await ListingRepo.findManySuggestedListing(apiParams);

  return dtos.map(buildSuggestedListing);
};

/**
 * Requires browser context with a valid session id set.
 */
export const fetchRequestSuggestedListings = async (wantedId: string): Promise<SuggestedListing[]> => {
  const dtos = await ListingRepo.findAllOpportunitiesByWanted(wantedId);

  return dtos.map(buildSuggestedListing);
};

// -- #UserUnconfirmedListing

/**
 * Requires browser context with a valid session id set.
 * TODO: #186083206 - Clean up code related to legacy unconfirmed listings.
 */
export const fetchUserUnconfirmedListings = async (params: FindManyParams): Promise<UnconfirmedListing[]> => {
  const dtos = await ListingRepo.findManyUnconfirmedListing(params);
  return dtos.map(dtoToUnconfirmedListing);
};

/**
 * todo #182883109 improve how recommended listings are generated.
 * Requires browser context with a valid session id set.
 */
export const fetchUserRecommendedListings = async (wanteds: Wanted[], params: { limit: number }): Promise<ListingSummary[]> => {
  let recListings: ListingSummary[] = [];
  for (const wanted of wanteds) {
    const wantedListings = await fetchWantedListings(wanted.id, params);
    recListings = [...recListings, ...wantedListings];

    if (recListings.length >= params.limit) {
      break;
    }
  }

  return recListings.slice(0, params.limit);
};

// -- #ListingFile

/**
 * Requires browser context with a valid session id set.
 * Get S3 upload urls from API and uploads them to S3
 */

export const uploadListingFiles = async (listingKey: string, payload: CreateListingFileUpload[], files: DropzoneFile[]): Promise<Url[]> => {
  const presignDtos = await ListingRepo.createManyListingFileUploadUrl(listingKey, { fileTypes: payload });
  if (files.length !== presignDtos.length) {
    throw new Error(`did not receive all PhotoUploadUrls for files`);
  }

  const urls: Url[] = [];
  const presignedFiles = presignDtos.map((presignDto, index) => {
    urls.push(presignDto.key);

    return {
      key: presignDto.key,
      url: presignDto.url,
      name: presignDto.name,
      type: presignDto.type,
      file: files[index]
    };
  });

  try {
    await ListingRepo.uploadS3ResourceFromUrl(presignedFiles);

    return urls;
  } catch (err) {
    throw new Error('Error Uploading Files');
  }
};

/**
 * Requires browser context with a valid session id set.
 */
export const createListingFiles = async (listingKey: string, payload: Url[]): Promise<ListingFile[]> => {
  return ListingRepo.createManyListingFile(listingKey, { fileUrls: payload });
};

/**
 * Requires browser context with a valid session id set.
 * WARN: Order/index of items in payload determines `order` value in the dB.
 * WARN: You must send in all existing, enabled photos, not just the ones you are reordering.
 */
export const reorderListingFiles = async (listingKey: string, payload: ListingFile[]): Promise<ListingFile[]> => {
  const listingDTO = await ListingRepo.updateOneListing(listingKey, { files: payload });

  return listingDTO.files;
};

/**
 * Requires browser context with a valid session id set.
 * WARN: Returns active files, not the files that were disabled.
 */
export const disableListingFiles = async (listingKey: string, payload: ListingFile[]): Promise<ListingFile[]> => {
  const dto = await ListingRepo.disableManyListingFiles(listingKey, { filesToDelete: payload });

  return dto;
};

// -- #ListingPhoto

/**
 * Get S3 upload urls from API and uploads them to S3
 * Requires browser context with a valid session id set.
 */
export const uploadListingPhotos = async (
  listingKey: string,
  payload: CreateListingPhotoUpload[],
  photos: DropzoneFile[]
): Promise<Url[]> => {
  const presignDtos = await ListingRepo.createManyListingPhotoUploadUrl(listingKey, { photoTypes: payload });
  if (photos.length !== presignDtos.length) {
    throw new Error(`did not receive all PhotoUploadUrls for photos`);
  }

  const urls: Url[] = [];
  const presignedPhotos = presignDtos.map((presignDto, index) => {
    urls.push(presignDto.key);

    return {
      key: presignDto.key,
      url: presignDto.url,
      name: presignDto.name,
      type: presignDto.type,
      file: photos[index]
    };
  });

  try {
    await ListingRepo.uploadS3ResourceFromUrl(presignedPhotos);

    return urls;
  } catch (error) {
    throw new Error('Error Uploading Files');
  }
};

/**
 * Requires browser context with a valid session id set.
 * Create listing_photo objects using S3 urls
 */
export const createListingPhotos = async (listingKey: string, payload: CreateManyListingPhoto): Promise<ListingPhoto[]> => {
  return ListingRepo.createManyListingPhoto(listingKey, payload);
};

/**
 * Requires browser context with a valid session id set.
 * WARN: Order/index of items in payload determines `order` value in the dB.
 * WARN: You must send in all existing, enabled photos, not just the ones you are reordering.
 */
export const reorderListingPhotos = async (listingKey: string, payload: ListingPhoto[]): Promise<ListingPhoto[]> => {
  const listingDTO = await ListingRepo.updateOneListing(listingKey, { photos: payload });

  return listingDTO.photos;
};

/**
 * WARN: Returns active photos, not the photos that were disabled.
 * Requires browser context with a valid session id set.
 */
export const disableListingPhotos = async (listingKey: string, payload: ListingPhoto[]): Promise<ListingPhoto[]> => {
  return ListingRepo.disableManyListingPhotos(listingKey, { photosToDelete: payload });
};

// -- #ListingMetric

/**
 *
 */
export const fetchMoovMetrics = async (): Promise<MoovMetrics> => {
  return ListingRepo.findOneListingMetrics({});
};
