import { useLocalizer } from '@/src/localization';
import routes from '@/src/routes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemText, Collapse, List, ListItem } from '@mui/material';
import { FC, MouseEventHandler, useState } from 'react';

export interface BuyerToolsProps {
  onNavigate: () => void;
}
export const BuyerTools: FC<BuyerToolsProps> = ({ onNavigate }) => {
  const [buyerToolsOpen, setBuyerToolsOpen] = useState(false);
  const loc = useLocalizer();

  const toggleBuyerTools = () => {
    setBuyerToolsOpen(!buyerToolsOpen);
  };

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (_) => {
    onNavigate();
  };
  return (
    <>
      <ListItem>
        <ListItemButton onClick={toggleBuyerTools}>
          <ListItemText primary="Buyer Tools" />
          {buyerToolsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={buyerToolsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.requests()}>
              <ListItemText primary={loc.ToolRequest.Requests} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.inReview()}>
              <ListItemText primary={loc.Account.ToolsInReview} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.opportunities()}>
              <ListItemText primary={loc.Account.Suggested} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.orders()}>
              <ListItemText primary={loc.Settlement.Orders} />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};
