import { AppState } from '../../store';
import { reduceCategories } from '@/src/providers/categories';
import { createSelector } from '@reduxjs/toolkit';

export const getAllCategories = (state: AppState) => Object.values(state.categories.byId);
export const getAllCategoriesForFilters = (state: AppState) => {
  return state.categories.idsForFilters.map((id) => state.categories.byId[id]).sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
};

export const getAllCategoriesById = (state: AppState) => state.categories.byId;
export const getCategoriesLoaded = (state: AppState) => state.categories.loaded && !state.categories.loading;
export const getParentCategoryRelationships = createSelector([getAllCategories], (categories) => {
  return reduceCategories(categories);
});
export const getParentCategoryRelationshipsForFilters = createSelector([getAllCategoriesForFilters], (categories) => {
  return reduceCategories(categories).sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
});

export const getCategoryById = (state: AppState, id: number) => state.categories.byId[id];

export const getAllChildCategoriesForCategory = createSelector([getAllCategories, getCategoryById], (categories, category) => {
  if (!category) return [];
  return [category].concat(categories.filter((cate) => cate.parent_id === category.id));
});
export const getRelatedCategoryIdsForFilters = createSelector([getAllChildCategoriesForCategory], (categories) => {
  return categories.map((category) => category.id);
});
