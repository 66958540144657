import { FC } from 'react';

interface SvgWrapperProps {
  readonly viewBox: string;
  readonly className: string;
  readonly fillClassName?: string;
  readonly width?: string;
  readonly height?: string;
}

/**
 * !!! WIDTH AND HEIGHT CLASSES NEED TO BE SET !!!
 * Once width and height classes are set, the SVG will scale to the
 * largest of the two while maintaining its aspect ratio.
 */
export const SvgWrapper: FC<SvgWrapperProps> = ({ children, viewBox, className = '', fillClassName = '', width, height }) => (
  <div className={className}>
    <svg
      className={fillClassName}
      width={width || '100%'}
      height={height || '100%'}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      {children}
    </svg>
  </div>
);
