import { AppDispatch, AppState } from '@/src/store';
import * as fullstoryHelpers from './fullstory/helpers';
import { CustomEvent } from './fullstory/customEvents';

export const FullstoryEvent = (...args: CustomEvent) => {
  return async (_: AppDispatch, _getState: () => AppState): Promise<void> => {
    fullstoryHelpers.initializeFullstory();
    fullstoryHelpers.fullstoryEvent(...args);
  };
};
