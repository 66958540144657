/**
 * Name of cookie that determines if we should show the cookie consent popup
 */
export const COOKIE_CONSENT_ACTION_TAKEN = 'cookie_consent_action_taken';

/**
 * Name of cookie that keeps track of cookie consent preferences
 */
export const COOKIE_PREFS_NAME = 'cookie_preferences';

/**
 * Name of cookie that keeps track of languange preferences
 */
export const COOKIE_LANGUAGE_PREFERENCE_NAME = 'language_preference';

/**
 * TTL for the cookie that keeps track of language preferences
 */
export const COOKIE_LANGUAGE_PREFERENCE_TTL = 365;

/**
 * Keys for the cookie preferences object
 */
export const COOKIE_PREFS_ESSENTIAL = 'essential';
export const COOKIE_PREFS_FUNCTIONAL = 'functional';
export const COOKIE_PREFS_ANALYTICS = 'analytics';

/**
 * Number of days before cookie consent must be renewed.
 * Cookie consent must be renewed at least once per year (according to the ePrivacy Directive)
 * or every 6 months in some European countries (e.g. those operating under the Irish DPC and French CNIL).
 */
export const COOKIE_CONSENT_LIFESPAN = 365 / 2;

/**
 * TTL for the cookie that keeps track of marketing content
 */
export const COOKIE_MARKETING_CONTENT_LIFESPAN = 30;

/**
 * Name of cookie that keeps track of marketing content
 */
export const COOKIE_MARKETING_CONTENT = 'cookie_marketing_content';
