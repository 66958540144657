import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CustomIconName, FigmaIconPadding, FigmaIconScale } from './types';
import { getIconFontSize, getIconPadding, isCustomIconName } from './helpers';

export interface FAIconProps {
  icon: IconDefinition | CustomIconName;
  color?: string;
  scale?: FigmaIconScale;
  padding?: FigmaIconPadding;
  'data-testid'?: string;
}

export const FAIcon: FC<FAIconProps> = (props) => {
  const { color, scale = 1, padding = 'none' } = props;
  const iconFontSize = getIconFontSize(scale);
  const iconPadding = getIconPadding(padding, scale);
  const style = { color, fontSize: iconFontSize, padding: iconPadding };

  if (isCustomIconName(props.icon)) {
    // Dynamic importing of custom icons is currently not supported by Font Awesome
    // which is why we have to render custom icons using the <i> tag.
    return <i className={`fa-kit fa-${props.icon}`} style={style} />;
  } else {
    return <FontAwesomeIcon icon={props.icon} style={style} data-testid={props['data-testid'] ?? props.icon.iconName} />;
  }
};
