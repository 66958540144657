import { useFlags as useCSRFlags } from 'launchdarkly-react-client-sdk';
import { BooleanFlag, StringFlag } from './knownFlags';
import { getDefaultBoolean, getDefaultString } from './knownFlags';
import { isServerSideRendering } from '../../utils/next/isServerSideRendering';
import { useSSRFlagContext } from './ssrFlagContext';

export const useBooleanFeatureFlag = (featureFlagName: BooleanFlag): boolean => {
  const ssrFlags = useSSRFlagContext();
  const csrFlags = useCSRFlags();

  const flags = isServerSideRendering() ? ssrFlags : csrFlags;

  return flags?.[featureFlagName] ?? getDefaultBoolean(featureFlagName);
};

export const useStringFeatureFlag = (featureFlagName: StringFlag): string => {
  const ssrFlags = useSSRFlagContext();
  const csrFlags = useCSRFlags();
  const flags = isServerSideRendering() ? ssrFlags : csrFlags;

  return flags?.[featureFlagName] ?? getDefaultString(featureFlagName);
};

//  TODO: reimplement this function when number flags are added
// export const useNumberFeatureFlag = (featureFlagName: NumberFlag): number => {
//   const ssrFlags = useSSRFlagContext();
//   const csrFlags = useCSRFlags();
//   const flags = isServerSideRendering() ? ssrFlags : csrFlags;

//   return flags?.[featureFlagName] ?? getDefaultNumber(featureFlagName);
// };

// kept in a separate file for easy codegen, but exported here for ergonomics
export { BooleanFlag } from './knownFlags';
export { StringFlag } from './knownFlags';
export { NumberFlag } from './knownFlags';
