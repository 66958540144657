import { FC } from 'react';

import { SubProps } from '..';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';

export const RawModel: FC<SubProps> = ({ state, dispatch, loc }) => {
  if (!state.modelNotListed) {
    return null;
  }

  return (
    <LabeledTextField
      required
      placeholder={loc.Listing.Model}
      label={loc.Listing.Model}
      value={state.textModel || ''}
      onChange={(e) => {
        dispatch({
          type: 'setTextModel',
          value: e.target.value
        });
      }}
      type={'text'}
    />
  );
};
