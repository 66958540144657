import {
  getDefinitions,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class BlogLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get BlogPostImage(): string {
    return this.definitions.BlogPostImage;
  }

  public get GetTheScoop(): string {
    return this.definitions.GetTheScoop;
  }

  public get NoBlogPostsFound(): string {
    return this.definitions.NoBlogPostsFound;
  }

  public get ReadPerspectivesFromLeaders(): string {
    return this.definitions.ReadPerspectivesFromLeaders;
  }

  public get SemiconductorEquipmentBlog(): string {
    return this.definitions.SemiconductorEquipmentBlog;
  }

  public get ViewBlogPost(): string {
    return this.definitions.ViewBlogPost;
  }
}
