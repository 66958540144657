import axios from 'axios';
import { Url } from '@/src/entities/common';
import { EquipmentFile, EquipmentPhoto } from '../../../types';
import { DTOWithMultiWrapper } from '@/src/providers/types';
import { EquipmentDropzoneFile } from '../index';
import { MOOV_API_URL } from '@/src/constants';
import { uploadS3ResourceFromUrl } from '@/src/providers/listings/repo';
import { CreateEquipmentPhotoUpload, DTODeleteManyEquipmentPhotos, EquipmentFileUploadUrl } from './photoTypes';

export const disableEquipmentPhotos = async (equipmentId: number, payload: DTODeleteManyEquipmentPhotos): Promise<EquipmentPhoto[]> => {
  const res = await axios.post<DTOWithMultiWrapper<EquipmentPhoto>>(`${MOOV_API_URL}/equipmentPhotos/${equipmentId}/bulkDelete`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

export const createManyEquipmentPhotoUploadUrl = async (
  equipmentId: number,
  payload: { Photos: CreateEquipmentPhotoUpload[] }
): Promise<EquipmentFileUploadUrl[]> => {
  const res = await axios.post<DTOWithMultiWrapper<EquipmentFileUploadUrl>>(
    `${MOOV_API_URL}/equipmentPhotos/${equipmentId}/bulkUpload`,
    payload,
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );

  return res.data.data;
};

export const saveEquipmentPhotos = async (
  equipmentId: number,
  payload: {
    Photos: (
      | EquipmentPhoto
      | EquipmentFile
      | {
          url: string;
          preview: string;
          lastModified: number;
          name: string;
          webkitRelativePath: string;
          size: number;
          type: string;
        }
    )[];
  }
): Promise<EquipmentPhoto[]> => {
  const res = await axios.post<DTOWithMultiWrapper<EquipmentPhoto>>(`${MOOV_API_URL}/equipmentPhotos/${equipmentId}/bulkCreate`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

export const uploadEquipmentPhotos = async (
  equipmentId: number,
  payload: CreateEquipmentPhotoUpload[],
  photos: EquipmentDropzoneFile[]
): Promise<Url[]> => {
  const presignDtos = await createManyEquipmentPhotoUploadUrl(equipmentId, { Photos: payload });

  if (photos.length !== presignDtos.length) {
    throw new Error(`did not receive all PhotoUploadUrls for photos`);
  }

  const urls: Url[] = [];
  const presignedPhotos = presignDtos.map((presignDto, index) => {
    urls.push(presignDto.key);

    return {
      key: presignDto.key,
      url: presignDto.url,
      name: presignDto.name,
      type: presignDto.type,
      file: photos[index]
    };
  });

  try {
    await uploadS3ResourceFromUrl(presignedPhotos);

    return urls;
  } catch (error) {
    throw new Error('Error Uploading Files');
  }
};
