import { Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import { StringFlag, useStringFeatureFlag } from '@/src/hooks/featureFlags';
import { spacing } from '@/src/design/designConstants';

export const MaintenanceBanner: FC = () => {
  const message = useStringFeatureFlag(StringFlag.MoovCoMaintenanceMessage);
  if (!message) {
    return <></>;
  }

  return (
    <Toolbar disableGutters sx={{ bgcolor: 'red', display: 'flex', justifyContent: 'center', p: spacing['xl'] }}>
      <Typography variant="h6">{message}</Typography>
    </Toolbar>
  );
};
