import axios from 'axios';
import { MOOV_API_URL } from '../../constants';
import { string2ListingActionStatus } from '../listings/utils';
import { DTOWithSingleWrapper } from '../types';
import { OpportunityDTO } from './dtos';
import { Opportunity, RejectedOpportunityDetails } from './types';

export const rejectOneOpportunity = async (opportunityKey: string, payload: RejectedOpportunityDetails) => {
  return axios.post<void>(`${MOOV_API_URL}/opportunities/${opportunityKey}/reject`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });
};

export const rejectOneOpportunityByListing = async (listingKey: string, payload: RejectedOpportunityDetails) => {
  return axios.post<void>(`${MOOV_API_URL}/opportunities/listing/reject`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params: { listing_key: listingKey },
    withCredentials: true
  });
};

/**
 * Returns null if an opportunity is not found
 */
export const getOneOpportunityByListing = async (listingKey: string): Promise<Opportunity | null> => {
  const res = await axios.get<DTOWithSingleWrapper<OpportunityDTO>>(`${MOOV_API_URL}/opportunities/listing/${listingKey}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  const rawResponse = res.data.data;

  if (!rawResponse) {
    return null;
  }

  return {
    status: string2ListingActionStatus(rawResponse.status),
    key: rawResponse.key
  };
};

export const approveOneOpportunity = async (opportunityKey: string) => {
  return axios.post<void>(
    `${MOOV_API_URL}/opportunities/${opportunityKey}/pipeline`,
    {},
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );
};

export const approveOneOpportunityByListing = async (listingKey: string) => {
  return axios.post<void>(
    `${MOOV_API_URL}/opportunities/listing/${listingKey}/pipeline`,
    {},
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );
};

export const markOneOpportunitySeenMagic = async (opportunityKey: string) => {
  return axios.post<void>(
    `${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/opportunities/${opportunityKey}/magic/seen`,
    {},
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true // this is in case this function is called by an AE on accident, the backend with not mark as ween
    }
  );
};

export const markOneOpportunitySeen = async (opportunityKey: string) => {
  return axios.post<void>(
    `${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/opportunities/${opportunityKey}/seen`,
    {},
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );
};
