import { FC } from 'react';
import { SellerOfferGroup } from '../types';
import { Badge, Paper, Divider, Stack, Typography, useTheme } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { parseUSDCurrencyStr } from '@/src/utils';
import { useAppSelector } from '@/src/store';
import { getLogisticCountries } from '@/src/state/logistics/selectors';

export const PendingOfferSummary: FC<{ sellerOfferGroup: SellerOfferGroup }> = ({ sellerOfferGroup }) => {
  const countryOptions = useAppSelector(getLogisticCountries);
  const { palette } = useTheme();
  return (
    <>
      <Paper
        sx={{
          py: spacing.md,
          px: spacing.lg,
          border: `1px solid ${palette.accent.main}`,
          background: palette.accent.light,
          color: palette.secondary.main
        }}
        variant="outlined"
      >
        <Stack whiteSpace="nowrap" mr={spacing.md} direction="row" alignItems="center" spacing={spacing['3xl']} height="100%">
          <Stack>
            <Typography variant="p14">
              <strong>Pending</strong>
            </Typography>
            <Typography variant="p14">
              <strong>Offers:</strong>
            </Typography>
          </Stack>

          <Badge badgeContent={sellerOfferGroup.pending_offers} color="primary" />
        </Stack>
      </Paper>
      {sellerOfferGroup.highest_offer && (
        <Paper
          sx={{
            py: spacing.md,
            px: spacing.lg,
            border: `1px solid ${palette.secondary.light}`,
            background: colors.brand[50],
            color: palette.secondary.main,
            width: '100%'
          }}
          variant="outlined"
        >
          <Stack direction="row" alignItems="center" spacing={spacing['xl']} height="100%">
            <Stack spacing={spacing.xs} width={{ xs: 'fill-available', d: 'fit-content' }}>
              <Typography variant="p12">
                <strong>Highest Offer:</strong>
              </Typography>
              <Typography variant="p12" color={colors.crayolaGreen['500']}>
                <strong>{`${parseUSDCurrencyStr(sellerOfferGroup.highest_offer.offer_amount)} USD`}</strong>
              </Typography>
            </Stack>

            <Divider sx={{ display: { d: 'block', xs: 'none' } }} orientation="vertical" />

            <Stack spacing={spacing.xs} display={{ d: 'flex', xs: 'none' }}>
              <Typography variant="p12">
                <strong>Destination Country:</strong>
              </Typography>
              <Typography variant="p12">{sellerOfferGroup.highest_offer.countryText(countryOptions)}</Typography>
            </Stack>

            <Divider sx={{ display: { d: 'block', xs: 'none' } }} orientation="vertical" />

            <Stack spacing={spacing.xs} display={{ d: 'flex', xs: 'none' }}>
              <Typography variant="p12">
                <strong>Offer Expiration:</strong>
              </Typography>
              <Typography variant="p12" color={colors.magenta['500']}>
                <strong>{sellerOfferGroup.highest_offer.expireDaysText}</strong>
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      )}
    </>
  );
};
