import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import { Carousel } from './carousel';
import { SmallLeftGrayNavArrow, SmallRightGrayNavArrow } from './carouselArrows';
import { runOnSpacebarOrEnterPress } from '../../utils';
import { Photo } from '../../entities/common';
import { MoovButton } from '../moovButton';

const ImageGalleryModal = dynamic(() => import('../modals/imageGalleryModal'), { ssr: false });

/**
 *
 */
const ImageCarousel = ({
  photos,
  alt,
  visibleLength,
  showAllButton
}: {
  photos: Photo[];
  alt: string;
  visibleLength: number;
  showAllButton?: boolean;
}) => {
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [photos]);

  const openImageGalleryModal = () => {
    setModal(<ImageGalleryModal photos={photos} alt={alt} initialIndex={activeIndex} setModal={setModal} />);
  };

  return (
    <>
      {modal}

      <div data-testid="ImageCarousel">
        <div
          className="relative mb-1 h-56 cursor-pointer rounded-lg bg-smokey-gray-100 hover:brightness-95 lg:mb-2 lg:h-102"
          onClick={openImageGalleryModal}
          onKeyDown={(event) => runOnSpacebarOrEnterPress(event, openImageGalleryModal)}
          tabIndex={0}
        >
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img src={photos[activeIndex]?.large_url} alt={alt} className="h-full w-full object-contain" />
          {showAllButton && (
            <div className="absolute bottom-4 right-4 z-10">
              <MoovButton
                variant="tertiary"
                className="py-2 text-14spx normal-case leading-5 tracking-normal"
                onClick={openImageGalleryModal}
              >
                <PhotoLibraryIcon className="mr-2" />
                Show All Photos
              </MoovButton>
            </div>
          )}
        </div>

        <div className="group h-20 lg:h-32">
          {/* todo #183054597 replace old Carousel component with new CarouselBase component */}
          <Carousel
            LeftArrow={SmallLeftGrayNavArrow}
            RightArrow={SmallRightGrayNavArrow}
            visibleLength={visibleLength}
            gapValue={0.25}
            gapUnit="rem"
          >
            {photos.map((photo, i) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`${photo.medium_url}-${i}`}
                className="cursor-pointer hover:brightness-95"
                onClick={() => setActiveIndex(i)}
                onKeyDown={(event) => runOnSpacebarOrEnterPress(event, () => setActiveIndex(i))}
                tabIndex={0}
              >
                <div className="relative h-full w-full bg-smokey-gray-100">
                  {/*eslint-disable-next-line @next/next/no-img-element*/}
                  <img src={photo.medium_url} alt={alt} className="h-full w-full object-contain" />
                </div>
              </li>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
