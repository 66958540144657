export interface CMSLinkConfig {
  path: string;
  label: string;
  order: number;
}

export type CMSLinks = Record<string, CMSLinkConfig[]>;

export enum CMSLocations {
  LEARN_MORE = 'LEARN_MORE',
  FOOTER_SOCIAL_LINKS = 'FOOTER_SOCIAL_LINKS',
  MESSAGES = 'MESSAGES'
}
