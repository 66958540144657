import axios from 'axios';
import { TouchPointType } from './constants';
import { MOOV_API_URL } from '../../constants';

// -- #methods
// -----------------------------------------------

export interface CreateTouchPointParams {
  readonly type: TouchPointType;
  readonly hash_key?: string;
  readonly task?: string;
  readonly details?: string;
}

/**
 * Requires browser context with a valid session id set.
 */
export const createTouchPoint = async (payload: CreateTouchPointParams): Promise<void> => {
  await axios.post<unknown>(`${MOOV_API_URL}/touchPoints/touch`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};
