import { useLocalizer } from '@/src/localization';
import { Box } from '@mui/material';
import { FC } from 'react';
import { colors, fontFamily, fontSize } from '../designConstants';

export interface RequiredLabelProps {
  error?: boolean;
}

export const RequiredLabel: FC<RequiredLabelProps> = (props) => {
  const { error } = props;
  const loc = useLocalizer();

  return (
    <Box
      component={'span'}
      sx={{
        fontFamily: fontFamily.inter,
        fontSize: fontSize['14spx'],
        fontStyle: 'italic',
        fontWeight: 500,
        color: error ? colors.notification.hotRed[500] : 'inherit'
      }}
    >
      {' - '}
      <Box component={'span'} sx={{ color: error ? colors.notification.hotRed[500] : colors.corpGray[500] }}>
        {loc.Forms.Required}
      </Box>
    </Box>
  );
};
