import { ERROR_MESSAGES, MOOV_API_URL } from '@/src/constants';
import { Make } from '@/src/entities/make';
import { FindManyParams } from '@/src/providers/types';
import { AppDispatch, AppState } from '@/src/store';
import axios from 'axios';
import { loadMakesForFilters, loadMakes } from './slice';
import { captureException } from '@sentry/nextjs';
import { openError } from '../banner/slice';
import { getMakesById } from './selectors';

export const fetchMakesByCategory = (category_id: number, params?: FindManyParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<{ data: Make[] }>(`${MOOV_API_URL}/makes/category/${category_id}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadMakesForFilters(resp.data.data));
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export interface GetOrFetchMakesParams extends FindManyParams {
  id: number[];
}
export const getOrFetchMakes = (params?: GetOrFetchMakesParams) => {
  return async (dispatch: AppDispatch, getState: () => AppState): Promise<Make[] | void> => {
    if (params?.id) {
      const ids = Array.isArray(params.id) ? params.id : [params.id];
      const makes = getMakesById(getState(), ids);

      if (makes.every((make) => make !== undefined)) {
        return makes;
      }
    }

    return axios
      .get<{ data: Make[] }>(`${MOOV_API_URL}/makes`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadMakes(resp.data.data));
        return resp.data.data;
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};
