import { Tab as MuiTab, TabProps, Tabs as MuiTabs, TabsProps, styled } from '@mui/material';
import { colors, fontFamily, fontSize, fontWeight } from '../../designConstants';

export const Tab = styled(MuiTab)<TabProps>(() => ({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight[600],
  color: colors.smokeyGray[500],
  lineHeight: fontSize['18spx'],
  fontSize: fontSize['14spx'],
  whiteSpace: 'nowrap',

  '&.Mui-selected': {
    color: colors.moovBlue[500]
  }
}));

export const Tabs = styled(MuiTabs)<TabsProps>(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: colors.skyBlue[500]
  }
}));
