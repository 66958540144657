import { ChangeEventHandler, FC } from 'react';

import { SubProps } from '..';
import { LabeledNumberInput } from '@/src/design/bytes/labeledNumberInput';

export const Quantity: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
    const rawValue = e.target.value.trim();
    const outValue = rawValue.length ? parseInt(rawValue) : undefined;

    dispatch({
      type: 'setQuantity',
      value: outValue || ''
    });
  };

  return (
    <LabeledNumberInput
      placeholder="1"
      label={loc.CreateListingMain.Quantity}
      value={state.quantity ? state.quantity.toString() : ''}
      onChange={setQuantity}
    />
  );
};
