import { getDefinitions, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class RegisterCompanyLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ArtificialIntelligenceMatcher(): string {
    return this.definitions.ArtificialIntelligenceMatcher;
  }

  public get Complete(): string {
    return this.definitions.Complete;
  }

  public get ConnectCompaniesBuyers(): string {
    return this.definitions.ConnectCompaniesBuyers;
  }

  public get InformationInventory(): string {
    return this.definitions.InformationInventory;
  }

  public get MatchPartiesFacilitate(): string {
    return this.definitions.MatchPartiesFacilitate;
  }

  public get RegisterCompany(): string {
    return this.definitions.RegisterCompany;
  }

  public get SellSecureReliable(): string {
    return this.definitions.SellSecureReliable;
  }

  public get SkipNow(): string {
    return this.definitions.SkipNow;
  }

  public get TellAboutCompany(): string {
    return this.definitions.TellAboutCompany;
  }
}
