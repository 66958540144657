import { spacing } from '@/src/design/designConstants';
import { Stack, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';

export const AnnouncementBanner: FC = () => {
  return (
    <Toolbar disableGutters sx={{ bgcolor: 'red', display: 'flex', justifyContent: 'center', p: spacing['xl'] }}>
      <Stack direction="row">
        <Typography variant="h6" sx={{ fontSize: { xs: '12px', d: '16px' } }}>
          6&quot; Fab For Sale from Moov -{' '}
          <a href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}/site/6-fab-sale`}>Click Here to Learn More</a>
        </Typography>
      </Stack>
    </Toolbar>
  );
};
