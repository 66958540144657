export enum NavLink {
  DASHBOARD = 'DASHBOARD',
  BUY = 'BUY',
  REQUESTS = 'REQUESTS',
  TOOLS_IN_REVIEW = 'TOOLS IN REVIEW',
  SUGGESTED_TOOLS = 'SUGGESTED TOOLS',
  ORDERS = 'ORDERS',
  SELL = 'SELL',
  LISTINGS = 'LISTINGS',
  EQUIPMENT = 'EQUIPMENT',
  SOLD = 'SOLD'
}
