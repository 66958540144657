import { FC } from 'react';
import { SellerOfferGroup } from '../../types';
import { Divider, Stack, Typography } from '@mui/material';
import { borderRadius, spacing } from '@/src/design/designConstants';
import Image from 'next/image';
import { parseUSDCurrencyStr } from '@/src/utils';

export const OfferHeader: FC<{ sellerOfferGroup: SellerOfferGroup }> = ({ sellerOfferGroup }) => {
  return (
    <Stack direction="row" spacing={spacing.lg}>
      <Stack width="25%">
        <Image
          alt={`listing-image-${sellerOfferGroup.listing.make_model}`}
          src={sellerOfferGroup.listingSmallPhotoUrl}
          quality={100}
          objectFit="cover"
          height={'100%'}
          width={'100%'}
          style={{ borderRadius: borderRadius.rounded }}
        />
      </Stack>

      <Stack spacing={spacing.xs} width="75%">
        <Typography variant="h6">{sellerOfferGroup.listing.make_model}</Typography>
        <Stack direction="row" spacing={spacing['2xl']}>
          <Stack spacing={spacing.xs}>
            <Typography variant="p12">
              <strong>Asking Price:</strong>
            </Typography>
            <Typography variant="p12">
              {Boolean(Number(sellerOfferGroup.listing.price)) ? `${parseUSDCurrencyStr(sellerOfferGroup.listing.price)} USD` : '-'}
            </Typography>
          </Stack>
          <Divider orientation="vertical" />
          <Stack spacing={spacing.xs}>
            <Typography variant="p12">
              <strong>Product ID:</strong>
            </Typography>
            <Typography variant="p12">{sellerOfferGroup.listing.id}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
