import type { FC } from 'react';

import { useLocalizer } from '../../localization';
import { IMAGE_URLS } from '../../constants/imageUrls';
import { ImageContained } from '../responsiveImage';

enum CommonExtension {
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  EML = 'eml',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PDF = 'pdf',
  PNG = 'png',
  PPT = 'ppt',
  PPTX = 'pptx',
  RAR = 'rar',
  TXT = 'txt',
  XLS = 'xls',
  XLSX = 'xlsx',
  ZIP = 'zip'
}

export interface FileIconProps {
  readonly file: { name: string; title?: string };
  readonly className?: string;
}

const FileIcon: FC<FileIconProps> = ({ file, className = '' }) => {
  const loc = useLocalizer();
  const extension = file.name.split('.').pop();

  let fileIconUrl = IMAGE_URLS.ICON_FILE_GENERIC;
  switch (extension?.toLowerCase()) {
    case CommonExtension.CSV:
      fileIconUrl = IMAGE_URLS.ICON_FILE_CSV;
      break;
    case CommonExtension.XLSX:
      fileIconUrl = IMAGE_URLS.ICON_FILE_CSV;
      break;
    case CommonExtension.DOC:
      fileIconUrl = IMAGE_URLS.ICON_FILE_DOC;
      break;
    case CommonExtension.DOCX:
      fileIconUrl = IMAGE_URLS.ICON_FILE_DOCX;
      break;
    case CommonExtension.PDF:
      fileIconUrl = IMAGE_URLS.ICON_FILE_PDF;
      break;
    case CommonExtension.JPG:
      fileIconUrl = IMAGE_URLS.ICON_FILE_JPG;
      break;
    case CommonExtension.PNG:
      fileIconUrl = IMAGE_URLS.ICON_FILE_PNG;
      break;
    default:
      fileIconUrl = IMAGE_URLS.ICON_FILE_GENERIC;
  }

  return <ImageContained src={fileIconUrl} alt={loc.Common.FileIcon} className={className} quality={100} />;
};

export const FileIconLarge: FC<FileIconProps> = ({ file }) => <FileIcon file={file} className="h-16 w-14" />;

export const FileIconSmall: FC<FileIconProps> = ({ file }) => <FileIcon file={file} className="h-6 w-7" />;
