import { ReactNode, useReducer } from 'react';
import { ModalTypes } from '../../globalComponents/mobileSuggestedItemsModal/modals/types';
import {
  initialMobileSuggestedItemsModalState,
  MobileSuggestedItemsModalReducer,
  MobileSuggestedItemsModalStore,
  MobileSuggestedItemsModalDispatch
} from './context';
import { StepFormValues, WorkflowButtons } from './types';

const MobileSuggestedItemsModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalState, dispatch] = useReducer(MobileSuggestedItemsModalReducer, initialMobileSuggestedItemsModalState);

  const openModal = ({ modalType, props }: { modalType: ModalTypes; props: { title?: string } }) => {
    dispatch({
      type: 'openModal',
      payload: {
        modalType,
        props
      }
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'closeModal'
    });
  };

  const resetModalState = () => {
    dispatch({
      type: 'resetState'
    });
  };

  const goToStep = (n: number) => {
    dispatch({
      type: 'goToStep',
      n
    });
  };

  const updateStepFormValues = (formValues: StepFormValues) => {
    dispatch({
      type: 'updateStepFormValues',
      payload: { formValues }
    });
  };

  const setCurrentStepSubmitting = (button: WorkflowButtons) => {
    dispatch({
      type: 'setCurrentStepSubmitting',
      payload: { button }
    });
  };

  const setCurrentStepSubmitted = (button: WorkflowButtons) => {
    dispatch({
      type: 'setCurrentStepSubmitted',
      payload: { button }
    });
  };
  const setCurrentStepSubmitFailed = (button: WorkflowButtons) => {
    dispatch({
      type: 'setCurrentStepSubmitFailed',
      payload: { button }
    });
  };

  return (
    <MobileSuggestedItemsModalStore.Provider value={modalState}>
      <MobileSuggestedItemsModalDispatch.Provider
        value={{
          updateStepFormValues,
          goToStep,
          openModal,
          closeModal,
          resetModalState,
          setCurrentStepSubmitting,
          setCurrentStepSubmitted,
          setCurrentStepSubmitFailed
        }}
      >
        {children}
      </MobileSuggestedItemsModalDispatch.Provider>
    </MobileSuggestedItemsModalStore.Provider>
  );
};

export default MobileSuggestedItemsModalProvider;
