import { Locale, getDefinitions } from '../../localization';
import translations from './index.loc.json';

export class EquipmentManageLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get EquipmentManagement(): string {
    return this.definitions.EquipmentManagement;
  }

  public get AddEquipment(): string {
    return this.definitions.AddEquipment;
  }

  public get Pricing(): string {
    return this.definitions.Pricing;
  }

  public get EnterYourAskingAndMin(): string {
    return this.definitions.EnterYourAskingAndMin;
  }

  public get AskingPrice(): string {
    return this.definitions.AskingPrice;
  }

  public get AskingPriceColon(): string {
    return this.definitions.AskingPriceColon;
  }

  public get AddAskingPrice(): string {
    return this.definitions.AddAskingPrice;
  }

  public get MinSellPrice(): string {
    return this.definitions.MinSellPrice;
  }

  public get MinSellPriceColon(): string {
    return this.definitions.MinSellPriceColon;
  }

  public get MinSellPriceDot(): string {
    return this.definitions.MinSellPriceDot;
  }

  public get AddMinSellPrice(): string {
    return this.definitions.AddMinSellPrice;
  }

  public get MoovMarketPrice(): string {
    return this.definitions.MoovMarketPrice;
  }

  public get MoovMarketPriceColon(): string {
    return this.definitions.MoovMarketPriceColon;
  }

  public get RequestPrice(): string {
    return this.definitions.RequestPrice;
  }

  public get PotentialBuyers(): string {
    return this.definitions.PotentialBuyers;
  }

  public get InterestedBuyers(): string {
    return this.definitions.InterestedBuyers;
  }

  public get InterestedBuyersColon(): string {
    return this.definitions.InterestedBuyersColon;
  }
  public get ListEquipment(): string {
    return this.definitions.ListEquipment;
  }

  public get Unlist(): string {
    return this.definitions.Unlist;
  }

  public get PricePending(): string {
    return this.definitions.PricePending;
  }

  public get PriceNotAvailable(): string {
    return this.definitions.PriceNotAvailable;
  }

  public get Expired(): string {
    return this.definitions.Expired;
  }

  public get AddCondition(): string {
    return this.definitions.AddCondition;
  }

  public get AddVintage(): string {
    return this.definitions.AddVintage;
  }

  public get AddSerialNumber(): string {
    return this.definitions.AddSerialNumber;
  }

  public get QuantityColon(): string {
    return this.definitions.QuantityColon;
  }

  public get AddQuantity(): string {
    return this.definitions.AddQuantity;
  }

  public get PrivateDetails(): string {
    return this.definitions.PrivateDetails;
  }

  public get ThisInformationIsOnlyViewableByYou(): string {
    return this.definitions.ThisInformationIsOnlyViewableByYou;
  }

  public get Location(): string {
    return this.definitions.Location;
  }

  public get LocationColon(): string {
    return this.definitions.LocationColon;
  }

  public get AddLocation(): string {
    return this.definitions.AddLocation;
  }

  public get InternalNotes(): string {
    return this.definitions.InternalNotes;
  }

  public get AddInternalNotes(): string {
    return this.definitions.AddInternalNotes;
  }

  public get Photos(): string {
    return this.definitions.Photos;
  }

  public get Documents(): string {
    return this.definitions.Documents;
  }

  public get Edit(): string {
    return this.definitions.Edit;
  }

  public get EditEquipment(): string {
    return this.definitions.EditEquipment;
  }

  public get EditEquipmentDescription(): string {
    return this.definitions.EditEquipmentDescription;
  }

  public get AddConfiguration(): string {
    return this.definitions.AddConfiguration;
  }

  public get AddDescription(): string {
    return this.definitions.AddDescription;
  }

  public get AddWaferSizes(): string {
    return this.definitions.AddWaferSizes;
  }

  public get Status(): string {
    return this.definitions.Status;
  }

  public get Make(): string {
    return this.definitions.Make;
  }

  public get Model(): string {
    return this.definitions.Model;
  }

  public get Category(): string {
    return this.definitions.Category;
  }

  public get QTY(): string {
    return this.definitions.QTY;
  }

  public get SerialHash(): string {
    return this.definitions.SerialHash;
  }

  public get ChooseCondition(): string {
    return this.definitions.ChooseCondition;
  }

  public get SelectWaferSize(): string {
    return this.definitions.SelectWaferSize;
  }

  public get RequestMoovMarketPrice(): string {
    return this.definitions.RequestMoovMarketPrice;
  }

  public get KnowTheCurrentMarketPrice(): string {
    return this.definitions.KnowTheCurrentMarketPrice;
  }

  public get RequestPricing(): string {
    return this.definitions.RequestPricing;
  }

  public get Save(): string {
    return this.definitions.Save;
  }

  public get PriceRequested(): string {
    return this.definitions.PriceRequested;
  }

  public get ThankYou(): string {
    return this.definitions.ThankYou;
  }

  public get YourMoovAEWillBeWorkingOnThis(): string {
    return this.definitions.YourMoovAEWillBeWorkingOnThis;
  }

  public get SureToListThisItem(): string {
    return this.definitions.SureToListThisItem;
  }

  public get AllPrivateInformationBeHidden(): string {
    return this.definitions.AllPrivateInformationBeHidden;
  }

  public get ThisItemWillBeDeleted(): string {
    return this.definitions.ThisItemWillBeDeleted;
  }

  public get UnlistEquipment(): string {
    return this.definitions.UnlistEquipment;
  }

  public get SureToUnListThisItem(): string {
    return this.definitions.SureToUnListThisItem;
  }

  public get YourEquipmentHasBeenUpdated(): string {
    return this.definitions.YourEquipmentHasBeenUpdated;
  }

  public get YourEquipmentHasBeenUnlisted(): string {
    return this.definitions.YourEquipmentHasBeenUnlisted;
  }

  public get UploadedSuccessfully(): string {
    return this.definitions.UploadedSuccessfully;
  }

  public get YourEquipmentIsNowListedForSale(): string {
    return this.definitions.YourEquipmentIsNowListedForSale;
  }

  public get WaferSize(): string {
    return this.definitions.WaferSize;
  }

  public get CreatedAt(): string {
    return this.definitions.CreatedAt;
  }

  public get Condition(): string {
    return this.definitions.Condition;
  }

  public get LoadingDots(): string {
    return this.definitions.LoadingDots;
  }

  public get YourChangesHaveBeenSaved(): string {
    return this.definitions.YourChangesHaveBeenSaved;
  }

  public get DeleteQuestion(): string {
    return this.definitions.DeleteQuestion;
  }

  public get Inventory(): string {
    return this.definitions.Inventory;
  }

  public get Listed(): string {
    return this.definitions.Listed;
  }

  public get Disabled(): string {
    return this.definitions.Disabled;
  }

  public get Sold(): string {
    return this.definitions.Sold;
  }

  public get SecondaryMailBoxFolder(): string {
    return this.definitions.SecondaryMailBoxFolder;
  }

  public get ClickAddEquipmentToGetStarted(): string {
    return this.definitions.ClickAddEquipmentToGetStarted;
  }

  public get LookingToManageOrSell(): string {
    return this.definitions.LookingToManageOrSell;
  }

  public get OffloadYourSurplusEquipment(): string {
    return this.definitions.OffloadYourSurplusEquipment;
  }

  public get OperationalStatus(): string {
    return this.definitions.OperationalStatus;
  }

  public get AddOperationalStatus(): string {
    return this.definitions.AddOperationalStatus;
  }

  public get ClickToRequestUpdated(): string {
    return this.definitions.ClickToRequestUpdated;
  }
}
