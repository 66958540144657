import { AppState } from '@/src/store';
import { createSelector } from '@reduxjs/toolkit';
import { CompanyRemarketingInfo } from './types';

export function getBySlugAll(state: AppState) {
  return state.exclusives.bySlug;
}

export function getCurrentSlug(state: AppState) {
  return state.exclusives.currentSlug;
}

export function getLoadingState(state: AppState) {
  return {
    loading: state.exclusives.loading,
    initialized: state.exclusives.initialized
  };
}

export const getCurrentCompanyRemarketingInfo = createSelector(
  [getCurrentSlug, getBySlugAll],
  (currentId, bySlug): CompanyRemarketingInfo | undefined => {
    return bySlug[currentId];
  }
);

export const getAllRemarketingInfo = createSelector([getBySlugAll], (bySlug) => {
  return Object.values(bySlug);
});

export const getRemarketingInfoBySlug = createSelector(
  [getBySlugAll, (_, remarketing_slug: string | undefined) => remarketing_slug],
  (bySlug, remarketing_slug) => {
    return !!remarketing_slug && bySlug[remarketing_slug];
  }
);
