import WorkflowModal from '../../../../bits/modals/workflowModal';
import { useLocalizer } from '../../../../localization';
import { answerQuestion } from '../../../../providers/questions';
import { Textarea } from '../../../../bits/forms/fields/textarea';
import { useAnswerQuestionsState } from './useAnswerQuestionsState';
import { useUser } from '../../../../hooks/useUser';
import { useSuccessMessageListener } from './useSuccessMessageListener';

export const AnswerListingQuestions = () => {
  const loc = useLocalizer();
  const { user } = useUser();

  const {
    currentStep,
    setCurrentStep,
    questionsState,
    setQuestionsState,
    currentValue,
    setCurrentValue,
    closeQuestionsModal,
    incrementOrCloseModal,
    currentQuestionSubmitValue,
    currentQuestionSubmitted,
    currentQuestion
  } = useAnswerQuestionsState();

  useSuccessMessageListener(questionsState);

  const actionButtonSubmit = async () => {
    const currentQuestionId = questionsState[currentStep - 1]?.id;
    const currentQuestion = questionsState[currentStep - 1]?.question;
    const currentAnswerValue = currentValue;

    if (currentQuestionSubmitted) {
      incrementOrCloseModal();
      return;
    }

    return answerQuestion(currentQuestionId, currentAnswerValue, currentQuestion, user?.id).then(() => {
      const newQuestionsState = [...questionsState];
      newQuestionsState[currentStep - 1].submitted = true;
      newQuestionsState[currentStep - 1].submitValue = currentAnswerValue;
      setQuestionsState(newQuestionsState);
      setCurrentValue('');
      incrementOrCloseModal();
    });
  };

  const isActionButtonDisabled = () => {
    if (currentQuestionSubmitted) return false;
    return !currentValue.trim();
  };

  return (
    <WorkflowModal
      modalOpen
      closeModal={closeQuestionsModal}
      backgroundColor={'white'}
      currentStep={currentStep}
      totalSteps={questionsState.length}
      displayProgressBar
      setCurrentStep={setCurrentStep}
      title={loc.Remarketing.BuyerQuestions}
      actionButtonSubmit={actionButtonSubmit}
      dismissButtonSubmit={async () => {
        incrementOrCloseModal();
      }}
      actionButtonDisabled={isActionButtonDisabled()}
      actionButtonContent={
        <div className="text-14spx">{currentStep === questionsState.length ? loc.Common.Submit : loc.Remarketing.NextQuestion}</div>
      }
      dismissButtonContent={<div className="text-14spx">{loc.Forms.Skip}</div>}
    >
      <div className="flex items-center lg:min-w-[700px]">
        <Textarea
          minRows={3}
          maxRows={5}
          disabled={currentQuestionSubmitted}
          label={currentQuestion}
          onChange={(event) => setCurrentValue(event.target.value)}
          value={currentQuestionSubmitValue ? currentQuestionSubmitValue : currentValue}
        />
      </div>
    </WorkflowModal>
  );
};
