import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListingMetadataV2 } from '../../entities/listing';
import { AppState } from '../../store';
import { type SearchListingsParamsAuth } from '../../providers/listings';
import { FILTER_KEYS, STATUSES, statusToParam } from './services';
import { ListingOrderByField } from '../../providers/listings/repo';
import { toRecord } from '@/src/state/services';
import { ListingSerializable } from '../types';
import { conditionOptions, waferSizeOptions } from '@/src/common/lib';

export interface LoadingState {
  error: boolean;
  loading: boolean;
  success: boolean;
}

// TODO: #185279699 Enhance order prop type to support sorting by columns presented in the Tanstack Table
export interface SearchState {
  categories: string[];
  conditions: string[];
  freeText: string;
  // ENG-1878 put this filter back filtering on address country
  // locations: string[];
  makes: string[];
  models: string[];
  private: string;
  status: string[];
  sort: ListingOrderByField | null;
  waferSizes: string[];
}

export interface ListingsState {
  currentId: string;
  byId: Record<string, ListingSerializable>;
  hasLoadedOnce: boolean;
  loading: LoadingState;
  meta: ListingMetadataV2;
  metaLoading: LoadingState;
  search: SearchState;
  sortedIds: number[];
}

const initialState: ListingsState = {
  currentId: '',
  byId: {},
  sortedIds: [],
  hasLoadedOnce: false,
  loading: {
    error: false,
    loading: false,
    success: false
  },
  meta: {
    categories: [],
    conditions: conditionOptions,
    locations: [],
    makes: [],
    models: [],
    statuses: STATUSES,
    waferSizes: waferSizeOptions
  },
  metaLoading: {
    error: false,
    loading: false,
    success: false
  },
  search: {
    private: 'both',
    status: [],
    categories: [],
    conditions: [],
    freeText: '',
    // ENG-1878 put this filter back filtering on address country
    // locations: [],
    makes: [],
    models: [],
    sort: null,
    waferSizes: []
  }
};

export interface SetSearchPayload {
  key: FILTER_KEYS;
  filters: string[];
}

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'listings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearCurrent: (state) => {
      state.currentId = '';
    },
    loading: (state) => {
      state.loading = {
        error: false,
        loading: true,
        success: false
      };
    },
    updateListing: (state, action: PayloadAction<ListingSerializable>) => {
      state.byId[action.payload.id] = action.payload;
    },
    loadSorted: (state, action: PayloadAction<ListingSerializable[]>) => {
      state.byId = toRecord(action.payload);
      state.sortedIds = action.payload.map((listing) => listing.id);
      state.loading = {
        error: false,
        loading: false,
        success: true
      };
      state.hasLoadedOnce = true;
    },
    load: (state, action: PayloadAction<ListingSerializable[]>) => {
      state.byId = toRecord(action.payload);
      state.loading = {
        error: false,
        loading: false,
        success: true
      };
      state.hasLoadedOnce = true;
    },
    resetState: () => {
      return initialState;
    },
    setById: (state, action: PayloadAction<Record<string, ListingSerializable>>) => {
      state.byId = action.payload;
    },
    setCurrent: (state, action: PayloadAction<string>) => {
      /* Redux Toolkit allows us to write "mutating" logic in reducers.
       * It doesn't actually mutate the state because it uses the Immer library,
       * which detects changes to a "draft state" and produces a brand new immutable state based off those changes  */
      state.currentId = action.payload.toString();
    },
    setFreeText: (state, action: PayloadAction<string>) => {
      state.search.freeText = action.payload;
    },
    setMeta: (state, action: PayloadAction<ListingMetadataV2>) => {
      state.meta = action.payload;
    },
    setMetaLoading: (state, action: PayloadAction<LoadingState>) => {
      state.metaLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<SetSearchPayload>) => {
      state.search = {
        ...state.search,
        [action.payload.key]: action.payload.filters
      };
    },
    clearSearch: (state) => {
      state.search = initialState.search;
    },
    setSort: (state, action: PayloadAction<ListingOrderByField | null>) => {
      state.search.sort = action.payload;
    },
    setSortedOrder: (state, action: PayloadAction<number[]>) => {
      state.sortedIds = action.payload;
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const {
  clearCurrent,
  loading,
  loadSorted,
  resetState,
  setById,
  setCurrent,
  setFreeText,
  setMeta,
  setMetaLoading,
  setSearch,
  clearSearch,
  setSort,
  setSortedOrder,
  load,
  updateListing
} = slice.actions;

// ** description: use searchState to generate params for searchListings
export const getSearchParams = (s: AppState): SearchListingsParamsAuth => {
  const categories = s.listings.search.categories;
  const conditions = s.listings.search.conditions;
  // ENG-1878 put this filter back filtering on address country
  // const locations = s.listings.search.locations;
  const makes = s.listings.search.makes;
  const models = s.listings.search.models;
  const sort = s.listings.search.sort;
  const q = s.listings.search.freeText ?? '';

  const statusParams = statusToParam(s.listings.search.status);
  const waferSizes = s.listings.search.waferSizes;
  let result: SearchListingsParamsAuth = {
    category_id: categories,
    condition: conditions,
    make_id: makes,
    model_id: models,
    // TODO: #185258795 replace hard coded limit with pagination value
    limit: 50,
    // ENG-1878 put this filter back filtering on address country
    // location: locations,
    q,
    sortByColumn: true,
    ...statusParams,
    wafer_size: waferSizes
  };
  if (sort) {
    result = {
      ...result,
      sort: sort
    };
  }
  return result;
};

export default slice.reducer;
