import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getLogisticsCountries } from '@/src/state/logistics/thunks';
import { userIsLoggedIn } from '@/src/state/user/selectors';

export const useFetchLogisticCountries = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(userIsLoggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getLogisticsCountries());
    }
  }, [isLoggedIn]);
};
