import { DefaultListingSearchSortValue, ListingSearchSort } from '@/src/providers/listings/constants';
import { FilterValues, SearchFlowType, SearchParams } from './types';
import { Condition, isCondition, isWaferSize, WaferSize } from '@/src/common/lib';
import { isListingSearchSortValue } from '@/src/providers/listings/utils';
import { parseStringI, parseIntI, parseBoolI, parseArrayI } from '@/src/utils';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

export const getEmptyFilterValues = (): FilterValues => ({
  q: '',
  order: ListingSearchSort.PHOTOS,
  category_id: 0,
  make_id: [],
  model_id: [],
  has_photos: false,
  is_exclusive: false,
  condition: [],
  wafer_size: [],
  min_vintage: 0,
  max_vintage: 0,
  search_flow_type: SearchFlowType.PlainText
});

export const getClearedFilterValues = (filterValues: FilterValues): FilterValues => {
  switch (filterValues.search_flow_type) {
    case SearchFlowType.Category:
      return { ...getEmptyFilterValues(), search_flow_type: SearchFlowType.Category, category_id: filterValues.category_id };
    case SearchFlowType.Make:
      return { ...getEmptyFilterValues(), search_flow_type: SearchFlowType.Make, make_id: filterValues.make_id };
    case SearchFlowType.Model:
      return { ...getEmptyFilterValues(), search_flow_type: SearchFlowType.Model, model_id: filterValues.model_id };
    case SearchFlowType.PlainText:
    default:
      return { ...getEmptyFilterValues(), search_flow_type: SearchFlowType.PlainText, q: filterValues.q };
  }
};

/**
 * Filters out any empty/default values to avoid inserting empty values in
 * the resulting URL query string (eg. /search?q=&wafer_size=&condition=)
 */
export const filterOutEmptyFilterValues = (filterValues: Partial<FilterValues>): Partial<FilterValues> => {
  const filtered: ParsedUrlQueryInput = { ...filterValues };

  Object.entries(filtered).forEach(([key, value]) => {
    let isEmpty = false;

    if (!value) {
      isEmpty = true;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        isEmpty = true;
      }

      if (value.every((v) => !v)) {
        isEmpty = true;
      }
    }

    if (key === 'order' && value === DefaultListingSearchSortValue) {
      isEmpty = true;
    }

    if (isEmpty) {
      delete filtered[key];
    }
  });

  return filtered;
};

export const queryToFilterValues = (query: ParsedUrlQuery): FilterValues => {
  const filterValues: FilterValues = {
    ...getEmptyFilterValues(),
    q: parseStringI(query.q),
    category_id: parseIntI(query.category_id),
    has_photos: parseBoolI(query.has_photos),
    is_exclusive: parseBoolI(query.is_exclusive),
    min_vintage: parseIntI(query.min_vintage),
    max_vintage: parseIntI(query.max_vintage),

    // Parse certain legacy values for backwards compatibility:
    // The old search queries used single values for make_id, model_id, condition,
    // and (in certain cases) wafer_size. We're parsing those values here into
    // arrays of integers to fit with the new setup.
    make_id:
      typeof query.make_id === 'string' && parseIntI(query.make_id) > 0
        ? [parseIntI(query.make_id)]
        : parseArrayI(query.make_id).map(parseIntI),

    model_id:
      typeof query.model_id === 'string' && parseIntI(query.model_id) > 0
        ? [parseIntI(query.model_id)]
        : parseArrayI(query.model_id).map(parseIntI),

    condition:
      typeof query.condition === 'string'
        ? ([parseIntI(query.condition)].filter(isCondition) as Condition[])
        : (parseArrayI(query.condition).map(parseIntI).filter(isCondition) as Condition[]),

    wafer_size:
      typeof query.wafer_size === 'string'
        ? ([parseIntI(query.wafer_size)].filter(isWaferSize) as WaferSize[])
        : (parseArrayI(query.wafer_size).map(parseIntI).filter(isWaferSize) as WaferSize[])
  };

  const parsedOrder = parseStringI(query.order);
  if (isListingSearchSortValue(parsedOrder)) {
    filterValues.order = parsedOrder;
  }

  const parsedSearchFlowType = parseStringI(query.search_flow_type);
  if (isSearchFlowType(parsedSearchFlowType)) {
    filterValues.search_flow_type = parsedSearchFlowType;
  }

  return filterValues;
};

export const isSearchFlowType = (value: string | undefined): value is SearchFlowType => {
  return Object.values(SearchFlowType).includes(value as SearchFlowType);
};

export const validateFilterValues = (filterValues: FilterValues): Error | null => {
  const { search_flow_type, q, category_id, make_id, model_id } = filterValues;

  /**
   * Refrained from using the `yup` library here because it can only validate
   * asyncronously which would make error handling more complex for the consumer.
   */
  const v = {
    qIsEmpty: { isValid: q.length === 0, errorMessage: 'Query string is not empty' },
    categoryIsChosen: { isValid: category_id > 0, errorMessage: 'Category ID is not chosen' },
    categoryIsValid: { isValid: category_id >= 0, errorMessage: 'Category ID is not valid' },
    categoryIsEmpty: { isValid: category_id === 0, errorMessage: 'Category ID is not empty' },
    makeIsChosen: { isValid: make_id.length === 1 && make_id[0] > 0, errorMessage: 'Make ID is not chosen' },
    makeIsValid: { isValid: make_id.every((id) => id > 0), errorMessage: 'Make IDs are not valid' },
    makeIsEmpty: { isValid: make_id.length === 0, errorMessage: 'Make IDs are not empty' },
    modelIsChosen: { isValid: model_id.length === 1 && model_id[0] > 0, errorMessage: 'Model ID is not chosen' },
    modelIsValid: { isValid: model_id.every((id) => id > 0), errorMessage: 'Model IDs are not valid' },
    modelIsEmpty: { isValid: model_id.length === 0, errorMessage: 'Model IDs are not empty' }
  };

  const validationQueue: (typeof v)[keyof typeof v][] = [];
  switch (search_flow_type) {
    case SearchFlowType.Category:
      validationQueue.push(v.qIsEmpty, v.categoryIsChosen, v.makeIsValid, v.modelIsEmpty);
      break;
    case SearchFlowType.Make:
      validationQueue.push(v.qIsEmpty, v.categoryIsValid, v.makeIsChosen, v.modelIsValid);
      break;
    case SearchFlowType.Model:
      validationQueue.push(v.qIsEmpty, v.categoryIsEmpty, v.makeIsEmpty, v.modelIsChosen);
      break;
    case SearchFlowType.PlainText:
    default:
      validationQueue.push(v.categoryIsValid, v.makeIsEmpty, v.modelIsEmpty);
      break;
  }

  const errorMsgs = validationQueue.filter(({ isValid }) => !isValid).map(({ errorMessage }) => errorMessage);

  return errorMsgs.length === 0
    ? null
    : new Error(
        `Filter values are invalid for search flow type "${search_flow_type}" due to the following reasons: ${errorMsgs.join(', ')}`,
        { cause: errorMsgs }
      );
};

export const isValidSearchParams = (filterValues: any): filterValues is SearchParams => {
  return validateFilterValues(filterValues) === null;
};
