import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';
import { GlobalAlertBannerType, GlobalBannerDurations } from './types';

export interface GlobalAlertBannerState {
  bannerType: AlertColor;
  visible: boolean;
  duration: number;
  message: string;
}

export const initialState: GlobalAlertBannerState = {
  bannerType: GlobalAlertBannerType.INFO,
  visible: false,
  duration: GlobalBannerDurations.DEFAULT,
  message: ''
};

export const slice = createSlice({
  name: 'alertBanner',
  initialState,
  reducers: {
    openSuccess: (state, action: PayloadAction<{ message: string; duration?: GlobalBannerDurations }>) => {
      const { duration = GlobalBannerDurations.SHORT, message } = action.payload;

      state.visible = true;
      state.bannerType = GlobalAlertBannerType.SUCCESS;
      state.duration = duration;
      state.message = message;
    },
    openError: (state, action: PayloadAction<{ error: string; duration?: GlobalBannerDurations }>) => {
      const { duration = GlobalBannerDurations.DEFAULT, error } = action.payload;

      state.visible = true;
      state.bannerType = GlobalAlertBannerType.ERROR;
      state.duration = duration;
      state.message = error;
    },
    close: () => initialState
  }
});

export const { openSuccess, openError, close } = slice.actions;

export default slice.reducer;
