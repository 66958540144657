import { FC, SyntheticEvent } from 'react';
import { DropDownOption } from '../dropdowns/types';
import { FieldLabel } from '../fieldLabel';
import { StyledAutocomplete } from '../styledAutocomplete';
import { StyledTextInput } from '../styledTextInput';
import { isEmpty } from '../../../../utils';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { autocompleteClasses } from '@mui/material';
import { isDropdownOption } from '../dropdowns/helpers';
import { isOptionEqualToValue } from '../dropdowns/dropdownBase/hooks';
import { shouldShowFieldError } from '../../utils';
import { HelperText } from '../../helperText';

export interface SearchFieldProps {
  value: DropDownOption | null;
  onChange: (value: DropDownOption | null) => void;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  hasErrored?: boolean;
  options: DropDownOption[];
  errorMessage?: string;
  placeholder?: string;
  noOptionsText?: string;
  size?: 'small' | 'medium';
  helperText?: string;
  clearOnBlur?: boolean;
  onBlur?: () => void;
  submitted?: boolean;
  touched?: boolean;
  validateOnTouchOrSubmit?: boolean;
}

export const SearchField: FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const {
    value,
    onChange,
    inputValue,
    onInputChange,
    label,
    required,
    disabled,
    hasErrored,
    options,
    errorMessage,
    placeholder,
    noOptionsText,
    size = 'small',
    helperText,
    clearOnBlur,
    onBlur,
    submitted,
    touched,
    validateOnTouchOrSubmit
  } = props;
  const handleChange = (_: SyntheticEvent<Element, Event>, value: unknown) => {
    if (isDropdownOption(value) || value === null) {
      onChange(value);
    }
  };

  const handleInputChange = (_: SyntheticEvent<Element, Event>, value: string) => {
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleBlur = () => {
    onBlur?.();
  };

  return (
    <FieldLabel
      label={label}
      required={required}
      disabled={disabled}
      hasErrored={hasErrored}
      touched={touched}
      submitted={submitted}
      validateOnTouchOrSubmit={validateOnTouchOrSubmit}
    >
      <StyledAutocomplete
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        popupIcon={disabled || !isEmpty(inputValue) ? null : <SearchIcon />}
        disabled={disabled}
        sx={{
          [`& .${autocompleteClasses.popupIndicator}`]: {
            transform: 'none'
          }
        }}
        clearIcon={<CancelIcon className="text-smokey-gray-400" fontSize="small" />}
        openOnFocus={true}
        options={options}
        getOptionLabel={(option) => (isDropdownOption(option) ? option.label : '')}
        groupBy={(option) => (isDropdownOption(option) ? option?.group ?? '' : '')}
        clearOnBlur={clearOnBlur}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => {
          return (
            <StyledTextInput
              {...params}
              size={size}
              placeholder={placeholder}
              fullWidth
              helperText={
                <HelperText
                  helperText={helperText}
                  hasErrored={hasErrored}
                  errorMessage={errorMessage}
                  disabled={disabled}
                  touched={touched}
                  submitted={submitted}
                  validateOnTouchOrSubmit={validateOnTouchOrSubmit}
                />
              }
              InputProps={{
                ...params.InputProps
              }}
              error={shouldShowFieldError(hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit)}
            />
          );
        }}
      />
    </FieldLabel>
  );
};
