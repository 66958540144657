import { useContext } from 'react';
import { MobileSuggestedItemsModalStore } from '../../state/mobileSuggestedItemsModal/context';
import { findModal } from './modals';

const GlobalModal = () => {
  const { modalOpen, modalType } = useContext(MobileSuggestedItemsModalStore);
  const Modal = findModal({ modalType: modalType });

  return <div>{modalOpen && <Modal />}</div>;
};

export default GlobalModal;
