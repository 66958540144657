import { FC, SyntheticEvent } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { FieldLabel } from '@/src/bits/forms/fields/fieldLabel';
import { LISTING_CONDITION_OPTIONS } from '@/src/providers/listings/constants';
import { SubProps } from '..';
import { DropDownOption } from '@/src/bits/forms/fields/dropdowns/types';

export const Condition: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setCondition = (e: SyntheticEvent<Element, Event>, value: DropDownOption | null) => {
    dispatch({
      type: 'setCondition',
      value: (value && parseInt(value.id, 10)) || ''
    });
  };

  return (
    <FieldLabel label={loc.Listing.Condition}>
      <Autocomplete
        value={LISTING_CONDITION_OPTIONS.find((x) => x.id == state.condition)}
        options={LISTING_CONDITION_OPTIONS}
        onChange={setCondition}
        renderInput={(params) => <TextField {...params} placeholder={loc.EquipmentManage.ChooseCondition} size="medium" />}
        renderOption={(props, option) => {
          return (
            <MenuItem {...props} key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          );
        }}
        data-testid="add-equipment-modal-condition-select"
      />
    </FieldLabel>
  );
};
