import { useEffect } from 'react';

import { useAppDispatch } from '@/src/store';
import { fetchManyCompanyRemarketingInfo } from '@/src/exclusives/thunks';

export const useFetchCompanyRemarketingInfo = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchManyCompanyRemarketingInfo());
  }, []);
};
