import translations from './index.loc.json';
import { Locale, getDefinitions } from '../localization';

/**
 *
 */
export class ToolRequestLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get Requests(): string {
    return this.definitions.Requests;
  }

  public get AddRequest(): string {
    return this.definitions.AddRequest;
  }

  public get ViewAll(): string {
    return this.definitions.ViewAll;
  }

  public get TableSearch(): string {
    return this.definitions.TableSearch;
  }

  public get Priority(): string {
    return this.definitions.Priority;
  }

  public get PriorityHigh(): string {
    return this.definitions.PriorityHigh;
  }

  public get PriorityMedium(): string {
    return this.definitions.PriorityMedium;
  }

  public get PriorityLow(): string {
    return this.definitions.PriorityLow;
  }

  public get Automated(): string {
    return this.definitions.Automated;
  }

  public get DeliveryLocation(): string {
    return this.definitions.DeliveryLocation;
  }

  public get TimeFrameTitle(): string {
    return this.definitions.TimeFrameTitle;
  }

  public get Timeframe(): string {
    return this.definitions.Timeframe;
  }

  public get RequestReason(): string {
    return this.definitions.RequestReason;
  }

  public get Delete(): string {
    return this.definitions.Delete;
  }

  public get ViewTools(): string {
    return this.definitions.ViewTools;
  }

  public get ToolsMatchCriteria(): string {
    return this.definitions.ToolsMatchCriteria;
  }

  public get LetsGetSpecific(): string {
    return this.definitions.LetsGetSpecific;
  }

  public get YourInputImproveMatching(): string {
    return this.definitions.YourInputImproveMatching;
  }

  public get AddBudget(): string {
    return this.definitions.AddBudget;
  }

  // TODO Translations
  public get AddTargetPrice(): string {
    return this.definitions.AddTargetPrice;
  }

  public get RequestPurpose(): string {
    return this.definitions.RequestPurpose;
  }

  public get CreateNewToolRequest(): string {
    return this.definitions.CreateNewToolRequest;
  }

  public get LetUsKnowWhatYouNeed(): string {
    return this.definitions.LetUsKnowWhatYouNeed;
  }

  public get Required(): string {
    return this.definitions.Required;
  }

  public get ChooseTimeFrame(): string {
    return this.definitions.ChooseTimeFrame;
  }

  public get MinimumConfiguration(): string {
    return this.definitions.MinimumConfiguration;
  }

  public get SpecifyTheConfiguration(): string {
    return this.definitions.SpecifyTheConfiguration;
  }

  public get LowPriority(): string {
    return this.definitions.LowPriority;
  }

  public get MediumPriority(): string {
    return this.definitions.MediumPriority;
  }

  public get HighPriority(): string {
    return this.definitions.HighPriority;
  }

  public get Choose(): string {
    return this.definitions.Choose;
  }

  public get TimeframeDescription(): string {
    return this.definitions.TimeframeDescription;
  }

  public get ASAP(): string {
    return this.definitions.ASAP;
  }

  public get WithinAFewWeeks(): string {
    return this.definitions.WithinAFewWeeks;
  }

  public get WithinAFewMonths(): string {
    return this.definitions.WithinAFewMonths;
  }

  public get NoDeadline(): string {
    return this.definitions.NoDeadline;
  }

  public get RequestPurposeDescription(): string {
    return this.definitions.RequestPurposeDescription;
  }

  public get ChooseRequestPurpose(): string {
    return this.definitions.ChooseRequestPurpose;
  }

  public get Inventory(): string {
    return this.definitions.Inventory;
  }

  public get Production(): string {
    return this.definitions.Production;
  }

  public get Refurbishment(): string {
    return this.definitions.Refurbishment;
  }

  public get Parts(): string {
    return this.definitions.Parts;
  }

  public get Deleterequest(): string {
    return this.definitions.Deleterequest;
  }

  public get AreYouSure(): string {
    return this.definitions.AreYouSure;
  }

  public get YouWillNoLongerBeShownNewListings(): string {
    return this.definitions.YouWillNoLongerBeShownNewListings;
  }

  public get Yes(): string {
    return this.definitions.Yes;
  }

  public get No(): string {
    return this.definitions.No;
  }

  public get AddCondition(): string {
    return this.definitions.AddCondition;
  }

  public get AddPriority(): string {
    return this.definitions.AddPriority;
  }

  public get AddWaferSize(): string {
    return this.definitions.AddWaferSize;
  }

  public get AddDeliveryLocation(): string {
    return this.definitions.AddDeliveryLocation;
  }

  public get AddTimeframe(): string {
    return this.definitions.AddTimeframe;
  }

  public get AddRequestPurpose(): string {
    return this.definitions.AddRequestPurpose;
  }

  public get ToolRequestAdded(): string {
    return this.definitions.ToolRequestAdded;
  }

  public get AdditionalInformation(): string {
    return this.definitions.AdditionalInformation;
  }

  public get EnterAnyAdditionalRequests(): string {
    return this.definitions.EnterAnyAdditionalRequests;
  }

  public get Save(): string {
    return this.definitions.Save;
  }

  public get HoldOnWeAreCheckingListings(): string {
    return this.definitions.HoldOnWeAreCheckingListings;
  }

  public get ChangePriority(): string {
    return this.definitions.ChangePriority;
  }

  public get ModifySearchCriteriaAndGetBetterMatches(): string {
    return this.definitions.ModifySearchCriteriaAndGetBetterMatches;
  }

  public get DoesThisWorkForYou(): string {
    return this.definitions.DoesThisWorkForYou;
  }

  public get AdjustSettings(): string {
    return this.definitions.AdjustSettings;
  }

  public get Working(): string {
    return this.definitions.Working;
  }

  public get WeWouldLikeToShareTheseTools(): string {
    return this.definitions.WeWouldLikeToShareTheseTools;
  }

  public get IfYourNeedIsUrgent(): string {
    return this.definitions.IfYourNeedIsUrgent;
  }

  public get AdditionallyLookingForOtherTools(): string {
    return this.definitions.AdditionallyLookingForOtherTools;
  }

  public get Photos(): string {
    return this.definitions.Photos;
  }

  public get Price(): string {
    return this.definitions.Price;
  }

  public get Created(): string {
    return this.definitions.Created;
  }

  public get ToolRequests(): string {
    return this.definitions.ToolRequests;
  }

  public get RequestTheToolsYouNeed(): string {
    return this.definitions.RequestTheToolsYouNeed;
  }

  public get WeHaveManyListings(): string {
    return this.definitions.WeHaveManyListings;
  }

  public get Destination(): string {
    return this.definitions.Destination;
  }

  public get AddDestination(): string {
    return this.definitions.AddDestination;
  }

  public get Budget(): string {
    return this.definitions.Budget;
  }

  // TODO Translations
  public get TargetPrice(): string {
    return this.definitions.TargetPrice;
  }

  public get NoResultsFound(): string {
    return this.definitions.NoResultsFound;
  }

  public get DeletedSuccessfully(): string {
    return this.definitions.DeletedSuccessfully;
  }

  public get SuccessCreatingToolRequest(): string {
    return this.definitions.SuccessCreatingToolRequest;
  }

  public get IncreaseYourListingStrength(): string {
    return this.definitions.IncreaseYourListingStrength;
  }

  public get RequestWasUpdatedSuccessfully(): string {
    return this.definitions.RequestWasUpdatedSuccessfully;
  }

  public get NoLongerSeeSuggestions(): string {
    return this.definitions.NoLongerSeeSuggestions;
  }
}
