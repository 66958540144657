import { FC } from 'react';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

export interface Option {
  id: string;
  label: string;
}

const _isOption = (value: any): value is Option => {
  return typeof value === 'object' && !Array.isArray(value) && 'id' in value && 'label' in value;
};

type AutocompletePropsCustom = Omit<AutocompleteProps<Option, boolean, boolean, boolean>, 'renderInput'>;
export interface CountryAutocompleteProps extends AutocompletePropsCustom {
  options: Option[];
  value: Option | null;
  handleChange: (newValue: Option | null) => void;
  placeholder?: string;
  error?: boolean;
}

export const CountryAutocomplete: FC<CountryAutocompleteProps> = (props) => {
  const handleChange2 = (_: any, newValue: NonNullable<string | Option> | (string | Option)[] | null) => {
    if (newValue == null) {
      props.handleChange(newValue);
    } else if (_isOption(newValue)) {
      props.handleChange(newValue);
    }
  };
  return (
    <Autocomplete
      ListboxProps={{ sx: { maxHeight: '150px' } }}
      {...props}
      onChange={handleChange2}
      renderInput={(inputProps) => <TextField {...inputProps} placeholder={props.placeholder} error={props.error} />}
    />
  );
};
