import { FC } from 'react';
import { InternalLink } from '../../bits/links';
import routes from '../../routes';
import { useSidebar } from '../../hooks/useSidebar';
import { useLocalizer } from '../../localization';
import { NavLink } from '../types';
import { useResolution } from '../../hooks/screen/useResolution';
import {
  DashboardIcon,
  EquipmentIcon,
  ListingsIcon,
  OrdersIcon,
  RequestsIcon,
  SoldIcon,
  SuggestedItemsIcon,
  YourPicksIcon
} from '../../bits/svgs/svgs';
import { getExpandedStyling, getFillColor, getSelectedStyling } from './helpers';
import { useRouter } from 'next/router';

export interface NavItemWithIconProps {
  link:
    | NavLink.DASHBOARD
    | NavLink.BUY
    | NavLink.REQUESTS
    | NavLink.TOOLS_IN_REVIEW
    | NavLink.SUGGESTED_TOOLS
    | NavLink.ORDERS
    | NavLink.SELL
    | NavLink.LISTINGS
    | NavLink.EQUIPMENT
    | NavLink.SOLD;
}

export const NavItemWithIcon: FC<NavItemWithIconProps> = ({ link }) => {
  const router = useRouter();
  const { pathname } = router;
  const { open, transitioning, closeSidebar } = useSidebar();
  const { isMobile } = useResolution();
  const loc = useLocalizer();
  const isOpenState = (open && !transitioning) || isMobile;

  let newRoute;
  let linkName;
  let SvgIcon;

  switch (link) {
    case NavLink.REQUESTS:
      newRoute = routes.requests();
      linkName = loc.ToolRequest.Requests;
      SvgIcon = RequestsIcon;
      break;
    case NavLink.TOOLS_IN_REVIEW:
      newRoute = routes.inReview();
      linkName = isOpenState ? loc.Account.ToolsInReview : loc.Account.InReview;
      SvgIcon = YourPicksIcon;
      break;
    case NavLink.SUGGESTED_TOOLS:
      newRoute = routes.opportunities();
      linkName = isOpenState ? loc.Account.SuggestedTools : loc.Account.Suggested;
      SvgIcon = SuggestedItemsIcon;
      break;
    case NavLink.ORDERS:
      newRoute = routes.orders();
      linkName = loc.Settlement.Orders;
      SvgIcon = OrdersIcon;
      break;
    case NavLink.LISTINGS:
      newRoute = routes.myListings();
      linkName = loc.Nav.Listings;
      SvgIcon = ListingsIcon;
      break;
    case NavLink.EQUIPMENT:
      newRoute = routes.equipment();
      linkName = loc.Nav.Equipment;
      SvgIcon = EquipmentIcon;
      break;
    case NavLink.SOLD:
      newRoute = routes.sellSold();
      linkName = loc.Account.Sold;
      SvgIcon = SoldIcon;
      break;
    case NavLink.DASHBOARD:
      newRoute = routes.dashboard();
      linkName = loc.Nav.Dashboard;
      SvgIcon = DashboardIcon;
      break;
    default:
      throw new Error('Invalid NavLink');
  }

  const isSelected = pathname === newRoute.split('?')[0];

  const getClassName = () => {
    return getExpandedStyling(isOpenState) + getSelectedStyling(isSelected);
  };

  return (
    <InternalLink onClick={closeSidebar} href={newRoute} className={`flex gap-2.5 rounded p-2 uppercase ${getClassName()}`}>
      {SvgIcon ? <SvgIcon fill={getFillColor(isSelected)} className="h-5 w-5" /> : null}
      <div>{linkName.toUpperCase()}</div>
    </InternalLink>
  );
};
