import { FC } from 'react';
import Image from 'next/image';

import { InternalLink } from '../../bits/links';
import routes from '../../routes';
import { useLocalizer } from '../../localization';

export interface MoovLogoProps {
  readonly variant: 'default' | 'edgy';
}

export const MoovLogo: FC<MoovLogoProps> = ({ variant }) => {
  const loc = useLocalizer();

  return (
    <div className="mr-6 flex items-center gap-x-4">
      <InternalLink href={routes.marketplace()} aria-label={loc.Nav.Marketplace}>
        <div className="relative h-12 w-48">
          <Image
            src={
              variant === 'edgy'
                ? 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-dark-blue.svg'
                : '/images/moov-logo-full.png'
            }
            alt="Moov logo"
            className="h-full w-auto"
            layout="fill"
            objectFit="contain"
            quality={100}
          />
        </div>
      </InternalLink>
    </div>
  );
};
