import type { FC } from 'react';
import { useLocalizer } from '../../../localization';
import { ExternalLink } from '../../../bits/links';
import { Subheading1 } from '../../../bits/text';
import { EXTERNAL_URLS } from '../../../constants/externalUrls';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { SocialMediaIcon } from './socialMediaIcon';
import { useAppSelector } from '@/src/store';
import { CMSLocations } from '@/src/state/meta/cmsLinkTypes';
import { getCMSLinksForLocation } from '@/src/state/meta/selectors';

export const SocialMediaSection: FC = () => {
  const loc = useLocalizer();
  const cmsLinks = useAppSelector((state) => getCMSLinksForLocation(state, CMSLocations.FOOTER_SOCIAL_LINKS));
  const weChatLink = cmsLinks.filter((l) => l.label.trim().toLowerCase() == 'wechat')[0];

  return (
    <div>
      <Subheading1 className="mb-5 lg:mb-3">{loc.Footer.FollowUs}</Subheading1>
      <div className="flex gap-6">
        <div className="-ml-1 flex items-center justify-center text-48spx">
          <ExternalLink href={EXTERNAL_URLS.MOOV_FACEBOOK} aria-label="Moov Technologies Facebook">
            <SocialMediaIcon icon={icons.facebookF} />
          </ExternalLink>
        </div>
        <div className="flex items-center justify-center text-48spx">
          <ExternalLink href={EXTERNAL_URLS.MOOV_LINKEDIN} aria-label="Moov Technologies LinkedIn">
            <SocialMediaIcon icon={icons.linkedInIn} />
          </ExternalLink>
        </div>
        <div className="flex items-center justify-center text-48spx">
          <ExternalLink href={EXTERNAL_URLS.MOOV_TWITTER} aria-label="Moov Technologies X">
            <SocialMediaIcon icon={icons.xTwitter} />
          </ExternalLink>
        </div>
        {weChatLink && (
          <div className="flex items-center justify-center text-48spx">
            <ExternalLink href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}${weChatLink.path}`} aria-label="Moov Technologies WeChat">
              <SocialMediaIcon icon={icons.weChat} />
            </ExternalLink>
          </div>
        )}
      </div>
    </div>
  );
};
