import { FC, useState } from 'react';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { Paper, Stack, Typography } from '@mui/material';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { closeModal } from '@/src/state/globalModal/slice';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { getModalState } from '@/src/state/globalModal/selectors';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { colors, spacing } from '@/src/design/designConstants';
import { captureException } from '@sentry/nextjs';
import { useForm } from 'react-hook-form';
import { declineOffer } from '@/src/offers/thunks';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';

export const DeclineOfferModal: FC = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector(getModalState);
  const { fullStoryAndGTMEvent } = useFullStory();
  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState(1);

  const defaultValues = {
    reason: ''
  };
  const hookForm = useForm<{ reason: string }>({
    defaultValues,
    mode: 'all'
  });
  hookForm.watch('reason');

  const { offerKey, listingKey, isBuyer } = modalProps.declineOffer ?? {};
  if (!offerKey || !listingKey) {
    return null;
  }

  return (
    <WorkflowModal
      // TODO: Translations - Decline Offer?
      title="Decline Offer?"
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      // TODO: Translations - CANCEL
      dismissButtonContent={'CANCEL'}
      dismissButtonSubmit={async () => {
        dispatch(closeModal());
      }}
      // TODO: Translations - SUBMIT
      actionButtonContent={'SUBMIT'}
      actionButtonSubmit={async () => {
        fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
          type: OfferCTATypes.ConfirmDeclineOffer,
          offerKey: offerKey,
          listingKey: listingKey
        });
        return dispatch(declineOffer(offerKey, hookForm.getValues('reason').trim(), isBuyer))
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            captureException(e);
            openErrorAlert(ERROR_MESSAGES.REFRESH);
          });
      }}
      actionButtonDisabled={false}
      actionButtonId="decline-offer-modal-submit"
      dismissButtonId="decline-offer-modal-skip"
    >
      <Stack sx={{ width: { d: '600px' } }} spacing={spacing['3xl']}>
        <LabeledTextField
          label={
            <Typography variant="p14">
              <strong>Note*</strong>(optional)
            </Typography>
          }
          onChange={(e) => {
            hookForm.setValue('reason', e.target.value);
          }}
          inputProps={{ 'data-testid': 'decline-offer-reason-input' }}
          value={hookForm.getValues('reason')}
        />

        <Paper elevation={0} sx={{ backgroundColor: colors.moovBlue['50'], py: spacing.xs, px: spacing.md, width: 'fit-content' }}>
          <Typography variant="p14" fontWeight={500}>
            <sup>*</sup>Note is for internal use only, we will not display this to the offering party.
          </Typography>
        </Paper>
      </Stack>
    </WorkflowModal>
  );
};
