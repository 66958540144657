import { FC, useEffect, useState } from 'react';
import FormikTextField from '../../forms/formikFields/formikTextField';
import { UpdateQuestions } from '../../../entities/opportunity';
import { FieldArrayRenderProps } from 'formik';
import { isEmpty } from '../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import { IMAGE_URLS } from '../../../constants/imageUrls';
import { TransparentDarkHoverButton } from '../../buttons';
import { ImageContained } from '../../responsiveImage';
import { isQuestionsFormValid } from './utils';
import { StepFormValues } from '../../../state/mobileSuggestedItemsModal/types';
import { useLocalizer } from '../../../localization';

export interface QuestionsProps {
  formValues: UpdateQuestions;
  arrayHelpers: FieldArrayRenderProps;
  externalUpdateFormValues?: (formValues: StepFormValues) => void;
}

export const Questions: FC<QuestionsProps> = ({ formValues, arrayHelpers, externalUpdateFormValues }) => {
  const [lastQuestionIndex, setLastQuestionIndex] = useState(0);
  const loc = useLocalizer();

  const handleAddQuestion = (arrayHelpers: FieldArrayRenderProps, values: UpdateQuestions): void => {
    if (values.questions.every((question) => !isEmpty(question))) {
      arrayHelpers.push('');
      setLastQuestionIndex(lastQuestionIndex + 1);
    }
  };

  const handleRemoveQuestion = (arrayHelpers: FieldArrayRenderProps, index: number): void => {
    arrayHelpers.remove(index);
    setLastQuestionIndex(lastQuestionIndex - 1);
  };

  useEffect(() => {
    const lastQuestion: HTMLElement | undefined = document.getElementsByName(`questions.${lastQuestionIndex}`)[0];
    if (typeof lastQuestion !== 'undefined') {
      lastQuestion.focus();
    }
  }, [lastQuestionIndex]);

  useEffect(() => {
    externalUpdateFormValues && externalUpdateFormValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <>
      <div className="flex w-full flex-col gap-y-1">
        {formValues.questions.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="flex items-start" key={i}>
            <div className="w-full">
              <FormikTextField
                type="text"
                placeholder={loc.Magic.EnterYourQuestion}
                name={`questions.${i}`}
                colorVariant="white"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  event.key === 'Enter' && handleAddQuestion(arrayHelpers, formValues)
                }
              />
            </div>

            {formValues.questions.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveQuestion(arrayHelpers, i)}
                className="bg-transparent py-2 pl-2 text-gray-400 hover:text-danger-red"
                aria-label={loc.Modals.DeleteQuestion}
              >
                <div className="h-6 w-6 text-24spx">
                  <CloseIcon fontSize="inherit" />
                </div>
              </button>
            )}
          </div>
        ))}
      </div>

      {isQuestionsFormValid(formValues.questions) && (
        <TransparentDarkHoverButton
          onClick={() => handleAddQuestion(arrayHelpers, formValues)}
          className="mb-4 w-full justify-start p-3 text-14spx"
        >
          <ImageContained src={IMAGE_URLS.DARK_BLUE_PLUS_SIGN_CIRCLE} alt="+" className="mr-2 h-7 w-7 lg:h-7 lg:w-7" />
          {loc.Magic.AddQuestion}
        </TransparentDarkHoverButton>
      )}
    </>
  );
};
