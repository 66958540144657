import { ClipboardEvent, ClipboardEventHandler, KeyboardEvent, KeyboardEventHandler } from 'react';

const FORBIDDEN_NUMBER_CHARS = ['e', '+', '-', '.'];
export const handleKeypress = (event: KeyboardEvent<HTMLDivElement>, onKeyPress?: KeyboardEventHandler<HTMLDivElement>) => {
  if (FORBIDDEN_NUMBER_CHARS.includes(event.key)) {
    event.preventDefault();
    return false;
  }
  onKeyPress?.(event);
};

export const handlePaste = (event: ClipboardEvent<HTMLDivElement>, onPaste?: ClipboardEventHandler<HTMLDivElement>) => {
  const pastedText = event.clipboardData.getData('Text');

  if (pastedText.split('').some((char) => FORBIDDEN_NUMBER_CHARS.includes(char.toLowerCase()))) {
    event.preventDefault();
    return false;
  }
  onPaste?.(event);
};
