import { useEffect, useState } from 'react';

/**
 * useTimeout will automatically clear the timeout function when the component
 * that houses this hook is unmounted thereby preventing memory leaks.
 */
export const useTimeout = () => {
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  useEffect(() => {
    if (timeoutId !== null) {
      return () => clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  const clearTO = () => {
    if (timeoutId !== null) clearTimeout(timeoutId);
  };

  const setTO = (handler: TimerHandler, delay?: number) => {
    clearTO();
    setTimeoutId(setTimeout(handler, delay));
  };

  return {
    setTO,
    clearTO
  };
};
