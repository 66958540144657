import { useLocalizer } from '@/src/localization';
import routes from '@/src/routes';
import { ListItemButton, ListItemText, Divider, ListItem } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

export interface PromotedLinksProps {
  onNavigate: () => void;
}
export const PromotedLinks: FC<PromotedLinksProps> = ({ onNavigate }) => {
  const loc = useLocalizer();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (_) => {
    onNavigate();
  };

  // TODO: translations
  return (
    <>
      <ListItem>
        <ListItemButton onClick={handleClick} href={routes.marketplace()}>
          <ListItemText primary={loc.Common.Browse} />
        </ListItemButton>
      </ListItem>

      <Divider />
      <ListItem>
        <ListItemButton onClick={handleClick} href={routes.exclusivesAll()}>
          <ListItemText primary={'Exclusives'} />
        </ListItemButton>
      </ListItem>
    </>
  );
};
