import { captureException } from '@sentry/nextjs';
import { isSuggestedListing, SuggestedListing } from '../../entities/listing';
import { fetchRequestSuggestedListings, fetchUserSuggestedListings, FetchUserSuggestedListingsParams } from '../../providers/listings';
import { OpportunityActions, OpportunityModalState } from '../../state/opportunities/types';

export const fetchBionicOpportunities = (
  params: FetchUserSuggestedListingsParams,
  bionicId: string,
  actions: OpportunityActions,
  state: OpportunityModalState,
  initalListingKey?: string
) => {
  const { selectCurrentOpportunity, loadOpportunities, fetchOpportunities: fetchOpportunitiesAction, closeModal } = actions;
  fetchOpportunitiesAction();
  fetchUserSuggestedListings({ ...params, bionic_message_id: bionicId })
    .then((listings) => {
      if (listings.length === 0) {
        closeModal();
        return;
      }
      const selectDefaultCurrentListing = () => {
        loadOpportunities(listings);
        selectCurrentOpportunity(listings[0].opportunity_key);
      };
      // Sets the currentListing on first load
      if (listings.length > 0 && !isSuggestedListing(state.currentListing)) {
        if (initalListingKey) {
          const initialListingI = listings.findIndex((listing) => listing.key === initalListingKey);
          if (initialListingI < 0) {
            // the listing was disabled so we filtered it out
            selectDefaultCurrentListing();
            return;
          }

          const initialListing = listings.find((listing) => listing.key === initalListingKey) as SuggestedListing;
          listings.splice(initialListingI, 1);
          listings.unshift(initialListing);

          loadOpportunities(listings); // DO NOT MOVE. must be done first or selecting current opp wont work and will brick suggested ops emails
          selectCurrentOpportunity(initialListing?.opportunity_key || listings[0]?.opportunity_key);
        } else {
          selectDefaultCurrentListing();
        }
      }
    })
    .catch((err) => {
      // setErrorMessage(err); TODO: address alert banner
      closeModal();
      captureException(err);
    })
    .finally(() => {
      actions.completeInitialFetchOpportunities();
    });

  return {};
};

export const fetchUserOpportunities = (
  params: FetchUserSuggestedListingsParams,
  actions: OpportunityActions,
  state: OpportunityModalState,
  initalListingKey?: string,
  includeAllOpportunities?: boolean
) => {
  const { selectCurrentOpportunity, loadOpportunities, fetchOpportunities: fetchOpportunitiesAction } = actions;

  fetchOpportunitiesAction();

  const finalParams = { ...params };
  if (includeAllOpportunities) {
    finalParams.limit = 50;
  } else {
    finalParams.is_seen = false;
    finalParams.limit = 10;
  }

  fetchUserSuggestedListings(finalParams)
    .then((listings) => {
      const selectDefaultCurrentListing = () => {
        loadOpportunities(listings);
        selectCurrentOpportunity(listings[0].opportunity_key);
      };
      // Sets the currentListing on first load
      if (listings.length > 0 && !isSuggestedListing(state.currentListing)) {
        if (initalListingKey) {
          const initialListingI = listings.findIndex((listing) => listing.key === initalListingKey);
          if (initialListingI < 0) {
            // the listing was disabled so we filtered it out
            selectDefaultCurrentListing();
            return;
          }

          const initialListing = listings.find((listing) => listing.key === initalListingKey) as SuggestedListing;
          listings.splice(initialListingI, 1);
          listings.unshift(initialListing);

          loadOpportunities(listings); // DO NOT MOVE. must be done first or selecting current opp wont work and will brick suggested ops emails
          selectCurrentOpportunity(initialListing?.opportunity_key || listings[0]?.opportunity_key);
        } else {
          selectDefaultCurrentListing();
        }
      } else {
        loadOpportunities(listings);
      }
    })
    .catch((err) => {
      captureException(err);
    })
    .finally(() => {
      actions.completeInitialFetchOpportunities();
    });

  return {};
};

export const fetchRequestOpportunities = (wantedId: string, actions: OpportunityActions, _state: OpportunityModalState) => {
  const { selectCurrentOpportunity, loadOpportunities, fetchOpportunities: fetchOpportunitiesAction } = actions;

  fetchOpportunitiesAction();

  return fetchRequestSuggestedListings(wantedId)
    .then((listings) => {
      loadOpportunities(listings);
      selectCurrentOpportunity(listings[0].opportunity_key);
    })
    .catch((err) => {
      captureException(err);
    })
    .finally(() => {
      actions.completeInitialFetchOpportunities();
    });
};
