import { captureException } from '@sentry/nextjs';
import { AppDispatch, AppState } from '../store';
import { Model } from '../entities/model';
import { searchModels as searchModelsOld } from '../providers/models';

export const searchModels = (queryTerm: string, limit = 10) => {
  return async (_: AppDispatch, __: () => AppState): Promise<Model[]> => {
    return searchModelsOld({ q: queryTerm, limit }).catch((e) => {
      captureException(e);
      return [];
    });
  };
};
