import {
  getDefinitions,
  interpolateVariable,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class CategoryMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public BuySellUsedCategory(categoryName: string): string {
    return interpolateVariable(this.definitions.BuySellUsedCategory, 'category_name', categoryName);
  }

  public get HaveOneToSell(): string {
    return this.definitions.HaveOneToSell;
  }

  public get LaserCutterWithPurpleGlow(): string {
    return this.definitions.LaserCutterWithPurpleGlow;
  }

  public ManufacturersOfCategory(categoryName: string): string {
    return interpolateVariable(this.definitions.ManufacturersOfCategory, 'category_name', categoryName);
  }

  public get NoMakesFound(): string {
    return this.definitions.NoMakesFound;
  }

  public TopProductsForCategory(categoryName: string): string {
    return interpolateVariable(this.definitions.TopProductsForCategory, 'category_name', categoryName);
  }

  public UsedCategoryForSale(categoryName: string): string {
    return interpolateVariable(this.definitions.UsedCategoryForSale, 'category_name', categoryName);
  }
}
