import { deconstruct, getDefinitions, interpolateVariable, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class CareersLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AdditionToGreat(): string {
    return this.definitions.AdditionToGreat;
  }

  public get BuildingForLongTerm(): string {
    return this.definitions.BuildingForLongTerm;
  }

  public get B2BMarketplaceCareers(): string {
    return this.definitions.B2BMarketplaceCareers;
  }

  public get CelebratingGivingChampioning(): string {
    return this.definitions.CelebratingGivingChampioning;
  }

  public get ComeJoinUs(): string {
    return this.definitions.ComeJoinUs;
  }

  public get Comforts(): string {
    return this.definitions.Comforts;
  }

  public get Compensatiion(): string {
    return this.definitions.Compensatiion;
  }

  public get CompensationPerformanceBonus(): string {
    return this.definitions.CompensationPerformanceBonus;
  }

  public get Culture(): string {
    return this.definitions.Culture;
  }

  public get CupOnTableHustle(): string {
    return this.definitions.CupOnTableHustle;
  }

  public get Development(): string {
    return this.definitions.Development;
  }

  public get DoYouThrive(): string {
    return this.definitions.DoYouThrive;
  }

  public get EmployeeLearning(): string {
    return this.definitions.EmployeeLearning;
  }

  public get FirstRateInsurance(): string {
    return this.definitions.FirstRateInsurance;
  }

  public get FunEnvironment(): string {
    return this.definitions.FunEnvironment;
  }

  public get GlassdoorStars(): string {
    return this.definitions.GlassdoorStars;
  }

  public GlassdoorStarsTuple(): [string, string, string] {
    return deconstruct(this.definitions.GlassdoorStars);
  }

  public get GloriousMoustacheMan(): string {
    return this.definitions.GloriousMoustacheMan;
  }

  public get HelpAccelerate(): string {
    return this.definitions.HelpAccelerate;
  }

  public get HelpBuildComplexTechnology(): string {
    return this.definitions.HelpBuildComplexTechnology;
  }

  public get HighGrowth(): string {
    return this.definitions.HighGrowth;
  }

  public get HighViewOfOffice(): string {
    return this.definitions.HighViewOfOffice;
  }

  public get InterestedInJobsExplore(): string {
    return this.definitions.InterestedInJobsExplore;
  }

  public get LookingForCommunity(): string {
    return this.definitions.LookingForCommunity;
  }

  public get MoovCareerForward(): string {
    return this.definitions.MoovCareerForward;
  }

  public get MoovTogether(): string {
    return this.definitions.MoovTogether;
  }

  public get NitroColdBrewMore(): string {
    return this.definitions.NitroColdBrewMore;
  }

  public get PeopleAroundLaptop(): string {
    return this.definitions.PeopleAroundLaptop;
  }

  public get PeopleInGolfCart(): string {
    return this.definitions.PeopleInGolfCart;
  }

  public get PeopleInPresentation(): string {
    return this.definitions.PeopleInPresentation;
  }

  public get PeopleSittingClose(): string {
    return this.definitions.PeopleSittingClose;
  }

  public get PeopleWithArmsAround(): string {
    return this.definitions.PeopleWithArmsAround;
  }

  public get PlayHard(): string {
    return this.definitions.PlayHard;
  }

  public get SeeOpenings(): string {
    return this.definitions.SeeOpenings;
  }

  public get SolvingPressingChallenges(): string {
    return this.definitions.SolvingPressingChallenges;
  }

  public get SoundRewardingToYou(): string {
    return this.definitions.SoundRewardingToYou;
  }

  public get Technology(): string {
    return this.definitions.Technology;
  }

  public get UnlimitedPaidTime(): string {
    return this.definitions.UnlimitedPaidTime;
  }

  public get Vacation(): string {
    return this.definitions.Vacation;
  }

  public get WeMoovTogether(): string {
    return this.definitions.WeMoovTogether;
  }

  public get WhyLoveMoov(): string {
    return this.definitions.WhyLoveMoov;
  }

  public get WideViewOfOffice(): string {
    return this.definitions.WideViewOfOffice;
  }

  public get WorkHard(): string {
    return this.definitions.WorkHard;
  }

  public get WorkWithUs(): string {
    return this.definitions.WorkWithUs;
  }

  public get WorldClassTechStack(): string {
    return this.definitions.WorldClassTechStack;
  }

  public get MoovValues(): string {
    return this.definitions.MoovValues;
  }

  public get MoovFAST(): string {
    return this.definitions.MoovFAST;
  }

  public get WeMoovInAWay(): string {
    return this.definitions.WeMoovInAWay;
  }

  public get EightyResultsTwentyEffort(): string {
    return this.definitions.EightyResultsTwentyEffort;
  }

  public get Use20Effort80Results(): string {
    return this.definitions.Use20Effort80Results;
  }

  public get TakeOwnership(): string {
    return this.definitions.TakeOwnership;
  }

  public get WeTakeOwnership(): string {
    return this.definitions.WeTakeOwnership;
  }

  public get IntegrityOverDollars(): string {
    return this.definitions.IntegrityOverDollars;
  }

  public get WeValueBeingHonest(): string {
    return this.definitions.WeValueBeingHonest;
  }

  public get GoTheExtraMile(): string {
    return this.definitions.GoTheExtraMile;
  }

  public get ToGoExtraMile(): string {
    return this.definitions.ToGoExtraMile;
  }

  public get LessIsMore(): string {
    return this.definitions.LessIsMore;
  }

  public get SayLessDeliverMore(): string {
    return this.definitions.SayLessDeliverMore;
  }

  public get OnlyOutcomesMatter(): string {
    return this.definitions.OnlyOutcomesMatter;
  }

  public get WeAreAMeritocracy(): string {
    return this.definitions.WeAreAMeritocracy;
  }

  public get CommunicationIsLeadership(): string {
    return this.definitions.CommunicationIsLeadership;
  }

  public get WeCommunicateCourageously(): string {
    return this.definitions.WeCommunicateCourageously;
  }

  public get One(): string {
    return this.definitions.One;
  }

  public get Two(): string {
    return this.definitions.Two;
  }

  public get Three(): string {
    return this.definitions.Three;
  }

  public get Four(): string {
    return this.definitions.Four;
  }

  public get Five(): string {
    return this.definitions.Five;
  }

  public get Six(): string {
    return this.definitions.Six;
  }

  public get Seven(): string {
    return this.definitions.Seven;
  }

  public get Eight(): string {
    return this.definitions.Eight;
  }

  public get Nine(): string {
    return this.definitions.Nine;
  }

  public get Ten(): string {
    return this.definitions.Ten;
  }

  public get JobOpenings(): string {
    return this.definitions.JobOpenings;
  }

  public OpenJobPosting(title: string, location: string): string {
    const message = interpolateVariable(this.definitions.OpenJobPosting, 'title', title);
    return interpolateVariable(message, 'location', location);
  }

  public get NoJobOpenings(): string {
    return this.definitions.NoJobOpenings;
  }

  public get Title(): string {
    return this.definitions.Title;
  }

  public get Location(): string {
    return this.definitions.Location;
  }

  public get FilterByLocation(): string {
    return this.definitions.FilterByLocation;
  }

  public get AllLocations(): string {
    return this.definitions.AllLocations;
  }

  public get Careers(): string {
    return this.definitions.Careers;
  }
}
