import { FC } from 'react';
import { Offer, ReadableOfferStatus, SellerOffer } from './types';
import { Chip, Stack } from '@mui/material';
import { FAIcon } from '../design/bits/fAIcon/fAIcon';
import { icons } from '../design/bits/fAIcon/icons';
import { spacing } from '../design/designConstants';
import { getStatusColor } from './utils';
import { ChipProps } from '@mui/material';

export const OfferStatusChip: FC<{ offer: Offer | SellerOffer } & ChipProps> = ({ offer, ...chipProps }) => {
  const color: 'success' | 'primary' | 'warning' | 'error' = getStatusColor(offer.readable_status);
  let label = <></>;

  switch (offer.readable_status) {
    case ReadableOfferStatus.PENDING:
      label = (
        <Stack direction="row" alignItems="center" spacing={spacing.xs}>
          <FAIcon icon={icons.clockFilled} scale={1} />
          <strong>{offer.readable_status}</strong>
        </Stack>
      );

      break;
    case ReadableOfferStatus.ACCEPTED:
      label = (
        <Stack direction="row" alignItems="center" spacing={spacing.xs}>
          <FAIcon icon={icons.circleCheck} scale={1} />
          <strong>{offer.readable_status}</strong>
        </Stack>
      );
      break;
    case ReadableOfferStatus.DECLINED:
    case ReadableOfferStatus.EXPIRED:
      label = (
        <Stack direction="row" alignItems="center" spacing={spacing.xs}>
          <FAIcon icon={icons.circleExclamation} scale={1} />
          <strong>{offer.readable_status}</strong>
        </Stack>
      );
      break;
  }

  return <Chip size="small" variant={'outlined'} color={color} label={label} {...chipProps} />;
};
