import { FC } from 'react';
import { BuyerOffer } from '../../types';
import { Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { borderRadius, spacing } from '@/src/design/designConstants';
import { OfferStatusChip } from '../../statusChip';

export const OfferHeader: FC<{ buyerOffer: BuyerOffer }> = ({ buyerOffer }) => {
  return (
    <Stack direction="row" spacing={spacing.lg}>
      <Stack width="25%">
        <Image
          alt={`listing-image-${buyerOffer.listing.make_model}`}
          src={buyerOffer.listingSmallPhotoUrl}
          quality={100}
          objectFit="cover"
          height={'100%'}
          width={'100%'}
          style={{ borderRadius: borderRadius.rounded }}
        />
      </Stack>
      <Stack width="75%" spacing={spacing.lg}>
        <Typography variant="h6">{buyerOffer.listing.make_model}</Typography>
        <OfferStatusChip offer={buyerOffer.offer} sx={{ padding: '0px', width: 'fit-content' }} />
      </Stack>
    </Stack>
  );
};
