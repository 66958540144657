import { TextFieldVariants, TextFieldProps, TextField } from '@mui/material';
import { KeyboardEventHandler } from 'react';

export type NumberInputProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> & {
  value: string;
};

export const NumberInput = function <Variant extends TextFieldVariants = TextFieldVariants>(props: NumberInputProps<Variant>) {
  // do not allow 'e' or 'E' in number inputs
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    (event.key === 'e' || event.key === 'E') && event.preventDefault();
  };

  return (
    <TextField
      onKeyDown={onKeyDown}
      data-testid="NumberInput"
      {...{
        ...props,
        type: 'number',
        inputProps: { ...props.inputProps, inputMode: 'numeric', pattern: '[0-9]*' },
        InputProps: { ...props.InputProps }
      }}
    />
  );
};
