import { FC, useEffect, useState } from 'react';

import Footer from './footer';
import CookieConsentPopup from './cookieManagement/cookieConsentPopup';
import SkipLink from './skipLink';
import { CookiePreferences, getCookiePrefs } from '../utils/cookies';
import { Sidebar } from '../sidebar';
import { useInternalUserBanner } from '../hooks/useInternalUserBanner';
import { useInitializeFullStory } from './useInitializeFullStory';
import { Header } from './header';

/**
 *
 */

export const Layout: FC = ({ children }) => {
  const [_cookiePrefs, setCookiePrefs] = useState<CookiePreferences | null>(null);
  const { minSidebarHeightClass } = useInternalUserBanner();
  useInitializeFullStory();

  useEffect(() => {
    setCookiePrefs(getCookiePrefs(document));
  }, []);

  return (
    <>
      <SkipLink />

      <CookieConsentPopup />

      <Header />

      <div className={`flex ${minSidebarHeightClass}`}>
        <Sidebar />
        <div className="flex w-0 grow flex-col" data-testid="LayoutContentContainer">
          <main className="flex flex-1 flex-col bg-smokey-gray-50">{children}</main>
          <Footer />
        </div>
      </div>
    </>
  );
};
