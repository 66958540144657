import { FC, useEffect } from 'react';

import { ErrorMessage, Form, FormikErrors } from 'formik';

import { getFieldDescribedById, isEmpty } from '../../../utils';
import { LoadingOverlay } from '../loaders';
import DeprecatedRequired from '../required';

// -- #form wrappers
// -----------------------------------------------

export interface FormikFormWrapperProps {
  readonly isSubmitting: boolean;
  readonly errors: FormikErrors<{ [key: string]: any }>;
  readonly submitCount: number;
  readonly className?: string;
}

/**
 *
 */
export const FormikFormWrapper: FC<FormikFormWrapperProps> = (props) => {
  const { isSubmitting, children, errors, submitCount, className } = props;

  useEffect(() => {
    if (submitCount > 0 && Object.keys(errors).length > 0) {
      const firstErroredEl: HTMLElement | undefined = document.getElementsByName(Object.keys(errors)[0])[0];
      if (typeof firstErroredEl !== 'undefined') {
        firstErroredEl.focus();
      }
    }
  }, [errors, submitCount]);

  return (
    <Form noValidate className={`relative ${className}`}>
      <LoadingOverlay isLoading={isSubmitting} />
      {children}
    </Form>
  );
};

// -- #field wrappers
// -----------------------------------------------

interface FormikFieldWrapperProps {
  readonly name: string;
  readonly label?: string;
  readonly helperText?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly touched: boolean;
  readonly error?: string;
  readonly ariaLabel?: string;
  readonly reserveSpaceBelow?: boolean;
}

/**
 *
 */
export const FormikFieldWrapper: FC<FormikFieldWrapperProps> = (props) => {
  const { name, label, helperText, required, disabled, touched, error, children, reserveSpaceBelow = true } = props;

  return (
    <div>
      <label className="flex w-full flex-col" aria-label={name}>
        {!isEmpty(label) && (
          <p className="mb-1.5 text-14spx font-bold tracking-wide">
            {label}
            {required && <DeprecatedRequired />}
          </p>
        )}

        <div className={`flex w-full ${disabled ? 'form-group-disabled' : ''}`}>{children}</div>
      </label>
      <p id={getFieldDescribedById(name)} className={`text-12spx ${reserveSpaceBelow ? 'mt-1 min-h-[1.5em]' : ''}`}>
        {touched && !isEmpty(error) ? (
          <ErrorMessage className="text-danger-red" component="span" name={name} />
        ) : (
          <span className="text-moov-blue-500">{helperText}</span>
        )}
      </p>
    </div>
  );
};

interface FormikCheckboxRadioGroupWrapperProps {
  readonly name: string;
  readonly label?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly reserveSpaceBelow?: boolean;
}

/**
 *
 */
export const FormikCheckboxRadioGroupWrapper: FC<FormikCheckboxRadioGroupWrapperProps> = (props) => {
  const { name, label, required, disabled, children, reserveSpaceBelow = true } = props;

  return (
    <div>
      <label className="flex w-full flex-col">
        {!isEmpty(label) && (
          <p className="mb-1.5 text-14spx font-bold tracking-wide">
            {label}
            {required && <DeprecatedRequired />}
          </p>
        )}

        <div className={`flex w-full flex-col gap-y-2 ${disabled ? 'form-group-disabled' : ''}`}>{children}</div>
      </label>

      <p id={getFieldDescribedById(name)} className={`text-12spx text-danger-red ${reserveSpaceBelow ? 'mt-1 min-h-[1.5em]' : ''}`}>
        <ErrorMessage component="span" name={name} />
      </p>
    </div>
  );
};

interface FormikToggleSwitchWrapperProps {
  readonly label?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
}

/**
 *
 */
export const FormikToggleSwitchWrapper: FC<FormikToggleSwitchWrapperProps> = (props) => {
  const { label, required, disabled, children } = props;

  return (
    <div>
      <label className="mb-1 flex w-full flex-col">
        {!isEmpty(label) && (
          <p className="mb-1.5 text-14spx font-bold tracking-wide">
            {label}
            {required && <DeprecatedRequired />}
          </p>
        )}

        <div className={`flex w-full flex-col gap-y-2 ${disabled ? 'form-group-disabled' : 'cursor-pointer'}`}>{children}</div>
      </label>
    </div>
  );
};
