import debounce from 'debounce';
import { useState, useMemo, useEffect } from 'react';
import { RESOLUTION } from '../../../constants/device';

type DeviceType = 'mobile' | 'desktop';
export const isWindowWidthMobile = () => window.innerWidth < RESOLUTION.DESKTOP;

/**
 * Use MUI breakpoints and display prop instead
 @deprecated
 */
export const useResolution = () => {
  const [deviceType, setDeviceType] = useState<DeviceType | null>(null);
  const [windowX, setWindowX] = useState<number | null>(null);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        setWindowX(window.innerWidth);

        if (isWindowWidthMobile()) {
          setDeviceType('mobile');
        } else {
          setDeviceType('desktop');
        }
      }, 100),
    []
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return {
    isMobile: deviceType === 'mobile',
    isDesktop: deviceType === 'desktop',
    windowX
  };
};
