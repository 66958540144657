import { Autocomplete, styled } from '@mui/material';
import TAILWIND_CSS_CONFIG from '../../../../tailwind.config';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  ['.MuiChip-root']: {
    color: TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500'],
    fontFamily: TAILWIND_CSS_CONFIG.theme.fontFamily.inter[0],
    fontSize: '0.75rem',
    height: '1.5rem'
  },
  ['.MuiChip-root .MuiChip-deleteIcon']: {
    fontSize: '1rem'
  },
  ['.MuiChip-label']: {
    paddingLeft: '0.5rem',
    paddingRight: '0.65rem'
  }
}));
