import { DropDownOption } from '@/src/bits/forms/fields/dropdowns/types';

export interface DTOLead {
  user_id: number;
  key: string;
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
}

export const isDTOLeadDefined = (rando: unknown): rando is DTOLead => {
  const testMe = rando as DTOLead;
  return typeof testMe === 'object' && !!testMe?.email;
};

export const ContactPreference = {
  WeChat: 1,
  WhatsApp: 2,
  KakaoTalk: 3,
  Skype: 4,
  LinkedIn: 5,
  SMS: 6,
  PhoneCall: 7,
  Email: 8,
  LINE: 9
};
export type ContactPreference = (typeof ContactPreference)[keyof typeof ContactPreference];

export const contactPreferences: { id: ContactPreference; label: string }[] = [
  { id: ContactPreference.Email, label: 'Email' },
  { id: ContactPreference.SMS, label: 'SMS' },
  { id: ContactPreference.LINE, label: 'LINE' },
  { id: ContactPreference.KakaoTalk, label: 'KakaoTalk' },
  { id: ContactPreference.WhatsApp, label: 'WhatsApp' },
  { id: ContactPreference.PhoneCall, label: 'Phone Call' }
];

export const contactPreferenceOptions: DropDownOption[] = contactPreferences.map((e) => ({ id: `${e.id}`, label: e.label }));

export const contactPreferencesForPhone = [ContactPreference.SMS, ContactPreference.PhoneCall];
export const contactPreferencesWithHandles = [
  ContactPreference.WhatsApp,
  ContactPreference.KakaoTalk,
  ContactPreference.Skype,
  ContactPreference.LinkedIn,
  ContactPreference.LINE
];
