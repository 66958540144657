import NotInterested from './notInterested';
import ShowNext from './showNext';
import MobileQuestionsForm from './mobileQuestionsForm';
import MobileSuggestedItems from './mobileSuggestedItems';
import { MobileSuggestedItemsModalStore } from '../../../../state/mobileSuggestedItemsModal/context';
import { useContext } from 'react';
import WorkflowModalBase from '../workflowBase';
import { useRouter } from 'next/router';
import { useOpportunities } from '../../../../hooks/opportunities/useOpportunities';
import { useEffect } from 'react';

const ContentDecider: React.FC = () => {
  const { currentStep } = useContext(MobileSuggestedItemsModalStore);
  const { fetchOpportunities } = useOpportunities(true);
  const router = useRouter();

  useEffect(() => {
    const isBionic = !!router.query.bionicId;

    return () => {
      if (isBionic) {
        const { pathname, query } = router;
        //Deleting this bionicId is so we don't keep re-fetching
        //opportunities on subsequent closings of this modal
        delete router.query.bionicId;
        router.replace({ pathname, query }, undefined, { shallow: true });
      }

      //We are using the useOpportunities fetch here to fetch
      //suggested items again to refresh the table when you close the modal
      fetchOpportunities({});
    };
    //This eslint disable is to ensure the cleanup function
    //only runs when the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StepToRender = [MobileSuggestedItems, NotInterested, MobileQuestionsForm, ShowNext][currentStep];

  return StepToRender ? (
    <WorkflowModalBase>
      <div className="h-full overflow-y-auto" data-testid="mobile_suggested_items_tool_tinder">
        <StepToRender />
      </div>
    </WorkflowModalBase>
  ) : null;
};

export default ContentDecider;
