import { FC } from 'react';
import { FieldLabel } from '@/src/bits/forms/fields/fieldLabel';
import { SubProps } from '..';
import { toolStatusOptions } from '@/src/equipment/types';
import { SelectWithOptions } from '@/src/design/bytes/selectWithOptions';
import { useLocalizer } from '@/src/localization';

export const ToolStatus: FC<SubProps> = ({ state, dispatch }) => {
  const loc = useLocalizer();
  const setToolStatus = (value: string | null) => {
    const selectedOption = toolStatusOptions.find((x) => x.id == value);
    dispatch({
      type: 'setToolStatus',
      value: (selectedOption && parseInt(selectedOption?.id)) || ''
    });
  };

  return (
    <FieldLabel label={loc.EquipmentManage.OperationalStatus}>
      <SelectWithOptions
        value={toolStatusOptions.find((x) => x.id == state.toolStatus)?.id || ''}
        options={toolStatusOptions}
        size="medium"
        handleChange={(val: string) => setToolStatus(val)}
        data-testid="add-equipment-modal-operational-status-select"
      />
    </FieldLabel>
  );
};
