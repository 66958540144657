import { createContext } from 'react';
import { ModalTypes } from '../../globalComponents/mobileSuggestedItemsModal/modals/types';
import { MobileSuggestedItemsModalState, ModalProps, StepFormValues, WorkflowButtons } from './types';
import { updateCurrentStepFormValues, updateCurrentStepLoading } from './utils';

export const getFreshMobileSuggestedItemsModalState = (): MobileSuggestedItemsModalState => ({
  modalOpen: false,
  modalType: ModalTypes.Empty,
  props: {
    title: '',
    steps: [],
    previousStep: 0
  },
  currentStep: 0
});

export const initialMobileSuggestedItemsModalState = getFreshMobileSuggestedItemsModalState();

export const MobileSuggestedItemsModalReducer = (state: MobileSuggestedItemsModalState, action: any): MobileSuggestedItemsModalState => {
  switch (action.type) {
    case 'goToStep': {
      if (state.props.steps) {
        return {
          ...state,
          currentStep: action.n,
          props: {
            ...state.props,
            steps: updateCurrentStepFormValues(state, state.props.steps, {}),
            previousStep: action.props?.previousStep || state.currentStep
          }
        };
      }
    }
    case 'setCurrentStepSubmitting':
      if (state.props.steps) {
        return {
          ...state,
          props: {
            ...state.props,
            steps: updateCurrentStepLoading(
              state,
              state.props.steps,
              { submitting: true, submitFailed: false, submitted: false },
              action.payload.button
            )
          }
        };
      }
    case 'setCurrentStepSubmitted':
      if (state.props.steps) {
        return {
          ...state,
          props: {
            ...state.props,
            steps: updateCurrentStepLoading(
              state,
              state.props.steps,
              { submitting: false, submitFailed: false, submitted: true },
              action.payload.button
            )
          }
        };
      }
    case 'setCurrentStepSubmitFailed':
      if (state.props.steps) {
        return {
          ...state,
          props: {
            ...state.props,
            steps: updateCurrentStepLoading(
              state,
              state.props.steps,
              { submitting: false, submitFailed: true, submitted: false },
              action.payload.button
            )
          }
        };
      }
    case 'updateStepFormValues':
      if (state.props.steps) {
        return {
          ...state,
          props: { ...state.props, steps: updateCurrentStepFormValues(state, state.props.steps, action.payload.formValues) }
        };
      }
    case 'openModal':
      return { ...state, modalOpen: true, modalType: action.payload.modalType, props: action.payload.props };
    case 'closeModal':
      return getFreshMobileSuggestedItemsModalState();
    case 'resetState':
      return getFreshMobileSuggestedItemsModalState();
    default:
      return state;
  }
};

export type OpenGlobalModalAction = (actionPayload: { modalType: ModalTypes; props: ModalProps }) => void;
export type CloseGlobalModalAction = () => void;
export type ResetGlobalModalStateAction = () => void;
export type GoToStepAction = (n: number, props?: ModalProps) => void;
export type UpdateStepFormValues = (formValues: StepFormValues) => void;
export type CurrentStepSubmittingAction = (button: WorkflowButtons) => void;
export type CurentStepSubmittedAction = (button: WorkflowButtons) => void;
export type CurrentStepSubmitFailedAction = (button: WorkflowButtons) => void;
export type MobileSuggestedItemsModalActions = {
  openModal: OpenGlobalModalAction;
  closeModal: CloseGlobalModalAction;
  resetModalState: ResetGlobalModalStateAction;
  goToStep: GoToStepAction;
  updateStepFormValues: UpdateStepFormValues;
  setCurrentStepSubmitting: CurrentStepSubmittingAction;
  setCurrentStepSubmitted: CurentStepSubmittedAction;
  setCurrentStepSubmitFailed: CurrentStepSubmitFailedAction;
};

export const MobileSuggestedItemsModalStore = createContext(initialMobileSuggestedItemsModalState);
export const MobileSuggestedItemsModalDispatch = createContext<MobileSuggestedItemsModalActions>({
  openModal: () => {},
  closeModal: () => {},
  resetModalState: () => {},
  goToStep: () => {},
  updateStepFormValues: () => {},
  setCurrentStepSubmitting: () => {},
  setCurrentStepSubmitted: () => {},
  setCurrentStepSubmitFailed: () => {}
});
