import { getDefinitions, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class ModalsLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AddQuestionsComments(): string {
    return this.definitions.AddQuestionsComments;
  }

  public get BackToLogin(): string {
    return this.definitions.BackToLogin;
  }

  public get CheckInboxExpires(): string {
    return this.definitions.CheckInboxExpires;
  }

  public get Close(): string {
    return this.definitions.Close;
  }

  public get CloseGallery(): string {
    return this.definitions.CloseGallery;
  }

  public get CloseModal(): string {
    return this.definitions.CloseModal;
  }

  public get EmailSent(): string {
    return this.definitions.EmailSent;
  }

  public get EnterEmailAssociated(): string {
    return this.definitions.EnterEmailAssociated;
  }

  public get FlipHorizontal(): string {
    return this.definitions.FlipHorizontal;
  }

  public get FlipVertical(): string {
    return this.definitions.FlipVertical;
  }

  public get ForgotPassword(): string {
    return this.definitions.ForgotPassword;
  }

  public get GetLink(): string {
    return this.definitions.GetLink;
  }

  public get InquirySent(): string {
    return this.definitions.InquirySent;
  }

  public get KeyboardControlsInfo(): string {
    return this.definitions.KeyboardControlsInfo;
  }

  public get MyDashboard(): string {
    return this.definitions.MyDashboard;
  }

  public get NextImage(): string {
    return this.definitions.NextImage;
  }

  public get NoAccount(): string {
    return this.definitions.NoAccount;
  }

  public get OfferAmount(): string {
    return this.definitions.OfferAmount;
  }

  public get Photo(): string {
    return this.definitions.Photo;
  }

  public get PreviousImage(): string {
    return this.definitions.PreviousImage;
  }

  public get RegisterHere(): string {
    return this.definitions.RegisterHere;
  }

  public get Rotate90Clockwise(): string {
    return this.definitions.Rotate90Clockwise;
  }

  public get Rotate90CounterClockwise(): string {
    return this.definitions.Rotate90CounterClockwise;
  }

  public get ThanksReachingShortly(): string {
    return this.definitions.ThanksReachingShortly;
  }

  public get WhatAdditionalInformation(): string {
    return this.definitions.WhatAdditionalInformation;
  }

  public get ZoomIn(): string {
    return this.definitions.ZoomIn;
  }

  public get ZoomOut(): string {
    return this.definitions.ZoomOut;
  }

  public get ResetRotation(): string {
    return this.definitions.ResetRotation;
  }

  public get DeleteQuestion(): string {
    return this.definitions.DeleteQuestion;
  }
}
