import { KeyboardEvent } from 'react';
import { SelectOption } from '../bits/forms/fields/selectField';
import { passwordValidationFunctions } from '../bits/forms/formikFields/formikPasswordField';
import { IMAGE_URLS } from '../constants/imageUrls';
import { SimpleDateFormat } from '../entities/common';
import { Localizer } from '../localization';

// -- #validation
// -----------------------------------------------

/**
 *
 */
export const isEmpty = (value: any): boolean => {
  if (typeof value === 'undefined') {
    return true;
  }
  if (value === null) {
    return true;
  }
  if (value === '') {
    return true;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};

/**
 * Works for `string` and `number`.
 */
export const isPopulated = <T extends string | number>(val: unknown): val is T => {
  if (typeof val === 'string' && val !== '') {
    return true;
  }
  if (typeof val === 'number' && !Number.isNaN(val) && val !== 0) {
    return true;
  }
  return false;
};

// -- #booleans
// -----------------------------------------------

/**
 * Defaults to `false`.
 */
export const parseBoolI = (val: unknown): boolean => {
  return val === 'true' || val === 1 || val === '1';
};

// -- #numbers
// -----------------------------------------------

/**
 *
 */
export const getRandNumber = (min: number, max: number) => {
  if (min > max) {
    throw new Error('min cannot be greater than max.');
  }
  return Math.floor(Math.random() * (max - min) + min);
};

/**
 *
 */
export const parseIntI = (val: unknown): number => {
  if (typeof val === 'number') {
    return !Number.isNaN(val) ? val : 0;
  }
  if (typeof val === 'string') {
    return parseIntI(Number.parseInt(val));
  }
  return 0;
};

export const parseArrayI = (val: unknown) => {
  if (Array.isArray(val)) {
    return val;
  }
  return [];
};

// -- #strings
// -----------------------------------------------

export const formatPriceToString = (price: number): string => {
  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return priceFormatter.format(price);
};

export const genHash = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const genId = (length: number = 16) => {
  let result = '';
  const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < length; i++) {
    result += alpha.charAt(Math.floor(Math.random() * alpha.length));
  }

  return result;
};

/**
 *
 */
export const parseStringI = (val: unknown): string => {
  if (typeof val === 'string') {
    return val;
  }
  if (typeof val === 'number') {
    return !Number.isNaN(val) ? val.toString() : '';
  }
  if (val === true) {
    return 'true';
  }
  if (val === false) {
    return 'false';
  }
  return '';
};

export const cleanPhoneNumber = (phone: string): string => {
  return phone.replace(/[^0-9]/g, '');
};

export const concatStrings = (elements: (string | null | undefined)[], separator: string) => {
  return elements.join(separator).trim();
};

export const getMakeModelName = (obj: { make: { name: string }; model: { name: string } }) => {
  return concatStrings([obj.make.name, obj.model.name], ' ');
};

export const getTimestampDisplayValue = (timestamp: number | null, loc: Localizer) => {
  return timestamp === null ? loc.Listing.Unknown : formatDaysAgo(new Date(timestamp * 1000), new Date(), loc);
};

export const getFullName = (user: { first_name: string; last_name: string }) => {
  return concatStrings([user.first_name, user.last_name], ' ');
};

export const parseUSDCurrencyStr = (value: number | string): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
  const intValue = Math.floor(Number(value));
  return formatter.format(intValue);
};

// -- #arrays
// -----------------------------------------------

/**
 * Returns a shuffled duplicate of the given array
 */
export const shuffleArray = <T>(array: Array<T>) => {
  const shuffle = (array: Array<T>) => {
    const arrayDupe = [...array];

    let currentIndex = arrayDupe.length;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = getRandNumber(0, currentIndex);
      currentIndex--;
      [arrayDupe[currentIndex], arrayDupe[randomIndex]] = [arrayDupe[randomIndex], arrayDupe[currentIndex]];
    }

    return arrayDupe;
  };
  let shuffled = shuffle(array);

  // keep shuffling until the shuffled array is different than its original configuration
  while (shuffled.every((el, i) => el === array[i])) {
    shuffled = shuffle(array);
  }

  return shuffled;
};

// -- #dates
// -----------------------------------------------

/**
 *
 */
export const calcDaysAgo = (fromDate: Date, toDate: Date): number =>
  Math.round((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24));

/**
 *
 */
export const formatDaysAgo = (fromDate: Date, toDate: Date, loc: Localizer): string => {
  const days = calcDaysAgo(fromDate, toDate);
  if (days === 0) return loc.Common.Today;
  if (days === 1) return loc.Common.Yesterday;
  if (days <= 30) return `${days.toLocaleString()}${loc.Common.DaysAgo}`;
  if (days <= 60) return loc.Common.ThirtyDaysAgo;
  return loc.Common.SixtyDaysAgo;
};

/**
 *
 */
export const formatDate = (
  value: SimpleDateFormat | number,
  locale: 'en' | 'zh-CN',
  options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' }
): string => {
  const date = new Date(value);
  return date.toLocaleDateString(locale, options);
};

export const safariFriendlyDateString = (safariSucks: string): string => {
  return safariSucks.split(' ')[0]; // get rid of the time.  make date only
};

export const unixTimestampToISOString = (unixTimestamp: number): string => {
  const date = new Date(unixTimestamp * 1000);
  return date.toISOString();
};

// -- #form validation
// -----------------------------------------------

/**
 *
 */
export const isValidPassword = (value: string | undefined) => {
  if (typeof value === 'undefined') return false;

  for (const [_, isValid] of Object.entries(passwordValidationFunctions)) {
    if (!isValid(value)) return false;
  }

  return true;
};

/**
 *
 */
export const isMaxTwoDecimalPlaces = (num: number | undefined) => {
  if (typeof num !== 'number') {
    return true;
  }
  return /^\d+(\.\d{1,2})?$/.test(num.toString());
};

/**
 *
 */
export const isNotEmptyString = (value: string | undefined) => {
  if (typeof value === 'undefined') {
    return false;
  }
  return value.trim() !== '';
};

/**
 * Used to link input fields with their respective error messages
 */
export const getFieldDescribedById = (name: string) => `field-describedby-${name}`;

// -- #accessiblity
// -----------------------------------------------

/**
 * Adds spacebar/enter confirm functionality to elements that don't have it by default
 */
export const runOnSpacebarOrEnterPress = (event: KeyboardEvent, callback: () => unknown) => {
  if ([' ', 'Enter'].includes(event.key)) {
    event.preventDefault();
    callback();
  }
};

// -- #misc
// -----------------------------------------------

/**
 * Sorts an array of objects by the values of the given keys in the order that they are given
 */
export const sortObjectsBy = <T>(items: T[], keys: Array<keyof T>, reverse: boolean = false): T[] =>
  items.sort((a, b) => {
    for (const key of keys) {
      if (a[key] < b[key]) return reverse ? 1 : -1;
      if (a[key] > b[key]) return reverse ? -1 : 1;
    }
    return 0;
  });

/**
 *
 */
export const sortOptionsByName = (options: SelectOption[]): SelectOption[] =>
  [...options].sort((a, b) => {
    const [A, B] = [a.label.toUpperCase(), b.label.toUpperCase()];
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  });

/**
 * Generates select options based on the given key-value of the given object without duplicates
 */
export const generateSelectOptions = <T>(items: T[], key: keyof T): SelectOption[] => {
  const values = new Set();

  for (const item of items) {
    const value = item[key];
    if (typeof value === 'string' || typeof value === 'number') {
      values.add(value);
    }
  }

  return Array.from(values)
    .sort()
    .map(
      (value) =>
        ({
          value,
          label: String(value)
        } as SelectOption)
    );
};

/**
 *
 */
export const throttle = <TArgs extends [], TReturn>(callback: (...args: TArgs) => TReturn, delayInMilliseconds: number) => {
  let shouldWait = false;
  return function (...args: TArgs) {
    if (!shouldWait) {
      callback(...args);
      shouldWait = true;
      setTimeout(() => (shouldWait = false), delayInMilliseconds);
    }
  };
};

/**
 *
 */
export const getClassFromColorVariant = (type: 'text' | 'bg', colorVariant: 'peach' | 'magenta' | 'green') => {
  let className = `${type}-`;
  switch (colorVariant) {
    case 'peach':
      className += 'peach';
      break;
    case 'magenta':
      className += 'magenta-500-old';
      break;
    case 'green':
      className += 'green-500';
      break;
  }
  return className;
};

/**
 *
 */
export const isElementInViewport = (element: HTMLElement): boolean => {
  const boundingRect = element.getBoundingClientRect();
  return (
    boundingRect.top >= 0 &&
    boundingRect.left >= 0 &&
    boundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    boundingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 *
 */
export const generateMoovImageURL = (obj: { id: number }, size: 's' | 'm' | 'l') => {
  let id = Number.parseInt(obj.id.toString()[obj.id.toString().length - 1]);
  if (id === 0) id = 1;
  if (id === 9) id = 2;

  if (id >= 1 && id <= 8) {
    return IMAGE_URLS.getDefaultMoovImageURL(id as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8, size);
  }
  return IMAGE_URLS.getDefaultMoovImageURL(1, size);
};

/**
 *
 */
export const tryParseJSONObject = (value: string): Record<string, any> | null => {
  try {
    const parsedJSON = JSON.parse(value);
    if (typeof parsedJSON === 'object' && !Array.isArray(parsedJSON) && parsedJSON !== null) {
      return parsedJSON;
    }
  } catch (e) {}
  return null;
};

export const getValidProfileImageURL = (str: string): string => {
  return !isEmpty(str) ? str : IMAGE_URLS.DEFAULT_PROFILE_PICTURE;
};

export const capitalize = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1);
};
