import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { BuyerOffer } from '../types';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { parseUSDCurrencyStr } from '@/src/utils';
import { buyerAcceptOffer } from '../thunks';
import { closeModal } from '@/src/state/globalModal/slice';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';

export const PendingSellerCounterCTAs: FC<{ offer: BuyerOffer }> = ({ offer }) => {
  const dispatch = useAppDispatch();
  const { fullStoryAndGTMEvent } = useFullStory();

  return (
    <>
      {/* TODO: translations */}
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenAcceptOffer,
            offerKey: offer.key,
            listingKey: offer.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.CONFIRMATION,
              modalProps: {
                confirmation: {
                  title: 'Are you sure you want to accept this offer?',
                  description: `You will be accepting an offer of ${parseUSDCurrencyStr(offer.offer.offer_amount)} USD for ${
                    offer.listing.make_model
                  }.`,
                  confirmText: 'Yes',
                  cancelText: 'No',
                  onConfirm: async () => {
                    fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                      type: OfferCTATypes.ConfirmAcceptOffer,
                      offerKey: offer.key,
                      listingKey: offer.listing.key
                    });
                    return dispatch(buyerAcceptOffer(offer));
                  },
                  onClose: () => {
                    dispatch(closeModal());
                  },
                  errorHandleConfirm: (err) => {
                    captureException(err);
                    dispatch(openError({ error: ERROR_MESSAGES.REFRESH }));
                  }
                }
              }
            })
          );
        }}
        variant="contained"
        color="primary"
        id="offer-cta-accept-offer"
      >
        ACCEPT OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenDeclineOffer,
            offerKey: offer.key,
            listingKey: offer.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.DECLINE_OFFER,
              modalProps: { declineOffer: { offerKey: offer.key, listingKey: offer.listing.key, isBuyer: true } }
            })
          );
        }}
        variant="contained"
        color="secondary"
        id="offer-cta-decline-offer"
      >
        DECLINE OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.CounterOffer,
            listingKey: offer.listing.key,
            offerKey: offer.key
          });

          dispatch(
            openModal({
              modalType: GlobalModalTypes.MAKE_OFFER,
              // TODO ENG-2363 new flow doesn't actually use id so we don't need it in the props after cleanup
              modalProps: {
                makeOffer: { listing: { ...offer.listing.toSerializable(), id: 0 }, offer: offer.toSerializable(), isCounter: true }
              }
            })
          );
        }}
        id="offer-cta-counter-offer"
        variant="outlined"
        color="info"
        size="large"
      >
        <Typography variant="button14AllCaps">COUNTER OFFER</Typography>
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.MessageSeller,
            listingKey: offer.listing.key,
            offerKey: offer.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.MESSAGE_SELLER,
              modalProps: { messageSeller: { listingKey: offer.listing.key } }
            })
          );
        }}
        id="offer-cta-message-seller"
        fullWidth
        size="large"
        variant="outlined"
        color="info"
      >
        <Typography variant="button14AllCaps">MESSAGE SELLER</Typography>
      </Button>
    </>
  );
};
