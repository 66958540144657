export enum ModalTypes {
  SuggestedItems = 'SuggestedItems',
  Empty = 'Empty'
}

export enum ModalButtonColors {
  Magenta = 'Magenta',
  DarkBlue = 'DarkBlue',
  White = 'White'
}
