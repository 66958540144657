import { Condition, WaferSize } from '@/src/common/lib';
import { ListingSearchSortValue } from '@/src/providers/listings/constants';

export enum SearchFlowType {
  PlainText = 'text',
  Category = 'category',
  Make = 'make',
  Model = 'model'
}

export interface BaseFilterValues {
  order: ListingSearchSortValue;
  has_photos: boolean;
  is_exclusive: boolean;
  condition: Condition[];
  wafer_size: WaferSize[];
  min_vintage: number;
  max_vintage: number;
}

export interface FilterValues extends BaseFilterValues {
  search_flow_type: SearchFlowType;
  q: string;
  category_id: number;
  make_id: number[];
  model_id: number[];
}

// #region Search parameter types for the `routes.search` function
export interface PlainTextFlowParams extends Partial<BaseFilterValues> {
  search_flow_type: SearchFlowType.PlainText;
  q: string;
  category_id?: number;
}
export interface CategoryFlowParams extends Partial<BaseFilterValues> {
  search_flow_type: SearchFlowType.Category;
  category_id: number;
  make_id?: number[];
}
export interface MakeFlowParams extends Partial<BaseFilterValues> {
  search_flow_type: SearchFlowType.Make;
  category_id?: number;
  make_id: [number];
  model_id?: number[];
}
export interface ModelFlowParams extends Partial<BaseFilterValues> {
  search_flow_type: SearchFlowType.Model;
  model_id: [number];
}
export type SearchParams = PlainTextFlowParams | CategoryFlowParams | MakeFlowParams | ModelFlowParams;
// #endregion Search parameter types for the `routes.search` function

export type FilterVisibility = {
  category: boolean;
  make: boolean;
  model: boolean;
};
