import { OffersSellersTab } from './sellers';
import { OffersBuyersTab } from './buyers';
import { Box, Button, Divider, Stack, Tab, Tabs } from '@mui/material';
import { PageWrapper } from '../layout/contentWrappers';
import { useRouter } from 'next/router';
import routes from '../routes';
import { BuyerOfferFilters } from './buyers/filters';
import { colors, spacing } from '../design/designConstants';
import { SellerOfferFilters } from './sellers/filters';
import { FAIcon } from '../design/bits/fAIcon/fAIcon';
import { icons } from '../design/bits/fAIcon/icons';
import { SideDrawers, useSideDrawer } from '../hooks/useSideDrawer';
import { OffersSellerFiltersMobile } from './sellers/mobile/filterDrawer';
import { OffersBuyerFiltersMobile } from './buyers/mobile/filterDrawer';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

export enum OffersTabs {
  Selling = 'Selling',
  Buying = 'Buying'
}

const getCurrentTab = (tab: OffersTabs) => {
  switch (tab) {
    case OffersTabs.Buying:
      return OffersTabs.Buying;
    case OffersTabs.Selling:
      return OffersTabs.Selling;
    default:
      return OffersTabs.Selling;
  }
};

const OffersMain = () => {
  const router = useRouter();
  const currentTab = getCurrentTab(router.query?.tab as OffersTabs);
  const { openDrawer } = useSideDrawer(SideDrawers.OfferFilters);

  return (
    <PageWrapper
      className="w-full overflow-hidden overflow-y-scroll bg-corp-gray-50 text-moov-blue-500"
      childrenClassName="p-0"
      title={'Offers'}
    >
      <Stack>
        <TabContext value={currentTab}>
          <Stack direction="row" justifyContent={'space-between'} alignItems="center" bgcolor={colors.surface.white}>
            <Tabs
              value={currentTab}
              onChange={(_, tab) => {
                switch (tab) {
                  case OffersTabs.Selling:
                    router.push(routes.offersSelling());
                    break;
                  case OffersTabs.Buying:
                    router.push(routes.offersBuying());
                    break;
                }
              }}
            >
              {/* TODO: translations */}
              <Tab label={OffersTabs.Selling} value={OffersTabs.Selling} />
              <Tab label={OffersTabs.Buying} value={OffersTabs.Buying} />
            </Tabs>

            <Box display={{ xs: 'none', d: 'block' }}>
              {currentTab === OffersTabs.Buying && (
                <Stack direction="row" px={spacing.xl} spacing={spacing.lg}>
                  <BuyerOfferFilters />
                </Stack>
              )}
              {currentTab === OffersTabs.Selling && (
                <Stack direction="row" px={spacing.xl} spacing={spacing.lg}>
                  <SellerOfferFilters />
                </Stack>
              )}
            </Box>
            <Box display={{ xs: 'block', d: 'none' }}>
              <Button onClick={openDrawer} variant="outlined" color="info" sx={{ height: spacing['5xl'], mr: spacing.xl }}>
                <FAIcon icon={icons.sliders} />
              </Button>
              {currentTab === OffersTabs.Selling && <OffersSellerFiltersMobile />}
              {currentTab === OffersTabs.Buying && <OffersBuyerFiltersMobile />}
            </Box>
          </Stack>
          <Divider />
          <TabPanel value={OffersTabs.Selling}>
            <OffersSellersTab />
          </TabPanel>
          <TabPanel value={OffersTabs.Buying}>
            <OffersBuyersTab />
          </TabPanel>
        </TabContext>
      </Stack>
    </PageWrapper>
  );
};

export default OffersMain;
