import type { Locale } from '../localization/types';
import { getDefinitions } from '../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class RemarketingLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AddADescriptionToIncrease(): string {
    return this.definitions.AddADescriptionToIncrease;
  }

  public get AddConfigurationInformation(): string {
    return this.definitions.AddConfigurationInformation;
  }

  public get AddConfiguration(): string {
    return this.definitions.AddConfiguration;
  }

  public get AddDescription(): string {
    return this.definitions.AddDescription;
  }

  public get BuyersWantToKnow(): string {
    return this.definitions.BuyersWantToKnow;
  }

  public get ConfigurationMattersToBuyers(): string {
    return this.definitions.ConfigurationMattersToBuyers;
  }

  public get ListingsWithPhotos(): string {
    return this.definitions.ListingsWithPhotos;
  }

  public get BuyerQuestions(): string {
    return this.definitions.BuyerQuestions;
  }

  public get NextQuestion(): string {
    return this.definitions.NextQuestion;
  }

  public get QuestionsAnswered(): string {
    return this.definitions.YourAnswers;
  }

  public get ListingSuccessfullyUpdated(): string {
    return this.definitions.ListingSuccessfullyUpdated;
  }

  public get Details(): string {
    return this.definitions.Details;
  }

  public get Unlisted(): string {
    return this.definitions.Unlisted;
  }

  public get Listed(): string {
    return this.definitions.Listed;
  }

  public get YouHaveBuyer(): string {
    return this.definitions.YouHaveBuyer;
  }

  public get ProvideAnswersQuestions(): string {
    return this.definitions.ProvideAnswersQuestions;
  }
}
