import { ERROR_MESSAGES } from '@/src/constants';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { getBuyerOfferByListingKey } from '@/src/offers/selectors';
import routes from '@/src/routes';
import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppDispatch, useAppSelector } from '@/src/store';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { captureException } from '@sentry/nextjs';
import { ReactNode, useEffect, useState } from 'react';
import { ButtonProps } from '@mui/material';
import { validateCanMakeOfferOnListing } from './thunks';

interface Props extends ButtonProps {
  listingKey: string;
  children: ReactNode;
}

export const ValidateMakeOfferButtonWrapper: React.FC<Props> = ({ listingKey, children, ...btnProps }) => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);
  const [canMakeOffer, setCanMakeOffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openErrorAlert } = useGlobalAlertBanner();
  const existingOffer = useAppSelector((state) => getBuyerOfferByListingKey(state, listingKey));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        setLoading(true);
        dispatch(validateCanMakeOfferOnListing(listingKey))
          .then((canMakeOffer: boolean) => {
            setLoading(false);
            setCanMakeOffer(canMakeOffer);
          })
          .catch((e) => {
            setLoading(false);
            setCanMakeOffer(false);
            captureException(e);
            openErrorAlert(ERROR_MESSAGES.REFRESH);
          });
      })();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) return <>{children}</>;

  if (loading) {
    return <LoadingButton loading={loading} fullWidth {...btnProps} />;
  }

  if (Boolean(existingOffer) || !canMakeOffer) {
    return (
      <Button fullWidth href={routes.offersBuying()} variant="contained" color="primary" {...btnProps}>
        {/* TODO: translations */}
        MANAGE OFFER
      </Button>
    );
  }

  return <>{children}</>;
};
