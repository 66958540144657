import { styled, TextField } from '@mui/material';
import TAILWIND_CSS_CONFIG from '../../../../tailwind.config';

export const StyledTextInput = styled(TextField)(() => ({
  backgroundColor: TAILWIND_CSS_CONFIG.theme.colors.white,
  borderRadius: '4px',

  ['.MuiInputBase-input']: {
    color: TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500'],
    fontFamily: TAILWIND_CSS_CONFIG.theme.fontFamily.inter[0],
    letterSpacing: '0.009375em'
  },
  ['& ::placeholder']: {
    color: TAILWIND_CSS_CONFIG.theme.colors['smokey-gray']['400'],
    opacity: 1
  },
  ['& .MuiOutlinedInput-notchedOutline']: {
    borderColor: TAILWIND_CSS_CONFIG.theme.colors['smokey-gray']['400']
  },
  ['& :not-disabled:hover .MuiOutlinedInput-notchedOutline']: {
    borderColor: TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500']
  },
  ['.MuiInputBase-root.Mui-focused:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline']: {
    borderColor: TAILWIND_CSS_CONFIG.theme.colors['sky-blue']['600']
  },
  ['.MuiInputBase-root.Mui-error:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline']: {
    borderColor: TAILWIND_CSS_CONFIG.theme.colors['hot-red']['500']
  },
  ['.MuiFormHelperText-root']: {
    marginTop: '0px',
    marginLeft: '0px'
  },
  ['.MuiChip-root .MuiChip-deleteIcon']: {
    color: TAILWIND_CSS_CONFIG.theme.colors['smokey-gray']['400']
  }
}));
