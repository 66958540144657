import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { useFilterValues } from '@/src/marketplace/search/hooks';
import { SearchFlowType } from '@/src/marketplace/search/types';
import { ROUTE_PATHNAMES } from '@/src/routes';
import { getFullStoryFilterValues } from '@/src/state/filters/thunks';
import { useAppDispatch } from '@/src/store';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';

export const useSearchTracking = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const typedPathname = router.pathname as ROUTE_PATHNAMES;
  const { fullStoryEvent } = useFullStory();
  const { filterValues } = useFilterValues();
  const [previousQuery, setPreviousQuery] = useState<ParsedUrlQuery>({});

  useEffect(() => {
    if (!router.isReady || typedPathname !== ROUTE_PATHNAMES.search) return;

    const qWasChanged = router.query.search_flow_type === SearchFlowType.PlainText && router.query.q !== previousQuery.q;

    // This covers an edge case where a user is in a non-PlainText search flow
    // and then starts a new PlainText search flow with nothing in the search bar.
    // The logic above would not catch this case since the current and previous
    // `q` values would both be `undefined`.
    const isNewPlainTextFlowInstance =
      router.query.search_flow_type === SearchFlowType.PlainText && previousQuery.search_flow_type !== SearchFlowType.PlainText;

    if (qWasChanged || isNewPlainTextFlowInstance) {
      dispatch(getFullStoryFilterValues(filterValues)).then((fullStoryFilterValues) => {
        fullStoryEvent(CustomEvents.search.search, { rawQuery: router.query, query: router.query.q, filterValues: fullStoryFilterValues });
      });
    } else {
      dispatch(getFullStoryFilterValues(filterValues)).then((fullStoryFilterValues) => {
        fullStoryEvent(CustomEvents.search.filter, { rawQuery: router.query, filterValues: fullStoryFilterValues });
      });
    }

    setPreviousQuery(router.query);
  }, [router.isReady, JSON.stringify(router.query), typedPathname]);
};
