import { parentCategoryData, defaultCategoryParentId } from './constants';
import * as CategoriesRepo from './repo';
import { fetchModels } from '../models';
import { pickMakesFromModels } from '../models/utils';
import type { FindManyParams } from '../types';
import { Category, CategoryDetail, ParentCategory, ParentCategoryDetail } from '../../entities/category';

interface FetchCategoriesParams extends FindManyParams {
  readonly parent_id?: number | string;
}

export const fetchCategories = async (params: FetchCategoriesParams): Promise<Category[]> => {
  return CategoriesRepo.findManyCategory(params);
};

export const fetchCategory = async (categorySlug: string): Promise<Category> => {
  return CategoriesRepo.findOneCategory(categorySlug);
};

/**
 * Assigns to default parent if not found.
 */
export const fetchCategoryDetail = async (categorySlug: string): Promise<CategoryDetail> => {
  const category = await fetchCategory(categorySlug);

  const models = await fetchModels({ category_id: category.id });
  const makes = pickMakesFromModels(models);

  let parent: ParentCategory | undefined;
  for (const parentDatum of parentCategoryData) {
    if (parentDatum.id === category.parent_id || (parentDatum.id === defaultCategoryParentId && typeof parent === 'undefined')) {
      parent = { ...parentDatum };
    }
  }
  if (typeof parent === 'undefined') {
    throw new Error(`invalid default Parent set`);
  }

  return { ...category, makes, parent };
};

/**
 * Assigns to default parent if not found.
 */
export const fetchParentCategories = async (): Promise<ParentCategoryDetail[]> => {
  const categories = await fetchCategories({});

  return reduceCategories(categories);
};

export const reduceCategories = (categories: Category[]): ParentCategoryDetail[] => {
  return categories.reduce((acc: ParentCategoryDetail[], currentCategory) => {
    if (!currentCategory.parent_id) {
      acc.push({ ...currentCategory, categories: categories.filter((cat) => cat.parent_id === currentCategory.id) });
    }
    return acc;
  }, []);
};
