import {
  getDefinitions,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class AllCategoryMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AllManufacturers(): string {
    return this.definitions.AllManufacturers;
  }

  public get BuySellMarketplaceAdded(): string {
    return this.definitions.BuySellMarketplaceAdded;
  }

  public get NoManufacturersFound(): string {
    return this.definitions.NoManufacturersFound;
  }

  public get OrBrowseAllCategories(): string {
    return this.definitions.OrBrowseAllCategories;
  }

  public get SemiconductorEquipmentSale(): string {
    return this.definitions.SemiconductorEquipmentSale;
  }
}
