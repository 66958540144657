import type { FC } from 'react';
import dynamic from 'next/dynamic';

import { useUser } from '../../hooks/useUser';
import { InternalLink } from '../../bits/links';
import { P2 } from '../../bits/text';
import { getMoovCoWebEnv, MOOV_CO_WEB_VERSION } from '../../constants/app';
import routes from '../../routes';
import { useLocalizer } from '../../localization';
import { ImageContained } from '../../bits/responsiveImage';
import { SetModal } from '../../entities/common';
const CookiePreferencesModal = dynamic(() => import('../cookieManagement/cookiePreferencesModal'), { ssr: false });

interface InfoSectionProps {
  readonly setModal: SetModal;
}

export const InfoSection: FC<InfoSectionProps> = (props) => {
  const { setModal } = props;
  const { user, userIsLoggedIn } = useUser();
  const loc = useLocalizer();

  const handleCookiePreferencesClick = () => {
    setModal(<CookiePreferencesModal setModal={setModal} />);
  };

  // -- version
  let appEnv: string;
  switch (getMoovCoWebEnv()) {
    case 'production':
      appEnv = 'P';
      break;
    case 'stage':
      appEnv = 'S';
      break;
    case 'beta':
      appEnv = 'B';
      break;
    case 'test':
      appEnv = 'T';
      break;
    case 'local':
      appEnv = 'L';
      break;
  }

  return (
    <div>
      <InternalLink
        href={routes.home(user, userIsLoggedIn)}
        className="mb-1.5 inline-block"
        aria-label={!userIsLoggedIn(user) ? loc.Nav.Home : loc.Nav.Dashboard}
      >
        <ImageContained src="/images/moov-logo-full-white.png" alt={loc.Nav.MoovLogo} className="mx-[-0.3rem] h-12 w-[11.5rem]" />
      </InternalLink>

      <div className="flex flex-col gap-3">
        <div className="flex flex-wrap">
          <P2>701 Brazos St, Suite 930.</P2>
          <P2>Austin, TX 78701</P2>
        </div>

        <P2>{loc.Footer.Copyright(new Date().getFullYear())}</P2>

        <P2>
          <InternalLink href={routes.policyPrivacy()} className="hover:underline">
            {loc.Common.PrivacyPolicy}
          </InternalLink>
          {' | '}
          <InternalLink href={routes.policyTerms()} className="hover:underline">
            {loc.Common.TermsAndConditions}
          </InternalLink>
          {' | '}
          <InternalLink href={routes.policyCandidatePrivacy()} className="hover:underline">
            CCPA & CPRA
          </InternalLink>
          {' | '}
          <button onClick={handleCookiePreferencesClick} type="button" className="bg-transparent hover:underline">
            {loc.Common.CookiePreferences}
          </button>
          {' | '}
          <span className="whitespace-nowrap">{`v${appEnv}:${loc.getLocaleLanguage().toUpperCase()}:${MOOV_CO_WEB_VERSION}`}</span>
        </P2>

        <ImageContained src="/images/semi-member-logo-white.png" alt={loc.Footer.WhiteSemiMemberLogo} className="h-7 w-[5.5rem]" />
      </div>
    </div>
  );
};
