import { Toolbar } from '@mui/material';
import { FC } from 'react';

export const InternalUserBanner: FC = () => {
  return (
    <Toolbar>
      <div
        className="m-0 flex h-[var(--internal-user-banner-height)] items-center overflow-hidden bg-internal-neon-green text-20spx font-black text-internal-neon-pink lg:-mx-2 lg:text-30spx"
        data-testid="InternalUserBanner"
      >
        <div className="whitespace-nowrap italic"> {Array.from({ length: 40 }, () => 'INTERNAL USER').join(' 👍 ')} </div>
      </div>
    </Toolbar>
  );
};
