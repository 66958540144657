import type { Locale } from '../localization/types';
import { getDefinitions } from '../localization/utils';
import translations from './index.loc.json';

export class CreateListingMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AddDetailsToYourListing(): string {
    return this.definitions.AddDetailsToYourListing;
  }

  public get DisplayedUnderOtherInformation(): string {
    return this.definitions.DisplayedUnderOtherInformation;
  }

  public get NeedAdditionalDetails(): string {
    return this.definitions.NeedAdditionalDetails;
  }

  public get ListForMe(): string {
    return this.definitions.ListForMe;
  }

  public get BulkUpload(): string {
    return this.definitions.BulkUpload;
  }

  public get HaveAList(): string {
    return this.definitions.HaveAList;
  }

  public get EmailItToUsAtPre(): string {
    return this.definitions.EmailItToUsAtPre;
  }

  public get EmailItToUsAtPost(): string {
    return this.definitions.EmailItToUsAtPost;
  }

  public get Images(): string {
    return this.definitions.Images;
  }

  public get MustBeFourDigits(): string {
    return this.definitions.MustBeFourDigits;
  }

  public get MustBeGreaterThan1939(): string {
    return this.definitions.MustBeGreaterThan1939;
  }

  public get Quantity(): string {
    return this.definitions.Quantity;
  }

  public get ViewManageAllEquipmentListings(): string {
    return this.definitions.ViewManageAllEquipmentListings;
  }
}
