import type { Dispatch, SetStateAction } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export type AppEnv = 'production' | 'stage' | 'test' | 'local' | 'beta';
export type BooleanValue = 0 | 1;
export type Count = number;
export type NumericValue = string;
export interface Photo {
  readonly small_url: Url;
  readonly medium_url: Url;
  readonly large_url: Url;
}
export type Route = string;
export type SimpleDateFormat = string;
export type Slug = string;
export type StatusValue = number;
export type UnixTimestamp = number;
export type Url = string;
export type SetModal = Dispatch<SetStateAction<JSX.Element | null>>;
export type SwapInModal = (modal: JSX.Element) => void;
export type HTMLString = string;

// Type for icons imported from MUI (ex. import SearchIcon from '@mui/icons-material/Search')
export type MUIIcon = OverridableComponent<SvgIconTypeMap<object, 'svg'>>;

export const isPhoto = (arg: unknown): arg is Photo =>
  !!arg && typeof arg === 'object' && !!(arg as Photo).large_url && !!(arg as Photo).medium_url && !!(arg as Photo).small_url;

export const changeFilesToPhotos = (arg: unknown[]): Photo[] => {
  const photos = arg.filter(isPhoto);

  return photos as Photo[];
};
