import { FC } from 'react';
import Image, { ImageProps, StaticImageData } from 'next/image';

interface SharedImageProps {
  readonly originalDimensions: { width: number; height: number }; // in pixels
  readonly src: string | StaticImageData;
  readonly alt: string;
  readonly className?: string;
  readonly quality?: number;
  readonly unoptimized?: boolean; // This property should only be used if nothing else works to unblur an image
}

interface NewResponsiveImageProps extends SharedImageProps {
  readonly objectFit: 'cover' | 'contain';
}

const NewResponsiveImage: FC<NewResponsiveImageProps> = (props) => {
  const {
    className = '',
    src,
    alt,
    quality = 100,
    objectFit,
    unoptimized,
    originalDimensions: { width, height }
  } = props;

  /**
   * Long explanation for what we're doing here and why it works:
   *
   * The purpose of this styling is to maintain the original height:width ratio of
   * the image even if the parent container resizes. We do this by calculating the
   * height:width ratio, turning that into a percentage, and using that value for
   * top padding. This works because when a percentage value is used for padding,
   * the final value will be calculated off of the element's width. So if the
   * original height:width ratio of the image is 2:1 (height is 200% of the width)
   * and we set the top padding to 200%, the padding will be 200% of the element's
   * width. The reason we can’t use the height property to do this is because a
   * percentage value on the height property references the parent’s height and not
   * the current element’s width.
   */
  const imageRatioStyle = {
    paddingTop: `${(height / width) * 100}%`
  };

  return (
    <div className={`relative ${className}`}>
      <div className="w-full" style={imageRatioStyle}>
        <div className="absolute bottom-0 left-0 right-0 top-0">
          <Image
            src={src}
            alt={alt}
            className="h-full w-full"
            objectFit={objectFit}
            quality={quality}
            unoptimized={unoptimized}
            layout="fill"
          />
        </div>
      </div>
    </div>
  );
};

export const NewImageContained: FC<SharedImageProps> = (props) => {
  return <NewResponsiveImage {...props} objectFit="contain" />;
};

export const NewImageCovered: FC<SharedImageProps> = (props) => {
  return <NewResponsiveImage {...props} objectFit="cover" />;
};

// -----------------------------------------------
// DEPRECATED COMPONENTS
// -----------------------------------------------

interface ResponsiveImageProps extends ImageProps {
  readonly className?: string;
  readonly src: string | StaticImageData;
  readonly alt: string;
  readonly quality?: number;
}

/**
 * @deprecated
 * Needs to be provided an explicit width and height to display properly
 * TODO: 184336560 - Refactor so that the container resizes with the image
 */
const ResponsiveImage: FC<ResponsiveImageProps> = ({ className = '', src, alt, quality = 100, ...imageProps }) => (
  <div className={`relative ${className}`}>
    <Image src={src} alt={alt} quality={quality} layout="fill" {...imageProps} />
  </div>
);

/**
 * @deprecated
 * Needs to be provided an explicit width and height to display properly
 * TODO: 184336560 - Refactor so that the container resizes with the image
 */
export const ImageContained: FC<ResponsiveImageProps> = ({ className = '', ...imageProps }) => (
  <ResponsiveImage className={className} layout="fill" objectFit="contain" {...imageProps} />
);

export const ImageCovered: FC<ResponsiveImageProps> = ({ className = '', ...imageProps }) => (
  <ResponsiveImage className={className} layout="fill" objectFit="cover" {...imageProps} />
);
