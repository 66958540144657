import axios from 'axios';

import type {
  DTOListingSummary,
  DTOListing,
  DTOUpdateListing,
  DTOListingFile,
  DTOCreateManyListingFile,
  DTOListingFileUploadUrl,
  DTOCreateManyListingFileUploadUrl,
  DTOListingPhoto,
  DTOListingPhotoUploadUrl,
  DTOCreateManyListingPhoto,
  DTOCreateManyListingPhotoUploadUrl,
  DTOListingMetrics,
  DTODeleteManyListingFiles,
  DTODeleteManyListingPhotos,
  DTOPresignedS3Resource,
  DTOSuggestedListing,
  DTOListingMetadata,
  DTOCreateListing
} from './dtos';
import type { DTOWithMultiWrapper, DTOWithSingleWrapper, FindManyParams } from '../types';
import { MOOV_API_URL } from '../../constants';
import { BooleanValue } from '../../entities/common';
import { DTOUnconfirmedListing } from '../unconfirmedListings/dtos';
import { ListingSerializable } from '@/src/listings/types';

// -- #methods
// -----------------------------------------------

interface FindManyListingParams extends FindManyParams {
  readonly user_id?: number | string;
}

/**
 * Calling with user_id param returns owned listings for the user and requires browser context with a valid session id set.
 */
export const findManyListing = async (params: FindManyListingParams): Promise<DTOListingSummary[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOListingSummary>>(`${MOOV_API_URL}/listings`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

export interface FindManySuggestedListingParams extends FindManyParams {
  condition?: number[] | string[];
  'gte:vintage'?: number;
  model_id?: number | string | (number | string)[];
  'set:wafer_size'?: number[] | string[];
  'gte:photo_count'?: number;
  '_c:seen'?: 0 | 1;
  '_c:bionic_message_id'?: string;
  'opportunities.status'?: number[];
  limit?: number | string;
  offset?: number | string;
}

/**
 *
 */
export const findManySuggestedListing = async (params: FindManySuggestedListingParams): Promise<DTOSuggestedListing[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOSuggestedListing>>(`${MOOV_API_URL}/listings/suggested`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

export const findAllOpportunitiesByWanted = async (wantedId: string): Promise<DTOSuggestedListing[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOSuggestedListing>>(`${MOOV_API_URL}/listings/wanted/${wantedId}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

export const findManyUserPicksListing = async (params: FindManyParams): Promise<DTOListingSummary[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOSuggestedListing>>(`${MOOV_API_URL}/listings/deals`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

type SortDir = 'asc' | 'desc';
type Comparitor =
  | 'eq'
  | 'neq'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'in'
  | 'nin'
  | 'like'
  | 'nlike'
  | 'ilike'
  | 'nilike'
  | 'is_null'
  | 'not_null';
type Comparison = `${Comparitor} ${number} `;
type Field =
  | 'categories.name'
  | 'condition'
  | 'created_at'
  | 'price'
  | 'price.keyword'
  | 'quantity'
  | 'id'
  | 'location'
  | 'location.keyword'
  | 'makes.name'
  | 'models.name'
  | 'tool_status'
  | 'photo_count'
  | 'serial_number'
  | 'verified_at_ts'
  | 'vintage'
  | 'wafer_size';

export type ListingOrderByField = `${Field} ${Comparison | ''}${SortDir}`;

export interface FindManyListingBySearchParams {
  q?: string;
  disabled?: 0 | 1;
  order?: ListingOrderByField[];
  condition?: number | string | (number | string)[];
  'gte:photo_count'?: number;
  'gte:vintage'?: number;
  'lte:vintage'?: number;
  category_id?: number | string | (number | string)[];
  make_id?: number | string | (number | string)[];
  model_id?: number | string | (number | string)[];
  limit?: number | string;
  offset?: number | string;
  'set:wafer_size'?: number[] | string[];
  wafer_size_arr?: number[] | string[];
  is_exclusive?: number;
  'between:vintage'?: string; // "min|max" (eg. "1900|2000")
  /**
   * For public searches only, will be ignored by authenticated searches.
   */
  autocomplete?: boolean | BooleanValue | 'true' | 'false';
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  'location.keyword'?: string[];
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  private?: (0 | 1 | '0' | '1')[];
  /**
   * For authenticated searches only, will be ignored by public searches.
   */
  status?: (string | number)[];
}

/**
 * `autocomplete` defaults to `true` in the API.
 * q=rainbow 4420&order[]=vintage desc&order[]=updated_at desc&gt:photo_count=0&condition=1&autocomplete=false&category_id=1
 */
export const findManyListingBySearch = async (
  params: FindManyListingBySearchParams,
  useExperimental = false
): Promise<DTOListingSummary[]> => {
  if (useExperimental) {
    const res = await axios.get<DTOWithMultiWrapper<DTOListingSummary>>(`${MOOV_API_URL}/search/experimental`, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      params,
      withCredentials: true
    });

    return res.data.data;
  } else {
    const res = await axios.get<{ data: { listings: DTOListingSummary[] } }>(`${MOOV_API_URL}/search`, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      params,
      withCredentials: true
    });

    return res.data.data.listings;
  }
};

/**
 *
 */
export const findManyUserListingBySearch = async (params: FindManyListingBySearchParams): Promise<DTOListing[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOListing>>(`${MOOV_API_URL}/auth/listings`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

interface GetListingsMetadataParams {
  readonly user_id?: number | string;
}

export const getListingsMetadata = async (params: GetListingsMetadataParams): Promise<DTOListingMetadata[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOListingMetadata>>(`${MOOV_API_URL}/listings/meta`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Search for is_exclusive can return nonexclusive listings, so need to use this endpoint instead
 */
export const fetchListingsByRemarketingSlug = async (params: {
  remarketing_slug: string;
  limit?: number;
}): Promise<ListingSerializable[]> => {
  const res = await axios.get<{ data: ListingSerializable[] }>(`${MOOV_API_URL}/listings/remarketing/${params.remarketing_slug}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });
  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 */
export const findManyListingByWanted = async (wantedId: number, params: FindManyParams): Promise<DTOListingSummary[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOListingSummary>>(`${MOOV_API_URL}/wanteds/${wantedId}/listings`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

interface FindOneListingParams {
  readonly user_id?: number;
}

/**
 * Calling with user_id param returns the owned listing for the user and requires browser context with a valid session id set.
 */
export const findOneListing = async (listingKey: string, params: FindOneListingParams = {}): Promise<DTOListing> => {
  const res = await axios.get<DTOWithSingleWrapper<DTOListing>>(`${MOOV_API_URL}/listings/${listingKey}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

export const findOneListingByOpportunityKey = async (opportunityKey: string): Promise<DTOListing> => {
  const res = await axios.get<DTOWithSingleWrapper<DTOListing>>(`${MOOV_API_URL}/listings/opportunity/${opportunityKey}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 */
export const updateOneListing = async (listingKey: string, payload: DTOUpdateListing): Promise<DTOListing> => {
  const res = await axios.put<DTOWithSingleWrapper<DTOListing>>(`${MOOV_API_URL}/listings/${listingKey}`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 */
export const findManyUnconfirmedListing = async (params: FindManyParams): Promise<DTOUnconfirmedListing[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOUnconfirmedListing>>(`${MOOV_API_URL}/unconfirmedListings`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params,
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 */
export const createOneUnconfirmedListing = async (payload: DTOCreateListing): Promise<DTOUnconfirmedListing> => {
  const res = await axios.post<DTOWithSingleWrapper<DTOUnconfirmedListing>>(`${MOOV_API_URL}/unconfirmedListings`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 */
export const createManyListingFile = async (listingKey: string, payload: DTOCreateManyListingFile): Promise<DTOListingFile[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingFile>>(`${MOOV_API_URL}/listingFiles/${listingKey}/bulkCreate`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 * Get S3 upload urls from API.
 */
export const createManyListingFileUploadUrl = async (
  listingKey: string,
  payload: DTOCreateManyListingFileUploadUrl
): Promise<DTOListingFileUploadUrl[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingFileUploadUrl>>(
    `${MOOV_API_URL}/listingFiles/${listingKey}/bulkUpload`,
    payload,
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );

  return res.data.data;
};

export const disableManyListingFiles = async (listingKey: string, payload: DTODeleteManyListingFiles): Promise<DTOListingFile[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingFile>>(`${MOOV_API_URL}/listingFiles/${listingKey}/bulkDelete`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Requires browser context with a valid session id set.
 * Create listing_photo objects using S3 urls
 */
export const createManyListingPhoto = async (listingKey: string, payload: DTOCreateManyListingPhoto): Promise<DTOListingPhoto[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingPhoto>>(`${MOOV_API_URL}/listingPhotos/${listingKey}/bulkCreate`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 * Get S3 upload urls from API.
 * Requires browser context with a valid session id set.
 */
export const createManyListingPhotoUploadUrl = async (
  listingKey: string,
  payload: DTOCreateManyListingPhotoUploadUrl
): Promise<DTOListingPhotoUploadUrl[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingPhotoUploadUrl>>(
    `${MOOV_API_URL}/listingPhotos/${listingKey}/bulkUpload`,
    payload,
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );

  return res.data.data;
};

/**
 * Puts resources into S3 using presigned URLs.
 */
export const uploadS3ResourceFromUrl = async (payload: DTOPresignedS3Resource[]): Promise<DTOPresignedS3Resource[]> => {
  return Promise.all(
    payload.map(async ({ url, file, type }) => {
      return axios.put(url, file, {
        headers: {
          'Content-Type': type
        }
      });
    })
  );
};

export const disableManyListingPhotos = async (listingKey: string, payload: DTODeleteManyListingPhotos): Promise<DTOListingPhoto[]> => {
  const res = await axios.post<DTOWithMultiWrapper<DTOListingPhoto>>(`${MOOV_API_URL}/listingPhotos/${listingKey}/bulkDelete`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

interface FindOneListingMetricParams {
  readonly disabled?: BooleanValue;
}

/**
 *
 */
export const findOneListingMetrics = async (params: FindOneListingMetricParams): Promise<DTOListingMetrics> => {
  const res = await axios.get<DTOWithSingleWrapper<DTOListingMetrics>>(`${MOOV_API_URL}/listings/metrics`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params
  });

  return res.data.data;
};
