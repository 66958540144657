import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Make } from '@/src/entities/make';

export interface MakeState {
  byId: Record<string, Make>;
  idsForFilters: number[];
}

export const initialState: MakeState = {
  byId: {},
  idsForFilters: []
};

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'makes',
  initialState,
  reducers: {
    loadMakes: (state, action: PayloadAction<Make[]>) => {
      action.payload.forEach((make) => {
        state.byId[make.id] = make;
      });
    },
    loadMakesForFilters: (state, action: PayloadAction<Make[]>) => {
      action.payload.forEach((make) => {
        state.byId[make.id] = make;
      });
      state.idsForFilters = action.payload.map((make) => make.id);
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const { loadMakesForFilters, loadMakes } = slice.actions;

export default slice.reducer;
