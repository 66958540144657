import type { FC } from 'react';

import { useUser } from '../../../hooks/useUser';
import routes, { InsightsPages } from '../../../routes';
import { useLocalizer } from '../../../localization';
import { NavSubsection } from './navSubsection';
import { NavLink } from '../navLink';

export const NavigationSection: FC = () => {
  const { user, userIsLoggedIn } = useUser();
  const loggedIn = userIsLoggedIn(user);
  const loc = useLocalizer();

  return (
    <div className="mb-6 flex gap-x-4 lg:mb-0">
      <div className={`flex flex-1 flex-col gap-4 ${loggedIn ? 'lg:flex-row' : ''}`}>
        <NavSubsection title={loc.Nav.Buy}>
          <NavLink href={routes.buy()}>{!loggedIn ? loc.Nav.HowToBuy : loc.Common.BuyEquipment}</NavLink>
          <NavLink href={routes.marketplace()}>{loc.Nav.BrowseMarketplace}</NavLink>
          <NavLink href={routes.search()}>{loc.Common.Search}</NavLink>
        </NavSubsection>

        <NavSubsection title={loc.Nav.Sell}>
          <NavLink href={routes.sell()}>{!loggedIn ? loc.Nav.HowToSell : loc.Common.SellEquipment}</NavLink>
        </NavSubsection>
      </div>

      <div className={`flex flex-1 flex-col gap-4 ${loggedIn ? 'lg:flex-row' : ''}`}>
        <NavSubsection title={loc.Footer.Explore}>
          <NavLink href={routes.home(user, userIsLoggedIn)}>{!loggedIn ? loc.Nav.Home : loc.Nav.Dashboard}</NavLink>
          <NavLink href={routes.services()}>{loc.Nav.Services}</NavLink>
        </NavSubsection>

        <NavSubsection title={loc.Nav.Company}>
          <NavLink href={routes.about()}>{loc.Nav.AboutMoov}</NavLink>
          <NavLink href={routes.companyProfile()}>{loc.Common.CompanyProfile}</NavLink>
          {/* todo #182883082 {MOOVDEV-2130} implement /team page */}
          {/*
            <NavLink href={routes.team()} className="hover:underline w-fit">
              {loc.Nav.OurTeam}
            </NavLink>
            */}
          <NavLink href={routes.careers()}>{loc.Nav.CareersAtMoov}</NavLink>
          <NavLink href={routes.news()}>{loc.Nav.NewsAndPress}</NavLink>
          <NavLink href={routes.blog()}>{loc.Common.Blog}</NavLink>
          {/* TODO: TRANSLATIONS */}
          <NavLink href={routes.insights(InsightsPages.marketTrends2022)}>Market Insights 2022</NavLink>
        </NavSubsection>
      </div>
    </div>
  );
};
