import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { useLocalizer } from '@/src/localization';
import { SearchFlowType } from '@/src/marketplace/search/types';
import routes from '@/src/routes';
import { getCategoriesLoaded, getParentCategoryRelationships } from '@/src/state/category/selectors';
import { fetchCategoriesForStore } from '@/src/state/category/thunks';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
interface CategoriesProps {
  onNavigate: () => void;
}

export const Categories: FC<CategoriesProps> = ({ onNavigate }) => {
  const dispatch = useAppDispatch();
  const loaded = useAppSelector(getCategoriesLoaded);
  const parentCategoryDetails = useAppSelector(getParentCategoryRelationships);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [openParentCategory, setOpenParentCategory] = useState(0);
  const loc = useLocalizer();
  const router = useRouter();
  const { fullStoryEvent } = useFullStory();

  useEffect(() => {
    dispatch(fetchCategoriesForStore());
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <ListItem>
        <ListItemButton
          onClick={() => {
            setCategoriesOpen(!categoriesOpen);
            !categoriesOpen && setOpenParentCategory(0);
          }}
        >
          <ListItemText primary={loc.Nav.Categories} />
          {categoriesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={categoriesOpen} timeout="auto" unmountOnExit data-testid="mobile-header-category-dropdown">
        <List component="div" disablePadding>
          {parentCategoryDetails.map((category) => {
            const parentCategoryOpen = openParentCategory === category.id;

            return (
              <>
                <ListItem>
                  <ListItemButton
                    sx={{ justifyContent: 'space-between', pl: 3 }}
                    onClick={() => setOpenParentCategory(category.id === openParentCategory ? 0 : category.id)}
                  >
                    <ListItemText
                      onClick={() => {
                        fullStoryEvent(CustomEvents.navigation.categories.categoryClick, {
                          category: category.name,
                          category_id: category.id
                        });
                        onNavigate();
                        router.push(routes.search({ category_id: category.id, search_flow_type: SearchFlowType.Category }));
                      }}
                      primary={category.name}
                    />
                    {parentCategoryOpen ? (
                      <Stack width="60px" alignItems={'end'}>
                        <ExpandLess />
                      </Stack>
                    ) : (
                      <Stack width="60px" alignItems={'end'}>
                        <ExpandMore data-testid={`open-mobile-parent-category-${category.id}`} />
                      </Stack>
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse in={parentCategoryOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.categories.map((childCategory) => {
                      return (
                        <ListItem key={`child-category-mobile-dropwon-$${childCategory.name}`}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              fullStoryEvent(CustomEvents.navigation.categories.categoryClick, {
                                category: childCategory.name,
                                category_id: childCategory.id
                              });
                              onNavigate();
                              router.push(routes.search({ category_id: childCategory.id, search_flow_type: SearchFlowType.Category }));
                            }}
                          >
                            <ListItemText primary={childCategory.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
