export const GTM_EVENT_TYPES = {
  listing: {
    create: 'moov-listing-create',
    quickviewMakeOffer: 'moov-listing-quickview-make-offer',
    makeOffer: 'moov-listing-make-offer',
    share: 'moov-listing-share',
    contactUs: 'moov-listing-contact-us',
    quickviewContactUs: 'moov-listing-quickview-contact-us',
    notifyMe: 'moov-listing-notify-me',
    viewTinder: 'moov-listing-tool-tinder'
  },
  header: {
    buy: 'moov-header-buy',
    sell: 'moov-header-sell',
    howItWorksDropdown: 'moov-header-how-it-works-dropdown',
    waysToMoov: 'moov-header-ways-to-moov',
    howToBuy: 'moov-header-how-to-buy',
    howToSell: 'moov-header-how-to-sell',
    createListing: 'moov-header-create-listing',
    dashboard: 'moov-header-dashboard',
    profileDropdown: 'moov-header-profile-dropdown',
    editProfile: 'moov-header-edit-profile',
    contactUs: 'moov-header-contact-us',
    register: 'moov-header-register'
  },
  browse: {
    contactUs: 'moov-browse-contact-us',
    quickview: 'moov-browse-quickview',
    newListing: 'moov-browse-new-listing'
  },
  dashboard: {
    createNewWanted: 'moov-dashboard-create-new-wanted-click'
  },
  wanted: {
    create: 'moov-wanted-create'
  },
  search: {
    quickview: 'moov-search-quickview',
    listing: 'moov-search-listing'
  },
  makeModels: {
    item: 'moov-make-models-item'
  },
  store: {
    create: 'moov-store-create'
  },
  contactUsForm: {
    header: 'moov-contact-us-form-header',
    browse: 'moov-contact-us-form-browse',
    quickview: 'moov-contact-us-form-quickview',
    productListing: 'moov-contact-us-form-product-listing',
    notifyMe: 'moov-contact-us-form-notify-me',
    makeOffer: 'moov-contact-us-form-make-offer',
    quickviewMakeOffer: 'moov-contact-us-form-quickview-make-offer'
  },
  register: {
    header: 'moov-register-header',
    headerClick: 'moov-register-click-header',
    loginModal: 'moov-register-login-modal',
    loginModalClick: 'moov-register-click-login-modal',
    landing: 'moov-register-landing',
    landingClick: 'moov-register-click-landing'
  },
  general: {
    register: 'moov-register',
    browseTools: 'moov-browse-tools',
    watchIntroVideo: 'moov-watch-intro-video',
    search: 'moov-search'
  }
};
