import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { Localizer } from './localizer';

export const useLocalizer = () => {
  const router = useRouter();

  return useMemo(() => new Localizer(router.locale), [router.locale]);
};
