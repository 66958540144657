import { Dispatch, useReducer } from 'react';

import WorkflowModal from '@/src/bits/modals/workflowModal';
import { Tabs } from '@/src/design/bytes/tabs';
import { Localizer, useLocalizer } from '@/src/localization';
import { create } from '@/src/equipment/thunks';
import { closeModal } from '@/src/state/globalModal/slice';
import { AppDispatch, useAppDispatch } from '@/src/store';

import { ConfigurationTab } from './configurationTab';
import { DetailsTab } from './detailsTab';
import { AddFormState, Reducer, getInitialState, Action, hasModelSet, hasCountrySet } from './state';
import { Equipment2, argIsToolStatus } from '@/src/equipment/types';
import { Address, idToCondition } from '@/src/common/lib';

export interface SubProps {
  state: AddFormState;
  dispatch: Dispatch<Action>;
  loc: Localizer;
}

const saveAndClose = (state: AddFormState, appDispatch: AppDispatch) => {
  const ws = state.waferSizes.map((v) => `${v}`);
  const tool_status = argIsToolStatus(state.toolStatus) ? state.toolStatus : undefined;
  const dto = new Equipment2({
    minimum_price: `${state.minSellPrice}`,
    condition: idToCondition(`${state.condition}`),
    wafer_size: ws.join(','),
    vintage: state.vintage === '' ? 0 : state.vintage,
    serial_number: state.serialNumber,
    quantity: state.quantity === '' ? 0 : state.quantity,
    address: new Address(state.address),
    description: state.description,
    configuration: state.configuration,
    tool_status,
    custom_fields: state.customFields
  });
  return appDispatch(
    create({
      ...dto.toSerializable(),
      ...(state.modelNotListed ? { raw_make: state.textManufacturer, raw_model: state.textModel } : { model_id: state.modelId })
    })
  );
};

const getButtonClickHandler = (state: AddFormState, appDispatch: AppDispatch, setCurrentTab: (tabNumber: number) => void) => {
  return () => {
    if (state.currentTab == 0) {
      return Promise.resolve(setCurrentTab(1));
    } else {
      return saveAndClose(state, appDispatch);
    }
  };
};

export const AddEquipmentModal = () => {
  const [state, dispatch] = useReducer(Reducer, getInitialState());
  const loc = useLocalizer();

  const appDispatch = useAppDispatch();

  const setCurrentTab = (tabNumber: number) => {
    dispatch({
      type: 'setCurrentTab',
      value: tabNumber
    });
  };

  const props = { state, dispatch, loc };
  const nextDisabled = !hasModelSet(state) || (hasModelSet(state) && !hasCountrySet(state));
  const tabBoxContent = {
    DETAILS: {
      label: loc.Remarketing.Details.toUpperCase(),
      tabContent: <DetailsTab {...props} state={state} dispatch={dispatch} />
    },
    CONFIGURATION: {
      label: loc.Listing.Configuration.toUpperCase(),
      disabled: nextDisabled,
      tabContent: <ConfigurationTab {...props} state={state} dispatch={dispatch} />
    }
  };

  return (
    <WorkflowModal
      title={loc.EquipmentManage.AddEquipment}
      modalOpen
      closeModal={() => appDispatch(closeModal())}
      currentStep={state.currentTab}
      totalSteps={2}
      setCurrentStep={setCurrentTab}
      actionButtonContent={state.currentTab == 0 ? loc.Common.Next : loc.ToolRequest.Save}
      actionButtonSubmit={getButtonClickHandler(state, appDispatch, setCurrentTab)}
      actionButtonDisabled={nextDisabled}
      contentClassName="!p-0"
    >
      <Tabs tabs={tabBoxContent} onChange={setCurrentTab} selectedTabIndex={state.currentTab} variant="fullWidth" />
    </WorkflowModal>
  );
};
