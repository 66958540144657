import { spacing } from '@/src/design/designConstants';
import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { BuyerOffer, ReadableOfferStatus } from '../types';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import routes from '@/src/routes';
import { SearchFlowType } from '@/src/marketplace/search/types';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { PendingSellerCounterCTAs } from './pendingSellerCounterCTAs';

export const BuyerOfferCTAs: FC<{ offer: BuyerOffer }> = ({ offer }) => {
  const dispatch = useAppDispatch();
  const { fullStoryAndGTMEvent } = useFullStory();

  if (offer.isPendingSellerCounter) {
    return (
      <Stack data-testid="offer-cta-container" spacing={spacing.md} minWidth="fit-content" justifyContent={'center'}>
        <PendingSellerCounterCTAs offer={offer} />
      </Stack>
    );
  }

  let ctas = <></>;
  switch (offer.offer.readable_status) {
    case ReadableOfferStatus.PENDING:
    case ReadableOfferStatus.DECLINED:
      ctas = (
        <>
          {/* TODO: translations */}
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.UpdateOffer,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.MAKE_OFFER,
                  // TODO ENG-2363 new flow doesn't actually use id so we don't need it in the props after cleanup
                  modalProps: { makeOffer: { listing: { ...offer.listing, id: 0 }, offer: offer.toSerializable() } }
                })
              );
            }}
            id="offer-cta-update-offer"
            variant="outlined"
            color="info"
            size="large"
            sx={{ whiteSpace: 'nowrap' }}
          >
            <Typography variant="button14AllCaps">INCREASE OFFER</Typography>
          </Button>
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.MessageSeller,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.MESSAGE_SELLER,
                  modalProps: { messageSeller: { listingKey: offer.listing.key } }
                })
              );
            }}
            id="offer-cta-message-seller"
            fullWidth
            size="large"
            variant="outlined"
            color="info"
          >
            <Typography variant="button14AllCaps">MESSAGE SELLER</Typography>
          </Button>
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.ViewListing,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
            }}
            id="offer-cta-view-listing"
            variant="outlined"
            color="info"
            size="large"
            href={routes.listing(offer.listing)}
            target="_blank"
          >
            <Typography variant="button14AllCaps">VIEW LISTING</Typography>
          </Button>
        </>
      );
      break;
    case ReadableOfferStatus.EXPIRED:
      ctas = (
        <>
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.UpdateOffer,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.MAKE_OFFER,
                  // TODO ENG-2363 new flow doesn't actually use id so we don't need it in the props after cleanup
                  modalProps: { makeOffer: { listing: { ...offer.listing, id: 0 }, offer: offer.toSerializable() } }
                })
              );
            }}
            id="offer-cta-update-offer"
            variant="contained"
            color="primary"
            size="large"
          >
            <Typography variant="button14AllCaps">SUBMIT OFFER</Typography>
          </Button>
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.MessageSeller,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.MESSAGE_SELLER,
                  modalProps: { messageSeller: { listingKey: offer.listing.key } }
                })
              );
            }}
            fullWidth
            id="offer-cta-message-seller"
            variant="outlined"
            color="info"
            size="large"
          >
            {/* TODO: translations */}
            <Typography variant="button14AllCaps">MESSAGE SELLER</Typography>
          </Button>
          <Button
            onClick={() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                type: OfferCTATypes.ViewSimilar,
                listingKey: offer.listing.key,
                offerKey: offer.key
              });
            }}
            variant="outlined"
            color="info"
            size="large"
            id="offer-cta-view-similar"
            href={routes.search({ search_flow_type: SearchFlowType.Model, model_id: [offer.listing.model.id] })}
            target="_blank"
          >
            <Typography variant="button14AllCaps">VIEW SIMILAR</Typography>
          </Button>
        </>
      );
      break;
  }

  return (
    <Stack data-testid="offer-cta-container" spacing={spacing.md} minWidth="fit-content" justifyContent={'center'}>
      {ctas}
    </Stack>
  );
};
