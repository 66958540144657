import translations from './error.loc.json';
import { getDefinitions, Locale } from './index';

/**
 *
 */

export class ErrorLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  // -- #words
  // -----------------------------------------------

  public get InvalidUserPassword(): string {
    return this.definitions.InvalidUserPassword;
  }

  public get SomethingWentWrongRefreshPage(): string {
    return this.definitions.SomethingWentWrongRefreshPage;
  }

  public get SomethingWentWrong(): string {
    return this.definitions.SomethingWentWrong;
  }

  public get ThereWasAnErrorProcessingRequest(): string {
    return this.definitions.ThereWasAnErrorProcessingRequest;
  }

  public get ThereAreErrorsInTheForm(): string {
    return this.definitions.ThereAreErrorsInTheForm;
  }
}
