import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCookie } from '../../utils/cookies';
import { COOKIE_LANGUAGE_PREFERENCE_NAME } from '../../constants/cookie';
import { isSupportedLocale } from './utils';

export const useLanguagePreference = () => {
  const router = useRouter();

  const { pathname, asPath, query, locale, isReady } = router;

  useEffect(() => {
    const languagePreferenceCookieValue = getCookie(document, COOKIE_LANGUAGE_PREFERENCE_NAME);
    if (
      isReady &&
      languagePreferenceCookieValue &&
      locale !== languagePreferenceCookieValue &&
      isSupportedLocale(languagePreferenceCookieValue)
    ) {
      router.push({ pathname, query }, asPath, { locale: languagePreferenceCookieValue });
    }
  }, [isReady]);
};
