import { ContactSalesFormValues } from './types';

export const isContactSalesFormSubmitDisabled = (formValues: ContactSalesFormValues) => {
  return Object.values(formValues.inquiry_reason_values).every((value) => !value);
};

export const defaultContactSalesFormValues: ContactSalesFormValues = {
  inquiry_reason_values: [],
  notes: ''
};
