import { useReducer } from 'react';
import { SuggestedListing } from '../../../entities/listing';
import { FetchUserSuggestedListingsParams } from '../../../providers/listings';
import { ViewMode } from '../../../userBuy/suggestedItems/suggestedItemsContent/types';
import { getFreshInitialState, OpportunitiesReducer } from '../helpers';
import { SuggestedOpportunityDispatch, SuggestedOpportunityStore } from './context';

export const SuggestedOpportunityProvider = ({ children }: { children: any }): JSX.Element => {
  const [opportunitiesState, dispatch] = useReducer(OpportunitiesReducer, getFreshInitialState());

  const rejectOpportunity = (opportunityKey: string) => {
    dispatch({
      type: 'reject',
      payload: {
        opportunity_key: opportunityKey
      }
    });
  };

  const approveOpportunity = (opportunityKey: string) => {
    dispatch({
      type: 'approve',
      payload: {
        opportunity_key: opportunityKey
      }
    });
  };

  const loadOpportunities = (opportunities: SuggestedListing[]) => {
    dispatch({
      type: 'load',
      payload: {
        listings: opportunities
      }
    });
  };

  const selectCurrentOpportunity = (opportunityKey: string) => {
    dispatch({
      type: 'selectCurrentListing',
      payload: {
        opportunity_key: opportunityKey
      }
    });
  };

  const selectNextOpportunity = () => {
    dispatch({
      type: 'selectNextOpportunity'
    });
  };

  const fetchOpportunities = () => {
    dispatch({
      type: 'fetch'
    });
  };

  const updateQueryParams = (queryParams: FetchUserSuggestedListingsParams) => {
    dispatch({
      type: 'updateQueryParams',
      payload: {
        queryParams
      }
    });
  };

  const completeInitialFetchOpportunities = () => {
    dispatch({
      type: 'completeInitialFetch'
    });
  };

  const openModal = () => {
    dispatch({
      type: 'openModal'
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'closeModal'
    });
  };

  const reset = () => {
    dispatch({
      type: 'reset'
    });
  };

  const resetTransientState = () => {
    dispatch({
      type: 'resetTransientState'
    });
  };

  const selectSuggestedItemView = (viewMode: ViewMode) => {
    dispatch({
      type: 'selectSuggestedItemView',
      payload: {
        viewMode: viewMode
      }
    });
  };

  const clearSelectionMade = () => {
    dispatch({
      type: 'clearSelectionMade'
    });
  };

  return (
    <SuggestedOpportunityStore.Provider value={opportunitiesState}>
      <SuggestedOpportunityDispatch.Provider
        value={{
          clearSelectionMade,
          rejectOpportunity,
          approveOpportunity,
          selectCurrentOpportunity,
          selectNextOpportunity,
          loadOpportunities,
          fetchOpportunities,
          updateQueryParams,
          completeInitialFetchOpportunities,
          openModal,
          closeModal,
          resetTransientState,
          reset,
          selectSuggestedItemView
        }}
      >
        {children}
      </SuggestedOpportunityDispatch.Provider>
    </SuggestedOpportunityStore.Provider>
  );
};
