import { Typography } from '@mui/material';
import { FC } from 'react';

export interface PageTitleProps {
  title: string;
  leftChildren?: React.ReactNode;
}

export const PageTitle: FC<PageTitleProps> = ({ title, children, leftChildren }) => {
  return (
    <div className="flex items-center justify-between gap-x-4 border-b-2 border-gray-200 bg-white px-6 pb-2.5 pt-3">
      {!!leftChildren && <div>{leftChildren}</div>}
      <Typography variant="h6">{title}</Typography>
      <div className="flex gap-4">{children}</div>
    </div>
  );
};
