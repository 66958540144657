import axios from 'axios';

import type { DTOModel } from './dtos';
import type { DTOWithMultiWrapper, DTOWithSingleWrapper, FindManyParams } from '../types';
import { MOOV_API_URL } from '../../constants';

// -- #methods
// -----------------------------------------------

/**
 * Staging DB data errors:
 *    - Category undefined: 3289
 *    - Category malformed: 7398, 7397, 7396, 7395
 *    - Make undefined: 0
 */

interface FindManyModelParams extends FindManyParams {
  readonly q?: string;
  readonly category_id?: number | string;
  readonly make_id?: number | string;
}

/**
 * todo #182884862 update this to type the return as (DTOModel | DTOModelSearchResult)[]
 */
export const findManyModel = async (params: FindManyModelParams): Promise<DTOModel[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOModel>>(`${MOOV_API_URL}/models`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params
  });

  return res.data.data;
};

/**
 *
 */
export const findManyUserSuggestedModels = async (): Promise<DTOModel[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOModel>>(`${MOOV_API_URL}/models/suggested`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });

  return res.data.data;
};

/**
 *
 */
export const findOneModel = async (modelSlugOrId: string | number): Promise<DTOModel> => {
  const res = await axios.get<DTOWithSingleWrapper<DTOModel>>(`${MOOV_API_URL}/models/${modelSlugOrId}`, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });

  return res.data.data;
};
