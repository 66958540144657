import { stringFromKey } from '../common/lib';

export interface CompanyRemarketingInfo {
  remarketing_slug: string;
  remarketing_name: string;
  remarketing_logo: string;
}

export const fromAPI = (obj: any): CompanyRemarketingInfo => {
  return {
    remarketing_slug: stringFromKey('remarketing_slug', obj),
    remarketing_name: obj.remarketing_name,
    remarketing_logo: obj.remarketing_logo
  };
};
