import { useLocalizer } from '@/src/localization';
import routes from '@/src/routes';
import { ListItemButton, ListItemText, Divider, ListItem } from '@mui/material';
import { FC } from 'react';

export interface LoginProps {
  onLogin: () => void;
  onRegister: () => void;
}
export const Login: FC<LoginProps> = ({ onLogin, onRegister }) => {
  const loc = useLocalizer();

  return (
    <>
      <ListItem>
        <ListItemButton onClick={onLogin}>
          <ListItemText primary={loc.Nav.LogIn} />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemButton onClick={onRegister} href={routes.register()}>
          <ListItemText primary={loc.Nav.Register} />
        </ListItemButton>
      </ListItem>
    </>
  );
};
