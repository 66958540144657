import { BionicOpportunityProvider } from './opportunities/bionic/provider';
import { SuggestedOpportunityProvider } from './opportunities/suggested/provider';
import { SidebarProvider } from './sidebar/provider';
import MobileSuggestedItemsModalProvider from './mobileSuggestedItemsModal/provider';

// As different global contexts are created, add all the providers here to enable the app to access them
export const GlobalState = ({ children }: { children: any }): JSX.Element => {
  return (
    <>
      <SuggestedOpportunityProvider>
        <BionicOpportunityProvider>
          <SidebarProvider>
            <MobileSuggestedItemsModalProvider>{children}</MobileSuggestedItemsModalProvider>
          </SidebarProvider>
        </BionicOpportunityProvider>
      </SuggestedOpportunityProvider>
    </>
  );
};

// use this to wrap an export to keep <GlobalState> out of the DOM, if desired
export const withGlobalState = (wrapMeInState: any) => {
  return <GlobalState>{wrapMeInState}</GlobalState>;
};
