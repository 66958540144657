import { useLocalizer } from '@/src/localization';
import routes from '@/src/routes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemText, Collapse, List, ListItem, Link } from '@mui/material';
import { FC, MouseEventHandler, useState } from 'react';
import { getCMSLinksForLocation } from '@/src/state/meta/selectors';
import { CMSLocations } from '@/src/state/meta/cmsLinkTypes';
import { useAppSelector } from '@/src/store';

export interface LearnMoreProps {
  onNavigate: () => void;
}
export const LearnMore: FC<LearnMoreProps> = ({ onNavigate }) => {
  const [learnMoreIsOpen, setLearnMoreOpen] = useState(false);
  const loc = useLocalizer();

  const cmsLinks = useAppSelector((state) => getCMSLinksForLocation(state, CMSLocations.LEARN_MORE));

  const toggleLearnMore = () => {
    setLearnMoreOpen(!learnMoreIsOpen);
  };

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (_) => {
    onNavigate();
  };
  return (
    <>
      <ListItem>
        <ListItemButton onClick={toggleLearnMore}>
          <ListItemText primary={loc.Services.LearnMore} />
          {learnMoreIsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={learnMoreIsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.sell()}>
              <ListItemText primary={loc.Nav.Sell} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.buy()}>
              <ListItemText primary={loc.Nav.Buy} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.services()}>
              <ListItemText primary={loc.Nav.Services} />
            </ListItemButton>
          </ListItem>

          {cmsLinks.map((link) => (
            <ListItem key={`${CMSLocations.LEARN_MORE}${link.path}-mobile`}>
              <ListItemButton
                LinkComponent={(props) => <Link {...{ ...props, component: 'a' }}>{props.children}</Link>}
                sx={{ pl: 4 }}
                onClick={handleClick}
                href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}${link.path}`}
              >
                <ListItemText primary={link.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};
