import { ERROR_MESSAGES, MOOV_API_URL } from '@/src/constants';
import { Category } from '@/src/entities/category';
import { FindManyParams } from '@/src/providers/types';
import { AppDispatch, AppState } from '@/src/store';
import {
  fetchCategories as fetchCategoriesAction,
  loadCategories,
  loadCategoriesForFilters,
  loadCategoriesError,
  loadCategory
} from './slice';
import axios from 'axios';
import { Category as CategoryClass } from '@/src/providers/categories/helpers';
import { captureException } from '@sentry/nextjs';
import { openError } from '../banner/slice';
import { getCategoryById } from './selectors';

export const fetchCategoriesForStore = (params?: FindManyParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    dispatch(fetchCategoriesAction());
    return axios
      .get<{ data: Category[] }>(`${MOOV_API_URL}/categories`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadCategories(resp.data.data.map((category) => new CategoryClass(category).toSerializable())));
      })
      .catch((error) => {
        captureException(error);
        dispatch(loadCategoriesError());
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export const getOrFetchCategory = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => AppState): Promise<Category | void> => {
    const category = getCategoryById(getState(), id);
    if (category) {
      return category;
    }

    return axios
      .get<{ data: Category }>(`${MOOV_API_URL}/categories/id/${id}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
      .then((resp) => {
        const category = new CategoryClass(resp.data.data).toSerializable();
        dispatch(loadCategory(category));
        return category;
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export const fetchCategoriesForFilters = (params?: FindManyParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<{ data: Category[] }>(`${MOOV_API_URL}/categories`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadCategoriesForFilters(resp.data.data.map((category) => new CategoryClass(category).toSerializable())));
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export const fetchCategoriesByMake = (make_id: number, params?: FindManyParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<{ data: Category[] }>(`${MOOV_API_URL}/categories/make/${make_id}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadCategoriesForFilters(resp.data.data.map((category) => new CategoryClass(category).toSerializable())));
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export const fetchRelatedCategories = (category_id: number, params?: FindManyParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<{ data: Category[] }>(`${MOOV_API_URL}/categories/${category_id}/related`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadCategoriesForFilters(resp.data.data.map((category) => new CategoryClass(category).toSerializable())));
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};
