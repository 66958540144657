import { FC } from 'react';
import { SellerOffer } from '../types';
import { Paper, Divider, Stack, Typography, useTheme } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { parseUSDCurrencyStr } from '@/src/utils';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { useAppSelector } from '@/src/store';
import { getLogisticCountries } from '@/src/state/logistics/selectors';

export const AcceptedOfferSummary: FC<{ accepted_offer: SellerOffer }> = ({ accepted_offer }) => {
  const countryOptions = useAppSelector(getLogisticCountries);
  const { palette } = useTheme();

  return (
    <>
      <Paper
        sx={{
          py: spacing.md,
          px: spacing.lg,
          border: `1px solid ${palette.error.light}`,
          background: palette.error.lighter,
          color: palette.error.main
        }}
        variant="outlined"
      >
        <Stack whiteSpace={'nowrap'} direction="row" height="100%" alignItems="center" spacing={spacing.md}>
          <FAIcon icon={icons.circle} />
          <Typography variant="p14">
            <strong>Sold</strong>
          </Typography>
        </Stack>
      </Paper>
      <Paper
        sx={{
          py: spacing.md,
          px: spacing.lg,
          border: `1px solid ${palette.secondary.light}`,
          background: colors.brand[50],
          color: palette.secondary.main,
          width: '100%'
        }}
        variant="outlined"
      >
        <Stack direction="row" alignItems="center" spacing={spacing['xl']} height="100%">
          <Stack spacing={spacing.xs} width={{ xs: 'fill-available', d: 'fit-content' }}>
            <Typography variant="p12">
              <strong>Accepted Offer:</strong>
            </Typography>
            <Typography variant="p12" color={colors.crayolaGreen['500']}>
              <strong>{`${parseUSDCurrencyStr(accepted_offer.offer_amount)} USD`}</strong>
            </Typography>
          </Stack>

          <Divider sx={{ display: { d: 'block', xs: 'none' } }} orientation="vertical" />

          <Stack spacing={spacing.xs} display={{ d: 'flex', xs: 'none' }}>
            <Typography variant="p12">
              <strong>Destination Country:</strong>
            </Typography>
            <Typography variant="p12">{accepted_offer.countryText(countryOptions)}</Typography>
          </Stack>

          <Divider sx={{ display: { d: 'block', xs: 'none' } }} orientation="vertical" />

          <Stack spacing={spacing.xs} display={{ d: 'flex', xs: 'none' }}>
            <Typography variant="p12">
              <strong>Offer Expiration:</strong>
            </Typography>
            <Typography variant="p12" color={colors.crayolaGreen['500']}>
              <strong>Accepted</strong>
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
