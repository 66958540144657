import { Chip, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { DropDownOption } from '../../../bits/forms/fields/dropdowns/types';

export type MultiSelectWithOptionsProps<T extends string[]> = SelectProps<T> & {
  options: DropDownOption[];
  value: T;
  className?: string;
  handleChange: (newValue: string[]) => void;
};

export const MultiSelectWithOptions = function <T extends string[]>({
  options,
  value,
  handleChange,
  ...muiSelectProps
}: MultiSelectWithOptionsProps<T>): JSX.Element {
  const onChange = (event: SelectChangeEvent<T>) => {
    const newValue = event.target.value;
    if (typeof newValue === 'string') {
      handleChange(newValue.split(','));
    } else if (Array.isArray(newValue)) {
      handleChange(newValue);
    }
  };

  return (
    <Select
      className="w-full"
      multiple
      value={value}
      onChange={onChange}
      size="small"
      data-testid={'Select'}
      {...muiSelectProps}
      renderValue={(selected) => (
        <>
          {selected.map((value) => (
            <Chip key={value} label={options.find((option) => option.id === value)?.label} />
          ))}
        </>
      )}
    >
      {options.map(({ id, label }) => {
        return (
          <MenuItem value={id} key={id}>
            <ListItemText primary={label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
