import * as UsersRepo from './repo';

import { getValidProfileImageURL } from '../../utils';
import { isRepresentativeLite } from './helpers';
import {
  UserLite,
  CreateUser,
  UpdateUser,
  UpdateUserWithPassword,
  ResetUserPasswordPayload,
  UpdateUserPasswordPayload,
  VerifyEmailToken,
  CreateUserContact,
  CreateAnonContact,
  User,
  CreateEnterpriseContact
} from '../../entities/user/types';
// import { useAppDispatch } from '@/src/store';

// -- #methods
// -----------------------------------------------

/**
 * Requires browser context with a valid session id set.
 */
export const fetchUser = async (): Promise<{ user: User | null; viewerCountryCode: string }> => {
  const data = await UsersRepo.findOneUser();
  return { user: data.user, viewerCountryCode: data.viewerCountryCode };
};

export const fetchOneUserForServerSideRenderedFeatureFlags = async (cookieHeader: string) => {
  return await UsersRepo.findOneUserForServerSideRenderedFeatureFlags(cookieHeader);
};

export const fetchUserLiteByKey = async (opportunityKey: string): Promise<UserLite> => {
  const userLite = await UsersRepo.findOneUserByOpportunityKey(opportunityKey);

  const user = {
    ...userLite
  };
  if (isRepresentativeLite(userLite.representative)) {
    user.representative = {
      ...userLite.representative,
      image_url: getValidProfileImageURL(userLite.representative?.image_url)
    };
  }

  return user;
};

export const createUser = async (payload: CreateUser): Promise<void> => {
  return UsersRepo.createOneUser(payload);
};

/**
 * Requires browser context with a valid session id set.
 */
export const updateUser = async (userId: number, payload: UpdateUser | UpdateUserWithPassword): Promise<void> => {
  return UsersRepo.updateOneUser(userId, payload);
};

export const resetUserPassword = async (payload: ResetUserPasswordPayload): Promise<void> => {
  return UsersRepo.resetOneUserPassword(payload);
};

export const updateUserPassword = async (payload: UpdateUserPasswordPayload): Promise<void> => {
  return UsersRepo.updateOneUserPassword(payload);
};

export const sendVerificationEmail = async (): Promise<void> => {
  return UsersRepo.sendOneVerificationEmail();
};

export const verifyEmail = async (payload: VerifyEmailToken): Promise<void> => {
  return UsersRepo.verifyOneEmail(payload);
};

export const unsubscribeUser = async (hash: string, params: unknown): Promise<number> => {
  return UsersRepo.unsubscribeOneUser(hash, params);
};

export const createContact = async (payload: CreateUserContact | CreateAnonContact | CreateEnterpriseContact): Promise<void> => {
  return UsersRepo.createOneContact(payload);
};
