import { EquipmentSerializable } from '@/src/equipment/types';
import { RequestPriceFormState } from './requestPriceForm';
import isEqual from 'lodash/isEqual';
import { RequestPriceModalSteps } from '.';
import { unreachable } from '@/src/utils/typeSafe';
import { Localizer } from '@/src/localization';

export const isFormDirty = (formState: RequestPriceFormState, equipment: EquipmentSerializable) => {
  const { condition, wafer_size_values, vintage, serial_number } = formState;
  const {
    condition: equipmentCondition,
    wafer_size_values: equipmentWaferSizeValues,
    vintage: equipmentVintage,
    serial_number: equipmentSerialNumber
  } = equipment;

  return (
    condition !== equipmentCondition ||
    !isEqual(wafer_size_values, equipmentWaferSizeValues) ||
    vintage !== equipmentVintage ||
    serial_number !== equipmentSerialNumber
  );
};

export const getActionContent = (loc: Localizer, currentStep: RequestPriceModalSteps) => {
  switch (currentStep) {
    case RequestPriceModalSteps.FORM:
      return loc.EquipmentManage.RequestPricing;
    case RequestPriceModalSteps.PHOTOS:
      return loc.EquipmentManage.Save;
    default:
      return unreachable(currentStep);
  }
};
