import { useRouter } from 'next/router';
import { useEffect, useContext, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import * as opportunityProviders from '../../providers/opportunities';
import { fetchUserOpportunities } from './helpers';
import { touchPoint } from '../../providers/touchPoints';
import { TouchPointType } from '../../providers/touchPoints/constants';
import { FetchUserSuggestedListingsParams } from '../../providers/listings';
import { SuggestedListing, isSuggestedListing, ListingActionStatus } from '../../entities/listing';
import { SuggestedOpportunityDispatch, SuggestedOpportunityStore } from '../../state/opportunities/suggested/context';
import { useOpenCloseMobileSuggestedItemsToolTinder } from '../useOpenCloseMobileSuggestedItemsToolTinder';
import { useCaptureExceptionForExternalUsers } from '../useCaptureExceptionForExternalUsers';
import { useMarkOpportunitySeen } from '../useMarkOpportunitySeen';

// TODO: make the two fetch params and object so it can extend without tons of params
export const useOpportunities = (includeAllOpportunities = false, fetch = false) => {
  const router = useRouter();
  const state = useContext(SuggestedOpportunityStore);
  const { currentListing: currentListingUntyped, selectionMade, suggestedListingsRecord, loaded, loading, queryParams, viewMode } = state;
  const currentListing = currentListingUntyped as SuggestedListing;
  const actions = useContext(SuggestedOpportunityDispatch);
  const [error, setError] = useState<Error | null>(null);
  const isBionic = !!router.query.bionicId;
  useEffect(() => {
    fetch && actions.reset();
  }, [fetch]);

  useEffect(() => {
    if (!loaded && !loading && fetch && !isBionic) {
      fetchOpportunities(queryParams);
    }
  }, [loaded, fetch]);

  const { markOpportunitySeenWithSentry } = useMarkOpportunitySeen();
  const { captureExceptionForExternalUsers } = useCaptureExceptionForExternalUsers();
  const { user, userIsLoggedIn, userIsInternal } = useUser();

  const markCurrentOpportunityTouched = () => {
    if (userIsLoggedIn(user) && !userIsInternal(user)) {
      touchPoint({
        touchPoint: {
          type: TouchPointType.TOUCH_POINT_TYPE_VISIT_LISTING,
          task: `Visited listing FID: ${currentListing.id}`,
          details: JSON.stringify({ url: router.asPath })
        }
      });
    }
  };

  const markCurrentOpportunitySeen = () => {
    if (userIsLoggedIn(user) && !userIsInternal(user)) {
      markOpportunitySeenWithSentry(currentListing.opportunity_key, true);
      touchPoint({
        touchPoint: {
          type: TouchPointType.TOUCH_POINT_TYPE_VISIT_LISTING,
          task: `Visited listing FID: ${currentListing.id}`,
          details: JSON.stringify({ url: router.asPath })
        }
      });
    }
  };

  //#region CALCULATED HELPERS
  const isAccepted = isSuggestedListing(currentListing) && currentListing.opportunity_status === ListingActionStatus.approved;
  const isRejected = isSuggestedListing(currentListing) && currentListing.opportunity_status === ListingActionStatus.rejected;
  const userHasAcceptedOpportunity = selectionMade && isAccepted;
  const userHasRejectedOpportunity = selectionMade && isRejected;
  const suggestedOpportunities = Object.values(suggestedListingsRecord);
  //#endregion CALCULATED HELPERS

  //#region HELPER METHODS

  const isLastOpportunity = () =>
    isSuggestedListing(state.currentListing) && state.currentListing?.opportunity_key === [...state.suggestedListingKeys].pop();

  const { openModal, closeModal } = useOpenCloseMobileSuggestedItemsToolTinder();

  const fetchOpportunities = (queryParams: FetchUserSuggestedListingsParams) => {
    fetchUserOpportunities(queryParams, actions, state, undefined, includeAllOpportunities);
  };

  //#endregion HELPER METHODS

  // #region COMPOUND METHODS

  const rejectOpportunity = (onSuccess?: () => void, onFailure?: (e: any) => void) => {
    markCurrentOpportunitySeen();
    opportunityProviders
      .rejectOpportunity(currentListing.opportunity_key, currentListing.id)
      .then(() => {
        onSuccess && onSuccess();
        actions.rejectOpportunity(currentListing.opportunity_key);
      })
      .catch((err) => {
        captureExceptionForExternalUsers(err, {
          contexts: { rejectOpportunity: { opportunityKey: currentListing.opportunity_key, listingId: currentListing.id } }
        });
        onFailure && onFailure(err);
        setError(err);
      });
  };

  const approveOpportunity = (onSuccess?: () => void, onFailure?: (e: any) => void) => {
    markCurrentOpportunitySeen();
    opportunityProviders
      .approveOpportunity(currentListing.opportunity_key, currentListing.id)
      .then(() => {
        onSuccess && onSuccess();
        actions.approveOpportunity(currentListing.opportunity_key);
      })
      .catch((err) => {
        captureExceptionForExternalUsers(err, {
          contexts: { approveOpportunity: { opportunityKey: currentListing.opportunity_key, listingId: currentListing.id } }
        });
        onFailure && onFailure(err);
        setError(err);
      });
  };

  const selectCurrentOpportunity = (opportunityKey: string) => {
    markCurrentOpportunitySeen();
    actions.selectCurrentOpportunity(opportunityKey);
  };

  // #endregion COMPOUND METHODS

  return {
    ...state,
    error,
    currentListing,
    isLoading: loading,
    isAccepted,
    isRejected,
    suggestedOpportunities,
    noOpportunities: loaded && (suggestedOpportunities.length === 0 || !isSuggestedListing(state.currentListing)),
    userHasAcceptedOpportunity,
    userHasRejectedOpportunity,
    queryParams: state.queryParams,
    viewMode,
    isLastOpportunity,
    markCurrentOpportunitySeen,
    rejectOpportunity,
    approveOpportunity,
    fetchOpportunities,
    updateQueryParams: actions.updateQueryParams,
    selectCurrentOpportunity: selectCurrentOpportunity,
    selectNextOpportunity: actions.selectNextOpportunity,
    openModal: openModal,
    closeModal: closeModal,
    selectSuggestedItemView: actions.selectSuggestedItemView,
    reset: actions.reset,
    resetTransientState: actions.resetTransientState,
    markCurrentOpportunityTouched,
    clearSelectionMade: actions.clearSelectionMade
  };
};
