import WorkflowModal from '@/src/bits/modals/workflowModal';
import { ERROR_MESSAGES } from '@/src/constants';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useLocalizer } from '@/src/localization';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { Button, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ContactForm, ContactFormValues } from '../../../forms/contactForm';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getModalState } from '@/src/state/globalModal/selectors';
import { GlobalModalTypes, closeModal, openModal } from '@/src/state/globalModal/slice';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { colors, spacing } from '@/src/design/designConstants';
import * as yup from 'yup';
import { isContactFormSubmitDisabled } from '../../../forms/contactForm/helpers';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { ListingForms, submitListingForm } from '@/src/state/forms/thunks';
import { isDTOLeadDefined } from '@/src/providers/leads/types';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { ExistingUserRegisterAccount } from '@/src/state/tracking/fullstory/customEvents/register';

export enum AnonRequestDetailsFormSteps {
  CONTACT_INFO = 1,
  REQUEST_DETAILS = 2
}
type PossibleSteps = AnonRequestDetailsFormSteps.CONTACT_INFO | AnonRequestDetailsFormSteps.REQUEST_DETAILS;

export interface AnonRequestDetailsFormValues extends ContactFormValues {
  budget: string;
  questions: Array<string>;
}

export const AnonRequestDetailsModal = () => {
  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const {
    modalProps: { requestDetails }
  } = useAppSelector(getModalState);
  const { openErrorAlert } = useGlobalAlertBanner();
  const { fullStoryEvent } = useFullStory();

  const [currentStep, setCurrentStep] = useState<PossibleSteps>(AnonRequestDetailsFormSteps.CONTACT_INFO);
  const initialValues: AnonRequestDetailsFormValues = {
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
    contact_preference: 8,
    contact_handle: '',
    budget: '',
    questions: ['']
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).shape({ budget: yup.string(), questions: yup.array() })
  );

  if (!requestDetails?.listing) return null;

  const stepData: Record<
    AnonRequestDetailsFormSteps,
    {
      title: string;
      actionButtonDisabled: boolean;
      actionButtonContent: string;
      actionButtonSubmit: () => Promise<void>;
      actionButtonId?: string;
      dismissButtonId?: string;
    }
  > = {
    [AnonRequestDetailsFormSteps.CONTACT_INFO]: {
      // TODO: Translations #185801497 - Contact Information
      title: 'Contact Information',
      actionButtonDisabled: isContactFormSubmitDisabled(formValidationHook),
      actionButtonContent: loc.Common.Next,
      actionButtonSubmit: async () => {
        const formIsValid = await formValidationHook.validateForm();
        if (formIsValid) {
          setCurrentStep(AnonRequestDetailsFormSteps.REQUEST_DETAILS);
        } else {
          openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
          setCurrentStep(AnonRequestDetailsFormSteps.CONTACT_INFO);
        }
      },
      actionButtonId: 'listing-contact-sales-anon-step-1-submit-button',
      dismissButtonId: 'listing-contact-sales-anon-step-1-dismiss-button'
    },
    [AnonRequestDetailsFormSteps.REQUEST_DETAILS]: {
      title: loc.Listing.RequestDetails,
      actionButtonDisabled: false,
      actionButtonContent: loc.Forms.Submit,
      actionButtonSubmit: async () => {
        fullStoryEvent(CustomEvents.listing.formSubmit, { type: 'ContactSales' });
        return dispatch(
          submitListingForm(
            {
              company: formValidationHook.formValues.company,
              from_url: window.location.href,
              first_name: formValidationHook.formValues.first_name,
              last_name: formValidationHook.formValues.last_name,
              email: formValidationHook.formValues.email,
              phone: formValidationHook.formValues.phone,
              contact_preference: formValidationHook.formValues.contact_preference,
              contact_handle: formValidationHook.formValues.contact_handle,
              is_offer: 0,
              budget: parseInt(formValidationHook.formValues.budget),
              notes: formValidationHook.formValues.questions.filter((q) => Boolean(q)).join('\n'),
              listing_id: requestDetails.listing.id,
              model_id: requestDetails.listing.model.id,
              form_name: ListingForms.REQUEST_DETAILS
            },
            requestDetails.listing.key
          )
        )
          .then((lead) => {
            if (isDTOLeadDefined(lead) && !lead.user_id) {
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.CREATE_ACCOUNT,
                  modalProps: {
                    createAccount: {
                      leadInfo: {
                        email: lead.email,
                        key: lead.key,
                        first_name: lead.first_name,
                        last_name: lead.last_name,
                        company: lead.company,
                        phone: lead.phone
                      }
                    }
                  }
                })
              );
            } else {
              const email = formValidationHook.formValues.email;
              fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_PROMPTED, { email });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.EXISTING_ACCOUNT_RESET_PASSWORD,
                  modalProps: { existingAccountResetPassword: { email } }
                })
              );
            }
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      },
      actionButtonId: 'listing-contact-sales-anon-step-2-submit-button',
      dismissButtonId: 'listing-contact-sales-anon-step-2-dismiss-button'
    }
  };
  return (
    <WorkflowModal
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={2}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      {...stepData[currentStep]}
      actionButtonId={stepData[currentStep].actionButtonId ?? 'modal-action-button'}
      dismissButtonId={stepData[currentStep].dismissButtonId ?? 'modal-dismiss-button'}
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Stack minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        {currentStep === AnonRequestDetailsFormSteps.CONTACT_INFO && <ContactForm formValidationHook={formValidationHook} />}
        {currentStep === AnonRequestDetailsFormSteps.REQUEST_DETAILS && (
          <Stack spacing={spacing['xl']}>
            <LabeledCurrencyInput
              inputProps={{ 'data-testid': 'request-details-budget' }}
              value={formValidationHook.formValues.budget}
              onChange={(e) => formValidationHook.setFieldValue('budget', e.target.value)}
              variant="outlined"
              label={loc.Forms.TargetPrice}
            />
            <Stack spacing={spacing.lg}>
              {formValidationHook.formValues.questions.map((question, index) => (
                // There is no good unique identifier for the key on these questions.
                // If you use the question value itself, it will cause the input to un-mount/mount
                // for every character so the input looses focus on every key stroke.
                // eslint-disable-next-line react/jsx-key
                <Stack>
                  {/* TODO: Translations #185801497 - What would you like to know? */}
                  {!index && <FormLabel>What would you like to know?</FormLabel>}
                  <Stack direction="row" alignContent="center">
                    <TextField
                      inputProps={{ 'data-testid': `request-details-question-${index}` }}
                      fullWidth
                      value={question}
                      onChange={(e) => {
                        const newQuestions = [...formValidationHook.formValues.questions];
                        newQuestions.splice(index, 1, e.target.value);
                        formValidationHook.setFieldValue('questions', newQuestions);
                      }}
                    />

                    <Button
                      onClick={() => {
                        const newQuestions = [...formValidationHook.formValues.questions];
                        newQuestions.splice(index, 1);
                        formValidationHook.setFieldValue('questions', newQuestions);
                      }}
                      variant="contained"
                      color="info"
                    >
                      <FAIcon icon={icons.xMark} />
                    </Button>
                  </Stack>
                </Stack>
              ))}
              <Stack
                direction="row"
                spacing={spacing.sm}
                padding={spacing.lg}
                sx={{ cursor: 'pointer' }}
                onClick={() => formValidationHook.setFieldValue('questions', [...formValidationHook.formValues.questions, ''])}
              >
                <FAIcon icon={icons.circlePlus} color={colors.magenta['500']} />
                <Typography color={colors.magenta['500']} variant="button14AllCaps">
                  {loc.Magic.AddQuestion}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </WorkflowModal>
  );
};
