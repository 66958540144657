import type { FC } from 'react';

import { useUser } from '../../../hooks/useUser';

interface NavSubsectionProps {
  readonly title: string;
}

export const NavSubsection: FC<NavSubsectionProps> = (props) => {
  const { user, userIsLoggedIn } = useUser();
  const { children, title } = props;

  return (
    <div className={`flex flex-col ${userIsLoggedIn(user) ? 'flex-1' : ''}`}>
      <div className="text-sky-blue-500 uppercase">{title}</div>
      {children}
    </div>
  );
};
