import { DropDownOption } from './types';

export const isDropdownOption = (value: unknown): value is DropDownOption => {
  return !!value && typeof value === 'object' && 'label' in value && 'id' in value;
};

export const isDropdownOptionArray = (value: unknown): value is DropDownOption[] => {
  return Array.isArray(value) && value.every(isDropdownOption);
};

export const getDropdownOptionsFromMap = (optionsMap: Map<string | number, string>): DropDownOption[] => {
  return [...optionsMap.entries()].map(([value, name]) => ({ id: value.toString(), label: name }));
};
