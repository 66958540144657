import type { SimpleDateFormat, Url } from '../common';
import { ListingSummary } from '../listing';
import { Representative } from '../user/types';

export interface SettlementSummary {
  readonly key: string;
  readonly name: string;
  readonly pipeline_id: number;
  readonly closed_at: SimpleDateFormat;
  readonly current_step: SettlementStep;
  readonly shipped_via: SettlementShippedVia;
  readonly comments: SettlementComment[];
  readonly delivery_estimate_at: SimpleDateFormat | null;
  readonly listings: ListingSummary[];
}

export type SettlementShippedVia = 1 | 2 | 3;
export interface SettlementDetail extends SettlementSummary {
  readonly files: SettlementFileDTO[];
  readonly representative: Representative;
  readonly buyer_specialist: Representative;
  readonly steps: SettlementStep[];
  readonly tracking: SettlementTracking[];
  readonly ship_to: string;
}

export interface SettlementComment {
  readonly id: number;
  readonly text: string;
  readonly created_at: SimpleDateFormat;
}

export interface SettlementFileDTO {
  readonly name: string;
  readonly title: string;
  readonly download_url: Url;
}

/**
 * WARN: `slug` is not a true Slug and should not be used for routing.
 */
export interface SettlementStep {
  readonly name: SettlementStepName;
  readonly slug: SettlementStepSlug;
  readonly completed_at: SimpleDateFormat | null;
}

export interface SettlementTracking {
  lat: number | null;
  lng: number | null;
  country: string | null;
  state: string | null;
  locality: string | null;
  temperature: number | null;
  humidity: number | null;
  light: number | null;
  motion: number | null;
  timestamp: number;
}

export enum SettlementStepName {
  CONTRACT = 'Contract',
  PICKED_UP = 'Picked Up',
  IN_TRANSIT = 'In Transit',
  DELIVERED = 'Delivered'
}
export enum SettlementStepSlug {
  CONTRACT = 'contracted',
  PICKED_UP = 'received',
  IN_TRANSIT = 'shipped',
  DELIVERED = 'delivered'
}

export enum SettlementChipStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NOT_COMPLETED = 'not_completed'
}

export interface GenerateSettlementStepProps {
  readonly name?: SettlementStepName;
  readonly slug?: SettlementStepSlug;
  readonly completed_at?: SimpleDateFormat | null;
}

export interface GenerateSettlementCommentProps {
  readonly id?: number;
  readonly text?: string;
  readonly created_at?: SimpleDateFormat;
}

export interface GenerateSettlementSummaryProps {
  key?: string;
  name?: string;
  pipeline_id?: number;
  closed_at?: SimpleDateFormat;
  current_step?: SettlementStep;
  comments?: SettlementComment[];
  delivery_estimate_at?: SimpleDateFormat | null;
}
