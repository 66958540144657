import { useReducer } from 'react';
import { SidebarStore, initialState, sidebarReducer, SidebarDispatch } from './context';

export const SidebarProvider = ({ children }: { children: any }): JSX.Element => {
  const [sidebarState, dispatch] = useReducer(sidebarReducer, initialState);

  const openSidebar = () => {
    dispatch({
      type: 'open'
    });
  };

  const closeSidebar = () => {
    dispatch({ type: 'close' });
  };

  const hideSidebar = () => {
    dispatch({ type: 'hide' });
  };
  const unhideSidebar = () => {
    dispatch({ type: 'unhide' });
  };

  const endTransition = () => {
    dispatch({ type: 'transitionEnd' });
  };

  return (
    <SidebarStore.Provider value={sidebarState}>
      <SidebarDispatch.Provider value={{ openSidebar, closeSidebar, hideSidebar, unhideSidebar, endTransition }}>
        {children}
      </SidebarDispatch.Provider>
    </SidebarStore.Provider>
  );
};
