import type { FC } from 'react';

import { DropzoneIcon } from './dropzoneIcon';
import { DropzoneText } from './dropzoneText';

interface DesktopDropzoneUIProps {
  readonly fileType: 'file' | 'image';
  readonly isDragActive: boolean;
  readonly isDragReject: boolean;
  readonly acceptedTypesLabel: string;
  readonly maxSize: number;
}

export const DesktopDropzoneUI: FC<DesktopDropzoneUIProps> = (props) => {
  const { fileType, isDragActive, isDragReject, acceptedTypesLabel, maxSize } = props;

  const getContainerClassName = () => {
    const baseClassName =
      'relative hidden flex-col items-center justify-center group rounded-xl px-10 py-8.5 outline-dashed outline-2 lg:flex min-h-33.5';
    let outlineClassName = 'outline-smokey-gray-300 hover:outline-moov-blue-500';
    let bgClassName = 'bg-smokey-gray-50 hover:bg-moov-blue-100';

    if (isDragActive && !isDragReject) {
      outlineClassName = 'outline-moov-blue-500';
      bgClassName = 'bg-white';
    }

    if (isDragReject) {
      outlineClassName = 'outline-hot-red-500';
      bgClassName = 'bg-white';
    }

    return `${baseClassName} ${outlineClassName} ${bgClassName}`;
  };

  return (
    <div className={getContainerClassName()}>
      {/* INSTRUCTIONAL TEXT */}
      <div className="flex items-center gap-x-4">
        <DropzoneIcon fileType={fileType} isDragReject={isDragReject} isDragActive={isDragActive} />
        <DropzoneText isDragActive={isDragActive} isDragReject={isDragReject} acceptedTypesLabel={acceptedTypesLabel} maxSize={maxSize} />
      </div>
    </div>
  );
};
