import { useDebounce } from '@/src/hooks/useDebounce';
import routes from '@/src/routes';
import { useRouter } from 'next/router';
import { useState, ChangeEventHandler, useEffect } from 'react';
import { isValidSearchParams, queryToFilterValues, validateFilterValues } from './helpers';
import { FilterValues, FilterVisibility, SearchFlowType } from './types';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { ERROR_MESSAGES } from '@/src/constants';
import { captureException } from '@sentry/nextjs';

export const useFilterValues = () => {
  const { openErrorAlert } = useGlobalAlertBanner();
  const router = useRouter();
  const parsedVals = queryToFilterValues(router.query);

  const handleFilterValuesChange = (newVals: Partial<FilterValues>) => {
    const updatedFilterValues = { ...parsedVals, ...newVals };

    // Makes will be refetched so we're clearing selected makes to prevent
    // the user from having selected makes that are no longer visible
    if (newVals.category_id && updatedFilterValues.search_flow_type === SearchFlowType.Category) {
      newVals.make_id = [];
    }
    // Models will be refetched so we're clearing selected models to prevent
    // the user from having selected models that are no longer visible
    else if (newVals.category_id && updatedFilterValues.search_flow_type === SearchFlowType.Make) {
      newVals.model_id = [];
    }

    if (isValidSearchParams(updatedFilterValues)) {
      router.replace(routes.search(updatedFilterValues), undefined, { scroll: false });
    } else {
      const error = validateFilterValues(updatedFilterValues);
      if (error) {
        console.error(error);
        captureException(error, {
          tags: {
            component: 'useFilterValues',
            file: 'src/marketplace/search/hooks.tsx'
          },
          extra: {
            newVals: JSON.stringify(newVals),
            parsedVals: JSON.stringify(parsedVals)
          }
        });
        openErrorAlert(ERROR_MESSAGES.NO_REFRESH);
      }
    }
  };

  const getFilterVisibility = (): FilterVisibility => {
    switch (parsedVals.search_flow_type) {
      case SearchFlowType.Category:
        return { category: true, make: true, model: false };
      case SearchFlowType.Make:
        return { category: true, make: false, model: true };
      case SearchFlowType.Model:
        return { category: false, make: false, model: false };
      case SearchFlowType.PlainText:
      default:
        return { category: true, make: false, model: false };
    }
  };

  return {
    filterValues: parsedVals,
    filterVisibilty: getFilterVisibility(),
    handleFilterValuesChange
  };
};

export const useSearchStrFilter = <T extends { name: string }>(objs: T[], callback: (filteredResults: T[]) => any) => {
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    setSearchStr('');
  }, [objs]);

  const filterByStr = useDebounce((objs: T[], searchStr: string, callback: (filteredResults: T[]) => any) => {
    const regex = new RegExp(searchStr.split('').join('.*?'), 'i');
    const matchedObjs = objs.filter((obj) => regex.test(obj.name));
    callback(matchedObjs);
  }, 300);

  const handleSearchStrChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchStr(e.target.value);
    filterByStr(objs, e.target.value, callback);
  };

  return {
    searchStr,
    handleSearchStrChange
  };
};
