import { useLocalizer } from '@/src/localization/hooks';
import routes from '@/src/routes';
import { Button, Link, Menu, MenuItem, Typography } from '@mui/material';
import { FC, useState, MouseEvent } from 'react';
import { getCMSLinksForLocation } from '@/src/state/meta/selectors';
import { CMSLocations } from '@/src/state/meta/cmsLinkTypes';
import { useAppSelector } from '@/src/store';

export const LearnMore: FC = () => {
  const loc = useLocalizer();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const cmsLinks = useAppSelector((state) => getCMSLinksForLocation(state, CMSLocations.LEARN_MORE));

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (_: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  // TODO: translations
  return (
    <>
      <Button onClick={openMenu} variant="text" color="secondary">
        <Typography variant={'body1'} textTransform={'uppercase'} fontWeight="600">
          {'Learn More'}
        </Typography>
      </Button>
      <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
        <Link underline="none" href={routes.sell()} color="secondary" key={'sell-link'}>
          <MenuItem onClick={closeMenu}>{loc.Nav.Sell}</MenuItem>
        </Link>
        <Link underline="none" href={routes.buy()} color="secondary" key={'buy-link'}>
          <MenuItem onClick={closeMenu}>{loc.Nav.Buy}</MenuItem>
        </Link>
        <Link underline="none" href={routes.services()} color="secondary" key={'services-link'}>
          <MenuItem onClick={closeMenu}>{loc.Nav.Services}</MenuItem>
        </Link>
        {cmsLinks.map((link) => (
          <Link
            component="a"
            underline="none"
            href={`${process.env.NEXT_PUBLIC_MOOV_CMS_DOMAIN}${link.path}`}
            color="secondary"
            key={`${CMSLocations.LEARN_MORE}${link.path}-desktop`}
          >
            <MenuItem onClick={closeMenu}>{link.label}</MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
};
