import { FC } from 'react';
import { useRouter } from 'next/router';
import { FEATURE_NAME, useFeatures } from '../../hooks/useFeatures';
import { isServerSideRendering } from '../../utils/next/isServerSideRendering';
import { ROUTE_PATHNAMES } from '../../routes';

export interface FeatureGuardProps {
  featureName: FEATURE_NAME;
  failureRedirect?: (typeof ROUTE_PATHNAMES)[keyof typeof ROUTE_PATHNAMES]; // YEAH BABY CHECK THIS CRAY CRAY TYPE OUT
  opposite?: boolean;
}

export const FeatureGuard: FC<FeatureGuardProps> = ({ featureName, failureRedirect, children, opposite }) => {
  const features = useFeatures();
  const router = useRouter();

  let showFeature = Object.values(features[featureName]).every((value) => value);
  if (opposite) showFeature = !showFeature;

  if (showFeature) {
    return <>{children}</>;
  } else if (failureRedirect && features[featureName].ready && !isServerSideRendering()) {
    router.push({ pathname: failureRedirect });
  }

  return <></>;
};
