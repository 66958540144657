import { AppState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { BuyerOffer, SellerOfferGroup } from './types';
import { OfferSortBys } from './slice';

const getBuyerOfferIds = createSelector([(state: AppState) => state.offers.buyerOfferIds], (buyerOfferIds) => buyerOfferIds);

const getById = createSelector([(state: AppState) => state.offers.byId], (byId) => byId);
const getFilters = createSelector([(state: AppState) => state.offers.filters], (filters) => filters);

export const getBuyerOffers = createSelector([getBuyerOfferIds, getById], (buyerOfferIds, byId) => {
  return buyerOfferIds.map((id) => BuyerOffer.anyToDto(byId[id]));
});

export const getBuyerOfferByListingKey = createSelector(
  [getBuyerOffers, (_, listingKey: string) => listingKey],
  (buyerOffers, listingKey) => {
    return buyerOffers.find((offer) => offer.listing.key === listingKey) ?? null;
  }
);

export const getFilteredBuyerOffers = createSelector([getFilters, getBuyerOffers], (filters, buyerOffers) => {
  const { status, modelId } = filters;
  return buyerOffers
    .filter((offer) => status === 'all' || offer.offer.readable_status === status)
    .filter((offer) => modelId === '0' || offer.listing.model.id.toString() === modelId);
});

const getSellerofferIds = createSelector([(state: AppState) => state.offers.sellerOfferIds], (sellerOfferIds) => sellerOfferIds);

export const getSellerOffers = createSelector([getSellerofferIds, getById], (sellerOfferIds, byId) => {
  return sellerOfferIds.map((id) => SellerOfferGroup.anyToDto(byId[id]));
});

export const getFilteredSellerOffers = createSelector([getFilters, getSellerOffers], (filters, sellerOffers) => {
  const { status, modelId, sortBy } = filters;
  return sellerOffers
    .filter((offer) => status === 'all' || offer.all_offers.find((o) => o.readable_status === status))
    .filter((offer) => modelId === '0' || offer.listing.model.id.toString() === modelId)
    .sort((a, b) => {
      if (sortBy === OfferSortBys.HIGHEST_OFFER) {
        //grap highest_offer first(only pending)
        const bOfferToSort = b.highest_offer?.offer_amount
          ? b.highest_offer.offer_amount
          : // if no highest offer, grab accepted offer
          b.accepted_offer?.offer_amount
          ? b.accepted_offer.offer_amount
          : // if no accepted offer, grab the highest offer of all of them
            b.all_offers.sort((a, b) => b.offer_amount - a.offer_amount)[0].offer_amount;
        //grap highest_offer first(only pending)
        const aOfferToSort = a.highest_offer?.offer_amount
          ? a.highest_offer.offer_amount
          : // if no highest offer, grab accepted offer
          a.accepted_offer?.offer_amount
          ? a.accepted_offer.offer_amount
          : // if no accepted offer, grab the highest offer of all of them
            a.all_offers.sort((a, b) => b.offer_amount - a.offer_amount)[0].offer_amount;
        return bOfferToSort - aOfferToSort;
      }

      const dateA = new Date(a.highest_offer?.expires_at || 0).getTime();
      const dateB = new Date(b.highest_offer?.expires_at || 0).getTime();

      if (sortBy === OfferSortBys.EXPIRE_TIME_DESC) {
        return dateB - dateA;
      }

      if (sortBy === OfferSortBys.EXPIRE_TIME_ASC) {
        return dateA - dateB;
      }

      return 0;
    });
});

export const getExpandedOfferId = createSelector(
  [(state: AppState) => state.offers.expandedOfferId],
  (currentSellerOfferId) => currentSellerOfferId
);

export const getExpandedSellerOfferGroup = createSelector([getById, getExpandedOfferId], (byId, expandedOfferId) => {
  let group: SellerOfferGroup = new SellerOfferGroup();
  if (expandedOfferId) {
    const byIdGroup = byId[expandedOfferId];
    if (byIdGroup) {
      group = SellerOfferGroup.anyToDto(byIdGroup);
    }
  }
  return group;
});

export const getExpandedBuyerOffer = createSelector([getById, getExpandedOfferId], (byId, expandedOfferId) => {
  let group: BuyerOffer = new BuyerOffer();
  if (expandedOfferId) {
    const byIdGroup = byId[expandedOfferId];
    if (byIdGroup) {
      group = BuyerOffer.anyToDto(byIdGroup);
    }
  }
  return group;
});

export const getOffersLoading = createSelector(
  [(state: AppState) => state.offers.loading, (state: AppState) => state.offers.initialized, (state: AppState) => state.offers.error],
  (loading, initialized, error) => ({
    loading,
    initialized,
    error
  })
);
export const getOfferFilters = createSelector([(state: AppState) => state.offers.filters], (filters) => filters);
