import { useAppSelector } from '../../store';
import { getModalState } from '../../state/globalModal/selectors';
import { findModal } from './utils';

const GlobalModal = () => {
  const { modalOpen, modalType } = useAppSelector(getModalState);
  const Modal = findModal({ modalType: modalType });

  return <div>{modalOpen && <Modal />}</div>;
};

export default GlobalModal;
