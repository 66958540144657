import axios from 'axios';

import type { UserDto, EmptyUserDto, UserLiteDTO } from './dtos';
import { DTOWithSingleWrapper } from '../types';
import { MOOV_API_URL } from '../../constants';
import {
  CreateAnonContact,
  CreateEnterpriseContact,
  CreateUser,
  CreateUserContact,
  ResetUserPasswordPayload,
  UpdateUser,
  UpdateUserPasswordPayload,
  UpdateUserWithPassword,
  User,
  VerifyEmailToken
} from '../../entities/user/types';

// -- #methods
// -----------------------------------------------

/**
 * Requires browser context with a valid session id set.
 */
export const findOneUser = async (): Promise<{ user: User | null; viewerCountryCode: string }> => {
  const res = await axios.get<UserDto | EmptyUserDto>(`${MOOV_API_URL}/user`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
  const viewerCountryCode = res.headers['moov-user-country-code'] ?? '-';
  const data = res.data.data?.attributes ?? null;
  return {
    user: data,
    viewerCountryCode
  };
};

export const findOneUserForServerSideRenderedFeatureFlags = async (cookieHeader: string) => {
  return await axios.get<UserDto | EmptyUserDto>(`${MOOV_API_URL}/user`, {
    headers: { 'Content-Type': 'application/vnd.api+json', cookie: cookieHeader },
    withCredentials: true
  });
};

export const findOneUserByOpportunityKey = async (opportunityKey: string): Promise<UserLiteDTO> => {
  const res = await axios.get<DTOWithSingleWrapper<UserLiteDTO>>(`${MOOV_API_URL}/opportunities/${opportunityKey}/user`, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });

  return res.data.data;
};

export const createOneUser = async (payload: CreateUser): Promise<void> => {
  await axios.post<unknown>(`${MOOV_API_URL}/user`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });
};

/**
 * Requires browser context with a valid session id set.
 */
export const updateOneUser = async (userId: number, payload: UpdateUser | UpdateUserWithPassword): Promise<void> => {
  await axios.put<unknown>(`${MOOV_API_URL}/user/${userId}`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};

export const resetOneUserPassword = async (payload: ResetUserPasswordPayload): Promise<void> => {
  await axios.put<unknown>(`${MOOV_API_URL}/user/password`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });
};

export const updateOneUserPassword = async (payload: UpdateUserPasswordPayload): Promise<void> => {
  await axios.put<unknown>(`${MOOV_API_URL}/user/password`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });
};

export const sendOneVerificationEmail = async (): Promise<void> => {
  await axios.get<unknown>(`${MOOV_API_URL}/user/verifyEmailToken`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};

export const verifyOneEmail = async (payload: VerifyEmailToken): Promise<void> => {
  await axios.post<unknown>(`${MOOV_API_URL}/user/verifyEmailToken`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};

/**
 * Unsubscribe from all emails.
 * This returns status to check for success, but the backend will return success even if the hash is wrong.
 * The backend expects BID or CID value.
 */
export const unsubscribeOneUser = async (hash: string, params: unknown): Promise<number> => {
  const res = await axios.get<unknown>(`${MOOV_API_URL}/user/${hash}/unsubscribe`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params
  });

  return res.status;
};

export const createOneContact = async (payload: CreateUserContact | CreateAnonContact | CreateEnterpriseContact): Promise<void> => {
  await axios.post<unknown>(`${MOOV_API_URL}/leads`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};
