import { captureException } from '@sentry/nextjs';
import { Condition, WaferSize } from './types';
import { SentryErrorLevels } from '@/src/constants/sentry';
import { isAppBeta, isAppDev, isAppProduction, isAppStage } from '@/src/utils/appContext';

export function dateToShort(d: Date | null) {
  let r = '';
  if (d !== null) r = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  return r;
}

export function formatDate(v: string) {
  if (!v) return '';
  const d = new Date(v);
  return dateToShort(d);
}

export function formatUnixTS(v: number) {
  if (!v) return '';
  const d = new Date(v * 1000);
  return dateToShort(d);
}

export function boolFromKey(k: string, arg: any): boolean {
  let val = false;
  try {
    val = valueFromKey<boolean>(k, arg);
  } catch {}
  return val;
}

export function numberFromKey(k: string, arg: any): number {
  let val = 0;
  try {
    val = valueFromKey<number>(k, arg);
  } catch {}
  return val;
}

export function stringFromKey(k: string, arg: any): string {
  let val = '';
  try {
    val = valueFromKey<string>(k, arg).trim();
  } catch {}
  return val;
}

export function genericObjectFromKey<T>(k: string, arg: any): T {
  let val: T;
  try {
    val = valueFromKey<T>(k, arg);
  } catch {
    val = {} as T;
  }
  return val;
}

export function valueFromKey<TValue, TKey = string>(k: TKey, arg: any): TValue {
  if (arg[k] === undefined) {
    const err = new InvalidKeyError(`Key '${k}' not found`);
    console.error(err);
    captureException(err, { level: SentryErrorLevels.FATAL, extra: { key: k, arg } });
    throw err;
  }
  return arg[k] as TValue;
}

export const isCondition = (value: unknown): value is Condition => Object.values(Condition).includes(value as Condition);
export const isWaferSize = (value: unknown): value is WaferSize => Object.values(WaferSize).includes(value as WaferSize);

export const getSessionKey = (): string => {
  if (isAppProduction()) return 'p_session_id';
  if (isAppBeta() || isAppStage()) return 'st_session_id';
  if (isAppDev()) return 'dev_session_id';
  return '';
};

export const truncateDecimals = (num: number, decimalPlaces: number): number => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.floor(num * factorOfTen) / factorOfTen;
};

export class InvalidKeyError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'InvalidKeyError';
  }
}

export const capitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
