import dayjs from 'dayjs';
import {
  Address,
  AddressSerializable,
  boolFromKey,
  numberFromKey,
  SimpleListing,
  SimpleListingSerializable,
  stringFromKey
} from '../common/lib';
import { IMAGE_URLS } from '../constants/imageUrls';
import { CountryResponse } from '../state/logistics/types';

export enum ReadableOfferStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  EXPIRED = 'Expired'
}

const formatExpireDays = (expirationTs: string): string => {
  const now = dayjs();
  const expiration = dayjs(expirationTs);
  const daysDiff = expiration.diff(now, 'day');
  const hoursDiff = expiration.diff(now, 'hour');
  let secondsDiff = expiration.diff(now, 'second');
  const minutesDiff = expiration.diff(now, 'minute');

  if (secondsDiff < 60) {
    if (secondsDiff === 0) secondsDiff++;
    return `${secondsDiff} Second${secondsDiff > 1 ? 's' : ''}`;
  }
  if (minutesDiff < 60) {
    return `${minutesDiff} Minute${minutesDiff > 1 ? 's' : ''}`;
  }
  if (daysDiff < 1) {
    return `${hoursDiff} Hour${hoursDiff > 1 ? 's' : ''}`;
  }
  return `${daysDiff} Day${daysDiff > 1 ? 's' : ''}`;
};

export enum OfferTypeName {
  OFFER = 'Offer',
  COUNTER = 'Counter Offer',
  QUOTE = 'Quote'
}

export enum OfferTypeId {
  OFFER = 1,
  COUNTER = 2,
  QUOTE = 3
}

// #region Buyer offer types

export class Offer {
  readable_status: ReadableOfferStatus = ReadableOfferStatus.PENDING;
  created_at = '';
  key = '';
  offer_amount = 0;
  required_by: string | null = '';
  destination_address = new Address();
  expires_at = '';
  offer_note = '';
  offer_subject_to = 0;
  offer_subject_to_note = '';
  current_user_is_owner = true;
  offer_type_id = OfferTypeId.OFFER;
  offer_type_name = OfferTypeName.OFFER;

  constructor(args: Partial<Offer> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): OfferSerializable {
    return {
      readable_status: this.readable_status,
      created_at: this.created_at,
      key: this.key,
      offer_amount: this.offer_amount,
      required_by: this.required_by,
      destination_address: this.destination_address.toSerializable(),
      expires_at: this.expires_at,
      offer_subject_to: this.offer_subject_to,
      offer_subject_to_note: this.offer_subject_to_note,
      offer_note: this.offer_note,
      current_user_is_owner: this.current_user_is_owner,
      offer_type_id: this.offer_type_id,
      offer_type_name: this.offer_type_name
    };
  }

  //region static members
  static anyToDto(a: any): Offer {
    const s = Offer.anyToSerializable(a);
    const destination_address = Address.anyToDto(a.destination_address ?? {});
    return new Offer({ ...s, destination_address });
  }

  static anyToSerializable(a: any): OfferSerializable {
    return {
      readable_status: stringFromKey('readable_status', a) as ReadableOfferStatus,
      created_at: stringFromKey('created_at', a),
      key: stringFromKey('key', a),
      offer_amount: numberFromKey('offer_amount', a),
      required_by: Boolean(a.required_by) ? stringFromKey('required_by', a) : null,
      destination_address: Address.anyToSerializable(a.destination_address),
      expires_at: stringFromKey('expires_at', a),
      offer_subject_to: numberFromKey('offer_subject_to', a),
      offer_subject_to_note: stringFromKey('offer_subject_to_note', a),
      offer_note: stringFromKey('offer_note', a),
      current_user_is_owner: boolFromKey('current_user_is_owner', a),
      offer_type_id: numberFromKey('offer_type_id', a) as OfferTypeId,
      offer_type_name: stringFromKey('offer_type_name', a) as OfferTypeName
    };
  }
  //endregion
}

export type OfferSerializable = {
  readable_status: ReadableOfferStatus;
  created_at: string;
  key: string;
  offer_amount: number;
  required_by: string | null;
  destination_address: AddressSerializable;
  expires_at: string;
  offer_subject_to: number;
  offer_subject_to_note: string;
  offer_note: string;
  current_user_is_owner: boolean;
  offer_type_id: OfferTypeId;
  offer_type_name: OfferTypeName;
};

export class BuyerOffer {
  offer = new Offer();
  key = '';
  listing = new SimpleListing();

  constructor(args: Partial<BuyerOffer> = {}) {
    Object.assign(this, args);
  }

  get listingSmallPhotoUrl(): string {
    return this.listing.photo?.small_url || IMAGE_URLS.NULL_STATE_PHOTO_SMALL;
  }

  get listingLargePhotoUrl(): string {
    return this.listing.photo?.large_url || IMAGE_URLS.NULL_STATE_PHOTO_LARGE;
  }

  get sellerNote(): string {
    if (!this.offer.current_user_is_owner) return this.offer.offer_note;
    return '';
  }

  get isPendingSellerCounter(): boolean {
    return this.isSellerCounter && this.offer.readable_status === ReadableOfferStatus.PENDING;
  }

  get isSellerCounter(): boolean {
    return this.offer.offer_type_id === OfferTypeId.COUNTER && !this.offer.current_user_is_owner;
  }

  get expireDaysText(): string {
    if (this.offer.readable_status === ReadableOfferStatus.ACCEPTED) {
      return this.offer.readable_status;
    } else if (this.offer.readable_status === ReadableOfferStatus.DECLINED) {
      return this.offer.readable_status;
    } else if (this.offer.readable_status === ReadableOfferStatus.EXPIRED) {
      return this.offer.readable_status;
    }

    return formatExpireDays(this.offer.expires_at);
  }

  toSerializable(): BuyerOfferSerializable {
    return {
      offer: this.offer.toSerializable(),
      key: this.key,
      listing: this.listing.toSerializable()
    };
  }

  countryText(countryOptions: CountryResponse[]): string {
    return countryOptions.find((opt) => opt.id === this.offer.destination_address.country)?.label ?? '';
  }

  //region static members
  static anyToDto(a: any): BuyerOffer {
    const s = BuyerOffer.anyToSerializable(a);
    const offer = Offer.anyToDto(a.offer);
    const listing = SimpleListing.anyToDto(a.listing);

    return new BuyerOffer({ ...s, offer, listing });
  }

  static anyToSerializable(a: any): BuyerOfferSerializable {
    return {
      offer: Offer.anyToSerializable(a.offer),
      key: stringFromKey('key', a.offer),
      listing: SimpleListing.anyToSerializable(a.listing)
    };
  }
  //endregion
}

export type BuyerOfferSerializable = {
  offer: OfferSerializable;
  key: string;
  listing: SimpleListingSerializable;
};

// #endregion

// #region Seller offer types

export class SellerOffer {
  buyer_alias = '';
  created_at = '';
  destination_address = new Address();
  expires_at = '';
  is_highest = false;
  key = '';
  offer_amount = 0;
  offer_note = '';
  readable_status = ReadableOfferStatus.PENDING;
  required_by: string | null = null;
  status_at = '';
  declined_reason = '';
  current_user_is_owner = false;
  offer_type_id = OfferTypeId.OFFER;
  offer_type_name = OfferTypeName.OFFER;

  constructor(args: Partial<SellerOffer> = {}) {
    Object.assign(this, args);
  }

  get isOwnedPendingCounter(): boolean {
    return this.readable_status === ReadableOfferStatus.PENDING && this.offer_type_id === OfferTypeId.COUNTER && this.current_user_is_owner;
  }

  get offerTypeText(): string {
    switch (this.offer_type_id) {
      case OfferTypeId.OFFER:
        return 'offer';
      case OfferTypeId.COUNTER:
        return 'counter-offer';
      case OfferTypeId.QUOTE:
        return 'quote';
      default:
        return '';
    }
  }

  get noteLabel(): string {
    // TODO: translations
    return this.current_user_is_owner ? 'Seller Note:' : 'Buyer Note:';
  }

  toSerializable(): SellerOfferSerializable {
    return {
      buyer_alias: this.buyer_alias,
      created_at: this.created_at,
      destination_address: this.destination_address,
      expires_at: this.expires_at,
      is_highest: this.is_highest,
      key: this.key,
      offer_amount: this.offer_amount,
      offer_note: this.offer_note,
      readable_status: this.readable_status,
      required_by: this.required_by,
      status_at: this.status_at,
      declined_reason: this.declined_reason,
      current_user_is_owner: this.current_user_is_owner,
      offer_type_id: this.offer_type_id,
      offer_type_name: this.offer_type_name
    };
  }

  countryText(countryOptions: CountryResponse[]): string {
    return countryOptions.find((opt) => opt.id === this.destination_address.country)?.label ?? '';
  }

  get expireDaysText(): string {
    if (this.readable_status === ReadableOfferStatus.ACCEPTED) {
      return this.readable_status;
    } else if (this.readable_status === ReadableOfferStatus.DECLINED) {
      return this.readable_status;
    } else if (this.readable_status === ReadableOfferStatus.EXPIRED) {
      return this.readable_status;
    }

    return formatExpireDays(this.expires_at);
  }

  // #region static members
  static anyToDto(a: any): SellerOffer {
    const s = SellerOffer.anyToSerializable(a);
    const address = Address.anyToDto(a.destination_address ?? {});
    return new SellerOffer({ ...s, destination_address: address });
  }

  static anyToSerializable(a: any): SellerOfferSerializable {
    return {
      buyer_alias: stringFromKey('buyer_alias', a),
      created_at: stringFromKey('created_at', a),
      destination_address: Address.anyToSerializable(a.destination_address ?? {}),
      expires_at: stringFromKey('expires_at', a),
      is_highest: boolFromKey('is_highest', a),
      key: stringFromKey('key', a),
      offer_amount: numberFromKey('offer_amount', a),
      offer_note: stringFromKey('offer_note', a),
      readable_status: stringFromKey('readable_status', a) as ReadableOfferStatus,
      required_by: Boolean(a.required_by) ? stringFromKey('required_by', a) : null,
      status_at: stringFromKey('status_at', a),
      declined_reason: stringFromKey('declined_reason', a),
      current_user_is_owner: boolFromKey('current_user_is_owner', a),
      offer_type_id: numberFromKey('offer_type_id', a) as OfferTypeId,
      offer_type_name: stringFromKey('offer_type_name', a) as OfferTypeName
    };
  }
  // #endregion
}

export type SellerOfferSerializable = {
  buyer_alias: string;
  created_at: string;
  destination_address: AddressSerializable;
  expires_at: string;
  is_highest: boolean;
  key: string;
  offer_amount: number;
  offer_note: string;
  readable_status: ReadableOfferStatus;
  required_by: string | null;
  status_at: string;
  declined_reason: string;
  current_user_is_owner: boolean;
  offer_type_id: OfferTypeId;
  offer_type_name: OfferTypeName;
};

export class SellerOfferGroup {
  all_offers: SellerOffer[] = [];
  listing = new SimpleListing();
  highest_offer: SellerOffer | null = null;
  accepted_offer: SellerOffer | null = null;
  pending_offers = 0;

  constructor(args: Partial<SellerOfferGroup> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): SellerOfferGroupSerializable {
    return {
      all_offers: this.all_offers.map((o) => o.toSerializable()),
      listing: this.listing.toSerializable(),
      highest_offer: this.highest_offer ? this.highest_offer.toSerializable() : null,
      accepted_offer: this.accepted_offer ? this.accepted_offer.toSerializable() : null,
      pending_offers: this.pending_offers
    };
  }

  // #region getters

  get listingSmallPhotoUrl(): string {
    return this.listing.photo?.small_url || IMAGE_URLS.NULL_STATE_PHOTO_SMALL;
  }

  get listingLargePhotoUrl(): string {
    return this.listing.photo?.medium_url || IMAGE_URLS.NULL_STATE_PHOTO_LARGE;
  }

  // #endregion

  // #region static members
  static anyToDto(a: any): SellerOfferGroup {
    const s = SellerOfferGroup.anyToSerializable(a);
    const all_offers = (a.all_offers ?? []).map((o: any) => SellerOffer.anyToDto(o));
    const listing = SimpleListing.anyToDto(a.listing);
    const highest_offer = a.highest_offer ? SellerOffer.anyToDto(a.highest_offer) : null;
    const accepted_offer = a.accepted_offer ? SellerOffer.anyToDto(a.accepted_offer) : null;

    return new SellerOfferGroup({ ...s, all_offers, listing, highest_offer, accepted_offer });
  }

  static anyToSerializable(a: any): SellerOfferGroupSerializable {
    return {
      all_offers: (a.all_offers ?? []).map((o: any) => SellerOffer.anyToSerializable(o)),
      listing: SimpleListing.anyToSerializable(a.listing),
      highest_offer: a.highest_offer ? SellerOffer.anyToSerializable(a.highest_offer) : null,
      pending_offers: numberFromKey('pending_offers', a),
      accepted_offer: a.accepted_offer ? SellerOffer.anyToSerializable(a.accepted_offer) : null
    };
  }
  // #endregion
}

export type SellerOfferGroupSerializable = {
  all_offers: SellerOfferSerializable[];
  listing: SimpleListingSerializable;
  highest_offer: SellerOfferSerializable | null;
  pending_offers: number;
  accepted_offer: SellerOfferSerializable | null;
};

// #endregion
