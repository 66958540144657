import { FC } from 'react';

import FormikToggleSwitchField, { FormikToggleSwitchFieldProps } from './formikToggleSwitchField';

interface CookieToggleFieldProps extends FormikToggleSwitchFieldProps {
  readonly title: string;
  readonly description: string;
}

/**
 *
 */
const FormikCookieToggleField: FC<CookieToggleFieldProps> = ({ title, description, ...toggleSwitchProps }) => (
  <label htmlFor={toggleSwitchProps.name} className="flex flex-col justify-between gap-y-2 lg:flex-row lg:gap-x-10">
    <article>
      <h6 className="font-bold">{title}</h6>
      <p>{description}</p>
    </article>
    <div className="self-end lg:self-auto">
      <FormikToggleSwitchField {...toggleSwitchProps} />
    </div>
  </label>
);

export default FormikCookieToggleField;
