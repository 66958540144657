import React, { useCallback, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { captureMessage } from '@sentry/nextjs';
import { useLocalizer } from '@/src/localization';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { EquipmentPhoto, EquipmentFile } from '../../types';
import { MobileDropzoneUI } from '@/src/listings/edit/FormikListingFileDropzone/mobileDropzoneUI';
import { DesktopDropzoneUI } from '@/src/listings/edit/FormikListingFileDropzone/desktopDropzoneUI';
import { DropzoneLabel } from './dropzoneLabel';
import { DropzoneSubmit } from './dropzoneSubmit';
import { DroppedFiles } from './droppedFiles';
import { DroppedPhotos } from './droppedPhotos';

export interface EquipmentDropzoneFile extends File {
  preview: string;
}

export type EquipmentFileDropzoneFileType = EquipmentPhoto | EquipmentFile | EquipmentDropzoneFile;

export const isEquipmentDropzoneFile = (file: EquipmentFileDropzoneFileType): file is EquipmentDropzoneFile =>
  (file as EquipmentDropzoneFile).preview !== undefined;

export const isEquipmentPhoto = (file: EquipmentFileDropzoneFileType): file is EquipmentPhoto =>
  (file as EquipmentPhoto).small_url !== undefined;

interface DropzoneProps {
  readonly label?: string;
  readonly helperText?: string;
  readonly maxSize: number;
  readonly accept: string | string[];
  readonly acceptedTypesLabel: string;
  readonly required?: boolean;
  readonly multiple?: boolean;
  readonly disableControls?: boolean;
  readonly disabled?: boolean;
  readonly fileType: 'image' | 'file';
  readonly dirty: boolean;
  readonly showUpdateButton?: boolean;
  readonly displayPhotoCTA?: boolean;
  readonly files: EquipmentFileDropzoneFileType[];
  readonly setFiles: (files: EquipmentFileDropzoneFileType[]) => void;
  readonly filesToDelete: EquipmentFileDropzoneFileType[];
  readonly setFilesToDelete: (files: EquipmentFileDropzoneFileType[]) => void;
  readonly onSubmit?: () => void;
  readonly loading?: boolean;
  readonly photoGallery?: boolean;
}

/**
 *
 */
const EquipmentFileDropzone = (props: DropzoneProps) => {
  const loc = useLocalizer();

  const { openErrorAlert } = useGlobalAlertBanner();
  const {
    label,
    helperText,
    required,
    accept,
    maxSize,
    acceptedTypesLabel,
    multiple,
    disableControls,
    disabled,
    fileType,
    dirty,
    showUpdateButton,
    displayPhotoCTA,
    files,
    setFiles,
    filesToDelete,
    setFilesToDelete,
    onSubmit,
    loading,
    photoGallery
  } = props;

  useEffect(() => {
    return () => {
      // Revoke the data uris on unmount to avoid memory leaks
      files.forEach((file) => {
        if (isEquipmentDropzoneFile(file)) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, []); // eslint-disable-line

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        if (fileRejections.some((data) => data.file.size > maxSize)) {
          // TODO: #185941670 Integrate these localized alert messages into this solution
          openErrorAlert(loc.Forms.FilesTooLarge);
          captureMessage('One or more files were too large');
        } else {
          openErrorAlert(loc.Forms.FilesNotAccepted);
          captureMessage('One or more files were not an accepted file type');
        }
        return;
      }

      const filesWithPreviews = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles([...files, ...filesWithPreviews]);
    },
    [maxSize, files, setFiles] // eslint-disable-line
  );

  const moveFileToFirst = (index: number) => {
    const updatedFiles = [...files];
    const shiftedFile = updatedFiles.splice(index, 1)[0];
    updatedFiles.unshift(shiftedFile);
    setFiles(updatedFiles);
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    const removedFile = updatedFiles.splice(index, 1)[0];
    setFiles(updatedFiles);

    if (isEquipmentDropzoneFile(removedFile)) {
      URL.revokeObjectURL(removedFile.preview); // revoke the data uri to avoid memory leaks
    } else {
      setFilesToDelete([...filesToDelete, removedFile]);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept,
    multiple
  });

  return (
    <div>
      <div className="mb-3 flex w-full flex-col">
        <DropzoneLabel label={label} required={required} helperText={helperText} />
        {/* DROPZONE WRAPPER */}
        <div className={`mb-2 flex w-full ${disabled ? 'form-group-disabled' : ''}`}>
          <div {...getRootProps()} className={`w-full ${disabled ? 'pointer-events-none' : ''}`}>
            <input {...getInputProps()} data-testid="EquipmentFileDropzone-input" />

            {/* DESKTOP DROPZONE UI */}
            <DesktopDropzoneUI
              fileType={fileType}
              isDragReject={isDragReject}
              isDragActive={isDragActive}
              acceptedTypesLabel={acceptedTypesLabel}
              maxSize={maxSize}
            />

            {/* MOBILE DROPZONE UI */}
            <MobileDropzoneUI fileType={fileType} />
          </div>
        </div>
      </div>
      <DropzoneSubmit
        displayPhotoCTA={displayPhotoCTA}
        fileType={fileType}
        files={files}
        filesToDelete={filesToDelete}
        showUpdateButton={showUpdateButton}
        dirty={dirty}
        loading={loading}
        onSubmit={onSubmit}
      />

      {/* DROPPED PHOTOS */}
      <DroppedPhotos
        fileType={fileType}
        files={files}
        disabled={disabled}
        disableControls={disableControls}
        removeFile={removeFile}
        moveFileToFirst={moveFileToFirst}
        photoGallery={photoGallery}
      />

      {/* DROPPED FILES */}
      <DroppedFiles fileType={fileType} files={files} disabled={disabled} disableControls={disableControls} removeFile={removeFile} />
    </div>
  );
};

export default EquipmentFileDropzone;
