import { MobileSuggestedItemsModalState, ModalStep, StepFormValues, WorkflowButtons } from './types';

export const updateCurrentStepFormValues = (state: MobileSuggestedItemsModalState, steps: ModalStep[], formValues: StepFormValues) => {
  if (!!steps[state.currentStep].formValues) {
    const newCurrentStep = { ...steps[state.currentStep] };
    newCurrentStep.formValues = formValues;
    const newSteps = [...steps];
    newSteps.splice(state.currentStep, 1, newCurrentStep);

    return newSteps;
  }
  return steps;
};

export const updateCurrentStepLoading = (
  state: MobileSuggestedItemsModalState,
  steps: ModalStep[],
  submittingState: { submitting: boolean; submitFailed: boolean; submitted: boolean },
  button: WorkflowButtons
) => {
  if (!!steps[state.currentStep]) {
    const newCurrentStep = { ...steps[state.currentStep], [button]: { ...steps[state.currentStep][button], ...submittingState } };
    const newSteps = [...steps];
    newSteps.splice(state.currentStep, 1, newCurrentStep);

    return newSteps;
  }
  return steps;
};
