import { AppDispatch, AppState } from '@/src/store';
import axios from 'axios';
import { GlobalBannerDurations } from '@/src/state/banner/types';
import { FreightType, CountryResponse } from '@/src/state/logistics/types';
import { captureException } from '@sentry/nextjs';
import { openError } from '../banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { loadCountries } from './slice';
import { FullstoryEvent } from '../tracking/thunks';
import { CustomEvents } from '../tracking/fullstory/customEvents';

export const getLogisticsCountries = () => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<CountryResponse[]>(`${process.env.NEXT_PUBLIC_MOOV_LOGISTICS_API_URL}/countries`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((response) => {
        dispatch(loadCountries(response.data.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))));
      })
      .catch((err) => {
        captureException(err);
        dispatch(loadCountries([]));
      });
  };
};

type LogisticsQuotePayload = { destination: string; freightType: FreightType; totalValue: string };

export const getLogisticsQuote = (listingId: number, payload: LogisticsQuotePayload) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<string> => {
    dispatch(FullstoryEvent(CustomEvents.logistics.quoteRequested));

    const payload2 = { ...payload, totalValue: parseInt(payload.totalValue) };
    return axios
      .post<{ cost: string }>(`${process.env.NEXT_PUBLIC_MOOV_LOGISTICS_API_URL}/listings/${listingId}`, payload2, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((response) => {
        const cost = response.data.cost || '';
        if (!cost) {
          dispatch(FullstoryEvent(CustomEvents.listing.logisticsFailure));
        } else {
          dispatch(FullstoryEvent(CustomEvents.listing.logisticsSuccess));
        }
        return cost;
      })
      .catch((err) => {
        captureException(err);
        dispatch(FullstoryEvent(CustomEvents.listing.logisticsFailure));
        dispatch(
          openError({
            duration: GlobalBannerDurations.DEFAULT,
            error: ERROR_MESSAGES.REFRESH
          })
        );
        return '';
      });
  };
};
