import { colors, spacing } from '@/src/design/designConstants';
import { FormValidationHook } from '@/src/hooks/useFormValidation';
import { Box, Stack, Typography } from '@mui/material';
import { ContactSalesFormValues, inquiryReasonOptions } from './types';
import { LabeledCheckboxGroup } from '@/src/design/bytes/labeledCheckboxGroup';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { getUpdatedCheckboxGroupValue } from '@/src/design/bytes/labeledCheckboxGroup/helpers';

export interface ContactSalesFormProps<T> {
  formValidationHook: FormValidationHook<T>;
}
export const ContactSalesForm = function <T extends ContactSalesFormValues>(props: ContactSalesFormProps<T>) {
  const { formValidationHook } = props;
  const { formValues, setFieldValue, onFieldBlur, isFieldErrored, getFieldError } = formValidationHook;

  return (
    <Box>
      <Box mb={spacing['3xl']}>
        <Typography variant="body1" color={colors.smokeyGray[500]}>
          {/* TODO: Translations #185801497 - Our dedicated team is here to assist all Moov users! Let us know how we can help, and we will respond to your request shortly. */}
          Our dedicated team is here to assist all Moov users! Let us know how we can help, and we will respond to your request shortly.
        </Typography>
      </Box>

      <Stack rowGap={spacing.xl}>
        <LabeledCheckboxGroup
          // TODO: Translations - Reason for inquiry
          label="Reason for inquiry"
          value={formValues.inquiry_reason_values}
          options={inquiryReasonOptions}
          onChange={(checked, value) => {
            if (value !== undefined) {
              setFieldValue('inquiry_reason_values', getUpdatedCheckboxGroupValue(formValues.inquiry_reason_values, checked, value));
            }
          }}
          onBlur={() => onFieldBlur('inquiry_reason_values')}
          error={isFieldErrored('inquiry_reason_values')}
          helperText={getFieldError('inquiry_reason_values')}
          required
        />
        <LabeledTextField
          // TODO: Translations - What additional information would you like to know?
          label={'What additional information would you like to know?'}
          multiline
          minRows={2}
          value={formValues.notes}
          onChange={(e) => setFieldValue('notes', e.target.value)}
          onBlur={() => onFieldBlur('notes')}
          error={isFieldErrored('notes')}
          helperText={getFieldError('notes')}
          inputProps={{
            'data-testid': 'contact-sales-notes'
          }}
        />
      </Stack>
    </Box>
  );
};
