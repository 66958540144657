import { FC } from 'react';
import { useUser } from '@/src/hooks/useUser';
import { UserContactSalesModal } from './userContactSalesModal';
import { AnonContactSalesModal } from './anonContactSalesModal';

interface ContactSalesModalProps {}

export const ContactSalesModal: FC<ContactSalesModalProps> = () => {
  const { user, userIsLoggedIn } = useUser();

  return userIsLoggedIn(user) ? <UserContactSalesModal /> : <AnonContactSalesModal />;
};
