import {
  getDefinitions,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class BuyMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get HeadOfProcurement(): string {
    return this.definitions.HeadOfProcurement;
  }

  public get HeroSubHeading(): [string, string, string] {
    return [this.definitions.HeroSubHeadingPreLink, this.definitions.HeroSubHeadingLink, this.definitions.HeroSubHeadingPostLink];
  }

  public get HowToBuyTitle(): string {
    return this.definitions.HowToBuyTitle;
  }

  public get HowToBuySubTitle(): string {
    return this.definitions.HowToBuySubTitle;
  }
}
