import { getDefinitions, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class CompanyProfileLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AdditionalServices(): string {
    return this.definitions.AdditionalServices;
  }

  public get BackEnd(): string {
    return this.definitions.BackEnd;
  }

  public get BrowseThousands(): string {
    return this.definitions.BrowseThousands;
  }

  public get BuyersSellersLeverage(): string {
    return this.definitions.BuyersSellersLeverage;
  }

  public get BuyWithMoov(): string {
    return this.definitions.BuyWithMoov;
  }

  public get ComplianceLogisticsTeam(): string {
    return this.definitions.ComplianceLogisticsTeam;
  }

  public get WhiteGloveService(): string {
    return this.definitions.WhiteGloveService;
  }

  public get CustomersPleased(): string {
    return this.definitions.CustomersPleased;
  }

  public get DedicatedLogisticsSpecialist(): string {
    return this.definitions.DedicatedLogisticsSpecialist;
  }

  public get DeinstallRiggingCrating(): string {
    return this.definitions.DeinstallRiggingCrating;
  }

  public get DoorToDoor(): string {
    return this.definitions.DoorToDoor;
  }

  public get EasilyListing(): string {
    return this.definitions.EasilyListing;
  }

  public get EasyAsHumanlyPossible(): string {
    return this.definitions.EasyAsHumanlyPossible;
  }

  public get FastestGrowingMarketplace(): string {
    return this.definitions.FastestGrowingMarketplace;
  }

  public get FrontEnd(): string {
    return this.definitions.FrontEnd;
  }

  public get MostAdvancedShipment(): string {
    return this.definitions.MostAdvancedShipment;
  }

  public get GlobalPresenceIn(): string {
    return this.definitions.GlobalPresenceIn;
  }

  public get Headquarters(): string {
    return this.definitions.Headquarters;
  }

  public get Idle(): string {
    return this.definitions.Idle;
  }

  public get LED(): string {
    return this.definitions.LED;
  }

  public get LiveTracking(): string {
    return this.definitions.LiveTracking;
  }

  public get Logistics(): string {
    return this.definitions.Logistics;
  }

  public get Management(): string {
    return this.definitions.Management;
  }

  public get ManageWithMoov(): string {
    return this.definitions.ManageWithMoov;
  }

  public get Mission(): string {
    return this.definitions.Mission;
  }

  public get MoovBuysAll(): string {
    return this.definitions.MoovBuysAll;
  }

  public get PaySuppliersAverage(): string {
    return this.definitions.PaySuppliersAverage;
  }

  public get PcbSmt(): string {
    return this.definitions.PcbSmt;
  }

  public get ReducedLeadTimes(): string {
    return this.definitions.ReducedLeadTimes;
  }

  public get RefurbishmentInstallation(): string {
    return this.definitions.RefurbishmentInstallation;
  }

  public get ScrapTools(): string {
    return this.definitions.ScrapTools;
  }

  public get SellWithMoov(): string {
    return this.definitions.SellWithMoov;
  }

  public get SignificantDiscount(): string {
    return this.definitions.SignificantDiscount;
  }

  public get Solar(): string {
    return this.definitions.Solar;
  }

  public get StevenMaxamTitles(): string {
    return this.definitions.StevenMaxamTitles;
  }

  public get Surplus(): string {
    return this.definitions.Surplus;
  }

  public get TheListings(): string {
    return this.definitions.TheListings;
  }

  public get TheMarketplace(): string {
    return this.definitions.TheMarketplace;
  }

  public get TheNumbers(): string {
    return this.definitions.TheNumbers;
  }

  public get Used(): string {
    return this.definitions.Used;
  }

  public get UsedBenefits(): string {
    return this.definitions.UsedBenefits;
  }

  public get WhoAreWe(): string {
    return this.definitions.WhoAreWe;
  }

  public get WhyMoov(): string {
    return this.definitions.WhyMoov;
  }

  public get MultilingualLogisticsTeam(): string {
    return this.definitions.MultilingualLogisticsTeam;
  }

  public get LogisticsExperience(): string {
    return this.definitions.LogisticsExperience;
  }

  public get WouldRecommend(): string {
    return this.definitions.WouldRecommend;
  }
}
