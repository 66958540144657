import { useLocalizer } from '../localization';

export enum ERROR_MESSAGES {
  REFRESH = 'Something went wrong. Try refreshing the page, or try again later.',
  NO_REFRESH = 'Something went wrong. Try again later.',
  ERROR_PROCESSING_REQUEST = 'There was an error processing your request.',
  FORM_INVALID = 'There are errors in the form. Please review and try again.'
}

export const useErrorMessages = () => {
  const loc = useLocalizer();

  const localizedMessages = {
    [ERROR_MESSAGES.REFRESH]: loc.ErrorMessage.SomethingWentWrongRefreshPage,
    [ERROR_MESSAGES.NO_REFRESH]: loc.ErrorMessage.SomethingWentWrong,
    [ERROR_MESSAGES.ERROR_PROCESSING_REQUEST]: loc.ErrorMessage.ThereWasAnErrorProcessingRequest,
    [ERROR_MESSAGES.FORM_INVALID]: loc.ErrorMessage.ThereAreErrorsInTheForm
  };

  return {
    getErrorMessage: (message: ERROR_MESSAGES) => localizedMessages[message]
  };
};
