import type { FC } from 'react';
import { InternalLink } from '../../../bits/links';
import { MoovButton } from '../../../bits/moovButton';
import { useLocalizer } from '../../../localization';
import routes from '../../../routes';
import { LanguageSection } from './languageSection';
import { SocialMediaSection } from './socialMediaSection';

export const SocialAndLanguageSection: FC = () => {
  const loc = useLocalizer();

  return (
    <div className="mb-6 flex flex-col lg:mb-0">
      <SocialMediaSection />

      <div className="my-6 h-px w-full bg-blue-500 lg:my-4" />

      <LanguageSection />

      <div className="my-6 h-px w-full bg-blue-500 lg:my-4" />

      <div>
        <InternalLink href={routes.register()}>
          <MoovButton variant="primary" className="w-full px-2.5 py-2" tabIndex={-1}>
            {loc.Nav.Register}
          </MoovButton>
        </InternalLink>
      </div>
    </div>
  );
};
