import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import { useUser } from '../../hooks/useUser';
import type _ from './productFruits.d';
import { ProductFruitsComponentProps } from './productFruits';

let moovProductFruitsRouteChangeHandlerIsEnabled = false;

const ProductFruitsBase = dynamic<ProductFruitsComponentProps>(() => import('react-product-fruits').then((mod) => mod.ProductFruits), {
  ssr: false
});

// NOTE: product fruits doesn't always track next page transitions correctly
// so we disable automated tracking in the component render (noted below)
// and manually track ourselves using next router events
// Next pagenav can also have _some_ behavior that causes PF to de-init and re-init
// So we disable the automatic unmount, and instead manually call
// window.productFruits.services.destroy when we stop having a user.
function ProductFruits() {
  const { user } = useUser();
  const router = useRouter();

  if (!global.window) {
    return null;
  }

  if (!user) {
    // If pf is initted with a user but now we don't have one, de-init
    // there _is_ a window.productFruitsUser, but that doesn't get cleared.
    if (window.productFruitsIsReady) {
      window.productFruits?.services?.destroy?.();
    }

    return null;
  }

  const leadInfo = {
    username: user.id.toString(),
    email: user.email,
    firstname: user.first_name,
    lastname: user.last_name
  };

  if (!process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_ID) {
    return null;
  }

  return (
    <ProductFruitsBase
      workspaceCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_ID}
      // needs to be a 2 letter language code, so no differentiating between
      // zh-CN and zh-TW :/ R.I.P. ISO 639-1
      // Also japan is `ja` not `jp` ???
      language={router.locale?.split('-')[0].replace('jp', 'ja') || 'en'}
      user={leadInfo}
      // disable automated page change tracking per above
      config={{ disableLocationChangeDetection: true }}
      // disable automatic unmounting of the component per above
      lifeCycle="neverUnmount"
    />
  );
}

export default ProductFruits;

// This is _profoundly_ non-idiomatic, but that is intentional.
// We _don't_ want to clean up when we unmount, per the above comment.
// To get around multiple listeners happening, We _do_ opt out of the
// listener if the JEST_WORKER_ID environment variable is set
if (!moovProductFruitsRouteChangeHandlerIsEnabled && !process?.env?.JEST_WORKER_ID) {
  moovProductFruitsRouteChangeHandlerIsEnabled = true;
  Router.events.on('routeChangeComplete', () => {
    // manually track route changes per above.
    window?.productFruits?.pageChanged?.();
  });
}
