import SendIcon from '@mui/icons-material/Send';
import { SetModal } from '../../entities/common';
import { useModal } from '../../hooks/useModal';

import { useLocalizer } from '../../localization';
import { MoovButton } from '../moovButton';
import ModalPopup from './modalPopup';

/**
 *
 */
const ResetPasswordSuccessModal = ({ setModal }: { setModal: SetModal }) => {
  const loc = useLocalizer();
  const { isOpen, onClose } = useModal(setModal);

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose} className="max-w-sm-modal">
      <div className="flex flex-col items-center px-8 pt-4 pb-16">
        <div className="mb-10 text-center">
          <div className="mb-5 text-20spx font-bold">{loc.Modals.EmailSent}</div>
          <div>{loc.Modals.CheckInboxExpires}</div>
        </div>
        <div className="relative mb-10 flex h-36 w-36 items-center justify-center rounded-full bg-magenta-500 text-64spx text-white">
          <div className="absolute top-1/2 left-1/2 -translate-x-[45%] -translate-y-[60%] rotate-[-30deg]">
            <SendIcon fontSize="inherit" />
          </div>
        </div>
        <MoovButton variant="secondary" className="w-56 px-3 py-2.5" onClick={onClose}>
          {loc.Modals.Close}
        </MoovButton>
      </div>
    </ModalPopup>
  );
};

export default ResetPasswordSuccessModal;
