import { FC } from 'react';

import { useLocalizer } from '../localization';
import { PageContentID } from './contentWrappers';

/**
 * This is a convenience link for users of assistive technology to skip navigation elements and go straight to the main content
 * NOTE: This does not work properly on logged out root page due to how the page must be structured for the unique navbar
 */
const SkipLink: FC = () => {
  const loc = useLocalizer();

  return (
    <a
      className="absolute -top-1 left-1/2 z-skipLink block -translate-x-1/2 -translate-y-full rounded-b bg-moov-blue-500 px-4 py-2 text-center text-18spx text-white underline focus:translate-y-0"
      href={`#${PageContentID}`}
    >
      {loc.Nav.SkipToContent}
    </a>
  );
};

export default SkipLink;
