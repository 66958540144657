import { FullStory, init, isInitialized } from '@fullstory/browser';
import { useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import { isAppProduction, isAppStage } from '../utils/appContext';

export const useInitializeFullStory = () => {
  const { userIsInternal, userIsLoggedIn, user } = useUser();
  const actuallyLoggedIn = userIsLoggedIn(user);
  const actuallyInternal = userIsInternal(user);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ID || '',
      devMode: !isAppProduction() && !isAppStage(),
      debug: !isAppProduction() && !isAppStage()
    });
  }, []);

  // workaround to track when fullstory is ready to indentify the user and make api calls. we cant call the isInitialized() function outside of a hook due to nextjs
  useEffect(() => {
    if (!initialized) {
      if (isInitialized()) {
        setInitialized(true);
      }
    }
  });

  useEffect(() => {
    if (initialized) {
      // Fullstory should be in dev mode for all non-prod environments,
      // There is only 1 Fullstory account so dev data will corrupt it.
      const fullStoryUserId = `${isAppProduction() ? '' : 'dev:'}${user?.id}`;
      if (actuallyLoggedIn) {
        const companyId = user.user_company?.id;
        const companyName = user?.user_company?.name;
        const properties = {
          displayName: fullStoryUserId,
          ...(actuallyInternal && { email: user.email }),
          ...(companyId && { companyId: companyId }),
          ...(companyName && { companyName: companyName })
        };
        FullStory('setIdentity', {
          uid: fullStoryUserId,
          properties
        });
      }
    }
  }, [user?.id, actuallyLoggedIn, actuallyInternal, initialized]);
};
