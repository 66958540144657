import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CMSLinks } from './cmsLinkTypes';

export interface MetaState {
  cmsLinks: CMSLinks;
}

const initialState: MetaState = {
  cmsLinks: {}
};

export const slice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    loadCMSLinks: (state, action: PayloadAction<CMSLinks>) => {
      state.cmsLinks = action.payload;
    }
  }
});

export const { loadCMSLinks } = slice.actions;

export default slice.reducer;
