import translations from './index.loc.json';
import { getDefinitions, Locale } from '../localization';

/**
 *
 */
export class MarketplaceMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get BrowseTheMarketplace(): string {
    return this.definitions.BrowseTheMarketplace;
  }

  public get ExploreVerifiedListings(): string {
    return this.definitions.ExploreVerifiedListings;
  }

  public get BrowseUsedSemiconductorEquipment(): string {
    return this.definitions.BrowseUsedSemiconductorEquipment;
  }

  public get HotProducts(): string {
    return this.definitions.HotProducts;
  }

  public get OrBrowseAllManufacturers(): string {
    return this.definitions.OrBrowseAllManufacturers;
  }

  public get SeeAllManufacturers(): string {
    return this.definitions.SeeAllManufacturers;
  }
}
