import { useState } from 'react';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { unreachable } from '@/src/utils/typeSafe';
import { getModalState } from '@/src/state/globalModal/selectors';
import { RequestPriceForm } from './requestPriceForm';
import { getActionContent, isFormDirty } from './utils';
import { RequestPricePhotos } from './requestPricePhotos';
import { useUploadEquipmentPhotos } from '@/src/equipment/ui/equipmentFileDropzone/photoUtils/hooks';
import { useRequestPriceFormStates, useSuccessMessageListener } from './hooks';
import { useLocalizer } from '@/src/localization';

import { postPriceRequest, updateCurrentEquipment as putEquipment } from '@/src/equipment/thunks';
import { EquipmentSerializable } from '@/src/equipment/types';
import { Box } from '@mui/material';

export enum RequestPriceModalSteps {
  FORM = 1,
  PHOTOS = 2
}

type PossibleSteps = RequestPriceModalSteps.FORM | RequestPriceModalSteps.PHOTOS;

export const RequestPriceModal = () => {
  const loc = useLocalizer();
  const { modalOpen } = useAppSelector(getModalState);
  const dispatch = useAppDispatch();
  const {
    modalProps: { requestPrice }
  } = useAppSelector(getModalState);
  const [currentStep, setCurrentStep] = useState<PossibleSteps>(1);
  const { formState, setFormState, files, setFiles, filesToDelete, setFilesToDelete } = useRequestPriceFormStates();

  const { onPhotosUpdateSubmit } = useUploadEquipmentPhotos();
  const ref = useSuccessMessageListener();

  if (!requestPrice) return null;

  const { equipment } = requestPrice;

  const getActionSubmit = () => {
    switch (currentStep) {
      case RequestPriceModalSteps.FORM:
        return async () => {
          return dispatch(postPriceRequest(requestPrice.equipment.id))
            .then(() => {
              const data = {
                ...requestPrice.equipment,
                ...formState
              } as EquipmentSerializable;
              isFormDirty(formState, requestPrice.equipment) && dispatch(putEquipment(data));
            })
            .then(() => {
              ref.current = true;
              setCurrentStep(RequestPriceModalSteps.PHOTOS);
            });
        };
      case RequestPriceModalSteps.PHOTOS:
        return async () => {
          const filesToUse = [...equipment.photos, ...files];

          return onPhotosUpdateSubmit(filesToUse, filesToDelete, equipment as EquipmentSerializable).then(() => {
            dispatch(closeModal());
          });
        };
      default:
        return unreachable(currentStep);
    }
  };

  return (
    <WorkflowModal<PossibleSteps>
      title={loc.EquipmentManage.RequestMoovMarketPrice}
      modalOpen={modalOpen}
      currentStep={currentStep}
      totalSteps={2}
      closeModal={() => dispatch(closeModal())}
      setCurrentStep={setCurrentStep}
      actionButtonContent={getActionContent(loc, currentStep)}
      actionButtonSubmit={getActionSubmit()}
      actionButtonDisabled={currentStep === RequestPriceModalSteps.PHOTOS && !files.length}
      displayBackButton={false}
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Box height={{ d: '640px' }} width={{ d: '600px' }}>
        {currentStep === RequestPriceModalSteps.FORM && (
          <RequestPriceForm equipment={requestPrice.equipment as EquipmentSerializable} formState={formState} setFormState={setFormState} />
        )}
        {currentStep === RequestPriceModalSteps.PHOTOS && (
          <RequestPricePhotos
            equipment={requestPrice.equipment}
            setFiles={setFiles}
            setFilesToDelete={setFilesToDelete}
            files={files}
            filesToDelete={filesToDelete}
          />
        )}
      </Box>
    </WorkflowModal>
  );
};
