import type { FC } from 'react';
import { Icon, InputAdornment as MUIInputAdornment } from '@mui/material';
import { MUIIcon } from '../../../../entities/common';

interface InputAdornmentProps {
  IconComponent: MUIIcon;
  colorVariant: 'gray';
  position: 'end' | 'start';
}

export const InputAdornment: FC<InputAdornmentProps> = (props) => {
  const { IconComponent, colorVariant, position } = props;

  const getColorClassName = () => {
    switch (colorVariant) {
      case 'gray':
        return 'text-smokey-gray-400';
    }
  };

  return (
    <MUIInputAdornment position={position} className={getColorClassName()}>
      <Icon component={IconComponent} color="inherit" />
    </MUIInputAdornment>
  );
};
