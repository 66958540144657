import { useEffect } from 'react';
import { fetchBuyerOffers } from '../thunks';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getBuyerOffers, getFilteredBuyerOffers, getOffersLoading } from '../selectors';
import { Box, Stack, Typography } from '@mui/material';
import { MoovLoader } from '@/src/bits/forms/loaders';
import { colors, spacing } from '@/src/design/designConstants';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { reset } from '../slice';
import { NullFilterState } from '../nullFilterState';
import { BuyerOffersDesktop } from './desktop';
import { BuyerOffersMobile } from './mobile';

export const OffersBuyersTab = () => {
  const dispatch = useAppDispatch();
  const { initialized, loading } = useAppSelector(getOffersLoading);
  const filteredBuyerOffers = useAppSelector(getFilteredBuyerOffers);

  const allBuyerOffers = useAppSelector(getBuyerOffers);
  useEffect(() => {
    dispatch(fetchBuyerOffers());

    return () => {
      //Reset on unmount so state doesnt affect seller tab
      dispatch(reset());
    };
  }, []);

  if (!initialized && loading) {
    return (
      <Stack variant="center" width="100%" height="100%">
        <MoovLoader isLoading />
      </Stack>
    );
  }

  if (initialized && !allBuyerOffers.length) {
    return (
      <Stack variant="center" alignItems={'center'} width="100%" height="100%">
        <Stack width="100%" p={spacing['2xl']} spacing={spacing.xl}>
          <Stack variant="center" spacing={spacing.md} textAlign={'center'}>
            <Typography color={colors.corpGray['500']} variant="subtitle3">
              No Current Offers
            </Typography>
            <Typography color={colors.corpGray['500']} variant="p14">
              No offers yet. Be the first to place an offer and secure the equipment you need!
            </Typography>
          </Stack>
          <Stack width="100%" variant="center" py={spacing.md} px={spacing.sm}>
            <img src={IMAGE_URLS.OFFER_NULL_STATE} alt="order card illustration" />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (initialized && !filteredBuyerOffers.length) {
    return <NullFilterState />;
  }

  return (
    <>
      <Box display={{ d: 'block', xs: 'none' }}>
        <BuyerOffersDesktop />
      </Box>
      <Box display={{ d: 'none', xs: 'block' }}>
        <BuyerOffersMobile />
      </Box>
    </>
  );
};
