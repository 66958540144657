import { SettlementStepName } from '../entities/settlement/types';
import { getDefinitions, interpolateVariable, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class SettlementLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ContractDate(): string {
    return this.definitions.ContractDate;
  }

  public get Copied(): string {
    return this.definitions.Copied;
  }

  public get EstimatedDelivery(): string {
    return this.definitions.EstimatedDelivery;
  }

  public get FullShipmentData(): string {
    return this.definitions.FullShipmentData;
  }

  public get HeadshotOfLogistics(): string {
    return this.definitions.HeadshotOfLogistics;
  }

  public get Messages(): string {
    return this.definitions.Messages;
  }

  public get NoDocuments(): string {
    return this.definitions.NoDocuments;
  }

  public get NoListingsToDisplay(): string {
    return this.definitions.NoListingsToDisplay;
  }

  public get NoMessages(): string {
    return this.definitions.NoMessages;
  }

  public get Order(): string {
    return this.definitions.Order;
  }

  public get OrderDetails(): string {
    return this.definitions.OrderDetails;
  }

  public get OrderRef(): string {
    return this.definitions.OrderRef;
  }

  public get Orders(): string {
    return this.definitions.Orders;
  }

  public get PlacedOn(): string {
    return this.definitions.PlacedOn;
  }

  public get Share(): string {
    return this.definitions.Share;
  }

  public get Shipping(): string {
    return this.definitions.Shipping;
  }

  public get ViewDetails(): string {
    return this.definitions.ViewDetails;
  }

  public get ViewManageTrackOrder(): string {
    return this.definitions.ViewManageTrackOrder;
  }

  public get YourOrder(): string {
    return this.definitions.YourOrder;
  }

  public get YourSpecialist(): string {
    return this.definitions.YourSpecialist;
  }

  public get AccountExecutive(): string {
    return this.definitions.AccountExecutive;
  }
  public get ItemDetails(): string {
    return this.definitions.ItemDetails;
  }

  public get ShipTo(): string {
    return this.definitions.ShipTo;
  }

  public get YourSpecialists(): string {
    return this.definitions.YourSpecialists;
  }

  public get Checkmark(): string {
    return this.definitions.Checkmark;
  }

  public get ReceiptIcon(): string {
    return this.definitions.Status;
  }

  public get LuggageCartIcon(): string {
    return this.definitions.LuggageCartIcon;
  }

  public get AirplaneTakingOffIcon(): string {
    return this.definitions.AirplaneTakingOffIcon;
  }

  public get DeliveryTruckIcon(): string {
    return this.definitions.DeliveryTruckIcon;
  }

  public get Status(): string {
    return this.definitions.Status;
  }

  public get Temperature(): string {
    return this.definitions.Temperature;
  }

  public get Humidity(): string {
    return this.definitions.Humidity;
  }

  public get Light(): string {
    return this.definitions.Light;
  }

  public get Acceleration(): string {
    return this.definitions.Acceleration;
  }

  public get OrderInfo(): string {
    return this.definitions.OrderInfo;
  }

  public get Sensors(): string {
    return this.definitions.Sensors;
  }

  public UpdateXDaysAgo(xDays: string | number): string {
    return interpolateVariable(this.definitions.UpdateXDaysAgo, 'daysAgo', xDays);
  }

  public SettlementChipStatus(step: string): string {
    switch (step) {
      case SettlementStepName.CONTRACT:
        return this.definitions.Contract;
      case SettlementStepName.PICKED_UP:
        return this.definitions.PickedUp;
      case SettlementStepName.IN_TRANSIT:
        return this.definitions.InTransit;
      case SettlementStepName.DELIVERED:
        return this.definitions.Delivered;
      default:
        return this.definitions.Contract;
    }
  }
}
