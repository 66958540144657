import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { SearchField } from '../searchField';
import { DropDownOption } from '../dropdowns/types';
import { useLocalizer } from '../../../../localization';
import { fetchModel, searchModels } from '../../../../providers/models';
import { concatStrings, isEmpty } from '../../../../utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Model } from '@/src/entities/model';
// -- Sentry
const scope = {
  FormikField: 'ModelSearchDropdown',
  file: 'src/bits/forms/fields/modelSearchDropdown/index.tsx'
};

interface ModelSearchDropdownProps {
  value: number;
  onChange: (value: number, model?: Model) => void;
  onBlur: () => void;
  required?: boolean;
  disabled?: boolean;
  hasErrored?: boolean;
  errorMessage?: string;
  size?: 'small' | 'medium';
  helperText?: string;
  submitted?: boolean;
  touched?: boolean;
  validateOnTouchOrSubmit?: boolean;
}

interface ModelSearchDropdownOptions extends DropDownOption {
  model?: Model;
}

export const ModelSearchDropdown: React.FC<ModelSearchDropdownProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    required,
    disabled,
    hasErrored,
    errorMessage,
    size,
    helperText,
    submitted,
    touched,
    validateOnTouchOrSubmit
  } = props;
  const loc = useLocalizer();
  const [options, setOptions] = useState<ModelSearchDropdownOptions[]>([]);
  const [selectedOption, setSelectedOption] = useState<ModelSearchDropdownOptions | null>(null);

  const debouncedFetchModels = useDebounce((searchTerm: string) => {
    if (isEmpty(searchTerm)) return setOptions([]);

    searchModels({ q: searchTerm, limit: 10 })
      .then((models) => {
        setOptions(
          models.map((model) => ({
            id: model.id.toString(),
            label: concatStrings([model.make.name, model.name], ' '),
            model: model
          }))
        );
      })
      .catch((error) => {
        captureException(error, {
          tags: {
            ...scope
          },
          extra: {
            searchTerm: searchTerm
          }
        });
      });
  }, 300);

  useEffect(() => {
    // Find the default model on initial render and set as default selected option
    if (value > 0) {
      fetchModel(value)
        .then((model) => {
          setSelectedOption({ id: model.id.toString(), label: concatStrings([model.make.name, model.name], ' '), model });
        })
        .catch((error) => {
          captureException(error, {
            tags: {
              ...scope
            }
          });
        });
    }
    return () => debouncedFetchModels.clear();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFetchModels]);

  const handleChange = (option: DropDownOption | null) => {
    setSelectedOption(option ? { ...option, model: options.find((opt) => opt.id === option?.id)?.model } : option);
    // 0 is equivalent to an empty value for model_id
    onChange(option?.id ? parseInt(option.id) : 0, options.find((opt) => opt.id === option?.id)?.model);
  };

  const handleSearchChange = (value: string) => {
    debouncedFetchModels(value);
  };

  return (
    <SearchField
      value={selectedOption}
      options={options}
      label={loc.Listing.ManufacturerAndModel}
      placeholder={loc.Forms.SearchModelsByKeyword}
      noOptionsText={loc.Forms.TypeSearchModels}
      disabled={disabled}
      helperText={helperText}
      required={required}
      hasErrored={hasErrored}
      errorMessage={errorMessage}
      size={size}
      submitted={submitted}
      touched={touched}
      validateOnTouchOrSubmit={validateOnTouchOrSubmit}
      onChange={handleChange}
      onBlur={onBlur}
      onInputChange={handleSearchChange}
    />
  );
};
