import {
  getDefinitions,
  interpolateVariable,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class FooterLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public Copyright(year: number): string {
    return interpolateVariable(this.definitions.Copyright, 'year', year);
  }

  public get Explore(): string {
    return this.definitions.Explore;
  }

  public get FollowUs(): string {
    return this.definitions.FollowUs;
  }

  public get International(): string {
    return this.definitions.International;
  }

  public get MoovMatches(): string {
    return this.definitions.MoovMatches;
  }

  public get WhiteSemiMemberLogo(): string {
    return this.definitions.WhiteSemiMemberLogo;
  }
}
