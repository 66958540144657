import { useEffect } from 'react';
import { initializeFullstory, fullstoryEvent } from '@/src/state/tracking/fullstory/helpers';
import { CustomEvent } from '@/src/state/tracking/fullstory/customEvents';
import { useGTM } from '../useGTM';

export const useFullStory = () => {
  useEffect(() => {
    initializeFullstory();
  }, []);
  const { trackGTMEvent } = useGTM();
  return {
    fullStoryEvent: fullstoryEvent,
    fullStoryAndGTMEvent: (...args: CustomEvent) => {
      fullstoryEvent(...args);
      const [name] = args;
      trackGTMEvent({ event: `moov-${name}` });
    }
  };
};
