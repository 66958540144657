import type { Locale } from '../../../localization/types';
import { getDefinitions, interpolateVariable } from '../../../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class TestimonyLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public Title(title: string): string {
    const t: string = title.replace(/[\s-.]+/, '').toLowerCase();
    switch (t) {
      case 'ceo':
        return this.definitions.CEO;
      case 'founder':
        return this.definitions.Founder;
      case 'owner':
        return this.definitions.Owner;
      case 'president':
        return this.definitions.President;
      default:
        return this.definitions.President;
    }
  }

  public TestimonyOf(name: string): string {
    const n: string = name.replace(/[\s-.]+/, '').toLowerCase();
    switch (n) {
      case 'louisfan':
        return this.definitions.TestimonyOfLouisFan;
      case 'earlweltmer':
        return this.definitions.TestimonyOfEarlWeltmer;
      case 'sheltonqiao':
        return this.definitions.TestimonyOfSheltonQiao;
      case 'dannylee':
        return this.definitions.TestimonyOfDannyLee;
      default:
        return '';
    }
  }

  public get TopManufacturersChooseMoov(): string {
    return this.definitions.TopManufacturersChooseMoov;
  }

  public HeadshotOf(name: string): string {
    return interpolateVariable(this.definitions.HeadshotOf, 'name', name);
  }
}
