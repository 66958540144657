import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import ProductFruits from '../integrations/productFruits';
import { Layout } from '../layout';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import '../styles/output.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { GlobalState } from '../state';
import GlobalComponents from '../globalComponents';
import store from '../store';
import { FFClientReady } from '../globalComponents/ffClientReady';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { theme } from '../design/muiTheme';
import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { config } from '@fortawesome/fontawesome-svg-core';

// This prevents the Font Awesome core SVG library from inserting <style> tags
// into the <head> of the page. Next.js already blocks this from happening so
// best to not even try.
config.autoAddCss = false;

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY ?? '');

const MoovApp = ({ pageProps, Component }: AppProps) => {
  return (
    <LDProvider clientSideID={process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID || ''}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <GlobalState>
              <FFClientReady>
                <GlobalComponents>
                  <ProductFruits />
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </GlobalComponents>
              </FFClientReady>
            </GlobalState>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </LDProvider>
  );
};

export default MoovApp;
