import { Listing } from '../../entities/listing';
import { touchPoint } from '../touchPoints';
import { TouchPointType } from '../touchPoints/constants';
import { DTOQuestion } from './dtos';
import * as questionRequests from './repo';

export const createQuestionsByOpportunityKey = async (opportunityKey: string, questions: string | string[], listing: Listing) => {
  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_SUBMITTED_QUESTIONS,
      task: `Submitted questions for FID: ${listing.id}`,
      details: JSON.stringify({ questions: questions })
    }
  });
  await questionRequests.createQuestionsByOpportunityKey(opportunityKey, { questions: [questions].flat(2) });
};

export const createQuestionsByListingAndUserId = async (listing: Listing, userId: number, questions: string | string[]) => {
  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_SUBMITTED_QUESTIONS,
      task: `User ID: ${userId} submitted questions for FID: ${listing.id})`,
      details: JSON.stringify({ questions: questions })
    }
  });
  await questionRequests.createQuestionsByListingKey(listing.key, { questions: [questions].flat(2) });
};

export const answerQuestion = async (questionId: number, answer: string, question: string, userId: number | undefined) => {
  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_LISTING_QUESTION_ANSWERED,
      task: `User ID: ${userId} submitted an answer for a listing question. QuestionId: ${questionId})`,
      details: JSON.stringify({ answer, question })
    }
  });
  return questionRequests.answerQuestion(questionId, answer);
};

export const fetchUnansweredQuestionsByListing = async (listingKey: string): Promise<DTOQuestion[]> => {
  const unansweredQuestions = await questionRequests.getUnansweredQuestionsByListing(listingKey);

  return unansweredQuestions;
};
