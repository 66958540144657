import { GridSortDirection } from '@mui/x-data-grid';

export class FeatureSettings {
  tableColumns: ColumnSettings[] = [];
  tableSort: SortSettings = new SortSettings({});

  constructor(arg: Partial<FeatureSettings>) {
    Object.assign(this, arg);
  }
}

export class ColumnSettings {
  name: string = '';
  visibility: boolean = false;
  width: number = 0;

  constructor(arg: Partial<ColumnSettings>) {
    Object.assign(this, arg);
  }
}

export class SortSettings {
  direction: GridSortDirection = 'desc';
  name: string = 'created_at';

  constructor(arg: Partial<SortSettings>) {
    Object.assign(this, arg);
  }
}
