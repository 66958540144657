import { FC } from 'react';
import { ReadableOfferStatus, SellerOffer, SellerOfferGroup } from '../../types';
import { getExpandedOfferId } from '../../selectors';
import { Box, Card, CardContent, Chip, Collapse, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useAppSelector } from '@/src/store';
import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { OfferStatusChip } from '../../statusChip';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { parseUSDCurrencyStr } from '@/src/utils';
import dayjs from 'dayjs';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { formatStatusTimeDifference } from '../../utils';
import { SellerOfferDeclinedCTAs, SellerOfferPendingCTAs, SellerOwnedPendingCounterCTAs } from '../offerCTAs';

const ExpiredContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();
  const logisticCountries = useAppSelector(getLogisticCountries);

  const title = offer.current_user_is_owner ? (
    <>
      <strong>Your</strong>&nbsp;{offer.offerTypeText} of&nbsp;
    </>
  ) : (
    <>
      <strong>{offer.buyer_alias}</strong>&nbsp;{offer.offerTypeText} of&nbsp;
    </>
  );

  return (
    <Card variant="outlined" data-testid={`expanded-offer-${offer.key}`}>
      <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0 } }}>
        <Stack direction="row" spacing={spacing.xl} width="100%" p={spacing['3xl']}>
          <Stack>
            <FAIcon scale={1.5} icon={icons.circleExclamation} color={palette.warning.main} />
          </Stack>
          <Stack direction="row" width="100%">
            <Stack width="100%">
              <Stack direction="row" width="100%" justifyContent={'space-between'}>
                <Stack width="100%" spacing={spacing['2xl']}>
                  <Stack spacing={spacing.md}>
                    <Stack alignItems="baseline" direction="row" spacing={spacing.xs}>
                      <Typography variant="p14">
                        {title}
                        <strong>{parseUSDCurrencyStr(offer.offer_amount)}</strong>&nbsp;USD has expired
                      </Typography>
                      <Paper elevation={0} sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}` }}>
                        <Stack variant="center" p={spacing.md}>
                          <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                            {formatStatusTimeDifference(offer)}
                          </Typography>
                        </Stack>
                      </Paper>
                    </Stack>
                    <Stack direction="row" spacing={spacing.lg}>
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Date Required By:</strong>
                        </Typography>
                        <Typography variant="p14">
                          {dayjs(offer.required_by).isValid() ? dayjs(offer.required_by).format('MM/DD/YYYY') : 'N/A'}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" />
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Destination Country:</strong>
                        </Typography>
                        <Typography variant="p14">{offer.countryText(logisticCountries)}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <SellerOfferDeclinedCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} />
                </Stack>
                <OfferStatusChip offer={offer} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const AcceptedContent: FC<{ offer: SellerOffer }> = ({ offer }) => {
  const { palette } = useTheme();

  const title = offer.current_user_is_owner ? (
    <>
      <strong>{offer.buyer_alias}</strong>&nbsp;accepted your {offer.offerTypeText} of&nbsp;
    </>
  ) : (
    <>
      You accepted&nbsp;<strong>{offer.buyer_alias}&apos;s</strong>&nbsp;{offer.offerTypeText} of&nbsp;
    </>
  );

  return (
    <Card variant="outlined" data-testid={`expanded-offer-${offer.key}`}>
      <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0 } }}>
        <Stack direction="row" width="100%" p={spacing['3xl']}>
          <Stack direction="row" width="100%" justifyContent={'space-between'} spacing={spacing.xl}>
            <Stack>
              <FAIcon scale={1.5} icon={icons.circleCheck} color={palette.success.main} />
            </Stack>
            <Stack direction="row" width="100%">
              <Stack alignItems="baseline" direction="row" spacing={spacing.xs}>
                <Typography variant="p14">
                  {title}
                  <Typography variant="p14" color={palette.success.main}>
                    <strong>{parseUSDCurrencyStr(offer.offer_amount)} USD</strong>
                  </Typography>
                  &nbsp;
                </Typography>
                <Paper elevation={0} sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}` }}>
                  <Stack variant="center" p={spacing.md}>
                    <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                      {formatStatusTimeDifference(offer)}
                    </Typography>
                  </Stack>
                </Paper>
              </Stack>
            </Stack>
          </Stack>
          <OfferStatusChip offer={offer} />
        </Stack>
      </CardContent>
    </Card>
  );
};

const DeclinedContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();
  const logisticCountries = useAppSelector(getLogisticCountries);

  const title = offer.current_user_is_owner ? (
    <>
      <strong>{offer.buyer_alias}</strong>&nbsp;declined your {offer.offerTypeText} of&nbsp;
    </>
  ) : (
    <>
      You declined&nbsp;<strong>{offer.buyer_alias}&apos;s</strong>&nbsp;{offer.offerTypeText} of&nbsp;
    </>
  );

  return (
    <Card variant="outlined" data-testid={`expanded-offer-${offer.key}`}>
      <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0 } }}>
        <Stack direction="row" spacing={spacing.xl} width="100%" p={spacing['3xl']}>
          <Stack>
            <FAIcon scale={1.5} icon={icons.circleExclamation} color={palette.error.main} />
          </Stack>
          <Stack direction="row" width="100%">
            <Stack width="100%">
              <Stack direction="row" width="100%" justifyContent={'space-between'}>
                <Stack width="100%" spacing={spacing['2xl']}>
                  <Stack spacing={spacing.lg}>
                    <Stack alignItems="baseline" direction="row" spacing={spacing.xs}>
                      <Typography variant="p14">
                        {title}
                        <strong>{parseUSDCurrencyStr(offer.offer_amount)}</strong>&nbsp;USD
                      </Typography>
                      <Paper elevation={0} sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}` }}>
                        <Stack variant="center" p={spacing.md}>
                          <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                            {formatStatusTimeDifference(offer)}
                          </Typography>
                        </Stack>
                      </Paper>
                    </Stack>
                    <Stack direction="row" spacing={spacing.lg}>
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Date Required By:</strong>
                        </Typography>
                        <Typography variant="p14">
                          {dayjs(offer.required_by).isValid() ? dayjs(offer.required_by).format('MM/DD/YYYY') : 'N/A'}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" />
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Destination Country:</strong>
                        </Typography>
                        <Typography variant="p14">{offer.countryText(logisticCountries)}</Typography>
                      </Stack>
                    </Stack>

                    {offer.declined_reason && (
                      <Paper elevation={0} sx={{ backgroundColor: palette.error.lightest, width: 'fit-content', p: spacing.md }}>
                        <Stack direction="row" alignItems="center" spacing={spacing.sm}>
                          <Box alignSelf="baseline">
                            <FAIcon icon={icons.noteSticky} scale={1} />
                          </Box>
                          <Typography variant="p14">
                            <strong>{offer.noteLabel}</strong>&nbsp;{offer.declined_reason}
                          </Typography>
                        </Stack>
                      </Paper>
                    )}
                  </Stack>

                  <SellerOfferDeclinedCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} />
                </Stack>
                <OfferStatusChip offer={offer} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const PendingContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();
  const logisticCountries = useAppSelector(getLogisticCountries);

  return (
    <Card variant="outlined" data-testid={`expanded-offer-${offer.key}`}>
      <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0 } }}>
        <Stack direction="row" spacing={spacing.xl} width="100%" p={spacing['3xl']}>
          <Stack>
            <FAIcon scale={1.5} icon={icons.circleArrowRight} color={palette.secondary.main} />
          </Stack>
          <Stack direction="row" width="100%">
            <Stack width="100%">
              <Stack direction="row" width="100%" justifyContent={'space-between'}>
                <Stack width="100%" spacing={spacing['2xl']}>
                  <Stack spacing={spacing.lg}>
                    <Stack alignItems="baseline" direction="row" spacing={spacing.xs}>
                      <Typography variant="p14">
                        <strong>{offer.buyer_alias}</strong>&nbsp;submitted an offer of&nbsp;
                        <Typography variant="p14" color={palette.success.main}>
                          <strong>{parseUSDCurrencyStr(offer.offer_amount)} USD</strong>
                        </Typography>
                        &nbsp;
                      </Typography>
                      <Paper elevation={0} sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}` }}>
                        <Stack variant="center" p={spacing.md}>
                          <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                            {formatStatusTimeDifference(offer)}
                          </Typography>
                        </Stack>
                      </Paper>
                    </Stack>
                    <Stack direction="row" spacing={spacing.lg}>
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Date Required By:</strong>
                        </Typography>
                        <Typography variant="p14">
                          {dayjs(offer.required_by).isValid() ? dayjs(offer.required_by).format('MM/DD/YYYY') : 'N/A'}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" />
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Destination Country:</strong>
                        </Typography>
                        <Typography variant="p14">{offer.countryText(logisticCountries)}</Typography>
                      </Stack>
                      <Divider orientation="vertical" />
                      <Stack spacing={spacing.xs} direction="row">
                        <Typography variant="p14">
                          <strong>Offer Expiration:</strong>
                        </Typography>
                        <Typography variant="p14" color={colors.magenta['500']}>
                          <strong>{offer.expireDaysText}</strong>
                        </Typography>
                      </Stack>
                    </Stack>

                    {offer.offer_note && (
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: colors.yellow['50'],
                          border: `1px solid ${colors.yellow['100']}`,
                          width: 'fit-content',
                          p: spacing.md
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={spacing.sm}>
                          <Box alignSelf="baseline">
                            <FAIcon icon={icons.noteSticky} scale={1} />
                          </Box>
                          <Typography variant="p14">
                            <strong>{offer.noteLabel}</strong>&nbsp;{offer.offer_note}
                          </Typography>
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={spacing.md}>
                    <SellerOfferPendingCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={spacing.md}>
                  {offer.key === sellerOfferGroup.highest_offer?.key && (
                    <Chip size="small" color="info" variant={'outlined'} label={'Highest Offer'} />
                  )}
                  <OfferStatusChip offer={offer} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const PendingSellerCounterContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();

  return (
    <Card variant="outlined" data-testid={`expanded-offer-${offer.key}`}>
      <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0 } }}>
        <Stack direction="row" spacing={spacing.xl} width="100%" p={spacing['3xl']}>
          <Stack>
            <FAIcon scale={1.5} icon={icons.circleArrowRight} color={palette.secondary.main} />
          </Stack>
          <Stack direction="row" width="100%">
            <Stack width="100%">
              <Stack direction="row" width="100%" justifyContent={'space-between'}>
                <Stack width="100%" spacing={spacing['2xl']}>
                  <Stack spacing={spacing.lg}>
                    <Stack alignItems="baseline" direction="row" spacing={spacing.xs}>
                      <Typography variant="p14">
                        <strong>You</strong>&nbsp;made a counter-offer of&nbsp;
                        <Typography variant="p14" color={palette.success.main}>
                          <strong>{parseUSDCurrencyStr(offer.offer_amount)} USD</strong>
                        </Typography>
                        &nbsp;
                      </Typography>
                      <Paper elevation={0} sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}` }}>
                        <Stack variant="center" p={spacing.md}>
                          <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                            {formatStatusTimeDifference(offer)}
                          </Typography>
                        </Stack>
                      </Paper>
                      &nbsp;
                      <Typography variant="p14">
                        Buyer has&nbsp;<strong>{offer.expireDaysText}</strong>&nbsp;to respond
                      </Typography>
                    </Stack>

                    {offer.offer_note && (
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: colors.yellow['50'],
                          border: `1px solid ${colors.yellow['100']}`,
                          width: 'fit-content',
                          p: spacing.md
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={spacing.sm}>
                          <Box alignSelf="baseline">
                            <FAIcon icon={icons.noteSticky} scale={1} />
                          </Box>
                          <Typography variant="p14">
                            <strong>{offer.noteLabel}</strong>&nbsp;{offer.offer_note}
                          </Typography>
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={spacing.md}>
                    <SellerOwnedPendingCounterCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ExpandedSellerOfferView: FC<{ sellerOfferGroup: SellerOfferGroup }> = ({ sellerOfferGroup }) => {
  const expandedSellerOfferId = useAppSelector(getExpandedOfferId);
  const { all_offers } = sellerOfferGroup;

  return (
    <Collapse in={sellerOfferGroup.listing.key === expandedSellerOfferId}>
      <Stack mt={spacing.xl} spacing={spacing.xl}>
        {all_offers.map((of) => {
          if (of.isOwnedPendingCounter) {
            return (
              <PendingSellerCounterContent key={`seller-offer-expanded-pending-${of.key}`} offer={of} sellerOfferGroup={sellerOfferGroup} />
            );
          }
          switch (of.readable_status) {
            case ReadableOfferStatus.EXPIRED:
              return <ExpiredContent key={`seller-offer-expanded-expired-${of.key}`} offer={of} sellerOfferGroup={sellerOfferGroup} />;
            case ReadableOfferStatus.ACCEPTED:
              return <AcceptedContent key={`seller-offer-expanded-accepted-${of.key}`} offer={of} />;
            case ReadableOfferStatus.DECLINED:
              return <DeclinedContent key={`seller-offer-expanded-declined-${of.key}`} offer={of} sellerOfferGroup={sellerOfferGroup} />;
            case ReadableOfferStatus.PENDING:
              return <PendingContent key={`seller-offer-expanded-pending-${of.key}`} offer={of} sellerOfferGroup={sellerOfferGroup} />;
            default:
              return null;
          }
        })}
      </Stack>
    </Collapse>
  );
};
