import { useState, useEffect } from 'react';

/**
 * useInterval will automatically clear the interval function when the component
 * that houses this hook is unmounted thereby preventing memory leaks.
 */
export const useInterval = () => {
  const [intervalId, setIntervalId] = useState<number | null>(null);

  useEffect(() => {
    if (intervalId !== null) {
      return () => clearInterval(intervalId);
    }
  }, [intervalId]);

  const clearIntvl = () => {
    if (intervalId !== null) clearInterval(intervalId);
  };

  const setIntvl = (handler: TimerHandler, delay?: number) => {
    clearIntvl();
    setIntervalId(setInterval(handler, delay));
  };

  return {
    setIntvl,
    clearIntvl
  };
};
