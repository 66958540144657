export enum OfferCTATypes {
  MessageSeller = 'Message Seller',
  UpdateOffer = 'Update Offer',
  CounterOffer = 'Counter Offer',
  ViewListing = 'View Listing',
  ViewSimilar = 'View Similar',
  OpenAcceptHighestOffer = 'Open Accept Highest Offer',
  ConfirmAcceptHighestOffer = 'Confirm Accept Highest Offer',
  OpenAcceptOffer = 'Open Accept Offer',
  ConfirmAcceptOffer = 'Confirm Accept Offer',
  OpenDeclineOffer = 'Open Decline Offer',
  ConfirmDeclineOffer = 'Confirm Decline Offer',
  ConfirmCounterOffer = 'Confirm Counter Offer',
  MessageBuyer = 'Message Buyer'
}
export const typedOffersEvent = {
  offerSubmitDeclined: 'offer-submit-declined',
  offerSubmitAccepted: 'offer-submit-accepted',
  offerCTAClick: 'offer-cta-clicked'
} as const;

type OfferCTAClickTypes = [typeof typedOffersEvent.offerCTAClick, { type: OfferCTATypes; listingKey: string; offerKey: string }];
type OfferDeclined = [typeof typedOffersEvent.offerSubmitDeclined, { listingKey: string; offerAmount: number }];
type OfferSubmitAccepted = [typeof typedOffersEvent.offerSubmitAccepted, { listingKey: string; offerAmount: number }];

export type OfferTypes = OfferDeclined | OfferSubmitAccepted | OfferCTAClickTypes;
