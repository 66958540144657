import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryResponse } from './types';

export interface LogisticsState {
  logisticCountries: CountryResponse[];
}

export const initialState: LogisticsState = {
  logisticCountries: []
};

export const slice = createSlice({
  name: 'logistics',
  initialState,
  reducers: {
    loadCountries: (state, action: PayloadAction<CountryResponse[]>) => {
      state.logisticCountries = action.payload;
    }
  }
});

export const { loadCountries } = slice.actions;

export default slice.reducer;
