import debounce from 'debounce';
import { useEffect, useState } from 'react';

type DebouncedFn = {
  clear(): void;
  flush(): void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function useDebounce<TCallback extends Function>(cb: TCallback, wait: number): DebouncedFn & TCallback {
  const [debouncedFn] = useState<TCallback & DebouncedFn>(() => debounce(cb, wait));

  useEffect(() => {
    return () => {
      debouncedFn.clear();
    };
  }, []);

  return debouncedFn;
}
