import translations from './index.loc.json';
import { getDefinitions, Locale } from '../../localization';

/**
 *
 */
export class AllMakeMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get TopProducts(): string {
    return this.definitions.TopProducts;
  }

  public get AllProducts(): string {
    return this.definitions.AllProducts;
  }
}
