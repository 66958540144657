import { FC, SyntheticEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import { FieldLabel } from '../../fieldLabel';
import { HelperTextForInput } from '../../../helperText/helperTextForInput';
import { StyledTextInput } from '../../styledTextInput';
import { StyledAutocomplete } from '../../styledAutocomplete';
import { DropDownOption } from '../types';
import { useDropDownBase } from './hooks';

export interface SharedDropdownProps {
  options: DropDownOption[];
  placeholder?: string;
  label?: string;
  hasErrored?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  defaultOpen?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  containerClassName?: string;
  size?: 'small' | 'medium';
  onClose?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  InputProps?: Parameters<typeof StyledTextInput>[0]['InputProps'];
  renderOption?: Parameters<typeof StyledAutocomplete>[0]['renderOption'];
}

export interface DropdownBaseProps<TValue = unknown> extends SharedDropdownProps {
  onChange: (_: SyntheticEvent<Element, Event>, value: TValue) => void;
  multiple?: boolean;
}

export interface DropdownBaseWithSingleValueProps extends DropdownBaseProps {
  value?: DropDownOption | null;
}

export interface DropdownBaseWithMultiValueProps extends DropdownBaseProps {
  value?: DropDownOption[];
}

/**
 * TODO: #185425534 Styling of dropdown options and icons from ListingStatusSelect
 * should be moved here to affect all dropdowns. DropdownOption should be adjusted
 * to accept an iconComponent and iconColorVariant prop.
 */
export const DropDownBase: FC<DropdownBaseWithSingleValueProps | DropdownBaseWithMultiValueProps> = function ({
  options,
  value,
  placeholder,
  label,
  hasErrored,
  errorMessage,
  disabled,
  required,
  multiple,
  defaultOpen = false,
  disableClearable,
  disableCloseOnSelect,
  limitTags = -1,
  containerClassName,
  size = 'small',
  onChange,
  onClose,
  onKeyPress,
  InputProps,
  renderOption
}: DropdownBaseWithSingleValueProps | DropdownBaseWithMultiValueProps) {
  const { inputValue, placeholderValue, handleClose, isOptionEqualToValue, open, onOpen, disableOnSelect } = useDropDownBase(
    value,
    defaultOpen,
    onClose,
    disableCloseOnSelect,
    multiple,
    placeholder
  );

  return (
    <div className={containerClassName}>
      <FieldLabel label={label} required={required} disabled={disabled} hasErrored={hasErrored}>
        <StyledAutocomplete
          value={inputValue}
          options={options}
          disabled={disabled}
          open={open}
          disableClearable={disableClearable}
          disableCloseOnSelect={disableOnSelect}
          limitTags={limitTags}
          multiple={multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          popupIcon={<ExpandMoreIcon className="text-moov-blue-500" />}
          clearIcon={<CancelIcon className="text-smokey-gray-400" fontSize="small" />}
          onChange={onChange}
          onClose={handleClose}
          onOpen={onOpen}
          renderOption={renderOption}
          renderInput={(params) => {
            return (
              <StyledTextInput
                {...params}
                variant="outlined"
                placeholder={placeholderValue}
                disabled={disabled}
                error={hasErrored}
                size={size}
                autoFocus={open}
                helperText={<HelperTextForInput hasErrored={hasErrored} errorMessage={errorMessage} disabled={disabled} />}
                onKeyPress={onKeyPress}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps
                }}
              />
            );
          }}
        />
      </FieldLabel>
    </div>
  );
};
