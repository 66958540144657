import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListingSerializable } from '@/src/listings/types';

export interface SearchState {
  totalMatchingResults: number;
  listings: ListingSerializable[];
  loading: boolean;
  success: boolean;
  error: boolean;
}

export const initialState: SearchState = {
  totalMatchingResults: 0,
  listings: [],
  loading: false,
  success: false,
  error: false
};

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<{ listings: ListingSerializable[]; total: number }>) => {
      state.listings = action.payload.listings;
      state.totalMatchingResults = action.payload.total;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    loadMore: (state, action: PayloadAction<{ listings: ListingSerializable[] }>) => {
      state.listings = state.listings.concat(action.payload.listings);
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    fetch: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    reset: () => initialState,
    error: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    }
  }
});

export const { fetch, load, loadMore, reset, error } = slice.actions;

export default slice.reducer;
