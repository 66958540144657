import { GlobalModalTypes } from '../../state/globalModal/slice';
import { AnswerListingQuestions } from './modals/answerListingQuestions';
import { AddEquipmentModal } from './modals/addEquipment';
import { GlobalConfirmationModal } from './modals/confirmation';
import { RequestPriceModal } from './modals/requestPrice';
import { ContactSalesModal } from './modals/contactSales';
import { RequestDetailsModal } from './modals/listing/general';
import { MakeOfferModal } from './modals/listing/makeOffer';
import { FindOneModal } from '@/src/globalComponents/globalModal/modals/findOne';
import { CreateAccountModal } from './modals/createAccount';
import { LoginModal } from './modals/login';
import { ExistingAccountResetPassword } from './modals/existingAccountResetPassword';
import { MessageSeller } from './modals/messageSeller';
import { DeclineOfferModal } from './modals/declineOffer';
import { MessageBuyerFromOffer } from './modals/messageBuyerFromOffer';
import { SellerCounterOfferModal } from './modals/sellerCounterOffer';

export const findModal = ({ modalType }: { modalType: GlobalModalTypes }) => {
  switch (modalType) {
    case GlobalModalTypes.ANSWER_LISTING_QUESTIONS:
      return AnswerListingQuestions;
    case GlobalModalTypes.CONFIRMATION:
      return GlobalConfirmationModal;
    case GlobalModalTypes.REQUEST_PRICE:
      return RequestPriceModal;
    case GlobalModalTypes.EMS_ADD_EQUIPMENT:
      return AddEquipmentModal;
    case GlobalModalTypes.FIND_ONE:
      return FindOneModal;
    case GlobalModalTypes.MAKE_OFFER:
      return MakeOfferModal;
    case GlobalModalTypes.CONTACT_SALES:
      return ContactSalesModal;
    case GlobalModalTypes.REQUEST_DETAILS:
      return RequestDetailsModal;
    case GlobalModalTypes.CREATE_ACCOUNT:
      return CreateAccountModal;
    case GlobalModalTypes.LOGIN:
      return LoginModal;
    case GlobalModalTypes.EXISTING_ACCOUNT_RESET_PASSWORD:
      return ExistingAccountResetPassword;
    case GlobalModalTypes.MESSAGE_SELLER:
      return MessageSeller;
    case GlobalModalTypes.MESSAGE_BUYER_FROM_OFFER:
      return MessageBuyerFromOffer;
    case GlobalModalTypes.DECLINE_OFFER:
      return DeclineOfferModal;
    case GlobalModalTypes.SELLER_COUNTER_OFFER:
      return SellerCounterOfferModal;
    case GlobalModalTypes.EMPTY:
    default:
      // eslint-disable-next-line react/display-name
      return () => <div data-testid="Empty Modal"></div>;
  }
};
