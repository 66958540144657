import type { FC } from 'react';
import { InputField, SharedInputFieldProps } from '../inputField';
import { handleKeypress, handlePaste } from './utils';

/**
 * @deprecated Use inputs from src/design/bytes or directly from MUI
 */
export const NumberInputField: FC<SharedInputFieldProps> = (props) => {
  const { value, onKeyPress, onPaste } = props;

  return (
    <InputField
      {...props}
      value={value}
      type="number"
      onKeyPress={(event) => handleKeypress(event, onKeyPress)}
      onPaste={(event) => handlePaste(event, onPaste)}
    />
  );
};
