import { useEffect, useRef, useState } from 'react';
import { RequestPriceFormState } from './requestPriceForm';
import { EquipmentFileDropzoneFileType } from 'src/equipment/ui/equipmentFileDropzone';
import { useAppSelector } from '@/src/store';
import { getModalState } from '@/src/state/globalModal/selectors';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useLocalizer } from '@/src/localization';
const useGetInitialFormState = () => {
  const {
    modalProps: { requestPrice }
  } = useAppSelector(getModalState);

  const state: RequestPriceFormState = {
    wafer_size_values: [],
    condition: 0,
    vintage: null,
    serial_number: ''
  };

  if (requestPrice?.equipment) {
    state.wafer_size_values = requestPrice.equipment.wafer_size_values;
    state.condition = requestPrice.equipment.condition;
    state.vintage = requestPrice.equipment.vintage;
    state.serial_number = requestPrice.equipment.serial_number;
  }

  return state;
};

export const useRequestPriceFormStates = () => {
  const [formState, setFormState] = useState<RequestPriceFormState>(useGetInitialFormState());
  const [files, setFiles] = useState<EquipmentFileDropzoneFileType[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<EquipmentFileDropzoneFileType[]>([]);

  return {
    formState,
    setFormState,
    files,
    setFiles,
    filesToDelete,
    setFilesToDelete
  };
};

export const useSuccessMessageListener = () => {
  const loc = useLocalizer();
  const ref = useRef(false);
  const { openSuccessMessage } = useGlobalAlertBanner();

  useEffect(() => {
    return () => {
      //Using a ref here mitigates closure issues with useState
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (ref.current) {
        openSuccessMessage(loc.EquipmentManage.PriceRequested);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};
