import { FC } from 'react';

import { SubProps } from '..';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';

export const RawManufacturer: FC<SubProps> = ({ state, dispatch, loc }) => {
  if (!state.modelNotListed) {
    return null;
  }

  return (
    <LabeledTextField
      required
      placeholder={loc.Common.Manufacturer}
      label={loc.Common.Manufacturer}
      value={state.textManufacturer || ''}
      onChange={(e) => {
        dispatch({
          type: 'setTextManufacturer',
          value: e.target.value
        });
      }}
      type={'text'}
    />
  );
};
