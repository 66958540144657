/* eslint-disable @next/next/no-img-element */
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { colors, spacing } from '@/src/design/designConstants';
import { Stack, Typography } from '@mui/material';

export const MessagesNullState = () => {
  return (
    <Stack width="100%" p={spacing.xl} spacing={spacing.xl}>
      <Stack width="100%" variant="center" py={spacing.md} px={spacing.sm}>
        <img src={IMAGE_URLS.ORDER_CARD_ILLUSTRATION} style={{ height: '100px' }} alt="order card illustration" />
      </Stack>
      <Stack variant="center" spacing={spacing.md} textAlign={'center'}>
        <Typography color={colors.corpGray['500']} variant="subtitle3">
          No Current Messages
        </Typography>
        <Typography color={colors.corpGray['500']} variant="p14">
          It&apos;s quiet here… Send a message to break the silence.
        </Typography>
      </Stack>
    </Stack>
  );
};
