import { Dispatch, SetStateAction, useState } from 'react';
import { DropdownBaseWithSingleValueProps, DropdownBaseWithMultiValueProps } from '.';
import { isDropdownOption, isDropdownOptionArray } from '../helpers';

type Value = DropdownBaseWithSingleValueProps['value'] | DropdownBaseWithMultiValueProps['value'];

export const useDropDownBase = (
  value: Value,
  defaultOpen: boolean,
  onClose: OnCloseHandler,
  disableCloseOnSelect?: boolean,
  multiple?: boolean,
  placeholder?: string
) => {
  const [open, setOpen] = useState(defaultOpen);
  const handleClose = CloseHandler(onClose, setOpen);
  const inputValue = getInputValue(value, multiple);
  const placeholderValue = getPlaceholder(value, placeholder);

  return {
    open,
    setOpen,

    placeholderValue,
    inputValue,
    handleClose,

    onOpen: onOpen(setOpen),
    isOptionEqualToValue,
    disableOnSelect: disableCloseOnSelect || multiple
  };
};

const getInputValue = (value: Value, multiple?: boolean) => {
  let inputValue = value;

  // Need to do this because Autocomplete throws an error
  // if multiple = true and value is not an array
  if (multiple) {
    inputValue = value ? value : [];
  }

  return inputValue;
};

const getPlaceholder = (value: Value, placeholder?: string) => {
  if ((isDropdownOptionArray(value) && value.length === 0) || !value) {
    return placeholder;
  }
};

export const isOptionEqualToValue = (option1: unknown, option2: unknown) => {
  return isDropdownOption(option1) && isDropdownOption(option2) && option1.id === option2.id;
};

type OnCloseHandler = DropdownBaseWithSingleValueProps['onClose'] | DropdownBaseWithMultiValueProps['onClose'];
const CloseHandler = (onClose: OnCloseHandler, setOpen: Dispatch<SetStateAction<boolean>>) => () => {
  onClose?.();
  setOpen(false);
};

const onOpen = (setOpen: Dispatch<SetStateAction<boolean>>) => () => setOpen(true);
