import { FC, useState, MouseEvent } from 'react';
import { useUser } from '../../hooks/useUser';
import routes from '../../routes';
import { useLocalizer } from '../../localization/hooks';
import { Box, Button, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { InternalLink } from '../../bits/links';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { colors } from '@/src/design/designConstants';

export interface AccountProps {
  onLoginClick: () => void;
  onLogoutSubmit: () => Promise<void>;
}

export const Account: FC<AccountProps> = ({ onLoginClick, onLogoutSubmit }) => {
  const { user, userIsLoggedIn } = useUser();
  const loc = useLocalizer();
  const { fullStoryEvent } = useFullStory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (_: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleLoginClick = () => {
    fullStoryEvent(CustomEvents.login.headerClick);
    onLoginClick();
  };

  const handleLogoutClick = () => {
    setAnchorEl(null);
    onLogoutSubmit();
  };

  return (
    <>
      {!userIsLoggedIn(user) ? (
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Button onClick={handleLoginClick} color="secondary" size={'large'}>
            <Typography variant="button14AllCaps">{loc.Nav.Login}</Typography>
          </Button>
        </Stack>
      ) : (
        <div className="flex h-10 cursor-default items-center gap-x-2 font-montserrat text-14spx">
          <Box sx={{ paddingLeft: 1 }}>
            <IconButton onClick={openMenu}>
              <FAIcon scale={1.5} icon={icons.userSolid} color={colors.moovBlue[500]} />
            </IconButton>
          </Box>

          <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
            <InternalLink href={routes.dashboard()}>
              <MenuItem onClick={closeMenu} className="hover:bg-smokey-gray-100">
                <div className="mx-auto py-1 font-lato text-16spx text-moov-blue-500">{loc.Nav.Dashboard.toUpperCase()}</div>
              </MenuItem>
            </InternalLink>
            <InternalLink href={routes.profile()}>
              <MenuItem onClick={closeMenu} className="hover:bg-smokey-gray-100">
                <div className="mx-auto py-1 font-lato text-16spx text-moov-blue-500">{loc.Nav.EditProfile.toUpperCase()}</div>
              </MenuItem>
            </InternalLink>
            <MenuItem onClick={handleLogoutClick} className="hover:bg-smokey-gray-100">
              <div className=" mx-auto py-1 font-lato text-16spx text-moov-blue-500">{loc.Nav.LogOut.toUpperCase()}</div>
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};
