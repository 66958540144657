import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { EquipmentFileDropzoneFileType, isEquipmentDropzoneFile, isEquipmentPhoto } from './index';

export const hasDroppedFiles = (fileType: string, files: EquipmentFileDropzoneFileType[]) => fileType === 'file' && files.length > 0;
export const hasDroppedPhotos = (fileType: string, files: EquipmentFileDropzoneFileType[]) => fileType === 'image' && files.length > 0;
export const shouldDisplayPhotoCTA = (
  displayPhotoCTA: boolean | undefined,
  fileType: string,
  files: EquipmentFileDropzoneFileType[],
  filesToDelete: EquipmentFileDropzoneFileType[]
) => {
  // Don't show CTA show unless explicitly showing the CTA
  if (!displayPhotoCTA) return false;

  // Don't show CTA if the file type is not an image
  if (fileType !== 'image') return false;

  // Don't show the CTA if we already have files uploaded
  if (files.length !== 0) return false;

  // Don't show the CTA if we've selected files to delete
  if (filesToDelete?.length > 0) return false;

  // Otherwise, show the CTA
  return true;
};

export const getImageSrc = (file: EquipmentFileDropzoneFileType) => {
  if (isEquipmentDropzoneFile(file)) {
    return file.preview;
  } else if (isEquipmentPhoto(file)) {
    return file.small_url;
  }
  return IMAGE_URLS.FILE_ICON;
};
