import { FC } from 'react';
import { isServerSideRendering } from '../../utils/next/isServerSideRendering';
import { useIsClientSideLDClientReady } from '../hooks/useIsClientSideLDClientReady';

/**
 * Does nothing if SSR'ing, and
 * waits to render until we
 * have feature flags during
 * CSR
 */
export const FFClientReady: FC = ({ children }) => {
  const clientIsReady = useIsClientSideLDClientReady();

  if (isServerSideRendering()) return <>{children}</>;

  return clientIsReady ? <>{children}</> : null;
};
