import { DividerProps, Divider as MuiDivider, styled } from '@mui/material';
import { colors } from '../../designConstants';

// Probably a better api that lets you just pass in a prop?
export const LightDivider = styled(MuiDivider)<DividerProps>({
  color: colors.smokeyGray[100]
});

export const DarkDivider = styled(MuiDivider)<DividerProps>({
  color: colors.corpGray[100]
});
