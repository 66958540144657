const plugin = require('tailwindcss/plugin');

const TAILWIND_CSS_CONFIG = {
  mode: 'jit',
  darkMode: 'class',
  content: ['./src/**/*.{ts,tsx}'], // purge any unused css from final bundle
  theme: {
    zIndex: {
      loadingOverlay: 9999,

      cookieConsentPopup: 52,
      slideDownNavbar: 50,
      skipLink: 50,
      50: 50,

      navbar: 40,
      40: 40,

      sidebar: 30,
      galleryControls: 30,
      30: 30,

      20: 20,

      remarketingHeroSection: 10,
      statusChipContainer: 10,
      suggestedListingCardIcon: 10,
      equipmentSearchBar: 10,
      globalModalButtonGroup: 10,
      trendsHeroSection: 10,
      decorativeShapes: 10,
      galleryMainImg: 10,
      galleryNavArrows: 10,
      galleryImageCarousel: 10,
      dropdownMenu: 10,
      carouselNavArrow: 10,
      10: 10
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px'
    },
    colors: {
      blue: {
        100: '#DEE8ED',
        150: '#DAE4E9',
        200: '#DAE0E4',
        250: '#C7D2D7',
        275: '#EAF1F4',
        500: '#5186A0',
        800: '#03334A',
        1000: '#19252B'
      },
      black: '#000',
      blackish: '#202020',
      'corp-gray': {
        50: '#F6F8F9',
        100: '#EDF1F2',
        200: '#D8E0E4',
        300: '#C6D1D7',
        400: '#ABBCC4',
        500: '#8FA5B0',
        600: '#7E98A3',
        800: '#3D4E57'
      },
      'crayola-green': {
        50: '#EBF9F4',
        500: '#1DA370',
        600: '#178259',
        700: '#126444'
      },
      'danger-red': '#DC3545',
      'hot-red': {
        50: '#FFF1F1',
        500: '#FD385D'
      },
      'smokey-gray': {
        50: '#FAFAFA',
        100: '#ECEEEF',
        200: '#E2E4E7',
        300: '#C0C4C7',
        400: '#979B9F',
        500: '#606770',
        600: '#575D66',
        800: '#3B3F45'
      },
      gray: {
        75: '#F4F5F6',
        200: '#E5E5E5',
        250: '#E5E8E9',
        275: '#D8D8D8',
        350: '#C6CED2',
        400: '#9D9D9D',
        600: '#6F6F6F',
        700: '#666666',
        800: '#4F4F4F'
      },
      green: {
        500: '#5FAF86'
      },
      inherit: 'inherit',
      'info-blue': '#1D95D6',
      logo: {
        salmon: '#EB737E',
        orange: '#F28C34',
        gold: '#F4C23A',
        yellow: '#E3D640',
        lime: '#9ECB57',
        emerald: '#5FAF86',
        turquoise: '#6EBDCD',
        teal: '#479DAA',
        'sky-blue': '#23C4F8',
        plum: '#CA64A5'
      },
      magenta: {
        50: '#FDF2F9',
        100: '#FAE5F3',
        200: '#F4C8E5',
        300: '#EEA5D5',
        400: '#E472BE',
        500: '#C72391',
        600: '#B21F81',
        700: '#9B2070',

        // OLD
        '200-old': '#FFE8F7',
        '500-old': '#CA64A5',
        '600-old': '#A05186'
      },
      'moov-black': '#19252B',
      'moov-blue': {
        50: '#F6F9FC',
        100: '#EAF4F8',
        200: '#BCD7E4',
        300: '#89B7CD',
        400: '#4A8FAF',
        500: '#234454',
        600: '#183643',
        800: '#152932'
      },
      // internal colors have not been approved by design and are for internal use only
      internal: {
        'neon-green': '#0FFF50',
        'neon-pink': '#f535AA'
      },
      peach: '#EB737E',
      'sky-blue': {
        50: '#E3F8FF',
        500: '#23C4F8',
        600: '#07A5D9',

        // OLD
        '100-old': '#F5F8FB',
        '200-old': '#ECF2F5',
        '250-old': '#EFF7FF',
        '300-old': '#DEF0FA',
        '700-old': '#BBE0F2',
        '900-old': '#91CEED',
        '1000-old': '#5186A0'
      },
      'success-green': '#5CB85C',
      transparent: 'transparent',
      'warning-yellow': '#FFC107',
      white: '#fff',
      yellow: '#EDCC61'
    },
    fontFamily: {
      montserrat: ['"Montserrat"', 'sans-serif'],
      lato: ['"Lato"', 'sans-serif'],
      inter: ['"Inter"', 'sans-serif'],
      helvetica: ['"Helvetica"', 'sans-serif']
    },
    // spx = scaling pixel value
    fontSize: {
      '9spx': '.5625rem',
      '10spx': '.625rem',
      '11spx': '.6875rem',
      '12spx': '.75rem',
      '13spx': '.8125rem',
      '14spx': '.875rem',
      '15spx': '.9375rem',
      '16spx': '1rem',
      '18spx': '1.125rem',
      '20spx': '1.25rem',
      '21spx': '1.3125rem',
      '24spx': '1.5rem',
      '30spx': '1.875rem',
      '32spx': '2rem',
      '34spx': '2.125rem',
      '36spx': '2.25rem',
      '40spx': '2.5rem',
      '48spx': '3rem',
      '60spx': '3.75rem',
      '64spx': '4rem',
      '72spx': '4.5rem',
      '96spx': '6rem'
    },
    extend: {
      animation: {
        'fade-in-down': 'fadeInDown 1s 1',
        'bounce-in-right-0': 'bounceInRight 2s .1s 1  cubic-bezier(0.215, 0.61, 0.355, 1)  forwards',
        'bounce-in-right-1': 'bounceInRight 2s .2s 1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'bounce-in-right-2': 'bounceInRight 2s .3s 1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'bounce-in-right-3': 'bounceInRight 2s .4s 1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'bounce-in-right-4': 'bounceInRight 2s .5s 1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'bounce-in-right-5': 'bounceInRight 2s .6s  1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'bounce-in-right-6': 'bounceInRight 2s .7s 1 cubic-bezier(0.215, 0.61, 0.355, 1) forwards',
        'hero-blue-rectangle': 'hero-blue-rectangle 24s linear infinite',
        'hero-green-rectangle': 'hero-green-rectangle 24s linear infinite',
        'hero-sky-blue-square': 'hero-sky-blue-square 24s linear infinite',
        'hero-orange-rectangle': 'hero-orange-rectangle 24s linear infinite',
        'hero-pink-square': 'hero-pink-square 24s linear infinite',
        'we-are-growing-pink-square': 'we-are-growing-pink-square 18s linear infinite',
        'we-are-growing-yellow-square': 'we-are-growing-yellow-square 18s linear infinite',
        'we-are-growing-blue-rectangle': 'we-are-growing-blue-rectangle 18s linear infinite',
        'we-are-growing-orange-rectangle': 'we-are-growing-orange-rectangle 18s linear infinite',
        'fade-in-right': 'fadeInRight 1s both',
        'fade-in': 'fadeIn 2s forwards',
        'fade-out': 'fadeOut 2s forwards',
        'slide-in-up': 'slideInUp .7s both',
        'slide-out-down': 'slideOutDown .7s forwards'
      },
      keyframes: {
        slideInUp: {
          '0%': {
            transform: 'translate3d(0,100%,0)',
            visibility: 'visible'
          },
          ' 100%': {
            transform: 'translateZ(0)'
          }
        },
        slideOutDown: {
          '0%': {
            transform: 'translateZ(0)'
          },
          '100%': {
            transform: 'translate3d(0,100%,0)',
            visibility: 'hidden'
          }
        },
        fadeOut: {
          '0%': {
            opacity: 1
          },
          '100%': {
            opacity: 0
          }
        },
        fadeIn: {
          '0%': {
            opacity: 0.08
          },
          '100%': {
            opacity: 1
          }
        },
        fadeInDown: {
          '0%': {
            transform: 'translate3d(0, -100%, 0)',
            opacity: 0
          },
          '100%': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1
          }
        },
        fadeInLeft: {
          '0%': {
            transform: 'translate3d(400%, 0, 0)',
            opacity: 0
          },
          '100%': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1
          }
        },
        fadeInRight: {
          '0%': {
            transform: 'translate3d(100%, 0, 0)',
            opacity: 0
          },
          '100%': {
            transform: 'translateZ(0)',
            opacity: 1
          }
        },
        bounceInRight: {
          '0%': {
            opacity: '0',
            transform: 'translate3d(3000px, 0, 0) scaleX(3)'
          },
          '60%': {
            opacity: '1',
            transform: 'translate3d(-25px, 0, 0) scaleX(1)'
          },
          '75%': {
            transform: 'translate3d(10px, 0, 0) scaleX(0.98)'
          },
          '90%': {
            transform: 'translate3d(-5px, 0, 0) scaleX(0.995)'
          },
          '100%': {
            opacity: '1',
            transform: 'translateZ(0)'
          }
        },
        'hero-blue-rectangle': {
          '0%': {
            top: '17.7%',
            left: '29.8%'
          },
          '25%': {
            top: '45.7%',
            left: '44.8%'
          },
          '50%': {
            top: '60.7%',
            left: '36.8%'
          },
          '75%': {
            top: '29.7%',
            left: '16.8%'
          },
          '100%': {
            top: '17.7%',
            left: '29.8%'
          }
        },
        'hero-green-rectangle': {
          '0%': {
            top: '31%',
            left: '0.6%'
          },
          '25%': {
            top: '22%',
            left: '12.6%'
          },
          '50%': {
            top: '23%',
            left: '43.6%'
          },
          '75%': {
            top: '57%',
            left: '18.6%'
          },
          '100%': {
            top: '31%',
            left: '0.6%'
          }
        },
        'hero-sky-blue-square': {
          '0%': {
            top: '50.4%',
            left: '73.3%'
          },
          '25%': {
            top: '55.4%',
            left: '21.3%'
          },
          '50%': {
            top: '17.4%',
            left: '31.3%'
          },
          '75%': {
            top: '31.4%',
            left: '37.3%'
          },
          '100%': {
            top: '50.4%',
            left: '73.3%'
          }
        },
        'hero-orange-rectangle': {
          '0%': {
            top: '59%',
            left: '41%'
          },
          '25%': {
            top: '60%',
            left: '20%'
          },
          '50%': {
            top: '34%',
            left: '10%'
          },
          '75%': {
            top: '54%',
            left: '47%'
          },
          '100%': {
            top: '59%',
            left: '41%'
          }
        },
        'hero-pink-square': {
          '0%': {
            top: '60%',
            left: '26%'
          },
          '25%': {
            top: '23%',
            left: '36%'
          },
          '50%': {
            top: '59%',
            left: '63%'
          },
          '75%': {
            top: '43%',
            left: '16%'
          },
          '100%': {
            top: '60%',
            left: '26%'
          }
        },
        'we-are-growing-pink-square': {
          '0%': {
            top: '-10%',
            left: '21%'
          },
          '33%': {
            top: '-10%',
            left: '55%'
          },
          '66%': {
            top: '20%',
            left: '72%'
          },
          '100%': {
            top: '-10%',
            left: '21%'
          }
        },
        'we-are-growing-yellow-square': {
          '0%': {
            right: '0%',
            top: '7%'
          },
          '33%': {
            right: '0%',
            top: '45%'
          },
          '66%': {
            right: '72%',
            top: '55%'
          },
          '100%': {
            right: '0%',
            top: '7%'
          }
        },
        'we-are-growing-blue-rectangle': {
          '0%': {
            bottom: '5%',
            left: '0%'
          },
          '33%': {
            bottom: '40%',
            left: '0%'
          },
          '66%': {
            bottom: '0%',
            left: '25%'
          },
          '100%': {
            bottom: '5%',
            left: '0%'
          }
        },
        'we-are-growing-orange-rectangle': {
          '0%': {
            right: '4%',
            bottom: '0%'
          },
          '33%': {
            right: '18%',
            bottom: '0%'
          },
          '66%': {
            right: '30%',
            bottom: '50%'
          },
          '100%': {
            right: '4%',
            bottom: '0%'
          }
        },
        wait: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' }
        }
      },
      backgroundImage: {
        'gradient-rainbow': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-rainbow.png')",
        'gradient-green': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-green.png')",
        'gradient-pink': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-pink.png')",
        'gradient-yellow-pink': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-yellow-pink.png')",
        'gradient-blue-yellow-pink':
          "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-blue-yellow-pink.png')",
        'gradient-blue-yellow-pink-light':
          "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-gradient-blue-yellow-pink-light.png')",
        'moov-logo-rainbow': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/background-moov-logo-rainbow.png')",
        'moov-difference': "url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-difference-bg.png')"
      },
      boxShadow: {
        basic: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
        blur: '0 4px 12px 0 rgb(0, 0, 0, 0.25)',
        'bottom-white-fade': 'inset 0 -2rem 1rem -0.5rem white',
        light: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
        'light-top': '0 -1px 4px 0 rgba(0, 0, 0, 0.25)',
        subtle: '0px 2px 8px rgba(0, 0, 0, 0.07)'
      },
      brightness: {
        115: '1.15',
        97: '0.97'
      },
      maxWidth: {
        'standard-content': '90rem',
        'tight-content': '70rem',
        'lg-modal': '67rem',
        'md-modal': '46rem',
        'sm-modal': '24rem'
      },
      skew: {
        7: '7deg',
        10: '10deg'
      },
      spacing: {
        'page-t-mobile': '1rem',
        'page-b-mobile': '2rem',
        'page-t-desktop': '2.5rem',
        'page-b-desktop': '5rem',
        'content-x-mobile': '1rem',
        'content-x-desktop': '2.5rem',
        102: '25.5rem'
      },
      opacity: {
        15: '0.15'
      }
    },
    letterSpacing: {
      tighter: '-.05em',
      tight: '-.025em',
      normal: '0',
      wide: '.025em',
      wider: '.05em',
      widest: '.1em'
    }
  },
  plugins: [
    plugin(function ({ addVariant }) {
      addVariant('not-first', '&:not(:first-child)');
      addVariant('not-last', '&:not(:last-child)');
      addVariant('not-empty', '&:not(:empty)');
      addVariant('not-disabled', '&:not(:disabled)');
    })
  ],
  corePlugins: {
    preflight: false
  }
};

/**
 * Adds spacing values ranging from 0rem - 25rem (0px - 400px) in intervals of 0.5rem (2px)
 */
const addGranularSpacing = () => {
  let granularSpacing = {};

  for (let i = 0; i <= 100; i += 0.5) {
    granularSpacing[i] = `${i * 0.25}rem`;
  }

  TAILWIND_CSS_CONFIG.theme.extend.spacing = { ...TAILWIND_CSS_CONFIG.theme.extend.spacing, ...granularSpacing };
  TAILWIND_CSS_CONFIG.theme.extend.maxWidth = { ...TAILWIND_CSS_CONFIG.theme.extend.maxWidth, ...granularSpacing };
  TAILWIND_CSS_CONFIG.theme.extend.minWidth = { ...TAILWIND_CSS_CONFIG.theme.extend.minWidth, ...granularSpacing };
  TAILWIND_CSS_CONFIG.theme.extend.maxHeight = { ...TAILWIND_CSS_CONFIG.theme.extend.maxHeight, ...granularSpacing };
  TAILWIND_CSS_CONFIG.theme.extend.minHeight = { ...TAILWIND_CSS_CONFIG.theme.extend.minHeight, ...granularSpacing };
};

/**
 * Adds flex values ranging from 0 - 10 in intervals of 0.5
 */
const addGranularFlex = () => {
  let granularFlex = {};

  for (let i = 0; i <= 20; i += 0.5) {
    granularFlex[i] = `${i} ${i} 0%`;
  }

  TAILWIND_CSS_CONFIG.theme.extend.flex = { ...TAILWIND_CSS_CONFIG.theme.extend.flex, ...granularFlex };
};

addGranularSpacing();
addGranularFlex();

module.exports = TAILWIND_CSS_CONFIG;
