import { ListingTypes, typedListingEvents, untypedListingEvents } from './listing';
import { LogisticsTypes, typedLogisticsEvents, untypedLogisticsEvents } from './logistics';
import { RegisterTypes, typedRegisterEvents, untypedRegisterEvents } from './register';
import { MessageTypes } from './messages';
import { OfferTypes } from './offers';

const genericFormSubmit = 'form-submit';
const searchCTAClick = 'search-cta-click';
const genericFormSubmitTypes = ['ContactSales Generic'] as const;
const searchCTATypes = ['FindOne'] as const;

export const CustomEvents = {
  register: {
    ...typedRegisterEvents,
    ...untypedRegisterEvents
  },
  login: {
    unauthCTAClick: 'login-unauth-cta-click',
    headerClick: 'login-header-click',
    loginFinish: 'login-finish-click'
  },

  search: {
    search: 'search-query',
    searchResultClick: 'search-result-click',
    filter: 'search-filter',
    filterClicked: 'search-filter-clicked',
    CTAClick: searchCTAClick
  },
  listing: {
    ...typedListingEvents,
    ...untypedListingEvents
  },
  logistics: {
    ...typedLogisticsEvents,
    ...untypedLogisticsEvents
  },
  forms: {
    submit: genericFormSubmit
  },
  model: {
    findOneClick: 'model-find-one-click',
    findOneSubmit: 'model-find-one-submit'
  },
  toolRequest: {
    addClick: 'toolRequest-add-click'
  },
  dashboard: {
    inReviewClick: 'dashboard-in-review-click'
  },
  opportunity: {
    viewed: 'opportunity-viewed',
    navigated: 'opportunity-navigated',
    learnMoreClick: 'opportunity-learn-more-click',
    notInterestedClick: 'opportunity-not-interested-click'
  },
  navigation: {
    categories: {
      categoryClick: 'category-navigation-click',
      seeAllCategoryClick: 'category-navigation-see-all-click'
    }
  }
} as const;

type ContactSalesGenericFormSubmit = [typeof genericFormSubmit, { type: (typeof genericFormSubmitTypes)[number] }];
type GenericFormTypes = ContactSalesGenericFormSubmit;

const loginValues = Object.values(CustomEvents.login);
type LoginValuesType = [(typeof loginValues)[number], any] | [(typeof loginValues)[number]];

const searchValues = Object.values(CustomEvents.search);
type SearchValuesType = [(typeof searchValues)[number], any] | [(typeof searchValues)[number]];

type SearchCtaClickType = [typeof searchCTAClick, { type: (typeof searchCTATypes)[number] }];
type SearchCTATypes = SearchCtaClickType;

const toolRequestValues = Object.values(CustomEvents.toolRequest);
type ToolRequestValuesType = [(typeof toolRequestValues)[number], any] | [(typeof toolRequestValues)[number]];

const dashboardValues = Object.values(CustomEvents.dashboard);
type DashboardValuesType = [(typeof dashboardValues)[number], any] | [(typeof dashboardValues)[number]];

const opportunityValues = Object.values(CustomEvents.opportunity);
type OpportunityValuesType = [(typeof opportunityValues)[number], any] | [(typeof opportunityValues)[number]];

const modelValues = Object.values(CustomEvents.model);
type ModelValuesType = [(typeof modelValues)[number], any] | [(typeof modelValues)[number]];

const categoryNavigationValues = Object.values(CustomEvents.navigation.categories);
type CategoryNavigationValuesType = [(typeof categoryNavigationValues)[number], any] | [(typeof categoryNavigationValues)[number]];

export type CustomEvent =
  | RegisterTypes
  | LoginValuesType
  | SearchValuesType
  | ToolRequestValuesType
  | DashboardValuesType
  | ModelValuesType
  | OpportunityValuesType
  | CategoryNavigationValuesType
  | GenericFormTypes
  | ListingTypes
  | LogisticsTypes
  | SearchCTATypes
  | MessageTypes
  | OfferTypes;
