import { useContext } from 'react';
import { useUser } from '../../../hooks/useUser';
import { useAnyOpportunities } from '../../../hooks/opportunities/useAnyOpportunities';
import { MobileSuggestedItemsModalStore } from '../../../state/mobileSuggestedItemsModal/context';
import { findButton, isButtonDisabled } from './utils';

const ButtonGroup = () => {
  const state = useContext(MobileSuggestedItemsModalStore);

  const useOpportunityPayload = useAnyOpportunities();
  const { user } = useUser();

  if (state.props.steps) {
    const step = state.props.steps[state.currentStep];
    const { actionButton, dismissButton } = step;

    // TODO: #184791304 - Once all the new buttons are in, replace ActionButton and DismissButton
    // with MoovButton and use findButtonVariant to determine the desired variant
    const ActionButton = findButton(actionButton);
    const DismissButton = findButton(dismissButton);
    const handleClickPayload = { state, useOpportunityPayload, user };

    return (
      <div
        // DO NOT REMOVE mb-[var(--safari-bottom-drawer-spacing)], see customProperties.css for explanation.
        className="fixed inset-x-0 bottom-0 z-globalModalButtonGroup mb-[var(--safari-bottom-drawer-spacing)] flex h-25 justify-around gap-x-2 border-t-2 border-gray-200 bg-white p-4 text-12spx"
      >
        {!dismissButton ? (
          <ActionButton
            id={actionButton.moovId}
            onClick={() => actionButton.handleClick(handleClickPayload)}
            className="w-full px-3 py-5"
            disabled={isButtonDisabled(actionButton, dismissButton, step)}
            isLoading={actionButton.submitting}
          >
            {actionButton.buttonContent}
          </ActionButton>
        ) : (
          <>
            <DismissButton
              id={dismissButton.moovId}
              disabled={isButtonDisabled(dismissButton, actionButton, step)}
              isLoading={dismissButton.submitting}
              onClick={() => dismissButton.handleClick(handleClickPayload)}
              className="w-1/2 px-3 py-5"
            >
              {dismissButton.buttonContent}
            </DismissButton>
            <ActionButton
              id={actionButton.moovId}
              onClick={() => actionButton.handleClick(handleClickPayload)}
              className="w-1/2 px-3 py-5"
              disabled={isButtonDisabled(actionButton, dismissButton, step)}
              isLoading={actionButton.submitting}
            >
              {actionButton.buttonContent}
            </ActionButton>
          </>
        )}
      </div>
    );
  }

  return null;
};

export default ButtonGroup;
