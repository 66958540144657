import { FC, useState } from 'react';
import * as yup from 'yup';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useLocalizer } from '@/src/localization';
import { Box } from '@mui/material';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { ERROR_MESSAGES } from '@/src/constants';
import { getContactFormSchema, getInquiryReasonValuesSchema } from '@/src/validationSchemas/lead';
import { ContactForm } from '@/src/globalComponents/globalModal/forms/contactForm';
import { defaultContactFormValues, isContactFormSubmitDisabled } from '../../forms/contactForm/helpers';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, closeModal, openModal } from '@/src/state/globalModal/slice';
import { ContactSalesPossibleSteps, ContactSalesSteps, ContactSalesWithUserInfoFormValues } from '../../forms/contactSalesForm/types';
import { ContactSalesForm } from '../../forms/contactSalesForm';
import { defaultContactSalesFormValues, isContactSalesFormSubmitDisabled } from '../../forms/contactSalesForm/helpers';
import { ContactSalesCTAName, createLeadFromContactSales } from '@/src/state/leads/thunks';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { ExistingUserRegisterAccount } from '@/src/state/tracking/fullstory/customEvents/register';

interface AnonContactSalesModalProps {}

export const AnonContactSalesModal: FC<AnonContactSalesModalProps> = () => {
  const loc = useLocalizer();
  const appDispatch = useAppDispatch();
  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState<ContactSalesPossibleSteps>(ContactSalesSteps.CONTACT_INFORMATION);

  const initialValues: ContactSalesWithUserInfoFormValues = {
    ...defaultContactFormValues,
    ...defaultContactSalesFormValues
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).shape({
      inquiry_reason_values: getInquiryReasonValuesSchema(),
      notes: yup.string()
    })
  );
  const { formValues } = formValidationHook;
  const { fullStoryEvent } = useFullStory();

  const stepData: Record<
    ContactSalesSteps,
    {
      title: string;
      actionButtonDisabled: boolean;
      actionButtonContent: string;
      actionButtonSubmit: () => Promise<void>;
    }
  > = {
    [ContactSalesSteps.CONTACT_INFORMATION]: {
      title: 'Contact Information',
      actionButtonDisabled: isContactFormSubmitDisabled(formValidationHook),
      actionButtonContent: loc.Common.Next,
      actionButtonSubmit: async () => setCurrentStep(currentStep + 1)
    },
    [ContactSalesSteps.CONTACT_SALES]: {
      title: 'Contact Sales',
      actionButtonDisabled: isContactSalesFormSubmitDisabled(formValues),
      actionButtonContent: loc.Forms.Submit,
      actionButtonSubmit: async () => {
        if (!(await formValidationHook.validateForm())) {
          openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
          setCurrentStep(ContactSalesSteps.CONTACT_INFORMATION);
          return;
        }
        fullStoryEvent(CustomEvents.forms.submit, { type: 'ContactSales Generic' });
        return appDispatch(
          createLeadFromContactSales(
            {
              ...formValues,
              from_url: window.location.href,
              form_name: ContactSalesCTAName.NAME
            },
            loc
          )
        )
          .then((lead) => {
            if (lead.user_id) {
              const email = formValidationHook.formValues.email;
              fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_PROMPTED, { email });
              appDispatch(
                openModal({
                  modalType: GlobalModalTypes.EXISTING_ACCOUNT_RESET_PASSWORD,
                  modalProps: { existingAccountResetPassword: { email } }
                })
              );
            } else {
              appDispatch(
                openModal({
                  modalType: GlobalModalTypes.CREATE_ACCOUNT,
                  modalProps: {
                    createAccount: {
                      leadInfo: {
                        email: lead.email,
                        key: lead.key,
                        first_name: lead.first_name,
                        last_name: lead.last_name,
                        company: lead.company,
                        phone: lead.phone
                      }
                    }
                  }
                })
              );
            }
          })
          .catch((e) => {
            appDispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      }
    }
  };

  return (
    <WorkflowModal
      modalOpen
      closeModal={() => appDispatch(closeModal())}
      totalSteps={2}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      title={stepData[currentStep].title}
      actionButtonContent={stepData[currentStep].actionButtonContent}
      actionButtonSubmit={stepData[currentStep].actionButtonSubmit}
      actionButtonDisabled={stepData[currentStep].actionButtonDisabled}
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Box minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        {currentStep === ContactSalesSteps.CONTACT_INFORMATION && <ContactForm formValidationHook={formValidationHook} />}
        {currentStep === ContactSalesSteps.CONTACT_SALES && <ContactSalesForm formValidationHook={formValidationHook} />}
      </Box>
    </WorkflowModal>
  );
};
