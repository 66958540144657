import axios from 'axios';
import { ERROR_MESSAGES, MOOV_API_URL } from '../constants';
import { AppDispatch } from '../store';
import { CompanyRemarketingInfo, fromAPI } from './types';
import { load, setLoading, select } from './slice';
import { captureException } from '@sentry/nextjs';
import { openError } from '../state/banner/slice';

export function fetchManyCompanyRemarketingInfo() {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return axios
      .get<{ data: CompanyRemarketingInfo[] }>(`${MOOV_API_URL}/companies/remarketing`)
      .then((resp) => {
        const remarketingData = resp.data.data.map(fromAPI);
        dispatch(load(remarketingData));
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function fetchCompanyRemarketingInfo(remarketing_slug: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return axios
      .get<{ data: CompanyRemarketingInfo }>(`${MOOV_API_URL}/companies/remarketing/${remarketing_slug}`)
      .then((resp) => {
        const remarketingData = fromAPI(resp.data.data);
        dispatch(load([remarketingData]));
        dispatch(select(remarketingData.remarketing_slug));
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
