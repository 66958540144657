import { FC } from 'react';
import { SellerOffer, SellerOfferGroup } from '../types';
import { Button } from '@mui/material';
import { useAppDispatch } from '@/src/store';
import { parseUSDCurrencyStr } from '@/src/utils';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { closeModal, GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { sellerAcceptOffer } from '../thunks';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';

const MessageBuyerButton: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup; fullWidth?: boolean }> = ({
  offer,
  sellerOfferGroup,
  fullWidth = false
}) => {
  const dispatch = useAppDispatch();
  const { fullStoryAndGTMEvent } = useFullStory();
  const width = fullWidth ? '100%' : 'fit-content';
  return (
    <Button
      onClick={() => {
        fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
          type: OfferCTATypes.MessageBuyer,
          offerKey: offer.key,
          listingKey: sellerOfferGroup.listing.key
        });
        dispatch(
          openModal({
            modalType: GlobalModalTypes.MESSAGE_BUYER_FROM_OFFER,
            modalProps: {
              messageBuyerFromOffer: { offerKey: offer.key }
            }
          })
        );
      }}
      variant="outlined"
      color="info"
      sx={{ width }}
    >
      MESSAGE BUYER
    </Button>
  );
};

export const SellerOfferPendingCTAs: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup; fullWidth?: boolean }> = ({
  offer,
  sellerOfferGroup,
  fullWidth = false
}) => {
  const { fullStoryAndGTMEvent } = useFullStory();
  const dispatch = useAppDispatch();
  const width = fullWidth ? '100%' : 'fit-content';
  return (
    <>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenAcceptOffer,
            offerKey: offer.key,
            listingKey: sellerOfferGroup.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.CONFIRMATION,
              modalProps: {
                confirmation: {
                  title: 'Are you sure you want to accept this offer?',
                  description: `You will be accepting an offer of ${parseUSDCurrencyStr(offer.offer_amount)} USD for ${
                    sellerOfferGroup.listing.make_model
                  }.`,
                  confirmText: 'Yes',
                  cancelText: 'No',
                  onConfirm: async () => {
                    fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                      type: OfferCTATypes.ConfirmAcceptOffer,
                      offerKey: offer.key,
                      listingKey: sellerOfferGroup.listing.key
                    });
                    return dispatch(sellerAcceptOffer(offer));
                  },
                  onClose: () => {
                    dispatch(closeModal());
                  },
                  errorHandleConfirm: (err) => {
                    captureException(err);
                    dispatch(openError({ error: ERROR_MESSAGES.REFRESH }));
                  }
                }
              }
            })
          );
        }}
        variant="contained"
        color="primary"
        sx={{ width }}
      >
        ACCEPT OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.OpenDeclineOffer,
            offerKey: offer.key,
            listingKey: sellerOfferGroup.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.DECLINE_OFFER,
              modalProps: { declineOffer: { offerKey: offer.key, listingKey: sellerOfferGroup.listing.key } }
            })
          );
        }}
        variant="contained"
        color="secondary"
        sx={{ width }}
      >
        DECLINE OFFER
      </Button>
      <Button
        onClick={() => {
          fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
            type: OfferCTATypes.CounterOffer,
            offerKey: offer.key,
            listingKey: sellerOfferGroup.listing.key
          });
          dispatch(
            openModal({
              modalType: GlobalModalTypes.SELLER_COUNTER_OFFER,
              modalProps: { sellerCounterOffer: { offerKey: offer.key, listingKey: sellerOfferGroup.listing.key } }
            })
          );
        }}
        variant="outlined"
        color="info"
        sx={{ width }}
      >
        COUNTER OFFER
      </Button>
      <MessageBuyerButton offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth={fullWidth} />
    </>
  );
};

export const SellerOfferDeclinedCTAs: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup; fullWidth?: boolean }> = ({
  offer,
  sellerOfferGroup,
  fullWidth
}) => {
  return <MessageBuyerButton offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth={fullWidth} />;
};

export const SellerOfferExpiredCTAs: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup; fullWidth?: boolean }> = ({
  offer,
  sellerOfferGroup,
  fullWidth
}) => {
  return <MessageBuyerButton offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth={fullWidth} />;
};

export const SellerOwnedPendingCounterCTAs: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup; fullWidth?: boolean }> = ({
  offer,
  sellerOfferGroup,
  fullWidth
}) => {
  return <MessageBuyerButton offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth={fullWidth} />;
};
