import { FC, SyntheticEvent } from 'react';
import { DropDownOption } from '../types';
import { isDropdownOptionArray } from '../helpers';
import { DropDownBase, SharedDropdownProps } from '../dropdownBase';

export interface MultiSelectDropdownProps extends SharedDropdownProps {
  value?: DropDownOption[];
  onChange: (value: DropDownOption[]) => void;
}
/**
 * @deprecated Use inputs from src/design/bytes or directly from MUI
 */
export const MultiSelectDropdown: FC<MultiSelectDropdownProps> = ({ onChange, ...props }) => {
  const handleChange = (_: SyntheticEvent<Element, Event>, value: unknown) => {
    if (isDropdownOptionArray(value)) {
      onChange(value);
    }
  };

  return <DropDownBase {...props} onChange={handleChange} multiple />;
};
