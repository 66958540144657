import { useEffect, useState } from 'react';
import { useUser } from '../../../hooks/useUser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { captureException } from '@sentry/nextjs';

export const useIsClientSideLDClientReady = () => {
  const client = useLDClient();
  const { user, userError } = useUser();
  const [clientIsReady, setClientIsReady] = useState(false);
  useEffect(() => {
    client
      ?.waitForInitialization()
      .then(() => {
        if (user?.id) {
          client.identify({ key: user.id.toString() });
        }
      })
      .catch((error) => {
        captureException(error, {
          extra: {
            context: 'Launch Darkly failed to initialize'
          }
        });
      })
      .finally(() => {
        setClientIsReady(true);
      });
  }, [client, user, user?.id, userError]);

  return clientIsReady;
};
