import { parseWaferSizeValues, stringifyWaferSizeValue } from '../providers/listings/utils';
import {
  Attribute,
  AttributeSerializable,
  dateToShort,
  numberFromKey,
  stringFromKey,
  waferSizeToText as _waferSizeToText,
  conditionToText as _conditionToText,
  Condition,
  conditionToText,
  AddressSerializable
} from '@/src/common/lib';
import { unreachable } from '@/src/utils/typeSafe';
import { ToolStatus2String } from '../utils/strings/toolStatus2String';
import { Address } from '@/src/common/lib';

// create an object with only Dto properties / no functions or getters
export const PriceStatus = {
  Available: 'available',
  Expired: 'expired',
  Init: 'init',
  Pending: 'pending',
  Unavailable: 'unavailable'
} as const;
export type PriceStatus = (typeof PriceStatus)[keyof typeof PriceStatus];

// create an object with only Dto properties / no functions or getters
export const Status = {
  Active: 100,
  Listed: 101,
  Disabled: 200,
  Sold: 201,
  Deleted: 300
} as const;
export type Status = (typeof Status)[keyof typeof Status];

export const ToolStatus = {
  /** Installed / Operational (facilitized) */
  NO_STATUS: 0,
  /** Installed / Running */
  INSTALLED_RUNNING: 11,
  /** Installed - Idle */
  INSTALLED_IDLE: 12,
  /** Installed - Down */
  INSTALLED_DOWN: 13,
  /** Deinstalled */
  DEINSTALLED: 14,
  /** Deinstalled - Uncrated */
  DEINSTALLED_UNCRATED: 15,
  /** Deinstalled - Crated */
  DEINSTALLED_CRATED: 16,
  /** Deinstalled - Palletized */
  DEINSTALLED_PALLETIZED: 17
} as const;
export type ToolStatus = (typeof ToolStatus)[keyof typeof ToolStatus];

export const argIsToolStatus = (arg: unknown): arg is ToolStatus => Object.values(ToolStatus).includes(arg as ToolStatus);

export class Equipment2 {
  //region Properties
  category = new Attribute();
  condition: Condition = Condition.Used;
  configuration = '';
  created_at = '';
  custom_fields: Record<string, string> = {};
  description = '';
  estimated_value = '';
  external_id = '';
  files = [];
  high_value = '';
  id = 0;
  listing_id = 0;
  listing_key = '';
  low_value = '';
  make = new Attribute();
  market_price = '';
  minimum_price = '';
  model = new Attribute();
  model_id = 0;
  notes = '';
  pending_price = 0;
  photo_count = 0;
  photos = [];
  price_expires_at_ts = 0;
  price_updated_at_ts = 0;
  quantity = 0;
  serial_number = '';
  status: Status = Status.Active;
  user_id = 0;
  vintage = 0;
  wafer_size = '';
  wafer_size_values: number[] = [];
  wanted_count: number = 0;
  tool_status: ToolStatus = ToolStatus.INSTALLED_IDLE;
  listing_strength: number = 0;
  address = new Address();
  //endregion

  constructor(args: Partial<Equipment2> = {}) {
    Object.assign(this, args);
  }

  //region Getters

  // return value as string and '' for 0 (api non value)
  get askingPrice(): string {
    let r = `${this.market_price}`;
    if (this.market_price === '0') r = '';
    return r;
  }

  get createdAtText(): string {
    return dateToShort(new Date(this.created_at));
  }

  get conditionText(): string {
    return conditionToText(this.condition);
  }

  get minPrice(): string {
    let r = '';
    if (this.minimum_price !== '0') r = this.minimum_price;
    return r;
  }

  get moovMarketPrice(): string {
    let r = 'Request Price';
    switch (this.priceStatus) {
      case PriceStatus.Available:
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        });
        r = `${formatter.format(parseInt(this.low_value))} - ${formatter.format(parseInt(this.high_value))}`;
        break;
      case PriceStatus.Expired:
        r = 'Expired';
        break;
      case PriceStatus.Init:
        r = 'Request Price';
        break;
      case PriceStatus.Pending:
        r = 'Price Pending';
        break;
      case PriceStatus.Unavailable:
        r = 'Price Not Available';
        break;
      default:
        unreachable(this.priceStatus);
        break;
    }
    return r;
  }

  get moovMarketPriceExport(): string {
    if (this.priceStatus === PriceStatus.Init) {
      return '-';
    }
    return this.moovMarketPrice;
  }

  get priceAvailable(): boolean {
    return this.low_value !== '0' && this.estimated_value !== '0' && this.high_value !== '0' && this.priceUpdated;
  }
  get priceExpired(): boolean {
    let r = false;
    if (this.price_expires_at_ts > 0) r = new Date(this.price_expires_at_ts * 1000) <= new Date();
    return r;
  }
  get priceInit(): boolean {
    return (
      this.low_value === '0' &&
      this.estimated_value === '0' &&
      this.high_value === '0' &&
      !this.priceUpdated &&
      !this.priceExpired &&
      !this.pricePending
    );
  }
  get pricePending(): boolean {
    return this.pending_price === 1;
  }
  get priceStatus(): PriceStatus {
    let r: PriceStatus = PriceStatus.Init;
    if (this.priceAvailable) r = PriceStatus.Available;
    if (this.priceUnavailable) r = PriceStatus.Unavailable;
    if (this.pricePending) r = PriceStatus.Pending;
    if (this.priceExpired) r = PriceStatus.Expired;
    return r;
  }

  get priceUpdated(): boolean {
    return this.price_updated_at_ts > 0;
  }
  get priceUnavailable(): boolean {
    return this.low_value === '0' && this.estimated_value === '0' && this.high_value === '0' && this.priceUpdated;
  }

  // return value as string and '' for 0 (api non value)
  get quantityText(): string {
    let r = `${this.quantity}`;
    if (this.quantity === 0) r = '';
    return r;
  }

  get statusCTAColor(): 'secondary' | 'success' {
    let r: 'secondary' | 'success' = 'success';
    if (this.status === Status.Listed) r = 'secondary';
    return r;
  }

  get statusCTAText(): string {
    let r = 'List Equipment';
    if (this.status === Status.Listed) r = 'Unlist';
    return r;
  }
  get statusText(): string {
    return Equipment2.statusToText(this.status);
  }

  get toolStatusText(): string {
    return Equipment2.toolStatusToText(this.tool_status);
  }

  get unlistable(): boolean {
    return this.model_id === 0;
  }

  // return value as string and '' for 0 (api non value)
  get vintageText(): string {
    let r = `${this.vintage}`;
    if (!this.vintage || this.vintage === 0) r = '';
    return r;
  }

  //endregion
  formatCountryText(countryOptions: { id: string; label: string }[]): string {
    return countryOptions.find((e) => e.id === this.address.country)?.label || '';
  }

  toSerializable(): EquipmentSerializable {
    const files: FileSerializable[] = this.files.map((e: File) => e.toSerializable());
    const photos: PhotoSerializable[] = this.photos.map((e: Photo) => e.toSerializable());
    return {
      category: this.category.toSerializable(),
      condition: this.condition,
      configuration: this.configuration,
      created_at: this.created_at,
      custom_fields: this.custom_fields,
      description: this.description,
      estimated_value: this.estimated_value,
      external_id: this.external_id,
      files,
      high_value: this.high_value,
      id: this.id,
      listing_id: this.listing_id,
      listing_key: this.listing_key,
      low_value: this.low_value,
      make: this.make.toSerializable(),
      market_price: this.market_price,
      minimum_price: this.minimum_price,
      model: this.model.toSerializable(),
      model_id: this.model_id,
      notes: this.notes,
      pending_price: this.pending_price,
      photo_count: this.photo_count,
      photos,
      price_expires_at_ts: this.price_expires_at_ts,
      price_updated_at_ts: this.price_updated_at_ts,
      quantity: this.quantity,
      serial_number: this.serial_number,
      status: this.status,
      user_id: this.user_id,
      vintage: this.vintage,
      wafer_size: this.wafer_size,
      wafer_size_values: this.wafer_size_values,
      wanted_count: this.wanted_count,
      tool_status: this.tool_status,
      listing_strength: this.listing_strength,
      address: this.address.toSerializable()
    };
  }

  //region Static Members
  static anyToDto(a: any): Equipment2 {
    const category = Attribute.anyToDto(a.category ?? {});
    const files = (a.files ?? []).map((e: any) => File.anyToDto(e));
    const make = Attribute.anyToDto(a.make ?? {});
    const model = Attribute.anyToDto(a.model ?? {});
    const photos = (a.photos ?? []).map((e: any) => Photo.anyToDto(e));
    const address = Address.anyToDto(a.address ?? {});
    const s = Equipment2.anyToSerializable(a);

    return new Equipment2({ ...s, category, files, make, model, photos, address });
  }

  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): EquipmentSerializable {
    // transform wafer_size into wafer_size_values
    const ws = stringFromKey('wafer_size', a);
    let wsv: number[] = [];
    if (ws !== '')
      wsv = ws
        .split(',')
        .map((e) => Number.parseInt(e))
        .sort((a, b) => a - b);

    return {
      category: Attribute.anyToSerializable(a.category ?? {}),
      condition: numberFromKey('condition', a) as Condition,
      configuration: stringFromKey('configuration', a),
      created_at: stringFromKey('created_at', a),
      custom_fields: a.custom_fields,
      description: stringFromKey('description', a),
      estimated_value: stringFromKey('estimated_value', a),
      external_id: stringFromKey('external_id', a),
      files: (a.files ?? []).map((e: any) => File.anyToSerializable(e)),
      high_value: stringFromKey('high_value', a),
      id: numberFromKey('id', a),
      listing_id: numberFromKey('listing_id', a),
      listing_key: stringFromKey('listing_key', a),
      low_value: stringFromKey('low_value', a),
      make: Attribute.anyToSerializable(a.make ?? {}),
      market_price: stringFromKey('market_price', a),
      minimum_price: stringFromKey('minimum_price', a),
      model: Attribute.anyToSerializable(a.model ?? {}),
      model_id: numberFromKey('model_id', a),
      notes: stringFromKey('notes', a),
      pending_price: numberFromKey('pending_price', a),
      photo_count: numberFromKey('photo_count', a),
      photos: (a.photos ?? []).map((e: any) => Photo.anyToSerializable(e)),
      price_expires_at_ts: numberFromKey('price_expires_at_ts', a),
      price_updated_at_ts: numberFromKey('price_updated_at_ts', a),
      quantity: numberFromKey('quantity', a),
      serial_number: stringFromKey('serial_number', a),
      status: numberFromKey('status', a) as Status,
      user_id: numberFromKey('user_id', a),
      vintage: numberFromKey('vintage', a),
      wafer_size: ws,
      wafer_size_values: wsv,
      wanted_count: numberFromKey('wanted_count', a),
      tool_status: a.tool_status,
      listing_strength: numberFromKey('listing_strength', a),
      address: Address.anyToSerializable(a.address)
    };
  }

  static statusToText(status: Status): string {
    switch (status) {
      case Status.Active:
        return 'Inventory';
      case Status.Listed:
        return 'Listed';
      case Status.Disabled:
        return 'Disabled';
      case Status.Sold:
        return 'Sold';
      case Status.Deleted:
        return 'Deleted';
      default:
        return unreachable(status);
    }
  }

  static toolStatusToText = ToolStatus2String;

  static statusStringToText(status: string): string {
    switch (status) {
      case Status.Active.toString():
        return 'Inventory';
      case Status.Listed.toString():
        return 'Listed';
      case Status.Disabled.toString():
        return 'Disabled';
      case Status.Sold.toString():
        return 'Sold';
      case Status.Deleted.toString():
        return 'Deleted';
      default:
        return '';
    }
  }

  //endregion
}
// create an object with only Dto properties / no functions or getters

export type EquipmentSerializable = {
  category: AttributeSerializable;
  condition: Condition;
  configuration: string;
  created_at: string;
  custom_fields: Record<string, string>;
  description: string;
  estimated_value: string;
  external_id: string;
  files: FileSerializable[];
  high_value: string;
  id: number;
  listing_id: number;
  listing_key: string;
  low_value: string;
  make: AttributeSerializable;
  market_price: string;
  minimum_price: string;
  model: AttributeSerializable;
  model_id: number;
  notes: string;
  pending_price: number;
  photo_count: number;
  photos: PhotoSerializable[];
  price_expires_at_ts: number;
  price_updated_at_ts: number;
  quantity: number;
  serial_number: string;
  status: Status;
  user_id: number;
  vintage: number;
  wafer_size: string;
  wafer_size_values: number[];
  wanted_count: number;
  tool_status: ToolStatus;
  listing_strength: number;
  address: AddressSerializable;
};

export class File {
  equipment_id = 0;
  file_location = '';
  id = 0;
  name = '';

  constructor(args: Partial<File> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): FileSerializable {
    return {
      equipment_id: this.equipment_id,
      file_location: this.file_location,
      id: this.id,
      name: this.name
    };
  }

  //region Static Members
  static anyToDto(a: any): File {
    const s = File.anyToSerializable(a);
    return new File(s);
  }
  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): FileSerializable {
    return {
      equipment_id: numberFromKey('equipment_id', a),
      file_location: stringFromKey('file_location', a),
      id: numberFromKey('id', a),
      name: stringFromKey('name', a)
    };
  }
  //endregion
}

// this approach to creating the serializable type only works for simple structures.  It does not work for nested structures.
const fs = { ...new File() };
export type FileSerializable = typeof fs;

export class Photo {
  content_type = '';
  id = 0;
  large_url = '';
  medium_url = '';
  name = '';
  order = 0;
  small_url = '';

  toSerializable(): PhotoSerializable {
    return {
      content_type: this.content_type,
      id: this.id,
      large_url: this.large_url,
      medium_url: this.medium_url,
      name: this.name,
      order: this.order,
      small_url: this.small_url
    };
  }

  constructor(args: Partial<Photo> = {}) {
    Object.assign(this, args);
  }

  static anyToDto(a: any): Photo {
    const ps = Photo.anyToSerializable(a);
    return new Photo(ps);
  }
  static anyToSerializable(a: any): PhotoSerializable {
    return {
      content_type: stringFromKey('content_type', a),
      id: numberFromKey('id', a),
      large_url: stringFromKey('large_url', a),
      medium_url: stringFromKey('medium_url', a),
      name: stringFromKey('name', a),
      order: numberFromKey('order', a),
      small_url: stringFromKey('small_url', a)
    };
  }
}

export const toolStatuses: { id: ToolStatus; label: string }[] = [
  {
    id: ToolStatus.INSTALLED_RUNNING,
    label: Equipment2.toolStatusToText(ToolStatus.INSTALLED_RUNNING)
  },
  {
    id: ToolStatus.INSTALLED_IDLE,
    label: Equipment2.toolStatusToText(ToolStatus.INSTALLED_IDLE)
  },
  {
    id: ToolStatus.INSTALLED_DOWN,
    label: Equipment2.toolStatusToText(ToolStatus.INSTALLED_DOWN)
  },
  {
    id: ToolStatus.DEINSTALLED,
    label: Equipment2.toolStatusToText(ToolStatus.DEINSTALLED)
  },
  {
    id: ToolStatus.DEINSTALLED_UNCRATED,
    label: Equipment2.toolStatusToText(ToolStatus.DEINSTALLED_UNCRATED)
  },
  {
    id: ToolStatus.DEINSTALLED_CRATED,
    label: Equipment2.toolStatusToText(ToolStatus.DEINSTALLED_CRATED)
  },
  {
    id: ToolStatus.DEINSTALLED_PALLETIZED,
    label: Equipment2.toolStatusToText(ToolStatus.DEINSTALLED_PALLETIZED)
  }
];

export const toolStatusOptions = toolStatuses.map((e) => ({ id: `${e.id}`, label: e.label }));

// Necessary for id value that comes back as string from the Select control
export function idToToolStatus(toolStatusId: string): ToolStatus {
  return toolStatuses.find((v) => v.id === parseInt(toolStatusId))?.id || ToolStatus.INSTALLED_IDLE;
}

// this approach to creating the serializable type only works for simple structures.  It does not work for nested structures.
const ps = { ...new Photo() };
export type PhotoSerializable = typeof ps;

export type CompanyCustomFields = {
  label: string;
  field: string;
};

//region Old Types
/** @deprecated - use PriceStatus */
export const PricingStatus = {
  Available: 'available',
  Expired: 'expired',
  Init: 'init',
  Pending: 'pending',
  Unavailable: 'unavailable'
} as const;
export type PricingStatus = (typeof PricingStatus)[keyof typeof PricingStatus];
/** @deprecated - use File */
export interface EquipmentFileDTO {
  equipment_id: number;
  file_location: string;
  id: number;
  name: string;
}

/** @deprecated - use Photo */
export interface EquipmentPhotoDTO {
  equipment_id: number;
  content_type: string;
  id: number;
  large_url: string;
  medium_url: string;
  name: string;
  order: number;
  small_url: string;
}

/** @deprecated - use Status */
export enum EquipmentStatus {
  STATUS_ACTIVE = 100,
  STATUS_LISTED = 101,
  STATUS_DISABLED = 200,
  STATUS_SOLD = 201,
  STATUS_DELETED = 300
}

/** @deprecated - use Common Attribute */
export interface EquipmentAttribute {
  readonly description: string;
  readonly id: number;
  readonly name: string;
  readonly slug: string;
}

/** @deprecated - use new Dto */
export const _getEmptyEquipmentAttribute = (): EquipmentAttribute => ({
  description: '',
  id: 0,
  name: '',
  slug: ''
});

/** @deprecated - use Dto */
export interface EquipmentDTO {
  id: number;
  category: EquipmentAttribute;
  condition: number;
  configuration: string;
  created_at: string;
  description: string;
  estimated_value: string;
  external_id: string;
  files: EquipmentFileDTO[];
  high_value: string;
  listing_id: number;
  listing_key: string;
  location: string;
  location_2: string;
  location_3: string;
  low_value: string;
  make: EquipmentAttribute;
  market_price: string;
  minimum_price: string;
  model: EquipmentAttribute;
  model_id: number;
  notes: string;
  pending_price: number;
  photo_count: number;
  photos: EquipmentPhotoDTO[];
  price_expires_at_ts: number;
  price_updated_at_ts: number;
  quantity: number;
  serial_number: string;
  status: EquipmentStatus;
  user_id: number;
  vintage: null | number;
  wafer_size: string;
  wanted_count: number;
  tool_status: number;
  operational_status: number;
  listing_strength: number;
}

/** @deprecated - use File */
export interface EquipmentFile extends Omit<EquipmentFileDTO, 'equipment_id'> {}

/** @deprecated - use Photo */
export interface EquipmentPhoto extends Omit<EquipmentPhotoDTO, 'equipment_id'> {}

/** @deprecated - use Dto */
export interface Equipment extends Omit<EquipmentDTO, 'wafer_size' | 'photos' | 'files' | 'notes' | 'operational_status'> {
  notes: string;
  wafer_size_values: number[];
  photos: EquipmentPhoto[];
  files: EquipmentFile[];
}

/** @deprecated - do not use */
export const isEquipment = (arg: unknown): arg is Equipment | EquipmentSerializable => {
  const hasEstValue = (arg as Equipment).estimated_value !== undefined;
  const hasLowValue = (arg as Equipment).low_value !== undefined;
  const hasHighValue = (arg as Equipment).high_value !== undefined;
  const hasPendingPrice = (arg as Equipment).pending_price !== undefined;
  const hasPriceExpires = (arg as Equipment).price_expires_at_ts !== undefined;
  const hasPriceUpdated = (arg as Equipment).price_updated_at_ts !== undefined;
  const hasListingKey = (arg as Equipment).listing_key !== undefined;
  return (
    !!arg &&
    hasEstValue &&
    hasLowValue &&
    hasHighValue &&
    hasPendingPrice &&
    hasPriceExpires &&
    hasPriceUpdated &&
    hasListingKey &&
    typeof arg === 'object' &&
    typeof (arg as Equipment).category === 'object' &&
    typeof (arg as Equipment).make === 'object' &&
    typeof (arg as Equipment).model === 'object'
  );
};

/** @deprecated - use Dto fromApi */
export const dtoToEquipment = (equipDTO: EquipmentDTO): Equipment => {
  let tool_status = equipDTO.tool_status ?? equipDTO.operational_status;
  const hasLegacyStatus = tool_status < ToolStatus.INSTALLED_RUNNING;
  if (hasLegacyStatus) {
    tool_status = ToolStatus.NO_STATUS;
  }

  return {
    ...equipDTO,
    notes: equipDTO.notes,
    wafer_size_values: parseWaferSizeValues(equipDTO.wafer_size),
    photos: equipDTO.photos?.map((p) => dtoToEquipmentPhoto(p)) ?? [],
    files: equipDTO.files?.map((f) => dtoToEquipmentFile(f)) ?? [],
    tool_status
  };
};

/** @deprecated - do not use */
export const equipmentToDTO = (equipment: Partial<Equipment> & { id: number }): Partial<EquipmentDTO> => ({
  ...equipment,
  notes: equipment.notes,
  wafer_size: stringifyWaferSizeValue(equipment.wafer_size_values),
  photos: equipment.photos?.map((photo) => equipmentPhotoToDto(photo, equipment.id)) ?? [],
  files: equipment.files?.map((f) => equipmentFileToDto(f, equipment.id)) ?? []
});

/** @deprecated - do not use */
export const newEquipmentToDTO = (equipment: Partial<Equipment>): Partial<EquipmentDTO> => ({
  ...equipment,
  notes: equipment.notes,
  wafer_size: stringifyWaferSizeValue(equipment.wafer_size_values),
  photos: [],
  files: []
});

/** @deprecated - do not use */
const equipmentPhotoToDto = (photo: EquipmentPhoto, equipment_id: number): EquipmentPhotoDTO => {
  return { ...photo, equipment_id };
};

/** @deprecated - do not use */
const dtoToEquipmentPhoto = ({ equipment_id, ...restOfPhoto }: EquipmentPhotoDTO): EquipmentPhoto => {
  return { ...restOfPhoto };
};

/** @deprecated - do not use */
const equipmentFileToDto = (file: EquipmentFile, equipment_id: number): EquipmentFileDTO => {
  return { ...file, equipment_id };
};

/** @deprecated - do not use */
const dtoToEquipmentFile = ({ equipment_id, ...restOfFile }: EquipmentFileDTO): EquipmentFile => {
  return { ...restOfFile };
};

/** @deprecated - do not use */
export const equipmentIsInventory = (equipment: Equipment): boolean => equipment.status === EquipmentStatus.STATUS_ACTIVE;

/** @deprecated - do not use */
export const equipmentIsListed = (equipment: Equipment | Equipment2 | EquipmentSerializable): boolean =>
  equipment.status === EquipmentStatus.STATUS_LISTED;

/** @deprecated - do not use */
export const equipmentIsDisabled = (equipment: Equipment): boolean => equipment.status === EquipmentStatus.STATUS_DISABLED;

/** @deprecated - do not use */
export const equipmentIsSold = (equipment: Equipment): boolean => equipment.status === EquipmentStatus.STATUS_SOLD;

/** @deprecated - do not use */
type StatusBooleans = {
  expired: boolean;
  pending: boolean;
  updated: boolean;
  valid: boolean;
};

/** @deprecated - do not use */
export function boolToPricingStatus({ expired, pending, updated, valid }: StatusBooleans): PricingStatus {
  let r: PricingStatus = PricingStatus.Init;
  if (valid && updated) r = PricingStatus.Available;
  if (!valid && updated) r = PricingStatus.Unavailable;
  if (pending) r = PricingStatus.Pending;
  if (expired) r = PricingStatus.Expired;
  return r;
}

/** @deprecated - do not use */
export const filterInactiveEquipment = (equipment: Equipment[]): Equipment[] =>
  equipment.filter((equip) => [EquipmentStatus.STATUS_ACTIVE, EquipmentStatus.STATUS_LISTED].includes(equip.status));

/** @deprecated - do not use */
export function getMakeDisplayString({ make: { name } }: Equipment | EquipmentSerializable) {
  return name;
}

/** @deprecated - do not use */
export function getModelDisplayString({ model: { name } }: Equipment | EquipmentSerializable) {
  return name;
}

/** @deprecated - do not use */
export function getEquipmentMakeModelDisplayString(equipment: Equipment | EquipmentSerializable) {
  return `${getMakeDisplayString(equipment)} ${getModelDisplayString(equipment)}`;
}
// endregion
