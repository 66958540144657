import { useAppDispatch, useAppSelector } from '@/src/store';
import { getFilteredSellerOffers } from '../../selectors';
import { Divider, Stack } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { PendingOfferSummary } from '../pendingOfferSummary';
import { AcceptedOfferSummary } from '../acceptedOfferSummary';
import { DeclinedExpiredOfferSummary } from '../DeclinedExpiredOfferSummary';
import { setExpandedOfferId } from '../../slice';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';
import { OffersSellerExpandedDrawer } from './expandedOfferDrawer';
import { OfferHeader } from './offerHeader';

export const OffersSellerTabMobile = () => {
  const filteredSellerOffers = useAppSelector(getFilteredSellerOffers);
  const dispatch = useAppDispatch();
  const { openDrawer } = useSideDrawer(SideDrawers.ExpandedOffer);
  return (
    <Stack>
      {filteredSellerOffers.map((sellerOfferGroup) => {
        return (
          <Stack key={`mobile-seller-offer-${sellerOfferGroup.listing.key}`}>
            <Stack
              onClick={() => {
                dispatch(setExpandedOfferId(sellerOfferGroup.listing.key));
                openDrawer();
              }}
              bgcolor={colors.surface.white}
              p={spacing.xl}
            >
              <Stack spacing={spacing.xl}>
                <OfferHeader sellerOfferGroup={sellerOfferGroup} />
                <Stack direction="row">
                  <Stack direction="row" spacing={spacing.xl} minHeight="54px" width="100%">
                    {Boolean(sellerOfferGroup.pending_offers) ? (
                      <PendingOfferSummary sellerOfferGroup={sellerOfferGroup} />
                    ) : sellerOfferGroup.accepted_offer ? (
                      <AcceptedOfferSummary accepted_offer={sellerOfferGroup.accepted_offer} />
                    ) : (
                      <DeclinedExpiredOfferSummary sellerOfferGroup={sellerOfferGroup} />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        );
      })}
      <OffersSellerExpandedDrawer />
    </Stack>
  );
};
