import { Formik } from 'formik';
import dynamic from 'next/dynamic';
import * as yup from 'yup';

import { useLocalizer } from '../../localization';
import { resetUserPassword } from '../../providers/users';
import { InternalLink } from '../links';
import routes from '../../routes';
import FormikTextField from '../forms/formikFields/formikTextField';
import { FormikFormWrapper } from '../forms/formikFields/formikWrappers';
import ModalPopup from './modalPopup';
import { SetModal } from '../../entities/common';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useModal } from '../../hooks/useModal';
import { MoovButton } from '../moovButton';
import { ERROR_MESSAGES } from '@/src/constants';

const ResetPasswordSuccessModal = dynamic(() => import('./resetPasswordSuccessModal'), { ssr: false });
const LoginModal = dynamic(() => import('./loginModal'), { ssr: false });

/**
 *
 */
const ResetPasswordModal = ({ setModal }: { setModal: SetModal }) => {
  const loc = useLocalizer();
  const { isOpen, onClose, swapInModal } = useModal(setModal);
  const { openErrorAlert } = useGlobalAlertBanner();

  const onSubmit = async (values: { email: string }) => {
    await resetUserPassword(values)
      .then(() => {
        swapInModal(<ResetPasswordSuccessModal setModal={setModal} />);
      })
      .catch(() => {
        openErrorAlert(ERROR_MESSAGES.REFRESH);
      });
  };

  const onLoginClick = () => {
    swapInModal(<LoginModal setModal={setModal} />);
  };

  const initialValues = {
    email: ''
  };

  const validationSchema = yup.object({
    email: yup.string().email('Please enter a valid email').required('Email is required')
  });

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose} className="max-w-sm-modal">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, errors, submitCount }) => (
          <FormikFormWrapper isSubmitting={isSubmitting} errors={errors} submitCount={submitCount}>
            <div className="flex flex-col items-center px-8 pb-16 pt-4">
              <div className="mb-6 text-center">
                <div className="mb-5 text-20spx font-bold">{loc.Modals.ForgotPassword}</div>
                <div>{loc.Modals.EnterEmailAssociated}</div>
              </div>

              <div className="mb-4 w-full">
                <FormikTextField type="email" name="email" placeholder={loc.Forms.Email} required />
              </div>

              <div className="mb-8 flex justify-center">
                <MoovButton variant="secondary" className="w-56 px-3 py-2.5" type="submit">
                  {loc.Modals.GetLink}
                </MoovButton>
              </div>

              <button type="button" onClick={onLoginClick} className="cursor-pointer bg-transparent text-center font-bold hover:underline">
                {loc.Modals.BackToLogin}
              </button>
              <div>
                {loc.Modals.NoAccount}
                {` `}
                <InternalLink onClick={onClose} href={routes.register()} className="hover:underline">
                  {loc.Modals.RegisterHere}
                </InternalLink>
              </div>
            </div>
          </FormikFormWrapper>
        )}
      </Formik>
    </ModalPopup>
  );
};

export default ResetPasswordModal;
