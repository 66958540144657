import type { FC } from 'react';

import Image from 'next/image';

import { useLocalizer } from '../../localization';
import { IMAGE_URLS } from '../../constants/imageUrls';

interface LoaderProps {
  readonly isLoading: boolean;
}

export const LoadingOverlayTestId = 'moov-logo-loader';

/**
 *
 */
export const LoadingOverlay: FC<LoaderProps> = ({ isLoading }) => {
  const loc = useLocalizer();
  if (!isLoading) return null;

  return (
    <div
      className="z-loadingOverlay absolute top-0 bottom-0 left-0 right-0 flex cursor-not-allowed items-center justify-center opacity-50"
      data-testid={LoadingOverlayTestId}
    >
      <div className="relative h-20 w-20">
        <Image src={IMAGE_URLS.MOOV_LOGO_LOADER} alt={loc.Forms.LoadingMoovLogo} layout="fill" objectFit="contain" />
      </div>
    </div>
  );
};

/**
 *
 */
export const MoovLoader: FC<LoaderProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="relative h-30 w-full" data-testid="MoovLoader">
      <LoadingOverlay isLoading />
    </div>
  );
};
