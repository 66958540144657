import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';
import { Box, Card, Chip, Drawer, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { setExpandedOfferId } from '../../slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getExpandedSellerOfferGroup } from '../../selectors';
import { ReadableOfferStatus, SellerOffer, SellerOfferGroup } from '../../types';
import { FC, useEffect, useRef, useState } from 'react';
import { parseUSDCurrencyStr } from '@/src/utils';
import { OfferHeader } from './offerHeader';
import { OfferStatusChip } from '../../statusChip';
import { formatStatusTimeDifference } from '../../utils';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import dayjs from 'dayjs';
import { SellerOfferDeclinedCTAs, SellerOfferExpiredCTAs, SellerOfferPendingCTAs, SellerOwnedPendingCounterCTAs } from '../offerCTAs';

const OfferSummary: FC<{ offer: SellerOffer }> = ({ offer }) => {
  const { palette } = useTheme();
  const countryOptions = useAppSelector(getLogisticCountries);
  return (
    <Stack direction="row">
      <Stack spacing={spacing.md} width="50%">
        <Stack spacing={spacing.xs}>
          <Typography variant="p12">
            <strong>Buyer:</strong>
          </Typography>
          <Typography variant="p12">{offer.buyer_alias}</Typography>
        </Stack>
        <Stack spacing={spacing.xs}>
          <Typography variant="p12">
            <strong>Destination Country:</strong>
          </Typography>
          <Typography variant="p12">{offer.countryText(countryOptions)}</Typography>
        </Stack>
      </Stack>
      <Stack width="50%" spacing={spacing.md}>
        <Stack spacing={spacing.xs}>
          <Typography variant="p12">
            <strong>Date Required By:</strong>
          </Typography>
          <Typography variant="p12">
            {dayjs(offer.required_by).isValid() ? dayjs(offer.required_by).format('MM/DD/YYYY') : 'N/A'}
          </Typography>
        </Stack>
        <Stack spacing={spacing.xs}>
          <Typography variant="p12">
            <strong>Offer Expiration:</strong>
          </Typography>
          <Typography
            color={
              offer.readable_status === ReadableOfferStatus.ACCEPTED
                ? colors.crayolaGreen['500']
                : offer.readable_status === ReadableOfferStatus.DECLINED
                ? colors.notification.hotRed['500']
                : offer.readable_status === ReadableOfferStatus.EXPIRED
                ? palette.warning.main
                : colors.magenta['500']
            }
            variant="p12"
          >
            <strong>
              {/* TODO: translations */}
              {offer.readable_status === ReadableOfferStatus.ACCEPTED
                ? 'Accepted'
                : offer.readable_status === ReadableOfferStatus.DECLINED
                ? 'Declined'
                : offer.readable_status === ReadableOfferStatus.EXPIRED
                ? 'Expired'
                : offer.expireDaysText}
            </strong>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PendingContent: FC<{
  offer: SellerOffer;
  sellerOfferGroup: SellerOfferGroup;
}> = ({ offer, sellerOfferGroup }) => {
  return (
    <Card variant="outlined" sx={{ backgroundColor: colors.brand[50] }}>
      <Stack p={spacing.lg} spacing={spacing.xl}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="p12" fontWeight={600} color={colors.smokeyGray['400']}>
              OFFER AMOUNT
            </Typography>
            <Typography variant="h5" color={colors.crayolaGreen['500']}>
              {parseUSDCurrencyStr(offer.offer_amount)} USD
            </Typography>
            <Paper
              elevation={0}
              sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}`, width: 'fit-content' }}
            >
              <Stack variant="center" p={spacing.md}>
                <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                  {formatStatusTimeDifference(offer)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={spacing.xs} alignItems="flex-end">
            <OfferStatusChip offer={offer} sx={{ padding: '0px', width: 'fit-content' }} />
            {offer.key === sellerOfferGroup.highest_offer?.key && (
              <Chip sx={{ padding: '0px' }} size="small" color="info" variant={'outlined'} label={'Highest Offer'} />
            )}
          </Stack>
        </Stack>
        <OfferSummary offer={offer} />
        <Stack spacing={spacing.md}>
          <SellerOfferPendingCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth />
        </Stack>
        {offer.offer_note && (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: colors.yellow['50'],
              border: `1px solid ${colors.yellow['100']}`,
              width: 'fit-content',
              p: spacing.md
            }}
          >
            <Stack direction="row" alignItems="center" spacing={spacing.sm}>
              <Box alignSelf="baseline">
                <FAIcon icon={icons.noteSticky} scale={1} />
              </Box>
              <Typography variant="p14">
                <strong>{offer.noteLabel}</strong>&nbsp;{offer.offer_note}
              </Typography>
            </Stack>
          </Paper>
        )}
      </Stack>
    </Card>
  );
};

const PendingSellerCounterContent: FC<{
  offer: SellerOffer;
  sellerOfferGroup: SellerOfferGroup;
}> = ({ offer, sellerOfferGroup }) => {
  return (
    <Card variant="outlined" sx={{ backgroundColor: colors.brand[50] }}>
      <Stack p={spacing.lg} spacing={spacing.xl}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="p12" fontWeight={600} color={colors.smokeyGray['400']}>
              COUNTER OFFER AMOUNT
            </Typography>
            <Typography variant="h5" color={colors.crayolaGreen['500']}>
              {parseUSDCurrencyStr(offer.offer_amount)} USD
            </Typography>
            <Paper
              elevation={0}
              sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}`, width: 'fit-content' }}
            >
              <Stack variant="center" p={spacing.md}>
                <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                  {formatStatusTimeDifference(offer)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={spacing.xs} alignItems="flex-end">
            <OfferStatusChip offer={offer} sx={{ padding: '0px', width: 'fit-content' }} />
          </Stack>
        </Stack>
        <OfferSummary offer={offer} />
        <Stack spacing={spacing.md}>
          <SellerOwnedPendingCounterCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth />
        </Stack>
        {offer.offer_note && (
          <Paper
            elevation={0}
            sx={{
              backgroundColor: colors.yellow['50'],
              border: `1px solid ${colors.yellow['100']}`,
              width: 'fit-content',
              p: spacing.md
            }}
          >
            <Stack direction="row" alignItems="center" spacing={spacing.sm}>
              <Box alignSelf="baseline">
                <FAIcon icon={icons.noteSticky} scale={1} />
              </Box>
              <Typography variant="p14">
                <strong>{offer.noteLabel}</strong>&nbsp;{offer.offer_note}
              </Typography>
            </Stack>
          </Paper>
        )}
      </Stack>
    </Card>
  );
};

const AcceptedContent: FC<{ offer: SellerOffer }> = ({ offer }) => {
  return (
    <Card variant="outlined" sx={{ backgroundColor: colors.brand[50] }}>
      <Stack p={spacing.lg} spacing={spacing.xl}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="p12" fontWeight={600} color={colors.smokeyGray['400']}>
              OFFER AMOUNT
            </Typography>
            <Typography variant="h5" color={colors.crayolaGreen['500']}>
              {parseUSDCurrencyStr(offer.offer_amount)} USD
            </Typography>
            <Paper
              elevation={0}
              sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}`, width: 'fit-content' }}
            >
              <Stack variant="center" p={spacing.md}>
                <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                  {formatStatusTimeDifference(offer)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={spacing.xs}>
            <OfferStatusChip offer={offer} sx={{ padding: '0px' }} />
          </Stack>
        </Stack>
        <OfferSummary offer={offer} />
      </Stack>
    </Card>
  );
};

const DeclinedContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();
  return (
    <Card variant="outlined" sx={{ backgroundColor: colors.brand[50] }}>
      <Stack p={spacing.lg} spacing={spacing.xl}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="p12" fontWeight={600} color={colors.smokeyGray['400']}>
              OFFER AMOUNT
            </Typography>
            <Typography variant="h5" color={colors.crayolaGreen['500']}>
              {parseUSDCurrencyStr(offer.offer_amount)} USD
            </Typography>
            <Paper
              elevation={0}
              sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}`, width: 'fit-content' }}
            >
              <Stack variant="center" p={spacing.md}>
                <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                  {formatStatusTimeDifference(offer)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={spacing.xs}>
            <OfferStatusChip offer={offer} sx={{ padding: '0px' }} />
          </Stack>
        </Stack>
        <OfferSummary offer={offer} />
        <SellerOfferDeclinedCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth />
        {offer.declined_reason && (
          <Paper elevation={0} sx={{ backgroundColor: palette.error.lightest, width: 'fit-content', p: spacing.md }}>
            <Stack direction="row" alignItems="center" spacing={spacing.sm}>
              <Box alignSelf="baseline">
                <FAIcon icon={icons.noteSticky} scale={1} />
              </Box>
              <Typography variant="p14">
                <strong>{offer.noteLabel}</strong>&nbsp;{offer.declined_reason}
              </Typography>
            </Stack>
          </Paper>
        )}
      </Stack>
    </Card>
  );
};

const ExpiredContent: FC<{ offer: SellerOffer; sellerOfferGroup: SellerOfferGroup }> = ({ offer, sellerOfferGroup }) => {
  const { palette } = useTheme();

  return (
    <Card variant="outlined" sx={{ backgroundColor: colors.brand[50] }}>
      <Stack p={spacing.lg} spacing={spacing.xl}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="p12" fontWeight={600} color={colors.smokeyGray['400']}>
              OFFER AMOUNT
            </Typography>
            <Typography variant="h5" color={palette.warning.main}>
              {parseUSDCurrencyStr(offer.offer_amount)} USD
            </Typography>
            <Paper
              elevation={0}
              sx={{ borderRadius: borderRadius.rounded, border: `1px solid ${colors.corpGray['100']}`, width: 'fit-content' }}
            >
              <Stack variant="center" p={spacing.md}>
                <Typography variant="p10" fontWeight={500} color={colors.corpGray['600']}>
                  {formatStatusTimeDifference(offer)}
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack spacing={spacing.xs}>
            <OfferStatusChip offer={offer} sx={{ padding: '0px' }} />
          </Stack>
        </Stack>
        <OfferSummary offer={offer} />
        <SellerOfferExpiredCTAs offer={offer} sellerOfferGroup={sellerOfferGroup} fullWidth />
      </Stack>
    </Card>
  );
};

export const OffersSellerExpandedDrawer = () => {
  const theme = useTheme();
  const { drawerOpen, closeDrawer } = useSideDrawer(SideDrawers.ExpandedOffer);
  const expandedSellerOfferGroup = useAppSelector(getExpandedSellerOfferGroup);
  const dispatch = useAppDispatch();
  const closeSellerSideDrawer = () => {
    dispatch(setExpandedOfferId(''));
    closeDrawer();
  };

  const headerRef = useRef<HTMLDivElement>(null);
  const [offsetHeight, setOffsetHeight] = useState(0);
  useEffect(() => {
    if (headerRef.current) {
      setOffsetHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef.current]);

  return (
    <Drawer
      anchor="right"
      open={drawerOpen && Boolean(expandedSellerOfferGroup.all_offers.length)}
      onClose={closeSellerSideDrawer}
      PaperProps={{
        sx: {
          width: '100vw'
        }
      }}
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Stack
        width="100%"
        bgcolor={colors.surface.white}
        zIndex={1}
        ref={headerRef}
        position="fixed"
        direction="row"
        alignItems="center"
        p={spacing.lg}
        borderBottom={`2px solid ${colors.corpGray['100']}`}
      >
        <IconButton sx={{ p: spacing.lg }} onClick={closeDrawer}>
          <FAIcon icon={icons.chevronLeft} scale={1} color={colors.moovBlue['500']} />
        </IconButton>
        {/* TODO: translations Offers */}
        <Typography variant="subtitle3">Offers{` (${expandedSellerOfferGroup.all_offers.length})`}</Typography>
        {/* <Divider /> */}
      </Stack>

      <Stack p={spacing.xl} spacing={spacing.xl} mt={`${offsetHeight}px`}>
        <OfferHeader sellerOfferGroup={expandedSellerOfferGroup} />
        {expandedSellerOfferGroup.all_offers.map((offer) => {
          const { readable_status } = offer;
          if (offer.isOwnedPendingCounter)
            return (
              <PendingSellerCounterContent
                key={`expanded-seller-offer-${offer.key}`}
                offer={offer}
                sellerOfferGroup={expandedSellerOfferGroup}
              />
            );
          switch (readable_status) {
            case ReadableOfferStatus.PENDING:
              return (
                <PendingContent key={`expanded-seller-offer-${offer.key}`} offer={offer} sellerOfferGroup={expandedSellerOfferGroup} />
              );
            case ReadableOfferStatus.ACCEPTED:
              return <AcceptedContent key={`expanded-seller-offer-${offer.key}`} offer={offer} />;
            case ReadableOfferStatus.DECLINED:
              return (
                <DeclinedContent key={`expanded-seller-offer-${offer.key}`} offer={offer} sellerOfferGroup={expandedSellerOfferGroup} />
              );
            case ReadableOfferStatus.EXPIRED:
              return (
                <ExpiredContent key={`expanded-seller-offer-${offer.key}`} offer={offer} sellerOfferGroup={expandedSellerOfferGroup} />
              );
            default:
              return null;
          }
        })}
      </Stack>
    </Drawer>
  );
};
