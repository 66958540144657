import translations from './nav.loc.json';
import type { Locale } from './types';
import { getDefinitions, interpolateVariable } from './utils';

/**
 *
 */
export class NavLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get About(): string {
    return this.definitions.About;
  }

  public get AboutMoov(): string {
    return this.definitions.AboutMoov;
  }

  public get Account(): string {
    return this.definitions.Account;
  }

  public get AllEquipment(): string {
    return this.definitions.AllEquipment;
  }

  public get BrowseCategories(): string {
    return this.definitions.BrowseCategories;
  }

  public get BrowseManufacturers(): string {
    return this.definitions.BrowseManufacturers;
  }

  public get BrowseMarketplace(): string {
    return this.definitions.BrowseMarketplace;
  }

  public get Buy(): string {
    return this.definitions.Buy;
  }

  public get Careers(): string {
    return this.definitions.Careers;
  }

  public get CareersAtMoov(): string {
    return this.definitions.CareersAtMoov;
  }

  public get Category(): string {
    return this.definitions.Category;
  }

  public get Categories(): string {
    return this.definitions.Categories;
  }

  public get Company(): string {
    return this.definitions.Company;
  }

  public get Dashboard(): string {
    return this.definitions.Dashboard;
  }

  public get EditProfile(): string {
    return this.definitions.EditProfile;
  }

  public get Home(): string {
    return this.definitions.Home;
  }

  public get HowToBuy(): string {
    return this.definitions.HowToBuy;
  }

  public get HowToSell(): string {
    return this.definitions.HowToSell;
  }

  public get Listings(): string {
    return this.definitions.Listings;
  }

  public get Login(): string {
    return this.definitions.Login;
  }

  public get LogIn(): string {
    return this.definitions.LogIn;
  }

  public get LogOut(): string {
    return this.definitions.LogOut;
  }
  public get Make(): string {
    return this.definitions.Make;
  }

  public get Makes(): string {
    return this.definitions.Makes;
  }

  public get Manufacturers(): string {
    return this.definitions.Manufacturers;
  }

  public get Marketplace(): string {
    return this.definitions.Marketplace;
  }

  public get Models(): string {
    return this.definitions.Models;
  }

  public get MoovLogo(): string {
    return this.definitions.MoovLogo;
  }

  public get NavigationMenu(): string {
    return this.definitions.NavigationMenu;
  }

  public get NewsAndPress(): string {
    return this.definitions.NewsAndPress;
  }

  public get NotFound(): string {
    return this.definitions.NotFound;
  }

  public get OurTeam(): string {
    return this.definitions.OurTeam;
  }

  public get Press(): string {
    return this.definitions.Press;
  }

  public get Register(): string {
    return this.definitions.Register;
  }

  public get Sell(): string {
    return this.definitions.Sell;
  }

  public get ServerError(): string {
    return this.definitions.ServerError;
  }

  public get Service(): string {
    return this.definitions.Service;
  }

  public get Services(): string {
    return this.definitions.Services;
  }

  public get Settlement(): string {
    return this.definitions.Settlement;
  }

  public get Settlements(): string {
    return this.definitions.Settlements;
  }

  public get SkipToContent(): string {
    return this.definitions.SkipToContent;
  }

  public get Team(): string {
    return this.definitions.Team;
  }

  public get SeeAllResults(): string {
    return this.definitions.SeeAllResults;
  }

  public get WeAreHiringBanner(): string {
    return this.definitions.WeAreHiringBanner;
  }

  public WelcomeUser(firstname: string): string {
    return interpolateVariable(this.definitions.WelcomeUser, 'user?.first_name', firstname);
  }

  public get Welcome(): string {
    return this.definitions.Welcome;
  }

  public get Equipment(): string {
    return this.definitions.Equipment;
  }
}
