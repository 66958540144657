import type { FC, ReactElement } from 'react';

import { Fade, Modal as MUIModal } from '@mui/material';

interface Props {
  readonly children: ReactElement<any, any>;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

/**
 * todo #182441368 Add modal labels
 */
const Modal: FC<Props> = ({ children, isOpen, onClose }) => (
  <MUIModal
    open={isOpen}
    onClose={onClose}
    closeAfterTransition
    className="flex justify-center overflow-y-scroll"
    role="dialog"
    aria-modal={true}
  >
    <Fade in={isOpen}>{children}</Fade>
  </MUIModal>
);

export default Modal;
