import { useAppDispatch, useAppSelector } from '@/src/store';
import { getFilteredBuyerOffers } from '../../selectors';
import { Divider, Stack } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { OfferHeader } from './offerHeader';
import { OffersBuyerExpandedDrawer } from './expandedOfferDrawer';
import { setExpandedOfferId } from '../../slice';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';

export const BuyerOffersMobile = () => {
  const filteredBuyerOffers = useAppSelector(getFilteredBuyerOffers);
  const dispatch = useAppDispatch();
  const { openDrawer } = useSideDrawer(SideDrawers.ExpandedOffer);
  return (
    <Stack>
      {filteredBuyerOffers.map((buyerOffer) => {
        return (
          <Stack
            key={`mobile-buyer-offer-${buyerOffer.offer.key}`}
            onClick={() => {
              dispatch(setExpandedOfferId(buyerOffer.key));
              openDrawer();
            }}
          >
            <Stack bgcolor={colors.surface.white} p={spacing.xl}>
              <Stack spacing={spacing.xl}>
                <OfferHeader buyerOffer={buyerOffer} />
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        );
      })}
      <OffersBuyerExpandedDrawer />
    </Stack>
  );
};
