import { useContext, useEffect } from 'react';
import { useAnyOpportunities } from '../../hooks/opportunities/useAnyOpportunities';
import { GTM_EVENT_TYPES } from '../../integrations/googleTagManager/constants';
import { useUser } from '../../hooks/useUser';
import { MobileSuggestedItemsModalStore } from '../../state/mobileSuggestedItemsModal/context';
import { ModalTypes } from '../mobileSuggestedItemsModal/modals/types';
import { useGTM } from '../../hooks/useGTM';

export const useTinderGTM = () => {
  const { currentListing, modalOpen } = useAnyOpportunities();
  const { modalOpen: mobileModalOpen, modalType } = useContext(MobileSuggestedItemsModalStore);

  const { user } = useUser();
  const { trackGTMEvent } = useGTM();

  useEffect(() => {
    const mobileSuggestedModalIsOpen = mobileModalOpen && modalType === ModalTypes.SuggestedItems;
    if (modalOpen || mobileSuggestedModalIsOpen) {
      trackGTMEvent({
        event: GTM_EVENT_TYPES.listing.viewTinder,
        listing: currentListing.key,
        userId: user?.id ?? undefined
      });
    }
  }, [currentListing?.id, modalOpen, mobileModalOpen, modalType]);
};
