import { CheckboxGroupOption } from '@/src/design/bytes/labeledCheckboxGroup/types';
import { ContactFormValues } from '../contactForm';

export const InquiryReason = {
  LearnAboutMoov: 1,
  Sell: 2,
  FindTool: 3,
  PurchaseTool: 4,
  LearnAboutServices: 5,
  Other: 6
};
export type InquiryReason = (typeof InquiryReason)[keyof typeof InquiryReason];

/**
 * TODO: Translations for labels
 */
export const inquiryReasonOptions: CheckboxGroupOption[] = [
  { id: InquiryReason.LearnAboutMoov, label: 'I want to learn more about Moov' },
  { id: InquiryReason.Sell, label: 'I want to sell on Moov' },
  { id: InquiryReason.FindTool, label: 'I am not able to find the tool I need' },
  { id: InquiryReason.PurchaseTool, label: 'Interested in purchasing a tool' },
  { id: InquiryReason.LearnAboutServices, label: "I'd like to learn about Moov services" },
  { id: InquiryReason.Other, label: 'Other' }
];

export enum ContactSalesSteps {
  CONTACT_INFORMATION = 1,
  CONTACT_SALES = 2
}
export type ContactSalesPossibleSteps = ContactSalesSteps.CONTACT_INFORMATION | ContactSalesSteps.CONTACT_SALES;

// Form values types
export interface ContactSalesFormValues {
  inquiry_reason_values: InquiryReason[];
  notes: string;
}
export interface ContactSalesWithUserInfoFormValues extends ContactSalesFormValues, ContactFormValues {}
