import React, { useEffect } from 'react';
import { Field, useField, FieldHookConfig } from 'formik';

import { FormikFieldWrapper } from './formikWrappers';
import { getFieldDescribedById, isEmpty } from '../../../utils';
import type { FormFieldColorVariant } from '../../../entities/form';

const calcTextareaClassName = (touched: boolean, error: string | undefined, colorVariant: FormFieldColorVariant) => {
  let className = 'form-input min-h-[4rem]';

  if (touched && !isEmpty(error)) {
    className += ' input-invalid';
  }

  if (colorVariant === 'white') {
    className += ' form-input-white';
  }

  return className;
};

type Props = FieldHookConfig<string> & {
  readonly label?: string;
  readonly name: string;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly readOnly?: boolean;
  readonly cols?: number;
  readonly rows: number;
  readonly maxLength?: number;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly colorVariant?: FormFieldColorVariant;
  readonly externalHandleChange?: (value: string) => void;
  readonly reserveSpaceBelow?: boolean;
};

/**
 *
 */
const FormikTextarea = (props: Props) => {
  const {
    label,
    name,
    placeholder,
    readOnly,
    colorVariant = 'default',
    cols,
    rows,
    maxLength,
    disabled,
    required,
    helperText,
    externalHandleChange,
    reserveSpaceBelow = true
  } = props;
  const [field, meta] = useField(props);

  const { error, touched } = meta;

  useEffect(() => {
    externalHandleChange && externalHandleChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <FormikFieldWrapper
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      disabled={disabled}
      touched={touched}
      error={error}
      reserveSpaceBelow={reserveSpaceBelow}
    >
      <Field
        as="textarea"
        className={calcTextareaClassName(touched, error, colorVariant)}
        {...field}
        placeholder={placeholder}
        readOnly={readOnly}
        cols={cols}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        required={required}
        aria-describedby={getFieldDescribedById(name)}
        aria-invalid={touched && !isEmpty(error)}
      />
    </FormikFieldWrapper>
  );
};

export default FormikTextarea;
