import { Field, useField, FieldHookConfig } from 'formik';
import React, { useEffect, useState } from 'react';

import { FormFieldColorVariant } from '../../../entities/form';
import { isEmpty, getFieldDescribedById } from '../../../utils';
import { FormikFieldWrapper } from './formikWrappers';

type Props = FieldHookConfig<string> & {
  readonly autoComplete?: 'on' | 'off';
  readonly disabled?: boolean;
  readonly label?: string;
  readonly maxLength?: number;
  readonly minLength?: number;
  readonly max?: number;
  readonly min?: number;
  readonly name: string;
  readonly placeholder?: string;
  readonly prependVal?: string;
  readonly helperText?: string;
  readonly readOnly?: boolean;
  readonly required?: boolean;
  readonly type?: string;
  readonly reserveSpaceBelow?: boolean;
  readonly colorVariant?: FormFieldColorVariant;
  readonly onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

/**
 * todo #182882288 TBI 3.0 [Spencer] create price form field that formats automatically (i.e. 1234567 becomes 1,234,567)
 */
const FormikTextField = (props: Props) => {
  const {
    autoComplete,
    disabled,
    label,
    maxLength,
    minLength,
    max,
    min,
    name,
    placeholder,
    prependVal,
    readOnly,
    required,
    type,
    helperText,
    reserveSpaceBelow,
    colorVariant = 'default'
  } = props;
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  const [fieldClass, setFieldClass] = useState('');
  const [prependClass, setPrependClass] = useState('');

  useEffect(() => {
    let fieldClass = 'relative flex-1 form-input';
    if (touched && !isEmpty(error)) {
      fieldClass += ' input-invalid';
    }

    if (!isEmpty(prependVal)) {
      fieldClass += ' rounded-l-none border-l-0';
      let prependClass = 'form-input-prepend';

      if (touched && !isEmpty(error)) {
        prependClass += ' input-invalid';
      }
      setPrependClass(prependClass);
    }

    setFieldClass(fieldClass);
  }, [touched, error, prependVal]);

  return (
    <FormikFieldWrapper
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      disabled={disabled}
      touched={touched}
      error={error}
      reserveSpaceBelow={reserveSpaceBelow}
    >
      {!isEmpty(prependVal) && <div className={prependClass}>{prependVal}</div>}
      <Field
        className={`${fieldClass} ${colorVariant === 'white' ? 'form-input-white' : ''}`}
        {...field}
        autoComplete={autoComplete}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        max={max}
        min={min}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        aria-describedby={getFieldDescribedById(name)}
        aria-invalid={touched && !isEmpty(error)}
        onKeyDown={props.onKeyDown}
      />
    </FormikFieldWrapper>
  );
};

export default FormikTextField;
