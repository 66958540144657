/**
 * Parses a comma-separated string of numbers into a sorted array of numbers.
 * Example: '1,2,3' => [1, 2, 3]
 */
export const parseStringOfNums = (arrStr: string): number[] => {
  if (arrStr === '') {
    return [];
  }

  const set = new Set<number>();
  for (const str of arrStr.split(',')) {
    const num = Number.parseInt(str);

    if (Number.isNaN(num)) {
      console.error(`Failed to parse number from string: ${str}`);
      continue;
    }

    set.add(num);
  }

  return Array.from(set).sort((a, b) => a - b);
};

/**
 * Converts an array of numbers into a comma-separated string.
 * Example: [1, 2, 3] => '1,2,3'
 */
export const stringifyArrayOfNums = (arr: number[]): string => {
  if (arr.length === 0) return '';
  return [...new Set(arr)].sort((a, b) => a - b).join(',');
};
