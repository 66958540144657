import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { toMakeModelString } from '@/src/models/utils';
import { searchModels } from '@/src/models/thunks';
import routes from '@/src/routes';
import { useAppDispatch } from '@/src/store';
import { Autocomplete, TextField, InputAdornment, Popper } from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useState, useEffect, ChangeEventHandler } from 'react';
import { colors } from '@/src/design/designConstants';
import { toRecord } from '@/src/state/services';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { SearchFlowType, SearchParams } from '@/src/marketplace/search/types';
import { unreachable } from '@/src/utils/typeSafe';
import { FS__SearchResultClickType } from '@/src/hooks/useFullStory/types';

enum GROUP {
  'MODELS' = 'MODELS',
  'CATEGORIES' = 'CATEGORIES'
}
interface Option {
  id: number;
  label: string;
  group: GROUP;
}

const _isOption = (value: any): value is Option => {
  return typeof value === 'object' && 'id' in value && 'label' in value;
};

const CustomPopper = (props: any) => {
  return <Popper {...props} sx={{ minWidth: 'fit-content' }} />;
};

export const SearchBar: FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [loading, setLoading] = useState(false);
  const { fullStoryEvent } = useFullStory();

  // handles populating results
  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else if (query) {
      _searchSuggestions(query);
    }
  }, [open]);

  useEffect(() => {
    setQuery(router.query.q === undefined ? '' : String(router.query.q));
  }, [router.query.q]);

  const _searchSuggestions = async (value: string) => {
    !options.length && setLoading(true);
    const searchResults = await dispatch(searchModels(value));

    const modelOptions = searchResults.map((model) => ({ id: model.id, group: GROUP.MODELS, label: toMakeModelString(model) }));
    const uniqueCategories = toRecord(searchResults.map((model) => model.category));
    const uniqueCategoryOptions = Object.values(uniqueCategories).map((category) => ({
      id: category.id,
      group: GROUP.CATEGORIES,
      label: category.name
    }));

    setOptions([...modelOptions, ...uniqueCategoryOptions]);
    setLoading(false);
    setOpen(true);
  };

  const _navigate2SearchPage = (searchParams: SearchParams) => {
    setOpen(false);
    router.push(routes.search(searchParams));
  };

  const _handleSearchQuery = (event: any) => {
    _navigate2SearchPage({ q: event?.target?.value || '', search_flow_type: SearchFlowType.PlainText });
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setQuery(event.target.value);
    _searchSuggestions(event.target.value);
  };

  const handleSubmit = (event: any, newValue: NonNullable<string | Option>) => {
    if (_isOption(newValue)) {
      handleDropdownOptionSelect(newValue);
    } else {
      // They did not select a dropdown option
      _handleSearchQuery(event);
    }
  };

  const handleDropdownOptionSelect = (newValue: Option) => {
    let searchParams: SearchParams = { q: '', search_flow_type: SearchFlowType.PlainText };
    let clickType = FS__SearchResultClickType.Category;

    switch (newValue.group) {
      case GROUP.CATEGORIES:
        searchParams = { category_id: newValue.id, search_flow_type: SearchFlowType.Category };
        clickType = FS__SearchResultClickType.Category;
        break;
      case GROUP.MODELS:
        searchParams = { model_id: [newValue.id], search_flow_type: SearchFlowType.Model };
        clickType = FS__SearchResultClickType.Model;
        break;
      default:
        unreachable(newValue.group);
        break;
    }

    fullStoryEvent(CustomEvents.search.searchResultClick, {
      type: clickType,
      id: newValue.id,
      name: newValue.label
    });
    setQuery('');
    setOptions([]);
    _navigate2SearchPage(searchParams);
  };

  const handleSearchByEnter = (event: any) => {
    if (event.key === 'Enter') {
      !selectedOption && _handleSearchQuery(event);
    }
  };

  return (
    <Autocomplete
      PopperComponent={CustomPopper}
      disableClearable
      onHighlightChange={(_, option) => {
        setSelectedOption(option);
      }}
      freeSolo
      options={options}
      getOptionLabel={(option) => (option as Option)?.label || query}
      open={open}
      groupBy={(option) => option.group}
      filterOptions={(x) => x} // needed to prevent filtering the results by autocompletes logic
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onKeyDown={handleSearchByEnter}
      value={query}
      onChange={handleSubmit}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          id="searchbar-header"
          onChange={handleSearchChange}
          value={query}
          placeholder="Search Equipment"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <FAIcon icon={icons.search} color={colors.smokeyGray[400]} />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};
