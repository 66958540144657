import { type ChangeEventHandler, type ClipboardEventHandler, type FC, type KeyboardEvent } from 'react';
import { HelperTextForInput } from '../../helperText/helperTextForInput';
import { FieldLabel } from '../fieldLabel';
import { StyledTextInput } from '../styledTextInput';
import { MUIIcon } from '../../../../entities/common';
import { InputAdornment } from './inputAdornment';
import { shouldShowFieldError } from '../../utils';

export interface SharedInputFieldProps {
  readonly value?: string | number;
  readonly defaultValue?: string | number;
  readonly label?: string;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly hasErrored?: boolean;
  readonly touched?: boolean;
  readonly errorMessage?: string;
  readonly onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly autoFocus?: boolean;
  readonly onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  readonly onBlur?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  readonly size?: 'small' | 'medium';
  readonly onKeyPress?: (e: KeyboardEvent<HTMLDivElement>) => void;
  readonly onPaste?: ClipboardEventHandler<HTMLDivElement>;
  readonly endAdornmentIconData?: {
    readonly IconComponent: MUIIcon;
    readonly colorVariant: 'gray';
  };
  readonly submitted?: boolean;
  readonly validateOnTouchOrSubmit?: boolean;
}

export interface InputFieldProps extends SharedInputFieldProps {
  readonly type: 'text' | 'number' | 'email';
}

/**
 * @deprecated Use inputs from src/design/bytes or directly from MUI
 */
export const InputField: FC<InputFieldProps> = (props) => {
  const {
    value,
    defaultValue,
    label,
    placeholder,
    helperText,
    required,
    disabled,
    hasErrored,
    touched,
    errorMessage,
    type,
    onChange,
    onBlur,
    onPaste,
    size,
    onKeyPress,
    autoFocus,
    endAdornmentIconData,
    submitted,
    validateOnTouchOrSubmit
  } = props;

  const handleBlur: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    onBlur?.(e);
  };

  const shouldShowError = shouldShowFieldError(hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit);

  return (
    <FieldLabel
      label={label}
      required={required}
      hasErrored={hasErrored}
      disabled={disabled}
      touched={touched}
      submitted={submitted}
      validateOnTouchOrSubmit={validateOnTouchOrSubmit}
    >
      <StyledTextInput
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        helperText={
          <HelperTextForInput
            helperText={helperText}
            hasErrored={hasErrored}
            errorMessage={errorMessage}
            disabled={disabled}
            touched={touched}
            submitted={submitted}
            validateOnTouchOrSubmit={validateOnTouchOrSubmit}
          />
        }
        disabled={disabled}
        error={shouldShowError}
        type={type}
        size={size}
        autoFocus={autoFocus}
        onKeyPress={(e) => onKeyPress && onKeyPress(e)}
        fullWidth
        onChange={onChange}
        onBlur={handleBlur}
        onPaste={onPaste}
        InputProps={{
          endAdornment: endAdornmentIconData && (
            <InputAdornment
              IconComponent={endAdornmentIconData.IconComponent}
              colorVariant={endAdornmentIconData.colorVariant}
              position="end"
            />
          )
        }}
      />
    </FieldLabel>
  );
};
