import { FC, useState } from 'react';
import * as yup from 'yup';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useLocalizer } from '@/src/localization';
import { Box } from '@mui/material';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { ERROR_MESSAGES } from '@/src/constants';
import { getInquiryReasonValuesSchema } from '@/src/validationSchemas/lead';
import { useAppDispatch } from '@/src/store';
import { closeModal } from '@/src/state/globalModal/slice';
import { ContactSalesFormValues, ContactSalesPossibleSteps, ContactSalesSteps } from '../../forms/contactSalesForm/types';
import { defaultContactSalesFormValues, isContactSalesFormSubmitDisabled } from '../../forms/contactSalesForm/helpers';
import { ContactSalesForm } from '../../forms/contactSalesForm';
import { ContactSalesCTAName, createLeadFromContactSales } from '@/src/state/leads/thunks';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';

interface UserContactSalesModalProps {}

export const UserContactSalesModal: FC<UserContactSalesModalProps> = () => {
  const loc = useLocalizer();
  const appDispatch = useAppDispatch();
  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState<ContactSalesPossibleSteps>(ContactSalesSteps.CONTACT_SALES);

  const initialValues: ContactSalesFormValues = defaultContactSalesFormValues;
  const formValidationHook = useFormValidation(
    initialValues,
    yup.object({
      inquiry_reason_values: getInquiryReasonValuesSchema(),
      notes: yup.string()
    })
  );
  const { formValues } = formValidationHook;
  const { fullStoryEvent } = useFullStory();

  const getActionSubmit = () => {
    return async () => {
      if (!(await formValidationHook.validateForm())) {
        openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
        setCurrentStep(ContactSalesSteps.CONTACT_INFORMATION);
        return;
      }
      fullStoryEvent(CustomEvents.forms.submit, { type: 'ContactSales Generic' });

      return appDispatch(
        createLeadFromContactSales(
          {
            ...formValues,
            from_url: window.location.href,
            form_name: ContactSalesCTAName.NAME
          },
          loc
        )
      ).then(() => {
        appDispatch(closeModal());
      });
    };
  };

  const getActionDisabled = () => {
    return isContactSalesFormSubmitDisabled(formValues);
  };

  return (
    <WorkflowModal
      // TODO: Translations #185801497 - Contact Sales
      title="Contact Sales"
      modalOpen
      closeModal={() => appDispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      actionButtonContent={loc.Forms.Submit}
      actionButtonSubmit={getActionSubmit()}
      actionButtonDisabled={getActionDisabled()}
      displayBackButton={false}
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Box minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        <ContactSalesForm formValidationHook={formValidationHook} />
      </Box>
    </WorkflowModal>
  );
};
