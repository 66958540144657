import { getDropdownOptionsFromMap } from '../../bits/forms/fields/dropdowns/helpers';
import { StatusValue } from '../../entities/common';
import { useLocalizer } from '../../localization';

export const listingWaferSizeData = new Map([
  [2, '2"/50mm'],
  [3, '3"/75mm'],
  [4, '4"/100mm'],
  [5, '5"/125mm'],
  [6, '6"/150mm'],
  [8, '8"/200mm'],
  [12, '12"/300mm'],
  [15, '15"/450mm']
]);
export const LISTING_WAFER_SIZES_OPTIONS = getDropdownOptionsFromMap(listingWaferSizeData);

//TODO: #185418753 - Remove redundant constants
// ***** constants, enums, and interfaces *****
export const listingConditionNameData: Map<StatusValue, string> = new Map([
  [1, 'Used'],
  [2, 'Refurbished'],
  [3, 'New'],
  [4, 'Parts Tool']
]);

export const LISTING_CONDITION_OPTIONS = getDropdownOptionsFromMap(listingConditionNameData);

export const listingDefaultCondition = 1;
export const listingDefaultConditionName = 'Used';

export const useListingDisabledData = () => {
  const loc = useLocalizer();

  return new Map([
    [0, loc.Listing.Listed],
    [1, loc.Listing.Unlisted]
  ]);
};

/**
 *
 */
const listingVintageMinYear = 1965;
const listingVintageMaxYear = new Date().getFullYear();
const listingVintageOptions: { value: number; label: string }[] = [
  { value: listingVintageMaxYear, label: listingVintageMaxYear.toString() }
];
for (const option of listingVintageOptions) {
  const previousYear = option.value - 1;
  if (previousYear >= listingVintageMinYear) {
    listingVintageOptions.push({ value: previousYear, label: previousYear.toString() });
  }
}

export { listingVintageOptions };

export enum ListingSearchSort {
  VERIFIED = 'verified',
  VINTAGE = 'vintage',
  PHOTOS = 'photos'
}
export type ListingSearchSortValue = (typeof ListingSearchSort)[keyof typeof ListingSearchSort];
export const DefaultListingSearchSortValue = ListingSearchSort.PHOTOS;

export type UserListingsSortValue = 'newest' | 'oldest' | 'price_hl' | 'price_lh' | 'location_az' | 'location_za';

export type ListingConditionSortValue = 'Used' | 'Refurbished' | 'New' | 'PartsTool';
