import { tryParseJSONObject } from '.';
import { getMoovCoWebEnv } from '../constants/app';
import {
  COOKIE_CONSENT_ACTION_TAKEN,
  COOKIE_CONSENT_LIFESPAN,
  COOKIE_PREFS_ANALYTICS,
  COOKIE_PREFS_ESSENTIAL,
  COOKIE_PREFS_FUNCTIONAL,
  COOKIE_PREFS_NAME,
  COOKIE_MARKETING_CONTENT_LIFESPAN,
  COOKIE_MARKETING_CONTENT
} from '../constants/cookie';

export type CookieData = [string, string | number | boolean];

/**
 * Prefix cookie keys with `_moov`.
 * Can only be called from the browser context.
 */
export function setCookie(doc: Document, [key, value]: CookieData, daysToExpire = 30, domain?: string): void {
  if (key.length === 0) {
    return;
  }

  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = date.toUTCString();

  const cookie = `${key}=${value}; expires=${expires}; path=/; SameSite=Lax`;
  doc.cookie = domain ? `${cookie}; domain=${domain}` : cookie;
}

/**
 * Can only be called from the browser context.
 * Will return `null` if the cookie was never set of if the cookie value is an empty string.
 */
export function getCookie(doc: Document, key: string): string | null {
  for (const cookie of doc.cookie.split(';')) {
    const [cookieKey, cookieValue] = cookie.trim().split('=');
    if (cookieKey === key && cookieValue.length !== 0) {
      return cookieValue;
    }
  }

  return null;
}

/**
 * Can only be called from the browser context.
 */
export function clearCookie(doc: Document, key: string): void {
  setCookie(doc, [key, ''], -1);
}

/**
 *
 */
export function resetAllCookies(doc: Document): void {
  const cookies = doc.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    doc.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

// -- #cookie consent
// ----------------------------

export interface CookiePreferences {
  [COOKIE_PREFS_ESSENTIAL]: 1;
  [COOKIE_PREFS_FUNCTIONAL]: 1 | 0;
  [COOKIE_PREFS_ANALYTICS]: 1 | 0;
}

/**
 * Will return 'null' if cookie preferences do not exist or if there was an error in parsing the cookie value.
 * Can only be called from the browser context.
 */
export function getCookiePrefs(doc: Document): CookiePreferences | null {
  const prefsJSONstr = getCookie(doc, COOKIE_PREFS_NAME);

  if (prefsJSONstr !== null) {
    const parsedPrefs = tryParseJSONObject(prefsJSONstr);

    if (parsedPrefs !== null) {
      return parsedPrefs as CookiePreferences;
    }
  }

  return null;
}

/**
 * Can only be called from the browser context.
 */
export function setCookiePrefs(doc: Document, values: CookiePreferences): void {
  setCookie(doc, [COOKIE_PREFS_NAME, JSON.stringify(values)], COOKIE_CONSENT_LIFESPAN);
}

/**
 * Can only be called from the browser context.
 */
export function markCookieActionTaken(doc: Document): void {
  setCookie(doc, [COOKIE_CONSENT_ACTION_TAKEN, 1], COOKIE_CONSENT_LIFESPAN);
}

/**
 * Check if the user has consented to a specific cookie category.
 * Can only be called from the browser context.
 */
export function checkConsent(doc: Document, key: keyof CookiePreferences): boolean {
  const cookiePrefs = getCookiePrefs(doc);
  return cookiePrefs !== null ? cookiePrefs[key] === 1 : false;
}

// -- #cookie marketing content
// ----------------------------

export interface MarketingContent {
  [key: string]: string;
}

/**
 * Can only be called from the browser context.
 */
export function setCookieMarketingContent(doc: Document, values: { [key: string]: string }): void {
  setCookie(doc, [COOKIE_MARKETING_CONTENT, JSON.stringify(values)], COOKIE_MARKETING_CONTENT_LIFESPAN);
}

/**
 * Will return 'null' if cookie preferences do not exist or if there was an error in parsing the cookie value.
 * Can only be called from the browser context.
 */
export function isMarketingContent(obj: unknown): obj is MarketingContent {
  return (
    !!obj &&
    typeof obj === 'object' &&
    !Array.isArray(obj) &&
    Object.keys(obj).every((key) => key.includes('utm')) &&
    Object.values(obj).every((value) => typeof value === 'string')
  );
}

export function getCookieMarketingContent(doc: Document): MarketingContent | undefined {
  const marketingContentJSONStr = getCookie(doc, COOKIE_MARKETING_CONTENT);

  if (marketingContentJSONStr !== null) {
    const parsedPrefs = tryParseJSONObject(marketingContentJSONStr);
    if (isMarketingContent(parsedPrefs)) {
      return parsedPrefs;
    }
  }
}

export const getDomain = () => {
  switch (getMoovCoWebEnv()) {
    case 'production':
    case 'stage':
      return '.moov.co';
    case 'test':
    case 'local':
      return 'localhost';
  }
};
