import { ERROR_MESSAGES, MOOV_API_URL } from '@/src/constants';
import { Model } from '@/src/entities/model';
import { FindManyParams } from '@/src/providers/types';
import { AppDispatch, AppState } from '@/src/store';
import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { openError } from '../banner/slice';
import { loadModelsForFilters, loadModels } from './slice';
import { getModelsById } from './selectors';

export interface FetchModelsForFiltersParams extends FindManyParams {
  category_id?: number | number[];
  make_id?: number | number[];
}

export const fetchModelsForFilters = (params?: FetchModelsForFiltersParams) => {
  return async (dispatch: AppDispatch, _getState: () => AppState): Promise<void> => {
    return axios
      .get<{ data: Model[] }>(`${MOOV_API_URL}/models`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadModelsForFilters(resp.data.data));
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};

export interface GetOrFetchModelsParams extends FindManyParams {
  id: number | number[];
}
export const getOrFetchModels = (params?: GetOrFetchModelsParams) => {
  return async (dispatch: AppDispatch, getState: () => AppState): Promise<Model[] | void> => {
    if (params?.id) {
      const ids = Array.isArray(params.id) ? params.id : [params.id];
      const models = getModelsById(getState(), ids);

      if (models.every((model) => model !== undefined)) {
        return models;
      }
    }

    return axios
      .get<{ data: Model[] }>(`${MOOV_API_URL}/models`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params
      })
      .then((resp) => {
        dispatch(loadModels(resp.data.data));
        return resp.data.data;
      })
      .catch((error) => {
        captureException(error);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
};
