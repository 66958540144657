import { ListItemText, MenuItem, Select, SelectProps } from '@mui/material';

export interface SelectWithOptionsDropdownOption<T extends string = string> {
  id: T;
  label: string | JSX.Element;
  disabled?: boolean;
}

export type SelectWithOptionsProps<T extends string = string> = SelectProps<T> & {
  options: SelectWithOptionsDropdownOption<T>[];
  value: T;
  className?: string;
  handleChange: (newValue: T) => void;
};

export const SelectWithOptions = function <T extends string = string>({
  options,
  value,
  handleChange,
  ...muiSelectProps
}: SelectWithOptionsProps<T>): JSX.Element {
  const onChange: SelectWithOptionsProps<T>['onChange'] = (event) => {
    handleChange(event.target.value as T); // just ignore the `string` case
  };

  return (
    <Select fullWidth value={value} onChange={onChange} size="small" data-testid={'Select'} {...muiSelectProps}>
      {options.map(({ id, label, disabled }) => {
        return (
          <MenuItem disabled={disabled} value={id} key={id}>
            <ListItemText primary={label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
