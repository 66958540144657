import { MagiqPageType } from '../entities/magiq';

export const EXTERNAL_URLS = {
  // MOOV SOCIAL MEDIA
  MOOV_FACEBOOK: 'https://www.facebook.com/moovtechnologies/',
  MOOV_LINKEDIN: 'https://www.linkedin.com/company/moov-technologies-inc/about/',
  MOOV_TWITTER: 'https://twitter.com/moovco',
  MOOV_GLASSDOOR: 'https://www.glassdoor.com/Overview/Working-at-Moov-Technologies-EI_IE3063911.11,28.htm',

  // INTRO VIDEOS
  INTRO_VIDEO_IN_ENGLISH_URL: 'https://player.vimeo.com/video/871599146?autoplay=1',
  INTRO_VIDEO_IN_TAIWAN_URL: 'https://player.vimeo.com/video/430885486?autoplay=1',
  INTRO_VIDEO_IN_CHINESE_URL: 'https://player.bilibili.com/player.html?aid=1802184942&bvid=BV1wt421V78A&cid=1477499803&p=1',

  // MISC
  MOOV_CAREERS: 'https://boards.greenhouse.io/moovtechnologiesinc',
  MOOV_IQ_URL: process.env.NEXT_PUBLIC_MOOV_IQ_WEB_URL
};

export const MOOV_API_URL = process.env.NEXT_PUBLIC_MOOV_CO_API_URL;

export const OWNED_MAGIQ_LINKS = [MagiqPageType.OPPORTUNITY];
