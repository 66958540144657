import { FC, useState } from 'react';
import * as yup from 'yup';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useLocalizer } from '@/src/localization';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { GlobalModalTypes, closeModal, openModal } from '@/src/state/globalModal/slice';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { CreateAccountFormValues } from './types';
import { getPasswordSchema, getToSSchema } from '@/src/validationSchemas/user';
import { getModalState } from '@/src/state/globalModal/selectors';
import { createUser } from '@/src/state/user/thunks';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { colors, spacing } from '@/src/design/designConstants';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import routes from '@/src/routes';
import { useUser } from '@/src/hooks/useUser';
import { TurnstileDiv } from '@/src/registerUser/turnstileDiv';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';

interface CreateAccountModalProps {}

export const CreateAccountModal: FC<CreateAccountModalProps> = () => {
  const loc = useLocalizer();
  const dispatch = useAppDispatch();
  const { loginUser } = useUser();
  const { modalProps } = useAppSelector(getModalState);

  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState(1);

  const initialValues: CreateAccountFormValues = {
    password: '',
    tos: false,
    turnstile_data: '',
    is_opt_in: 1
  };
  const formValidationHook = useFormValidation(
    initialValues,
    yup.object({
      password: getPasswordSchema(loc),
      tos: getToSSchema(loc),
      turnstile_data: yup.string(),
      is_opt_in: yup.number()
    })
  );
  const { formValues, isFormValid, setFieldValue, setFieldError, onFieldBlur, isFieldErrored } = formValidationHook;

  const leadInfo = modalProps.createAccount?.leadInfo;
  if (!leadInfo) {
    return null;
  }

  const onSubmit = async () => {
    if (!(await formValidationHook.validateForm())) {
      openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
      return;
    }

    return dispatch(
      createUser({
        password: formValues.password,
        turnstile_data: formValues.turnstile_data,
        is_opt_in: formValues.is_opt_in,
        ...leadInfo
      })
    )
      .then(async () => {
        return loginUser({ email: leadInfo.email, password: formValues.password }).finally(async () => dispatch(closeModal()));
      })
      .catch((e) => {
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
        captureException(e);
      });
  };

  const openLoginModal = () => {
    dispatch(
      openModal({
        modalType: GlobalModalTypes.LOGIN,
        modalProps: {}
      })
    );
  };

  return (
    <WorkflowModal
      // TODO: Translations - Create Your Account
      title="Create Your Account"
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      // TODO: Translations - Skip for now
      dismissButtonContent={'Skip for now'}
      dismissButtonSubmit={async () => {
        dispatch(closeModal());
      }}
      // TODO: Translations - FINISH CREATING ACCOUNT
      actionButtonContent={'FINISH CREATING ACCOUNT'}
      actionButtonSubmit={onSubmit}
      // Turnstile data is being validated here instead of in the schema because
      // of a race condition between the Turnstile widget and the form validation
      // check on initial render.
      actionButtonDisabled={!isFormValid() || formValues.turnstile_data === ''}
      actionButtonId="create-account-modal-submit"
      dismissButtonId="create-account-modal-skip"
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Box width={{ d: '600px' }}>
        <Box mb={spacing['3xl']}>
          <Typography variant="body1" color={colors.smokeyGray[500]}>
            {/* TODO: Translations - You’re almost there! Save time and get more from Moov by completing account setup. */}
            {`You're`} almost there! Save time and get more from Moov by completing account setup.
            <br />
            <br />
            {/* TODO: Translations - Moov account holders can track and sell equipment, get notified about new tools that match your interests, and track complete
              shipping details. */}
            Moov account holders can track and sell equipment, get notified about new tools that match your interests, and track complete
            shipping details.
          </Typography>
        </Box>

        <form>
          {/* 
            Do not remove this form element. It is used for chromes password manager to detect the password/email field.
            If this is not wrapped in form and has a hidden email input, chrome autofills our search bar with an email.
            The hidden email input with a value of their lead info email so chrome saves the password for the correct email.
          */}
          <Box visibility={'hidden'}>
            <input type="email" value={leadInfo.email} />
          </Box>
          <Stack rowGap={spacing.xl} mb={spacing.xl}>
            <LabeledTextField
              type="password"
              // TODO: Translations - Add your password
              label={'Add your password'}
              placeholder={loc.Forms.Password}
              value={formValues.password}
              onChange={(e) => setFieldValue('password', e.target.value)}
              onBlur={() => onFieldBlur('password')}
              error={isFieldErrored('password')}
              // TODO: Translations - Must contain a capital letter, 1 number or symbol, at least 8 characters
              helperText={'Must contain a capital letter, 1 number or symbol, at least 8 characters'}
              inputProps={{
                'data-testid': 'contact-sales-password'
              }}
            />
            <CheckboxByte
              label={
                <Box>
                  {loc.RegisterUser.HaveReadTermsAndPrivacyPre}
                  <Link href={routes.policyTerms()} target="_blank">
                    {loc.RegisterUser.HaveReadTermsAndPrivacyTerms}
                  </Link>{' '}
                  {loc.RegisterUser.HaveReadTermsAndPrivacyAnd}
                  <Link href={routes.policyPrivacy()} target="_blank">
                    {loc.RegisterUser.HaveReadTermsAndPrivacyPrivacy}
                  </Link>
                  {loc.RegisterUser.HaveReadTermsAndPrivacyPost}
                </Box>
              }
              checked={formValues.tos}
              onChange={(checked) => setFieldValue('tos', checked)}
              data-testid="create-account-tos"
            />
            <CheckboxByte
              label={
                <Typography variant="p14">Stay in the Moov loop! We&apos;ll send you occasional emails (not very frequently).</Typography>
              }
              checked={Boolean(formValues.is_opt_in)}
              onChange={(checked) => setFieldValue('is_opt_in', Boolean(checked) ? 1 : 0)}
              data-testid="create-account-communication-opt-in"
            />
            <TurnstileDiv
              setFieldValue={(_, turnstileMsg) => {
                setFieldValue('turnstile_data', turnstileMsg);
              }}
              setFieldError={(_, errMsg) => {
                setFieldError('turnstile_data', errMsg ?? '');
              }}
            />
          </Stack>
        </form>

        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={spacing.md}>
          <Typography variant="body2">{loc.RegisterUser.AlreadyAccount}</Typography>
          <Button onClick={openLoginModal}>
            <Typography variant="body2">{loc.Common.SignIn}</Typography>
          </Button>
        </Stack>
      </Box>
    </WorkflowModal>
  );
};
