import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toRecord } from '../services';
import { UnconfirmedListing } from '@/src/entities/unconfirmedListing';

export interface LoadingState {
  error: boolean;
  loading: boolean;
  success: boolean;
}

export interface UnconfirmedListingsState {
  byId: Record<string, UnconfirmedListing>;
  hasLoadedOnce: boolean;
  loading: LoadingState;
}

export const initialState: UnconfirmedListingsState = {
  byId: {},
  hasLoadedOnce: false,
  loading: {
    error: false,
    loading: false,
    success: false
  }
};

export const slice = createSlice({
  name: 'unconfirmedListings',
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = {
        error: false,
        loading: true,
        success: false
      };
    },
    load: (state, action: PayloadAction<UnconfirmedListing[]>) => {
      state.byId = toRecord(action.payload);
      state.hasLoadedOnce = true;
      state.loading = {
        error: false,
        loading: false,
        success: true
      };
    },
    reset: () => initialState,
    error: (state) => {
      state.loading = {
        error: true,
        loading: false,
        success: false
      };
    }
  }
});

export const { loading, load, reset, error } = slice.actions;

export default slice.reducer;
