import { FC } from 'react';
import { NavLink } from '../types';
import { NavItemWithoutIcon } from './navItemWithoutIcon';
import { NavItemWithIcon } from './navItemWithIcon';

export interface NavItemProps {
  link: NavLink;
}

export const NavItem: FC<NavItemProps> = ({ link }) => {
  if (link === NavLink.BUY || link === NavLink.SELL) {
    return <NavItemWithoutIcon link={link} />;
  } else {
    return <NavItemWithIcon link={link} />;
  }
};
