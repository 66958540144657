import {
  getDefinitions,
  Locale
} from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class NewsLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get CompanyNews(): string {
    return this.definitions.CompanyNews;
  }

  public get News(): string {
    return this.definitions.News;
  }

  public get NewsImage(): string {
    return this.definitions.NewsImage;
  }

  public get NoNews(): string {
    return this.definitions.NoNews;
  }

  public get WeMoovFast(): string {
    return this.definitions.WeMoovFast;
  }

  public get WhatsNewAtMoov(): string {
    return this.definitions.WhatsNewAtMoov;
  }
}
