import { useEffect } from 'react';
import { setCookieMarketingContent } from '../../utils/cookies';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useUser } from '../useUser';

const getAllMarketingQueryParams = (params: ParsedUrlQuery) => {
  const marketingKeys = Object.keys(params).filter((key) => key.includes('utm_'));

  const marketingParams = marketingKeys.reduce((acc: { [key: string]: string }, key) => {
    const paramValue = params[key];
    if (typeof paramValue === 'string') {
      acc[key] = paramValue;
    }
    return acc;
  }, {});
  return {
    hasMarketingParams: marketingKeys.length,
    marketingParams
  };
};

export const useMarketingParamListener = () => {
  const router = useRouter();
  const { userIsLoggedIn, user } = useUser();
  useEffect(() => {
    if (router.isReady && !userIsLoggedIn(user)) {
      const { marketingParams, hasMarketingParams } = getAllMarketingQueryParams(router.query);
      hasMarketingParams && setCookieMarketingContent(document, marketingParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);
};
