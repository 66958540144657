interface BaseType {
  id: number;
}

/** description: Convert array of T to Record<string, T> */
export function toRecord<T extends BaseType>(arg: T[]): Record<string, T> {
  return arg.reduce((prev: Record<string, T>, cur: T) => {
    prev[cur.id.toString()] = cur;
    return prev;
  }, {});
}
