import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyRemarketingInfo } from './types';

export interface State {
  bySlug: Record<string, CompanyRemarketingInfo>;
  currentSlug: string;
  initialized: boolean;
  loading: boolean;
}

export const initialState: State = {
  bySlug: {},
  currentSlug: '',
  initialized: false,
  loading: false
};

export const slice = createSlice({
  name: 'exclusives',
  initialState,
  reducers: {
    deselect: (state) => {
      state.currentSlug = '';
    },
    load: (state, action: PayloadAction<CompanyRemarketingInfo[]>) => {
      const newById = action.payload.reduce((acc: { [key: string]: CompanyRemarketingInfo }, curr) => {
        acc[curr.remarketing_slug] = curr;
        return acc;
      }, {});
      state.bySlug = { ...state.bySlug, ...newById };
    },
    reset: () => initialState,
    select: (state, action: PayloadAction<string>) => {
      state.currentSlug = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (!state.initialized && !state.loading) state.initialized = true;
    }
  }
});

export const { deselect, load, reset, select, setLoading } = slice.actions;

export default slice.reducer;
