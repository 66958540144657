import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { CheckBoxFieldCheckedValue, CheckboxValue, getNewCheckboxValue } from '../formikFields/formikCheckBoxField';

interface ToggleSwitchFieldProps {
  readonly value: CheckboxValue;
  readonly checkedValue: CheckBoxFieldCheckedValue;
  readonly disabled?: boolean;
  readonly onChange: (value: CheckboxValue) => void;
  readonly ariaLabel: string;
}

/**
 *
 */
export const ToggleSwitchCheckboxUI: FC<{ readonly checked: boolean }> = ({ checked }) => (
  <div
    className={`peer-focus-visible:default-focus-outline relative h-8 w-16 rounded-full transition-all duration-300 ease-out ${
      checked ? 'bg-moov-blue-500' : 'bg-gray-400'
    }`}
  >
    <div
      className={`absolute left-1 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-smokey-gray-50 transition-[transform] duration-300 ease-in-out ${
        checked ? 'translate-x-8' : 'translate-x-0'
      }`}
    />
  </div>
);

/**
 *
 */
export const ToggleSwitchField = (props: ToggleSwitchFieldProps) => {
  const { value, checkedValue, disabled, onChange, ariaLabel } = props;
  const [checked, setChecked] = useState(value === checkedValue);

  useEffect(() => {
    setChecked(value === checkedValue);
  }, [value, checkedValue]);

  const handleChange: ChangeEventHandler = () => {
    onChange(getNewCheckboxValue(checkedValue, checked));
  };

  return (
    <label
      className={`group relative block w-fit ${disabled ? 'form-group-disabled' : 'cursor-pointer'}`}
      aria-label={ariaLabel}
      data-testid="ToggleSwitchField"
    >
      {/* ACTUAL CHECKBOX ELEMENT */}
      <input type="checkbox" className="peer absolute h-0 w-0 opacity-0" onChange={handleChange} disabled={disabled} checked={checked} />

      {/* CHECKBOX UI */}
      <ToggleSwitchCheckboxUI checked={checked} />
    </label>
  );
};
