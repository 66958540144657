import type { FC, ReactElement } from 'react';
import { fontFamily } from '../../designConstants';

import { Tab, Tabs as TabsBit } from '../../bits/tabs';
import { DarkDivider } from '../divider';

function a11yProps(id: string) {
  return {
    id: `simple-tab-${id}`,
    'aria-controls': `simple-tabpanel-${id}`
  };
}

type tabReqs = {
  label: string;
  tabContent: ReactElement<any, any>;
  disabled?: boolean;
};

type tabList = { [id: string]: tabReqs };

interface TabsProps {
  tabs: tabList;
  selectedTabIndex: number;
  onChange: (newSelectedTabIndex: number) => void;
  variant?: 'fullWidth' | 'scrollable' | undefined;
}

export const Tabs: FC<TabsProps> = ({ tabs, selectedTabIndex, onChange, variant }) => {
  const handleChange = (_event: React.SyntheticEvent, newSelectedTabIndex: number) => {
    onChange(newSelectedTabIndex);
  };

  return (
    <>
      <TabsBit value={selectedTabIndex} onChange={handleChange} aria-label="basic tabs example" variant={variant}>
        {Object.getOwnPropertyNames(tabs).map((tabId) => {
          const { label, disabled = false } = tabs[tabId];
          return <Tab key={label} label={label} {...a11yProps(tabId)} disabled={disabled} sx={{ fontFamily: fontFamily.inter }} />;
        })}
      </TabsBit>
      <DarkDivider />
      {Object.getOwnPropertyNames(tabs).map((tabId, index) => {
        const { label, tabContent } = tabs[tabId];
        return (
          selectedTabIndex === index && (
            <div role="tabpanel" key={label} id={`simple-tabpanel-${tabId}`} aria-labelledby={`simple-tab-${tabId}`}>
              {tabContent}
            </div>
          )
        );
      })}
    </>
  );
};
