import { FC, useState } from 'react';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { Stack, Typography } from '@mui/material';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { closeModal } from '@/src/state/globalModal/slice';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { getModalState } from '@/src/state/globalModal/selectors';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { spacing } from '@/src/design/designConstants';
import { captureException } from '@sentry/nextjs';
import { useForm } from 'react-hook-form';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { RequiredLabel } from '@/src/design/bits/requiredLabel';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { sellerCounterOffer } from '@/src/offers/thunks';

const joiSchema = joi.object({
  offer_amount: joi.number().min(1).required(),
  offer_note: joi.string().allow(''),
  terms_and_conditions: joi.boolean().valid(true).required()
});

export const SellerCounterOfferModal: FC = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector(getModalState);
  const { fullStoryAndGTMEvent } = useFullStory();
  const { openErrorAlert } = useGlobalAlertBanner();
  const [currentStep, setCurrentStep] = useState(1);

  const defaultValues = {
    offer_note: '',
    offer_amount: 0,
    terms_and_conditions: false
  };
  const hookForm = useForm<{ offer_note: string; offer_amount: number; terms_and_conditions: boolean }>({
    defaultValues,
    mode: 'onChange',
    resolver: joiResolver(joiSchema)
  });
  hookForm.watch(['offer_note', 'offer_amount', 'terms_and_conditions']);

  const { offerKey, listingKey } = modalProps.sellerCounterOffer ?? {};
  if (!offerKey || !listingKey) {
    return null;
  }
  const offerAmount = hookForm.getValues('offer_amount');

  return (
    <WorkflowModal
      // TODO: Translations - Make Counter Offer
      title="Make Counter Offer"
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      // TODO: Translations - CANCEL
      dismissButtonContent={'CANCEL'}
      dismissButtonSubmit={async () => {
        dispatch(closeModal());
      }}
      // TODO: Translations - SUBMIT
      actionButtonContent={'SUBMIT'}
      actionButtonSubmit={async () => {
        fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
          type: OfferCTATypes.ConfirmCounterOffer,
          offerKey: offerKey,
          listingKey: listingKey
        });
        return dispatch(
          sellerCounterOffer({
            offerKey,
            body: { offer_amount: hookForm.getValues('offer_amount'), offer_note: hookForm.getValues('offer_note') }
          })
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            captureException(e);
            openErrorAlert(ERROR_MESSAGES.REFRESH);
          });
      }}
      actionButtonDisabled={!hookForm.formState.isValid}
      actionButtonId="decline-offer-modal-submit"
      dismissButtonId="decline-offer-modal-skip"
    >
      <Stack sx={{ width: { d: '600px' } }} spacing={spacing['3xl']}>
        <LabeledCurrencyInput
          label={
            <Stack direction="row" alignItems="center">
              <Typography variant="p14">
                <strong>Offer Amount</strong>&nbsp;
              </Typography>
              <RequiredLabel />
            </Stack>
          }
          value={offerAmount ? offerAmount.toString() : ''}
          onChange={(e) => {
            hookForm.setValue('offer_amount', Number(e.target.value));
            hookForm.trigger('offer_amount');
          }}
          inputProps={{ 'data-testid': 'seller-counter-offer-offer_amount-input' }}
        />
        <LabeledTextField
          label={
            <Typography variant="p14">
              <strong>Note</strong>&nbsp;(optional)
            </Typography>
          }
          onChange={(e) => {
            hookForm.setValue('offer_note', e.target.value);
          }}
          inputProps={{ 'data-testid': 'seller-counter-offer-offer_note-input' }}
          value={hookForm.getValues('offer_note')}
        />

        <CheckboxByte
          label={
            // TODO: translations
            <Typography variant="p14" fontWeight={500}>
              I acknowledge that by clicking submit you are agreeing to sell this item if your offer is accepted. The buyer may negotiate
              payment terms upon acceptance of the offer before final invoicing is issued.
            </Typography>
          }
          onChange={(checked) => {
            hookForm.setValue('terms_and_conditions', checked);
            hookForm.trigger('terms_and_conditions');
          }}
          checked={hookForm.getValues('terms_and_conditions')}
          data-testid="seller-counter-offer-terms_and_conditions-checkbox"
        />
      </Stack>
    </WorkflowModal>
  );
};
