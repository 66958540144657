import MobileSuggestedItems from './mobileSuggestedItems';
import { ModalTypes } from './types';

export const findModal = ({ modalType }: { modalType: ModalTypes }) => {
  switch (modalType) {
    case ModalTypes.SuggestedItems:
      return MobileSuggestedItems;
    case ModalTypes.Empty:
    default:
      // eslint-disable-next-line react/display-name
      return () => <></>;
  }
};
