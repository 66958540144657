import { TextFieldVariants, TextFieldProps, TextField, InputAdornment } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export type CurrencyInputProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> & {
  value: string;
};

export const CurrencyInput = function <Variant extends TextFieldVariants = TextFieldVariants>(props: CurrencyInputProps<Variant>) {
  return (
    <TextField
      {...{
        ...props,
        inputProps: {
          ...props.inputProps
        },
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputComponent: NumericFormatForMUI as any,
          ...props.InputProps
        }
      }}
    />
  );
};

interface NumericFormatForMUIProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  thousandSeparator?: boolean;
  valueIsNumericString?: boolean;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  decimalScale?: number;
}
/**
 * This is what is used by the offical MUI docs to showcase custom formatting
 * using third party libraries.
 * https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
 */
const NumericFormatForMUI = forwardRef<NumericFormatProps, NumericFormatForMUIProps>(function NumericFormatForMUI(props, ref) {
  const {
    onChange,
    thousandSeparator = true,
    valueIsNumericString = true,
    allowNegative = false,
    allowLeadingZeros = false,
    decimalScale = 0,
    ...other
  } = props;

  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={thousandSeparator}
      valueIsNumericString={valueIsNumericString}
      allowNegative={allowNegative}
      allowLeadingZeros={allowLeadingZeros}
      decimalScale={decimalScale}
      {...other}
    />
  );
});
