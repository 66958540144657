import { useRouter } from 'next/router';
import Router from 'next/router';

export enum SideDrawers {
  DesktopMessaging = 'messaging',
  MobileMessaging = 'mobileMessaging',
  Request = 'request',
  Listing = 'listing',
  Equipment = 'equipment',
  OfferFilters = 'offerFilters',
  ExpandedOffer = 'expandedOffer'
}

export const useSideDrawer = (drawer: SideDrawers) => {
  const router = useRouter();
  const isOpen = router.query.drawer === drawer;
  const openDrawer = () => !isOpen && router.replace({ query: { ...router.query, drawer } });
  const closeDrawer = () => {
    // Use Router.query here to always have the updated query
    const query = { ...Router.query };
    delete query.drawer;
    router.replace({ query });
  };

  return {
    drawerOpen: router.query.drawer === drawer,
    openDrawer,
    closeDrawer,
    toggleDrawer: () => (isOpen ? closeDrawer() : openDrawer())
  };
};
