import { getDefinitions, Locale } from '../localization';
import translations from './corp.loc.json';

/**
 *
 */
export class CorpLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get DidYouKnow(): string {
    return this.definitions.DidYouKnow;
  }

  public get ExampleModelCard(): string {
    return this.definitions.ExampleModelCard;
  }

  public get ExploreAddOn(): string {
    return this.definitions.ExploreAddOn;
  }

  public get GoToHome(): string {
    return this.definitions.GoToHome;
  }

  public get MoovEnsuresSeamless(): string {
    return this.definitions.MoovEnsuresSeamless;
  }

  public get PageNotFound(): string {
    return this.definitions.PageNotFound;
  }

  public get PrivacyPolicyDescription(): string {
    return this.definitions.PrivacyPolicyDescription;
  }

  public get ServiceSideError(): string {
    return this.definitions.ServiceSideError;
  }

  public get TermsAndConditionsDescription(): string {
    return this.definitions.TermsAndConditionsDescription;
  }

  public get ThereWasAnError(): string {
    return this.definitions.ThereWasAnError;
  }

  public get ThisPageNotFound(): string {
    return this.definitions.ThisPageNotFound;
  }

  public get FourSquaresArt(): string {
    return this.definitions.FourSquaresArt;
  }
}
