import { UpdateRejectReason } from '../../providers/opportunities/types';
import { SuggestedListing } from '../../entities/listing';
import { User } from '../../entities/user/types';
import { ModalTypes, ModalButtonColors } from '../../globalComponents/mobileSuggestedItemsModal/modals/types';

export interface QuestionsForm {
  questions: string[];
}

export const isQuestionsForm = (arg: unknown): arg is QuestionsForm => {
  return !!arg && typeof arg === 'object' && !!(arg as QuestionsForm).questions;
};

export const isRejectOpportunityForm = (arg: unknown): arg is UpdateRejectReason => {
  return !!arg && typeof arg === 'object' && (!!(arg as UpdateRejectReason).rejectReason || !!(arg as UpdateRejectReason).rejectComments);
};

export type StepFormValues = Record<string, unknown>;

export interface UseOpportunityPayload {
  currentListing: SuggestedListing;
  rejectOpportunity: (onSuccess?: () => void, onFailure?: (e: any) => void) => void;
  approveOpportunity: (onSuccess?: () => void, onFailure?: (e: any) => void) => void;
  isLastOpportunity: () => boolean;
  closeModal: () => void;
  selectNextOpportunity: () => void;
  fetchOpportunities: ({}) => void;
  markCurrentOpportunitySeen: () => void;
  markCurrentOpportunityTouched: () => void;
}

export interface ActionButtonData {
  state: MobileSuggestedItemsModalState;
  useOpportunityPayload: UseOpportunityPayload;
  user: User | undefined | null;
}

export interface IndexKeyAsString {
  [key: string]: unknown;
}

export enum WorkflowButtons {
  DISMISS = 'dismissButton',
  ACTION = 'actionButton'
}

export interface WorkflowModalButton {
  buttonColor: ModalButtonColors;
  buttonContent: JSX.Element | string;
  handleClick: ({ state }: ActionButtonData) => void;
  moovId: string;
  isFormValid?: (step: ModalStep) => boolean;
  submitted?: boolean;
  submitting?: boolean;
  submitFailed?: boolean;
}

export interface ModalStep extends IndexKeyAsString {
  actionButton: WorkflowModalButton;
  dismissButton?: WorkflowModalButton;
  formValues?: StepFormValues;
  error?: any;
}

export interface ModalProps {
  title?: string;
  steps?: ModalStep[];
  previousStep?: number;
}

export interface MobileSuggestedItemsModalState {
  modalOpen: boolean;
  modalType: ModalTypes;
  props: ModalProps;
  currentStep: number;
}
