import { UpdateQuestions } from '../../entities/opportunity';
import { createQuestionsByListingAndUserId, createQuestionsByOpportunityKey } from '../../providers/questions';
import { Listing } from '../../entities/listing';
import { useCaptureExceptionForExternalUsers } from '../useCaptureExceptionForExternalUsers';
import { captureException } from '@sentry/nextjs';
import { ERROR_MESSAGES } from '../../constants';

export const useQuestionsFormSubmit = () => {
  const { captureExceptionForExternalUsers } = useCaptureExceptionForExternalUsers();
  return async (
    values: UpdateQuestions,
    opportunityKey: string | undefined,
    userId: number | undefined,
    listing: Listing,
    setErrorMessage?: (arg: ERROR_MESSAGES) => void,
    onSuccess?: () => void
  ) => {
    const cleanedQuestions = values.questions.map((question) => question.trim());

    try {
      if (opportunityKey) {
        await createQuestionsByOpportunityKey(opportunityKey, cleanedQuestions, listing);
      } else if (listing && userId) {
        await createQuestionsByListingAndUserId(listing, userId, cleanedQuestions);
      } else {
        captureException('Error: Cannot submit questions', {
          contexts: { submitQuestions: { message: 'Cannot submit questions without opportunity key or user id' } }
        });
        setErrorMessage && setErrorMessage(ERROR_MESSAGES.REFRESH);
      }

      onSuccess && onSuccess();
    } catch (err) {
      captureExceptionForExternalUsers(err, { contexts: { submitQuestions: { opportunityKey, listingId: listing.id } } });
      setErrorMessage && setErrorMessage(ERROR_MESSAGES.REFRESH);
    }
  };
};
