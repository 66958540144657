import { generateDTOWantedCategory, generateDTOWantedMake, generateDTOWantedModel } from '../providers/wanteds/helpers';
import { unreachable } from '../utils/typeSafe';
import type { SimpleDateFormat, Slug } from './common';

/**
 * @deprecated refer to src/request2/types.ts
 */
export enum WANTED_PRIORITY {
  AUTOMATED = -1,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

/**
 * @deprecated refer to src/request2/types.ts
 */
export enum CONDITION {
  USED = 1,
  REFURBISHED = 2,
  NEW = 3,
  PARTS_TOOL = 4
}

/**
 * @deprecated refer to src/request2/types.ts
 */
export const condition2String = (condition: CONDITION): string => {
  switch (condition) {
    case CONDITION.USED:
      return 'Used';
    case CONDITION.REFURBISHED:
      return 'Refurbished';
    case CONDITION.NEW:
      return 'New';
    case CONDITION.PARTS_TOOL:
      return 'Parts/Tool';
    default:
      unreachable(condition);
      return 'Unknown';
  }
};

/**
 * @deprecated refer to src/request2/types.ts
 */
export interface Wanted {
  readonly id: number;
  readonly price: number;
  readonly created_at: SimpleDateFormat;
  readonly category: {
    readonly id: number;
    readonly slug: Slug;
    readonly name: string;
    readonly description: string;
  };
  readonly make: {
    readonly id: number;
    readonly slug: Slug;
    readonly name: string;
    readonly description: string;
  };
  readonly model: {
    readonly id: number;
    readonly slug: Slug;
    readonly name: string;
    readonly description: string;
  };
  readonly wafer_size_values: number[];
  priority: WANTED_PRIORITY;
  condition: CONDITION;
  location: string;
  opportunity_count?: number;
}

/**
 * @deprecated refer to src/request2/types.ts
 */
export const getEmptyWanted = (): Wanted => ({
  id: 0,
  price: 0,
  created_at: '',
  category: {
    id: -1,
    slug: '',
    name: '',
    description: ''
  },
  make: {
    id: -1,
    slug: '',
    name: '',
    description: ''
  },
  model: {
    id: -1,
    slug: '',
    name: '',
    description: ''
  },
  priority: WANTED_PRIORITY.LOW,
  condition: CONDITION.NEW,
  location: '',
  wafer_size_values: []
});

/**
 * @deprecated refer to src/request2/types.ts
 */
export const generateWanted = (props: Partial<Wanted> = {}): Wanted => ({
  id: props.id || 0,
  price: props.price || 1000.5,
  created_at: props.created_at || '2022-01-01T12:00:00.000000Z',
  category: props.category || generateDTOWantedCategory(),
  make: props.make || generateDTOWantedMake(),
  model: props.model || generateDTOWantedModel(),
  priority: props.priority || WANTED_PRIORITY.LOW,
  condition: props.condition || CONDITION.NEW,
  location: props.location || '',
  wafer_size_values: props.wafer_size_values || []
});

/**
 * @deprecated refer to src/request2/types.ts
 */
export interface UpdateWanted {
  readonly condition?: CONDITION;
  readonly location?: string;
  readonly price?: number;
  readonly priority?: WANTED_PRIORITY;
  readonly wafer_size_values?: number[];
}
