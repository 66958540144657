import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

export type Content = { line1?: string; line2?: string; title: string; yes: string; no: string };
export type Props = {
  c: Content;
  open: boolean;
  noClick: VoidFunction;
  yesClick: VoidFunction;
};
export function DialogYesNo({ c, open, noClick, yesClick }: Props) {
  return (
    <Dialog onClose={noClick} open={open}>
      <DialogTitle>{c.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{c.line1 ?? ''}</DialogContentText>
        <DialogContentText>{c.line2 ?? ''}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={noClick}>
          {c.no}
        </Button>
        <Button onClick={yesClick}>{c.yes}</Button>
      </DialogActions>
    </Dialog>
  );
}
