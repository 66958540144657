import { ListingActionStatus } from '../../entities/listing';

export interface RejectedOpportunityDetails {
  loss_reason?: number;
  loss_comments?: string;
}

export type RejectedOpportunityReason = 'VINTAGE' | 'CONDITION' | 'SPECS' | 'NEED' | 'PARTS' | '';

export const isRejectOpportunityReason = (arg: unknown): arg is RejectedOpportunityReason => {
  const opportunityReasonsArray: RejectedOpportunityReason[] = ['VINTAGE', 'CONDITION', 'SPECS', 'NEED', 'PARTS', ''];
  return !!arg && typeof arg === 'string' && opportunityReasonsArray.includes(arg as RejectedOpportunityReason);
};

export interface Opportunity {
  key: string;
  status: ListingActionStatus;
}

export const getEmptyOpportunity = (): Opportunity => ({
  key: '',
  status: ListingActionStatus.none
});

export interface UpdateRejectReason {
  readonly rejectReason?: RejectedOpportunityReason;
  readonly rejectComments?: string;
}
