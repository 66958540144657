import { COOKIE_LANGUAGE_PREFERENCE_NAME, COOKIE_LANGUAGE_PREFERENCE_TTL } from '../../constants/cookie';
import { SUPPORTED_LOCALES } from '../../layout/header/desktop/languageDropdown/constants';
import { getDomain, setCookie } from '../../utils/cookies';
import { updateUser } from '../../providers/users';
import { User } from '../../entities/user/types';

export const isSupportedLocale = (locale: string | undefined) => {
  if (!locale) return false;
  return Object.keys(SUPPORTED_LOCALES).includes(locale);
};

export const updateLanguagePreferenceCookie = (doc: Document, language_preference: string) => {
  if (isSupportedLocale(language_preference)) {
    setCookie(doc, [COOKIE_LANGUAGE_PREFERENCE_NAME, language_preference], COOKIE_LANGUAGE_PREFERENCE_TTL, getDomain());
  }
};

export const updateUserLanguagePreference = (user: User, language_preference: string) => {
  if (isSupportedLocale(language_preference)) {
    updateUser(user.id, { language: language_preference });
  }
};
