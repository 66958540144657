import { ReactNode } from 'react';
import { Fade, Modal } from '@mui/material';

interface Props {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly className?: string;
  readonly childrenContainerClassName?: string;
}

/**
 *
 */
const Modal2 = ({ children, isOpen, onClose, className = '', childrenContainerClassName = '' }: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      className="flex justify-center overflow-y-auto bg-blue-800/80"
      role="dialog"
      aria-modal={true}
    >
      <Fade in={isOpen}>
        <div className={`w-full ${className}`}>
          <div className={`h-full w-full rounded bg-transparent ${childrenContainerClassName}`}>{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default Modal2;
