import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigAnswer } from './types';

export interface ConfigAnswerState {
  byListingId: Record<string, ConfigAnswer[]>;
  byEquipmentId: Record<string, ConfigAnswer[]>;
  error: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ConfigAnswerState = {
  byListingId: {},
  byEquipmentId: {},
  error: false,
  loading: false,
  loaded: false
};

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'ConfigAnswers',
  initialState,
  reducers: {
    loadListingConfigAnswers: (state, action: PayloadAction<ConfigAnswer[]>) => {
      const newByListingId = action.payload.reduce((acc: Record<string, ConfigAnswer[]>, curr) => {
        if (!acc[curr.listing_id.toString()]) {
          acc[curr.listing_id.toString()] = [curr];
        } else {
          acc[curr.listing_id.toString()].push(curr);
        }
        return acc;
      }, {});

      state.byListingId = { ...state.byListingId, ...newByListingId };
      state.loading = false;
      state.loaded = true;
      state.error = false;
    },

    loadEquipmentConfigAnswers: (state, action: PayloadAction<ConfigAnswer[]>) => {
      const newByEquipmentId = action.payload.reduce((acc: Record<string, ConfigAnswer[]>, curr) => {
        if (!acc[curr.equipment_id.toString()]) {
          acc[curr.equipment_id.toString()] = [curr];
        } else {
          acc[curr.equipment_id.toString()].push(curr);
        }
        return acc;
      }, {});

      state.byEquipmentId = { ...state.byEquipmentId, ...newByEquipmentId };
      state.loading = false;
      state.loaded = true;
      state.error = false;
    },

    createListingConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      const { listing_id } = action.payload;
      // If the array does not exist, create it, then append the new answer
      if (!state.byListingId[listing_id]) {
        state.byListingId[listing_id] = [action.payload];
      } else {
        state.byListingId[listing_id].push(action.payload);
      }
    },

    createEquipmentConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      const { equipment_id } = action.payload;
      // If the array does not exist, create it, then append the new answer
      if (!state.byEquipmentId[equipment_id]) {
        state.byEquipmentId[equipment_id] = [action.payload];
      } else {
        state.byEquipmentId[equipment_id].push(action.payload);
      }
    },

    updateListingConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      const { listing_id, question_id } = action.payload;
      const listingAnswers = state.byListingId[listing_id] || [];

      const existingAnswerIndex = listingAnswers.findIndex(
        (answer) => answer.question_id === question_id && answer.listing_id === listing_id
      );

      if (existingAnswerIndex >= 0) {
        listingAnswers[existingAnswerIndex] = action.payload;
      } else {
        listingAnswers.push(action.payload);
      }

      state.byListingId[listing_id] = listingAnswers;
    },

    updateEquipmentConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      const { equipment_id, question_id } = action.payload;
      const equipmentAnswers = state.byEquipmentId[equipment_id] || [];

      const existingAnswerIndex = equipmentAnswers.findIndex(
        (answer) => answer.question_id === question_id && answer.equipment_id === equipment_id
      );

      if (existingAnswerIndex >= 0) {
        equipmentAnswers[existingAnswerIndex] = action.payload;
      } else {
        equipmentAnswers.push(action.payload);
      }

      state.byEquipmentId[equipment_id] = equipmentAnswers;
    },

    removeListingConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      state.byListingId[action.payload.listing_id] = state.byListingId[action.payload.listing_id].filter(
        (answer) => answer.question_id !== action.payload.question_id
      );
    },

    removeEquipmentConfigAnswer: (state, action: PayloadAction<ConfigAnswer>) => {
      state.byEquipmentId[action.payload.equipment_id] = state.byEquipmentId[action.payload.equipment_id].filter(
        (answer) => answer.question_id !== action.payload.question_id
      );
    },

    fetchConfigAnswersStart: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = false;
    },
    fetchConfigAnswersFailed: (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = true;
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const {
  loadListingConfigAnswers,
  loadEquipmentConfigAnswers,
  createListingConfigAnswer,
  createEquipmentConfigAnswer,
  updateListingConfigAnswer,
  updateEquipmentConfigAnswer,
  removeListingConfigAnswer,
  removeEquipmentConfigAnswer,
  fetchConfigAnswersStart,
  fetchConfigAnswersFailed
} = slice.actions;

export default slice.reducer;
