import React, { useState, ReactNode, ReactElement, JSXElementConstructor, useEffect, useCallback } from 'react';
import { useResolution } from '../../hooks/screen/useResolution';

export interface CarouselProps {
  readonly children: ReactElement<any, string | JSXElementConstructor<any>>[] | ReactElement<any, string | JSXElementConstructor<any>>;
  readonly visibleLength: number;
  readonly LeftArrow: ({ onClick }: { onClick: () => void }) => JSX.Element;
  readonly RightArrow: ({ onClick }: { onClick: () => void }) => JSX.Element;
  readonly gapValue: number;
  readonly gapUnit: 'px' | 'em' | 'rem' | '%';
  readonly breakpoints?: Record<number, { visibleLength: number }>;
  readonly className?: string;
  readonly activeIndex?: number;
}

/**
 * Use embla carousel now in design
 @deprecated
 */
export const Carousel = (props: CarouselProps) => {
  const { children, visibleLength, LeftArrow, RightArrow, gapValue, gapUnit, breakpoints, className = '', activeIndex } = props;

  const { windowX } = useResolution();
  const [translateX, setTranslateX] = useState(0);
  const [maxTranslateX, setMaxTranslateX] = useState(0);
  const [finalVisibleLength, setFinalVisibleLength] = useState(visibleLength);
  const [gap, setGap] = useState('0px');
  const [itemStyle, setItemStyle] = useState({});
  const [finalItems, setFinalItems] = useState<ReactNode[]>([]);

  useEffect(() => {
    setGap(`${gapValue}${gapUnit}`);
  }, [gapValue, gapUnit]);

  useEffect(() => {
    if (breakpoints === undefined || windowX === null) return;

    const sortedBreakpoints = Object.keys(breakpoints)
      .map((breakpoint) => parseInt(breakpoint))
      .sort((a, b) => a - b);

    for (let i = 0; i < sortedBreakpoints.length; i++) {
      const breakpoint = sortedBreakpoints[i];
      if (windowX < breakpoint) {
        setFinalVisibleLength(breakpoints[breakpoint].visibleLength);
        return;
      }
    }

    setFinalVisibleLength(visibleLength);
  }, [breakpoints, windowX, visibleLength]);

  useEffect(() => {
    setItemStyle({
      width: `calc(${100 / finalVisibleLength}% - ${gap})`,
      flex: 'none'
    });
  }, [finalVisibleLength, gap]);

  const scrollHoriz = useCallback(
    (amount: number) => {
      let newTranslateX = translateX + amount;

      if (newTranslateX < 0) {
        newTranslateX = maxTranslateX;
      } else if (newTranslateX > maxTranslateX) {
        newTranslateX = 0;
      }

      setTranslateX(newTranslateX);
    },
    [translateX, maxTranslateX]
  );

  useEffect(() => {
    const elements: ReactNode[] = [];
    // Add invisible divider elements based on given `gapValue` and `gapUnit`
    React.Children.forEach(children, (child, i) => {
      elements.push(React.cloneElement(child, { style: itemStyle, key: child.key || i }));
      if (i < React.Children.count(children) - 1) {
        // eslint-disable-next-line react/no-array-index-key
        elements.push(<li key={`gap-${i}`} style={{ width: gap, flex: 'none' }} />);
      }
    });
    setFinalItems(elements);

    // Calculate max page based on number of children
    const newMaxTranslateX = (Math.ceil(React.Children.count(children) / finalVisibleLength) - 1) * 100;
    setMaxTranslateX(newMaxTranslateX);

    // If we're on an empty page after deleting the last element,
    // move to the last page with a visible element
    if (newMaxTranslateX >= 0 && translateX > newMaxTranslateX) {
      setTranslateX(newMaxTranslateX);
    }
  }, [children, itemStyle, finalVisibleLength, gap]);

  useEffect(() => {
    // If we're keeping track of the activeIndex and the current item is not visible,
    // move to the page where it will be visible
    if (activeIndex !== undefined) {
      const translateXNeededForVisibility = Math.floor(activeIndex / visibleLength) * 100;
      if (translateX !== translateXNeededForVisibility) {
        setTranslateX(translateXNeededForVisibility);
      }
    }
  }, [activeIndex]);

  return (
    <>
      <div className={`relative h-full w-full overflow-hidden ${className}`} data-testid="carousel">
        {translateX != 0 && <LeftArrow onClick={() => scrollHoriz(-100)} />}
        <ul
          className="flex h-full transition-all duration-300"
          style={{ transform: `translateX(-${translateX}%)`, marginRight: `calc(-${gap} - 1px)` }}
        >
          {finalItems}
        </ul>
        {maxTranslateX > translateX && <RightArrow onClick={() => scrollHoriz(100)} />}
      </div>
    </>
  );
};
