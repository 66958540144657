import { isAppProduction, isAppStage } from '@/src/utils/appContext';
import { FullStory, isInitialized, init } from '@fullstory/browser';
import { CustomEvent } from './customEvents';

export const fullstoryEvent = (...args: CustomEvent) => {
  const [name, properties] = args;
  FullStory('trackEvent', { name, properties });
};

export const initializeFullstory = () => {
  !isInitialized() &&
    init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ID || '',
      devMode: !isAppProduction() && !isAppStage(),
      debug: !isAppProduction() && !isAppStage()
    });
};
