import { Slug } from '../../entities/common';
import { WANTED_PRIORITY, CONDITION } from '../../entities/wanted';
import { DTOWanted, DTOWantedCategory, DTOWantedMake, DTOWantedModel } from './dtos';


/**
 * @deprecated Use requests2/types instead
 */
export interface GenerateDTOWantedCategoryProps {
  readonly id?: number;
  readonly slug?: Slug;
  readonly name?: string;
  readonly description?: string;
}
/**
 * @deprecated Use requests2/types instead
 */
export interface GenerateDTOWantedMakeProps extends GenerateDTOWantedCategoryProps {}
/**
 * @deprecated Use requests2/types instead
 */
export interface GenerateDTOWantedModelProps extends GenerateDTOWantedCategoryProps {}

/**
 * @deprecated Use requests2/types instead
 */
export const generateDTOWantedCategory = (props: GenerateDTOWantedCategoryProps = {}): DTOWantedCategory => ({
  id: props.id || 0,
  slug: props.slug || 'DTOWantedCategorySlug',
  name: props.name || 'DTOWantedCategoryName',
  description: props.description || 'DTOWantedCategoryDescription'
});

/**
 * @deprecated Use requests2/types instead
 */
export const generateDTOWantedMake = (props: GenerateDTOWantedMakeProps = {}): DTOWantedMake => ({
  id: props.id || 0,
  slug: props.slug || 'DTOWantedMakeSlug',
  name: props.name || 'DTOWantedMakeName',
  description: props.description || 'DTOWantedMakeDescription'
});

/**
 * @deprecated Use requests2/types instead
 */
export const generateDTOWantedModel = (props: GenerateDTOWantedModelProps = {}): DTOWantedModel => ({
  id: props.id || 0,
  slug: props.slug || 'DTOWantedModelSlug',
  name: props.name || 'DTOWantedModelName',
  description: props.description || 'DTOWantedModelDescription'
});

/**
 * @deprecated Use requests2/types instead
 */
export const generateDTOWanted = (props: Partial<DTOWanted> = {}): DTOWanted => ({
  id: props.id || 0,
  price: props.price || '1000.50',
  created_at: props.created_at || '2022-01-01T12:00:00.000000Z',
  category: props.category || generateDTOWantedCategory(),
  make: props.make || generateDTOWantedMake(),
  model: props.model || generateDTOWantedModel(),
  priority: props.priority || WANTED_PRIORITY.LOW,
  condition: props.condition || CONDITION.NEW,
  location: props.location || '',
  wafer_size: props.wafer_size || '',
  opportunity_count: props.opportunity_count || 0
});
