import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from '@/src/entities/model';

export interface ModelState {
  byId: Record<string, Model>;
  idsForFilters: number[];
}

export const initialState: ModelState = {
  byId: {},
  idsForFilters: []
};

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    loadModels: (state, action: PayloadAction<Model[]>) => {
      action.payload.forEach((model) => {
        state.byId[model.id] = model;
      });
    },
    loadModelsForFilters: (state, action: PayloadAction<Model[]>) => {
      action.payload.forEach((model) => {
        state.byId[model.id] = model;
      });
      state.idsForFilters = action.payload.map((model) => model.id);
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const { loadModelsForFilters, loadModels } = slice.actions;

export default slice.reducer;
