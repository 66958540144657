import WorkflowModal from '@/src/bits/modals/workflowModal';
import { ERROR_MESSAGES } from '@/src/constants';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { useLocalizer } from '@/src/localization';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { Button, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ContactFormValues } from '../../../forms/contactForm';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getModalState } from '@/src/state/globalModal/selectors';
import { closeModal } from '@/src/state/globalModal/slice';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { colors, spacing } from '@/src/design/designConstants';
import * as yup from 'yup';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';
import { getCurrentUser } from '@/src/state/user/selectors';
import { ListingForms, submitListingForm } from '@/src/state/forms/thunks';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';

export enum AuthRequestDetailsFormSteps {
  REQUEST_DETAILS = 1
}
type PossibleSteps = AuthRequestDetailsFormSteps.REQUEST_DETAILS;

export interface AuthRequestDetailsFormValues extends ContactFormValues {
  budget: string;
  questions: Array<string>;
}

export const AuthRequestDetailsModal = () => {
  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const {
    modalProps: { requestDetails }
  } = useAppSelector(getModalState);
  const { fullStoryEvent } = useFullStory();
  const user = useAppSelector(getCurrentUser);

  const [currentStep, setCurrentStep] = useState<PossibleSteps>(AuthRequestDetailsFormSteps.REQUEST_DETAILS);
  const initialValues: AuthRequestDetailsFormValues = {
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    company: user?.user_company?.name || '',
    phone: user?.phone,
    contact_preference: user?.contact_preference,
    contact_handle: user?.contact_handle,
    budget: '',
    questions: ['']
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).shape({ budget: yup.string(), questions: yup.array() })
  );

  if (!requestDetails?.listing || !user?.id) return null;

  const stepData: Record<
    AuthRequestDetailsFormSteps,
    {
      title: string;
      actionButtonDisabled: boolean;
      actionButtonContent: string;
      actionButtonSubmit: () => Promise<void>;
      actionButtonId?: string;
      dismissButtonId?: string;
    }
  > = {
    [AuthRequestDetailsFormSteps.REQUEST_DETAILS]: {
      title: loc.Listing.RequestDetails,
      actionButtonDisabled: false,
      actionButtonContent: loc.Forms.Submit,
      actionButtonSubmit: async () => {
        fullStoryEvent(CustomEvents.listing.formSubmit, { type: 'ContactSales' });
        return dispatch(
          submitListingForm(
            {
              from_url: window.location.href,
              is_offer: 0,
              budget: parseInt(formValidationHook.formValues.budget),
              questions: formValidationHook.formValues.questions.filter((q) => Boolean(q)),
              listing_id: requestDetails.listing.id,
              model_id: requestDetails.listing.model.id,
              form_name: ListingForms.REQUEST_DETAILS
            },
            requestDetails.listing.key
          )
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      },
      actionButtonId: 'listing-contact-sales-auth-step-1-submit-button',
      dismissButtonId: 'listing-contact-sales-auth-step-1-dismiss-button'
    }
  };

  return (
    <WorkflowModal
      modalOpen
      displayBackButton={!!user?.id}
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      {...stepData[currentStep]}
      actionButtonId={stepData[currentStep].actionButtonId ?? 'modal-action-button'}
      dismissButtonId={stepData[currentStep].dismissButtonId ?? 'modal-dismiss-button'}
    >
      {/* TODO: ENG-93 - Replace hard-coded height and width with reuseable
      spacing constants for modal sizes */}
      <Stack minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        {currentStep === AuthRequestDetailsFormSteps.REQUEST_DETAILS && (
          <Stack spacing={spacing['xl']}>
            <LabeledCurrencyInput
              inputProps={{ 'data-testid': 'request-details-budget' }}
              value={formValidationHook.formValues.budget}
              onChange={(e) => formValidationHook.setFieldValue('budget', e.target.value)}
              variant="outlined"
              label={loc.Forms.TargetPrice}
            />
            <Stack spacing={spacing.lg}>
              {formValidationHook.formValues.questions.map((question, index) => (
                // There is no good unique identifier for the key on these questions.
                // If you use the question value itself, it will cause the input to un-mount/mount
                // for every character so the input looses focus on every key stroke.
                // eslint-disable-next-line react/jsx-key
                <Stack>
                  {/* TODO: Translations #185801497 - What would you like to know? */}
                  {!index && <FormLabel>What would you like to know?</FormLabel>}
                  <Stack direction="row" alignContent="center">
                    <TextField
                      inputProps={{ 'data-testid': `request-details-question-${index}` }}
                      fullWidth
                      value={question}
                      onChange={(e) => {
                        const newQuestions = [...formValidationHook.formValues.questions];
                        newQuestions.splice(index, 1, e.target.value);
                        formValidationHook.setFieldValue('questions', newQuestions);
                      }}
                    />

                    <Button
                      onClick={() => {
                        const newQuestions = [...formValidationHook.formValues.questions];
                        newQuestions.splice(index, 1);
                        formValidationHook.setFieldValue('questions', newQuestions);
                      }}
                      variant="contained"
                      color="info"
                    >
                      <FAIcon icon={icons.xMark} />
                    </Button>
                  </Stack>
                </Stack>
              ))}
              <Stack
                direction="row"
                spacing={spacing.sm}
                padding={spacing.lg}
                sx={{ cursor: 'pointer' }}
                onClick={() => formValidationHook.setFieldValue('questions', [...formValidationHook.formValues.questions, ''])}
              >
                <FAIcon icon={icons.circlePlus} color={colors.magenta['500']} />
                <Typography color={colors.magenta['500']} variant="button14AllCaps">
                  {loc.Magic.AddQuestion}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </WorkflowModal>
  );
};
