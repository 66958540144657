import type { FC } from 'react';

import { PlaceholderText } from './bits/text';
import { isEmpty } from './utils';
import { FileLinkWithSmallIcon } from './bits/fileLink/fileLink';
import { waferSizeValuesToDisplayString } from './marketplace/utils';
import { useLocalizer } from './localization';
import { Listing } from './entities/listing';
import { Section } from '@/src/common/section';

const ListingDetailsDivider = () => <div className="h-px bg-gray-200" />;

export interface ListingDetailsProps {
  readonly listing: Listing;
  readonly className?: string;
}

export const ListingDetails: FC<ListingDetailsProps> = ({ listing, className = ' ' }) => {
  const loc = useLocalizer();

  return (
    <section className={`lg:px-6 ${className}`}>
      <Section title={`${loc.Listing.KeyItemDetails} & ${loc.Listing.Configuration}`}>
        <div className="flex flex-col gap-y-3">
          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.ConditionColon}</div>
            <div className="flex-2 lg:flex-3">{listing.condition_name}</div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.ProductIdColon}</div>
            <div className="flex-2 lg:flex-3">{listing.id}</div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.WaferSizesColon}</div>
            <div className="flex-2 lg:flex-3">
              {isEmpty(listing.wafer_size_values) ? (
                <PlaceholderText>{loc.Listing.Unknown}</PlaceholderText>
              ) : (
                waferSizeValuesToDisplayString(listing.wafer_size_values)
              )}
            </div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.VintageColon}</div>
            <div className="flex-2 lg:flex-3">
              {isEmpty(listing.vintage) ? <PlaceholderText>{loc.Listing.Unknown}</PlaceholderText> : listing.vintage}
            </div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.ConfigurationColon}</div>
            <div className="flex-2 lg:flex-3">
              {isEmpty(listing.configuration) ? <PlaceholderText>{loc.Listing.NoConfiguration}</PlaceholderText> : listing.configuration}
            </div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Listing.DescriptionColon}</div>
            <div className="flex-2 lg:flex-3">
              {isEmpty(listing.description) ? <PlaceholderText>{loc.Common.NoDescriptionAvailable}</PlaceholderText> : listing.description}
            </div>
          </div>
          <ListingDetailsDivider />

          <div className="flex w-full gap-x-6">
            <div className="flex-1 font-extrabold">{loc.Magic.AttachedFilesColon}</div>
            {isEmpty(listing.files) ? (
              <div className="flex-2 lg:flex-3">
                <PlaceholderText>{loc.Magic.NoAttachedFiles}</PlaceholderText>
              </div>
            ) : (
              <ul className="flex flex-2 flex-wrap items-center gap-x-6 gap-y-5 lg:flex-3">
                {listing.files.map((file) => (
                  <li key={file.id} className="group flex cursor-pointer flex-col rounded hover:brightness-95 lg:w-2/6">
                    <FileLinkWithSmallIcon file={file} downloadUrl={file.url} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Section>
    </section>
  );
};

export default ListingDetails;
