import { MultiSelectDropdown } from '@/src/bits/forms/fields/dropdowns/multiSelectDropdown';
import { SingleSelectDropdown } from '@/src/bits/forms/fields/dropdowns/singleSelectDropdown';
import { InputField } from '@/src/bits/forms/fields/inputField';
import { NumberInputField } from '@/src/bits/forms/fields/numberInputField';
import { EquipmentSerializable, getEquipmentMakeModelDisplayString } from '@/src/equipment/types';
import { useLocalizer } from '@/src/localization';
import { LISTING_CONDITION_OPTIONS, LISTING_WAFER_SIZES_OPTIONS } from '@/src/providers/listings/constants';
import { FC } from 'react';
import { FieldLabel } from '@/src/bits/forms/fields/fieldLabel';
import { StyledTextInput } from '@/src/bits/forms/fields/styledTextInput';
import { colors, spacing } from '@/src/design/designConstants';
import { Box, Stack, Typography } from '@mui/material';

export interface RequestPriceFormState {
  wafer_size_values: number[];
  condition: number;
  vintage: number | null;
  serial_number: string;
}

interface RequestPriceFormProps {
  equipment: EquipmentSerializable;
  formState: RequestPriceFormState;
  setFormState: (formState: RequestPriceFormState) => void;
}

export const RequestPriceForm: FC<RequestPriceFormProps> = ({ equipment, formState, setFormState }) => {
  const loc = useLocalizer();

  return (
    <Box>
      <Box mb={spacing['3xl']}>
        <Typography variant="body1" color={colors.smokeyGray[500]}>
          {loc.EquipmentManage.KnowTheCurrentMarketPrice}
        </Typography>
      </Box>

      <Stack rowGap={spacing['3xl']}>
        <FieldLabel disabled label={loc.Listing.ManufacturerAndModel}>
          <StyledTextInput disabled fullWidth value={getEquipmentMakeModelDisplayString(equipment)} />
        </FieldLabel>

        <SingleSelectDropdown
          label={loc.Listing.Condition}
          disableClearable
          placeholder={loc.ToolRequest.AddCondition}
          value={LISTING_CONDITION_OPTIONS.find((option) => parseInt(option.id) === formState.condition) || null}
          options={LISTING_CONDITION_OPTIONS}
          onChange={(option) => {
            if (option !== null) {
              setFormState({ ...formState, condition: parseInt(option.id) });
            }
          }}
          size="medium"
        />
        <NumberInputField
          label={loc.Listing.Vintage}
          onChange={(e) => setFormState({ ...formState, vintage: Number(e.target.value) })}
          value={formState.vintage ? formState.vintage : undefined}
        />

        <MultiSelectDropdown
          label={loc.Listing.WaferSize}
          placeholder={loc.ToolRequest.AddWaferSize}
          value={LISTING_WAFER_SIZES_OPTIONS.filter((option) => formState.wafer_size_values.includes(parseInt(option.id)))}
          options={LISTING_WAFER_SIZES_OPTIONS}
          onChange={(options) => {
            setFormState({ ...formState, wafer_size_values: options.map((option) => parseInt(option.id)) });
          }}
          size="medium"
        />
        <InputField
          type="text"
          label={loc.Listing.SerialNumber}
          placeholder={loc.Listing.SerialNumber}
          value={formState.serial_number}
          onChange={(e) => setFormState({ ...formState, serial_number: e.target.value })}
          size="medium"
        />
      </Stack>
    </Box>
  );
};
