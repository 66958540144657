// * managed dependencies i.e. node_modules *
import axios from 'axios';
import { captureException } from '@sentry/nextjs';

// * external dependencies *
import { valueFromKey } from '@/src/common/lib';
import { ERROR_MESSAGES, MOOV_API_URL } from '@/src/constants';
import { GlobalBannerDurations } from '@/src/state/banner/types';
import { AppDispatch, AppState } from '@/src/store';

// * internal dependencies *
import { deleteOne, load, setLoading, updateRequest } from './slice';
import { Wanted2, WantedSerializable } from './types';
import { openError, openSuccess } from '@/src/state/banner/slice';
import { getCurrent } from './selectors';

// TODO: 186150352 translate all content in this file

export function fetchAll() {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const allWantedFetch = axios
      .get<{ data: WantedSerializable[] }>(`${MOOV_API_URL}/wanteds`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<WantedSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = body.map((e) => Wanted2.anyToSerializable(e));
        dispatch(load(data));
      });

    return Promise.all([allWantedFetch])
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

const _update = (toolRequestToUpdate: WantedSerializable, trUpdate: Partial<WantedSerializable>) => {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading(true));

    //Optimistically update the tool request
    dispatch(updateRequest({ ...toolRequestToUpdate, ...trUpdate }));
    return axios
      .put<{ data: WantedSerializable }>(`${MOOV_API_URL}/wanteds/${toolRequestToUpdate.id}`, trUpdate, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<WantedSerializable>>('data', r.data);
        const data = Wanted2.anyToSerializable(body);

        dispatch(load([data]));
        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            message: 'Your changes have been saved!'
          })
        );
      })
      .catch((e) => {
        //Revert request back to previous values if the update fails
        dispatch(updateRequest(toolRequestToUpdate));

        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export function updateCurrentToolRequest(newStuff: Partial<WantedSerializable>) {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const current = getCurrent(getState()).toSerializable();
    _update(current, newStuff)(dispatch, getState);
  };
}

export function updateToolRequest(trToUpdate: WantedSerializable, newStuff: Partial<WantedSerializable>) {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    _update(trToUpdate, newStuff)(dispatch, getState);
  };
}

export function deleteById(a: WantedSerializable) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return axios
      .delete<{ data: WantedSerializable }>(`${MOOV_API_URL}/wanteds/${a.id}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then(() => {
        dispatch(deleteOne(a.id.toString()));
        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            message: 'Your request has been deleted.'
          })
        );
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
