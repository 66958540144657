import { AppState } from '@/src/store';
import { CMSLocations } from './cmsLinkTypes';
import { createSelector } from 'reselect';

const getCMSLinks = (state: AppState) => state.meta.cmsLinks;

export const getCMSLinksForLocation = createSelector(
  [getCMSLinks, (_: AppState, location: CMSLocations) => location],
  (cmsLinks, location) => cmsLinks[location] || []
);
