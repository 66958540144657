import { FC } from 'react';
import { AnonRequestDetailsModal } from './anonRequestDetails';
import { AuthRequestDetailsModal } from './authRequestDetails';
import { useAppSelector } from '@/src/store';
import { userIsLoggedIn } from '@/src/state/user/selectors';

export const RequestDetailsModal: FC = () => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);

  return isLoggedIn ? <AuthRequestDetailsModal /> : <AnonRequestDetailsModal />;
};
