import { useContext } from 'react';
import { MobileSuggestedItemsModalDispatch } from '../../../../state/mobileSuggestedItemsModal/context';
import { UpdateQuestions } from '../../../../entities/opportunity';
import { getQuestionsFormSchema } from '../../../../validationSchemas/question';
import { useLocalizer } from '../../../../localization';
import { FieldArray, Formik } from 'formik';
import { FormikFormWrapper } from '../../../../bits/forms/formikFields/formikWrappers';
import { Questions } from '../../../../bits/modals/interestedModal/questions';
import { StepFormValues } from '../../../../state/mobileSuggestedItemsModal/types';
import AcknowledgeMessage from '../../../../acknowledgeMessage';

const MobileQuestionsForm: React.FC = () => {
  const actions = useContext(MobileSuggestedItemsModalDispatch);
  const loc = useLocalizer();
  const initialValues: UpdateQuestions = {
    questions: ['']
  };

  const updateFormValues = (formValues: StepFormValues) => {
    actions.updateStepFormValues(formValues);
  };

  const validationSchema = getQuestionsFormSchema(loc);
  return (
    <div className="h-full bg-magenta-50">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
        {({ isSubmitting, errors, submitCount, values }) => (
          <FormikFormWrapper isSubmitting={isSubmitting} errors={errors} submitCount={submitCount}>
            <AcknowledgeMessage>
              <div className="mb-2">
                <FieldArray
                  name="questions"
                  render={(arrayHelpers) => (
                    <Questions formValues={values} arrayHelpers={arrayHelpers} externalUpdateFormValues={updateFormValues} />
                  )}
                />
              </div>
            </AcknowledgeMessage>
          </FormikFormWrapper>
        )}
      </Formik>
    </div>
  );
};

export default MobileQuestionsForm;
