import { FC } from 'react';

interface DividerProps {
  readonly className?: string;
}

interface GenericDividerProps {
  readonly className?: string;
  readonly colorVariant: 'magenta-light' | 'gray' | 'smokey-gray';
}

export const DashedDivider: FC<DividerProps> = ({ className = '' }) => (
  <div className={`border-b-2 border-dashed border-smokey-gray-300 ${className}`} />
);

/**
 * @deprecated
 * SolidGrayDivider should be replaced by Divider with colorVariant prop
 */
export const SolidGrayDivider: FC<DividerProps> = ({ className = '' }) => <div className={`border-b-2 border-gray-200 ${className}`} />;

export const Divider: FC<GenericDividerProps> = ({ colorVariant, className = '' }) => {
  let colorClassName;
  switch (colorVariant) {
    case 'gray':
      colorClassName = 'border-smokey-gray-200';
      break;
    case 'smokey-gray':
      colorClassName = 'border-smokey-gray-100';
      break;
    case 'magenta-light':
      colorClassName = 'border-magenta-500 opacity-15';
      break;
  }

  return <div className={`border-b-2 ${colorClassName} ${className}`} />;
};
