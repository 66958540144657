import type { FC, MouseEventHandler } from 'react';
import { InternalLink } from '../../bits/links';

interface NavLinkProps {
  readonly href: string;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const NavLink: FC<NavLinkProps> = (props) => {
  const { children, href, onClick } = props;

  return (
    <InternalLink href={href} className="w-fit hover:underline" onClick={onClick}>
      {children}
    </InternalLink>
  );
};
