import { convertRejectedReason2num } from './constants';
import { RejectedOpportunityReason, RejectedOpportunityDetails } from './types';

export const createRejectPayload = (reason?: RejectedOpportunityReason, comment?: string): RejectedOpportunityDetails => {
  const payload: RejectedOpportunityDetails = {};
  if (reason) {
    payload.loss_reason = convertRejectedReason2num(reason);
  }
  if (comment) {
    payload.loss_comments = comment;
  }

  return payload;
};
