import translations from './index.loc.json';
import { getDefinitions, Locale } from '../../../localization';

/**
 *
 */
export class BuySellWidgetsLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ServicesWhiteGloveExperience(): string {
    return this.definitions.ServicesWhiteGloveExperience;
  }

  public get ServicesMoovBeyond(): string {
    return this.definitions.ServicesMoovBeyond;
  }

  public get MoovGoesBeyond(): string {
    return this.definitions.MoovGoesBeyond;
  }

  public get ServicesTakeThePainOutOfShipping(): string {
    return this.definitions.ServicesTakeThePainOutOfShipping;
  }

  public get ServicesProtectEquipment(): string {
    return this.definitions.ServicesProtectEquipment;
  }

  public get ServicesEnsurePerformance(): string {
    return this.definitions.ServicesEnsurePerformance;
  }

  public get ServicesManageAssets(): string {
    return this.definitions.ServicesManageAssets;
  }

  public get ServicesAskAboutRemarketingProgram(): string {
    return this.definitions.ServicesAskAboutRemarketingProgram;
  }

  public get BuyWithConfidence(): string {
    return this.definitions.BuyWithConfidence;
  }

  public get Deinstallation(): string {
    return this.definitions.Deinstallation;
  }

  public get LetMoovDeinstall(): string {
    return this.definitions.LetMoovDeinstall;
  }
}
