import { Banner } from '../bits/banner';
import MobileSuggestedItemsModal from './mobileSuggestedItemsModal';
import GlobalModal from './globalModal';
import { useLanguagePreference } from './useLanguagePreference';
import { useGTMUser } from './hooks/useGTMUser';
import { useTinderGTM } from './useTinderGTM';
import { useMarketingParamListener } from '../hooks/useMarketingParamListener';
import { useFetchCompanyRemarketingInfo } from './useFetchCompanyRemarketingInfo';
import { useSearchTracking } from './hooks/Search/useSearchTracking';
import { useFetchLogisticCountries } from './useFetchLogisticCountries';
import { useFetchCMSLinks } from './useFetchCMSLinks';
import { useFetchUnreadMessageCount } from './useFetchUnreadMessageCount';

const GlobalComponents: React.FC = ({ children }) => {
  useTinderGTM();
  useMarketingParamListener();
  useLanguagePreference();
  useGTMUser();
  useFetchCompanyRemarketingInfo();
  useSearchTracking();
  useFetchLogisticCountries();
  useFetchCMSLinks();
  useFetchUnreadMessageCount();

  return (
    <>
      <MobileSuggestedItemsModal />
      <GlobalModal />
      <Banner>{children}</Banner>
    </>
  );
};

export default GlobalComponents;
