import { useEffect, useState } from 'react';
import { useUser } from '../../../hooks/useUser';
import { useGTM } from '../../../hooks/useGTM';

export const useGTMUser = () => {
  const { user } = useUser();
  const { trackGTMEvent } = useGTM();
  const [previousId, setPreviousId] = useState<number>(0);

  useEffect(() => {
    if (user?.id && user.id !== previousId) {
      trackGTMEvent({
        event: 'updateUserId',
        user_id: user.id
      });
      setPreviousId(user.id);
    }
  }, [user?.id]);
};
