import { defaultLocale } from './constants';
import type { DefinitionDict, Locale, TranslationDict } from './types';

/**
 * todo #182884188 clean this up, remove ignores
 */
export const getDefinitions = <T extends TranslationDict>(translationDict: T, locale: Locale): DefinitionDict<T> => {
  const definitions: { [key: string]: string } = {};
  for (const [key, values] of Object.entries(translationDict)) {
    definitions[key] = values[locale] ?? values[defaultLocale] ?? '';
  }
  return definitions as Record<keyof T, string>;
};

/**
 *
 */
export const parseConstant = (str: string, key: string): string => {
  const findRegex = new RegExp(`(?<=\{${key}\{).+?(?=\})`, 'g');
  const matches = str.match(findRegex);
  if (matches === null) {
    // -- todo #182883164 [Sentry] implement
    return '';
  }
  if (matches.length > 1) {
    // -- todo #182883164 [Sentry] implement
  }
  return matches[0];
};

/**
 *
 */
export const interpolateConstant = (str: string, key: string): string => {
  return str.replace(new RegExp(`\{${key}\{.+?\}\}`, 'g'), parseConstant(str, key));
};

/**
 *
 */
export const interpolateVariable = (str: string, key: string, value: string | number): string => {
  return str.replace(`\[\[${key}\]\]`, value.toString());
};

/**
 *
 */
export const deconstruct = (str: string): [string, string, string] => {
  const deconstructedStr = str.split(/{{|}}/, 3);

  if (deconstructedStr.length < 3) {
    const reconstructedStr = deconstructedStr.join('');
    return [reconstructedStr, '', ''];
  } else {
    return [deconstructedStr[0], deconstructedStr[1], deconstructedStr[2]];
  }
};
