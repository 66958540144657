import { FC } from 'react';
import { SvgWrapper } from './svgWrapper';
import { SvgIconProps } from './types';

export const CheckmarkSvgIcon: FC<SvgIconProps> = ({ fill, className }) => (
  <SvgWrapper viewBox="0 0 14 10" className={className} fillClassName={fill}>
    <path
      d="M13.2619 2.62327C13.848 2.03713 13.848 1.07994 13.2619 0.493804C12.6758 -0.0923357 11.7186 -0.0923357 11.1324 0.493804L5.31405 6.31218L2.93723 3.93536C2.35109 3.34922 1.39391 3.34922 0.807769 3.93536C0.221629 4.5215 0.221629 5.47868 0.807769 6.06482L4.24932 9.50637C4.83546 10.0925 5.79264 10.0925 6.37878 9.50637L13.2619 2.62327Z"
      fill={fill}
    />
  </SvgWrapper>
);
