import { LinearProgress } from '@mui/material';
import { FC } from 'react';

export interface LinearProgressBarProps {
  readonly backgroundColor: string;
  readonly progressColor: string;
  readonly percentage: number; // number value from 0 to 100
  readonly height?: string;
  readonly borderRadius?: string;
}

export const LinearProgressBar: FC<LinearProgressBarProps> = ({ backgroundColor, progressColor, percentage, height, borderRadius }) => {
  return (
    <LinearProgress
      data-testid="determinate-linear-progress-bar"
      variant="determinate"
      value={percentage}
      sx={{
        borderRadius,
        height,
        width: '100%',
        backgroundColor: backgroundColor,
        '& .MuiLinearProgress-bar': {
          backgroundColor: progressColor
        }
      }}
    />
  );
};
