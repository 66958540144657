import { Address, AddressSerializable } from '../common/lib';
import { generateCategorySummary } from '../providers/categories/helpers';
import { DTOListingCategory, DTOListingMake, DTOListingModel } from '../providers/listings/dtos';
import { getListingConditionName, parseWaferSizeValues } from '../providers/listings/utils';
import { generateMakeSummary } from '../providers/makes/helpers';
import { generateModelSummary } from '../providers/models/helpers';
import { DTOUnconfirmedListing } from '../providers/unconfirmedListings/dtos';

export interface UnconfirmedListing {
  id: number;
  status: number;
  raw_make: string;
  raw_model: string;
  category: DTOListingCategory;
  make: DTOListingMake;
  model: DTOListingModel;
  price: number | 0;
  condition: number;
  condition_name: string;
  wafer_size_values: number[];
  vintage: number | null;
  quantity: number;
  serial_number: string | '';
  description: string;
  configuration: string;
  created_at: string;
  address: AddressSerializable;
}

export const getEmptyUnconfirmedListing = (): UnconfirmedListing => ({
  id: 0,
  status: 0,
  raw_make: '',
  raw_model: '',
  category: generateCategorySummary(),
  make: generateMakeSummary(),
  model: generateModelSummary(),
  price: 0,
  condition: 0,
  condition_name: '',
  wafer_size_values: [],
  vintage: null,
  quantity: 0,
  serial_number: '',
  description: '',
  configuration: '',
  created_at: '',
  address: new Address()
});

export const generateUnconfirmedListing = (props: Partial<UnconfirmedListing>): UnconfirmedListing => ({
  ...getEmptyUnconfirmedListing(),
  ...props
});

export const dtoToUnconfirmedListing = (unconfirmedListingDTO: DTOUnconfirmedListing): UnconfirmedListing => ({
  ...unconfirmedListingDTO,
  vintage: unconfirmedListingDTO.vintage !== 0 ? unconfirmedListingDTO.vintage : null,
  wafer_size_values: parseWaferSizeValues(unconfirmedListingDTO.wafer_size),
  condition_name: getListingConditionName(unconfirmedListingDTO),
  price: Number.parseFloat(unconfirmedListingDTO.price),
  address: new Address(unconfirmedListingDTO.address).toSerializable()
});

export const getMakeName = (unconfirmedListing: UnconfirmedListing): string => {
  return unconfirmedListing.model.id ? unconfirmedListing.make.name : unconfirmedListing.raw_make;
};

export const getModelName = (unconfirmedListing: UnconfirmedListing): string => {
  return unconfirmedListing.model.id ? unconfirmedListing.model.name : unconfirmedListing.raw_model;
};
