// * managed imports e.g. node_modules *
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// * external imports *
import { toRecord } from '@/src/state/services';

// * local imports *
import { CompanyCustomFields, EquipmentSerializable } from './types';

export interface State {
  byId: Record<string, EquipmentSerializable>;
  currentId: string;
  initialized: boolean;
  loading: boolean;
  customFields: CompanyCustomFields[];
}

export const initialState: State = {
  byId: {},
  currentId: '',
  initialized: false,
  loading: false,
  customFields: []
};

export const slice = createSlice({
  name: 'equipment2',
  initialState,
  reducers: {
    deleteOne: (state, action: PayloadAction<string>) => {
      state.byId = Object.entries(state.byId)
        .filter(([key, _value]) => key !== action.payload)
        .reduce((acc: Record<string, EquipmentSerializable>, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      if (action.payload === state.currentId) state.currentId = '';
    },
    deselect: (state) => {
      state.currentId = '';
    },
    updateEquipment: (state, action: PayloadAction<EquipmentSerializable>) => {
      state.byId[action.payload.id] = action.payload;
    },
    load: (state, action: PayloadAction<EquipmentSerializable[]>) => {
      const newById = toRecord<EquipmentSerializable>(action.payload);
      state.byId = { ...state.byId, ...newById };
    },
    loadCustomFields: (state, action: PayloadAction<CompanyCustomFields[]>) => {
      state.customFields = action.payload;
    },
    reset: () => initialState,
    select: (state, action: PayloadAction<string>) => {
      state.currentId = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (!state.initialized && !state.loading) state.initialized = true;
    }
  }
});

export const { deleteOne, deselect, load, loadCustomFields, reset, select, setLoading, updateEquipment } = slice.actions;

export default slice.reducer;
