import { FC, useState } from 'react';
import { useUser } from '@/src/hooks/useUser';
import { Box, Toolbar } from '@mui/material';
import { MobileHeader } from './mobile';
import LoginModal from '@/src/bits/modals/loginModal';
import { DesktopHeader } from './desktop';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useSelector } from 'react-redux';
import { userIsInternal } from '@/src/state/user/selectors';
import { colors } from '@/src/design/designConstants';
import { MaintenanceBanner } from './maintenanceBanner';
import { AnnouncementBanner } from './announcementBanner';

export interface HeaderProps {
  disableSearchBar?: boolean;
  hidden?: boolean;
}

export const Header: FC<HeaderProps> = ({ disableSearchBar = false, hidden = false }) => {
  const { logoutUser } = useUser();
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const { fullStoryEvent } = useFullStory();
  const internalUser = useSelector(userIsInternal);
  const onLogoutSubmit = async () => {
    await logoutUser();
  };

  const onLoginClick = () => {
    fullStoryEvent(CustomEvents.login.headerClick);
    setModal(<LoginModal setModal={setModal} />);
  };

  return (
    <>
      {modal}
      {!hidden && (
        <>
          {/* TODO: These banners are not directly visible, the other one sits on top of this
          but this is needed temporarily to offset the height of the page content when
          the maintenance banner is visible */}
          <MaintenanceBanner />
          <AnnouncementBanner />
          <Box sx={{ display: { xs: 'none', d: 'flex' } }}>
            <DesktopHeader onLoginClick={onLoginClick} />
          </Box>
          <Box sx={{ display: { d: 'none' } }}>
            <MobileHeader {...{ variant: 'default', onLoginClick, onLogoutSubmit, disableSearchBar }} />
          </Box>
          <Toolbar sx={{ backgroundColor: colors.surface.white }} />{' '}
          {/* this is to prevent the header from displaying on top of page content  */}
          <Box display={{ xs: 'none', d: 'block' }}>
            {/* This toolbar is for the desktop category navigation */}
            <Toolbar sx={{ backgroundColor: colors.surface.white }} />
          </Box>
          {internalUser && <Toolbar sx={{ backgroundColor: colors.surface.white }} />}
        </>
      )}
    </>
  );
};
