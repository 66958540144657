import type { Locale } from '../localization/types';
import { getDefinitions, interpolateVariable } from '../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class DashboardMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AreYouSureYouWantToDelete(): string {
    return this.definitions.AreYouSureYouWantToDelete;
  }

  public get SuccessfullyAddedToWishlist(): string {
    return this.definitions.SuccessfullyAddedToWishlist;
  }

  public get WhatsYourBudget(): string {
    return this.definitions.WhatsYourBudget;
  }

  // TODO Translations
  public get WhatsYourTargetPrice(): string {
    return this.definitions.WhatsYourTargetPrice;
  }

  public get NoActivityYet(): string {
    return this.definitions.NoActivityYet;
  }

  public get NoMakeOrModelSpecified(): string {
    return this.definitions.NoMakeOrModelSpecified;
  }

  public get IdColon(): string {
    return this.definitions.IdColon;
  }

  public get InReview(): string {
    return this.definitions.InReview;
  }

  public get Item(): string {
    return this.definitions.Item;
  }

  public get SetBudget(): string {
    return this.definitions.SetBudget;
  }

  // TODO Translations
  public get SetTargetPrice(): string {
    return this.definitions.SetTargetPrice;
  }

  public get UpdateBudget(): string {
    return this.definitions.UpdateBudget;
  }

  // TODO Translations
  public get UpdateTargetPrice(): string {
    return this.definitions.UpdateTargetPrice;
  }

  public get ContractDateColon(): string {
    return this.definitions.ContractDateColon;
  }

  public get OrderRefColon(): string {
    return this.definitions.OrderRefColon;
  }

  public get ViewDetails(): string {
    return this.definitions.ViewDetails;
  }

  public get VerifyYourEmailAddress(): string {
    return this.definitions.VerifyYourEmailAddress;
  }

  public WelcomeBackUsername(userName: string): string {
    return interpolateVariable(this.definitions.WelcomeBackUsername, 'user_name', userName);
  }

  public get UnconfirmedListings(): string {
    return this.definitions.UnconfirmedListings;
  }

  public get RecentlyViewed(): string {
    return this.definitions.RecentlyViewed;
  }

  public get ActivityFeed(): string {
    return this.definitions.ActivityFeed;
  }

  public get BasedOnYourActivity(): string {
    return this.definitions.BasedOnYourActivity;
  }

  public get SeeAll(): string {
    return this.definitions.SeeAll;
  }

  public get MoovInTheNews(): string {
    return this.definitions.MoovInTheNews;
  }

  public get FromTheBlog(): string {
    return this.definitions.FromTheBlog;
  }

  public get FindYourNextToolFromThousands(): string {
    return this.definitions.FindYourNextToolFromThousands;
  }

  public get PrivatePublic(): string {
    return this.definitions.PrivatePublic;
  }

  public PrivateWithCount(count: string): string {
    return interpolateVariable(this.definitions.PrivateWithCount, 'private_count', count);
  }

  public PublicWithCount(count: string): string {
    return interpolateVariable(this.definitions.PublicWithCount, 'public_count', count);
  }

  public ActiveWithCount(count: string): string {
    return interpolateVariable(this.definitions.ActiveWithCount, 'active_count', count);
  }

  public SoldWithCount(count: string): string {
    return interpolateVariable(this.definitions.SoldWithCount, 'sold_count', count);
  }

  public DisabledWithCount(count: string): string {
    return interpolateVariable(this.definitions.DisabledWithCount, 'disabled_count', count);
  }

  public get Both(): string {
    return this.definitions.Both;
  }

  public get AllLocation(): string {
    return this.definitions.AllLocation;
  }

  public get AllCategories(): string {
    return this.definitions.AllCategories;
  }

  public get AllModels(): string {
    return this.definitions.AllModels;
  }

  public get AllStatus(): string {
    return this.definitions.AllStatus;
  }

  public get NoLocation(): string {
    return this.definitions.NoLocation;
  }

  public get New(): string {
    return this.definitions.New;
  }
}
