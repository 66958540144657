import { ChangeEventHandler, FC } from 'react';

import { Textarea } from '@/src/bits/forms/fields/textarea';

import { SubProps } from '..';

export const Description: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setDescription: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    dispatch({
      type: 'setDescription',
      value: e.target.value
    });
  };
  return (
    <Textarea
      minRows={2}
      value={state.description}
      onChange={setDescription}
      label={loc.Listing.Description}
      placeholder={loc.Forms.DescribeYourItem}
    />
  );
};
