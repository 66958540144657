import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Divider, Link, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { getAllRemarketingInfo } from '@/src/exclusives/selectors';
import { useAppSelector } from '@/src/store';
import { type FC, useState, type MouseEvent } from 'react';
import routes from '@/src/routes';
import { colors } from '@/src/design/designConstants';

export const Exclusives: FC = () => {
  const remarketingInfo = useAppSelector(getAllRemarketingInfo);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box alignSelf="center">
      <Button onClick={handleClick} variant="text" color="secondary">
        <Typography variant={'body1'} textTransform={'uppercase'} fontWeight="600">
          EXCLUSIVES
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} data-testid="browse-menu">
        <Link underline="none" href={routes.marketplace()} color="secondary">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            {/* TODO: Translations #185801497 - Browse All Equipment */}
            Browse All Equipment
          </MenuItem>
        </Link>

        <Divider />

        <Typography variant="caption" px="16px" py="8px" display="block" color={colors.smokeyGray[400]}>
          {/* TODO: Translations #185801497 - EXCLUSIVES */}
          EXCLUSIVES
        </Typography>
        {[
          ...remarketingInfo.map((info) => {
            if (!info.remarketing_name) return null;

            return (
              <Link underline="none" href={routes.exclusives(info.remarketing_slug)} color="secondary" key={info.remarketing_slug}>
                <MenuItem onClick={handleClose}>{info.remarketing_name}</MenuItem>
              </Link>
            );
          }),
          <Link underline="none" href={routes.exclusivesAll()} color="secondary" key={'all-exclusive-link'}>
            <MenuItem onClick={handleClose}>View All Exclusives</MenuItem>
          </Link>
        ]}
      </Menu>
    </Box>
  );
};
