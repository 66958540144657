import { getDefinitions, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class UnsubscribeLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ContactFeedbackConcerns(): string {
    return this.definitions.ContactFeedbackConcerns;
  }

  public get ErrorUnsubscribingPleaseEmail(): string {
    return this.definitions.ErrorUnsubscribingPleaseEmail;
  }

  public get SorrySeeGo(): string {
    return this.definitions.SorrySeeGo;
  }

  public get SureStopEmailsRelevant(): string {
    return this.definitions.SureStopEmailsRelevant;
  }

  public get Unsubscribe(): string {
    return this.definitions.Unsubscribe;
  }

  public get YouBeenUnsubscribed(): string {
    return this.definitions.YouBeenUnsubscribed;
  }
}
