import { FC, useEffect } from 'react';
import { CheckSvgIcon, RejectXIcon } from '../bits/svgs/svgs';

import { Overline, PlaceholderText } from '../bits/text';
import { formatDaysAgo } from '../utils';
import { useLocalizer } from '../localization';
import { ListingActionStatus } from '../entities/listing';
import { useAnyOpportunities } from '../hooks/opportunities/useAnyOpportunities';
import { ExclusiveBadging } from '../exclusives/ui/listingCardResults/exclusiveBadging';
import { getRemarketingInfoBySlug } from '../exclusives/selectors';
import { useAppSelector, AppState } from '../store';
import { Box } from '@mui/material';
import { useFullStory } from '../hooks/useFullStory/useFullStory';
import { CustomEvents } from '../hooks/useFullStory/customEvents';
import { useDisplayMarketingPrice } from '../listings/hooks';
import { MarketingPrice } from '../marketplace/listingV2/marketingPrice';
import { spacing } from '../design/designConstants';

interface SuggestedListingInfoProps {
  readonly className?: string;
}

export const SuggestedListingInfo: FC<SuggestedListingInfoProps> = (props) => {
  const { className = '' } = props;
  const { currentListing } = useAnyOpportunities();
  const loc = useLocalizer();
  const { fullStoryEvent } = useFullStory();
  const { shouldDisplayMarketingPrice } = useDisplayMarketingPrice(currentListing);

  const remarketingInfo = useAppSelector((state: AppState) => getRemarketingInfoBySlug(state, currentListing.remarketing_slug));

  useEffect(() => {
    fullStoryEvent(CustomEvents.opportunity.viewed, { listingId: currentListing.id });
  }, [currentListing.id]);

  return (
    <div className={className} id="opportunity-action-box">
      {remarketingInfo && (
        <Box mb={spacing.xl}>
          <ExclusiveBadging listing={currentListing} />
        </Box>
      )}

      <div className="flex justify-between">
        <h6 className="text-20spx leading-6 lg:mb-2">{currentListing.make.name}</h6>
        {currentListing.opportunity_status === ListingActionStatus.rejected && <RejectXIcon fill="#FFFFFF" className="h-10 w-10" />}
        {currentListing.opportunity_status === ListingActionStatus.approved && <CheckSvgIcon fill="#FFFFFF" className="h-10 w-10" />}
      </div>

      <h3 className="mb-2 font-inter text-36spx font-bold leading-10 lg:mb-4">{currentListing.model.name}</h3>
      <div className="mb-4 flex flex-col justify-between gap-x-6 lg:flex-row">
        <div>
          <Overline className="text-gray-400">{loc.Nav.Category}</Overline>
          <h6 className="text-20spx font-bold leading-tight">{currentListing.category.name}</h6>
        </div>
        <div className="flex items-end lg:text-right">
          <p className="flex flex-wrap justify-end gap-x-1 text-14spx leading-snug lg:text-16spx">
            <strong>{loc.Listing.LastVerifiedColon}</strong>{' '}
            {currentListing.verified_at_ts !== null ? (
              <span>{formatDaysAgo(new Date(currentListing.verified_at_ts * 1000), new Date(), loc)}</span>
            ) : (
              <PlaceholderText>{loc.Listing.Unknown}</PlaceholderText>
            )}
          </p>
        </div>
      </div>
      {shouldDisplayMarketingPrice && <MarketingPrice listing={currentListing} />}
    </div>
  );
};
