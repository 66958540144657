import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategorySerializable } from '@/src/providers/categories/helpers';

export interface CategoryState {
  byId: Record<string, CategorySerializable>;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  idsForFilters: number[];
}

export const initialState: CategoryState = {
  byId: {},
  loading: false,
  loaded: false,
  error: false,
  idsForFilters: []
};

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategories: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = false;
    },
    loadCategories: (state, action: PayloadAction<CategorySerializable[]>) => {
      action.payload.forEach((category) => {
        state.byId[category.id] = category;
      });
      state.loading = false;
      state.loaded = true;
      state.error = false;
    },
    loadCategoriesError: (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = true;
    },
    loadCategory: (state, action: PayloadAction<CategorySerializable>) => {
      state.byId[action.payload.id] = action.payload;
    },
    loadCategoriesForFilters: (state, action: PayloadAction<CategorySerializable[]>) => {
      action.payload.forEach((category) => {
        state.byId[category.id] = category;
      });
      state.idsForFilters = action.payload.map((category) => category.id);
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const { fetchCategories, loadCategories, loadCategoriesError, loadCategory, loadCategoriesForFilters } = slice.actions;

export default slice.reducer;
