import { colors } from '@/src/design/designConstants';
import { SuggestedListing } from '@/src/entities/listing';
import { useDisplayMarketingPrice } from '@/src/listings/hooks';
import { Listing2 } from '@/src/listings/types';
import { useLocalizer } from '@/src/localization';
import { parseUSDCurrencyStr } from '@/src/utils';
import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { LoginMask } from '../../design/kilos/loginMask/loginMask';
import { loginMaskClickEnum } from '../../state/tracking/fullstory/customEvents/register';
interface MarketingPriceProps {
  readonly listing: Listing2 | SuggestedListing;
}

const LoginMaskText = () => (
  <>
    <Typography color="primary" variant="button14AllCaps">
      {'LOGIN'} <Typography variant="button14AllCaps" color={colors.moovBlue[800]}>{`TO SEE PRICE`}</Typography>
    </Typography>
  </>
);

export const MarketingPrice: FC<MarketingPriceProps> = (props) => {
  const { listing } = props;
  const loc = useLocalizer();
  const { shouldDisplayMarketingPrice } = useDisplayMarketingPrice(listing);

  return (
    <>
      {shouldDisplayMarketingPrice && (
        <LoginMask maskText={<LoginMaskText />} fullstoryEventType={loginMaskClickEnum.LISTING_PRICE}>
          <Stack>
            <Typography variant="overline" color={colors.smokeyGray['400']}>
              {loc.EquipmentManage.AskingPrice.toUpperCase()}
            </Typography>
            <Typography variant="h4" color={colors.crayolaGreen[500]}>
              {parseUSDCurrencyStr(listing.marketing_price)}
            </Typography>
          </Stack>
        </LoginMask>
      )}
    </>
  );
};
