import { FC } from 'react';

import { SubProps } from '..';
import { MultiSelectWithOptions } from '@/src/design/bytes/multiSelectWithOptions';
import { FieldLabel } from '@/src/bits/forms/fields/fieldLabel';
import { waferSizeOptions } from '@/src/common/lib';

export const WaferSizes: FC<SubProps> = ({ state, loc, dispatch }) => {
  const setWaferSize = (val: number[]) => {
    dispatch({
      type: 'setWaferSizes',
      value: val
    });
  };

  return (
    <FieldLabel label={loc.Listing.WaferSize}>
      <MultiSelectWithOptions
        placeholder={loc.EquipmentManage.SelectWaferSize}
        value={waferSizeOptions.filter((option) => state.waferSizes?.includes(parseInt(option.id))).map((opt) => opt.id.toString())}
        options={waferSizeOptions}
        handleChange={(ids) => {
          setWaferSize(ids.map((id) => parseInt(id)));
        }}
        size="medium"
        data-testid="add-equipment-modal-wafer-size-select"
      />
    </FieldLabel>
  );
};
