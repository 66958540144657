import { FC } from 'react';
import { InternalLink } from '../../bits/links';
import routes from '../../routes';
import { useSidebar } from '../../hooks/useSidebar';
import { useLocalizer } from '../../localization';
import { NavLink } from '../types';
import { getSelectedStyling } from './helpers';
import { useRouter } from 'next/router';

export interface NavItemWithoutIconProps {
  link: NavLink.BUY | NavLink.SELL;
}

export const NavItemWithoutIcon: FC<NavItemWithoutIconProps> = ({ link }) => {
  const router = useRouter();
  const { pathname } = router;
  const { closeSidebar } = useSidebar();
  const loc = useLocalizer();

  let newRoute;
  let linkName;

  switch (link) {
    case NavLink.BUY:
      newRoute = routes.buy();
      linkName = loc.Nav.Buy;
      break;
    case NavLink.SELL:
      newRoute = routes.sell({});
      linkName = loc.Nav.Sell;
      break;
    default:
      throw new Error('Invalid NavLink');
  }

  const isSelected = pathname === newRoute;

  const getClassName = () => {
    return ' text-16spx' + getSelectedStyling(isSelected);
  };

  return (
    <InternalLink onClick={closeSidebar} href={newRoute} className={`rounded p-2 uppercase ${getClassName()}`}>
      {linkName.toUpperCase()}
    </InternalLink>
  );
};
