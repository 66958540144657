import TAILWIND_CSS_CONFIG from '../../../tailwind.config';

export const getExpandedStyling = (isExpanded: boolean) => {
  const expandedStyling = ' flex-row min-h-6 text-14spx';
  const unexpandedStyling = ' flex-col items-center text-center text-11spx whitespace-nowrap';
  return isExpanded ? expandedStyling : unexpandedStyling;
};

export const getSelectedStyling = (isSelected: boolean) => {
  const selectedStyling = ' bg-moov-blue-50 font-semibold text-moov-blue-500';
  const unselectedStyling = ' hover:text-magenta-500 hover:bg-moov-blue-50';
  return isSelected ? selectedStyling : unselectedStyling;
};

export const getFillColor = (isSelected: boolean) => {
  return isSelected ? TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500'] : TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['600'];
};
