import { FC } from 'react';
import { SubProps } from '..';
import { FormControl, FormLabel } from '@mui/material';
import { RequiredLabel } from '@/src/design/bits/requiredLabel';
import { useAppSelector } from '@/src/store';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { CountryAutocomplete } from '@/src/design/kilos/countrySelect';

export const Location: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setAddress = (country: string) => {
    dispatch({
      type: 'setAddress',
      value: { country }
    });
  };

  const countryOptions = useAppSelector(getLogisticCountries);

  return (
    <FormControl>
      <FormLabel>
        {loc.Listing.Location}
        <RequiredLabel />
      </FormLabel>
      <CountryAutocomplete
        data-testid="equipment-modal-country-select"
        options={countryOptions}
        value={countryOptions.find((option) => option.id === state.address.country) || null}
        handleChange={(country) => setAddress(country?.id || '')}
        size="medium"
      />
    </FormControl>
  );
};
