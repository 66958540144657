import type { FC } from 'react';

import Select, { MultiValue, SingleValue } from 'react-select';

export type SelectOption = {
  value: string | number;
  label: string;
};

export type SelectFieldOnChangeHandler = (option: MultiValue<SelectOption> | SingleValue<SelectOption>) => void;

export interface SelectFieldProps {
  readonly id: string;
  readonly options: SelectOption[];
  readonly value?: string | number | (string | number)[];
  readonly placeholder?: string;
  readonly isMulti?: boolean;
  readonly isClearable?: boolean;
  readonly onChange: SelectFieldOnChangeHandler;
  readonly clearOnUndefined?: boolean;
}

export const isSingleValue = (option: MultiValue<SelectOption> | SingleValue<SelectOption>): option is SingleValue<SelectOption> => {
  return !Array.isArray(option);
};

export const isSelectOption = (value: unknown): value is SelectOption => {
  return (
    !!value &&
    typeof value === 'object' &&
    ['string', 'number'].includes(typeof (value as SelectOption).value) &&
    typeof (value as SelectOption).label === 'string'
  );
};

const SelectField: FC<SelectFieldProps> = ({ id, options, value, placeholder, isMulti, isClearable, onChange, clearOnUndefined }) => {
  const getValue = () => {
    if (isMulti && Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    }
    const valReturn = options.find((option) => option.value === value);
    if (clearOnUndefined && valReturn === undefined) {
      return null;
    }
    return valReturn;
  };

  return (
    <Select
      id={id}
      instanceId={id}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder}
      closeMenuOnSelect={!isMulti}
      classNamePrefix="react-select"
    />
  );
};

export default SelectField;
