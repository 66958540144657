import type { FC } from 'react';
import { Tooltip } from '@mui/material';

import { ExternalLink } from '../links';
import { Caption } from '../text';
import { FileIconLarge, FileIconProps, FileIconSmall } from './fileIcon';

interface FileLinkProps extends FileIconProps {
  readonly downloadUrl: string;
}

export const FileLinkWithLargeIcon: FC<FileLinkProps> = ({ file, downloadUrl }) => (
  <Tooltip classes={{ tooltip: 'text-14spx' }} title={file.name} placement="top" arrow enterDelay={0} enterTouchDelay={0}>
    {/* 
      This extra div wrapping the link serves to avoid a legacy ref error
      thrown by MUI's Tooltip which happens when the immediate child is an anchor tag
    */}
    <div className="group w-full">
      <ExternalLink href={downloadUrl}>
        <div className="flex justify-center">
          <FileIconLarge file={file} />
        </div>
        <Caption className="mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-center text-magenta-500 group-hover:underline group-focus-visible:underline">
          {file.title || file.name}
        </Caption>
      </ExternalLink>
    </div>
  </Tooltip>
);
export const FileLinkWithSmallIcon: FC<FileLinkProps> = ({ file, downloadUrl }) => {
  return (
    <Tooltip classes={{ tooltip: 'text-14spx' }} title={file.name} placement="top" arrow enterDelay={0} enterTouchDelay={0}>
      {/* 
        This extra div wrapping the link serves to avoid a legacy ref error
        thrown by MUI's Tooltip which happens when the immediate child is an anchor tag
      */}
      <div className="max-w-fit">
        <ExternalLink href={downloadUrl} className="group flex gap-x-2 overflow-hidden">
          <div>
            <FileIconSmall file={file} />
          </div>
          <p className="overflow-hidden text-ellipsis text-magenta-500 group-hover:underline group-focus-visible:underline lg:whitespace-nowrap">
            {file.title || file.name}
          </p>
        </ExternalLink>
      </div>
    </Tooltip>
  );
};
