import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModelConfigType {
  TYPE_TEXT = 1,
  TYPE_NUMBER = 2,
  TYPE_SELECT = 3,
  TYPE_MULTI_SELECT = 4,
  TYPE_BOOLEAN = 5,
  TYPE_RANGE = 6
}

export interface ModelConfigQuestion {
  id: number;
  question_label: string;
  question: string;
  model_id: number;
  help_text: string;
  options: { label: string; value: string }[];
  type: ModelConfigType;
}

export interface ModelConfigQuestionState {
  byModelId: Record<string, ModelConfigQuestion[]>;
  error: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ModelConfigQuestionState = {
  byModelId: {},
  error: false,
  loading: false,
  loaded: false
};

/** createSlice() is a function that accepts an initial state, an object full of reducer functions, and a "slice name",
 *  and automatically generates action creators and action types that correspond to the reducers and state.
 *  This API is the standard approach for writing Redux logic.  */
export const slice = createSlice({
  name: 'modelConfigQuestions',
  initialState,
  reducers: {
    loadModelConfigQuestions: (state, action: PayloadAction<ModelConfigQuestion[]>) => {
      const newById = action.payload.reduce((acc: Record<string, ModelConfigQuestion[]>, curr) => {
        if (!acc[curr.model_id.toString()]) {
          acc[curr.model_id.toString()] = [curr];
        } else {
          acc[curr.model_id.toString()].push(curr);
        }
        return acc;
      }, {});

      state.byModelId = { ...state.byModelId, ...newById };
      state.loading = false;
      state.loaded = true;
      state.error = false;
    },
    fetchModelConfigQuestionsStart: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = false;
    },
    fetchModelConfigQuestionsFailed: (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = true;
    }
  }
});

// Actions synchronously modify state and are created for us by `createSlice()`
// we export them here for ease of use in other slices and thunks
export const { loadModelConfigQuestions, fetchModelConfigQuestionsStart, fetchModelConfigQuestionsFailed } = slice.actions;

export default slice.reducer;
