import { FC } from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import { getAlertBannerState } from '../state/banner/selectors';
import { useAppSelector } from '../store';
import { useGlobalAlertBanner } from '../hooks/useGlobalAlertBanner';
import { FAIcon } from '../design/bits/fAIcon/fAIcon';
import { icons } from '../design/bits/fAIcon/icons';

export const Banner: FC = ({ children }) => {
  const { message, bannerType, duration, visible } = useAppSelector(getAlertBannerState);
  const { closeBanner } = useGlobalAlertBanner();

  const onClose = () => {
    closeBanner();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={visible}
        autoHideDuration={duration * 1000}
        onClose={onClose}
        sx={{
          display: 'block',
          maxWidth: 'fit-content',
          margin: '0 auto'
        }}
      >
        <Alert
          variant="filled"
          severity={bannerType}
          className="shadow-basic"
          action={
            <IconButton size="small" color="inherit" onClick={onClose}>
              <FAIcon icon={icons.xMark} />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </>
  );
};
