import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

import { getModalState } from '@/src/state/globalModal/selectors';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { ContactForm, ContactFormValues } from '@/src/globalComponents/globalModal/forms/contactForm';
import { conditions, Condition, priorities, Priority, waferSizes, WaferSize } from '@/src/common/lib';
import { spacing } from '@/src/design/designConstants';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { useLocalizer } from '@/src/localization';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { GlobalModalTypes, closeModal, openModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { ERROR_MESSAGES } from '@/src/constants';
import { isContactFormSubmitDisabled } from '@/src/globalComponents/globalModal/forms/contactForm/helpers';
import { modelFormRequest } from '@/src/state/forms/thunks';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { isDTOLeadDefined } from '@/src/providers/leads/types';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { ModelSearchDropdown } from '@/src/bits/forms/fields/modelSearchDropdown';
import * as yup from 'yup';
import { getMakeModelSchema } from '@/src/validationSchemas/listing';
import { ModelForms } from '@/src/state/forms/thunks';
import { ExistingUserRegisterAccount } from '@/src/state/tracking/fullstory/customEvents/register';

export interface FindOneAnonFormValues extends ContactFormValues {
  always_looking: boolean;
  appraisal: boolean;
  budget: string;
  condition: Condition;
  config: string;
  destination: string;
  installation: boolean;
  insurance: boolean;
  logistics: boolean;
  model_id?: number;
  priority: Priority;
  required_by: Date | null;
  wafer_size: WaferSize[];
  form_name: ModelForms;
}

export function FindOneAnon() {
  const [step, setStep] = useState<number>(1);

  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const { fullStoryEvent } = useFullStory();
  const {
    modalProps: { findOne }
  } = useAppSelector(getModalState);

  const initialValues: FindOneAnonFormValues = {
    always_looking: false,
    budget: '0',
    appraisal: false,
    company: '',
    condition: Condition.Used,
    config: '',
    contact_handle: '',
    contact_preference: 8,
    destination: '',
    email: '',
    first_name: '',
    insurance: true,
    last_name: '',
    logistics: true,
    model_id: findOne?.model?.id || 0,
    phone: '',
    priority: Priority.Medium,
    installation: false,
    required_by: null,
    wafer_size: [],
    form_name: ModelForms.FIND_ONE_FOR_ME
  };

  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).concat(
      yup.object().shape({
        model_id: yup.lazy(() => {
          // Only validate model_id if we are past the contact form on step one
          if (step > 1) {
            return getMakeModelSchema(loc);
          }
          return yup.number().notRequired();
        })
      })
    )
  );

  const { openErrorAlert } = useGlobalAlertBanner();

  async function actionHandler() {
    switch (step) {
      case 1:
        if (await formValidationHook.validateForm()) {
          setStep(2);
        } else {
          openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
        }
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        fullStoryEvent(CustomEvents.model.findOneSubmit, { modelId: formValidationHook.formValues.model_id });
        return dispatch(
          modelFormRequest({
            always_looking: formValidationHook.formValues.always_looking,
            budget: formValidationHook.formValues.budget,
            appraisal: formValidationHook.formValues.appraisal,
            company: formValidationHook.formValues.company,
            condition: formValidationHook.formValues.condition,
            config: formValidationHook.formValues.config,
            contact_handle: formValidationHook.formValues.contact_handle,
            contact_preference: formValidationHook.formValues.contact_preference,
            destination: formValidationHook.formValues.destination,
            email: formValidationHook.formValues.email,
            first_name: formValidationHook.formValues.first_name,
            insurance: formValidationHook.formValues.insurance,
            last_name: formValidationHook.formValues.last_name,
            logistics: formValidationHook.formValues.logistics,
            model_id: formValidationHook.formValues.model_id,
            phone: formValidationHook.formValues.phone,
            priority: formValidationHook.formValues.priority,
            installation: formValidationHook.formValues.installation,
            required_by: formValidationHook.formValues.required_by,
            wafer_size: formValidationHook.formValues.wafer_size,
            form_name: ModelForms.FIND_ONE_FOR_ME
          })
        )
          .then((lead) => {
            if (isDTOLeadDefined(lead) && !lead.user_id) {
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.CREATE_ACCOUNT,
                  modalProps: {
                    createAccount: {
                      leadInfo: {
                        email: lead.email,
                        key: lead.key,
                        first_name: lead.first_name,
                        last_name: lead.last_name,
                        company: lead.company,
                        phone: lead.phone
                      }
                    }
                  }
                })
              );
            } else {
              const email = formValidationHook.formValues.email;
              fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_PROMPTED, { email });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.EXISTING_ACCOUNT_RESET_PASSWORD,
                  modalProps: { existingAccountResetPassword: { email } }
                })
              );
            }
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      default:
        openErrorAlert(ERROR_MESSAGES.REFRESH);
        dispatch(closeModal());
        break;
    }
  }

  const step1 = <ContactForm formValidationHook={formValidationHook} />;

  // TODO: Translations
  const step2 = (
    <>
      <ModelSearchDropdown
        value={Number(formValidationHook.formValues.model_id)}
        onChange={(value) => {
          formValidationHook.setFieldValue('model_id', value);
        }}
        onBlur={() => {
          formValidationHook.validateField('model_id', formValidationHook.formValues.model_id);
          formValidationHook.touchField('model_id');
        }}
        hasErrored={!!formValidationHook.formErrorState.model_id}
        errorMessage={formValidationHook.formErrorState.model_id}
        touched={formValidationHook.formTouchedState.model_id}
        validateOnTouchOrSubmit
        required
        size="medium"
      />
      <Stack>
        <InputLabel>{loc.Forms.TargetPrice}</InputLabel>
        <MaskedInput
          inputMode="numeric"
          mask={createNumberMask({
            allowDecimal: true,
            allowLeadingZeroes: false,
            allowNegative: false,
            decimalSymbol: '.',
            decimalLimit: 2,
            includeThousandsSeparator: true,
            integerLimit: 12,
            prefix: '',
            suffix: '',
            thousandsSeparatorSymbol: ','
          })}
          render={(innerRef, props) => (
            <OutlinedInput {...props} inputRef={innerRef} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
          )}
          value={formValidationHook.formValues.budget}
          onChange={(e) => {
            const v = e.target.value.replace(/,/g, '');
            formValidationHook.setFieldValue('budget', v);
          }}
        />
      </Stack>
      <Stack>
        <InputLabel>Required By</InputLabel>
        <DatePicker
          value={formValidationHook.formValues.required_by}
          onChange={(v) => formValidationHook.setFieldValue('required_by', v)}
        />
      </Stack>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ ml: -1.5 }}
            checked={formValidationHook.formValues.always_looking}
            onChange={(_, v) => formValidationHook.setFieldValue('always_looking', v)}
          />
        }
        label="Always Looking"
      />
      <Stack>
        <InputLabel>Destination Address</InputLabel>
        <TextField
          placeholder="Tempe, AZ"
          value={formValidationHook.formValues.destination}
          onChange={(e) => formValidationHook.setFieldValue('destination', e.target.value)}
        />
      </Stack>
      <Stack>
        <InputLabel>{loc.ToolRequest.MinimumConfiguration}</InputLabel>
        <TextField
          multiline
          rows={4}
          value={formValidationHook.formValues.config}
          onChange={(e) => formValidationHook.setFieldValue('config', e.target.value)}
          inputProps={{ 'data-testid': 'anon-find-one-minimum-config-textarea' }}
        />
      </Stack>
    </>
  );

  const step3 = (
    <>
      <Stack>
        <InputLabel>{loc.EquipmentManage.Condition}</InputLabel>
        <Select
          value={formValidationHook.formValues.condition}
          onChange={(e) => formValidationHook.setFieldValue('condition', e.target.value)}
        >
          {conditions.map((e) => {
            return (
              <MenuItem key={e.id} value={e.id}>
                {e.label}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
      <Stack data-testid="anon-find-one-wafer-size-container">
        <InputLabel>{loc.EquipmentManage.WaferSize}</InputLabel>
        <Select
          multiple
          value={formValidationHook.formValues.wafer_size}
          onChange={(e) => formValidationHook.setFieldValue('wafer_size', e.target.value)}
        >
          {waferSizes.map((e) => {
            return (
              <MenuItem key={e.id} value={e.id}>
                {e.label}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
      <Stack>
        <InputLabel>{loc.ToolRequest.Priority}</InputLabel>
        <Select
          value={formValidationHook.formValues.priority}
          onChange={(e) => formValidationHook.setFieldValue('priority', e.target.value)}
        >
          {priorities.map((e) => {
            if (e.id !== Priority.Automated)
              return (
                <MenuItem key={e.id} value={e.id}>
                  {e.label}
                </MenuItem>
              );
          })}
        </Select>
      </Stack>
      <Stack>
        <InputLabel>Do you require any of the following services?</InputLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.insurance}
                onChange={(_, v) => formValidationHook.setFieldValue('insurance', v)}
              />
            }
            label={loc.Common.Insurance}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.logistics}
                onChange={(_, v) => formValidationHook.setFieldValue('logistics', v)}
              />
            }
            label={loc.Common.Logistics}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.appraisal}
                onChange={(_, v) => formValidationHook.setFieldValue('appraisal', v)}
              />
            }
            label="Appraisal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.installation}
                onChange={(_, v) => formValidationHook.setFieldValue('installation', v)}
              />
            }
            label="Install Assistance"
          />
        </FormGroup>
      </Stack>
    </>
  );

  const steps: { [key: string]: React.JSX.Element } = {
    1: step1,
    2: step2,
    3: step3
  };

  const isSubmitDisabled = () => {
    if (step === 1) {
      return isContactFormSubmitDisabled(formValidationHook);
    }
    if (step === 2) {
      return !Boolean(formValidationHook.formValues.model_id);
    }
    return false;
  };

  return (
    <Stack spacing={spacing.xl}>
      <WorkflowModal
        actionButtonContent={step === 3 ? <div>Submit</div> : <div>Next</div>}
        actionButtonDisabled={isSubmitDisabled()}
        actionButtonSubmit={actionHandler}
        displayBackButton={true}
        closeModal={() => dispatch(closeModal())}
        currentStep={step}
        modalOpen
        setCurrentStep={setStep}
        totalSteps={3}
        title={loc.Common.FindOneForMe}
      >
        <Stack spacing={spacing.xl} width={{ d: '600px' }}>
          {steps[step] ?? <></>}
        </Stack>
      </WorkflowModal>
    </Stack>
  );
}
