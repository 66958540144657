import { AppState } from '@/src/store';
import { createSelector } from '@reduxjs/toolkit';

import { Equipment2, Status } from './types';
import { toRecord } from '@/src/state/services';
import { WaferSize } from '../common/lib';

export const getCustomFields = (store: AppState) => {
  return store.equipment2.customFields;
};

export function getByIdAll(store: AppState) {
  return store.equipment2.byId;
}

export function getCurrentId(store: AppState): string | null {
  return store.equipment2.currentId;
}

// use createSelector to automatically memoize the result of this selector and prevent unnecessary re-renders
export const getAll = createSelector([getByIdAll], (byId) => {
  return Object.values(byId).map((e) => Equipment2.anyToDto(e));
});

export const getActiveAndListed = createSelector([getAll], (all) => {
  const r = all.map((e) => Equipment2.anyToDto(e));
  return r.filter((e) => e.status === Status.Active || e.status === Status.Listed);
});

export const getById = createSelector([getByIdAll, (_, id) => id], (byIdAll, id) => {
  return byIdAll[id];
});

export const getCurrent = createSelector([getCurrentId, getByIdAll], (currentId, byId) => {
  let r: Equipment2 = new Equipment2();
  if (currentId) {
    const s = byId[currentId];
    if (s) {
      r = Equipment2.anyToDto(s);
    }
  }
  return r;
});

export const isEquipmentSelected = createSelector([getCurrentId], (currentId) => {
  return !!currentId;
});

export const getByIdCount = createSelector([getByIdAll], (byId) => {
  return Object.keys(byId).length;
});

export const getActiveListedUniqueMakes = createSelector(getActiveAndListed, (equipment) => {
  return Object.values(toRecord(equipment.map((e) => e.make).filter((e) => e.name)));
});

export const getActiveListedUniqueModels = createSelector(getActiveAndListed, (equipment) => {
  return Object.values(toRecord(equipment.map((e) => e.model).filter((e) => e.name)));
});

export const getActiveListedUniqueConditions = createSelector(getActiveAndListed, (equipment) => {
  return [...new Set(equipment.map((e) => e.condition))];
});

export const getActiveListedUniqueCountries = createSelector(getActiveAndListed, (equipment) => {
  return [...new Set(equipment.map((e) => e.address.country).filter((e) => e))];
});

export const getActiveListedUniqueCategories = createSelector(getActiveAndListed, (equipment) => {
  return Object.values(toRecord(equipment.map((e) => e.category).filter((e) => e.name)));
});

export const getActiveListedUniqueWaferSizes = createSelector(getActiveAndListed, (equipment) => {
  return [...new Set(equipment.map((e) => e.wafer_size_values).flat())] as WaferSize[];
});

// use createSelector to automatically memoize the result of this selector and prevent unnecessary re-renders
export const getLoadingState = createSelector(
  [(state: AppState) => state.equipment2.initialized, (state: AppState) => state.equipment2.loading],
  (initialized, loading) => {
    return { initialized, loading };
  }
);
