import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageThreadSerializable } from './types';

export interface MessagesState {
  byThreadKey: Record<string, MessageThreadSerializable>;
  byThreadKeyOrder: string[];
  currentThreadKey: string;
  initialized: boolean;
  loading: boolean;
  error: boolean;
  unreadCount: number;
}

export const initialState: MessagesState = {
  byThreadKey: {},
  byThreadKeyOrder: [],
  currentThreadKey: '',
  initialized: false,
  loading: false,
  error: false,
  unreadCount: 0
};

export const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    selectMessageOnLoad: (state) => {
      const noCurrentThreadAndThreadsExist = !state.currentThreadKey && state.byThreadKeyOrder.length;
      const currentThreadDoesNotExistAndThreadsExist =
        !state.byThreadKeyOrder.includes(state.currentThreadKey) && state.byThreadKeyOrder.length;
      if (noCurrentThreadAndThreadsExist || currentThreadDoesNotExistAndThreadsExist) {
        state.currentThreadKey = state.byThreadKeyOrder[0];
      } else if (!state.byThreadKeyOrder.length) {
        state.currentThreadKey = '';
      }
    },
    loadMessages: (state, action: PayloadAction<MessageThreadSerializable[]>) => {
      state.byThreadKeyOrder = action.payload.map((thread) => thread.key);
      state.byThreadKey = action.payload.reduce((acc: Record<string, MessageThreadSerializable>, cur: MessageThreadSerializable) => {
        acc[cur.key] = cur;
        return acc;
      }, {});
      state.initialized = true;
      state.loading = false;
    },
    setError: (state) => {
      state.loading = false;
      state.error = true;
    },
    selectMessage: (state, action: PayloadAction<string>) => {
      state.currentThreadKey = action.payload;
    },
    deselectMessage: (state) => {
      state.currentThreadKey = '';
    },
    updateThread: (state, action: PayloadAction<MessageThreadSerializable>) => {
      state.byThreadKey[action.payload.key] = action.payload;
    },
    setUnreadMessageCount: (state, action: PayloadAction<number>) => {
      state.unreadCount = action.payload;
    }
  }
});

export const {
  loadMessages,
  setLoading,
  selectMessage,
  setError,
  updateThread,
  setUnreadMessageCount,
  selectMessageOnLoad,
  deselectMessage
} = slice.actions;

export default slice.reducer;
