import { useContext } from 'react';
import { MobileSuggestedItemsModalDispatch } from '../../../../../state/mobileSuggestedItemsModal/context';
import { useGlobalAlertBanner } from '../../../../../hooks/useGlobalAlertBanner';
import { SuggestedItemsSteps } from '../types';
import { useQuestionsFormSubmit } from '../../../../../hooks/useSubmitQuestionsForm';
import { useSubmitRejectReasonForm } from '../../../../../hooks/useSubmitRejectReasonForm';
import { ERROR_MESSAGES } from '../../../../../constants';
import {
  WorkflowButtons,
  ActionButtonData,
  isQuestionsForm,
  isRejectOpportunityForm
} from '../../../../../state/mobileSuggestedItemsModal/types';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';

export const useMobileSuggestedItemsHandleSubmit = () => {
  const actions = useContext(MobileSuggestedItemsModalDispatch);
  const { openErrorAlert } = useGlobalAlertBanner();
  const questionsFormSubmit = useQuestionsFormSubmit();
  const rejectReasonFormSubmit = useSubmitRejectReasonForm();
  const { fullStoryEvent } = useFullStory();

  const onFailure = (errorMessage: ERROR_MESSAGES) => {
    actions.setCurrentStepSubmitFailed(WorkflowButtons.ACTION);
    openErrorAlert(errorMessage);
  };
  const onSuccess = () => {
    actions.setCurrentStepSubmitted(WorkflowButtons.ACTION);
    actions.goToStep(SuggestedItemsSteps.SHOW_NEXT);
  };
  return {
    submitQuestions: ({ state, useOpportunityPayload: { currentListing }, user }: ActionButtonData) => {
      if (state.props.steps) {
        const formValues = state.props.steps[state.currentStep].formValues;
        if (isQuestionsForm(formValues)) {
          actions.setCurrentStepSubmitting(WorkflowButtons.ACTION);
          questionsFormSubmit(
            { questions: formValues.questions },
            currentListing.opportunity_key,
            user?.id,
            currentListing,
            onFailure,
            onSuccess
          );
        }
      }
    },
    reject: ({ state, user, useOpportunityPayload: { currentListing } }: ActionButtonData) => {
      if (state.props.steps) {
        const formValues = state.props.steps[state.currentStep].formValues;
        if (isRejectOpportunityForm(formValues)) {
          actions.setCurrentStepSubmitting(WorkflowButtons.ACTION);
          rejectReasonFormSubmit(formValues, currentListing.opportunity_key, user?.id, currentListing, onFailure, onSuccess);
        }
      }
    },
    showNext: ({
      useOpportunityPayload: { isLastOpportunity, closeModal, selectNextOpportunity, currentListing, markCurrentOpportunityTouched }
    }: ActionButtonData) => {
      if (!currentListing.is_actionable) {
        markCurrentOpportunityTouched();
      }
      if (isLastOpportunity()) {
        closeModal();
      } else {
        fullStoryEvent(CustomEvents.opportunity.navigated);
        selectNextOpportunity();
        actions.goToStep(SuggestedItemsSteps.SUGGESTED_ITEMS);
      }
    }
  };
};
