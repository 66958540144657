import type { FC } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { useLocalizer } from '../../../localization';
import { NavLink } from '../navLink';
import { useUser } from '../../../hooks/useUser';
import { Subheading1 } from '../../../bits/text';
import routes from '../../../routes';
import {
  isSupportedLocale,
  updateLanguagePreferenceCookie,
  updateUserLanguagePreference
} from '../../../globalComponents/useLanguagePreference/utils';
import { useRouter } from 'next/router';

export const LanguageSection: FC = () => {
  const { user, userIsLoggedIn } = useUser();
  const loc = useLocalizer();
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const handleLanguageChange = (locale: string) => {
    updateLanguagePreferenceCookie(document, locale);
    userIsLoggedIn(user) && updateUserLanguagePreference(user, locale);
    if (isSupportedLocale(locale)) {
      router.push({ pathname, query }, asPath, { locale });
    }
  };

  return (
    <div className="flex-1">
      <Subheading1 className="mb-3 flex items-center gap-x-2">
        <div className="text-24spx">
          <LanguageIcon fontSize="inherit" />
        </div>
        {loc.Footer.International}
      </Subheading1>
      <ul className="flex flex-wrap gap-x-3">
        <li>
          <NavLink href={routes.home(user, userIsLoggedIn)} onClick={() => handleLanguageChange('en')}>
            {loc.Language.English}
          </NavLink>
        </li>
        <li>
          <NavLink href={routes.home(user, userIsLoggedIn)} onClick={() => handleLanguageChange('zh-CN')}>
            {loc.Language.ChineseSimplified}
          </NavLink>
        </li>
        <li>
          <NavLink href={routes.home(user, userIsLoggedIn)} onClick={() => handleLanguageChange('zh-TW')}>
            {loc.Language.ChineseTraditional}
          </NavLink>
        </li>
        <li>
          <NavLink href={routes.home(user, userIsLoggedIn)} onClick={() => handleLanguageChange('ko')}>
            {loc.Language.Korean}
          </NavLink>
        </li>
        <li>
          <NavLink href={routes.home(user, userIsLoggedIn)} onClick={() => handleLanguageChange('jp')}>
            {loc.Language.Japanese}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
