import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { colors, spacing } from '@/src/design/designConstants';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';
import { useLocalizer } from '@/src/localization';
import { Button, Drawer, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { clearFilters } from '../../slice';
import { useAppDispatch } from '@/src/store';
import { SellerOfferFilters } from '../filters';

export const OffersSellerFiltersMobile = () => {
  const loc = useLocalizer();
  const theme = useTheme();
  const { drawerOpen, closeDrawer } = useSideDrawer(SideDrawers.OfferFilters);
  const dispatch = useAppDispatch();
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: '100vw'
        }
      }}
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" columnGap={spacing.lg} py={spacing.lg} px={spacing.md}>
        <Button variant="contained" color="info" size="small" onClick={() => dispatch(clearFilters())}>
          <Typography variant="button12AllCaps" color={colors.skyBlue[600]}>
            {loc.Forms.Clear}
          </Typography>
        </Button>
        <Typography variant="subtitle3">{loc.Search.Filters}</Typography>
        <IconButton onClick={closeDrawer}>
          <FAIcon icon={icons.xMark} scale={1.5} />
        </IconButton>
      </Stack>
      <Stack spacing={spacing.xl} p={spacing.lg}>
        <SellerOfferFilters />
      </Stack>
    </Drawer>
  );
};
