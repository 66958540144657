import { useRouter } from 'next/router';
import { FC, MouseEvent, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { Localizer } from '../../../../localization';
import { SUPPORTED_LOCALES } from './constants';
import { Button, Menu, MenuItem } from '@mui/material';
import { updateLanguagePreferenceCookie, updateUserLanguagePreference } from '../../../../globalComponents/useLanguagePreference/utils';
import { useUser } from '../../../../hooks/useUser';

interface LanguageDropdownProps {
  readonly loc: Localizer;
}

export const LanguageDropdown: FC<LanguageDropdownProps> = ({ loc }) => {
  const router = useRouter();
  const { user, userIsLoggedIn } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (_: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const { locale, pathname, asPath, query } = router;
  const languageData = [
    {
      value: SUPPORTED_LOCALES['en'],
      label: loc.Language.English
    },
    {
      value: SUPPORTED_LOCALES['zh-CN'],
      label: loc.Language.ChineseSimplified
    },
    {
      value: SUPPORTED_LOCALES['zh-TW'],
      label: loc.Language.ChineseTraditional
    },
    {
      value: SUPPORTED_LOCALES['ko'],
      label: loc.Language.Korean
    },
    {
      value: SUPPORTED_LOCALES['jp'],
      label: loc.Language.Japanese
    }
  ];
  let selectedLanguageData = languageData.find((data) => data.value === locale);
  if (typeof selectedLanguageData === 'undefined') {
    selectedLanguageData = languageData[0];
  }

  const handleLocaleChange = (locale: string) => {
    closeMenu({} as MouseEvent<HTMLElement>);
    updateLanguagePreferenceCookie(document, locale);
    userIsLoggedIn(user) && updateUserLanguagePreference(user, locale);
    router.push({ pathname, query }, asPath, { locale });
  };

  return (
    <>
      <Button variant="text" color="secondary" size="large" onClick={openMenu} startIcon={<LanguageIcon fontSize="inherit" />}></Button>

      <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
        {languageData.map(({ value, label }) => (
          <MenuItem
            onClick={() => handleLocaleChange(value)}
            key={value}
            className="flex items-center justify-center gap-x-4 text-moov-blue-500 hover:bg-smokey-gray-100"
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
