import { ChangeEventHandler, FC } from 'react';

import { Textarea } from '@/src/bits/forms/fields/textarea';

import { SubProps } from '..';

export const Configuration: FC<SubProps> = ({ state, dispatch, loc }) => {
  const setConfiguration: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    dispatch({
      type: 'setConfiguration',
      value: e.target.value
    });
  };
  return (
    <Textarea
      minRows={10}
      value={state.configuration}
      onChange={setConfiguration}
      label={loc.Listing.Configuration}
      placeholder={loc.ToolRequest.SpecifyTheConfiguration}
    />
  );
};
