import { Formik } from 'formik';
import { useContext } from 'react';
import { SingleValue } from 'react-select';
import { isSelectOption, SelectOption } from '../../../../bits/forms/fields/selectField';
import { FormikSelectField } from '../../../../bits/forms/formikFields/formikSelectField';
import FormikTextarea from '../../../../bits/forms/formikFields/formikTextarea';
import { FormikFormWrapper } from '../../../../bits/forms/formikFields/formikWrappers';
import { useLocalizer } from '../../../../localization';
import { isRejectOpportunityReason, UpdateRejectReason } from '../../../../providers/opportunities/types';
import { MobileSuggestedItemsModalDispatch, MobileSuggestedItemsModalStore } from '../../../../state/mobileSuggestedItemsModal/context';
import { getCurrentForm } from '../utils';

const NotInterested: React.FC = () => {
  const initialValues: UpdateRejectReason = {};
  const loc = useLocalizer();
  const actions = useContext(MobileSuggestedItemsModalDispatch);
  const state = useContext(MobileSuggestedItemsModalStore);

  const updateFormValues = (valueChange: SingleValue<SelectOption> | null | string) => {
    if (valueChange === null) {
      actions.updateStepFormValues({ ...getCurrentForm(state), rejectReason: '' });
    } else if (isSelectOption(valueChange) && isRejectOpportunityReason(valueChange.value)) {
      actions.updateStepFormValues({ ...getCurrentForm(state), rejectReason: valueChange.value });
    } else if (typeof valueChange === 'string') {
      actions.updateStepFormValues({ ...getCurrentForm(state), rejectComments: valueChange });
    }
  };

  return (
    <div className="h-full bg-moov-blue-100 text-moov-blue-500">
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ isSubmitting, errors, submitCount }) => (
          <FormikFormWrapper isSubmitting={isSubmitting} errors={errors} submitCount={submitCount} className="h-full">
            <div className="h-full p-4 lg:p-6">
              <p className="mb-4 text-20spx font-bold">Why are you not interested?</p>

              <div className="mb-2 flex flex-col gap-y-4">
                <FormikSelectField
                  name="rejectReason"
                  placeholder={loc.Magic.ChooseAResponse}
                  options={loc.Magic.RejectedOpportunityReasonOptions}
                  reserveSpaceBelow={false}
                  colorVariant="white"
                  externalHandleChange={updateFormValues}
                />
                <FormikTextarea
                  label={loc.Magic.AdditionalDetails}
                  placeholder={loc.Magic.IsThereMoreInformation}
                  name="rejectComments"
                  rows={4}
                  disabled={false}
                  colorVariant="white"
                  externalHandleChange={updateFormValues}
                />
              </div>
            </div>
          </FormikFormWrapper>
        )}
      </Formik>
    </div>
  );
};

export default NotInterested;
