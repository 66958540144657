import { useRouter } from 'next/router';
import { useBionicOpportunities } from './useBionicOpportunities';
import { useOpportunities } from './useOpportunities';
import { useRequestOpportunities } from './useRequestOpportunities';
import { ROUTE_PATHNAMES } from '../../routes';

export const useAnyOpportunities = () => {
  const router = useRouter();
  const isBionic = !!router.query.bionicId;
  const isToolRequestPage = router.pathname === ROUTE_PATHNAMES.requests;
  const suggestedOpportunities = useOpportunities();
  const bionicOpportunities = useBionicOpportunities();
  const requestOpportunities = useRequestOpportunities();

  if (isBionic) return bionicOpportunities;
  else if (isToolRequestPage) return requestOpportunities;
  else return suggestedOpportunities;
};
