import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RouterState {
  registrationInitPath: string; // path the user initialized registration from to navigate back when done
}

const initialState: RouterState = {
  registrationInitPath: ''
};

export const slice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    startRegistration: (state, action: PayloadAction<string>) => {
      state.registrationInitPath = action.payload;
    },
    finishRegistration: (state) => {
      state.registrationInitPath = '';
    }
  }
});

export const { startRegistration, finishRegistration } = slice.actions;

export default slice.reducer;

//
