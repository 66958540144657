import { GlobalBannerDurations } from '../../state/banner/types';
import { useAppDispatch } from '@/src/store';
import { openSuccess, close, openError } from '@/src/state/banner/slice';

export const useGlobalAlertBanner = () => {
  const dispatch = useAppDispatch();

  return {
    openSuccessMessage: (message: string, duration = GlobalBannerDurations.SHORT) => {
      dispatch(
        openSuccess({
          duration,
          message
        })
      );
    },
    openErrorAlert: (error: string, duration = GlobalBannerDurations.DEFAULT) => {
      dispatch(
        openError({
          duration,
          error
        })
      );
    },
    closeBanner: () => {
      dispatch(close());
    }
  };
};
