import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { createContext, useContext } from 'react';

const FlagContext = createContext<LDFlagSet | null>(null);
FlagContext.displayName = 'SSRFeatuerFlagContext';

const useSSRFlagContext = () => useContext(FlagContext);

const SSRFlagProvider = FlagContext.Provider;

export { SSRFlagProvider, useSSRFlagContext };
