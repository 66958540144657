import translations from './index.loc.json';
import { getDefinitions, Locale } from '../../localization';

/**
 *
 */
export class SellMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get SellSemiconductorEquipment(): string {
    return this.definitions.SellSemiconductorEquipment;
  }

  public get EnjoySeamlessSellingExperience(): string {
    return this.definitions.EnjoySeamlessSellingExperience;
  }

  public get RegisterCTA(): string {
    return this.definitions.RegisterCTA;
  }

  public get CreateListingCTA(): string {
    return this.definitions.CreateListingCTA;
  }

  public get LookingToBuy(): string {
    return this.definitions.LookingToBuy;
  }

  public get FindYourNextTool(): string {
    return this.definitions.FindYourNextTool;
  }
}
