import { RequiredLabel } from '../../bits/requiredLabel';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import classNames from 'classnames';
import { ChangeEvent, FocusEvent } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

export interface LabeledPhoneFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

export const LabeledPhoneField = function (props: LabeledPhoneFieldProps) {
  const { label, value, placeholder, disabled, onChange, onBlur, error, helperText, required } = props;

  const handleChange = (value: string, _countryData: CountryData, _event: ChangeEvent<HTMLInputElement>, _formattedValue: string) => {
    onChange?.(value);
  };

  const handleBlur = (_event: FocusEvent<HTMLInputElement, Element>, _countryData: CountryData) => {
    onBlur?.();
  };

  return (
    <FormControl error={error}>
      <FormLabel>
        {label}
        {required && <RequiredLabel error={props.error} />}
      </FormLabel>
      <PhoneInput
        value={value}
        country="us"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        enableSearch
        containerClass={classNames('labeled-phone-input-container', {
          'input-invalid': error
        })}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
