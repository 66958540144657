import { FormControl, FormLabel, TextFieldProps, TextFieldVariants } from '@mui/material';
import { RequiredLabel } from '../../bits/requiredLabel';
import { CurrencyInput } from '../../bits/currencyInput';
import { ReactElement } from 'react';

export type LabeledCurrencyInputProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> & {
  label: string | ReactElement<any, any>;
  value: string;
};

export const LabeledCurrencyInput = function <Variant extends TextFieldVariants = TextFieldVariants>(
  props: LabeledCurrencyInputProps<Variant>
) {
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel>
        {props.label}
        {props.required && <RequiredLabel error={props.error} />}
      </FormLabel>
      <CurrencyInput {...{ ...props, label: undefined }} />
    </FormControl>
  );
};
