import ChevronRight from '@mui/icons-material/ChevronRight';
import { useContext } from 'react';
import { MobileSuggestedItemsModalDispatch } from '../../../../../state/mobileSuggestedItemsModal/context';
import { ModalButtonColors, ModalTypes } from '../../types';
import { isQuestionsFormValid } from '../../../../../bits/modals/interestedModal/utils';
import { SuggestedItemsSteps } from '../types';
import { isQuestionsForm, ModalStep, WorkflowButtons } from '../../../../../state/mobileSuggestedItemsModal/types';
import { useMobileSuggestedItemsHandleSubmit } from './useMobileSuggestedItemsHandleSubmit';
import { useGlobalAlertBanner } from '../../../../../hooks/useGlobalAlertBanner';
import { ERROR_MESSAGES } from '../../../../../constants';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';

export const useMobileSuggestedItemsModal = () => {
  const actions = useContext(MobileSuggestedItemsModalDispatch);
  // const { currentListing } = useAnyOpportunities();
  const { reject, submitQuestions, showNext } = useMobileSuggestedItemsHandleSubmit();
  const { openErrorAlert } = useGlobalAlertBanner();
  const { fullStoryEvent } = useFullStory();

  const onFailure = (errorMessage: ERROR_MESSAGES, button: WorkflowButtons) => {
    actions.setCurrentStepSubmitFailed(button);
    openErrorAlert(errorMessage);
  };
  return {
    closeMobileSuggestedItemsModal: () => {
      actions.closeModal();
    },
    openMobileSuggestedItemsModal: () =>
      actions.openModal({
        modalType: ModalTypes.SuggestedItems,
        props: {
          steps: [
            {
              actionButton: {
                moovId: 'opportunity-learn-more-button-mobile',
                buttonContent: 'LEARN MORE',
                handleClick: ({ useOpportunityPayload: { approveOpportunity, currentListing } }) => {
                  fullStoryEvent(CustomEvents.opportunity.learnMoreClick, { listingId: currentListing.id });

                  const onSuccess = () => {
                    actions.setCurrentStepSubmitted(WorkflowButtons.ACTION);
                    actions.goToStep(SuggestedItemsSteps.QUESTIONS);
                  };
                  actions.setCurrentStepSubmitting(WorkflowButtons.ACTION);

                  approveOpportunity(onSuccess, () => onFailure(ERROR_MESSAGES.NO_REFRESH, WorkflowButtons.ACTION));
                },
                buttonColor: ModalButtonColors.Magenta
              },
              dismissButton: {
                moovId: 'opportunity-not-interested-button-mobile',
                buttonContent: 'NOT INTERESTED',
                handleClick: ({ useOpportunityPayload: { rejectOpportunity, currentListing } }) => {
                  fullStoryEvent(CustomEvents.opportunity.notInterestedClick, { listingId: currentListing.id });

                  const onSuccess = () => {
                    actions.setCurrentStepSubmitted(WorkflowButtons.DISMISS);
                    actions.goToStep(SuggestedItemsSteps.NOT_INTERESTED);
                  };
                  actions.setCurrentStepSubmitting(WorkflowButtons.DISMISS);

                  rejectOpportunity(onSuccess, () => onFailure(ERROR_MESSAGES.NO_REFRESH, WorkflowButtons.DISMISS));
                },
                buttonColor: ModalButtonColors.DarkBlue
              }
            },
            {
              actionButton: {
                moovId: 'opportunity-feedback-submit-button-mobile',
                buttonContent: 'SUBMIT RESPONSE',
                handleClick: reject,
                buttonColor: ModalButtonColors.Magenta,
                isFormValid: (step: ModalStep) => {
                  const formValues = step.formValues;
                  return !!(formValues?.rejectReason || formValues?.rejectComments);
                }
              },
              dismissButton: {
                moovId: 'opportunity-feedback-skip-button-mobile',
                buttonContent: 'SKIP',
                handleClick: () => actions.goToStep(SuggestedItemsSteps.SHOW_NEXT),
                buttonColor: ModalButtonColors.White
              },
              formValues: { rejectReason: '', rejectComments: '' }
            },
            {
              actionButton: {
                moovId: 'opportunity-question-submit-button-mobile',
                buttonContent: 'SUBMIT QUESTION',
                handleClick: submitQuestions,
                buttonColor: ModalButtonColors.Magenta,
                isFormValid: (step: ModalStep) => {
                  const formValues = step.formValues;
                  return isQuestionsForm(formValues) && isQuestionsFormValid(formValues.questions);
                }
              },
              dismissButton: {
                moovId: 'opportunity-question-skip-button-mobile',
                buttonContent: 'SKIP',
                handleClick: () => actions.goToStep(SuggestedItemsSteps.SHOW_NEXT),
                buttonColor: ModalButtonColors.White
              },
              formValues: { questions: [] }
            },
            {
              actionButton: {
                moovId: 'opportunity-browse-next-button-mobile',
                buttonContent: (
                  <>
                    <div>SHOW NEXT</div>
                    <ChevronRight />
                  </>
                ),
                handleClick: showNext,
                buttonColor: ModalButtonColors.Magenta
              }
            }
          ]
        }
      })
  };
};
