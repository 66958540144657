import { MOOV_API_URL } from '@/src/constants';
import { AppDispatch, AppState } from '@/src/store';
import axios from 'axios';
import { openSuccess } from '../banner/slice';

interface CreateUserFromLeadPayload {
  key: string; // the key from the lead.. TODO: we dont need this?
  email: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  phone?: string;
  password: string;
  turnstile_data: string;
  is_opt_in: number;
}

export const createUser = (payload: CreateUserFromLeadPayload) => {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .post(
        `${MOOV_API_URL}/user`,
        { ...payload },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' }
        }
      )
      .then(() => {
        // TODO: Translations
        dispatch(openSuccess({ message: 'Request submitted successfully' }));
      });
  };
};
