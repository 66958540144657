import { FC, ReactNode, useState } from 'react';
import { MoovButton } from '../../moovButton';
import ModalPopup from '../modalPopup';
import { MarketingH5, MarketingP1 } from '../../text';
import { useLocalizer } from '../../../localization';

interface ConfirmationModalProps {
  handleConfirm: () => Promise<void>;
  errorHandleConfirm?: (err: Error) => void;
  handleClose: () => void;
  isOpen: boolean;
  title: string;
  description?: ReactNode;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const loc = useLocalizer();
  const {
    handleConfirm,
    errorHandleConfirm,
    handleClose,
    isOpen,
    title,
    description,
    confirmText = loc.ToolRequest.Yes,
    cancelText = loc.ToolRequest.No
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const internalConfirm = async () => {
    setIsLoading(true);
    handleConfirm()
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        errorHandleConfirm?.(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalPopup isOpen={isOpen} onClose={handleClose} placementMobile="bottom" className="text-moov-blue-500 sm:max-w-[25rem]">
      <div className="p-4 text-center lg:p-6">
        <div className="pb-4">
          <MarketingH5 className="mb-2">{title}</MarketingH5>
          <MarketingP1 className="text-smokey-gray-500">{description}</MarketingP1>
        </div>

        <div className="mt-6 flex w-full justify-between gap-x-2">
          <MoovButton
            onClick={handleClose}
            isLoading={isLoading}
            variant="secondary"
            className="h-14 flex-1"
            data-testid="ConfirmationModal-Cancel"
          >
            {cancelText}
          </MoovButton>
          <MoovButton
            variant="primary"
            onClick={internalConfirm}
            className="h-14 flex-1"
            isLoading={isLoading}
            data-testid="ConfirmationModal-Submit"
          >
            {confirmText}
          </MoovButton>
        </div>
      </div>
    </ModalPopup>
  );
};
