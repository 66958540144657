import { ReactNode, FC } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface NavArrowProps {
  readonly onClick: () => void;
  readonly disabled?: boolean;
}

interface NavContainerProps extends NavArrowProps {
  readonly children: ReactNode;
  readonly className: string;
}

/**
 * todo #183054597 replace old Carousel component with new CarouselBase component
 */
const GrayNavArrowContainer = ({ children, className = '', onClick, disabled }: NavContainerProps) => (
  <button
    type="button"
    className={`absolute top-1/2 z-carouselNavArrow -translate-y-1/2 cursor-pointer bg-black bg-opacity-10 text-white not-disabled:active:bg-opacity-30 lg:not-disabled:hover:bg-opacity-30 ${className} disabled:cursor-not-allowed disabled:opacity-50`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

/**
 * todo #183054597 replace old Carousel component with new CarouselBase component
 */
export const SmallLeftGrayNavArrow = ({ onClick, disabled }: NavArrowProps) => (
  <GrayNavArrowContainer className="left-0 h-16 w-8 rounded-r-full" onClick={onClick} disabled={disabled}>
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-36spx">
      <ChevronLeftIcon fontSize="inherit" />
    </div>
  </GrayNavArrowContainer>
);

/**
 * todo #183054597 replace old Carousel component with new CarouselBase component
 */
export const SmallRightGrayNavArrow = ({ onClick, disabled }: NavArrowProps) => (
  <GrayNavArrowContainer className="right-0 h-16 w-8 rounded-l-full" onClick={onClick} disabled={disabled}>
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-36spx">
      <ChevronRightIcon fontSize="inherit" />
    </div>
  </GrayNavArrowContainer>
);

/**
 * todo #183054597 replace old Carousel component with new CarouselBase component
 */
export const LeftGrayNavArrow = ({ onClick, disabled }: NavArrowProps) => (
  <GrayNavArrowContainer className="left-0 h-28 w-14 rounded-r-full" onClick={onClick} disabled={disabled}>
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-48spx">
      <ChevronLeftIcon fontSize="inherit" />
    </div>
  </GrayNavArrowContainer>
);

/**
 * todo #183054597 replace old Carousel component with new CarouselBase component
 */
export const RightGrayNavArrow = ({ onClick, disabled }: NavArrowProps) => (
  <GrayNavArrowContainer className="right-0 h-28 w-14 rounded-l-full" onClick={onClick} disabled={disabled}>
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-48spx">
      <ChevronRightIcon fontSize="inherit" />
    </div>
  </GrayNavArrowContainer>
);

export const RoundedBtnChevronForward = ({ onClick, disabled }: NavArrowProps) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className="absolute right-0 top-1/2 z-carouselNavArrow flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-3xl border-2 border-smokey-gray-200 bg-white text-24spx shadow"
  >
    <ChevronRightIcon fontSize="inherit" />
  </button>
);

export const RoundedBtnChevronBack = ({ onClick, disabled }: NavArrowProps) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className="absolute left-0 top-1/2 z-carouselNavArrow flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-3xl border-2 border-smokey-gray-200 bg-white text-24spx shadow"
  >
    <ChevronLeftIcon fontSize="inherit" />
  </button>
);

export interface CarouselNavArrowProps {
  readonly identifierClass: string;
}

export const LeftSmallNavArrow: FC<CarouselNavArrowProps> = ({ identifierClass }) => (
  <div
    className={`absolute left-0 top-1/2 z-carouselNavArrow flex h-full -translate-y-1/2 cursor-pointer items-center justify-center bg-inherit px-2.5 text-24spx hover:brightness-97 ${identifierClass}`}
  >
    <ChevronLeftIcon fontSize="inherit" />
  </div>
);

export const RightSmallNavArrow: FC<CarouselNavArrowProps> = ({ identifierClass }) => (
  <div
    className={`absolute right-0 top-1/2 z-carouselNavArrow flex h-full -translate-y-1/2 cursor-pointer items-center justify-center bg-inherit px-2.5 text-24spx hover:brightness-97 ${identifierClass}`}
  >
    <ChevronRightIcon fontSize="inherit" />
  </div>
);
