import { RejectedOpportunityReason } from './types';
import { touchPoint } from '../touchPoints';
import { TouchPointType } from '../touchPoints/constants';
import { createRejectPayload } from './helpers';
import {
  rejectOneOpportunity,
  approveOneOpportunity,
  approveOneOpportunityByListing,
  markOneOpportunitySeen,
  markOneOpportunitySeenMagic,
  rejectOneOpportunityByListing
} from './repo';
import { Listing } from '../../entities/listing';

export const rejectOpportunity = async (
  opportunityKey: string,
  listingId: number,
  reason?: RejectedOpportunityReason,
  comment?: string
) => {
  let details = {};
  if (reason) {
    details = { loss_reason: reason };
  }
  if (comment) {
    details = { ...details, loss_comments: comment };
  }

  const payload = createRejectPayload(reason, comment);

  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_SUBMITTED_NOT_INTERESTED,
      task: `Not interested in FID: ${listingId}`,
      details: JSON.stringify(details)
    }
  });

  return rejectOneOpportunity(opportunityKey, payload);
};

export const rejectOpportunityByListingAndUserId = async (
  listing: Listing,
  userId: number,
  reason?: RejectedOpportunityReason,
  comment?: string
) => {
  let details = {};
  if (reason) {
    details = { loss_reason: reason };
  }
  if (comment) {
    details = { ...details, loss_comments: comment };
  }

  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_SUBMITTED_NOT_INTERESTED,
      task: `User ID: ${userId} not iterested in FID: ${listing.id}`,
      details: JSON.stringify(details)
    }
  });

  const payload = createRejectPayload(reason, comment);

  await rejectOneOpportunityByListing(listing.key, payload);
};

export const approveOpportunity = async (opportunityKey: string, listingId: number) => {
  touchPoint({
    touchPoint: {
      type: TouchPointType.TOUCH_POINT_TYPE_SUBMITTED_INTERESTED,
      task: `Interested in FID ${listingId}`
    }
  });

  return approveOneOpportunity(opportunityKey);
};

export const approveOpportunityByListing = async (listingKey: string) => {
  await approveOneOpportunityByListing(listingKey);
};

export const opportunitySeen = async (opportunityKey: string, withCredentials = false) => {
  return withCredentials ? markOneOpportunitySeen(opportunityKey) : markOneOpportunitySeenMagic(opportunityKey);
};
