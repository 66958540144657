import { FC } from 'react';

import { Configuration } from './inputs/ConfigurationInput';
import { Description } from './inputs/DescriptionInput';

import { SubProps } from '.';
import { Box, Stack } from '@mui/material';
import { spacing } from '@/src/design/designConstants';

export const ConfigurationTab: FC<SubProps> = (props) => {
  return (
    // TODO: ENG-93 - Replace hard-coded height and width with reuseable
    // spacing constants for modal sizes
    <Box height={{ d: '640px' }} width={{ d: '600px' }} p={spacing['3xl']}>
      <Stack rowGap={spacing.xl}>
        <Description {...props} />
        <Configuration {...props} />
      </Stack>
    </Box>
  );
};
