import { useAppSelector } from '@/src/store';
import { getFilteredBuyerOffers } from '../../selectors';
import { Card, CardContent, CardHeader, Divider, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import Image from 'next/image';
import { parseUSDCurrencyStr } from '@/src/utils';
import dayjs from 'dayjs';
import { OfferStatusChip } from '../../statusChip';
import { BuyerOfferCTAs } from '../ctas';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { getExpireDaysColor, getOfferAmountColor } from '../utils';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';

export const BuyerOffersDesktop = () => {
  const filteredBuyerOffers = useAppSelector(getFilteredBuyerOffers);
  const countryOptions = useAppSelector(getLogisticCountries);

  return (
    <Stack
      margin="auto"
      px={spacing['10xl']}
      py={spacing['3xl']}
      spacing={spacing['3xl']}
      height="100%"
      data-testid="buyer-offers-desktop-content"
    >
      {filteredBuyerOffers.map((offer) => {
        return (
          <Card variant="outlined" sx={{ px: spacing['3xl'], py: spacing['xl'] }} key={`buyer-offer-${offer.key}`}>
            <Stack direction="row" alignItems={'center'} spacing={spacing['3xl']}>
              <Stack>
                <Image
                  alt={`listing-image-${offer.listing?.make_model}`}
                  src={offer.listingLargePhotoUrl}
                  quality={100}
                  objectFit="cover"
                  height={200}
                  width={200}
                  style={{ borderRadius: borderRadius.rounded }}
                />
              </Stack>
              <Stack spacing={spacing['3xl']} width="100%">
                <CardHeader
                  sx={{ p: 0 }}
                  title={
                    <Stack direction="row" justifyContent={'space-between'}>
                      <Typography variant="h6">{offer.listing.make_model}</Typography>
                      <OfferStatusChip offer={offer.offer} />
                    </Stack>
                  }
                />
                <CardContent
                  sx={{
                    p: 0,
                    '&:last-child': {
                      paddingBottom: 0,
                      mt: spacing.lg
                    }
                  }}
                >
                  <Stack spacing={spacing.lg}>
                    <Stack direction="row" justifyContent={'space-between'} spacing={spacing['4xl']}>
                      <Stack width="75%">
                        <Grid container alignItems="center" rowGap={spacing.xs} spacing={spacing.sm}>
                          <Grid xs={5}>
                            <Typography variant="p14">
                              <strong>Offer Amount:</strong>
                            </Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography variant="p14" color={getOfferAmountColor(offer)}>
                              <strong>{parseUSDCurrencyStr(offer.offer.offer_amount)} USD</strong>
                            </Typography>
                          </Grid>

                          <Grid xs={12}>
                            <Divider />
                          </Grid>

                          <Grid xs={5}>
                            <Typography variant="p14">
                              <strong>Date Required By:</strong>
                            </Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography variant="p14">
                              {dayjs(offer.offer.required_by).isValid() ? dayjs(offer.offer.required_by).format('MM/DD/YYYY') : 'N/A'}
                            </Typography>
                          </Grid>

                          <Grid xs={12}>
                            <Divider />
                          </Grid>

                          <Grid xs={5}>
                            <Typography variant="p14">
                              <strong>Destination Country:</strong>
                            </Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography variant="p14">{offer.countryText(countryOptions)}</Typography>
                          </Grid>

                          <Grid xs={12}>
                            <Divider />
                          </Grid>

                          <Grid xs={5}>
                            <Typography variant="p14">
                              <strong>Offer Expiration:</strong>
                            </Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography variant="p14" color={getExpireDaysColor(offer)}>
                              <strong>{offer.expireDaysText}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                      <BuyerOfferCTAs offer={offer} />
                    </Stack>
                    {offer.sellerNote && (
                      <Paper
                        sx={{
                          py: spacing.md,
                          px: spacing.lg,
                          border: `1px solid ${colors.magenta['200']}`,
                          background: colors.magenta['50'],
                          width: 'fit-content'
                        }}
                        variant="outlined"
                      >
                        <Stack direction="row" height="100%" alignItems="center" spacing={spacing.md}>
                          <FAIcon icon={icons.noteSticky} />
                          <Typography variant="p14">
                            <strong>Seller Note:</strong>
                            &nbsp;
                            {offer.sellerNote}
                          </Typography>
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                </CardContent>
              </Stack>
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};
