import type { RejectedOpportunityReason } from './types';

export const convertRejectedReason2num = (reason: RejectedOpportunityReason): number => {
  switch (reason) {
    case 'VINTAGE':
      return 14;
    case 'CONDITION':
      return 15;
    case 'SPECS':
      return 16;
    case 'NEED':
      return 17;
    case 'PARTS':
      return 18;
    default:
      return 10;
  }
};
