import { Stack, Typography } from '@mui/material';
import { colors, spacing } from '../design/designConstants';
import { IMAGE_URLS } from '../constants/imageUrls';

export const NullFilterState = () => {
  return (
    <Stack variant="center" alignItems={'center'} width="100%" height="100%">
      <Stack width="100%" p={spacing['2xl']} spacing={spacing.xl}>
        <Stack variant="center" spacing={spacing.md} textAlign={'center'}>
          <Typography color={colors.corpGray['500']} variant="subtitle3">
            No offers for current filter selection
          </Typography>
          <Typography color={colors.corpGray['500']} variant="p14">
            Change your filters to see more offers.
          </Typography>
        </Stack>
        <Stack width="100%" variant="center" py={spacing.md} px={spacing.sm}>
          <img src={IMAGE_URLS.OFFER_NULL_STATE} alt="order card illustration" />
        </Stack>
      </Stack>
    </Stack>
  );
};
