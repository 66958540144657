import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { getModalState } from '@/src/state/globalModal/selectors';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { conditions, Condition, priorities, Priority, waferSizes, WaferSize } from '@/src/common/lib';
import { spacing } from '@/src/design/designConstants';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { useLocalizer } from '@/src/localization';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { ERROR_MESSAGES } from '@/src/constants';
import { modelFormRequest } from '@/src/state/forms/thunks';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import * as yup from 'yup';
import { getMakeModelSchema } from '@/src/validationSchemas/listing';
import { ModelSearchDropdown } from '@/src/bits/forms/fields/modelSearchDropdown';
import { useRouter } from 'next/router';
import { ROUTE_PATHNAMES } from '@/src/routes';
import { ModelForms } from '@/src/state/forms/thunks';

export interface FindOneAuthFormValues {
  always_looking: boolean;
  appraisal: boolean;
  budget: string;
  condition: Condition;
  config: string;
  destination: string;
  installation: boolean;
  insurance: boolean;
  logistics: boolean;
  model_id?: number;
  priority: Priority;
  required_by: Date | null;
  wafer_size: WaferSize[];
  form_name: ModelForms;
}

export function FindOneAuth() {
  const [step, setStep] = useState<1 | 2>(1); // [step, setStep
  const isToolRequestPage = useRouter().pathname === ROUTE_PATHNAMES.requests;
  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const { fullStoryEvent } = useFullStory();
  const {
    modalProps: { findOne }
  } = useAppSelector(getModalState);

  const initialValues: FindOneAuthFormValues = {
    always_looking: false,
    budget: '0',
    appraisal: false,
    condition: Condition.Used,
    config: '',
    destination: '',
    insurance: true,
    logistics: true,
    model_id: findOne?.model?.id,
    priority: Priority.Medium,
    installation: false,
    required_by: null,
    wafer_size: [],
    form_name: ModelForms.FIND_ONE_FOR_ME
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).concat(
      yup.object({
        model_id: getMakeModelSchema(loc)
      })
    )
  );
  const { openErrorAlert } = useGlobalAlertBanner();

  async function actionHandler() {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        fullStoryEvent(CustomEvents.model.findOneSubmit, { modelId: formValidationHook.formValues.model_id });
        return dispatch(
          modelFormRequest(
            {
              always_looking: formValidationHook.formValues.always_looking,
              budget: formValidationHook.formValues.budget,
              appraisal: formValidationHook.formValues.appraisal,
              condition: formValidationHook.formValues.condition,
              config: formValidationHook.formValues.config,
              destination: formValidationHook.formValues.destination,
              insurance: formValidationHook.formValues.insurance,
              logistics: formValidationHook.formValues.logistics,
              model_id: formValidationHook.formValues.model_id,
              priority: formValidationHook.formValues.priority,
              installation: formValidationHook.formValues.installation,
              required_by: formValidationHook.formValues.required_by,
              wafer_size: formValidationHook.formValues.wafer_size,
              form_name: ModelForms.FIND_ONE_FOR_ME
            },
            isToolRequestPage
          )
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      default:
        openErrorAlert(ERROR_MESSAGES.REFRESH);
        dispatch(closeModal());
        break;
    }
  }

  // TODO: Translations
  const step1 = (
    <>
      <ModelSearchDropdown
        value={Number(formValidationHook.formValues.model_id)}
        onChange={(value) => {
          formValidationHook.setFieldValue('model_id', value);
        }}
        onBlur={() => {
          formValidationHook.validateField('model_id', formValidationHook.formValues.model_id);
          formValidationHook.touchField('model_id');
        }}
        hasErrored={!!formValidationHook.formErrorState.model_id}
        errorMessage={formValidationHook.formErrorState.model_id}
        touched={formValidationHook.formTouchedState.model_id}
        validateOnTouchOrSubmit
        required
        size="medium"
      />
      <Stack>
        <InputLabel>{loc.Forms.TargetPrice}</InputLabel>
        <MaskedInput
          inputMode="numeric"
          mask={createNumberMask({
            allowDecimal: true,
            allowLeadingZeroes: false,
            allowNegative: false,
            decimalSymbol: '.',
            decimalLimit: 2,
            includeThousandsSeparator: true,
            integerLimit: 12,
            prefix: '',
            suffix: '',
            thousandsSeparatorSymbol: ','
          })}
          render={(innerRef, props) => (
            <OutlinedInput {...props} inputRef={innerRef} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
          )}
          value={formValidationHook.formValues.budget}
          onChange={(e) => {
            const v = e.target.value.replace(/,/g, '');
            formValidationHook.setFieldValue('budget', v);
          }}
        />
      </Stack>
      <Stack>
        <InputLabel>Required By</InputLabel>
        <DatePicker
          value={formValidationHook.formValues.required_by}
          onChange={(v) => formValidationHook.setFieldValue('required_by', v)}
        />
      </Stack>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ ml: -1.5 }}
            checked={formValidationHook.formValues.always_looking}
            onChange={(_, v) => formValidationHook.setFieldValue('always_looking', v)}
          />
        }
        label="Always Looking"
      />
      <Stack>
        <InputLabel>Destination Address</InputLabel>
        <TextField
          placeholder="Tempe, AZ"
          value={formValidationHook.formValues.destination}
          onChange={(e) => formValidationHook.setFieldValue('destination', e.target.value)}
        />
      </Stack>
      <Stack>
        <InputLabel>{loc.ToolRequest.MinimumConfiguration}</InputLabel>
        <TextField
          multiline
          rows={4}
          value={formValidationHook.formValues.config}
          onChange={(e) => formValidationHook.setFieldValue('config', e.target.value)}
          inputProps={{ 'data-testid': 'auth-find-one-minimum-config-textarea' }}
        />
      </Stack>
    </>
  );

  const step2 = (
    <>
      <Stack>
        <InputLabel>{loc.EquipmentManage.Condition}</InputLabel>
        <Select
          value={formValidationHook.formValues.condition}
          onChange={(e) => formValidationHook.setFieldValue('condition', e.target.value)}
        >
          {conditions.map((e) => {
            return (
              <MenuItem key={e.id} value={e.id}>
                {e.label}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
      <Stack data-testid="auth-find-one-wafer-size-container">
        <InputLabel>{loc.EquipmentManage.WaferSize}</InputLabel>
        <Select
          multiple
          value={formValidationHook.formValues.wafer_size}
          onChange={(e) => formValidationHook.setFieldValue('wafer_size', e.target.value)}
        >
          {waferSizes.map((e) => {
            return (
              <MenuItem key={e.id} value={e.id}>
                {e.label}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
      <Stack>
        <InputLabel>{loc.ToolRequest.Priority}</InputLabel>
        <Select
          value={formValidationHook.formValues.priority}
          onChange={(e) => formValidationHook.setFieldValue('priority', e.target.value)}
        >
          {priorities.map((e) => {
            if (e.id !== Priority.Automated)
              return (
                <MenuItem key={e.id} value={e.id}>
                  {e.label}
                </MenuItem>
              );
          })}
        </Select>
      </Stack>
      <Stack>
        <InputLabel>Do you require any of the following services?</InputLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.insurance}
                onChange={(_, v) => formValidationHook.setFieldValue('insurance', v)}
              />
            }
            label={loc.Common.Insurance}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.logistics}
                onChange={(_, v) => formValidationHook.setFieldValue('logistics', v)}
              />
            }
            label={loc.Common.Logistics}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.appraisal}
                onChange={(_, v) => formValidationHook.setFieldValue('appraisal', v)}
              />
            }
            label="Appraisal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValidationHook.formValues.installation}
                onChange={(_, v) => formValidationHook.setFieldValue('installation', v)}
              />
            }
            label="Install Assistance"
          />
        </FormGroup>
      </Stack>
    </>
  );

  const steps: { [key: string]: React.JSX.Element } = {
    1: step1,
    2: step2
  };

  return (
    <Stack spacing={spacing.xl}>
      <WorkflowModal
        actionButtonContent={step === 2 ? <div>Submit</div> : <div>Next</div>}
        actionButtonSubmit={actionHandler}
        actionButtonDisabled={!Boolean(formValidationHook.formValues.model_id)}
        displayBackButton={true}
        closeModal={() => dispatch(closeModal())}
        currentStep={step}
        modalOpen
        setCurrentStep={setStep}
        totalSteps={2}
        title={loc.Common.FindOneForMe}
      >
        <Stack spacing={spacing.xl} width={{ d: '600px' }}>
          {steps[step] ?? <></>}
        </Stack>
      </WorkflowModal>
    </Stack>
  );
}
