import { getDefinitions, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class RegisterUserLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AcceptTermsPrivacy(): string {
    return this.definitions.AcceptTermsPrivacy;
  }

  public get AccessFeatures(): string {
    return this.definitions.AccessFeatures;
  }

  public get AccessManageDashboard(): string {
    return this.definitions.AccessManageDashboard;
  }

  public get AlreadyAccount(): string {
    return this.definitions.AlreadyAccount;
  }

  public get EmailRequired(): string {
    return this.definitions.EmailRequired;
  }

  public get FinishAccount(): string {
    return this.definitions.FinishAccount;
  }

  public get FreeAccountTo(): string {
    return this.definitions.FreeAccountTo;
  }

  public get HarnessPowerAnalytics(): string {
    return this.definitions.HarnessPowerAnalytics;
  }

  public get HaveReadTermsAndPrivacyAnd(): string {
    return this.definitions.HaveReadTermsAndPrivacyAnd;
  }

  public get HaveReadTermsAndPrivacyPost(): string {
    return this.definitions.HaveReadTermsAndPrivacyPost;
  }

  public get HaveReadTermsAndPrivacyPre(): string {
    return this.definitions.HaveReadTermsAndPrivacyPre;
  }

  public get HaveReadTermsAndPrivacyPrivacy(): string {
    return this.definitions.HaveReadTermsAndPrivacyPrivacy;
  }

  public get HaveReadTermsAndPrivacyTerms(): string {
    return this.definitions.HaveReadTermsAndPrivacyTerms;
  }

  public get PasswordRequired(): string {
    return this.definitions.PasswordRequired;
  }

  public get PleaseValidEmail(): string {
    return this.definitions.PleaseValidEmail;
  }

  public get PrimaryReasonRegistering(): string {
    return this.definitions.PrimaryReasonRegistering;
  }

  public get ReadyJoin(): string {
    return this.definitions.ReadyJoin;
  }

  public get RegisterMoovAccount(): string {
    return this.definitions.RegisterMoovAccount;
  }

  public get SecureReliableCreateAccount(): string {
    return this.definitions.SecureReliableCreateAccount;
  }

  public get SoftwareListIdle(): string {
    return this.definitions.SoftwareListIdle;
  }
}
