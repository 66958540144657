import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { UnansweredQuestion } from '../../../../entities/questions';
import { closeModal } from '../../../../state/globalModal/slice';
import { getModalState } from '../../../../state/globalModal/selectors';
export interface QuestionState extends UnansweredQuestion {
  submitted: boolean;
  submitValue: string;
}

export const getQuestionInitialState = (questions: UnansweredQuestion[] | undefined): QuestionState[] => {
  if (!!questions && questions.length > 0) {
    return questions.map((q) => ({ ...q, submitted: false, submitValue: '' }));
  }

  return [];
};

export const useAnswerQuestionsState = () => {
  const dispatch = useAppDispatch();
  const { modalProps } = useAppSelector(getModalState);
  const { answerListingQuestions } = modalProps;
  const [currentStep, setCurrentStep] = useState(1);
  const [questionsState, setQuestionsState] = useState<QuestionState[]>(getQuestionInitialState(answerListingQuestions?.questions));
  const [currentValue, setCurrentValue] = useState('');

  const closeQuestionsModal = () => {
    answerListingQuestions?.onClose();
    dispatch(closeModal());
  };

  const incrementOrCloseModal = () => {
    if (currentStep < questionsState.length) {
      setCurrentValue('');
      setCurrentStep(currentStep + 1);
    } else {
      closeQuestionsModal();
    }
  };

  return {
    currentStep,
    setCurrentStep,
    questionsState,
    setQuestionsState,
    currentValue,
    setCurrentValue,
    incrementOrCloseModal,
    closeQuestionsModal,
    currentQuestionSubmitValue: questionsState[currentStep - 1]?.submitValue,
    currentQuestionSubmitted: questionsState[currentStep - 1]?.submitted,
    currentQuestion: questionsState[currentStep - 1]?.question
  };
};
