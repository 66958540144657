import { useEffect } from 'react';
import { useGlobalAlertBanner } from '../../../../hooks/useGlobalAlertBanner';
import { QuestionState } from './useAnswerQuestionsState';
import { useLocalizer } from '../../../../localization';
export const useSuccessMessageListener = (questionState: QuestionState[]) => {
  const { openSuccessMessage } = useGlobalAlertBanner();
  const loc = useLocalizer();

  useEffect(() => {
    return () => {
      const questionAnswered = questionState.find((q) => q.submitted);
      if (!!questionAnswered) {
        openSuccessMessage(loc.Remarketing.QuestionsAnswered);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
