import { FC, SyntheticEvent } from 'react';
import { DropDownOption } from '../types';
import { isDropdownOption } from '../helpers';
import { DropDownBase, SharedDropdownProps } from '../dropdownBase';
import { Select as MUISelect } from '@mui/material';

export interface SingleSelectDropdownProps extends SharedDropdownProps {
  value?: DropDownOption | null;
  onChange: (value: DropDownOption | null) => void;
}

/**
 * @deprecated Use Select from below
 */
export const SingleSelectDropdown: FC<SingleSelectDropdownProps> = ({ onChange, ...props }) => {
  const handleChange = (_: SyntheticEvent<Element, Event>, value: unknown) => {
    if (isDropdownOption(value) || value === null) {
      onChange(value);
    }
  };

  return <DropDownBase {...props} onChange={handleChange} />;
};

// TODO: replace this with styled one from https://www.pivotaltracker.com/story/show/185659815
export const Select = MUISelect;
