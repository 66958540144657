import { FC } from 'react';

import { ModelSearchDropdown } from '@/src/bits/forms/fields/modelSearchDropdown';

import { SubProps } from '..';

export const ModelSearch: FC<SubProps> = ({ state, dispatch }) => {
  if (state.modelNotListed) {
    return null;
  }

  const setModelId = (newId: number) => {
    dispatch({
      type: 'setModelId',
      value: newId
    });
  };

  return (
    <ModelSearchDropdown
      value={state.modelId || 0}
      onChange={setModelId}
      onBlur={() => {}}
      hasErrored={false}
      errorMessage={''}
      touched={false}
      submitted={false}
      validateOnTouchOrSubmit
      required
      size="medium"
    />
  );
};
