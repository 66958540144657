import { Stack } from '@mui/material';
import { borderRadius, colors } from '@/src/design/designConstants';
import { FC } from 'react';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type SocialMediaIconProps = {
  icon: IconDefinition;
};

export const SocialMediaIcon: FC<SocialMediaIconProps> = ({ icon }) => {
  return (
    <Stack
      variant="center"
      width={40}
      height={40}
      padding={0.5}
      borderRadius={borderRadius.rounded}
      sx={{
        background: colors.surface.white
      }}
    >
      <FAIcon icon={icon} scale={2} color={colors.moovBlue[500]} />
    </Stack>
  );
};
