import type { FC } from 'react';

import { useLocalizer } from '../../../../localization';

interface DropzoneTextProps {
  readonly isDragActive: boolean;
  readonly isDragReject: boolean;
  readonly acceptedTypesLabel: string;
  readonly maxSize: number;
}

export const DropzoneText: FC<DropzoneTextProps> = (props) => {
  const { isDragActive, isDragReject, acceptedTypesLabel, maxSize } = props;
  const loc = useLocalizer();

  if (isDragReject) {
    return <p className="text-16spx font-bold uppercase text-[#FD385D]">{loc.Forms.FileTypeNot}</p>;
  }

  if (isDragActive) {
    return <p className="text-16spx font-bold uppercase text-moov-blue-500">{loc.Forms.DropHereUpload}</p>;
  }

  return (
    <div className="font-medium">
      <p className="text-16spx font-bold">CLICK TO UPLOAD</p>
      <p className="text-14spx text-corp-gray-500">OR DRAG AND DROP</p>
      <p className="text-12spx text-corp-gray-500">
        {acceptedTypesLabel} • {loc.Forms.MaxSize(Math.floor(maxSize / (1024 * 1024)))}
      </p>
    </div>
  );
};
