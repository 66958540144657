import type { FC } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';

interface SectionProps {
  title: string | JSX.Element;
  titleMarginBottom?: (typeof spacing)[keyof typeof spacing];
  toolTipTitle?: string;
  toolTipDescription?: string;
}

//TODO(Storybook): make this a component
export const Section: FC<SectionProps> = (props) => {
  const { children, title, titleMarginBottom = spacing.xl, toolTipTitle, toolTipDescription } = props;

  return (
    <Stack>
      <Stack mb={titleMarginBottom} direction="row">
        <Typography variant="h6">{title}</Typography>
        {(toolTipTitle || toolTipDescription) && (
          <Tooltip
            title={
              <Stack gap={spacing.md}>
                <Typography variant="subtitle3">{toolTipTitle}</Typography>
                <Typography variant="p14" color={colors.smokeyGray[600]}>
                  {toolTipDescription}
                </Typography>
              </Stack>
            }
            placement="top"
          >
            <Box ml={spacing.xs}>
              <FAIcon icon={icons.questionCircle} />
            </Box>
          </Tooltip>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
