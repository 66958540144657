import { MOOV_API_URL } from '@/src/constants';
import { AppDispatch, AppState } from '@/src/store';
import axios from 'axios';
import { openSuccess } from '../banner/slice';
import { DTOLead } from '@/src/providers/leads/types';
import { userIsLoggedIn } from '../user/selectors';
import { FindOneAnonFormValues } from '@/src/globalComponents/globalModal/modals/findOne/findOneAnon';
import { FindOneAuthFormValues } from '@/src/globalComponents/globalModal/modals/findOne/findOneAuth';
import { stringifyArrayOfNums } from '@/src/providers/utils';
import { Wanted2, WantedSerializable, isWantedSerializable } from '@/src/requests2/types';
import { load, select } from '@/src/requests2/slice';

interface RequestDetails {
  from_url: string;
  is_offer: 0 | 1;
  budget: number;
  listing_id: number;
  model_id: number;
  form_name: ListingForms;
}

export interface RequestDetailsAuth extends RequestDetails {
  questions: string[];
}

type RequestDetailsUnauth = RequestDetails &
  UserSignup & {
    notes: string;
  };
interface UserSignup {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  contact_preference: number;
  contact_handle: string;
}

export interface MakeOffer {
  from_url: string;
  is_offer: 1;
  offer_amount: string;
  destination: string;
  required_by: Date | null;
  insurance: boolean;
  logistics: boolean;
  appraisal: boolean;
  installation: boolean;
  listing_id: number | null;
  model_id: number | null;
  listing_key: string;
  form_name: ListingForms;
}
export type AnonMakeOffer = UserSignup & MakeOffer;

type ListingFormPayload = RequestDetailsUnauth | RequestDetailsAuth | AnonMakeOffer | MakeOffer;

export enum ListingForms {
  REQUEST_DETAILS = 'Request Details',
  MAKE_OFFER = 'Make Offer'
}

export const submitListingForm = (payload: ListingFormPayload, listingKey: string) => {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const loggedIn = userIsLoggedIn(getState());
    return axios
      .post<void | { data: DTOLead }>(`${MOOV_API_URL}/forms/listing/${listingKey}`, payload, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: loggedIn
      })
      .then((res) => {
        // TODO: Translations #185801497 - Request submitted successfully
        dispatch(openSuccess({ message: 'Request submitted successfully' }));
        return res.data?.data;
      });
  };
};

export enum ModelForms {
  FIND_ONE_FOR_ME = 'Find One For Me'
}

export function modelFormRequest(a: FindOneAnonFormValues | FindOneAuthFormValues, setSelected = false) {
  return async (dispatch: AppDispatch) => {
    // Convert DateTime object to iso string for API
    const required_by = a.required_by?.toISOString() ?? '';
    // Convert Wafer Sizes to string for API
    const wafer_size = stringifyArrayOfNums(a.wafer_size);
    const pl = { ...a, required_by, wafer_size };
    return axios
      .post<{ data: DTOLead | WantedSerializable }>(`${MOOV_API_URL}/forms/model/${a.model_id}`, pl, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((res) => {
        // TODO: Translations #185801497 - Request submitted successfully
        dispatch(openSuccess({ message: 'Request submitted successfully' }));

        if (isWantedSerializable(res.data.data) && setSelected) {
          dispatch(load([Wanted2.anyToSerializable(res.data.data)]));
          dispatch(select(res.data.data.id.toString()));
        }

        return res.data.data;
      });
  };
}
