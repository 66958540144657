import axios from 'axios';
import { DTOWithMultiWrapper, DTOWithSingleWrapper } from '../types';
import { DTOQuestion } from './dtos';

export const createQuestionsByOpportunityKey = async (opportunityKey: string, payload: { questions: string[] }) => {
  return axios.post<void>(`${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/opportunities/${opportunityKey}/question`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' }
  });
};

export const createQuestionsByListingKey = async (listingKey: string, payload: { questions: string[] }) => {
  return axios.post<void>(`${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/opportunities/listing/question`, payload, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    params: { listing_key: listingKey },
    withCredentials: true
  });
};

export const getUnansweredQuestionsByListing = async (listingKey: string): Promise<DTOQuestion[]> => {
  const res = await axios.get<DTOWithMultiWrapper<DTOQuestion>>(
    `${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/questions/listing/${listingKey}`,
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );

  return res.data.data;
};

export const answerQuestion = async (questionId: number, answer: string): Promise<DTOQuestion> => {
  const res = await axios.put<DTOWithSingleWrapper<DTOQuestion>>(
    `${process.env.NEXT_PUBLIC_MOOV_CO_API_URL}/questions/${questionId}`,
    { answer },
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      withCredentials: true
    }
  );

  return res.data.data;
};
