import { FormControl, FormLabel, TextFieldProps, TextFieldVariants } from '@mui/material';
import { NumberInput } from '../../bits/numberInput';
import { RequiredLabel } from '../../bits/requiredLabel';

export type LabeledNumberInputProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> & {
  label: string;
  value: string;
};

export const LabeledNumberInput = function <Variant extends TextFieldVariants = TextFieldVariants>(
  props: LabeledNumberInputProps<Variant>
) {
  return (
    <FormControl fullWidth error={props.error}>
      <FormLabel>
        {props.label}
        {props.required && <RequiredLabel error={props.error} />}
      </FormLabel>
      <NumberInput {...{ ...props, label: undefined }} />
    </FormControl>
  );
};
