import type { Locale } from '../localization/types';
import { getDefinitions } from '../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class ProfileMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get ManagePassword(): string {
    return this.definitions.ManagePassword;
  }

  public get CompanyInformation(): string {
    return this.definitions.CompanyInformation;
  }

  public get MyInformation(): string {
    return this.definitions.MyInformation;
  }

  public get NotVerified(): string {
    return this.definitions.NotVerified;
  }

  public get SitePreferences(): string {
    return this.definitions.SitePreferences;
  }

  public get CommunicationPreferences(): string {
    return this.definitions.CommunicationPreferences;
  }

  public get UserProfile(): string {
    return this.definitions.UserProfile;
  }

  public get YourProfile(): string {
    return this.definitions.YourProfile;
  }

  public get Profile(): string {
    return this.definitions.Profile;
  }

  public get ViewEditPersonalProfile(): string {
    return this.definitions.ViewEditPersonalProfile;
  }

  public get MyAccount(): string {
    return this.definitions.MyAccount;
  }

  public get Verified(): string {
    return this.definitions.Verified;
  }

  public get VerifyYourEmail(): string {
    return this.definitions.VerifyYourEmail;
  }

  public get Preferences(): string {
    return this.definitions.Preferences;
  }
}
