import { FeatureGuard } from '@/src/guards/featureGuard';
import { FEATURE_NAME } from '@/src/hooks/useFeatures';
import { useLocalizer } from '@/src/localization';
import routes from '@/src/routes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemText, Collapse, List, ListItem } from '@mui/material';
import { FC, MouseEventHandler, useState } from 'react';

export interface SellerToolsProps {
  onNavigate: () => void;
}
export const SellerTools: FC<SellerToolsProps> = ({ onNavigate }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const loc = useLocalizer();

  const toggleBuyerTools = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (_) => {
    onNavigate();
  };
  return (
    <>
      <ListItem>
        <ListItemButton onClick={toggleBuyerTools}>
          <ListItemText primary="Seller Tools" />
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <FeatureGuard featureName={FEATURE_NAME.LISTINGS}>
            <ListItem>
              <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.myListings()}>
                <ListItemText primary={loc.Nav.Listings} />
              </ListItemButton>
            </ListItem>
          </FeatureGuard>
          <FeatureGuard featureName={FEATURE_NAME.EMS}>
            <ListItem>
              <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.equipment()}>
                <ListItemText primary={loc.Nav.Equipment} />
              </ListItemButton>
            </ListItem>
          </FeatureGuard>
          <ListItem>
            <ListItemButton sx={{ pl: 4 }} onClick={handleClick} href={routes.sellSold()}>
              <ListItemText primary={loc.Account.Sold} />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};
