import { contactPreferencesForPhone, contactPreferencesWithHandles } from '@/src/providers/leads/types';
import { ContactFormValues } from '.';
import { FormValidationHook } from '@/src/hooks/useFormValidation';

export const isPhoneRequired = (formValues: ContactFormValues): boolean => {
  return contactPreferencesForPhone.includes(formValues.contact_preference);
};

export const isContactPreferenceRequired = (formValues: ContactFormValues): boolean => {
  return contactPreferencesWithHandles.includes(formValues.contact_preference);
};

export const isContactFormSubmitDisabled = function <T extends ContactFormValues>(formValidationHook: FormValidationHook<T>): boolean {
  const { formValues, formErrorState } = formValidationHook;

  if (formErrorState.email) {
    return true;
  }

  if (isPhoneRequired(formValues) && formValues.phone === '') {
    return true;
  }

  if (isContactPreferenceRequired(formValues) && formValues.contact_handle === '') {
    return true;
  }

  return false;
};

export const defaultContactFormValues: ContactFormValues = {
  email: '',
  first_name: '',
  last_name: '',
  company: '',
  phone: '',
  contact_preference: 8,
  contact_handle: ''
};
