export const calcFieldTextColor = (
  defaultColorClass: string,
  hasErrored?: boolean,
  disabled?: boolean,
  touched?: boolean,
  submitted?: boolean,
  validateOnTouchOrSubmit?: boolean
): string | null => {
  let textColorClass = defaultColorClass;

  switch (true) {
    case disabled:
      textColorClass = 'text-smokey-gray-400';
      break;
    case shouldShowFieldError(hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit):
      textColorClass = 'text-hot-red-500';
      break;
  }

  return textColorClass;
};

export const shouldShowFieldError = (
  hasErrored?: boolean,
  disabled?: boolean,
  touched?: boolean,
  submitted?: boolean,
  validateOnTouchOrSubmit?: boolean
) => {
  if (disabled) return false;
  if (!hasErrored) return false;

  if (!validateOnTouchOrSubmit) return true;

  return touched || submitted;
};
