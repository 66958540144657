import { captureException } from '@sentry/nextjs';
import { createTouchPoint, CreateTouchPointParams } from './repo';

export interface TouchPointParams {
  readonly touchPoint: CreateTouchPointParams;
  readonly GTMEvent?: string;
}

export const touchPoint = async (params: TouchPointParams): Promise<void> => {
  try {
    const payload: CreateTouchPointParams = { ...params.touchPoint };
    await createTouchPoint(payload);
  } catch (error) {
    captureException(error, {
      extra: {
        context: 'Error creating touchpoint',
        createTouchPointPayload: params.touchPoint
      }
    });
  }
};
