import { FC, useState } from 'react';
import Image from 'next/image';
import routes from '../../../routes';
import { useLocalizer } from '../../../localization';

import { SearchBar } from '../searchBar';
import { Badge, Divider, Drawer, Link, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';

export interface MobileHeaderProps {
  readonly variant: 'default' | 'edgy';
  disableSearchBar?: boolean;
  onLoginClick: () => void;
  onLogoutSubmit: () => void;
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import * as MoovIcon from '@/public/images/moov-logo-icon.png';
import { colors } from '@/src/design/designConstants';
import { useSelector } from 'react-redux';
import { getCurrentUser, userIsInternal, userIsLoggedIn } from '@/src/state/user/selectors';
import { BuyerTools } from './menu/buyerTools';
import { SellerTools } from './menu/sellerTools';
import { Language } from './menu/language';
import { useUser } from '@/src/hooks/useUser';
import { PromotedLinks } from './menu/promotedLinks';
import { Login } from './menu/login';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { InternalUserBanner } from '../internalUserBanner';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { Categories } from './menu/categories';
import { useRouter } from 'next/router';
import { LearnMore } from './menu/learnMore';
import { MaintenanceBanner } from '../maintenanceBanner';
import { AnnouncementBanner } from '../announcementBanner';
import { FeatureGuard } from '@/src/guards/featureGuard';
import { useAppSelector } from '@/src/store';
import { getUnreadMessageCount } from '@/src/messages/selectors';
import { FEATURE_NAME } from '@/src/hooks/useFeatures';
import { getHeaderOffsetHeight } from '../selectors';
import { useAppBarResize } from '../useAppbarResize';
import { MESSAGING_LINK_TYPES, typedMessageEvent } from '@/src/state/tracking/fullstory/customEvents/messages';

export interface MobileHeaderProps {
  onLoginClick: () => void;
}
export const MobileHeader: FC<MobileHeaderProps> = ({ onLoginClick }) => {
  const [anchorNavElement, setAnchorNavElement] = useState<null | HTMLElement>(null);
  const user = useSelector(getCurrentUser);
  const loggedIn = useSelector(userIsLoggedIn);
  const internalUser = useSelector(userIsInternal);
  const loc = useLocalizer();
  const { logoutUser } = useUser();
  const { fullStoryEvent, fullStoryAndGTMEvent } = useFullStory();
  const router = useRouter();
  const unreadCount = useAppSelector(getUnreadMessageCount);
  const offsetHeight = useAppSelector(getHeaderOffsetHeight);
  const toggleNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    anchorNavElement ? setAnchorNavElement(null) : setAnchorNavElement(event.currentTarget);
  };

  const closeNavMenu = () => {
    setAnchorNavElement(null);
  };
  const menuOpen = Boolean(anchorNavElement);

  const handleLogout = () => {
    closeNavMenu();
    logoutUser();
  };

  const handleLogin = () => {
    closeNavMenu();
    onLoginClick();
  };

  const handleRegister = () => {
    fullStoryEvent(CustomEvents.register.headerClick);
    closeNavMenu();
  };

  const { appBarRef } = useAppBarResize();

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: colors.surface.white, display: { d: 'none' } }}
      >
        <MaintenanceBanner />
        <AnnouncementBanner />
        <Container maxWidth="d" sx={{ backgroundColor: colors.surface.white }}>
          <Toolbar disableGutters>
            <Stack direction="column" width={'100%'}>
              {internalUser && <InternalUserBanner />}
              <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems="center" sx={{ width: '100%' }}>
                <Image onClick={() => router.push(routes.marketplace())} src={MoovIcon} alt="Moov Icon" width={40} height={40} />
                {menuOpen && loggedIn ? (
                  <Stack minWidth="180px" width={'100%'} alignItems="center">
                    <Typography variant="subtitle3" color={'secondary'}>
                      {user.first_name} {user.last_name}
                    </Typography>
                    <Link href={routes.profile()} underline="none" color={colors.skyBlue[500]} onClick={closeNavMenu}>
                      {loc.Nav.Account}
                    </Link>
                  </Stack>
                ) : !menuOpen ? (
                  <Stack minWidth="180px" width={'100%'}>
                    <SearchBar />
                  </Stack>
                ) : (
                  <></>
                )}
                <IconButton onClick={toggleNavMenu}>{menuOpen ? <FAIcon icon={icons.xMark} scale={1.5} /> : <MenuIcon />}</IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor={'top'}
        open={!!anchorNavElement}
        onClose={closeNavMenu}
        PaperProps={{
          sx: {
            height: '100%',
            minHeight: '100%',
            pt: `${offsetHeight}px`
          }
        }}
        data-testid="mobile-header-drawer"
      >
        <Stack direction={'column'} width={'100%'} divider={<Divider />}>
          {!loggedIn && <Login onLogin={handleLogin} onRegister={handleRegister} />}

          <PromotedLinks onNavigate={closeNavMenu} />
          {loggedIn && (
            <ListItem>
              <ListItemButton onClick={closeNavMenu} href={routes.dashboard()}>
                <ListItemText primary={loc.Nav.Dashboard} />
              </ListItemButton>
            </ListItem>
          )}
          {loggedIn && (
            <FeatureGuard featureName={FEATURE_NAME.MESSAGES}>
              <ListItem data-testid="mobile-messages-link">
                <ListItemButton
                  onClick={() => {
                    closeNavMenu();
                    fullStoryAndGTMEvent(typedMessageEvent.messagingOpen, { location: MESSAGING_LINK_TYPES.MESSAGE_CENTER });
                  }}
                  href={routes.messages()}
                >
                  <ListItemText primary={'Messages'} />
                  <Badge badgeContent={unreadCount} color="error" max={99} sx={{ fontSize: '12px' }} />
                </ListItemButton>
              </ListItem>
            </FeatureGuard>
          )}
          {loggedIn && (
            <FeatureGuard featureName={FEATURE_NAME.MAKE_OFFER}>
              <ListItem data-testid="mobile-messages-link">
                <ListItemButton
                  onClick={() => {
                    closeNavMenu();
                  }}
                  href={routes.offers()}
                >
                  {/* TODO: translations Offers */}
                  <ListItemText primary={'Offers'} />
                </ListItemButton>
              </ListItem>
            </FeatureGuard>
          )}
          <LearnMore onNavigate={closeNavMenu} />
          {loggedIn && <BuyerTools onNavigate={closeNavMenu} />}
          {loggedIn && <SellerTools onNavigate={closeNavMenu} />}
          <Categories onNavigate={closeNavMenu} />
          <Language onNavigate={closeNavMenu} />

          {loggedIn && (
            <ListItem>
              <ListItemButton onClick={handleLogout}>
                <ListItemText primary={loc.Nav.LogOut} />
              </ListItemButton>
            </ListItem>
          )}
        </Stack>
      </Drawer>
    </>
  );
};
