import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { colors, spacing } from '@/src/design/designConstants';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';
import { Divider, Drawer, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { setExpandedOfferId } from '../../slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getExpandedBuyerOffer } from '../../selectors';
import { parseUSDCurrencyStr } from '@/src/utils';
import { OfferHeader } from './offerHeader';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';
import { getExpireDaysColor, getOfferAmountColor } from '../utils';
import { BuyerOfferCTAs } from '../ctas';

export const OffersBuyerExpandedDrawer = () => {
  const theme = useTheme();
  const { drawerOpen, closeDrawer } = useSideDrawer(SideDrawers.ExpandedOffer);
  const buyerOffer = useAppSelector(getExpandedBuyerOffer);
  const dispatch = useAppDispatch();
  const countryOptions = useAppSelector(getLogisticCountries);

  const closeBuyerDrawer = () => {
    dispatch(setExpandedOfferId(''));
    closeDrawer();
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen && Boolean(buyerOffer.key)}
      onClose={closeBuyerDrawer}
      PaperProps={{
        sx: {
          width: '100vw'
        }
      }}
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Stack direction="row" alignItems="center" p={spacing.lg}>
        <IconButton sx={{ p: spacing.lg }} onClick={closeDrawer}>
          <FAIcon icon={icons.chevronLeft} scale={1} color={colors.moovBlue['500']} />
        </IconButton>
        {/* TODO: translations Offers */}
        <Typography variant="subtitle3">Offers</Typography>
      </Stack>
      <Divider />

      <Stack p={spacing.xl} spacing={spacing.xl}>
        <OfferHeader buyerOffer={buyerOffer} />
        <Grid container alignItems="center" rowGap={spacing.xs} spacing={spacing.sm}>
          <Grid>
            <Typography variant="p14">
              <strong>Offer Amount:</strong>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="p14" color={getOfferAmountColor(buyerOffer)}>
              <strong>{parseUSDCurrencyStr(buyerOffer.offer.offer_amount)} USD</strong>
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Divider />
          </Grid>

          <Grid>
            <Typography variant="p14">
              <strong>Date Required By:</strong>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="p14">
              {dayjs(buyerOffer.offer.required_by).isValid() ? dayjs(buyerOffer.offer.required_by).format('MM/DD/YYYY') : 'N/A'}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Divider />
          </Grid>

          <Grid>
            <Typography variant="p14">
              <strong>Destination Country:</strong>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="p14">{buyerOffer.countryText(countryOptions)}</Typography>
          </Grid>

          <Grid xs={12}>
            <Divider />
          </Grid>

          <Grid>
            <Typography variant="p14">
              <strong>Offer Expiration:</strong>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="p14" color={getExpireDaysColor(buyerOffer)}>
              <strong>{buyerOffer.expireDaysText}</strong>
            </Typography>
          </Grid>
        </Grid>
        {buyerOffer.sellerNote && (
          <Paper
            sx={{
              py: spacing.md,
              px: spacing.lg,
              border: `1px solid ${colors.magenta['200']}`,
              background: colors.magenta['50'],
              width: 'fit-content'
            }}
            variant="outlined"
          >
            <Stack direction="row" height="100%" alignItems="center" spacing={spacing.md}>
              <FAIcon icon={icons.noteSticky} />
              <Typography variant="p14">
                <strong>Seller Note:</strong>
                &nbsp;
                {buyerOffer.sellerNote}
              </Typography>
            </Stack>
          </Paper>
        )}
      </Stack>
      <Stack position="fixed" bottom={0} width="100%" p={spacing.xl}>
        <BuyerOfferCTAs offer={buyerOffer} />
      </Stack>
    </Drawer>
  );
};
