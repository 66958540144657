/* eslint-disable @next/next/no-img-element */
import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { Chip, Stack, Typography } from '@mui/material';
import { getCurrentThreadKey } from './selectors';
import { useAppDispatch } from '@/src/store';
import { MessageThread } from './types';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { useSelector } from 'react-redux';
import { selectMessage } from './slice';
import { SenderChip } from './senderChip';
import { formatShortDate } from './utils';
import Image from 'next/image';

export const MessageSummary: React.FC<{ messageThread: MessageThread }> = ({ messageThread }) => {
  const mostRecentMessage = messageThread.messages[messageThread.messages.length - 1];
  const photo = messageThread.listing_info.photo?.small_url || IMAGE_URLS.NULL_STATE_PHOTO_SMALL;
  const currentThreadKey = useSelector(getCurrentThreadKey);
  const isSelected = currentThreadKey === messageThread.key;
  const dispatch = useAppDispatch();
  return (
    <Stack
      onClick={() => dispatch(selectMessage(messageThread.key))}
      bgcolor={isSelected ? colors.corpGray['100'] : colors.surface.white}
      sx={{ ...(!isSelected && { cursor: 'pointer' }) }}
      alignItems="center"
      direction="row"
      padding={spacing.xl}
      spacing={spacing.lg}
    >
      <Stack maxWidth="25%">
        <Image
          alt={`listing-image-${messageThread.listing_info?.make_model}`}
          src={photo}
          quality={100}
          objectFit="cover"
          height={80}
          width={80}
          style={{ borderRadius: borderRadius.rounded }}
        />
      </Stack>

      <Stack flex={1} width="75%" spacing={spacing.md} justifyContent={'center'}>
        <Stack direction="row" justifyContent={'space-between'} spacing={spacing.sm}>
          <Typography variant="caption" color={colors.smokeyGray['400']} noWrap>
            {messageThread.listing_info.make_model}
          </Typography>
          <Typography color={colors.smokeyGray['400']} variant="caption" whiteSpace={'nowrap'}>
            {formatShortDate(mostRecentMessage.created_at)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'}>
          <SenderChip message={mostRecentMessage} />
          {!mostRecentMessage.seen && <Chip color="error" label="New" />}
        </Stack>
        <Typography variant="body2" fontWeight={mostRecentMessage.seen ? 400 : 700} noWrap>
          {mostRecentMessage.body}
        </Typography>
      </Stack>
    </Stack>
  );
};
