import { getDefinitions, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class ServicesLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AftermarketServices(): string {
    return this.definitions.AftermarketServices;
  }

  public get AutomaticallyOpted(): string {
    return this.definitions.AutomaticallyOpted;
  }

  public get ConfidenceWithGuarantee(): string {
    return this.definitions.ConfidenceWithGuarantee;
  }

  public get CustomerFocused(): string {
    return this.definitions.CustomerFocused;
  }

  public get CustomerPortal(): string {
    return this.definitions.CustomerPortal;
  }

  public get DataPointsCargo(): string {
    return this.definitions.DataPointsCargo;
  }

  public get DayDelivery(): string {
    return this.definitions.DayDelivery;
  }

  public get EnjoyWorryFree(): string {
    return this.definitions.EnjoyWorryFree;
  }

  public get FastReliableSecure(): string {
    return this.definitions.FastReliableSecure;
  }

  public get FeeValueCoverage(): string {
    return this.definitions.FeeValueCoverage;
  }

  public get ForASmallFee(): string {
    return this.definitions.ForASmallFee;
  }

  public get AutomaticallyOptedMBG(): string {
    return this.definitions.AutomaticallyOptedMBG;
  }

  public get NowFree(): string {
    return this.definitions.NowFree;
  }

  public get GPSMonitoring(): string {
    return this.definitions.GPSMonitoring;
  }

  public get HandOffWork(): string {
    return this.definitions.HandOffWork;
  }

  public get HowItWorks(): string {
    return this.definitions.HowItWorks;
  }

  public get IncidentOccur(): string {
    return this.definitions.IncidentOccur;
  }

  public get LearnMoreAtSemi(): string {
    return this.definitions.LearnMoreAtSemi;
  }

  public get LearnMore(): string {
    return this.definitions.LearnMore;
  }

  public get LiabilityInsurance(): string {
    return this.definitions.LiabilityInsurance;
  }

  public get LiveTracking(): string {
    return this.definitions.LiveTracking;
  }

  public get Metrics(): string {
    return this.definitions.Metrics;
  }

  public get MoneyBackGuarantee(): string {
    return this.definitions.MoneyBackGuarantee;
  }

  public get MoovsAssurance(): string {
    return this.definitions.MoovsAssurance;
  }

  public get NotifyIfDissatisfied(): string {
    return this.definitions.NotifyIfDissatisfied;
  }

  public get ProviderNetwork(): string {
    return this.definitions.ProviderNetwork;
  }

  public get SatisfactionRate(): string {
    return this.definitions.SatisfactionRate;
  }

  public get TakeStressOut(): string {
    return this.definitions.TakeStressOut;
  }

  public get ToolYouNeed(): string {
    return this.definitions.ToolYouNeed;
  }

  public get Tracking(): string {
    return this.definitions.Tracking;
  }

  public get TrackRecordClaims(): string {
    return this.definitions.TrackRecordClaims;
  }

  public get TrustedInsurance(): string {
    return this.definitions.TrustedInsurance;
  }

  public get SemiOrgLogo(): string {
    return this.definitions.SemiOrgLogo;
  }

  public get Remarketing(): string {
    return this.definitions.Remarketing;
  }

  public get EquipmentLiquidation(): string {
    return this.definitions.EquipmentLiquidation;
  }

  public get MaximizeRecovery(): string {
    return this.definitions.MaximizeRecovery;
  }

  public get LeverageTheExpertise(): string {
    return this.definitions.LeverageTheExpertise;
  }

  public get PricingAndStrategyRecommendations(): string {
    return this.definitions.PricingAndStrategyRecommendations;
  }
}
