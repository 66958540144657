import { ReactNode } from 'react';
import { useContext } from 'react';
import { MobileSuggestedItemsModalDispatch, MobileSuggestedItemsModalStore } from '../../../state/mobileSuggestedItemsModal/context';
import WorkFlowButtonGroup from './workflowButtonGroup';
import Modal2 from '../../../bits/modals/modal2';

const ModalBase = ({ children }: { children: ReactNode }) => {
  const { closeModal } = useContext(MobileSuggestedItemsModalDispatch);
  const { modalOpen } = useContext(MobileSuggestedItemsModalStore);

  return (
    <Modal2 isOpen={modalOpen} onClose={() => closeModal()} className="h-full bg-white">
      {/* // DO NOT REMOVE --safari-bottom-drawer-spacing, see customProperties.css for explanation. */}
      <div className="h-full overflow-y-auto pb-[calc(var(--safari-bottom-drawer-spacing)_+_6.25rem)]">{children}</div>
      <WorkFlowButtonGroup />
    </Modal2>
  );
};

export default ModalBase;
