import React, { useMemo, useState } from 'react';

import { Field, FieldHookConfig, useField } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { Localizer, useLocalizer } from '../../../localization';
import { genHash, getFieldDescribedById, isEmpty } from '../../../utils';
import { FormikFieldWrapper } from './formikWrappers';
import { FULLSTORY_EXCLUDE_DATA_CAPTURE_CLASSNAME } from '@/src/design/designConstants';

export const passwordValidationFunctions = {
  hasAtLeastEightLength: (value: string | undefined) => (typeof value === 'undefined' ? false : value.length >= 8),
  hasAtLeastOneNumOrSpecial: (value: string | undefined) => (typeof value === 'undefined' ? false : /[^A-Za-z]+/.test(value)),
  hasAtLeastOneCapital: (value: string | undefined) => (typeof value === 'undefined' ? false : /[A-Z]+/.test(value))
};

const getPasswordValidation = (loc: Localizer) => [
  {
    key: 'Has 8 or more characters',
    message: loc.Forms.HasEightOrMore,
    isValid: passwordValidationFunctions.hasAtLeastEightLength
  },
  {
    key: 'Contains at least 1 number or special character',
    message: loc.Forms.ContainsOneNumberOrSpecial,
    isValid: passwordValidationFunctions.hasAtLeastOneNumOrSpecial
  },
  {
    key: 'Contains at least one capital letter',
    message: loc.Forms.ContainsOneCapital,
    isValid: passwordValidationFunctions.hasAtLeastOneCapital
  }
];

interface ValidationListItemProps {
  readonly containerClass?: string;
  readonly iconClass?: string;
  readonly message: string;
  readonly Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
}
/**
 *
 */
const ValidationListItem = ({ containerClass = '', iconClass = '', message, Icon }: ValidationListItemProps) => (
  <li className={`flex ${containerClass}`}>
    <div className={`mr-2 mt-[0.1em] text-[1.2em] ${iconClass}`}>
      <Icon fontSize="inherit" />
    </div>
    <div>{message}</div>
  </li>
);

type Props = FieldHookConfig<string> & {
  readonly autoComplete?: 'on' | 'off';
  readonly label?: string;
  readonly name: string;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly required?: boolean;
  readonly showValidationList?: boolean;
};

/**
 *
 */
const FormikPasswordField = (props: Props) => {
  const { autoComplete, disabled, label, name, placeholder, required, showValidationList, helperText } = props;
  const [field, meta] = useField(props);
  const { value } = field;
  const { error, touched } = meta;
  const [showPassword, setShowPassword] = useState(false);
  const valildationsId = useMemo(() => genHash(), []);
  const loc = useLocalizer();

  const renderValidations = () => {
    if (!showValidationList) return null;

    const statusBars: JSX.Element[] = [];
    const validationListItems: JSX.Element[] = [];
    getPasswordValidation(loc).forEach(({ key, message, isValid }) => {
      if ((required && touched) || !isEmpty(value)) {
        if (isValid(value)) {
          statusBars.push(<li key={`bar-${key}`} className="flex-1 bg-success-green" />);

          validationListItems.push(
            <ValidationListItem key={key} message={message} containerClass="text-success-green" Icon={CheckCircleIcon} />
          );
        } else {
          statusBars.push(<li key={`bar-${key}`} className="flex-1 bg-danger-red" />);

          validationListItems.push(<ValidationListItem key={key} message={message} containerClass="text-danger-red" Icon={CancelIcon} />);
        }
      } else {
        statusBars.push(<li key={`bar-${key}`} className="flex-1 bg-blue-250" />);

        validationListItems.push(
          <ValidationListItem key={key} message={message} iconClass="text-blue-250" Icon={CheckCircleOutlineIcon} />
        );
      }
    });

    return (
      <div className="mt-2">
        <ul className="mb-2 flex h-2 gap-x-2">{statusBars}</ul>
        <ul className="flex flex-col gap-y-4 text-14spx" id={valildationsId}>
          {validationListItems}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <FormikFieldWrapper
        name={name}
        label={label}
        helperText={helperText}
        required={required}
        disabled={disabled}
        touched={touched}
        error={error}
      >
        <Field
          className={`${FULLSTORY_EXCLUDE_DATA_CAPTURE_CLASSNAME} form-input relative flex-1 rounded-r-none border-r-0 ${
            touched && !isEmpty(error) ? 'input-invalid' : ''
          }`}
          {...field}
          autoComplete={autoComplete}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          type={showPassword ? 'text' : 'password'}
          aria-describedby={`${getFieldDescribedById(name)} ${valildationsId}`}
          aria-invalid={touched && !isEmpty(error)}
          title={name}
        />
        <button
          className={`form-input-append bg-transparent ${touched && !isEmpty(error) ? 'input-invalid' : ''}`}
          onClick={() => setShowPassword(!showPassword)}
          aria-label={showPassword ? loc.Forms.HidePassword : loc.Forms.ShowPassword}
          type="button"
          data-testid="ShowPasswordButton"
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </button>
      </FormikFieldWrapper>

      {renderValidations()}
    </div>
  );
};

export default FormikPasswordField;
