import { Formik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';

import { TransparentDarkHoverButton } from '../../bits/buttons';
import { FormikFormWrapper } from '../../bits/forms/formikFields/formikWrappers';
import ModalPopup from '../../bits/modals/modalPopup';
import { H3, H6 } from '../../bits/text';
import { useLocalizer } from '../../localization';
import { CookiePreferences, getCookiePrefs, markCookieActionTaken, setCookiePrefs } from '../../utils/cookies';
import { COOKIE_PREFS_ANALYTICS, COOKIE_PREFS_ESSENTIAL, COOKIE_PREFS_FUNCTIONAL } from '../../constants/cookie';
import FormikCookieToggleField from '../../bits/forms/formikFields/formikCookieToggleField';
import { useModal } from '../../hooks/useModal';
import { SetModal } from '../../entities/common';
import { MoovButton } from '../../bits/moovButton';

interface CookiePreferencesModalProps {
  readonly setModal: SetModal;
  readonly beforeSave?: () => void;
}

/**
 * TODO 182433668 log missing translations once we get the legal copy from legal Andrew. legal.
 */
const CookiePreferencesModal = (props: CookiePreferencesModalProps) => {
  const { setModal, beforeSave } = props;
  const loc = useLocalizer();
  const { isOpen, onClose } = useModal(setModal);

  const validationSchema = yup.object({
    [COOKIE_PREFS_ESSENTIAL]: yup.number(),
    [COOKIE_PREFS_FUNCTIONAL]: yup.number(),
    [COOKIE_PREFS_ANALYTICS]: yup.number()
  });

  const [initialValues, setInitialValues] = useState<CookiePreferences>({
    [COOKIE_PREFS_ESSENTIAL]: 1,
    [COOKIE_PREFS_FUNCTIONAL]: 0,
    [COOKIE_PREFS_ANALYTICS]: 0
  });

  useEffect(() => {
    const cookiePrefs = getCookiePrefs(document);
    if (cookiePrefs !== null) {
      setInitialValues(cookiePrefs);
    }
  }, []);

  const handleSubmit = async (values: CookiePreferences) => {
    setCookiePrefs(document, values);
    markCookieActionTaken(document);
    beforeSave?.();
    onClose();
  };

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose} className="max-w-md-modal">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ isSubmitting, errors, submitCount, values }) => (
          <FormikFormWrapper isSubmitting={isSubmitting} errors={errors} submitCount={submitCount}>
            <div className="px-4 pb-10 lg:px-16 lg:pb-8">
              <article className="mb-8">
                <H3 className="mb-7 lg:mb-3">Cookie Preferences</H3>
                <H6>
                  These cookies will only be stored in your browser with your prior consent. You can choose to enable or disable some or all
                  of these cookies but disabling some of them may affect your browsing experience.
                </H6>
              </article>

              <div className="mb-6 flex flex-col gap-y-4 lg:mb-10 lg:gap-y-5">
                <div className="h-px w-full bg-smokey-gray-300 " />

                <FormikCookieToggleField
                  title="Essential"
                  description="Essential cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data."
                  name={COOKIE_PREFS_ESSENTIAL}
                  value={values[COOKIE_PREFS_ESSENTIAL]}
                  checkedValue={1}
                  disabled={true}
                  ariaLabel="Essential cookies"
                />

                <div className="h-px w-full bg-smokey-gray-300 " />

                <FormikCookieToggleField
                  title="Functional"
                  description="Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features."
                  name={COOKIE_PREFS_FUNCTIONAL}
                  value={values[COOKIE_PREFS_FUNCTIONAL]}
                  checkedValue={1}
                  ariaLabel="Functional cookies"
                />

                <div className="h-px w-full bg-smokey-gray-300 " />

                <FormikCookieToggleField
                  title="Analytics"
                  description="Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc."
                  name={COOKIE_PREFS_ANALYTICS}
                  value={values[COOKIE_PREFS_ANALYTICS]}
                  checkedValue={1}
                  ariaLabel="Analytical cookies"
                />
              </div>

              <div className="flex justify-end gap-2 text-14spx">
                <TransparentDarkHoverButton onClick={onClose} className="px-3 py-2.5">
                  {loc.Modals.Close}
                </TransparentDarkHoverButton>
                <MoovButton variant="secondary" type="submit" className="px-3 py-2.5">
                  Save Preferences
                </MoovButton>
              </div>
            </div>
          </FormikFormWrapper>
        )}
      </Formik>
    </ModalPopup>
  );
};

export default CookiePreferencesModal;
