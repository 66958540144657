import { AddressSerializable } from '@/src/common/lib';
import { unreachable } from '@/src/utils/typeSafe';

export interface AddFormState {
  currentTab: number;
  modelId: number;
  textManufacturer: string;
  textModel: string;
  modelNotListed: boolean;
  minSellPrice: number | '';
  condition: number | '';
  toolStatus: number | '';
  waferSizes: number[];
  vintage: number | '';
  serialNumber: string;
  quantity: number | '';
  address: AddressSerializable;
  description: string;
  configuration: string;
  customFields: Record<string, string>;
}

export type Action =
  | {
      type: 'setCurrentTab';
      value: number;
    }
  | {
      type: 'setTextManufacturer';
      value: string;
    }
  | {
      type: 'setTextModel';
      value: string;
    }
  | {
      type: 'setModelId';
      value: number;
    }
  | {
      type: 'setModelNotListed';
      value: boolean;
    }
  | {
      type: 'setMinSellPrice';
      value: number | '';
    }
  | {
      type: 'setCondition';
      value: number | '';
    }
  | {
      type: 'setToolStatus';
      value: number | '';
    }
  | {
      type: 'setWaferSizes';
      value: number[];
    }
  | {
      type: 'setVintage';
      value: number | '';
    }
  | {
      type: 'setSerialNumber';
      value: string;
    }
  | {
      type: 'setQuantity';
      value: number | '';
    }
  | {
      type: 'setAddress';
      value: AddressSerializable;
    }
  | {
      type: 'setDescription';
      value: string;
    }
  | {
      type: 'setConfiguration';
      value: string;
    }
  | {
      type: 'setCustomField';
      field: string;
      value: string;
    };

export const getInitialState = (): AddFormState => {
  return {
    currentTab: 0,
    modelId: 0,
    textManufacturer: '',
    textModel: '',
    modelNotListed: false,
    minSellPrice: '',
    condition: '',
    waferSizes: [],
    vintage: '',
    serialNumber: '',
    quantity: '',
    description: '',
    configuration: '',
    customFields: {},
    toolStatus: '',
    address: { country: '' }
  };
};

export const hasCountrySet = ({ address }: AddFormState) => {
  return Boolean(address.country);
};

export const hasModelSet = ({ modelId, textManufacturer, textModel, modelNotListed }: AddFormState) => {
  return modelNotListed ? (textModel.trim().length && textManufacturer.trim().length) > 0 : !!modelId;
};

const ReducerInternal = (state: AddFormState, action: Action): AddFormState => {
  const { type, value } = action;

  switch (type) {
    case 'setCurrentTab': {
      return { ...state, currentTab: value };
    }
    case 'setTextManufacturer': {
      return { ...state, textManufacturer: value };
    }
    case 'setTextModel': {
      return { ...state, textModel: value };
    }
    case 'setModelId': {
      return { ...state, modelId: value };
    }
    case 'setModelNotListed': {
      return { ...state, modelNotListed: value };
    }
    case 'setMinSellPrice': {
      return { ...state, minSellPrice: value };
    }
    case 'setCondition': {
      return { ...state, condition: value };
    }
    case 'setToolStatus': {
      return { ...state, toolStatus: value };
    }
    case 'setWaferSizes': {
      return { ...state, waferSizes: value };
    }
    case 'setVintage': {
      return { ...state, vintage: value };
    }
    case 'setSerialNumber': {
      return { ...state, serialNumber: value };
    }
    case 'setQuantity': {
      return { ...state, quantity: value };
    }
    case 'setAddress': {
      return { ...state, address: value };
    }
    case 'setDescription': {
      return { ...state, description: value };
    }
    case 'setConfiguration': {
      return { ...state, configuration: value };
    }
    case 'setCustomField': {
      return { ...state, customFields: { ...state.customFields, [action.field]: value } };
    }
    default:
      unreachable(type);
      return state;
  }
};

const normalizeTabInState = (newState: AddFormState) => {
  const cleanedTab = hasModelSet(newState) ? newState.currentTab : 0;
  return { ...newState, tab: cleanedTab };
};

export const Reducer = (state: AddFormState, action: Action): AddFormState => {
  const newState = ReducerInternal(state, action);
  return normalizeTabInState(newState);
};
