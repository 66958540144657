import { EquipmentSerializable, equipmentIsListed } from '@/src/equipment/types';
import EquipmentFileDropzone, { EquipmentFileDropzoneFileType } from 'src/equipment/ui/equipmentFileDropzone';
import { FC } from 'react';
import { CheckmarkSvgIcon } from '@/src/bits/svgs/checkmark';
import TAILWIND_CSS_CONFIG from '@/tailwind.config';
import { useLocalizer } from '@/src/localization';
interface RequestPricePhotosProps {
  equipment: EquipmentSerializable;
  files: EquipmentFileDropzoneFileType[];
  filesToDelete: EquipmentFileDropzoneFileType[];
  setFiles: (files: EquipmentFileDropzoneFileType[]) => void;
  setFilesToDelete: (files: EquipmentFileDropzoneFileType[]) => void;
}

export const RequestPricePhotos: FC<RequestPricePhotosProps> = ({ equipment, setFiles, setFilesToDelete, files, filesToDelete }) => {
  const loc = useLocalizer();
  return (
    <div>
      <div className="flex items-center justify-center">
        <CheckmarkSvgIcon className="mr-3 h-4 w-4" fill={TAILWIND_CSS_CONFIG.theme.colors.magenta['500']} />
        <div className="text-24spx font-bold text-magenta-500">{loc.EquipmentManage.ThankYou}</div>
      </div>
      <div className="my-4 flex items-center gap-2 rounded-lg bg-magenta-50 p-6 text-center text-16spx text-moov-blue-500">
        <p>{loc.EquipmentManage.YourMoovAEWillBeWorkingOnThis}</p>
      </div>

      <EquipmentFileDropzone
        fileType="image"
        accept={['image/png', 'image/jpeg']}
        acceptedTypesLabel="JPG, PNG"
        maxSize={5242880} // 5MB
        multiple
        dirty={false}
        showUpdateButton={false}
        displayPhotoCTA={equipmentIsListed(equipment)}
        files={files}
        setFiles={setFiles}
        filesToDelete={filesToDelete}
        setFilesToDelete={setFilesToDelete}
      />
    </div>
  );
};
