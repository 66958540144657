import { CircularProgress } from '@mui/material';
import { FC, HTMLAttributes, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { CloseIcon } from '../svgs/svgs';
import TAILWIND_CSS_CONFIG from '../../../tailwind.config';

// -- #standard buttons
// -----------------------------------------------
/**
 * @deprecated The method should not be used
 */
interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  readonly children: ReactNode;
  readonly className?: string;
  readonly type?: 'button' | 'submit' | 'reset';
  readonly disabled?: boolean;
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
  readonly isLoading?: boolean;
}

/**
 * base styling is passed as a class so that tailwind's utility classes can override them, allowing for greater versatility
 * @deprecated The method should not be used
 */
export const BaseButton = ({ children, className = '', type = 'button', disabled, onClick, isLoading, ...props }: ButtonProps) => {
  const loadingClassName = isLoading ? 'disabled:cursor-not-allowed' : '';
  const disabledClassName = disabled ? 'disabled:cursor-not-allowed disabled:opacity-50' : '';

  return (
    <button
      className={`base-button  ${className} ${loadingClassName} ${disabledClassName}`}
      disabled={disabled || isLoading}
      type={type}
      onClick={onClick}
      {...props}
    >
      {isLoading && (
        <div className="absolute-center flex items-center justify-center">
          <CircularProgress color="inherit" size="1.3em" data-testid="MUICircularProgress" />
        </div>
      )}
      <div className={`flex items-center justify-center ${isLoading ? 'text-transparent' : ''}`}>{children}</div>
    </button>
  );
};

/**
 * @deprecated The method should not be used
 */
export enum FabButtonType {
  close = 'close'
}

/**
 * @deprecated The method should not be used
 */
export interface FABButtonProps {
  readonly type: FabButtonType;
  readonly disabled?: boolean;
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

// FAB = Floating Action Button in material design
/**
 * @deprecated The method should not be used
 */
export const FAB: FC<FABButtonProps> = ({ type, disabled, onClick }) => {
  let icon;

  switch (type) {
    case FabButtonType.close:
      icon = <CloseIcon fill={TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500']} className="h-3 w-3" />;
      break;

    default:
      break;
  }
  return (
    <button
      type="button"
      className={`flex h-11 w-11 items-center justify-center rounded-full bg-white`}
      onClick={onClick}
      disabled={disabled}
      data-testid="FAB"
    >
      {icon}
    </button>
  );
};

/**
 * @deprecated The method should not be used
 */
export const WhiteButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-growing-bubble border-gray-225 rounded border-2 border-solid bg-white text-moov-blue-500 ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export const BrightPurpleButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover bg-magenta-500-old text-white hover:brightness-110 ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export const MagentaButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover bg-magenta-500 text-white ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export const BlueButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover bg-blue-500 text-white ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export const DarkBlueButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover bg-moov-blue-500 text-white ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export const TransDarkBlueButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover border-2 border-moov-blue-500 bg-transparent text-moov-blue-500 transition-all hover:bg-moov-blue-500 hover:text-white ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * @deprecated The method should not be used
 */
export interface TransDarkBlueButtonPersistProps extends ButtonProps {
  persisted: boolean;
}

/**
 * @deprecated The method should not be used
 */
export const TransDarkBluePersistButton: FC<TransDarkBlueButtonPersistProps> = ({
  children,
  className = '',
  type = 'button',
  disabled,
  persisted,
  onClick,
  ...props
}) => {
  const persistedClass = `border-2 border-moov-blue-500 bg-moov-blue-500 text-moov-blue-500 text-white ${className}`;
  const unClickedClass = `animate-brighten-on-hover border-2 border-moov-blue-500 bg-transparent text-moov-blue-500 transition-all hover:bg-moov-blue-500 hover:text-white ${className}`;

  const [localClassName, setLocalClassName] = useState(className);

  useEffect(() => {
    setLocalClassName(persisted ? persistedClass : unClickedClass);
  }, [persisted]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setLocalClassName(persistedClass);
    onClick && onClick(e);
  };

  return (
    <BaseButton className={localClassName} disabled={disabled} type={type} onClick={handleClick} {...props}>
      {children}
    </BaseButton>
  );
};

/**
 * @deprecated The method should not be used
 */
export const PeachButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <BaseButton
    className={`animate-brighten-on-hover bg-peach text-white ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

// -- #special buttons
// -----------------------------------------------

type HoverBgColorClassName = `after:bg-${string}`;

interface TransparentButtonProps extends ButtonProps {
  readonly hoverBgColorClassName: HoverBgColorClassName;
}

/**
 * after:bg-color needs to be set in order to enable the hover animation
 * @deprecated The method should not be used
 */
const TransparentButton = ({
  children,
  className = '',
  hoverBgColorClassName,
  type = 'button',
  disabled,
  onClick,
  ...props
}: TransparentButtonProps) => (
  <BaseButton
    className={`animate-growing-bubble-transparent bg-transparent ${hoverBgColorClassName} ${className}`}
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * Button with a transparent background where the background gets darker on hover
 * @deprecated The method should not be used
 */
export const TransparentDarkHoverButton = ({ children, className = '', type = 'button', disabled, onClick, ...props }: ButtonProps) => (
  <TransparentButton
    className={`bg-transparent text-moov-blue-500 after:bg-opacity-5 ${className}`}
    hoverBgColorClassName="after:bg-black"
    disabled={disabled}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </TransparentButton>
);
