import { SelectOption } from '../../bits/forms/fields/selectField';
import { CONDITION } from '../../entities/wanted';
import { getDefinitions, interpolateVariable, Locale } from '../../localization';
import translations from './index.loc.json';

export class ListingLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AsIs(): string {
    return this.definitions.AsIs;
  }

  public BuyOrSellUsedMakeModel(makeName: string, modelName: string): string {
    const makeInterpolated = interpolateVariable(this.definitions.BuyOrSellUsedMakeModel, 'make_name', makeName);
    return interpolateVariable(makeInterpolated, 'model_name', modelName);
  }

  public ConditionName(condition: CONDITION): string {
    switch (condition) {
      case CONDITION.USED:
        return this.definitions.Used;
      case CONDITION.REFURBISHED:
        return this.definitions.Refurbished;
      case CONDITION.NEW:
        return this.definitions.New;
      case CONDITION.PARTS_TOOL:
        return this.definitions.PartsTool;
      default:
        return this.definitions.Unknown;
    }
  }

  public get Configuration(): string {
    return this.definitions.Configuration;
  }

  public get Description(): string {
    return this.definitions.Description;
  }

  public get HotItem(): string {
    return this.definitions.HotItem;
  }

  public get LastVerified(): string {
    return this.definitions.LastVerified;
  }

  public get LetMoovTakeRiskOut(): string {
    return this.definitions.LetMoovTakeRiskOut;
  }

  public ListingCount(count: number): string {
    return interpolateVariable(this.definitions.ListingCount, 'listings_count', count);
  }

  public get ListingNoLongerAvailable(): string {
    return this.definitions.ListingNoLongerAvailable;
  }

  public get ListingStrength(): string {
    return this.definitions.ListingStrength;
  }

  public get ListItWithMoov(): string {
    return this.definitions.ListItWithMoov;
  }

  public get ManufacturerAndModel(): string {
    return this.definitions.ManufacturerAndModel;
  }

  public get Model(): string {
    return this.definitions.Model;
  }

  public get MoovingCanBeRisky(): string {
    return this.definitions.MoovingCanBeRisky;
  }

  public get MoreCompleteListings(): string {
    return this.definitions.MoreCompleteListings;
  }

  public get NeedAToolSpecification(): string {
    return this.definitions.NeedAToolSpecification;
  }

  public get New(): string {
    return this.definitions.New;
  }

  public get OneStopShopForMooving(): string {
    return this.definitions.OneStopShopForMooving;
  }

  public get PartsTool(): string {
    return this.definitions.PartsTool;
  }

  public get PercentComplete(): string {
    return this.definitions.PercentComplete;
  }

  public get Refurbished(): string {
    return this.definitions.Refurbished;
  }

  public get SellWithMoov(): string {
    return this.definitions.SellWithMoov;
  }

  public get SerialNumber(): string {
    return this.definitions.SerialNumber;
  }

  public get SimilarListings(): string {
    return this.definitions.SimilarListings;
  }

  public get TargetPrice(): string {
    return this.definitions.TargetPrice;
  }

  public get Unknown(): string {
    return this.definitions.Unknown;
  }

  public get Used(): string {
    return this.definitions.Used;
  }

  public get ViewListing(): string {
    return this.definitions.ViewListing;
  }

  public get LookingForSomeMoreInformation(): string {
    return this.definitions.LookingForSomeMoreInformation;
  }

  public get QuicklyGetAResponse(): string {
    return this.definitions.QuicklyGetAResponse;
  }

  public get SellItWithMoov(): string {
    return this.definitions.SellItWithMoov;
  }

  public get RequestDetails(): string {
    return this.definitions.RequestDetails;
  }

  public get MakeOffer(): string {
    return this.definitions.MakeOffer;
  }

  public get Verified(): string {
    return this.definitions.Verified;
  }

  public get RequestVerification(): string {
    return this.definitions.RequestVerification;
  }

  public get BreatheEasyWithVerifiedListings(): string {
    return this.definitions.BreatheEasyWithVerifiedListings;
  }

  public get MoovValidatesAllListings(): string {
    return this.definitions.MoovValidatesAllListings;
  }

  public get KeyItemDetails(): string {
    return this.definitions.KeyItemDetails;
  }

  public HeadTitle(makeName: string, modelName: string): string {
    const makeInterpolated = interpolateVariable(this.definitions.HeadTitle, 'make_name', makeName);
    return interpolateVariable(makeInterpolated, 'model_name', modelName);
  }

  public get LastVerifiedColon(): string {
    return this.definitions.LastVerifiedColon;
  }

  public get ProductIdColon(): string {
    return this.definitions.ProductIdColon;
  }

  public get VintageColon(): string {
    return this.definitions.VintageColon;
  }

  public get ConfigurationColon(): string {
    return this.definitions.ConfigurationColon;
  }

  public get DescriptionColon(): string {
    return this.definitions.DescriptionColon;
  }

  public get ConditionColon(): string {
    return this.definitions.ConditionColon;
  }

  public get WaferSizesColon(): string {
    return this.definitions.WaferSizesColon;
  }

  public get SerialNumberColon(): string {
    return this.definitions.SerialNumberColon;
  }

  public get NoConfiguration(): string {
    return this.definitions.NoConfiguration;
  }

  public get OEMModelDescription(): string {
    return this.definitions.OEMModelDescription;
  }

  public get NoOEMModel(): string {
    return this.definitions.NoOEMModel;
  }

  public get NoneProvided(): string {
    return this.definitions.NoneProvided;
  }

  public get OtherInformation(): string {
    return this.definitions.OtherInformation;
  }

  public get NoOtherInformationProvided(): string {
    return this.definitions.NoOtherInformationProvided;
  }

  public get AvailableServices(): string {
    return this.definitions.AvailableServices;
  }

  public get OurSalesTeamIsStandingBy(): string {
    return this.definitions.OurSalesTeamIsStandingBy;
  }

  public get NoSimilarListings(): string {
    return this.definitions.NoSimilarListings;
  }

  public get SeeSimilarListings(): string {
    return this.definitions.SeeSimilarListings;
  }

  public get YourListingHasBeenCreated(): string {
    return this.definitions.YourListingHasBeenCreated;
  }

  public get YourListingIsNowLive(): string {
    return this.definitions.YourListingIsNowLive;
  }

  public get YourListingHasBeenSubmitted(): string {
    return this.definitions.YourListingHasBeenSubmitted;
  }

  public get OnceYourListingIsVerified(): string {
    return this.definitions.OnceYourListingIsVerified;
  }

  public get WaferSize(): string {
    return this.definitions.WaferSize;
  }

  public get Vintage(): string {
    return this.definitions.Vintage;
  }

  public get Condition(): string {
    return this.definitions.Condition;
  }

  public get LearnMore(): string {
    return this.definitions.LearnMore;
  }

  public get NotInterested(): string {
    return this.definitions.NotInterested;
  }

  public get WellGetMorePhotos(): string {
    return this.definitions.WellGetMorePhotos;
  }

  public get ShowNext(): string {
    return this.definitions.ShowNext;
  }

  public get Done(): string {
    return this.definitions.Done;
  }

  public get WhyAreYouInterested(): string {
    return this.definitions.WhyAreYouInterested;
  }

  public get WhatElseWouldYouLikeToKnow(): string {
    return this.definitions.WhatElseWouldYouLikeToKnow;
  }

  public get YouLikedThisItem(): string {
    return this.definitions.YouLikedThisItem;
  }

  public get YourAccountExecutiveWillContact(): string {
    return this.definitions.YourAccountExecutiveWillContact;
  }

  public get YouDiscardedThisItem(): string {
    return this.definitions.YouDiscardedThisItem;
  }

  public get PleaseContactYourAE(): string {
    return this.definitions.PleaseContactYourAE;
  }

  public get ThankYouForSubmission(): string {
    return this.definitions.ThankYouForSubmission;
  }

  public get IncreaseYourListingStrength(): string {
    return this.definitions.IncreaseYourListingStrength;
  }

  public get AddAsMuchInformationToListing(): string {
    return this.definitions.AddAsMuchInformationToListing;
  }

  public get Why(): string {
    return this.definitions.Why;
  }

  public get AskingPrice(): string {
    return this.definitions.AskingPrice;
  }

  public get AddAskingPrice(): string {
    return this.definitions.AddAskingPrice;
  }

  public get AddVintage(): string {
    return this.definitions.AddVintage;
  }
  public get AddSerialNumber(): string {
    return this.definitions.AddSerialNumber;
  }

  public get ProductPhotos(): string {
    return this.definitions.ProductPhotos;
  }

  public get Visiblity(): string {
    return this.definitions.Visiblity;
  }

  public get YourListingsWillShowAsAvailable(): string {
    return this.definitions.YourListingsWillShowAsAvailable;
  }

  public get Location(): string {
    return this.definitions.Location;
  }

  public get Serial(): string {
    return this.definitions.Serial;
  }

  public get AddMorePhotos(): string {
    return this.definitions.AddMorePhotos;
  }

  public get AddPhotosToSellFaster(): string {
    return this.definitions.AddPhotosToSellFaster;
  }

  public get AddPhotos(): string {
    return this.definitions.AddPhotos;
  }

  public get ManageListing(): string {
    return this.definitions.ManageListing;
  }

  public get PublicView(): string {
    return this.definitions.PublicView;
  }

  public get EnterConfiguration(): string {
    return this.definitions.EnterConfiguration;
  }

  public get NoDescription(): string {
    return this.definitions.NoDescription;
  }

  public get Respond(): string {
    return this.definitions.Respond;
  }

  public get Action(): string {
    return this.definitions.Action;
  }

  public get QTY(): string {
    return this.definitions.QTY;
  }

  public get AddLocation(): string {
    return this.definitions.AddLocation;
  }

  public get EnterDescription(): string {
    return this.definitions.EnterDescription;
  }

  public get RespondToBuyer(): string {
    return this.definitions.RespondToBuyer;
  }

  public get AllStatuses(): string {
    return this.definitions.AllStatuses;
  }

  public get Listed(): string {
    return this.definitions.Listed;
  }

  public get Unlisted(): string {
    return this.definitions.Unlisted;
  }

  public get Sold(): string {
    return this.definitions.Sold;
  }

  public ListingConditionOptions(): SelectOption[] {
    return [
      { value: 1, label: this.definitions.Used },
      { value: 2, label: this.definitions.Refurbished },
      { value: 3, label: this.definitions.New },
      { value: 4, label: this.definitions.PartsTool }
    ];
  }

  public get Listings(): string {
    return this.definitions.Listings;
  }
}
