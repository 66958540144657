import { getDefinitions, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class UserBuyLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get CurrentlyAvailableListings(): string {
    return this.definitions.CurrentlyAvailableListings;
  }

  public get ExploreByCategory(): string {
    return this.definitions.ExploreByCategory;
  }

  public get NextToolVerifiedListings(): string {
    return this.definitions.NextToolVerifiedListings;
  }
}
