import type { FC } from 'react';

interface TextProps {
  readonly className?: string;
  readonly id?: string;
}

// -- #general
// -----------------------------------------------

/**
 *
 */
export const H1: FC<TextProps> = ({ className = '', children, id }) => (
  <h1 id={id} className={`font-inter text-36spx font-bold leading-tight lg:text-72spx ${className}`}>
    {children}
  </h1>
);

/**
 *
 */
export const H2: FC<TextProps> = ({ className = '', children, id }) => (
  <h2 id={id} className={`font-montserrat text-24spx leading-tight lg:text-60spx ${className}`}>
    {children}
  </h2>
);

/**
 *
 */
export const H3: FC<TextProps> = ({ className = '', children, id }) => (
  <h3 id={id} className={`font-montserrat text-24spx leading-tight lg:text-48spx ${className}`}>
    {children}
  </h3>
);

/**
 *
 */
export const H4: FC<TextProps> = ({ className = '', children, id }) => (
  <h4 id={id} className={`font-montserrat text-24spx leading-tight lg:text-34spx ${className}`}>
    {children}
  </h4>
);

/**
 *
 */
export const H5: FC<TextProps> = ({ className = '', children, id }) => (
  <h5 id={id} className={`font-montserrat text-20spx leading-tight lg:text-24spx ${className}`}>
    {children}
  </h5>
);

/**
 *
 */
export const H6: FC<TextProps> = ({ className = '', children, id }) => (
  <h6 id={id} className={`font-montserrat text-16spx font-medium leading-tight lg:text-20spx ${className}`}>
    {children}
  </h6>
);

/**
 *
 */
export const Subheading1: FC<TextProps> = ({ className = '', children, id }) => (
  <h6 id={id} className={`font-montserrat text-16spx leading-tight ${className}`}>
    {children}
  </h6>
);

/**
 *
 */
export const Subheading2: FC<TextProps> = ({ className = '', children, id }) => (
  <h6 id={id} className={`font-montserrat text-14spx font-medium leading-tight ${className}`}>
    {children}
  </h6>
);

/**
 *
 */
export const Subheading3: FC<TextProps> = ({ className = '', children, id }) => (
  <h6 id={id} className={`font-montserrat text-14spx font-bold leading-tight ${className}`}>
    {children}
  </h6>
);

/**
 *
 */
export const Subtitle1: FC<TextProps> = ({ className = '', children, id }) => (
  <p id={id} className={`font-montserrat text-16spx leading-tight ${className}`}>
    {children}
  </p>
);

/**
 *
 */
export const Subtitle2: FC<TextProps> = ({ className = '', children, id, ...props }) => (
  <p id={id} className={`font-montserrat text-14spx font-medium leading-tight tracking-normal ${className}`} {...props}>
    {children}
  </p>
);

/**
 *
 */
export const P1: FC<TextProps> = ({ className = '', children, id, ...props }) => (
  <p id={id} className={`font-lato text-16spx leading-snug ${className}`} {...props}>
    {children}
  </p>
);

/**
 *
 */
export const P2: FC<TextProps> = ({ className = '', children, id }) => (
  <p id={id} className={`font-lato text-14spx leading-snug ${className}`}>
    {children}
  </p>
);

/**
 *
 */
export const Overline: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`font-lato text-12spx uppercase leading-tight tracking-widest ${className}`}>
    {children}
  </div>
);

/**
 *
 */
export const Caption: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`font-lato text-12spx leading-tight ${className}`}>
    {children}
  </div>
);

// -- #marketing
// -----------------------------------------------

/**
 *
 */
export const MarketingH1: FC<TextProps> = ({ className = '', children, id }) => (
  <h2 id={id} className={`font-inter text-36spx font-bold leading-tight lg:text-72spx ${className}`}>
    {children}
  </h2>
);

/**
 *
 */
export const MarketingH2: FC<TextProps> = ({ className = '', children, id }) => (
  <h2 id={id} className={`font-inter text-36spx font-bold leading-tight lg:text-64spx ${className}`}>
    {children}
  </h2>
);

/**
 *
 */
export const MarketingH3: FC<TextProps> = ({ className = '', children, id }) => (
  <h3 id={id} className={`font-inter text-24spx font-bold leading-tight lg:text-48spx ${className}`}>
    {children}
  </h3>
);

/**
 *
 */
export const MarketingH4: FC<TextProps> = ({ className = '', children, id }) => (
  <h4 id={id} className={`font-inter text-24spx font-bold leading-tight lg:text-36spx ${className}`}>
    {children}
  </h4>
);

/**
 *
 */
export const MarketingH5: FC<TextProps> = ({ className = '', children, id }) => (
  <h5 id={id} className={`font-inter text-20spx font-bold leading-tight lg:text-24spx ${className}`}>
    {children}
  </h5>
);

/**
 *
 */
export const MarketingSubheading: FC<TextProps> = ({ className = '', children, id }) => (
  <h6 id={id} className={`font-lato text-16spx leading-tight lg:text-24spx ${className}`}>
    {children}
  </h6>
);

/**
 *
 */
export const MarketingP1: FC<TextProps> = ({ className = '', children, id }) => (
  <p id={id} className={`font-lato text-16spx leading-snug tracking-wide lg:text-18spx ${className}`}>
    {children}
  </p>
);

/**
 *
 */
export const MarketingOverline: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`font-inter text-18spx uppercase leading-tight tracking-widest ${className}`}>
    {children}
  </div>
);

/**
 *
 */
export const MarketingOverlineMagenta: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`font-inter text-18spx uppercase leading-tight tracking-widest text-magenta-500-old ${className}`}>
    {children}
  </div>
);

/**
 *
 */
export const MarketingOverlineBlue: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`text-sky-blue-1000-old font-inter text-18spx uppercase leading-tight tracking-widest ${className}`}>
    {children}
  </div>
);

/**
 *
 */
export const MarketingOverlinePeach: FC<TextProps> = ({ className = '', children, id }) => (
  <div id={id} className={`font-inter text-18spx uppercase leading-tight tracking-widest text-peach ${className}`}>
    {children}
  </div>
);

// -- #misc
// -----------------------------------------------

/**
 *
 */
export const PlaceholderText: FC<TextProps> = ({ className = '', children, id }) => (
  <span id={id} className={`italic text-gray-400 ${className}`}>
    {children}
  </span>
);
