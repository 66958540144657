import { useAppSelector } from '@/src/store';
import { getFilteredSellerOffers } from '../../selectors';
import { Card, CardActions, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import { borderRadius, spacing } from '@/src/design/designConstants';
import Image from 'next/image';
import { parseUSDCurrencyStr } from '@/src/utils';
import { SellerOfferGroupCTAs } from './sellerOfferGroupCTAs';
import { PendingOfferSummary } from '../pendingOfferSummary';
import { AcceptedOfferSummary } from '../acceptedOfferSummary';
import { DeclinedExpiredOfferSummary } from '../DeclinedExpiredOfferSummary';
import { ExpandedSellerOfferView } from './expandedView';

export const OffersSellersTabDesktop = () => {
  const filteredSellerOffers = useAppSelector(getFilteredSellerOffers);

  return (
    <Stack py={spacing['3xl']} spacing={spacing['3xl']} height="100%" alignItems="center" data-testid="offers-seller-desktop-content">
      {filteredSellerOffers.map((sellerOfferGroup) => {
        return (
          <Card
            variant="outlined"
            sx={{ px: spacing['3xl'], py: spacing['xl'], width: '90%' }}
            key={`buyer-offer-${sellerOfferGroup.listing.key}`}
            data-testid={`seller-offer-card-${sellerOfferGroup.listing.key}`}
          >
            <Stack direction="row" alignItems={'center'} spacing={spacing['3xl']}>
              <Stack>
                <Image
                  alt={`listing-image-${sellerOfferGroup.listing.make_model}`}
                  src={sellerOfferGroup.listingLargePhotoUrl}
                  quality={100}
                  objectFit="cover"
                  height={200}
                  width={200}
                  style={{ borderRadius: borderRadius.rounded }}
                />
              </Stack>
              <Stack direction="row" width="100%" flexWrap="wrap" justifyContent={'space-between'} alignItems="center">
                <Stack spacing={spacing['3xl']}>
                  <CardHeader
                    sx={{ p: 0, pb: 0, margin: 0 }}
                    title={
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant="h6">{sellerOfferGroup.listing.make_model}</Typography>
                      </Stack>
                    }
                  />
                  <CardContent sx={{ p: 0, pb: 0, ':last-child': { padding: 0, marginTop: spacing.md } }}>
                    <Stack spacing={spacing['2xl']}>
                      <Stack height="34px" direction="row" alignItems="center" spacing={spacing.xl}>
                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Asking Price:</strong>
                          </Typography>
                          <Typography variant="p12">
                            {Boolean(Number(sellerOfferGroup.listing.price))
                              ? `${parseUSDCurrencyStr(sellerOfferGroup.listing.price)} USD`
                              : '-'}
                          </Typography>
                        </Stack>

                        <Divider orientation="vertical" />

                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Vintage:</strong>
                          </Typography>
                          <Typography variant="p12">
                            {Boolean(sellerOfferGroup.listing.vintage) ? sellerOfferGroup.listing.vintage : '-'}
                          </Typography>
                        </Stack>

                        <Divider orientation="vertical" />

                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Wafer Size:</strong>
                          </Typography>
                          <Typography variant="p12">{sellerOfferGroup.listing.readable_wafer_size}</Typography>
                        </Stack>

                        <Divider orientation="vertical" />

                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Product ID:</strong>
                          </Typography>
                          <Typography variant="p12">{sellerOfferGroup.listing.id}</Typography>
                        </Stack>

                        <Divider orientation="vertical" />

                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Operational Status:</strong>
                          </Typography>
                          <Typography variant="p12">{sellerOfferGroup.listing.readable_operational_status}</Typography>
                        </Stack>

                        <Divider orientation="vertical" />

                        <Stack spacing={spacing.xs}>
                          <Typography variant="p12">
                            <strong>Condition:</strong>
                          </Typography>
                          <Typography variant="p12">{sellerOfferGroup.listing.readable_condition}</Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={spacing.xl} minHeight="54px">
                        {Boolean(sellerOfferGroup.pending_offers) ? (
                          <PendingOfferSummary sellerOfferGroup={sellerOfferGroup} />
                        ) : sellerOfferGroup.accepted_offer ? (
                          <AcceptedOfferSummary accepted_offer={sellerOfferGroup.accepted_offer} />
                        ) : (
                          <DeclinedExpiredOfferSummary sellerOfferGroup={sellerOfferGroup} />
                        )}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Stack>
                <CardActions>
                  <SellerOfferGroupCTAs sellerOfferGroup={sellerOfferGroup} />
                </CardActions>
              </Stack>
            </Stack>
            <ExpandedSellerOfferView sellerOfferGroup={sellerOfferGroup} />
          </Card>
        );
      })}
    </Stack>
  );
};
