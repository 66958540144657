import { ChangeEventHandler, FC } from 'react';

import { SubProps } from '..';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';

export const SerialNumber: FC<SubProps> = ({ loc, state, dispatch }) => {
  const setSerialNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({
      type: 'setSerialNumber',
      value: e.target.value
    });
  };

  return (
    <LabeledTextField
      placeholder={loc.Listing.SerialNumber}
      label={loc.Listing.SerialNumber}
      value={state.serialNumber || ''}
      onChange={setSerialNumber}
      type={'text'}
    />
  );
};
