import { useEffect } from 'react';
import { useInterval } from '@/src/hooks/useInterval';
import { getUnreadMessageCount } from '@/src/messages/thunks';
import { useAppDispatch } from '@/src/store';
import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useSelector } from 'react-redux';

export const useFetchUnreadMessageCount = () => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { setIntvl, clearIntvl } = useInterval();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      //Fetch initial unread count
      dispatch(getUnreadMessageCount());

      //Fetch unread count every 2 minutes
      const interval = 2 * 60000;
      setIntvl(() => {
        dispatch(getUnreadMessageCount());
      }, interval);
    } else {
      //If logged out clear the interval so we don't make
      //a request unauthenticated
      clearIntvl();
    }
  }, [isLoggedIn]);
};
