import { getDefinitions, Locale } from '../localization';
import translations from './index.loc.json';

/**
 *
 */
export class VerificationLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get EmailAddressVerified(): string {
    return this.definitions.EmailAddressVerified;
  }

  public get EmailSentVerifyLinkPostEmailAddress(): string {
    return this.definitions.EmailSentVerifyLinkPostEmailAddress;
  }

  public get EmailSentVerifyLinkPreEmailAddress(): string {
    return this.definitions.EmailSentVerifyLinkPreEmailAddress;
  }

  public get EmailVerified(): string {
    return this.definitions.EmailVerified;
  }

  public get PleaseVerifyUnlock(): string {
    return this.definitions.PleaseVerifyUnlock;
  }

  public get ResendEmail(): string {
    return this.definitions.ResendEmail;
  }

  public get TroubleEmailCustomerSuccessPostEmailAddress(): string {
    return this.definitions.TroubleEmailCustomerSuccessPostEmailAddress;
  }

  public get TroubleEmailCustomerSuccessPreEmailAddress(): string {
    return this.definitions.TroubleEmailCustomerSuccessPreEmailAddress;
  }

  public get VerificationEmailSent(): string {
    return this.definitions.VerificationEmailSent;
  }

  public get VerifyYourAccount(): string {
    return this.definitions.VerifyYourAccount;
  }
}
