import { FC } from 'react';
import TAILWIND_CSS_CONFIG from '../../tailwind.config';

interface HamburgerMenuToggleProps {
  readonly onClick?: () => void;
  readonly isOpen: boolean;
}

/**
 * HamburgerMenuToggle will fill the width and height of the parent
 */
export const HamburgerMenuToggle: FC<HamburgerMenuToggleProps> = (props) => {
  const { isOpen, onClick } = props;

  return (
    <button className={`hamburger-menu-toggle bg-transparent ${isOpen ? 'open' : ''}`} onClick={onClick} data-testid="HamburgerMenuToggle">
      <div className="hamburger-menu-svg-wrapper">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path className="hamburger-line-1" d="M0 70l28-28c2-2 2-2 7-2h64" />
          <path className="hamburger-line-2" d="M0 50h99" />
          <path className="hamburger-line-3" d="M0 30l28 28c2 2 2 2 7 2h64" />
        </svg>
      </div>
    </button>
  );
};

export const HamburgerMenuToggleV2: FC<HamburgerMenuToggleProps> = (props) => {
  const { isOpen, onClick } = props;

  return (
    <button className="rounded bg-transparent px-2 py-2.5 hover:bg-smokey-gray-100" onClick={onClick} data-testid="HamburgerMenuToggle">
      <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        {!isOpen ? (
          <path
            d="M0 1.28544C0 0.654364 0.639732 0.142578 1.42857 0.142578H18.5714C19.3616 0.142578 20 0.654364 20 1.28544C20 1.91758 19.3616 2.42829 18.5714 2.42829H1.42857C0.639732 2.42829 0 1.91758 0 1.28544ZM0 6.99972C0 6.36758 0.639732 5.85686 1.42857 5.85686H18.5714C19.3616 5.85686 20 6.36758 20 6.99972C20 7.63186 19.3616 8.14258 18.5714 8.14258H1.42857C0.639732 8.14258 0 7.63186 0 6.99972ZM18.5714 13.8569H1.42857C0.639732 13.8569 0 13.3462 0 12.714C0 12.0819 0.639732 11.5711 1.42857 11.5711H18.5714C19.3616 11.5711 20 12.0819 20 12.714C20 13.3462 19.3616 13.8569 18.5714 13.8569Z"
            fill={TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['500']}
          />
        ) : (
          <>
            <path
              d="M1.5634 2.43444H18.6983C19.4868 2.43444 20.1309 1.92444 20.1309 1.29444C20.1309 0.664441 19.4979 0.154441 18.6983 0.154441H1.5634C0.774946 0.144441 0.130859 0.654441 0.130859 1.28444C0.130859 1.91444 0.774946 2.42444 1.5634 2.42444V2.43444Z"
              fill={TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['500']}
            />
            <path
              d="M18.8413 5.85453H7.56528C6.85528 5.85453 6.27528 6.36453 6.27528 6.99453C6.27528 7.62453 6.85528 8.13453 7.56528 8.13453H18.8413C19.5513 8.13453 20.1313 7.62453 20.1313 6.99453C20.1313 6.36453 19.5613 5.85453 18.8413 5.85453Z"
              fill={TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['500']}
            />
            <path
              d="M18.6983 11.5742H1.5634C0.774946 11.5742 0.130859 12.0842 0.130859 12.7142C0.130859 13.3442 0.774946 13.8542 1.5634 13.8542H18.6983C19.4868 13.8542 20.1309 13.3442 20.1309 12.7142C20.1309 12.0842 19.4979 11.5742 18.6983 11.5742Z"
              fill={TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['500']}
            />
            <path
              d="M4.79109 10.1045C5.11109 9.78453 5.11109 9.26453 4.79109 8.94453L2.84109 6.99453L4.79109 5.04453C5.11109 4.72453 5.11109 4.20453 4.79109 3.88453C4.47109 3.56453 3.95109 3.56453 3.63109 3.88453L1.10109 6.41453C1.10109 6.41453 1.03109 6.50453 1.01109 6.55453C0.801094 6.87453 0.821094 7.30453 1.10109 7.58453L3.63109 10.1145C3.95109 10.4345 4.47109 10.4345 4.79109 10.1145V10.1045Z"
              fill={TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['500']}
            />
          </>
        )}
      </svg>
    </button>
  );
};
