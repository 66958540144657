import { useEffect, useState } from 'react';

export const useResizeObserver = () => {
  const [currentObserver, setCurrentObserver] = useState<ResizeObserver | null>(null);

  useEffect(() => {
    return () => {
      clearObserver();
    };
  }, [currentObserver]);

  const clearObserver = () => {
    if (currentObserver) {
      currentObserver.disconnect();
    }
  };

  const setObserver = (ref: React.RefObject<HTMLElement>, callback: ResizeObserverCallback) => {
    clearObserver();

    const newObserver = new ResizeObserver(callback);
    if (ref.current) {
      newObserver.observe(ref.current);
    }
    setCurrentObserver(newObserver);
  };

  return { setObserver, clearObserver };
};
