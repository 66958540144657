import React, { FC } from 'react';
import { useLocalizer } from '../../localization';
import { calcFieldTextColor } from './utils';

// =========================================
// DEPRECATED
// =========================================

interface RequiredProps {
  readonly required?: boolean;
  readonly hasErrored?: boolean;
  readonly disabled?: boolean;
  readonly submitted?: boolean;
  readonly touched?: boolean;
  readonly validateOnTouchOrSubmit?: boolean;
}

/**
 * @deprecated
 */
export const Required: FC<RequiredProps> = (props) => {
  const { required, hasErrored, disabled, submitted, touched, validateOnTouchOrSubmit } = props;
  const loc = useLocalizer();
  const textColor = calcFieldTextColor('text-corp-gray-500', hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit);

  return required ? <span className={`font-bold italic ${textColor}`}> - {loc.Forms.Required}</span> : null;
};

/**
 * @deprecated
 */
const DeprecatedRequired = () => {
  const loc = useLocalizer();

  return <span className="font-bold italic text-corp-gray-500"> - {loc.Forms.Required}</span>;
};

export default DeprecatedRequired;
