import Select, { SingleValue } from 'react-select';
import { useField } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

import { SelectOption } from '../fields/selectField';
import { getFieldDescribedById, isEmpty } from '../../../utils';
import { FormikFieldWrapper } from './formikWrappers';
import type { FormFieldColorVariant } from '../../../entities/form';

export type FormikSelectBaseProps = {
  readonly disabled?: boolean;
  readonly label?: string;
  readonly placeholder?: string;
  readonly name: string;
  readonly helperText?: string;
  readonly required?: boolean;
  readonly isMulti?: boolean;
  readonly reserveSpaceBelow?: boolean;
  readonly colorVariant?: FormFieldColorVariant;
  readonly components?: Record<string, () => ReactNode>;
  readonly options: SelectOption[];
  readonly handleInputChange?: (value: string) => void;
  readonly isSearchable?: boolean;
  readonly externalHandleChange?: (value: SingleValue<SelectOption> | null) => void;
};

interface FormikSelectFieldProps extends FormikSelectBaseProps {
  readonly defaultSelectedOption?: SelectOption;
  readonly filterOption?: (option: SelectOption) => boolean;
}

const calcPlaceholder = (placeholder: string | undefined, label: string | undefined) => {
  if (!isEmpty(placeholder)) {
    return placeholder;
  } else if (!isEmpty(label) && typeof label !== 'undefined') {
    return `Select ${label}`;
  } else {
    return 'Select';
  }
};

const calcIsSearchable = (isSearchable: boolean | undefined, options: SelectOption[]) => {
  return isSearchable ? isSearchable : options.length > 8;
};

const calcSelectClassname = (touched: boolean, error: string | undefined, colorVariant: FormFieldColorVariant) => {
  return `w-full ${touched && !isEmpty(error) ? 'input-invalid' : ''} ${colorVariant === 'white' ? 'form-input-white' : ''}`;
};

export const FormikSelectField = (props: FormikSelectFieldProps) => {
  const {
    disabled,
    label,
    name,
    required,
    placeholder,
    handleInputChange,
    options,
    components,
    helperText,
    defaultSelectedOption,
    reserveSpaceBelow,
    colorVariant = 'default',
    isSearchable,
    externalHandleChange,
    filterOption
  } = props;
  const [selectedOption, setSelectedOption] = useState<SelectOption | null | undefined>(defaultSelectedOption);
  const [field, meta, helpers] = useField({ name });
  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    setSelectedOption(defaultSelectedOption);
  }, [defaultSelectedOption]);

  useEffect(() => {
    if (selectedOption === null) {
      if (typeof value === 'string') {
        setValue('');
      } else if (typeof value === 'number') {
        setValue(0);
      }
    } else if (typeof selectedOption !== 'undefined') {
      setValue(selectedOption.value);
    }
  }, [selectedOption]); // eslint-disable-line

  const handleChange = (option: SingleValue<SelectOption> | null) => {
    externalHandleChange && externalHandleChange(option);
    setSelectedOption(option);
  };

  return (
    <FormikFieldWrapper
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      disabled={disabled}
      touched={touched}
      error={error}
      reserveSpaceBelow={reserveSpaceBelow}
    >
      <Select
        id={name}
        className={calcSelectClassname(touched, error, colorVariant)}
        name={name}
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={field.onBlur}
        isDisabled={disabled}
        options={options}
        isClearable={true}
        isSearchable={calcIsSearchable(isSearchable, options)}
        placeholder={calcPlaceholder(placeholder, label)}
        classNamePrefix="react-select"
        components={components}
        aria-describedby={getFieldDescribedById(name)}
        aria-invalid={touched && !isEmpty(error)}
        filterOption={filterOption}
      />
    </FormikFieldWrapper>
  );
};
