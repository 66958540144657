import { useCaptureExceptionForExternalUsers } from '../useCaptureExceptionForExternalUsers';
import { opportunitySeen } from '../../providers/opportunities';
import { useUser } from '../useUser';

export const useMarkOpportunitySeen = () => {
  const { captureExceptionForExternalUsers } = useCaptureExceptionForExternalUsers();
  const { user } = useUser();
  return {
    markOpportunitySeenWithSentry: (opportunityKey: string, withCredentials = false) => {
      return opportunitySeen(opportunityKey, withCredentials).catch((error: unknown) => {
        captureExceptionForExternalUsers(error, {
          contexts: { opportunitySeen: { opportunityKey, userId: user?.id } }
        });
      });
    }
  };
};
