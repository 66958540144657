import type { FC } from 'react';
import { HelperTextForInput } from '../helperText/helperTextForInput';
import { FieldLabel } from './fieldLabel';
import { SharedInputFieldProps } from './inputField';
import { StyledTextInput } from './styledTextInput';

export interface TextareaExlusiveProps {
  readonly rows?: string | number;
  readonly maxRows?: string | number;
  readonly minRows?: string | number;
}

export interface TextareaProps extends TextareaExlusiveProps, SharedInputFieldProps {}

export const Textarea: FC<TextareaProps> = (props) => {
  const {
    value,
    defaultValue,
    label,
    placeholder,
    helperText,
    required,
    disabled,
    hasErrored,
    errorMessage,
    autoFocus,
    onFocus,
    onChange,
    rows,
    maxRows,
    minRows
  } = props;

  return (
    <FieldLabel label={label} required={required} hasErrored={hasErrored} disabled={disabled}>
      <StyledTextInput
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        helperText={<HelperTextForInput helperText={helperText} hasErrored={hasErrored} errorMessage={errorMessage} disabled={disabled} />}
        disabled={disabled}
        error={hasErrored}
        fullWidth
        autoFocus={autoFocus}
        onChange={onChange}
        multiline
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        spellCheck={false}
        onFocus={onFocus}
      />
    </FieldLabel>
  );
};
