import type { FC } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { calcFieldTextColor, shouldShowFieldError } from '../utils';

export interface SharedHelperTextProps {
  readonly helperText?: string;
  readonly disabled?: boolean;
  readonly errorMessage?: string;
  readonly hasErrored?: boolean;
  readonly submitted?: boolean;
  readonly touched?: boolean;
  readonly validateOnTouchOrSubmit?: boolean;
}

interface HelperTextProps extends SharedHelperTextProps {
  readonly className?: string;
}

export const HelperText: FC<HelperTextProps> = (props) => {
  const { helperText, errorMessage, hasErrored, disabled, className = '', touched, submitted, validateOnTouchOrSubmit } = props;
  const textColor = calcFieldTextColor('text-moov-blue-500', hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit);

  if (!helperText && !hasErrored) {
    return null;
  }

  const showErrorUI = shouldShowFieldError(hasErrored, disabled, touched, submitted, validateOnTouchOrSubmit);

  return (
    <span className={`flex gap-x-1 text-12spx ${textColor} ${className}`} data-testid="HelperText">
      {showErrorUI && <ErrorIcon className="h-5 w-5" />}
      <span className="mt-[0.1em]">{showErrorUI ? errorMessage : helperText}</span>
    </span>
  );
};
