import { getDefinitions, interpolateVariable, Locale } from '../../localization';
import translations from './magic.loc.json';

/**
 *
 */
export class MagicLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get YourEquipmentIsProtected(): string {
    return this.definitions.YourEquipmentIsProtected;
  }

  public get TakeAdvantageOfLogistics(): string {
    return this.definitions.TakeAdvantageOfLogistics;
  }

  public get UpgradeEquipmentToFitNeeds(): string {
    return this.definitions.UpgradeEquipmentToFitNeeds;
  }

  public get RiskFreePurchasing(): string {
    return this.definitions.RiskFreePurchasing;
  }

  public get ThanksForSubmission(): string {
    return this.definitions.ThanksForSubmission;
  }

  public get WellGetBackToYou(): string {
    return this.definitions.WellGetBackToYou;
  }

  public get ThankYou(): string {
    return this.definitions.ThankYou;
  }
  public get Skip(): string {
    return this.definitions.Skip;
  }

  public HiUsernameGladYoureInterested(username: string): string {
    return interpolateVariable(this.definitions.HiUsernameGladYoureInterested, 'user_name', username);
  }

  public get GladYoureInterested(): string {
    return this.definitions.GladYoureInterested;
  }

  public get CanIHelpAnswerQuestions(): string {
    return this.definitions.CanIHelpAnswerQuestions;
  }

  public get EnterYourQuestion(): string {
    return this.definitions.EnterYourQuestion;
  }
  public get ImNotInterested(): string {
    return this.definitions.ImNotInterested;
  }

  public get IfPossibleTellUsWhy(): string {
    return this.definitions.IfPossibleTellUsWhy;
  }

  public HiUsernameSorryNotInterested(username: string): string {
    return interpolateVariable(this.definitions.HiUsernameSorryNotInterested, 'user_name', username);
  }

  public get SorryNotInterested(): string {
    return this.definitions.SorryNotInterested;
  }

  public get ChooseAResponse(): string {
    return this.definitions.ChooseAResponse;
  }

  public get QuestionCannotBeBlank(): string {
    return this.definitions.QuestionCannotBeBlank;
  }

  public get RejectedOpportunityReasonOptions() {
    return [
      {
        label: this.definitions.NeedDifferentVintage,
        value: 'VINTAGE'
      },
      {
        label: this.definitions.NeedBetterCondition,
        value: 'CONDITION'
      },
      {
        label: this.definitions.DoesNotHaveSpecsINeed,
        value: 'SPECS'
      },
      {
        label: this.definitions.NoLongerNeeded,
        value: 'NEED'
      },
      {
        label: this.definitions.MissingParts,
        value: 'PARTS'
      }
    ];
  }
  public get AddQuestion(): string {
    return this.definitions.AddQuestion;
  }

  public get AdditionalDetails(): string {
    return this.definitions.AdditionalDetails;
  }

  public get IsThereMoreInformation(): string {
    return this.definitions.IsThereMoreInformation;
  }

  public get LearnMore(): string {
    return this.definitions.LearnMore;
  }

  public get NotInterested(): string {
    return this.definitions.NotInterested;
  }

  public get AttachedFilesColon(): string {
    return this.definitions.AttachedFilesColon;
  }

  public get NoAttachedFiles(): string {
    return this.definitions.NoAttachedFiles;
  }
}
