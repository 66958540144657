import { CreateUnconfirmedListing } from '@/src/entities/listing';

// a custom event type and a typed payload for the event
const listingLearnMoreClick = 'listing-learn-more-click';
export type ListingLearnMoreClick = { listingId: number };

const listingCTAClick = 'listing-cta-click';
const listingCTAClickFromMessagingSummary = 'listing-cta-click-from-messaging-summary';
const listingCTATypesFromMessagingSummary = ['MakeOffer'] as const;
const listingFormSubmit = 'listing-form-submit';
const listingCTATypes = [
  'ContactSales',
  'MakeOffer',
  'FindOne',
  'ContactSales Generic',
  'ContactSales Logistics Calc',
  'Message Seller'
] as const;
const listingFormSubmitTypes = ['ContactSales', 'MakeOffer'] as const;

export enum CreateListingLinkLocations {
  SellListingsHeader = 'sell-listings-header',
  SellHeader = 'sell-header',
  SellNoResultsSection = 'sell-no-results-section'
}
export const typedListingEvents = {
  learnMoreClick: listingLearnMoreClick,
  CTAClick: listingCTAClick,
  CTAClickFromMessagingSummary: listingCTAClickFromMessagingSummary,
  listingCreateSubmit: 'listing-create-submit',
  formSubmit: listingFormSubmit,
  listingLinkToCreatePage: 'listing-link-to-create-page',
  listingCardClick: 'listing-card-click'
} as const;

// these dont need any other information besides the event name for fullstory
export const untypedListingEvents = {
  logisticsSuccess: 'listing-logistics-success',
  logisticsFailure: 'listing-logistics-failure'
} as const;

export type ListingCTAClickFromMessagingSummaryType = [
  typeof listingCTAClickFromMessagingSummary,
  { type: (typeof listingCTATypesFromMessagingSummary)[number] }
];
export type ListingCTAClickType = [typeof listingCTAClick, { type: (typeof listingCTATypes)[number] }];
export type ListingCreateUnconfirmedSubmit = [typeof typedListingEvents.listingCreateSubmit, CreateUnconfirmedListing];
export type ListingFormSubmit = [typeof listingFormSubmit, { type: (typeof listingFormSubmitTypes)[number] }];
export type ListingLinkToCreatePage = [typeof typedListingEvents.listingLinkToCreatePage, { location: CreateListingLinkLocations }];
export type ListingCardClick = [typeof typedListingEvents.listingCardClick, { displayed_price: boolean }];

const untypedListingEventValues = Object.values(untypedListingEvents);
export type ListingNoPayloadEvents = [(typeof untypedListingEventValues)[number]];

export type ListingTypes =
  | ListingCTAClickType
  | ListingNoPayloadEvents
  | ListingCreateUnconfirmedSubmit
  | ListingFormSubmit
  | ListingLinkToCreatePage
  | ListingCardClick
  | ListingCTAClickFromMessagingSummaryType;
