import { FC, HTMLAttributes, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';

export type MoovButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';
interface MoovButtonProps extends HTMLAttributes<HTMLButtonElement> {
  readonly variant: MoovButtonVariant;
  readonly children: ReactNode;
  readonly className?: string;
  readonly type?: 'button' | 'submit' | 'reset';
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * @deprecated use MUI's Button instead
 */
export const MoovButton: FC<MoovButtonProps> = (props) => {
  const { variant, children, className = '', type = 'button', disabled, isLoading, onClick, ...otherProps } = props;

  let variantClassName = '';
  let disabledClassName = 'disabled:text-corp-gray-400 bg-transparent';
  switch (variant) {
    case 'primary':
      variantClassName = 'bg-magenta-500 not-disabled:hover:bg-magenta-600 not-disabled:focus:bg-magenta-700 text-white';
      disabledClassName += ' disabled:bg-corp-gray-200';
      break;
    case 'secondary':
      variantClassName = 'bg-moov-blue-500 not-disabled:hover:bg-moov-blue-600 not-disabled:focus:bg-moov-blue-800 text-white';
      disabledClassName += ' disabled:bg-corp-gray-200';
      break;
    case 'tertiary':
      variantClassName =
        'bg-white border-corp-gray-200 border-2 not-disabled:hover:border-corp-gray-400 not-disabled:focus:border-moov-blue-800 text-moov-blue-500 not-disabled:focus:text-moov-blue-800';
      disabledClassName += ' disabled:border-corp-gray-200';
      break;
    case 'quaternary':
      variantClassName = 'bg-crayola-green-500 not-disabled:hover:bg-crayola-green-600 not-disabled:focus:bg-crayola-green-700 text-white';
      disabledClassName += ' disabled:bg-corp-gray-200';
      break;
  }

  const classNames = `${variantClassName} ${disabled ? disabledClassName : ''} ${className}`;

  return (
    <button
      {...otherProps}
      // The base styling for padding and border-radius are passed as
      // a component class (moov-button-base) so that they can be reliably
      // overridden by our utility classes (ex. px-5 py-10)
      className={`moov-button-base relative flex items-center justify-center font-lato disabled:cursor-not-allowed ${classNames}`}
      disabled={disabled || isLoading}
      type={type}
      onClick={onClick}
    >
      {isLoading && (
        <div className="absolute-center flex items-center justify-center">
          <CircularProgress color="inherit" size="1.3em" data-testid="MUICircularProgress" />
        </div>
      )}
      <div className={`flex items-center justify-center ${isLoading ? 'text-transparent' : ''}`}>{children}</div>
    </button>
  );
};
