import { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { useLocalizer } from '../../localization';
import { TransparentDarkHoverButton } from '../buttons';
import Modal from './modal';

interface Props {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly className?: string;
  readonly placementMobile?: 'center' | 'bottom';
}

/**
 *
 */
const ModalPopup = ({ children, isOpen, onClose, className = '', placementMobile = 'center' }: Props) => {
  const loc = useLocalizer();

  let wrapperPlacement;
  let innerWrapperClassName;

  switch (placementMobile) {
    case 'bottom':
      wrapperPlacement = 'mt-auto sm:m-auto';
      innerWrapperClassName = 'rounded-t-lg sm:rounded-lg';
      break;
    case 'center':
      wrapperPlacement = 'm-auto';
      innerWrapperClassName = 'rounded-lg m-4 mb-16 lg:mb-4';
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={`w-full ${wrapperPlacement} ${className}`}>
        <div className={`bg-white ${innerWrapperClassName}`}>
          <div className="p-4">
            <TransparentDarkHoverButton onClick={onClose} className="ml-auto" aria-label={loc.Modals.CloseModal}>
              <div className="h-6 w-6">
                <CloseIcon />
              </div>
            </TransparentDarkHoverButton>
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalPopup;
