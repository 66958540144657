import { useAnyOpportunities } from '../../../../hooks/opportunities/useAnyOpportunities';
import ImageCarousel from '../../../../bits/carousels/imageCarousel';
import { getMakeModelName } from '../../../../utils';
import { SuggestedListingInfo } from '../../../../suggestedListingInfo';
import ListingDetails from '../../../../listingDetails';
import { isSuggestedListing } from '../../../../entities/listing';
import { useContext } from 'react';
import { CloseIcon } from '../../../../bits/svgs/svgs';
import { MobileSuggestedItemsModalDispatch } from '../../../../state/mobileSuggestedItemsModal/context';
import { useRedirectIfOpportunityIsNotActionable } from './hooks/useRedirectIfOpportunityIsNotActionable';
import TAILWIND_CSS_CONFIG from '../../../../../tailwind.config';
import { useLocalizer } from '../../../../localization';

const MobileSuggestedItems = () => {
  const { currentListing, loading, loaded, markCurrentOpportunitySeen } = useAnyOpportunities();
  const loc = useLocalizer();

  const { closeModal } = useContext(MobileSuggestedItemsModalDispatch);
  useRedirectIfOpportunityIsNotActionable();
  if (!loading && loaded && isSuggestedListing(currentListing)) {
    return (
      <div className="text-moov-blue-500">
        <div className="relative flex px-2.5 py-2">
          <div className="flex w-full items-center justify-center text-center font-bold">{loc.Account.SuggestedTools}</div>
          <button
            className="bg-transparent p-3"
            data-testid="close_mobile_suggested_tool_tinder"
            onClick={() => {
              markCurrentOpportunitySeen();
              closeModal();
            }}
          >
            <CloseIcon fill={TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500']} className="h-3 w-3" />
          </button>
        </div>

        <ImageCarousel photos={currentListing.photos} alt={getMakeModelName(currentListing)} visibleLength={3} />

        <div className="p-4">
          <SuggestedListingInfo />
        </div>

        <ListingDetails listing={currentListing} className="bg-smokey-gray-50 p-6 text-14spx" />
      </div>
    );
  }

  return null;
};

export default MobileSuggestedItems;
