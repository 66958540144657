import { updateLanguagePreferenceCookie, updateUserLanguagePreference } from '@/src/globalComponents/useLanguagePreference/utils';
import { SUPPORTED_LOCALES } from '@/src/layout/header/desktop/languageDropdown/constants';
import { useLocalizer } from '@/src/localization';
import { getCurrentUser, userIsLoggedIn } from '@/src/state/user/selectors';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemText, Collapse, List, Box, ListItem } from '@mui/material';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { spacing } from '@/src/design/designConstants';

export interface LanguageProps {
  onNavigate: () => void;
}

export const Language: FC<LanguageProps> = ({ onNavigate }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const loc = useLocalizer();
  const router = useRouter();
  const user = useSelector(getCurrentUser);
  const loggedIn = useSelector(userIsLoggedIn);

  const { locale, pathname, asPath, query } = router;
  const languageData = [
    {
      value: SUPPORTED_LOCALES['en'],
      label: loc.Language.English
    },
    {
      value: SUPPORTED_LOCALES['zh-CN'],
      label: loc.Language.ChineseSimplified
    },
    {
      value: SUPPORTED_LOCALES['zh-TW'],
      label: loc.Language.ChineseTraditional
    },
    {
      value: SUPPORTED_LOCALES['ko'],
      label: loc.Language.Korean
    },
    {
      value: SUPPORTED_LOCALES['jp'],
      label: loc.Language.Japanese
    }
  ];
  const selectedLanguageData = languageData.find((data) => data.value === locale) || languageData[0];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = (newLocale: string) => {
    onNavigate();
    updateLanguagePreferenceCookie(document, newLocale);
    loggedIn && updateUserLanguagePreference(user, newLocale);
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };
  return (
    <>
      <ListItem>
        <ListItemButton onClick={toggleMenu}>
          <Box sx={{ paddingRight: spacing.md }}>
            <FAIcon icon={icons.globe} />
          </Box>
          <ListItemText primary={selectedLanguageData.label} />
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>

      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {languageData.map((language) => (
            <ListItem key={language.value}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  handleClick(language.value);
                }}
              >
                <ListItemText primary={language.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};
