import { FC, useEffect, useState } from 'react';

import { TransDarkBlueButton } from '../../bits/buttons';
import { InternalLink } from '../../bits/links';
import routes from '../../routes';
import { P1 } from '../../bits/text';
import dynamic from 'next/dynamic';
import { CookiePreferences, getCookie, markCookieActionTaken, setCookiePrefs } from '../../utils/cookies';
import {
  COOKIE_CONSENT_ACTION_TAKEN,
  COOKIE_PREFS_ANALYTICS,
  COOKIE_PREFS_ESSENTIAL,
  COOKIE_PREFS_FUNCTIONAL
} from '../../constants/cookie';
import { parseIntI } from '../../utils';
import { useLocalizer } from '../../localization';
import { MoovButton } from '../../bits/moovButton';

const CookiePreferencesModal = dynamic(() => import('./cookiePreferencesModal'), { ssr: false });

/**
 * TODO 182433668 log missing translations once we get the legal copy from legal Andrew. legal.
 */
export const CookieConsentPopup: FC = () => {
  const loc = useLocalizer();
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [actionTaken, setActionTaken] = useState(true);

  useEffect(() => {
    setActionTaken(parseIntI(getCookie(document, COOKIE_CONSENT_ACTION_TAKEN)) === 1);
  }, []);

  const handleAcceptClick = () => {
    const fullConsentPreferences: CookiePreferences = {
      [COOKIE_PREFS_ESSENTIAL]: 1,
      [COOKIE_PREFS_FUNCTIONAL]: 1,
      [COOKIE_PREFS_ANALYTICS]: 1
    };
    setCookiePrefs(document, fullConsentPreferences);
    markCookieActionTaken(document);
    setActionTaken(true);
  };

  const handleCustomizeClick = () => {
    setModal(<CookiePreferencesModal setModal={setModal} beforeSave={() => setActionTaken(true)} />);
  };

  if (actionTaken) {
    return null;
  }

  return (
    <>
      {modal}

      <div className="fixed left-0 bottom-0 right-0 z-cookieConsentPopup flex flex-col items-center gap-y-4 bg-white py-5 px-6 shadow-light lg:left-4 lg:bottom-4 lg:right-auto lg:max-w-[24rem] lg:rounded lg:px-6 lg:py-6">
        <article>
          <h6 className="mb-1 text-18spx font-bold">
            {/* TODO: Translations */}
            We value your privacy
          </h6>
          <P1>
            {/* TODO: Translations */}
            We and our selected partners use cookies to enhance your browsing experience, serve personalized content, and analyze our
            traffic. By clicking {`"Accept All"`}, you consent to our use of cookies.{' '}
            <InternalLink href={routes.policyPrivacy()} className="whitespace-nowrap text-peach hover:underline">
              {loc.HomeMain.ReadMore}
            </InternalLink>
          </P1>
        </article>
        <div className="flex w-full flex-col gap-y-2 text-14spx">
          <MoovButton variant="secondary" className="px-3 py-2.5" onClick={handleAcceptClick}>
            {/* TODO: Translations */}
            Accept All
          </MoovButton>
          <TransDarkBlueButton className="px-3 py-2.5" onClick={handleCustomizeClick}>
            {/* TODO: Translations */}
            Customize
          </TransDarkBlueButton>
        </div>
      </div>
    </>
  );
};

export default CookieConsentPopup;
