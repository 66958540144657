import { useEffect } from 'react';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { SelectWithOptions } from '@/src/design/bytes/selectWithOptions';
import { colors, spacing } from '@/src/design/designConstants';
import { FormValidationHook } from '@/src/hooks/useFormValidation';
import { useLocalizer } from '@/src/localization';
import { Box, FormControl, FormLabel, Stack, Typography } from '@mui/material';
import { ContactHandleField } from './contactHandleField';
import { LabeledPhoneField } from '@/src/design/bytes/labeledPhoneField';
import { contactPreferenceOptions, contactPreferencesForPhone } from '@/src/providers/leads/types';
import { isPhoneRequired } from './helpers';

export interface ContactFormValues {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  contact_preference: number;
  contact_handle: string;
}

export interface ContactFormProps<T> {
  formValidationHook: FormValidationHook<T>;
}

export const ContactForm = function <T extends ContactFormValues>(props: ContactFormProps<T>) {
  const { formValidationHook } = props;
  const { formValues, setFieldValue, onFieldBlur, isFieldErrored, getFieldError, validateField } = formValidationHook;
  const loc = useLocalizer();

  useEffect(() => {
    if (!contactPreferencesForPhone.includes(formValues.contact_preference)) {
      validateField('phone', formValues.phone);
    }
  }, [formValues.contact_preference]);

  return (
    <Box>
      <Box mb={spacing['3xl']}>
        <Typography variant="body1" color={colors.smokeyGray[500]}>
          {/* TODO: Translations #185801497 - Provide your contact information so we can follow up on your request */}
          Provide your contact information so we can follow up on your request
        </Typography>
      </Box>

      <Stack rowGap={spacing.xl}>
        <LabeledTextField
          type="text"
          label={loc.Forms.Email}
          placeholder={loc.Forms.Email}
          value={formValues.email || ''}
          onChange={(e) => setFieldValue('email', e.target.value)}
          onBlur={() => onFieldBlur('email')}
          error={isFieldErrored('email')}
          helperText={getFieldError('email')}
          required
          size="medium"
        />
        <LabeledTextField
          type="text"
          label={loc.Forms.FirstName}
          placeholder={loc.Forms.FirstName}
          value={formValues.first_name || ''}
          onChange={(e) => setFieldValue('first_name', e.target.value)}
          onBlur={() => onFieldBlur('first_name')}
          error={isFieldErrored('first_name')}
          helperText={getFieldError('first_name')}
          size="medium"
        />
        <LabeledTextField
          type="text"
          label={loc.Forms.LastName}
          placeholder={loc.Forms.LastName}
          value={formValues.last_name || ''}
          onChange={(e) => setFieldValue('last_name', e.target.value)}
          onBlur={() => onFieldBlur('last_name')}
          error={isFieldErrored('last_name')}
          helperText={getFieldError('last_name')}
          size="medium"
        />
        <LabeledTextField
          type="text"
          // TODO: Translations #185801497 - Company Name
          label={'Company Name'}
          placeholder={'Company Name'}
          value={formValues.company || ''}
          onChange={(e) => setFieldValue('company', e.target.value)}
          onBlur={() => onFieldBlur('company')}
          error={isFieldErrored('company')}
          helperText={getFieldError('company')}
          size="medium"
        />
        <LabeledPhoneField
          label={loc.Common.PhoneNumber}
          placeholder={loc.Common.PhoneNumber}
          value={formValues.phone || ''}
          onChange={(value) => setFieldValue('phone', value)}
          onBlur={() => onFieldBlur('phone')}
          error={isFieldErrored('phone')}
          helperText={getFieldError('phone')}
          required={isPhoneRequired(formValues)}
        />
        <FormControl>
          {/* TODO: Translations #185801497 - How would you prefer to be contacted? */}
          <FormLabel>How would you prefer to be contacted?</FormLabel>
          <SelectWithOptions
            data-testid="contact-form-contact-preference"
            value={contactPreferenceOptions.find((option) => parseInt(option.id) === formValues.contact_preference)?.id || ''}
            options={contactPreferenceOptions}
            handleChange={(id) => {
              setFieldValue('contact_preference', parseInt(id));
              setFieldValue('contact_handle', '');
            }}
            size="medium"
          />
        </FormControl>
        <ContactHandleField formValidationHook={formValidationHook} />
      </Stack>
    </Box>
  );
};
