import type { Locale } from '../../localization/types';
import { getDefinitions } from '../../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class EditListingMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get EditListing(): string {
    return this.definitions.EditListing;
  }

  public get EditYourListing(): string {
    return this.definitions.EditYourListing;
  }

  public get MoreInformationBetterListing(): string {
    return this.definitions.MoreInformationBetterListing;
  }

  public get OpenListingInNewTab(): string {
    return this.definitions.OpenListingInNewTab;
  }

  public get SuccessFilesUpdated(): string {
    return this.definitions.SuccessFilesUpdated;
  }

  public get SuccessListingInfoUpdated(): string {
    return this.definitions.SuccessListingInfoUpdated;
  }

  public get SuccessPhotosUpdated(): string {
    return this.definitions.SuccessPhotosUpdated;
  }
}
