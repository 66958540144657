// * managed dependencies i.e. node_modules *
import axios from 'axios';
import { captureException } from '@sentry/nextjs';

// * external dependencies *
import { valueFromKey } from '@/src/common/lib';
import { ERROR_MESSAGES, MOOV_API_URL } from '@/src/constants';
import { GlobalBannerDurations } from '@/src/state/banner/types';
import { AppDispatch, AppState } from '@/src/store';

// * internal dependencies *
import { deleteOne, load, setLoading, select, loadCustomFields, updateEquipment as updateEquipmentAction } from './slice';
import { CompanyCustomFields, Equipment2, EquipmentSerializable } from './types';
import { openError, openSuccess } from '@/src/state/banner/slice';
import { closeModal } from '@/src/state/globalModal/slice';
import { getCurrent } from './selectors';
import Router from 'next/router';
import routes from '../routes';

// TODO: 186150352 translate all content in this file

const _fetchCustomFields = () => {
  return axios.get<{ data: CompanyCustomFields[] }>(`${MOOV_API_URL}/companyEquipmentFields`, {
    headers: { 'Content-Type': 'application/vnd.api+json' },
    withCredentials: true
  });
};

export function fetchAll() {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const allEquipmentFetch = axios
      .get<{ data: EquipmentSerializable[] }>(`${MOOV_API_URL}/equipment`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = body.map((e) => Equipment2.anyToSerializable(e));
        dispatch(load(data));
      });

    const companyCustomFieldsFetch = _fetchCustomFields().then((customFields) => {
      dispatch(loadCustomFields(customFields.data.data));
    });

    return Promise.all([allEquipmentFetch, companyCustomFieldsFetch])
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function fetchById(id: number | string) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const equipmentFetch = axios
      .get<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${id}`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));

        return data.id.toString();
      });

    const companyCustomFieldsFetch = _fetchCustomFields().then((customFields) => {
      dispatch(loadCustomFields(customFields.data.data));
    });

    return Promise.all([equipmentFetch, companyCustomFieldsFetch])
      .then((results) => {
        dispatch(select(results[0]));
        dispatch(setLoading(false));
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function create(a: EquipmentSerializable) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return axios
      .post<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment`, a, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));
        dispatch(closeModal());
        dispatch(select(data.id.toString()));
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function postPriceRequest(id: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return axios
      .post<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${id}/requestPrice`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

function _update(equipmentToUpdate: EquipmentSerializable, update: Partial<EquipmentSerializable>) {
  return async (dispatch: AppDispatch, _: () => AppState) => {
    dispatch(setLoading(true));
    const updated = { ...update, custom_fields: { ...equipmentToUpdate.custom_fields, ...update.custom_fields } };

    //Optimistically update the equipment
    dispatch(updateEquipmentAction({ ...equipmentToUpdate, ...updated }));

    return axios
      .put<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${equipmentToUpdate.id}`, updated, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));

        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            message: 'Your changes have been saved!'
          })
        );
      })
      .catch((e) => {
        //Revert equipment back to previous values if the update fails
        dispatch(updateEquipmentAction(equipmentToUpdate));

        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function updateCurrentEquipment(newStuff: Partial<EquipmentSerializable>) {
  return function (dispatch: AppDispatch, getState: () => AppState) {
    const current = getCurrent(getState()).toSerializable();
    return _update(current, newStuff)(dispatch, getState);
  };
}

export function updateEquipment(equipmentToUpdate: EquipmentSerializable, newStuff: Partial<EquipmentSerializable>) {
  return function (dispatch: AppDispatch, getState: () => AppState) {
    return _update(equipmentToUpdate, newStuff)(dispatch, getState);
  };
}

export function listEquipment(a: EquipmentSerializable) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return axios
      .post<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${a.id}/listing`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));
        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            // TODO: translations(we need to figure out how we are doing this in thunks first #185941670)
            message: 'Your equipment is now listed for sale!'
          })
        );
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function unlistEquipment(a: EquipmentSerializable) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return axios
      .post<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${a.id}/listing/disable`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const body = valueFromKey<Array<EquipmentSerializable>>('data', r.data);
        // toSerializable confirms that we have the shape we expect from the API / gives us stronger type safety than Axios<T>
        const data = Equipment2.anyToSerializable(body);
        dispatch(load([data]));
        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            // TODO: translations(we need to figure out how we are doing this in thunks first #185941670)
            message: 'Your listing has been removed.'
          })
        );
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function deleteById(a: EquipmentSerializable, redirect = true) {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return axios
      .post<{ data: EquipmentSerializable }>(`${MOOV_API_URL}/equipment/${a.id}/disable`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then(() => {
        dispatch(deleteOne(a.id.toString()));
        dispatch(
          openSuccess({
            duration: GlobalBannerDurations.SHORT,
            message: 'Your changes have been saved!' //TODO: fix?
          })
        );
        if (redirect) {
          Router.push(routes.equipment());
        }
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
