import { ListingSearchSort, ListingSearchSortValue } from '@/src/providers/listings/constants';
import { getDefinitions, Locale } from '../../localization';
import translations from './index.loc.json';
import type { SelectWithOptionsDropdownOption } from '@/src/design/bytes/selectWithOptions/index';

/**
 *
 */
export class SearchLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get FilterByModel(): string {
    return this.definitions.FilterByModel;
  }
  public get FilterbyCondition(): string {
    return this.definitions.FilterbyCondition;
  }

  public get FilterbyMinimumVintage(): string {
    return this.definitions.FilterbyMinimumVintage;
  }

  public get FilterByWaferSize(): string {
    return this.definitions.FilterByWaferSize;
  }

  public get HasPhotos(): string {
    return this.definitions.HasPhotos;
  }

  public get LearnMore(): string {
    return this.definitions.LearnMore;
  }

  public get MinimumVintage(): string {
    return this.definitions.MinimumVintage;
  }

  public get MostPhotos(): string {
    return this.definitions.MostPhotos;
  }

  public get SmartSort(): string {
    return this.definitions.SmartSort;
  }

  public get SortBy(): string {
    return this.definitions.SortBy;
  }

  public get ListingConditionSortOptions() {
    return [
      { value: 0, label: `-- ${this.definitions.AllConditions} --` },
      { value: 1, label: this.definitions.Used },
      { value: 2, label: this.definitions.Refurbished },
      { value: 3, label: this.definitions.New },
      { value: 4, label: this.definitions.PartsTool }
    ];
  }

  public get ListingSearchSortOptionsForMui(): SelectWithOptionsDropdownOption<ListingSearchSortValue>[] {
    return [
      { id: ListingSearchSort.PHOTOS, label: this.definitions.MostPhotos },
      { id: ListingSearchSort.VERIFIED, label: this.definitions.LastVerified },
      { id: ListingSearchSort.VINTAGE, label: this.definitions.Vintage }
    ];
  }

  public get ListingSortOptions() {
    return [
      { value: 'newest', label: this.definitions.NewestFirst },
      { value: 'oldest', label: this.definitions.OldestFirst },
      { value: 'price_hl', label: this.definitions.PriceHighToLow },
      { value: 'price_lh', label: this.definitions.PriceLowToHigh },
      { value: 'location_az', label: this.definitions.LocationAToZ },
      { value: 'location_za', label: this.definitions.LocationZToA }
    ];
  }

  public get Filters(): string {
    return this.definitions.Filters;
  }

  public get LocationColon(): string {
    return this.definitions.LocationColon;
  }

  public get PriceNotSpecified(): string {
    return this.definitions.PriceNotSpecified;
  }

  public get AllConditions(): string {
    return this.definitions.AllConditions;
  }

  public get AllMakes(): string {
    return this.definitions.AllMakes;
  }

  public get AllWaferSizes(): string {
    return this.definitions.AllWaferSizes;
  }

  public get AllCategories(): string {
    return this.definitions.AllCategories;
  }

  public get AllModels(): string {
    return this.definitions.AllModels;
  }

  public get AllLocations(): string {
    return this.definitions.AllLocations;
  }

  public get AllListings(): string {
    return this.definitions.AllListings;
  }
}
