// this is the set that requires payloads of a specific shape in addition to the event name
export const typedLogisticsEvents = {
  freightTypeSelected: 'logistics-freight-type-selected',
  insuranceAmountChanged: 'logistics-insurance-amount-changed',
  countrySelected: 'logistics-country-selected'
} as const;

// these dont need any other information besides the event name for fullstory
export const untypedLogisticsEvents = {
  quoteRequested: 'logistics-quote-requested',
  additionalQuoteInitiated: 'logistics-additional-quote-initiated'
} as const;

// typing for all basic events without payload
const logisticValues = Object.values(untypedLogisticsEvents);
type LogisticValuesTypes = [(typeof logisticValues)[number]];

const frieghtTypeValuesConst = ['ocean', 'air'] as const;
type LogisticsFeightSelectedEventType = [
  typeof typedLogisticsEvents.freightTypeSelected,
  { type: (typeof frieghtTypeValuesConst)[number] }
];

type InsuranceAmountChangedEventType = [typeof typedLogisticsEvents.insuranceAmountChanged, { amount: number }];
type CountrySelectedEventType = [typeof typedLogisticsEvents.countrySelected, { country: string }];

// The end result of all possible events and their payloads
export type LogisticsTypes =
  | LogisticValuesTypes
  | LogisticsFeightSelectedEventType
  | InsuranceAmountChangedEventType
  | CountrySelectedEventType;
