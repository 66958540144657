import { ProductPhotosCTA } from '@/src/listings/edit/FormikListingFileDropzone/productPhotosCTA';
import { shouldDisplayPhotoCTA } from './utils';
import { FC } from 'react';
import { EquipmentFileDropzoneFileType } from './index';
import { MoovButton } from '@/src/bits/moovButton';
import { useLocalizer } from '@/src/localization';

interface DropzoneSubmitProps {
  displayPhotoCTA?: boolean;
  showUpdateButton?: boolean;
  loading?: boolean;
  dirty: boolean;
  fileType: 'image' | 'file';
  files: EquipmentFileDropzoneFileType[];
  filesToDelete: EquipmentFileDropzoneFileType[];
  onSubmit?: () => void;
}

export const DropzoneSubmit: FC<DropzoneSubmitProps> = ({
  displayPhotoCTA,
  fileType,
  files,
  filesToDelete,
  showUpdateButton,
  dirty,
  loading,
  onSubmit
}) => {
  const loc = useLocalizer();
  return (
    <>
      {shouldDisplayPhotoCTA(displayPhotoCTA, fileType, files, filesToDelete) ? (
        <ProductPhotosCTA />
      ) : (
        <>
          {showUpdateButton && (
            <MoovButton
              variant="quaternary"
              className="mb-4 w-full px-3 py-4.5 text-14spx lg:py-2.5"
              disabled={!dirty}
              onClick={() => onSubmit && onSubmit()}
              isLoading={loading}
            >
              {loc.Forms.Update}
            </MoovButton>
          )}
        </>
      )}
    </>
  );
};
