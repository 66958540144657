import translations from './language.loc.json';
import type { Locale } from './types';

/**
 *
 */
export class LanguageLocalizer {
  private definitions;

  constructor(_: Locale) {
    this.definitions = translations.all;
  }

  public get English(): string {
    return this.definitions.en;
  }

  public get ChineseSimplified(): string {
    return this.definitions.zhCN;
  }

  public get ChineseTraditional(): string {
    return this.definitions.zhTW;
  }

  public get Japanese(): string {
    return this.definitions.jp;
  }

  public get Korean(): string {
    return this.definitions.ko;
  }
}
