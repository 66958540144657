import { customIconNames } from './icons';
import { CustomIconName, FigmaIconPadding, FigmaIconScale } from './types';

/**
 * The default font size that the Font Awesome tool uses in Figma.
 * There are currently no plans to change this, but if there is
 * ever a need we can update that value here.
 */
const FIGMA_FONT_SIZE_IN_PX = 16;

export const getIconFontSize = (scale: FigmaIconScale) => {
  return `${scale * FIGMA_FONT_SIZE_IN_PX}px`;
};

export const getIconPadding = (padding: FigmaIconPadding, scale: FigmaIconScale) => {
  const pxWidth = scale * FIGMA_FONT_SIZE_IN_PX;

  switch (padding) {
    case 'none':
      return '0';
    case 'fixed-width':
      return `0 ${0.125 * pxWidth}px`;
    case 'square':
      return `${0.125 * pxWidth}px`;
    case 'roomy':
      return `${0.25 * pxWidth}px`;
  }
};

export const isCustomIconName = (value: unknown): value is CustomIconName => {
  return typeof value === 'string' && (Object.values(customIconNames) as string[]).includes(value);
};
