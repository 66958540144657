import { CheckboxGroupValue } from './types';

/**
 * Helper function for the LabeledCheckboxGroup that returns a new
 * CheckboxGroupValue with updated array values.
 */
export const getUpdatedCheckboxGroupValue = (oldGroupValue: CheckboxGroupValue, checked: boolean, value: number): CheckboxGroupValue => {
  const newGroupValue = new Set(oldGroupValue);
  checked ? newGroupValue.add(value) : newGroupValue.delete(value);
  return Array.from(newGroupValue);
};
