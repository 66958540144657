import { ToolStatus, isEmptyToolStatus } from '@/src/listings/types';
import { unreachable } from '../typeSafe';

export const ToolStatus2String = (status: ToolStatus): string => {
  if (isEmptyToolStatus(status) || status === ToolStatus.NO_STATUS) {
    return '';
  }

  switch (status) {
    case ToolStatus.INSTALLED_RUNNING:
      return 'Installed / Running';
    case ToolStatus.INSTALLED_IDLE:
      return 'Installed / Idle';
    case ToolStatus.INSTALLED_DOWN:
      return 'Installed / Down';
    case ToolStatus.DEINSTALLED:
      return 'Deinstalled';
    case ToolStatus.DEINSTALLED_UNCRATED:
      return 'Deinstalled / Uncrated';
    case ToolStatus.DEINSTALLED_CRATED:
      return 'Deinstalled / Crated';
    case ToolStatus.DEINSTALLED_PALLETIZED:
      return 'Deinstalled / Palletized';
    default:
      unreachable(status);
      return 'unknown';
  }
};
