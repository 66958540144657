import { getDefinitions, interpolateVariable, Locale } from '../../localization';
import translations from './index.loc.json';

/**
 *
 */
export class HomeMainLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get AmkorLogo(): string {
    return this.definitions.AmkorLogo;
  }

  public get AmkorWebsite(): string {
    return this.definitions.AmkorWebsite;
  }

  public get Buyers(): string {
    return this.definitions.Buyers;
  }

  public get CompaniesAndGrowing(): string {
    return this.definitions.CompaniesAndGrowing;
  }

  public DomesticInternationalDelivery(domesticDeliveryDays: string | number, internationalDeliverDays: string | number): string {
    const text = interpolateVariable(this.definitions.DomesticInternationalDelivery, 'domestic_delivery_days', domesticDeliveryDays);
    return interpolateVariable(text, 'international_delivery_days', internationalDeliverDays);
  }

  public get FindQualityFast(): string {
    return this.definitions.FindQualityFast;
  }

  public get FirstClassService(): string {
    return this.definitions.FirstClassService;
  }

  public get TransactWithConfidence(): string {
    return this.definitions.TransactWithConfidence;
  }

  public get GlobalScale(): string {
    return this.definitions.GlobalScale;
  }

  public get HowItWorks(): string {
    return this.definitions.HowItWorks;
  }

  public get JoinTheMoovment(): string {
    return this.definitions.JoinTheMoovment;
  }

  public get KLALogo(): string {
    return this.definitions.KLALogo;
  }

  public get KLAWebsite(): string {
    return this.definitions.KLAWebsite;
  }

  public get LGLogo(): string {
    return this.definitions.LGLogo;
  }
  public get SamsungLogo(): string {
    return this.definitions.SamsungLogo;
  }

  public get LGWebsite(): string {
    return this.definitions.LGWebsite;
  }

  public get SamsungWebsite(): string {
    return this.definitions.SamsungWebsite;
  }

  public get ManageAcceptTrack(): string {
    return this.definitions.ManageAcceptTrack;
  }

  public get MoovDifference(): string {
    return this.definitions.MoovDifference;
  }

  public get MoovDifferenceDescription1(): string {
    return this.definitions.MoovDifferenceDescription1;
  }

  public get MoovDifferenceDescription2(): string {
    return this.definitions.MoovDifferenceDescription2;
  }
  public get MoovDifferenceDescription3(): string {
    return this.definitions.MoovDifferenceDescription3;
  }
  public get MoovDifferenceDescription4(): string {
    return this.definitions.MoovDifferenceDescription4;
  }
  public get MoovDifferenceDescriptionConclusion(): string {
    return this.definitions.MoovDifferenceDescriptionConclusion;
  }

  public get NewWayToBuySell(): string {
    return this.definitions.NewWayToBuySell;
  }

  public get NXPLogo(): string {
    return this.definitions.NXPLogo;
  }

  public get NXPWebsite(): string {
    return this.definitions.NXPWebsite;
  }

  public get OverXCompaniesAndGrowing(): string {
    return this.definitions.OverXCompaniesAndGrowing;
  }

  public get PercentSatisfaction(): string {
    return this.definitions.PercentSatisfaction;
  }

  public get ReadMore(): string {
    return this.definitions.ReadMore;
  }

  public get ReadTheLatest(): string {
    return this.definitions.ReadTheLatest;
  }

  public get SeeWhyManufacturersTrustMoov(): string {
    return this.definitions.SeeWhyManufacturersTrustMoov;
  }

  public get Sellers(): string {
    return this.definitions.Sellers;
  }

  public get TotalValue(): string {
    return this.definitions.TotalValue;
  }

  public get WithVerifiedUptodateInformation(): string {
    return this.definitions.WithVerifiedUptodateInformation;
  }

  public get TrustedBy(): string {
    return this.definitions.TrustedBy;
  }

  public get UsedSemiconductorEquipmentMarketplace(): string {
    return this.definitions.UsedSemiconductorEquipmentMarketplace;
  }

  public get ZeroDamageClaims(): string {
    return this.definitions.ZeroDamageClaims;
  }

  public get MoovEliminatesPurchaseRisk(): string {
    return this.definitions.MoovEliminatesPurchaseRisk;
  }

  public get CreateToolRequests(): string {
    return this.definitions.CreateToolRequests;
  }

  public get SimplifyEquipemtProcurement(): string {
    return this.definitions.SimplifyEquipemtProcurement;
  }

  public get ReviewToolMatches(): string {
    return this.definitions.ReviewToolMatches;
  }

  public get ToolRequestsAndPreferences(): string {
    return this.definitions.ToolRequestsAndPreferences;
  }

  public get AskTechnicalQuestions(): string {
    return this.definitions.AskTechnicalQuestions;
  }

  public get NeedToKnowMoreSpecifics(): string {
    return this.definitions.NeedToKnowMoreSpecifics;
  }

  public get PurchaseAndTrack(): string {
    return this.definitions.PurchaseAndTrack;
  }

  public get ExpertsInLogisticsAndPaperwork(): string {
    return this.definitions.ExpertsInLogisticsAndPaperwork;
  }

  public get RegisterForAFreeAccount(): string {
    return this.definitions.RegisterForAFreeAccount;
  }

  public get SignUpInLessThanAMinute(): string {
    return this.definitions.SignUpInLessThanAMinute;
  }

  public get EnterpriseManagementForListings(): string {
    return this.definitions.EnterpriseManagementForListings;
  }

  public get ListAndManageYourLargeVolumnOfEquipment(): string {
    return this.definitions.ListAndManageYourLargeVolumnOfEquipment;
  }

  public get QandAForTechnicalQuestions(): string {
    return this.definitions.QandAForTechnicalQuestions;
  }

  public get GetNotifiedWhenYouHaveABuyer(): string {
    return this.definitions.GetNotifiedWhenYouHaveABuyer;
  }

  public get LogisticsHandled(): string {
    return this.definitions.LogisticsHandled;
  }

  public get KnowYourEquipmentWillBePurchased(): string {
    return this.definitions.KnowYourEquipmentWillBePurchased;
  }

  public get GetPaidFast(): string {
    return this.definitions.GetPaidFast;
  }

  public get MoovsSalesTeamArmed(): string {
    return this.definitions.MoovsSalesTeamArmed;
  }

  public get Features(): string {
    return this.definitions.Features;
  }

  public get ToolRequests(): string {
    return this.definitions.ToolRequests;
  }

  public get SimplifiesToolRequests(): string {
    return this.definitions.SimplifiesToolRequests;
  }

  public get ManageListings(): string {
    return this.definitions.ManageListings;
  }

  public get QuickAndEffortlessListingCreation(): string {
    return this.definitions.QuickAndEffortlessListingCreation;
  }

  public get DetailedTracking(): string {
    return this.definitions.DetailedTracking;
  }

  public get DetailedGPSTrackingWorldwide(): string {
    return this.definitions.DetailedGPSTrackingWorldwide;
  }
}
