import React, { FC, HTMLAttributes, MouseEventHandler, ReactNode, useEffect, useRef } from 'react';

import Image from 'next/image';
import Draggable from 'react-draggable';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import { useLocalizer } from '../../../localization';

import Modal from '../modal';
import { Photo, SetModal } from '../../../entities/common';
import { useModal } from '../../../hooks/useModal';
import { useStateRef } from '../../../hooks/useStateRef';

interface ControlButtonProps extends HTMLAttributes<HTMLButtonElement> {
  readonly children: ReactNode;
  readonly onClick?: MouseEventHandler;
}

/**
 *
 */
const ControlButton = ({ children, onClick, className, ...props }: ControlButtonProps) => (
  <button
    type="button"
    className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 p-3 text-white active:bg-opacity-80 lg:hover:bg-opacity-80 ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

/**
 *
 */
const ControlsDivider = () => <div className="mx-2 h-full w-px bg-black bg-opacity-50" />;

interface ControlsInfoRowProps {
  readonly label: string;
  readonly description: string;
}
/**
 *
 */
const ControlsInfoRow = ({ label, description }: ControlsInfoRowProps) => (
  <div className="flex items-center gap-4 whitespace-nowrap" key={label}>
    <div className="flex w-4 justify-center font-bold text-blue-500">{label}</div>
    <div className="whitespace-nowrap">{description}</div>
  </div>
);

interface ImageGalleryModalProps {
  readonly photos: Photo[];
  readonly alt: string;
  readonly initialIndex?: number;
  readonly setModal: SetModal;
}

/**
 *
 */
const ImageGalleryModal: FC<ImageGalleryModalProps> = (props) => {
  const loc = useLocalizer();
  const { photos, alt, initialIndex, setModal } = props;

  const { isOpen, onClose } = useModal(setModal);
  const [activeIndex, setActiveIndex, activeIndexRef] = useStateRef(0);
  const [imgScale, setImgScale, imgScaleRef] = useStateRef(1);
  const [rotateDegs, setRotateDegs, rotateDegsRef] = useStateRef(0);
  const [scaleX, setScaleX, scaleXRef] = useStateRef(1);
  const [scaleY, setScaleY, scaleYRef] = useStateRef(1);
  const isOpenRef = useRef(isOpen);
  const nodeRef = useRef(null);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (typeof initialIndex === 'undefined' || initialIndex < 0 || initialIndex > photos.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(initialIndex);
    }
  }, [initialIndex, photos, setActiveIndex]);

  useEffect(() => {
    setImgScale(1);
    setRotateDegs(0);
    setScaleX(1);
    setScaleY(1);
  }, [activeIndex, setImgScale, setRotateDegs, setScaleX, setScaleY]);

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  const viewNext = () => {
    if (activeIndexRef.current < photos.length - 1) {
      setActiveIndex(activeIndexRef.current + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const viewPrevious = () => {
    if (activeIndexRef.current > 0) {
      setActiveIndex(activeIndexRef.current - 1);
    } else {
      setActiveIndex(photos.length - 1);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        viewPrevious();
        break;
      case 'ArrowRight':
        viewNext();
        break;
      case 'ArrowUp':
        incrementImgScale(0.1);
        break;
      case 'ArrowDown':
        incrementImgScale(-0.1);
        break;
      case 'q':
        rotate(-90);
        break;
      case 'e':
        rotate(90);
        break;
      case 'w':
        setRotateDegs(0);
        break;
      case 'h':
        setScaleX(scaleXRef.current * -1);
        break;
      case 'v':
        setScaleY(scaleYRef.current * -1);
        break;
    }
  };

  const incrementImgScale = (amount: number) => {
    if (amount > 0 || imgScaleRef.current > 0.2) {
      setImgScale(imgScaleRef.current + amount);
    }
  };

  const rotate = (degs: number) => {
    setRotateDegs(rotateDegsRef.current + degs);
  };

  const images = photos.map((photo, i) => (
    <li
      className={`relative flex h-full min-w-[6rem] max-w-[6rem] cursor-pointer items-center justify-center overflow-hidden brightness-50 hover:brightness-100`}
      style={{
        filter: activeIndex === i ? 'brightness(1)' : ''
      }}
      onClick={() => setActiveIndex(i)}
      key={photo.small_url}
    >
      <Image src={photo.small_url} alt={loc.Modals.Photo} layout="fill" objectFit="cover" />
    </li>
  ));

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="h-screen w-screen select-none">
          <div className="fixed flex h-[90%] w-full flex-col items-center">
            {/* CLOSE-ON-CLICK OVERLAY */}
            <div onClick={onClose} className="absolute bottom-0 left-0 right-0 top-0" />

            {/* MAIN IMAGE */}
            <Draggable nodeRef={nodeRef} key={activeIndex}>
              <div
                className="m-auto flex justify-center"
                style={{
                  maxHeight: 'calc(100% - 8rem)'
                }}
                ref={nodeRef}
              >
                <img // eslint-disable-line
                  src={photos[activeIndex]?.large_url}
                  alt={alt}
                  className="z-galleryMainImg m-auto max-h-full max-w-full cursor-grab transition-all duration-200 active:cursor-grabbing"
                  draggable={false}
                  style={{
                    transform: `scale(${imgScale}) scaleX(${scaleX}) scaleY(${scaleY}) rotate(${rotateDegs}deg)`
                  }}
                  data-testid="ImageGalleryModal-MainImg"
                />
              </div>
            </Draggable>

            {/* GALLERY CONTROL BUTTONS */}
            <div className="z-galleryControls flex gap-2.5 p-4" data-testid="ImageGalleryModal-GalleryControls">
              <ControlButton onClick={() => incrementImgScale(0.25)} aria-label={loc.Modals.ZoomIn} data-testid="ControlButton-ZoomIn">
                <ZoomInIcon />
              </ControlButton>
              <ControlButton onClick={() => incrementImgScale(-0.25)} aria-label={loc.Modals.ZoomOut} data-testid="ControlButton-ZoomOut">
                <ZoomOutIcon />
              </ControlButton>

              <ControlsDivider />

              <ControlButton
                onClick={() => rotate(-90)}
                aria-label={loc.Modals.Rotate90CounterClockwise}
                data-testid="ControlButton-RotateCounterClockwise"
              >
                <Rotate90DegreesCcwIcon />
              </ControlButton>
              <ControlButton onClick={() => setRotateDegs(0)} aria-label={loc.Modals.ResetRotation} data-testid="ControlButton-RotateReset">
                <AutorenewIcon />
              </ControlButton>
              <ControlButton
                onClick={() => rotate(90)}
                aria-label={loc.Modals.Rotate90Clockwise}
                data-testid="ControlButton-RotateClockwise"
              >
                <Rotate90DegreesCwIcon />
              </ControlButton>

              <ControlsDivider />

              <ControlButton
                onClick={() => setScaleX(scaleX * -1)}
                aria-label={loc.Modals.FlipHorizontal}
                data-testid="ControlButton-FlipHorizontal"
              >
                <SwapHorizIcon />
              </ControlButton>
              <ControlButton
                onClick={() => setScaleY(scaleY * -1)}
                aria-label={loc.Modals.FlipVertical}
                data-testid="ControlButton-FlipVertical"
              >
                <SwapVertIcon />
              </ControlButton>

              <div className="hidden gap-2.5 lg:flex">
                <ControlsDivider />
                <div className="relative">
                  <ControlButton
                    className="peer"
                    aria-label={loc.Modals.KeyboardControlsInfo}
                    data-testid="ControlButton-KeyboardControlsInfo"
                  >
                    <InfoIcon />
                  </ControlButton>

                  {/* KEYBOARD CONTROLS TOOLTIP */}
                  <div className="absolute -top-2 left-1/2 hidden w-max -translate-x-1/2 -translate-y-full grid-cols-2 gap-x-8 gap-y-4 rounded bg-black bg-opacity-80 p-6 text-white peer-hover:grid peer-focus-visible:grid">
                    <ControlsInfoRow label="⬅" description={loc.Modals.PreviousImage} />
                    <ControlsInfoRow label="⮕" description={loc.Modals.NextImage} />
                    <ControlsInfoRow label="⬆" description={loc.Modals.ZoomIn} />
                    <ControlsInfoRow label="⬇" description={loc.Modals.ZoomOut} />
                    <ControlsInfoRow label="H" description={loc.Modals.FlipHorizontal} />
                    <ControlsInfoRow label="V" description={loc.Modals.FlipVertical} />
                    <ControlsInfoRow label="Q" description={loc.Modals.Rotate90CounterClockwise} />
                    <ControlsInfoRow label="E" description={loc.Modals.Rotate90Clockwise} />
                    <ControlsInfoRow label="W" description={loc.Modals.ResetRotation} />
                    <ControlsInfoRow label="ESC" description={loc.Modals.CloseGallery} />
                  </div>
                </div>
              </div>
            </div>

            {/* NAVIGATION BUTTONS */}
            {/* DESKTOP */}
            <button
              type="button"
              onClick={viewPrevious}
              className="absolute left-0 z-galleryNavArrows hidden h-full cursor-pointer items-center justify-center bg-transparent p-8 text-64spx text-white hover:bg-white hover:bg-opacity-10 lg:flex"
              aria-label={loc.Modals.PreviousImage}
              data-testid="ImageGalleryModal-DesktopNavPrev"
            >
              <ChevronLeftIcon fontSize="inherit" />
            </button>
            <button
              type="button"
              onClick={viewNext}
              aria-label={loc.Modals.NextImage}
              className="absolute right-0 z-galleryNavArrows hidden h-full cursor-pointer items-center justify-center bg-transparent p-8 text-64spx text-white hover:bg-white hover:bg-opacity-10 lg:flex"
              data-testid="ImageGalleryModal-DesktopNavNext"
            >
              <ChevronRightIcon fontSize="inherit" />
            </button>

            {/* MOBILE */}
            <div className="z-galleryNavArrows flex h-14 w-full justify-between gap-x-px lg:hidden">
              <button
                type="button"
                onClick={viewPrevious}
                className="flex flex-1 items-center justify-center bg-black bg-opacity-40 text-36spx text-white active:bg-opacity-50"
                aria-label={loc.Modals.PreviousImage}
              >
                <ChevronLeftIcon fontSize="inherit" />
              </button>
              <button
                type="button"
                onClick={viewNext}
                className="flex flex-1 items-center justify-center bg-black bg-opacity-40 text-36spx text-white active:bg-opacity-50"
                aria-label={loc.Modals.NextImage}
              >
                <ChevronRightIcon fontSize="inherit" />
              </button>
            </div>
          </div>

          {/* ALL IMAGES CAROUSEL */}
          <div className="fixed bottom-0 z-galleryImageCarousel h-[10%] w-screen bg-black bg-opacity-50">
            <ul
              className="hidden h-full transition-all duration-200 lg:flex"
              style={{ transform: `translateX(calc(50% - ${activeIndex} * 6rem - 6rem / 2))` }}
            >
              {images}
            </ul>
            <div className="flex h-full overflow-x-scroll transition-all duration-200">{images}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageGalleryModal;
