import { useEffect } from 'react';
import { useAppDispatch } from '@/src/store';
import { fetchCMSLinks } from '@/src/state/meta/thunks';

export const useFetchCMSLinks = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCMSLinks());
  }, []);
};
