import { FC } from 'react';
import { useLocalizer } from '../../../localization';

export const ProductPhotosCTA: FC = () => {
  const loc = useLocalizer();
  return (
    <div className="flex flex-col items-center gap-2 rounded-lg bg-magenta-50 py-6 text-center text-moov-blue-500">
      <h5 className="font-lato text-20spx font-black leading-normal">{loc.Corp.DidYouKnow}</h5>
      <p className="px-4 font-lato text-16spx font-normal leading-tight">{loc.Remarketing.ListingsWithPhotos}</p>
    </div>
  );
};
