import { Required } from '@/src/bits/forms/required';
import { FC } from 'react';

export interface DropzoneLabelProps {
  label?: string;
  required?: boolean;
  helperText?: string;
}

export const DropzoneLabel: FC<DropzoneLabelProps> = ({ label, required, helperText }) => {
  return (
    <>
      {label && (
        <label className="mb-2 text-14spx font-bold tracking-wide">
          {label}
          {required && <Required />}
          {helperText && <span className="text-12spx font-normal"> {helperText}</span>}
        </label>
      )}
    </>
  );
};
